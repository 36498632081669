import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';

import useScreenSizes from 'hooks/useScreenSizes.js';
import PropTypes from 'prop-types';

const Gallery = (props) => {
    const { open, setOpen, urlList, initialIndex } = props;
    const [activeIndex, setActiveIndex] = useState(initialIndex);
    const [width] = useScreenSizes();

    const prevOpen = useRef(false);

    const keyDownHandle = useCallback(
        (e) => {
            if (!open) return;

            if (e.keyCode === 27) setOpen(false);
            if (e.keyCode === 37 && activeIndex > 0) setActiveIndex(activeIndex - 1);
            if (e.keyCode === 39 && activeIndex < urlList.length - 1) setActiveIndex(activeIndex + 1);
        },
        [activeIndex, urlList, open],
    );

    useEffect(() => {
        if (open) window.addEventListener('keydown', keyDownHandle);
        else window.removeEventListener('keydown', keyDownHandle);

        return () => window.removeEventListener('keydown', keyDownHandle);
    }, [open, keyDownHandle]);

    /*
        On open make the clicked photo already selected
    */
    useEffect(() => {
        if (open !== prevOpen.current && open) {
            setActiveIndex(initialIndex);
        }

        if (open === prevOpen.current) prevOpen.current = open;
    }, [open, initialIndex]);

    return createPortal(
        urlList?.length ? (
            <div
                className={`no-user-select-recursive fixed left-0 top-0 z-50 flex w-full  items-center justify-center transition-opacity ${
                    open ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'
                }`}
                style={{
                    background: 'rgba(0, 0, 0, .85)',
                    height: width > 750 ? '100svh' : 'calc(100svh - 5rem + 10px)',
                    marginTop: width > 750 ? '0' : 'calc(5rem - 10px)',
                }}
            >
                <div
                    className="absolute right-0 top-0  flex items-center justify-center"
                    style={{
                        width: width > 750 ? 'calc(100% - 5rem - 10px)' : '100%',
                        height: width > 750 ? '100svh' : 'calc(100svh - 5rem + 10px)',
                    }}
                >
                    {/* Close Icon */}
                    <div
                        onClick={() => setOpen(false)}
                        className="absolute right-4 top-4 z-50 flex h-20 w-20 cursor-pointer items-center justify-center rounded-md transition-colors hover:bg-layout-transparent"
                    >
                        <CloseIcon className="text-white" style={{ fontSize: '3rem' }} />
                    </div>

                    {/* Photos and bottom history */}
                    <div
                        className="relative z-40 flex w-full  flex-col overflow-hidden"
                        style={{
                            height: width > 750 ? '100svh' : 'calc(100svh - 5rem + 10px)',
                        }}
                    >
                        {/* Top */}
                        <div className="relative flex w-full flex-1 items-center justify-center">
                            {/* Prev Arrow */}
                            <div
                                onClick={() => (activeIndex > 0 ? setActiveIndex(activeIndex - 1) : null)}
                                className={`absolute left-4 z-50 flex h-20 w-20 cursor-pointer items-center justify-center rounded-md transition-all hover:bg-layout-transparent ${
                                    activeIndex > 0 ? 'opacity-100' : 'opacity-0'
                                }`}
                            >
                                <ArrowBackIcon className="text-white" style={{ fontSize: '3rem' }} />
                            </div>

                            {/* Next Arrow */}
                            <div
                                onClick={() =>
                                    activeIndex < urlList.length - 1 ? setActiveIndex(activeIndex + 1) : null
                                }
                                className={`absolute right-4 z-50 flex h-20 w-20 cursor-pointer items-center justify-center rounded-md transition-all hover:bg-layout-transparent ${
                                    activeIndex < urlList.length - 1 ? 'opacity-100' : 'opacity-0'
                                }`}
                            >
                                <ArrowForwardIcon className="text-white" style={{ fontSize: '3rem' }} />
                            </div>

                            {urlList.map((url, index) => (
                                <img
                                    key={url + index}
                                    src={url}
                                    style={{
                                        maxWidth: '95%',
                                        maxHeight: '98.5%',
                                    }}
                                    className={`absolute  transform transition-all duration-200 ${
                                        index === activeIndex && open ? 'pointer-events-auto' : 'pointer-events-none'
                                    } ${
                                        index === activeIndex
                                            ? 'translate-x-0 opacity-100'
                                            : `opacity-0 ${index < activeIndex ? '-translate-x-16' : 'translate-x-16'}`
                                    }`}
                                />
                            ))}
                        </div>

                        {/* Bottom */}
                        <div className="relative mx-auto flex w-full flex-shrink-0 items-end justify-start bg-black py-2">
                            {urlList.map((url, index) => (
                                <div
                                    key={url + index + 'MINI'}
                                    onClick={() => setActiveIndex(index)}
                                    className="relative mr-2 w-1/12 cursor-pointer  bg-cover bg-center md:w-1/6 sm:w-1/4"
                                >
                                    <div
                                        style={{
                                            paddingBottom: '100%',
                                            opacity: index === activeIndex ? '1' : '.35',
                                            transition: 'opacity .2s ease',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundImage: `url('${url}')`,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        ) : null,

        document.getElementById('gallery-root'),
    );
};

Gallery.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    urlList: PropTypes.arrayOf(PropTypes.string),
    initialIndex: PropTypes.number,
};

Gallery.defaultProps = {
    open: false,
    setOpen: () => null,
    urlList: [],
    initialIndex: 0,
};

export default Gallery;
