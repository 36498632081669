import React from 'react';

import PropTypes from 'prop-types';
import { CHART_ELEMENTS_VALUES, getAverageStatus } from 'utils/kpiUtils';

const KpiChartDot = ({ cx, cy, value, active, indexes }) => {
    const average = value.at(-1);

    if (average === null) return null;

    const { color } = getAverageStatus(average, indexes);
    const { text, circle } = CHART_ELEMENTS_VALUES['dot'];
    const { yOffset, fontSize } = text;
    const { r } = circle;

    return (
        <>
            <text
                x={cx}
                y={cy - yOffset}
                textAnchor="middle"
                fontSize={fontSize}
                fill="var(--white)"
                className={`${active ? 'opacity-100' : 'opacity-70'}`}
            >
                {average}
            </text>
            <defs>
                <filter id="brightness">
                    <feComponentTransfer>
                        <feFuncR type="linear" slope="1.7" />
                        <feFuncG type="linear" slope="1.7" />
                        <feFuncB type="linear" slope="1.7" />
                    </feComponentTransfer>
                </filter>
            </defs>
            <circle
                cx={cx}
                cy={cy}
                r={r}
                fill={color}
                stroke="rgb(var(--base-black) / 20%)"
                filter={active && 'url(#brightness)'}
            />
        </>
    );
};

KpiChartDot.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    value: PropTypes.string,
    active: PropTypes.bool,
    indexes: PropTypes.object,
};

KpiChartDot.defaultProps = {
    cx: 0,
    cy: 0,
    value: [0],
    active: false,
    indexes: null,
};

export default KpiChartDot;
