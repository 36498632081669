import React, { useEffect, useState } from 'react';

import { Goal } from 'lucide-react';

import Loading from 'components/shared/loading';
import useMonths from 'hooks/useMonths';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling, generateYears } from 'utils';
import { DUMMY_KPI, getPeriodsFilter, handleFormatFormula, PERIODS } from 'utils/kpiUtils';

import KpiAverageComparison from '../components/kpi-average-comparison';
import KpiAverageProgressBar from '../components/kpi-average-progress-bar';
import KpiFormula from '../components/kpi-formula';
import KpiToolbar from '../components/kpi-toolbar';
import KpiPersonalViewChart from './kpi-personal-view-chart';

const KpiPersonalViw = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const months = useMonths();
    const years = generateYears(2015, new Date().getFullYear() + 5);

    const [periodsFilter, setPeriodsFilter] = useState(
        getPeriodsFilter(
            { value: years.indexOf(new Date().getFullYear()), options: years },
            { value: new Date().getMonth(), options: months },
        ),
    );

    const [loading, setLoading] = useState(true);

    const [kpi, setKpi] = useState({ ...DUMMY_KPI, formula: handleFormatFormula(DUMMY_KPI.formula) });

    const [period, setPeriod] = useState(PERIODS.at(0));

    const handleData = async () => {
        try {
            //TODO: Request the personal kpi
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        //TODO: Check user kpi permission and redirect if not present
        handleData();
    }, []);

    const handleChangePeriod = (i) => setPeriod(PERIODS.at(i));
    const handleChangeDivision = (i) => setPeriod((prev) => ({ ...prev, selectedDivision: prev.divisions[i] }));

    //TODO: Request the new kpi
    const handleChangeFilter = (k, v) =>
        setPeriodsFilter((prev) => ({ ...prev, selectedFilter: { ...prev.selectedFilter, [k]: v } }));

    return (
        <>
            <Helmet>
                <title>{kpi ? kpi.name : t('Personal KPI')}</title>
            </Helmet>
            <Header
                pageTitle={kpi ? kpi.name : t('Personal KPI')}
                pageIcon={<Goal />}
                toolbar={
                    <KpiToolbar
                        period={period}
                        periodsFilter={periodsFilter}
                        disabled={loading}
                        onChangePeriod={handleChangePeriod}
                        onChangeDivision={handleChangeDivision}
                        onChangeFilter={handleChangeFilter}
                    />
                }
            />
            <div className="page-container flex flex-col gap-8">
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="flex gap-8 xl:flex-col">
                            <div className="flex-none">
                                <KpiAverageComparison kpi={kpi} period={period} />
                            </div>
                            <div className="flex-grow">
                                <KpiAverageProgressBar kpi={kpi} period={period} />
                            </div>
                        </div>
                        {PERIODS.flatMap((period) =>
                            [...period.divisions].reverse().map((division) => {
                                return <KpiPersonalViewChart key={division} kpi={kpi} division={division} />;
                            }),
                        )}
                        <KpiFormula kpi={kpi} />
                    </>
                )}
            </div>
        </>
    );
};

export default KpiPersonalViw;
