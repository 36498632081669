import React, { useContext } from 'react';

import { InputAdornment } from '@material-ui/core';

import ValueDisplay from 'components/shared/value-display';
import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';
import { DatePicker, LabelWrapper } from 'RaisisComponents';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { toLocaleNumber } from 'utils';

const InvoiceValues = ({
    invoice,
    disableInputs,
    getExchangeRate,
    onUpdateVAT,
    onUpdateExchangeRate,
    onUpdateInvoiceValue,
}) => {
    const { t } = useTranslation();
    const { language, currencyObj, referenceCurrencyObj } = useContext(GlobalContext);

    return (
        <div className="mb-10 flex max-w-2xl flex-col gap-3">
            <div className="inline-block">
                <LocaleTextField
                    disabled={disableInputs}
                    name="vat"
                    label={`${t('Enter VAT')} %`}
                    placeholder={`${t('Enter VAT')} %`}
                    value={invoice.VAT}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    onChange={onUpdateVAT}
                />
            </div>

            <div className="flex gap-3">
                <LabelWrapper label={t('Select the date for the exchange rate')}>
                    <DatePicker disabled={disableInputs} date={invoice.exchangeDate} setDate={getExchangeRate} />
                </LabelWrapper>
                <LabelWrapper label={t('Exchange rate')}>
                    <LocaleTextField
                        disabled={disableInputs}
                        placeholder={`${toLocaleNumber(0, language, 4, 4)}`}
                        value={invoice.exchangeRate}
                        onChange={onUpdateExchangeRate}
                        minDecimals={4}
                        maxDecimals={4}
                    />
                </LabelWrapper>
            </div>

            {invoice.type !== 'REVERSAL' && (
                <div className="flex max-w-max gap-3 rounded-md bg-layout-transparent p-2">
                    <div className="flex flex-col gap-3">
                        <LabelWrapper label={t('Invoice value')}>
                            <LocaleTextField
                                disabled={disableInputs}
                                placeholder={`${toLocaleNumber(100, language, 2)} ${currencyObj.currency}`}
                                value={invoice.value}
                                onChange={onUpdateInvoiceValue}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                    ),
                                }}
                            />
                        </LabelWrapper>
                        <ValueDisplay
                            label={t('Invoice value (with VAT)')}
                            value={toLocaleNumber(invoice.valueWithVat, language, 2)}
                            alias={currencyObj.currency}
                        />
                    </div>
                    <div className="flex flex-col gap-3">
                        <ValueDisplay
                            label={t('Invoice value in the reference currency')}
                            value={toLocaleNumber(invoice.secondValue, language, 2)}
                            alias={referenceCurrencyObj.currency}
                        />

                        <ValueDisplay
                            label={t('Invoice value in the reference currency (with VAT)')}
                            value={toLocaleNumber(invoice.secondValueWithVat, language, 2)}
                            alias={referenceCurrencyObj.currency}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

InvoiceValues.propTypes = {
    invoice: PropTypes.object,
    disableInputs: PropTypes.bool,
    getExchangeRate: PropTypes.func,
    onUpdateVAT: PropTypes.func,
    onUpdateExchangeRate: PropTypes.func,
    onUpdateInvoiceValue: PropTypes.func,
};

InvoiceValues.defaultProps = {
    invoice: null,
    disableInputs: false,
    getExchangeRate: () => {},
    onUpdateVAT: () => {},
    onUpdateExchangeRate: () => {},
    onUpdateInvoiceValue: () => {},
};

export default InvoiceValues;
