import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import { COLORS, STATUSES } from 'utils/kpiUtils';

import KpiIndexesInput from './kpi-indexes-input';

const KpiIndexesView = ({ indexes, currentIndexes, division, disabled, onChangeIndexes }) => {
    const { t } = useTranslation();
    const { id, divisions } = currentIndexes;
    const divisionIndexes = divisions.find((item) => item.type === division);

    return (
        <div className="overflow-x-auto border-t border-layout-transparent">
            <div
                className="flex max-w-3xl gap-px py-16"
                style={{
                    marginLeft: '3.75rem',
                    marginRight: '5.5rem',
                    marginTop: '4.25rem',
                    minWidth: '32rem',
                }}
            >
                {Object.entries(divisionIndexes)
                    .filter(([key]) => key !== 'type')
                    .map(([name, value]) => {
                        return (
                            <Fragment key={name}>
                                <KpiIndexesInput
                                    name={name}
                                    value={value}
                                    disabled={division !== 'MONTHS' || disabled}
                                    onChangeIndexes={(e) => onChangeIndexes(e, id, indexes)}
                                />
                                <div className="relative flex w-full items-center">
                                    <div className="relative h-px w-full bg-gradient-to-l from-transparent to-layout-transparent-dark">
                                        <div
                                            className="absolute left-0 top-1/2 h-0 w-0 -translate-y-1/2 transform border-b-4 border-r-4 border-t-4 border-transparent"
                                            style={{
                                                borderRightColor: 'var(--layout-lighter)',
                                            }}
                                        />
                                    </div>

                                    <p className="whitespace-nowrap px-3 text-xl font-medium leading-6 opacity-80">
                                        {t(capitalizeFirstLetter(STATUSES[name]))}
                                    </p>
                                    <div
                                        className="absolute bottom-0 left-1/2 h-7 w-7 -translate-x-1/2 transform rounded-full border-2 border-layout-lighter"
                                        style={{
                                            backgroundColor: COLORS[name],
                                        }}
                                    />

                                    <div className="h-px w-full bg-gradient-to-r from-transparent to-layout-transparent-dark">
                                        <div
                                            className="absolute right-0 top-1/2 h-0 w-0 -translate-y-1/2 transform border-b-4 border-l-4 border-t-4 border-transparent"
                                            style={{
                                                borderLeftColor: 'var(--layout-lighter)',
                                            }}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        );
                    })}

                <KpiIndexesInput
                    value={`${t('over')} ${divisionIndexes.idealIndex}`}
                    inputType="display"
                    disabled={true}
                />
            </div>
        </div>
    );
};

KpiIndexesView.propTypes = {
    indexes: PropTypes.array,
    currentIndexes: PropTypes.object,
    division: PropTypes.string,
    disabled: PropTypes.bool,
    onChangeIndexes: PropTypes.func,
};

KpiIndexesView.defaultProps = {
    indexes: [],
    currentIndexes: null,
    division: null,
    disabled: false,
    onChangeIndexes: () => {},
};

export default KpiIndexesView;
