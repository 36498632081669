import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { KanbanSquare, Trash } from 'lucide-react';
import AddIcon from '@material-ui/icons/Add';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import GetAppIcon from '@material-ui/icons/GetApp';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';

import NoImage from '../../../assets/common/no-image.png';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import Gallery from 'components/crm/estate/gallery';
import GalleryEditable from 'components/crm/estate/gallery-editable';
import BasicTooltip from 'components/shared/basic-tooltip';
import Loading from 'components/shared/loading';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import Cashflow from 'pages/crm/contracts/cashflow-contracts';
import PropTypes from 'prop-types';
import {
    ExpandableRow,
    MultiDropdown,
    TableSeparator,
    Tabs,
} from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import {
    crm,
    internalActivity,
    projectInManagement as pmRoute,
    projectManagement,
} from 'routes';
import { errorHandling, formatDate, slugify, toLocaleNumber } from 'utils';
import API from 'utils/axios';
import { getEntityFields } from 'utils/getterFunctions';

import DisplayPaginatedResults from '../display-paginated-results';
import ErrorButton from '../error-button';
import FileDisplay from '../file-display/file-display';
import NoDataPlaceholder from '../no-data-placeholder';
import ProjectDisplay from './project-display';

const entities = [
    'Contacts',
    'Contracts',
    'Immobile',
    'Partners',
    'ContractPM',
];
const mappedColspanOffsets = {
    Contacts: 0,
    Contracts: 2,
    Immobile: 1,
    Partners: 0,
    ContractPM: 2,
};

const CogHeaderConfig = (props) => {
    const { t } = useTranslation();
    const { headers, selectedHeaders, setSelectedHeaders } = props;

    return (
        <div className="inline-flex justify-end">
            <BasicTooltip tip={t('Configure table')}>
                <MultiDropdown
                    variant="black"
                    icon={<SettingsIcon className="text-buttons-text" />}
                    options={headers}
                    selectedOptions={selectedHeaders}
                    setSelectedOptions={(i) => {
                        if (selectedHeaders.indexOf(i) > -1) {
                            setSelectedHeaders(
                                selectedHeaders.filter((opt) => opt !== i),
                            );
                        } else {
                            setSelectedHeaders([...selectedHeaders, i]);
                        }
                    }}
                />
            </BasicTooltip>
        </div>
    );
};

CogHeaderConfig.propTypes = {
    headers: PropTypes.array,
    selectedHeaders: PropTypes.array,
    setSelectedHeaders: PropTypes.func,
};

CogHeaderConfig.defaultProps = {
    headers: [],
    selectedHeaders: [],
    setSelectedHeaders: () => null,
};

const EntityInfo = (props) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const { enqueueSnackbar } = useSnackbar();

    const {
        data: actualData,
        contactInfo,
        partnerInfo,
        projectInfo,
        contractData,
        actualHeaders,
        entity,
        images,
        pmProjectId,
        setRefreshTrigger,
    } = props;

    const { id: entityId, data } = actualData;

    const history = useHistory();
    const [activeTab, setActiveTab] = useState(0);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [initialIndex, setInitialIndex] = useState(0);
    const optionClass = 'text-base text-dark-text mb-2';
    const optionSpanClass = 'text-main-text font-bold';

    const { checkPerm } = useContext(UserContext);
    const { language } = useContext(GlobalContext);

    const canAllContacts = checkPerm([
        {
            permissionId: '6',
            permissionType: 'ALL',
        },
    ]);

    const canViewContactsActivity = checkPerm([
        {
            permissionId: '5',
            permissionType: 'VIEW',
        },
    ]);

    const canAllSIP = checkPerm([
        {
            permissionId: '7',
            permissionType: 'ALL',
        },
    ]);

    const canAllContracts = checkPerm([
        {
            permissionId: '8',
            permissionType: 'ALL',
        },
    ]);

    const canAllPartners = checkPerm([
        {
            permissionId: '4',
            permissionType: 'ALL',
        },
    ]);

    const canViewCRMFiles = checkPerm([
        {
            permissionId: '32',
            permissionType: 'VIEW',
        },
    ]);

    const canAllPmProjects = checkPerm([
        {
            permissionId: '59',
            permissionType: 'ALL',
        },
    ]);

    const canAllPmContracts = checkPerm([
        {
            permissionId: '60',
            permissionType: 'ALL',
        },
    ]);

    const handleDelete = async () => {
        try {
            const params = {
                id: entityId,
            };

            if (entity === entities[2]) {
                await API.delete('immobile', {
                    params,
                });
            }

            if (entity === entities[0]) {
                await API.delete('contact', {
                    params,
                });
            }

            if (entity === entities[3]) {
                await API.delete('partners', {
                    params,
                });
            }

            if (entity === entities[1]) {
                await API.delete('contracts', {
                    params,
                });
            }

            if (entity === entities[4]) {
                await API.delete('contracts_pm', {
                    params,
                });
            }

            setRefreshTrigger((prev) => !prev);

            enqueueSnackbar(t('Deletion completed!'), {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    return (
        <>
            <div className="w-full">
                <div className="mb-8 flex flex-col items-start gap-4">
                    {entity === entities[0] && (
                        <>
                            <Tabs
                                tabs={[t('Contact details')]}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />

                            {!canAllContacts && canViewContactsActivity && (
                                <div className="flex items-center gap-2">
                                    <Button
                                        startIcon={<PersonIcon />}
                                        color="secondary"
                                        onClick={() =>
                                            history.push(
                                                crm.base +
                                                    crm.contactActivity.base +
                                                    '/' +
                                                    entityId,
                                            )
                                        }
                                    >
                                        {t('See detailed activity')}
                                    </Button>
                                    <Button
                                        startIcon={<KanbanSquare />}
                                        color="secondary"
                                        onClick={() =>
                                            history.push(
                                                crm.base +
                                                    crm.contactActivityPipeline
                                                        .base +
                                                    '/' +
                                                    entityId,
                                            )
                                        }
                                    >
                                        {t('See activities pipeline')}
                                    </Button>
                                </div>
                            )}
                            {canAllContacts && (
                                <div className="flex items-center gap-2">
                                    <Button
                                        startIcon={<PersonIcon />}
                                        color="secondary"
                                        onClick={() =>
                                            history.push(
                                                crm.base +
                                                    crm.contactActivity.base +
                                                    '/' +
                                                    entityId,
                                            )
                                        }
                                    >
                                        {t('See detailed activity')}
                                    </Button>
                                    <Button
                                        startIcon={<KanbanSquare />}
                                        color="secondary"
                                        onClick={() =>
                                            history.push(
                                                crm.base +
                                                    crm.contactActivityPipeline
                                                        .base +
                                                    '/' +
                                                    entityId,
                                            )
                                        }
                                    >
                                        {t('See activities pipeline')}
                                    </Button>
                                    <Button
                                        startIcon={<BorderColorIcon />}
                                        color="secondary"
                                        onClick={() =>
                                            history.push(
                                                crm.base +
                                                    crm.contacts.base +
                                                    crm.contacts.update +
                                                    '/' +
                                                    entityId,
                                            )
                                        }
                                    >
                                        {t('Edit details')}
                                    </Button>
                                    <ErrorButton
                                        startIcon={<Trash />}
                                        onClick={() =>
                                            confirm(
                                                t(
                                                    'Are you sure you want to delete?',
                                                ),
                                                () => handleDelete(),
                                            )
                                        }
                                    >
                                        {t('Delete')}
                                    </ErrorButton>
                                </div>
                            )}
                        </>
                    )}

                    {(entity === entities[1] || entity === entities[4]) && (
                        <>
                            <Tabs
                                tabs={[
                                    t('Contract details'),
                                    t('Payments & Rates'),
                                ]}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                            {activeTab === 0 && (
                                <div className="flex items-center gap-2">
                                    {entity === entities[1] &&
                                        canAllPmProjects && (
                                            <Button
                                                startIcon={<AddIcon />}
                                                color="secondary"
                                                onClick={() => {
                                                    history.push({
                                                        pathname:
                                                            projectManagement.base +
                                                            projectManagement
                                                                .projectEntity
                                                                .base +
                                                            projectManagement
                                                                .projectEntity
                                                                .create,
                                                        state: {
                                                            contractId:
                                                                entityId,
                                                            contactId:
                                                                contactInfo?.id ??
                                                                null,
                                                            partnerId:
                                                                partnerInfo?.id ??
                                                                null,
                                                        },
                                                    });
                                                }}
                                            >
                                                {t(
                                                    'Create project based on this contract',
                                                )}
                                            </Button>
                                        )}

                                    {contractData.templatePdf && (
                                        <a
                                            className="flex items-center"
                                            href={contractData.templatePdf}
                                            download
                                            rel="noreferrer"
                                        >
                                            <Button
                                                startIcon={<GetAppIcon />}
                                                color="secondary"
                                            >
                                                {t('Download PDF')}
                                            </Button>
                                        </a>
                                    )}

                                    {((canAllContracts && !pmProjectId) ||
                                        (canAllPmContracts && pmProjectId)) && (
                                        <>
                                            <Button
                                                startIcon={<BorderColorIcon />}
                                                color="secondary"
                                                onClick={() =>
                                                    entity === entities[4]
                                                        ? history.push(
                                                              pmRoute.base +
                                                                  '/' +
                                                                  pmProjectId +
                                                                  pmRoute
                                                                      .projectContracts
                                                                      .base +
                                                                  pmRoute
                                                                      .projectContracts
                                                                      .update +
                                                                  '/' +
                                                                  entityId,
                                                          )
                                                        : history.push(
                                                              crm.base +
                                                                  crm.contracts
                                                                      .base +
                                                                  crm.contracts
                                                                      .update +
                                                                  '/' +
                                                                  entityId,
                                                          )
                                                }
                                            >
                                                {t('Edit details')}
                                            </Button>
                                            <ErrorButton
                                                startIcon={<Trash />}
                                                onClick={() =>
                                                    confirm(
                                                        t(
                                                            'Are you sure you want to delete?',
                                                        ),
                                                        () => handleDelete(),
                                                    )
                                                }
                                            >
                                                {t('Delete')}
                                            </ErrorButton>
                                        </>
                                    )}
                                </div>
                            )}
                            {activeTab === 1 && (
                                <Cashflow
                                    contractId={entityId}
                                    payments={contractData.payments}
                                    paymentDataFromComponent={contractData}
                                    fetchContract={contractData.func(entity.id)}
                                    VAT={contractData.VAT}
                                    pmProjectId={pmProjectId}
                                />
                            )}
                        </>
                    )}

                    {entity === entities[2] && (
                        <>
                            <div className="flex items-center gap-2">
                                {canAllSIP && (
                                    <>
                                        <Button
                                            startIcon={<BorderColorIcon />}
                                            color="secondary"
                                            onClick={() =>
                                                history.push(
                                                    crm.base +
                                                        crm.estate.base +
                                                        crm.estate.update +
                                                        '/' +
                                                        entityId,
                                                )
                                            }
                                        >
                                            {t('Edit details')}
                                        </Button>
                                        <ErrorButton
                                            startIcon={<Trash />}
                                            onClick={() =>
                                                confirm(
                                                    t(
                                                        'Are you sure you want to delete?',
                                                    ),
                                                    () => handleDelete(),
                                                )
                                            }
                                        >
                                            {t('Delete')}
                                        </ErrorButton>
                                    </>
                                )}
                            </div>
                        </>
                    )}

                    {entity === entities[3] && (
                        <>
                            <Tabs
                                tabs={[t('Partner details')]}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                            <div className="flex items-center gap-2">
                                {canAllPartners && (
                                    <>
                                        <Button
                                            startIcon={
                                                <BorderColorIcon className="text-buttons-text" />
                                            }
                                            color="secondary"
                                            onClick={() =>
                                                history.push(
                                                    internalActivity.base +
                                                        internalActivity
                                                            .partners.base +
                                                        internalActivity
                                                            .partners.update +
                                                        '/' +
                                                        entityId,
                                                )
                                            }
                                        >
                                            {t('Edit details')}
                                        </Button>
                                        <ErrorButton
                                            startIcon={<Trash />}
                                            onClick={() =>
                                                confirm(
                                                    t(
                                                        'Are you sure you want to delete?',
                                                    ),
                                                    () => handleDelete(),
                                                )
                                            }
                                        >
                                            {t('Delete')}
                                        </ErrorButton>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {activeTab === 0 && (
                    <div className="grid grid-cols-5 gap-8 pb-10 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
                        {Object.keys(data).map((key, index1) => {
                            // Check if column exists on this record
                            let isColumnEmpty = true;
                            Object.keys(data[key]).every((smallKey) => {
                                if (data[key][smallKey] !== '') {
                                    isColumnEmpty = false;
                                    return false;
                                }
                                return true;
                            });

                            // Display column with fields
                            if (!isColumnEmpty)
                                return (
                                    <div key={key}>
                                        <h4 className="mb-5 whitespace-nowrap">
                                            {actualHeaders[index1].displayName}
                                        </h4>
                                        {Object.keys(data[key]).map(
                                            (smallKey, index2) => (
                                                <React.Fragment key={index2}>
                                                    <p
                                                        key={smallKey}
                                                        className={optionClass}
                                                    >
                                                        {
                                                            actualHeaders[
                                                                index1
                                                            ].fields[index2]
                                                        }
                                                        :{' '}
                                                        {typeof data[key][
                                                            smallKey
                                                        ] !== 'object' &&
                                                            (() => {
                                                                const filedName =
                                                                    Object.keys(
                                                                        data[
                                                                            key
                                                                        ],
                                                                    )[index2];

                                                                const indexFieldName =
                                                                    actualHeaders[
                                                                        index1
                                                                    ].names.findIndex(
                                                                        (f) =>
                                                                            f ===
                                                                            filedName,
                                                                    );

                                                                const selectedTypeOption =
                                                                    actualHeaders[
                                                                        index1
                                                                    ]
                                                                        .selectedTypeOption[
                                                                        indexFieldName
                                                                    ];

                                                                if (
                                                                    selectedTypeOption ===
                                                                        'Lei' ||
                                                                    selectedTypeOption ===
                                                                        '€'
                                                                ) {
                                                                    return (
                                                                        <span
                                                                            className={
                                                                                optionSpanClass
                                                                            }
                                                                        >
                                                                            {toLocaleNumber(
                                                                                data[
                                                                                    key
                                                                                ][
                                                                                    smallKey
                                                                                ],
                                                                                language,
                                                                                2,
                                                                            )}
                                                                        </span>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <span
                                                                            className={
                                                                                optionSpanClass
                                                                            }
                                                                        >
                                                                            {
                                                                                data[
                                                                                    key
                                                                                ][
                                                                                    smallKey
                                                                                ]
                                                                            }
                                                                        </span>
                                                                    );
                                                                }
                                                            })()}
                                                        {typeof data[key][
                                                            smallKey
                                                        ] === 'object' &&
                                                            (() => {
                                                                const filedName =
                                                                    Object.keys(
                                                                        data[
                                                                            key
                                                                        ],
                                                                    )[index2];

                                                                const indexFieldName =
                                                                    actualHeaders[
                                                                        index1
                                                                    ].names.findIndex(
                                                                        (f) =>
                                                                            f ===
                                                                            filedName,
                                                                    );

                                                                const type =
                                                                    actualHeaders[
                                                                        index1
                                                                    ].types[
                                                                        indexFieldName
                                                                    ];

                                                                if (
                                                                    Array.isArray(
                                                                        data[
                                                                            key
                                                                        ][
                                                                            smallKey
                                                                        ],
                                                                    )
                                                                ) {
                                                                    if (
                                                                        type ===
                                                                        'Image'
                                                                    ) {
                                                                        return (
                                                                            <div className="mt-1">
                                                                                <FileDisplay
                                                                                    files={
                                                                                        data[
                                                                                            key
                                                                                        ][
                                                                                            smallKey
                                                                                        ]
                                                                                    }
                                                                                    purpose={
                                                                                        type
                                                                                    }
                                                                                    view={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        );
                                                                    } else if (
                                                                        type ===
                                                                        'File'
                                                                    ) {
                                                                        return (
                                                                            <div className="mt-1">
                                                                                <FileDisplay
                                                                                    utilityOptions={{
                                                                                        canDelete: false,
                                                                                        canCopyLink: true,
                                                                                        canDownload: true,
                                                                                    }}
                                                                                    files={
                                                                                        data[
                                                                                            key
                                                                                        ][
                                                                                            smallKey
                                                                                        ]
                                                                                    }
                                                                                    purpose={
                                                                                        type
                                                                                    }
                                                                                    view={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                } else {
                                                                    return (
                                                                        <span className="text-main-text">
                                                                            -
                                                                        </span>
                                                                    );
                                                                }
                                                            })()}
                                                    </p>
                                                </React.Fragment>
                                            ),
                                        )}
                                    </div>
                                );
                            else return <></>;
                        })}

                        {entity === entities[2] && canViewCRMFiles && (
                            <div>
                                <h4 className="mb-5 whitespace-nowrap">
                                    {t('Gallery')}
                                </h4>
                                <GalleryEditable
                                    gallery={images}
                                    photoClickCallback={(photoIndex) => {
                                        setInitialIndex(photoIndex);
                                    }}
                                    setOpen={setGalleryOpen}
                                    view
                                />
                                {galleryOpen && (
                                    <Gallery
                                        open={galleryOpen}
                                        setOpen={setGalleryOpen}
                                        initialIndex={initialIndex}
                                        urlList={images.map(
                                            (image) => image.url,
                                        )}
                                    />
                                )}
                            </div>
                        )}

                        {(entity === entities[1] || entity === entities[4]) && (
                            <>
                                <div>
                                    <h4 className="mb-5 whitespace-nowrap">
                                        {t('Contract info')}
                                    </h4>

                                    <p className={optionClass}>
                                        Status:{' '}
                                        <span className={optionSpanClass}>
                                            {t(
                                                contractData.info.status.slice(
                                                    0,
                                                    1,
                                                ) +
                                                    contractData.info.status
                                                        .slice(1)
                                                        .toLowerCase(),
                                            )}
                                        </span>
                                    </p>

                                    <p className={optionClass}>
                                        {t('Updated on')}:{' '}
                                        <span className={optionSpanClass}>
                                            {formatDate(
                                                contractData.info.createdAt,
                                            )}
                                        </span>
                                    </p>
                                </div>

                                {contactInfo && (
                                    <div>
                                        <h4 className="mb-5 whitespace-nowrap">
                                            {t('Contact info')}
                                        </h4>

                                        <p className={optionClass}>
                                            {t('Name')}:{' '}
                                            <span className={optionSpanClass}>
                                                {contactInfo.name}
                                            </span>
                                        </p>

                                        <p className={optionClass}>
                                            Email:{' '}
                                            <span className={optionSpanClass}>
                                                {contactInfo.email}
                                            </span>
                                        </p>

                                        <p className={optionClass}>
                                            {t('Phone')}:{' '}
                                            <span className={optionSpanClass}>
                                                {contactInfo.phone}
                                            </span>
                                        </p>
                                        <p className={optionClass + ' mb-8'}>
                                            {t('PIN')}:{' '}
                                            <span className={optionSpanClass}>
                                                {contactInfo.CNP}
                                            </span>
                                        </p>

                                        {canAllContacts && (
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    history.push(
                                                        crm.base +
                                                            crm.contacts.base +
                                                            crm.contacts
                                                                .update +
                                                            '/' +
                                                            contactInfo.id,
                                                    )
                                                }
                                            >
                                                {t('See contact')}
                                            </Button>
                                        )}
                                    </div>
                                )}

                                {partnerInfo && (
                                    <div>
                                        <h4 className="mb-5 whitespace-nowrap">
                                            {t('Partner info')}
                                        </h4>

                                        <p className={optionClass + ' mb-8'}>
                                            {t('Name')}:{' '}
                                            <span className={optionSpanClass}>
                                                {partnerInfo.name}
                                            </span>
                                        </p>

                                        {canAllPartners && (
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    history.push(
                                                        internalActivity.base +
                                                            internalActivity
                                                                .partners.base +
                                                            internalActivity
                                                                .partners
                                                                .update +
                                                            '/' +
                                                            partnerInfo.id,
                                                    )
                                                }
                                            >
                                                {t('See partner')}
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </>
                        )}

                        {entity === entities[0] && projectInfo && (
                            <ProjectDisplay project={projectInfo} />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

EntityInfo.propTypes = {
    data: PropTypes.object,
    contactInfo: PropTypes.object,
    partnerInfo: PropTypes.object,
    projectInfo: PropTypes.object,
    contractData: PropTypes.object,
    actualHeaders: PropTypes.array,
    entity: PropTypes.string,
    images: PropTypes.array,
    setOpen: PropTypes.func,
    initialIndex: PropTypes.number,
    setInitialIndex: PropTypes.func,
    urlList: PropTypes.array,
    setUrlList: PropTypes.func,
    pmProjectId: PropTypes.string,
    setRefreshTrigger: PropTypes.func,
};

EntityInfo.defaultProps = {
    data: null,
    contactInfo: {},
    partnerInfo: {},
    projectInfo: {},
    contractData: {},
    actualHeaders: [],
    entity: '',
    images: [],
    setOpen: () => null,
    initialIndex: 0,
    setInitialIndex: () => null,
    urlList: [],
    setUrlList: () => null,
    pmProjectId: null,
    setRefreshTrigger: () => null,
};

const TableDisplay = (props) => {
    const {
        entity,
        setOpen,
        query,
        filterParameters,
        initialIndex,
        setInitialIndex,
        urlList,
        setUrlList,
        pmProjectId,
    } = props;

    const { theme } = useContext(GlobalContext);
    const colspanOffset = mappedColspanOffsets[entity];

    const [headers, setHeaders] = useState([]);
    const [fields, setFields] = useState([]);
    const [records, setRecords] = useState([]);
    const [actualData, setActualData] = useState([]);
    const [actualHeaders, setActualHeaders] = useState([]);
    const [loadingHeaders, setLoadingHeaders] = useState(true);
    const [loadingRecords, setLoadingRecords] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState([]);

    const [images, setImages] = useState([]);

    const [refreshTrigger, setRefreshTrigger] = useState(false);

    const { t } = useTranslation();

    const fetchContract = async (contractId) => {
        try {
            let res;
            if (entity === 'ContractPM') {
                res = await API.get(`contract_pm/${contractId}`);
            } else {
                res = await API.get(`contract/${contractId}`);
            }

            return res.data.contract;
        } catch (error) {
            console.error(error);
            return {};
        }
    };

    const initializeQueryResults = (response) => {
        // If is immobile than we will have an array with images
        const newImages = [];

        let rr;
        if (entities.indexOf(entity) === 0)
            rr = [...response.data.data.contacts];
        if (entities.indexOf(entity) === 1 || entities.indexOf(entity) === 4)
            rr = [...response.data.contracts];
        if (entities.indexOf(entity) === 2) rr = [...response.data.immobile];
        if (entities.indexOf(entity) === 3)
            rr = [...response.data.formattedPartners];

        const newRecords = rr.map((row) => {
            let parsedData =
                typeof row.data === 'string' ? JSON.parse(row.data) : row.data;
            if ('data' in parsedData)
                parsedData =
                    typeof parsedData.data === 'string'
                        ? JSON.parse(parsedData.data)
                        : parsedData.data;

            let aux = {};

            if (row.images) {
                newImages.push(
                    row.images.map((url, index) => ({
                        url,
                        id: row.ImmobileImages[index].id,
                    })),
                );
            }

            for (const [
                headerColumnIndex,
                headerColumn,
            ] of actualHeaders.entries()) {
                const oldColumn = {
                    ...parsedData[slugify(headerColumn.displayName)],
                };
                let newColumn = {};

                for (const [
                    headerFieldIndex,
                    headerField,
                ] of headerColumn.fields.entries()) {
                    const slug = slugify(headerField);
                    let value = {};

                    if (slug in oldColumn) {
                        value = oldColumn[slug];
                        const type = headerColumn?.types[headerFieldIndex];

                        if (type === 'Reference') value = '-';
                        else if (type === 'Date') value = formatDate(value);
                        else if (type === 'Selector')
                            value =
                                fields?.objectToRender[headerColumnIndex]
                                    ?.fields[headerFieldIndex]?.options[
                                    value[0]
                                ];
                        else if (
                            type === 'Toggle' &&
                            typeof value !== 'undefined'
                        )
                            value =
                                fields?.objectToRender[headerColumnIndex]
                                    ?.fields[headerFieldIndex]?.options[
                                    value === true ? 0 : 1
                                ];
                        else if (type === 'File') {
                            value =
                                value.map((v) => v.url).length +
                                ' ' +
                                t('file/s');
                        } else if (type === 'Image') {
                            value =
                                value.map((v) => v.url).length +
                                ' ' +
                                t('image/s');
                        }
                    } else {
                        value = '';
                    }

                    newColumn = {
                        ...newColumn,
                        [slug]: value,
                    };
                }

                aux = {
                    ...aux,
                    ...newColumn,
                };
            }

            return aux;
        });

        setRecords(newRecords);
        setImages(newImages);

        const newActualData = rr.map((row) => {
            let parsedData =
                typeof row.data === 'string' ? JSON.parse(row.data) : row.data;
            if ('data' in parsedData)
                parsedData =
                    typeof parsedData.data === 'string'
                        ? JSON.parse(parsedData.data)
                        : parsedData.data;
            let aux = {};

            for (const [
                headerColumnIndex,
                headerColumn,
            ] of actualHeaders.entries()) {
                const columnSlug = slugify(headerColumn.displayName);
                const oldColumn = {
                    ...parsedData[slugify(headerColumn.displayName)],
                };
                let newColumn = {};

                for (const [
                    headerFieldIndex,
                    headerField,
                ] of headerColumn.fields.entries()) {
                    const fieldSlug = slugify(headerField);
                    let value = {};

                    if (fieldSlug in oldColumn) {
                        value = oldColumn[fieldSlug];
                        const type = headerColumn.types[headerFieldIndex];

                        if (type === 'Reference') value = '-';
                        else if (type === 'Date') value = formatDate(value);
                        else if (type === 'Selector')
                            value =
                                fields?.objectToRender[headerColumnIndex]
                                    ?.fields[headerFieldIndex]?.options[
                                    value[0]
                                ];
                        else if (
                            type === 'Toggle' &&
                            typeof value !== 'undefined'
                        )
                            value =
                                fields?.objectToRender[headerColumnIndex]
                                    ?.fields[headerFieldIndex]?.options[
                                    value === true ? 0 : 1
                                ];
                    } else {
                        value = '';
                    }

                    newColumn = {
                        ...newColumn,
                        [fieldSlug]: value,
                    };
                }

                aux[columnSlug] = newColumn;
            }

            /**
             * It's a contract that has on offer and a contact assigned to it or just a contact
             */
            let contactInfo = null;

            /**
             * It's a contract that has a partner assigned to it
             */
            let partnerInfo = null;

            /**
             * It's a contract and we hade to pass the payment data for cashflow
             */
            const contractData = {
                payments: [],
                func: () => {},
                VAT: null,
                templatePdf: null,
                info: {
                    status: 'DRAFT',
                    // sentDate: null,
                },
            };

            if (
                entities.indexOf(entity) === 1 ||
                entities.indexOf(entity) === 4
            ) {
                contractData.payments = row.forecastedPayments;
                contractData.func = () => fetchContract;
                contractData.VAT = row.TVA;
                contractData.templatePdf = row.templatePdf;

                //TODO Change this when the backend is done
                contractData.info = row.statusHistory.at(-1);
                // contractData.info = { status: 'SENT', createdAt: new Date() };

                let contactData;
                let partnerData;
                if (row.offer) {
                    if (typeof row.offer.contact.data === 'string') {
                        contactData = JSON.parse(row.offer.contact.data);
                    } else {
                        contactData = row.offer.contact.data;
                    }
                    if ('data' in contactData)
                        contactData = JSON.parse(contactData.data);
                    const standardKey =
                        'standare' in contactData ? 'standare' : 'standard';

                    contactInfo = {
                        id: row.offer.contact.id,
                        name: contactData[standardKey].name || '',
                        email: contactData[standardKey].email || '',
                        phone: contactData[standardKey].phone_number || '',
                        CNP: contactData[standardKey].cnp || '',
                    };
                } else if (row.contact) {
                    if (typeof row.contact.data === 'string') {
                        contactData = JSON.parse(row.contact.data);
                    } else {
                        contactData = row.contact.data;
                    }
                    if ('data' in contactData)
                        contactData = JSON.parse(contactData.data);
                    const standardKey =
                        'standare' in contactData ? 'standare' : 'standard';

                    contactInfo = {
                        id: row.contact.id,
                        name: contactData[standardKey].name || '',
                        email: contactData[standardKey].email || '',
                        phone: contactData[standardKey].phone_number || '',
                        CNP: contactData[standardKey].cnp || '',
                    };
                } else if (row.Partners) {
                    if (typeof row.Partners.data === 'string') {
                        partnerData = JSON.parse(row.Partners.data);
                    } else {
                        partnerData = row.Partners.data;
                    }

                    if ('data' in partnerData)
                        partnerData = JSON.parse(partnerData.data);
                    const standardKey =
                        'standare' in partnerData ? 'standare' : 'standard';

                    partnerInfo = {
                        id: row.Partners.id,
                        name: partnerData[standardKey].name || '',
                    };
                }
            }

            /**
             * It's a contact and we hade to pass the project data for display
             */
            let projectInfo = null;

            if (
                entities.indexOf(entity) === 0 &&
                row.pm_planningOverviewProject
            ) {
                projectInfo = {
                    id: row.pm_planningOverviewProject.id,
                    name: row.pm_planningOverviewProject.name,
                    createAt: row.pm_planningOverviewProject.createAt,
                    updateAt: row.pm_planningOverviewProject.updateAt,
                    teams: row.pm_planningOverviewProject.teams,
                };
            }

            return {
                id: row.id,
                data: aux,
                contactInfo,
                partnerInfo,
                projectInfo,
                contractData,
            };
        });

        setActualData(newActualData);
    };

    const getData = async (currentPage, perPage, controller) => {
        let response;
        if (entities.indexOf(entity) === 4) {
            response = await API.get('project_contracts_pm', {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,
                    projectId: pmProjectId,
                    ...filterParameters,
                },
                signal: controller.signal,
            });
        } else {
            response = await API.get('/' + entity.toLowerCase(), {
                params: {
                    currentPage,
                    perPage,
                    pagesToLoad: 1,
                    ...filterParameters,
                },
                signal: controller.signal,
            });
        }

        initializeQueryResults(response);

        if (entities.indexOf(entity) === 0) return response.data.data.length;

        return response.data.length;
    };

    const getDataSearch = async (currentPage, perPage, controller) => {
        const response = await API.get('/search' + entity, {
            params: {
                currentPage,
                perPage,
                pagesToLoad: 1,
                text: query,
                ...filterParameters,
            },
            signal: controller.signal,
        });

        let formattedResponse = {};

        if (entities.indexOf(entity) === 3)
            formattedResponse = {
                ...response,
                data: {
                    formattedPartners: response.data.data.partners,
                    length: response.data.data.length,
                },
            };
        else formattedResponse = response;

        initializeQueryResults(formattedResponse);

        if (entities.indexOf(entity) === 0) return response.data.data.length;

        return response.data.length;
    };

    useEffect(() => {
        (async () => {
            try {
                // Creating all table headers
                const fields = await getEntityFields(entity);

                // All fields as strings
                const newHeaders = [];
                // All columns as strings, with all the specific fields
                const newActualHeaders = [];
                // Selected from the cog fields that appear as table headers, initially half of them appear
                const newSelectedHeaders = [];

                for (const obj of fields.objectToRender) {
                    const colFields = [];
                    const colTypes = [];
                    const colNames = [];
                    const colSelectedTypeOption = [];

                    for (const field of obj.fields) {
                        colNames.push(field.name);
                        colFields.push(field.displayName);
                        colTypes.push(field.type);
                        colSelectedTypeOption.push(field.selectedTypeOptions);
                        newHeaders.push(field.displayName);
                    }

                    newActualHeaders.push({
                        displayName: obj.displayName,
                        fields: colFields,
                        types: colTypes,
                        names: colNames,
                        selectedTypeOption: colSelectedTypeOption,
                    });
                }

                // Max 5 table headers
                for (let i = 0; i < Math.min(newHeaders.length / 2, 5); i++) {
                    newSelectedHeaders.push(i);
                }

                setHeaders(newHeaders);
                setActualHeaders(newActualHeaders);
                setSelectedHeaders(newSelectedHeaders);
                setFields(fields);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingHeaders(false);
            }
        })();
    }, []);

    if (loadingHeaders) {
        return <Loading />;
    }

    return (
        <DisplayPaginatedResults
            query={query}
            loading={loadingRecords}
            setLoading={setLoadingRecords}
            getFunction={getData}
            getSearchFunction={getDataSearch}
            refreshList={[...Object.values(filterParameters), refreshTrigger]}
        >
            <Fragment>
                <div className="mb-4">
                    <CogHeaderConfig
                        headers={headers}
                        selectedHeaders={selectedHeaders}
                        setSelectedHeaders={setSelectedHeaders}
                    />
                </div>

                <div className="relative z-50">
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {entity === entities[2] && (
                                        <TableCell>Thumbnail</TableCell>
                                    )}
                                    {headers.map((head, index) => (
                                        <React.Fragment key={index}>
                                            {selectedHeaders.indexOf(index) >=
                                            0 ? (
                                                <TableCell>
                                                    <div className="inline-flex items-center">
                                                        {t(head)}
                                                    </div>
                                                </TableCell>
                                            ) : (
                                                <React.Fragment
                                                    key={index}
                                                ></React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    {entity === entities[0] && (
                                        <TableCell>{t('Project')}</TableCell>
                                    )}
                                    {(entity === entities[1] ||
                                        entity === entities[4]) && (
                                        <>
                                            <TableCell>Status</TableCell>
                                            <TableCell>
                                                {t('Updated on')}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableSeparator />
                                {loadingRecords ? (
                                    <TableRow>
                                        <TableCell
                                            colSpan="100%"
                                            padding="none"
                                        >
                                            <div className="flex h-64 w-full items-center justify-center rounded-md bg-layout-transparent p-20">
                                                <CircularProgress />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ) : records.length &&
                                  selectedHeaders.length > 0 ? (
                                    records.map((record, index) => (
                                        <ExpandableRow
                                            key={index}
                                            colSpan={
                                                selectedHeaders.length -
                                                1 +
                                                colspanOffset
                                            }
                                            row={
                                                <>
                                                    {entity === entities[2] && (
                                                        <TableCell>
                                                            <div
                                                                className="h-12 w-20 rounded-md bg-layout-transparent bg-cover bg-center"
                                                                style={{
                                                                    backgroundImage: `url('${
                                                                        images[
                                                                            index
                                                                        ][0]
                                                                            ?.url ??
                                                                        NoImage
                                                                    }')`,
                                                                }}
                                                            />
                                                        </TableCell>
                                                    )}

                                                    {Object.keys(record).map(
                                                        (key, index) => (
                                                            <React.Fragment
                                                                key={index}
                                                            >
                                                                {selectedHeaders.indexOf(
                                                                    index,
                                                                ) >= 0 ? (
                                                                    <TableCell>
                                                                        {typeof record[
                                                                            key
                                                                        ] !==
                                                                            'object' &&
                                                                            record[
                                                                                key
                                                                            ]}
                                                                    </TableCell>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </React.Fragment>
                                                        ),
                                                    )}

                                                    {entity === entities[0] && (
                                                        <TableCell>
                                                            {actualData[index]
                                                                .projectInfo
                                                                ?.name ??
                                                                `${t('Not assigned to a project')}`}
                                                        </TableCell>
                                                    )}

                                                    {(entity === entities[1] ||
                                                        entity ===
                                                            entities[4]) && (
                                                        <>
                                                            <TableCell>
                                                                {t(
                                                                    actualData[
                                                                        index
                                                                    ].contractData.info.status.slice(
                                                                        0,
                                                                        1,
                                                                    ) +
                                                                        actualData[
                                                                            index
                                                                        ].contractData.info.status
                                                                            .slice(
                                                                                1,
                                                                            )
                                                                            .toLowerCase(),
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatDate(
                                                                    actualData[
                                                                        index
                                                                    ]
                                                                        .contractData
                                                                        .info
                                                                        .createdAt,
                                                                )}
                                                            </TableCell>
                                                        </>
                                                    )}
                                                </>
                                            }
                                            collapse={
                                                <EntityInfo
                                                    id={actualData[index].id}
                                                    data={actualData[index]}
                                                    partnerInfo={
                                                        actualData[index]
                                                            .partnerInfo
                                                    }
                                                    contactInfo={
                                                        actualData[index]
                                                            .contactInfo
                                                    }
                                                    projectInfo={
                                                        actualData[index]
                                                            .projectInfo
                                                    }
                                                    contractData={
                                                        actualData[index]
                                                            .contractData
                                                    }
                                                    actualHeaders={
                                                        actualHeaders
                                                    }
                                                    entity={entity}
                                                    images={images[index]}
                                                    setOpen={setOpen}
                                                    initialIndex={initialIndex}
                                                    setInitialIndex={
                                                        setInitialIndex
                                                    }
                                                    urlList={urlList}
                                                    setUrlList={setUrlList}
                                                    pmProjectId={pmProjectId}
                                                    setRefreshTrigger={
                                                        setRefreshTrigger
                                                    }
                                                />
                                            }
                                        />
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan="100%"
                                            padding="none"
                                        >
                                            <NoDataPlaceholder />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Fragment>
        </DisplayPaginatedResults>
    );
};

TableDisplay.propTypes = {
    entity: PropTypes.oneOf(entities),
    setOpen: PropTypes.func,
    initialIndex: PropTypes.number,
    setInitialIndex: PropTypes.func,
    urlList: PropTypes.array,
    setUrlList: PropTypes.func,
    pmProjectId: PropTypes.string,
    query: PropTypes.string,
    filterParameters: PropTypes.object,
};

TableDisplay.defaultProps = {
    entity: entities[0],
    setOpen: () => null,
    initialIndex: 0,
    setInitialIndex: () => null,
    urlList: [],
    setUrlList: () => null,
    pmProjectId: null,
    query: '',
    filterParameters: {},
};

export default TableDisplay;
