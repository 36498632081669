import React from 'react';

import PropTypes from 'prop-types';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { generateUUID, getTextWidth } from 'utils';
import { CHART_AXIS_HEIGHT, CHART_WIDTH_OFFSET, getIndexesAsMap, getStops } from 'utils/kpiUtils';

import KpiChartDot from './kpi-chart-dot';
import KpiChartTickX from './kpi-chart-tick-x';
import KpiChartTickY from './kpi-chart-tick-y';
import KpiChartTooltipCursor from './kpi-chart-tooltip-cursor';

const KpiChart = ({ kpi, division, padding, detailed }) => {
    const lineId = generateUUID();
    const layoutId = generateUUID();

    const { data, indexes } = kpi;
    const currentIndexes = indexes.at(-1).divisions.find((d) => d.type === division);

    const indexesArr = getIndexesAsMap(currentIndexes);
    const width =
        indexesArr.reduce((acc, cur) => {
            const width = getTextWidth(cur, 'text-base');
            return width > acc ? width : acc;
        }, 0) + CHART_WIDTH_OFFSET;
    const stops = getStops(data[division]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data[division]}>
                <CartesianGrid stroke="var(--layout-transparent-dark)" syncWithTicks />
                <XAxis
                    dataKey="label"
                    tick={<KpiChartTickX data={data[division]} detailed={detailed} />}
                    tickLine={{ stroke: 'var(--layout-transparent-dark)' }}
                    axisLine={false}
                    interval={'preserveStartEnd'}
                    minTickGap={1}
                    padding={{ left: 0, right: padding }}
                    height={CHART_AXIS_HEIGHT}
                />
                <YAxis
                    tick={<KpiChartTickY detailed={detailed} indexes={currentIndexes} width={width} />}
                    tickLine={{ stroke: 'var(--layout-transparent-dark)' }}
                    axisLine={false}
                    interval={'preserveStartEnd'}
                    minTickGap={1}
                    padding={{ top: CHART_AXIS_HEIGHT }}
                    ticks={indexesArr}
                    tickSize={detailed ? CHART_WIDTH_OFFSET : padding}
                    width={detailed ? width : padding}
                />

                <defs>
                    <linearGradient id={lineId} x1="0" y1="0" x2="1" y2="0">
                        {stops.map((stop, index) => (
                            <stop key={index} {...stop} />
                        ))}
                    </linearGradient>
                    <linearGradient id={layoutId} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="var(--layout-transparent-dark)" stopOpacity={1} />
                        <stop offset="100%" stopColor="var(--layout-transparent-dark)" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <Tooltip cursor={<KpiChartTooltipCursor />} content={() => null} />
                <Area
                    type="natural"
                    dataKey="value"
                    stroke={`url(#${lineId})`}
                    fill={`url(#${layoutId})`}
                    connectNulls
                    animationDuration={300}
                    dot={(props) => <KpiChartDot {...props} indexes={currentIndexes} />}
                    activeDot={(props) => <KpiChartDot {...props} active indexes={currentIndexes} />}
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};

KpiChart.propTypes = {
    kpi: PropTypes.object,
    division: PropTypes.string,
    padding: PropTypes.number,
    detailed: PropTypes.bool,
};

KpiChart.defaultProps = {
    kpi: null,
    division: null,
    padding: 5,
    detailed: false,
};

export default KpiChart;
