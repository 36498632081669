import React from 'react';

import { SendRounded } from '@material-ui/icons';

import { Button, CircularProgress } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const TimelineEmailNotificationButtons = ({
    cancel,
    send,
    disableSend,
    step,
    handleChangeNotifyStep,
    handleNextStep,
    canGoNext,
}) => {
    const { t } = useTranslation();

    return (
        <div className="w-full rounded-b-xl p-4">
            <div className="flex justify-between">
                <Button onClick={cancel} disabled={disableSend}>
                    {t('CANCEL')}
                </Button>
                <div className="flex items-center gap-8">
                    {step == 0 && (
                        <Button
                            onClick={() => {
                                handleNextStep();
                                handleChangeNotifyStep(1);
                            }}
                            disabled={canGoNext || disableSend}
                        >
                            {t('NEXT')}
                        </Button>
                    )}
                    {step == 1 && (
                        <Button
                            disabled={disableSend}
                            onClick={() => handleChangeNotifyStep(0)}
                        >
                            {t('BACK')}
                        </Button>
                    )}
                    {step === 1 && (
                        <Button
                            color="primary"
                            onClick={send}
                            disabled={disableSend}
                            endIcon={
                                disableSend ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <SendRounded />
                                )
                            }
                        >
                            {t('SEND')}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
TimelineEmailNotificationButtons.propTypes = {
    send: PropTypes.func,
    cancel: PropTypes.func,
    disableSend: PropTypes.bool,
    step: PropTypes.number,
    handleChangeNotifyStep: PropTypes.func,
    handleNextStep: PropTypes.func,
    canGoNext: PropTypes.bool,
};

TimelineEmailNotificationButtons.defaultProps = {
    send: () => {},
    cancel: () => {},
    disableSend: false,
    step: 0,
    handleChangeNotifyStep: () => {},
    handleNextStep: () => {},
    canGoNext: false,
};

export default TimelineEmailNotificationButtons;
