import React, { useState } from 'react';

import { CircleCheck, CircleX } from 'lucide-react';

import { Button } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const ReportModalInvoices = ({ open, onClose, onFilter, revenues, expenses, filteredOptions, setFilteredOptions }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [selection, setSelection] = useState(filteredOptions);

    const mappedOptionsList = {
        revenues,
        expenses,
    };

    const handleMultiDropdownChange = (i, type) => {
        const value = mappedOptionsList[type][i].id;
        let newOptions = [...selection[type]];

        const isPresent = newOptions.indexOf(value) >= 0;
        if (isPresent) newOptions = newOptions.filter((v) => v !== value);
        else newOptions.push(value);

        setSelection((prev) => ({ ...prev, [type]: newOptions }));
    };

    const handleFinish = () => {
        if (!selection.revenues.length && !selection.expenses.length) {
            enqueueSnackbar(t('You must select at least one revenue or one expense!'), { variant: 'error' });
            return;
        }

        setFilteredOptions(selection);
        onFilter(selection);
        onClose();
    };

    return (
        <CustomModal open={open}>
            <div className="flex w-2xl flex-col items-center gap-16 p-10 sm:w-full">
                <h2>{t('Select data')}</h2>

                <div className="flex w-full flex-col gap-4">
                    <LabelWrapper label={t('Select revenues')}>
                        <MultiDropdown
                            options={revenues.map((revenue) => revenue.name)}
                            placeholder={t('Select revenues')}
                            selectedOptions={selection.revenues.map((option) =>
                                revenues.findIndex((o) => o.id === option),
                            )}
                            setSelectedOptions={(i) => handleMultiDropdownChange(i, 'revenues')}
                        />
                    </LabelWrapper>

                    <LabelWrapper label={t('Select expenses')}>
                        <MultiDropdown
                            options={expenses.map((expense) => expense.name)}
                            placeholder={t('Select expenses')}
                            selectedOptions={selection.expenses.map((option) =>
                                expenses.findIndex((o) => o.id === option),
                            )}
                            setSelectedOptions={(i) => handleMultiDropdownChange(i, 'expenses')}
                        />
                    </LabelWrapper>
                </div>

                <div className="flex w-full justify-end gap-4">
                    <Button onClick={onClose} startIcon={<CircleX />} color="secondary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={handleFinish} startIcon={<CircleCheck />} color="secondary">
                        {t('Complete')}
                    </Button>
                </div>
            </div>
        </CustomModal>
    );
};

ReportModalInvoices.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFilter: PropTypes.func,
    revenues: PropTypes.array,
    expenses: PropTypes.array,
    filteredOptions: PropTypes.array,
    setFilteredOptions: PropTypes.func,
};

ReportModalInvoices.defaultProps = {
    open: false,
    onClose: () => {},
    onFilter: () => {},
    revenues: [],
    expenses: [],
    filteredOptions: { type: 'COMPANY', options: [] },
    setFilteredOptions: () => {},
};

export default ReportModalInvoices;
