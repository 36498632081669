import React from 'react';

import PropTypes from 'prop-types';

const labelStyle = {
    fontSize: '10px',
    textAlign: 'left',
    marginBottom: '2px',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: 'calc(1rem + 1px)',
};

export const LabelWrapper = (props) => {
    const { children, label, variant, style } = props;
    return (
        <div className="relative w-full">
            <div
                style={{
                    ...labelStyle,
                    ...style,
                }}
                className={variant ? 'text-dark-text' : 'text-main-text'}
            >
                {label}
            </div>

            {children}
        </div>
    );
};
LabelWrapper.propTypes = {
    label: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.arrayOf(PropTypes.element)]),
    variant: PropTypes.string,
    style: PropTypes.object,
};
LabelWrapper.defaultProps = {
    label: '',
    children: <></>,
    white: false,
    style: {},
};
