import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

const handleScroll = (node) => {
    const height = node.clientHeight;
    const scrollHeight = node.scrollHeight;

    if (scrollHeight > height) {
        const position = node.scrollTop;

        if (position !== 0) node.firstChild.style.opacity = 1;
        else node.firstChild.style.opacity = 0;

        if (position !== scrollHeight - height) node.lastChild.style.opacity = 1;
        else node.lastChild.style.opacity = 0;
    }
};

const ScrollableContainer = ({ children }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) handleScroll(ref.current);
    }, []);

    return (
        <div
            className="relative flex h-full w-full flex-col overflow-y-auto"
            ref={ref}
            onScroll={(e) => handleScroll(e.currentTarget)}
        >
            <div className="pointer-events-none sticky top-0 h-0 opacity-0 transition-opacity duration-150">
                <div
                    className="h-20"
                    style={{
                        background: 'linear-gradient(to bottom, var(--layout-transparent), transparent)',
                    }}
                />
            </div>

            <div className="flex-1">{children}</div>

            <div className="pointer-events-none sticky bottom-0 h-0 opacity-0 transition-opacity duration-150">
                <div
                    className="-mt-20 h-20"
                    style={{
                        background: 'linear-gradient(to top, var(--layout-transparent), transparent)',
                    }}
                />
            </div>
        </div>
    );
};

ScrollableContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

ScrollableContainer.defaultProps = {
    children: null,
};

export default ScrollableContainer;
