import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { kpi as kpiRoutes } from 'routes';

const useStyles = makeStyles(() => {
    return {
        details: {
            padding: '0.25rem 1rem',
            fontSize: '1.25rem',
            border: '1px solid var(--layout-transparent)',
            backgroundColor: `var(--layout-transparent)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--layout-transparent-dark)`,
            },
        },
    };
});

const KpiCardFooter = ({ kpi, type }) => {
    const history = useHistory();

    const { id, type: kpiType } = kpi;
    const { name: typeName } = kpiType;

    const { details } = useStyles();
    const { t } = useTranslation();

    const handleViewKpi = () => history.push(kpiRoutes.base + kpiRoutes[type.toLowerCase()].base + '/' + id);

    return (
        <div className="flex items-center justify-between gap-6 py-4 pl-6 pr-4">
            <p className="text-lg leading-4 opacity-40">{typeName}</p>
            <Button className={details} onClick={handleViewKpi}>
                {t('View details')}
            </Button>
        </div>
    );
};

KpiCardFooter.propTypes = {
    kpi: PropTypes.object,
    type: PropTypes.string,
};

KpiCardFooter.defaultProps = {
    kpi: null,
    type: 'PERSONAL',
};

export default KpiCardFooter;
