import {
    ArrowDown,
    ArrowUp,
    BookUser,
    Divide,
    Equal,
    FileCheck,
    GitFork,
    Handshake,
    Hash,
    KanbanSquare,
    ListTodo,
    ListTree,
    Minus,
    Package,
    Plus,
    Star,
    User,
    Users,
    X,
} from 'lucide-react';

import KpiCrmCard from 'pages/kpi/crm/kpi-crm-card';
import KpiPersonalCard from 'pages/kpi/personal/kpi-personal-card';
import { formatPositiveNumber, generateUUID } from 'utils';

import { getCrmKpiArr, getPersonalKpiArr } from './getterFunctions';

const DUMMY_TYPES = [
    { id: generateUUID(), name: 'Quantitative KPI' },
    { id: generateUUID(), name: 'CRM KPI' },
];

const PERIODS = [
    {
        type: 'MONTHLY',
        divisions: ['WEEKS', 'DAYS'],
        selectedDivision: 'WEEKS',
    },
    {
        type: 'YEARLY',
        divisions: ['YEARS', 'QUARTERS', 'MONTHS'],
        selectedDivision: 'MONTHS',
    },
];

const STATUSES = {
    weakIndex: 'WEAK',
    goodIndex: 'GOOD',
    superIndex: 'SUPER',
    idealIndex: 'IDEAL',
};

const COLORS = {
    neutral: 'var(--layout-transparent-dark)',
    weakIndex: 'var(--error)',
    goodIndex: 'var(--primary-main)',
    superIndex: 'var(--success)',
    idealIndex: 'var(--secondary-main)',
};

const KPI_VIEW = {
    PERSONAL: {
        title: "Personal KPI's",
        card: KpiPersonalCard,
        getFunction: getPersonalKpiArr,
    },
    CRM: {
        title: "CRM KPI's",
        card: KpiCrmCard,
        getFunction: getCrmKpiArr,
    },
};

const INDEXES_TABS = [
    {
        key: 'DAYS',
        label: 'Daily',
        icon: null,
    },
    {
        key: 'WEEKS',
        label: 'Weekly',
        icon: null,
    },
    {
        key: 'MONTHS',
        label: 'Monthly',
        icon: Star,
    },
    {
        key: 'QUARTERS',
        label: 'Quarterly',
        icon: null,
    },
    {
        key: 'YEARS',
        label: 'Yearly',
        icon: null,
    },
];

const PERSONAL_KPI_TITLES = {
    DAYS: 'KPI ON DAYS',
    WEEKS: 'KPI ON WEEKS',
    MONTHS: 'KPI ON MONTHS',
    QUARTERS: 'KPI ON QUARTERS',
    YEARS: 'KPI ON YEARS',
};

const CRM_KPI_STATUSES = {
    WEAK: 'Week KPI',
    GOOD: 'Good KPI',
    SUPER: 'Super KPI',
    IDEAL: 'Ideal KPI',
};

const WIDTH_CLASSES = {
    PERSONAL: {
        DAYS: 'grid-cols-kpi-view-3xl',
        WEEKS: 'grid-cols-kpi-view-md',
        MONTHS: 'grid-cols-kpi-view-xl',
        QUARTERS: 'grid-cols-kpi-view-md',
        YEARS: 'grid-cols-kpi-view-md',
    },
    CRM: {
        DAYS: 'grid-cols-1',
        WEEKS: 'grid-cols-1',
        MONTHS: 'grid-cols-1',
        QUARTERS: 'grid-cols-1',
        YEARS: 'grid-cols-1',
    },
};

const CHART_WIDTH_OFFSET = 72;
const CHART_AXIS_HEIGHT = 22;
const PROGRESS_BAR_OFFSETS = {
    false: 15,
    true: 45,
};

const CHART_ELEMENTS_VALUES = {
    dot: {
        text: {
            yOffset: 7,
            fontSize: 13.5,
        },
        circle: {
            r: 3,
        },
    },
    tick: {
        x: {
            tspan: {
                dy: 14,
            },
        },
        y: {
            x1offset: 2,
            text: {
                dy: 4,
                dx: 4,
            },
            rect: {
                rx: 1.5,
                ry: 1.5,
                w: 9,
                h: 3,
                yOffset: 1.5,
            },
            foreignObject: {
                xOffset: 6.5,
                yOffset: 6,
            },
        },
    },
};

const INDEXES_INPUT = {
    input: {
        type: 'number',
        style: {
            width: '3.5rem',
            height: '3.5rem',
            padding: 0,
            textAlign: 'center',
        },
    },
    display: {
        type: 'string',
        style: {
            width: '7rem',
            height: '3.5rem',
            padding: 0,
            textAlign: 'center',
            color: 'var(--buttons-text)',
            backgroundColor: 'var(--layout-transparent-dark)',
        },
    },
};

const SIGNS = {
    add: Plus,
    subtract: Minus,
    multiply: X,
    divide: Divide,
    equal: Equal,
};

const getDivisionsIndexes = ({
    weakIndex,
    goodIndex,
    superIndex,
    idealIndex,
}) => {
    const monthsIndexes = {
        type: 'MONTHS',
        weakIndex,
        goodIndex,
        superIndex,
        idealIndex,
    };

    const daysIndexes = {
        type: 'DAYS',
        weakIndex: Math.ceil(weakIndex / 30),
        goodIndex: Math.ceil(goodIndex / 30),
        superIndex: Math.ceil(superIndex / 30),
        idealIndex: Math.ceil(idealIndex / 30),
    };

    const weeksIndexes = {
        type: 'WEEKS',
        weakIndex: Math.ceil(weakIndex / 5),
        goodIndex: Math.ceil(goodIndex / 5),
        superIndex: Math.ceil(superIndex / 5),
        idealIndex: Math.ceil(idealIndex / 5),
    };

    const quartersIndexes = {
        type: 'QUARTERS',
        weakIndex: weakIndex * 3,
        goodIndex: goodIndex * 3,
        superIndex: superIndex * 3,
        idealIndex: idealIndex * 3,
    };

    const yearsIndexes = {
        type: 'YEARS',
        weakIndex: weakIndex * 12,
        goodIndex: goodIndex * 12,
        superIndex: superIndex * 12,
        idealIndex: idealIndex * 12,
    };

    return [
        daysIndexes,
        weeksIndexes,
        monthsIndexes,
        quartersIndexes,
        yearsIndexes,
    ];
};

const getAverage = (data) =>
    data.length
        ? data.reduce((acc, cur) => (acc += cur.value), 0) / data.length
        : 0;

const getPercent = (average, previousAverage) => {
    if (!previousAverage)
        return { percent: 0, color: COLORS.neutral, icon: null };

    const difference = average - previousAverage;
    const percent = (difference / previousAverage) * 100;

    const color =
        percent === 0
            ? COLORS.neutral
            : percent > 0
              ? COLORS.superIndex
              : COLORS.weakIndex;
    const icon = percent === 0 ? null : percent > 0 ? ArrowUp : ArrowDown;

    return { percent: Math.abs(percent), color, icon };
};

const getAverageStatus = (average, indexes) => {
    const { goodIndex, superIndex, idealIndex } = indexes;

    if (average < goodIndex)
        return { status: STATUSES.weakIndex, color: COLORS.weakIndex };
    if (average >= goodIndex && average < superIndex)
        return { status: STATUSES.goodIndex, color: COLORS.goodIndex };
    if (average >= superIndex && average < idealIndex)
        return { status: STATUSES.superIndex, color: COLORS.superIndex };
    return { status: STATUSES.idealIndex, color: COLORS.idealIndex };
};

const getIndexesAsMap = (indexes) =>
    Object.entries(indexes)
        .filter(([key]) => key !== 'type')
        .map(([_, value]) => value)
        .sort((a, b) => a - b);

const getIndexesPositions = (max, indexes, offset) => {
    const indexesPositions = Object.entries(indexes)
        .filter(([key]) => key !== 'type')
        .map(([key, value]) => ({
            position:
                value === 0
                    ? `${offset / 2}px`
                    : `calc(${(value / max) * 100}% - ${offset / 2}px)`,
            color: COLORS[key],
            tip: STATUSES[key],
            value,
        }));

    if (max > indexes.idealIndex)
        indexesPositions.push({
            position: `calc(100% - ${offset / 2}px)`,
            color: COLORS.neutral,
            tip: '',
            value: '',
        });

    return indexesPositions;
};

const getStops = (data) => {
    const stops = [];
    let startNulls = 0,
        endNulls = 0;

    for (let i = 0; i < data.length - 1; i++) {
        const current = data[i].value;
        if (current === null) startNulls++;
        else break;
    }

    for (let i = data.length - 1; i >= 0; i--) {
        const current = data[i].value;
        if (current === null) endNulls++;
        else break;
    }

    if (startNulls === data.length - 1) return [];

    const offsetDivider = data.length - startNulls - endNulls - 1;
    for (let i = startNulls; i < data.length - endNulls - 1; i++) {
        const current = data[i].value;
        const next = data[i + 1].value;
        const stopColor =
            current !== null && next !== null
                ? 'var(--disabled)'
                : 'transparent';

        stops.push({
            offset: `${((i - startNulls) / offsetDivider) * 100}%`,
            stopColor,
        });
        stops.push({
            offset: `${((i + 1 - startNulls) / offsetDivider) * 100}%`,
            stopColor,
        });
    }

    return stops;
};

const getPeriodsFilter = (yearData, monthData) => {
    const { value: YEAR, options: yearsOptions } = yearData;
    const { value: MONTH, options: monthsOptions } = monthData;

    return {
        filter: [
            { key: 'YEAR', options: yearsOptions },
            { key: 'MONTH', options: monthsOptions },
        ],
        selectedFilter: {
            YEAR,
            MONTH,
        },
    };
};

const handleFormatFormula = (formula) => {
    const columns = {
        'main-column': {
            id: 'main-column',
            elementsIds: [],
        },
    };

    const elements = {};
    Array.from({ length: formula.length }, (_, i) => {
        const elementId = `element-${generateUUID()}`;
        elements[elementId] = {
            ...formula[i],
            id: elementId,
        };
        columns['main-column'].elementsIds.push(elementId);
    });

    return {
        elements,
        columns,
        columnOrder: ['main-column'],
    };
};

//TODO: WIP (change if needed when implementing with the backend)
const handleFormat = (kpi) => {
    const { type, formula } = kpi;
    const { id } = type;

    return {
        ...kpi,
        type: id,
        formula: handleFormatFormula(formula),
    };
};

const handleUpdateFormulaElement = (formula, elementId, entries) => {
    return {
        ...formula,
        elements: {
            ...formula.elements,
            [elementId]: {
                ...formula.elements[elementId],
                ...entries,
            },
        },
    };
};

const handleChangeIndexes = (e, id, indexes) =>
    indexes.map((item) =>
        item.id === id
            ? {
                  ...item,
                  divisions: getDivisionsIndexes({
                      ...item.divisions.find(
                          (divisionItem) => divisionItem.type === 'MONTHS',
                      ),
                      [e.target.name]: formatPositiveNumber(e.target.value),
                  }),
              }
            : item,
    );

const handleChangeMonitorIndexes = (e, id, indexes) =>
    indexes.map((item) =>
        item.id === id
            ? {
                  ...item,
                  [e.target.name]: !e.target.checked,
              }
            : item,
    );

const handleAddIndexes = (indexes) => [
    ...indexes,
    {
        ...DEFAULT_INDEXES,
        divisions: indexes.at(-1)
            ? indexes.at(-1).divisions
            : DEFAULT_INDEXES.divisions,
    },
];

const handleRemoveIndexes = (id, indexes) =>
    indexes.filter((item) => item.id !== id);

const handleUpdateIndexes = (id, newItem, indexes) =>
    indexes.map((item) => (item.id === id ? newItem : item));

const handleCancelUpdateIndexes = (id, newItem, indexes) =>
    newItem ? handleUpdateIndexes(id, newItem, indexes) : indexes.slice(0, -1);

const DUMMY_DATA = {
    data: {
        YEARS: [
            {
                label: 'Y.1',
                value: 1480,
            },
            {
                label: 'Y.2',
                value: 1222,
            },
            {
                label: 'Y.3',
                value: 1670,
            },
            {
                label: 'Y.4',
                value: 980,
            },
            {
                label: 'Y.5',
                value: 1266,
            },
        ],
        QUARTERS: [
            {
                label: 'Q.1',
                value: 620,
            },
            {
                label: 'Q.2',
                value: 311,
            },
            {
                label: 'Q.3',
                value: 440,
            },
            {
                label: 'Q.4',
                value: 110,
            },
        ],
        MONTHS: [
            {
                label: 'M.1',
                value: 90,
            },
            {
                label: 'M.2',
                value: 80,
            },
            {
                label: 'M.3',
                value: 160,
            },
            { label: 'M.4', value: 126 },
            { label: 'M.5', value: 93 },
            { label: 'M.6', value: 113 },
            { label: 'M.7', value: 90 },
            { label: 'M.8', value: 113 },
            { label: 'M.9', value: 160 },
            { label: 'M.10', value: 141 },
            { label: 'M.11', value: 154 },
            { label: 'M.12', value: 108 },
        ],
        WEEKS: [
            {
                label: 'W.1',
                value: 25,
            },
            {
                label: 'W.1',
                value: 23,
            },
            {
                label: 'W.1',
                value: 32,
            },
            {
                label: 'W.1',
                value: 15,
            },
            {
                label: 'W.1',
                value: 22,
            },
        ],
        DAYS: [
            {
                label: 'D.1',
                value: null,
            },
            {
                label: 'D.2',
                value: null,
            },
            {
                label: 'D.3',
                value: null,
            },
            {
                label: 'D.4',
                value: 4,
            },
            {
                label: 'D.5',
                value: 3,
            },
            {
                label: 'D.6',
                value: 1,
            },
            {
                label: 'D.7',
                value: 3,
            },
            {
                label: 'D.8',
                value: null,
            },
            {
                label: 'D.9',
                value: null,
            },
            {
                label: 'D.10',
                value: null,
            },
            { label: 'D.11', value: 1 },
            { label: 'D.12', value: 3 },
            { label: 'D.13', value: 4 },
            { label: 'D.14', value: 6 },
            { label: 'D.15', value: 5 },
            { label: 'D.16', value: 1 },
            { label: 'D.17', value: 3 },
            { label: 'D.18', value: 4 },
            { label: 'D.19', value: null },
            { label: 'D.20', value: null },
            { label: 'D.21', value: null },
            { label: 'D.22', value: 3 },
            { label: 'D.23', value: null },
            { label: 'D.24', value: 6 },
            { label: 'D.25', value: 5 },
            { label: 'D.26', value: 1 },
            { label: 'D.27', value: 3 },
            { label: 'D.28', value: null },
            { label: 'D.29', value: null },
            { label: 'D.30', value: null },
        ],
    },
    previousAverage: {
        YEARS: 1480,
        QUARTERS: 500,
        MONTHS: 120,
        WEEKS: 22,
        DAYS: 4,
        createAt: new Date().setMonth(new Date().getMonth() - 1),
    },
    users: [
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 1', profileImageUrl: null },
            indexes: [
                {
                    id: generateUUID(),
                    monitor: true,
                    createAt: new Date().setMonth(new Date().getMonth() - 1),
                    updateAt: new Date().setMonth(new Date().getMonth() - 1),
                    divisions: getDivisionsIndexes({
                        weakIndex: 0,
                        goodIndex: 15,
                        superIndex: 30,
                        idealIndex: 60,
                    }),
                },
            ],
            averages: {
                YEARS: 120,
                QUARTERS: 50,
                MONTHS: 10,
                WEEKS: 2,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 2', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 140,
                QUARTERS: 45,
                MONTHS: 9,
                WEEKS: 1,
                DAYS: 0,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 3', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 110,
                QUARTERS: 55,
                MONTHS: 11,
                WEEKS: 3,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 4', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 150,
                QUARTERS: 60,
                MONTHS: 12,
                WEEKS: 2,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 5', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 130,
                QUARTERS: 50,
                MONTHS: 8,
                WEEKS: 1,
                DAYS: 0,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 1', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 100,
                QUARTERS: 40,
                MONTHS: 9,
                WEEKS: 2,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 6', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 145,
                QUARTERS: 58,
                MONTHS: 10,
                WEEKS: 3,
                DAYS: 0,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test final 1', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 125,
                QUARTERS: 42,
                MONTHS: 8,
                WEEKS: 1,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 7', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 135,
                QUARTERS: 48,
                MONTHS: 11,
                WEEKS: 2,
                DAYS: 0,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 7', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 105,
                QUARTERS: 44,
                MONTHS: 9,
                WEEKS: 3,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 7', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 115,
                QUARTERS: 53,
                MONTHS: 12,
                WEEKS: 2,
                DAYS: 0,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 7', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 140,
                QUARTERS: 55,
                MONTHS: 10,
                WEEKS: 1,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 7', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 130,
                QUARTERS: 60,
                MONTHS: 12,
                WEEKS: 3,
                DAYS: 0,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 7', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 110,
                QUARTERS: 42,
                MONTHS: 8,
                WEEKS: 1,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 7', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 125,
                QUARTERS: 50,
                MONTHS: 10,
                WEEKS: 2,
                DAYS: 0,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test 7', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 145,
                QUARTERS: 48,
                MONTHS: 11,
                WEEKS: 3,
                DAYS: 1,
            },
        },
        {
            id: generateUUID(),
            userId: generateUUID(),
            profile: { name: 'User Test final', profileImageUrl: null },
            indexes: [],
            averages: {
                YEARS: 140,
                QUARTERS: 58,
                MONTHS: 9,
                WEEKS: 1,
                DAYS: 0,
            },
        },
    ],
};

const DUMMY_FORMULA = [
    {
        id: generateUUID(),
        name: 'Field 1',
        createAt: new Date(),
        sign: 'divide',
        item: {
            id: generateUUID(),
            key: 'crm',
            input: false,
            value: null,
            item: {
                id: generateUUID(),
                key: 'clients',
                input: false,
                value: null,
                item: {
                    id: generateUUID(),
                    key: 'test',
                    input: false,
                    value: null,
                    item: null,
                },
            },
        },
        filters: [
            {
                id: generateUUID(),
                key: 'name',
                criterion: 'contains',
                value: 'test',
            },
            { id: generateUUID(), key: 'test', criterion: 'gte', value: 10 },
        ],
    },
    {
        id: generateUUID(),
        name: 'Field 2',
        createAt: new Date(),
        sign: 'multiply',
        item: {
            id: generateUUID(),
            key: 'ai',
            input: false,
            value: null,
            item: {
                id: generateUUID(),
                key: 'activities',
                input: false,
                value: null,
                item: null,
            },
        },
        filters: [
            {
                id: generateUUID(),
                key: 'status',
                criterion: 'in',
                value: ['DONE'],
            },
        ],
    },
    {
        id: generateUUID(),
        name: 'Field 3',
        createAt: new Date(),
        sign: 'equal',
        item: {
            id: generateUUID(),
            key: 'number',
            input: true,
            value: 10,
            item: null,
        },
        filters: [],
    },
];

const DUMMY_KPI = {
    id: generateUUID(),
    name: 'Test KPI',
    type: DUMMY_TYPES[0],
    createAt: new Date(),
    monitorUsers: true,
    formula: DUMMY_FORMULA,
    indexes: [
        {
            id: generateUUID(),
            monitor: true,
            createAt: new Date().setMonth(new Date().getMonth() - 1),
            updateAt: new Date().setMonth(new Date().getMonth() - 1),
            divisions: getDivisionsIndexes({
                weakIndex: 0,
                goodIndex: 80,
                superIndex: 100,
                idealIndex: 120,
            }),
        },
        {
            id: generateUUID(),
            monitor: true,
            createAt: new Date().setDate(1),
            updateAt: new Date().setDate(1),
            divisions: getDivisionsIndexes({
                weakIndex: 0,
                goodIndex: 100,
                superIndex: 120,
                idealIndex: 150,
            }),
        },
    ],
    usersIndexes: [
        {
            id: generateUUID(),
            monitor: true,
            createAt: new Date(),
            updateAt: new Date(),
            divisions: getDivisionsIndexes({
                weakIndex: 0,
                goodIndex: 10,
                superIndex: 12,
                idealIndex: 15,
            }),
        },
    ],
    ...DUMMY_DATA,
};

const DEFAULT_INDEXES = {
    monitor: true,
    divisions: [
        {
            type: 'DAYS',
            weakIndex: 0,
            goodIndex: 0,
            superIndex: 0,
            idealIndex: 0,
        },
        {
            type: 'WEEKS',
            weakIndex: 0,
            goodIndex: 0,
            superIndex: 0,
            idealIndex: 0,
        },
        {
            type: 'MONTHS',
            weakIndex: 0,
            goodIndex: 0,
            superIndex: 0,
            idealIndex: 0,
        },
        {
            type: 'QUARTERS',
            weakIndex: 0,
            goodIndex: 0,
            superIndex: 0,
            idealIndex: 0,
        },
        {
            type: 'YEARS',
            weakIndex: 0,
            goodIndex: 0,
            superIndex: 0,
            idealIndex: 0,
        },
    ],
};

const DEFAULT_FORMULA_ELEMENT = {
    name: '',
    sign: 'equal',
    item: null,
    filters: [],
};

const DEFAULT_FORMULA = {
    elements: {
        'element-main': {
            id: 'element-main',
            ...DEFAULT_FORMULA_ELEMENT,
        },
    },
    columns: {
        'main-column': {
            id: 'main-column',
            elementsIds: ['element-main'],
        },
    },
    columnOrder: ['main-column'],
};

const DEFAULT_KPI = {
    name: '',
    type: null,
    monitorUsers: true,
    indexes: [DEFAULT_INDEXES],
    usersIndexes: [DEFAULT_INDEXES],
    users: [],
    formula: DEFAULT_FORMULA,
};

const FILTERS = {
    text: {
        criteria: [
            { label: 'Start with', criterion: 'startsWith' },
            { label: 'Contains', criterion: 'contains' },
            { label: 'Equals to', criterion: 'equals' },
            { label: 'Ends with', criterion: 'endsWith' },
        ],
        value: '',
    },
    number: {
        criteria: [
            { label: 'Greater or equal to', criterion: 'gte' },
            { label: 'Equals to', criterion: 'equals' },
            { label: 'Lesser or equal to', criterion: 'lte' },
        ],
        value: 0,
    },
    multiselect: {
        criteria: [
            { label: 'Is in', criterion: 'in' },
            { label: 'Is not in', criterion: 'notIn' },
        ],
        value: [],
    },
    boolean: {
        criteria: [],
        value: false,
    },
};

const FORMULA_SELECTION = [
    {
        key: 'ai',
        icon: ListTree,
        upperCase: true,
        input: false,
        filters: [],
        options: [
            {
                key: 'activities',
                icon: ListTodo,
                upperCase: false,
                options: [],
                input: false,
                filters: [
                    {
                        key: 'status',
                        input: 'multiselect',
                        options: [
                            { label: 'Done', key: 'DONE' },
                            { label: 'Todo', key: 'TODO' },
                            { label: 'Failed', key: 'FAILED' },
                        ],
                    },
                ],
            },
            {
                key: 'milestones',
                icon: KanbanSquare,
                upperCase: false,
                options: [],
                input: false,
                filters: [],
            },
            {
                key: 'partners',
                icon: BookUser,
                upperCase: false,
                options: [],
                input: false,
                filters: [],
            },
            {
                key: 'project-structures',
                icon: GitFork,
                upperCase: false,
                options: [],
                input: false,
                filters: [],
            },
        ],
    },
    {
        key: 'crm',
        icon: Users,
        upperCase: true,
        input: false,
        filters: [],
        options: [
            {
                key: 'clients',
                icon: User,
                upperCase: false,
                input: false,
                filters: [],
                options: [
                    {
                        key: 'test',
                        icon: null,
                        upperCase: false,
                        options: [],
                        input: false,
                        filters: [
                            {
                                key: 'name',
                                input: 'text',
                                options: [],
                            },
                            {
                                key: 'test',
                                input: 'number',
                                options: [],
                            },
                        ],
                    },
                ],
            },
            {
                key: 'catalog',
                icon: Package,
                upperCase: false,
                options: [],
                input: false,
                filters: [],
            },
            {
                key: 'contracts',
                icon: FileCheck,
                upperCase: false,
                options: [],
                input: false,
                filters: [],
            },
            {
                key: 'offers',
                icon: Handshake,
                upperCase: false,
                options: [],
                input: false,
                filters: [],
            },
        ],
    },
    {
        key: 'number',
        icon: Hash,
        upperCase: false,
        options: [],
        input: true,
        filters: [],
    },
];

export {
    DUMMY_TYPES,
    DUMMY_KPI,
    DUMMY_DATA,
    DEFAULT_FORMULA_ELEMENT,
    DEFAULT_KPI,
    DEFAULT_INDEXES,
    PERIODS,
    COLORS,
    STATUSES,
    KPI_VIEW,
    INDEXES_TABS,
    PERSONAL_KPI_TITLES,
    CRM_KPI_STATUSES,
    WIDTH_CLASSES,
    CHART_WIDTH_OFFSET,
    CHART_AXIS_HEIGHT,
    PROGRESS_BAR_OFFSETS,
    CHART_ELEMENTS_VALUES,
    SIGNS,
    INDEXES_INPUT,
    FORMULA_SELECTION,
    FILTERS,
    getPeriodsFilter,
    getAverage,
    getPercent,
    getDivisionsIndexes,
    getAverageStatus,
    getIndexesAsMap,
    getIndexesPositions,
    getStops,
    handleFormat,
    handleFormatFormula,
    handleChangeIndexes,
    handleAddIndexes,
    handleChangeMonitorIndexes,
    handleRemoveIndexes,
    handleUpdateIndexes,
    handleCancelUpdateIndexes,
    handleUpdateFormulaElement,
};
