import React, { useContext, useEffect, useState } from 'react';

import { FileUp, ListPlus } from 'lucide-react';

import { Button, CircularProgress } from '@material-ui/core';

import { Expenses, Revenues } from 'api/Expenses_Revenues';
import ExportTable from 'components/shared/export/export-table';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { displayNumber, errorHandling, getRawImageFromPath } from 'utils';
import API from 'utils/axios';
import exportExcel from 'utils/exportExcel';
import exportHtmlAsPDF from 'utils/exportHtmlAsPDF';
import { formatExcelData, mergeExcelCellStyles } from 'utils/exportUtils';
import { getCompanyLogoAndIcon } from 'utils/getterFunctions';

import ReportModalInvoices from './report-modal-invoice';

const ReportInvoices = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        tenant: { companyName },
        currencyObj,
        referenceCurrencyObj,
        language,
    } = useContext(GlobalContext);

    const columns = [
        {
            key: 'name',
            content: t('Invoice name'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'number',
            content: t('Invoice number'),
            render: (content, entry, index) => content,
        },
        {
            key: 'client',
            content: t('Client'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'partner',
            content: t('Partner'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'mainPrice',
            content: `${t('Invoice value')} (${currencyObj.currency})`,
            render: (content, entry, index) => displayNumber(content, language),
        },
        {
            key: 'secondaryPrice',
            content: `${t('Invoice value')} (${referenceCurrencyObj.currency})`,
            render: (content, entry, index) => displayNumber(content, language),
        },
        {
            key: 'type',
            content: t('Invoice type'),
            render: (content, entry, index) =>
                t(content.charAt(0) + content.slice(1).toLowerCase()),
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'payment',
            content: t('Payment status'),
            render: (content, entry, index) => t(content.split('_').join(' ')),
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
    ];

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [revenues, setRevenues] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [exportingExcel, setExportingExcel] = useState(false);
    const [companyLogo, setCompanyLogo] = useState(null);

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState({
        revenues: [],
        expenses: [],
    });

    useEffect(() => {
        (async () => {
            try {
                const [revenuesRes, expensesRes, { logoUrl }] =
                    await Promise.all([
                        Revenues.get(99999, 0),
                        Expenses.get(0, 99999),
                        getCompanyLogoAndIcon(),
                    ]);

                const logoBase64 = await getRawImageFromPath(logoUrl);
                setCompanyLogo(logoBase64);

                setRevenues(
                    revenuesRes.data.revenuName.reduce((acc, grandparent) => {
                        acc.push(grandparent);
                        grandparent.subRevenueNames.map((parent) => {
                            acc.push(parent);
                            parent.subRevenueNames.map((child) => {
                                acc.push(child);
                            });
                        });

                        return acc;
                    }, []),
                );
                setExpenses(
                    expensesRes.data.expenseNames.reduce((acc, grandparent) => {
                        acc.push(grandparent);
                        grandparent.subExpensesName.map((parent) => {
                            acc.push(parent);
                            parent.subExpensesName.map((child) => {
                                acc.push(child);
                            });
                        });

                        return acc;
                    }, []),
                );
            } catch (error) {
                console.error(error);
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFilter = async (params) => {
        try {
            setFetching(true);

            const dataRes = await API.get('/invoices_report', { params });

            setData(dataRes.data.report);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setFetching(false);
        }
    };

    const handleExport = async () => {
        try {
            setExporting(true);

            const html = document.getElementById(
                'invoices-report-table',
            ).innerHTML;

            const columnsWidths = [
                '13%',
                '13%',
                '13%',
                '13%',
                '13%',
                '13%',
                '11%',
                '11%',
            ];

            await exportHtmlAsPDF(
                'landscape',
                [html],
                [],
                null,
                null,
                true,
                null,
                `Invoices_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                [
                    {
                        elements: [
                            {
                                key: 'table',
                                props: {
                                    widths: columnsWidths,
                                    dontBreakRows: true,
                                },
                            },
                        ],
                    },
                ],
                [],
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setExporting(false);
        }
    };

    const handleExportExcel = async () => {
        try {
            setExportingExcel(true);

            const {
                columns: exportColumns,
                rows,
                lastRow,
            } = formatExcelData(columns, data);

            await exportExcel(
                `Invoices_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                `Invoices_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                t('Stocks report'),
                exportColumns,
                rows,
                lastRow,
                (cell, iCell, row, iRow) => {
                    mergeExcelCellStyles(cell, iCell, exportColumns);
                },
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setExportingExcel(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Invoices report')}</title>
            </Helmet>
            <Header
                pageTitle={t('Invoices report')}
                action={
                    <>
                        <Button
                            color="primary"
                            startIcon={
                                fetching ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <ListPlus />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleOpen}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel
                            }
                        >
                            {t(fetching ? 'Loading...' : 'Select data')}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={
                                exportingExcel ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <FileUp />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleExportExcel}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel ||
                                !data.length
                            }
                        >
                            {t(
                                exportingExcel
                                    ? 'Loading...'
                                    : 'Download Excel file',
                            )}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={
                                exporting ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <FileUp />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleExport}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel ||
                                !data.length
                            }
                        >
                            {t(exporting ? 'Loading...' : 'Export as PDF')}
                        </Button>
                    </>
                }
            />

            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '100%', margin: 'auto 0' }} />
                ) : data.length ? (
                    <div className="overflow-x-auto" id="invoices-report-table">
                        <ExportTable
                            companyLogo={companyLogo}
                            title={t('Invoices report')}
                            columns={columns}
                            data={data}
                        />
                    </div>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>

            <ReportModalInvoices
                open={open}
                onClose={handleClose}
                onFilter={handleFilter}
                revenues={revenues}
                expenses={expenses}
                filteredOptions={filteredOptions}
                setFilteredOptions={setFilteredOptions}
            />
        </>
    );
};

export default ReportInvoices;
