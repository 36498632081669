import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import BasicTooltip from 'components/shared/basic-tooltip';
import ErrorButton from 'components/shared/error-button';
import Loading from 'components/shared/loading';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import EditElementModal from 'pages/configurator/documents_configurator/documents_items/edit-element-modal';
import PropTypes from 'prop-types';
import { ActionButton, Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
    errorHandling,
    formatDate,
    generateUUID,
    getRawImageFromPath,
    rgbToHex,
} from 'utils';
import API from 'utils/axios';
import exportHtmlAsPDF from 'utils/exportHtmlAsPDF';
import exportImagesToPDF from 'utils/exportImagesToPDF';
import { getCompanyLogoAndIcon } from 'utils/getterFunctions';

import RiemExportModal from './components/riem-export-modal';
import RiemLegend from './components/riem-legend';
import RiemTable from './components/riem-table';

const DEFAULT_RIEM = (t) => {
    const spacingHTML = `\u200B\t`;
    const documentStyles = getComputedStyle(document.documentElement);
    const pdfTextColor = documentStyles
        .getPropertyValue('--pdf-text')
        .replaceAll(' ', ', ');
    const errorColor = documentStyles
        .getPropertyValue('--error')
        .replaceAll(' ', ', ');

    return {
        id: generateUUID(),
        name: t('Main language'),
        createdAt: new Date(),
        updatedAt: new Date(),
        items: [],
        info: {
            title: t('Risk identification, evaluation and mitigation plan'),
            project: t('Project'),
            beneficiary: t('Beneficiary'),
            dates: t('Realization date / last update'),
        },
        header: {
            number: t('No.'),
            stage: t('Stage'),
            identifiedDescription: t('Description of the identified risks'),
            identifiedScore: t('Identified risk score'),
            identifiedProbability: t('Risk probability'),
            identifiedTotal: t('Risk score before control measures'),
            measures: t('Mitigation / control measures'),
            residualDescription: t('Description of residual risks'),
            residualScore: t('Residual risk score'),
            residualProbability: t('Residual risk probability'),
            residualTotal: t('Total score residual risk'),
            identificationDate: t('Identification date'),
            lastUpdate: t('Last update'),
        },
        footer: {
            total: t('Score - TOTAL'),
        },
        legend: {
            title: t('RIEM legend'),
            content: `<h2 style="color: ${pdfTextColor}; font-size: 30px; text-align: center">${t('RIEM legend')}</h2>
            <p style="font-size: 16px;">${spacingHTML}</p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><strong>${t(
                'The risk will be rated from 0 to 5, 5 being the highest',
            )}</strong></p>
            <p style="font-size: 16px;">${spacingHTML}</p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><strong>${t(
                'The probability of the risk occurring will be rated from 0 to 5, 5 being the highest',
            )}</strong></p>        
            <p style="color: ${errorColor}; font-size: 16px;"><strong>${t(
                'Risk Score and Risk Probability',
            )}:</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>0 ${t(
                'points',
            )}</strong></span><strong> - ${t('No risk / No probability')}</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>1 ${t(
                'point',
            )}</strong></span><strong> - ${t('Minor risk / Minor probability')}</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>2 ${t(
                'points',
            )}</strong></span><strong> - ${t('Low risk / Low probability')}</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>3 ${t(
                'points',
            )}</strong></span><strong> - ${t('Medium risk / Medium probability')}</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>4 ${t(
                'points',
            )}</strong></span><strong> - ${t('High risk / High probability')}</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>5 ${t(
                'points',
            )}</strong></span><strong> - ${t('Major risk / Major probability')}</strong></p>
            <p style="font-size: 16px;">${spacingHTML}</p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><strong>${t(
                'The final residual risk will be calculated as the risk remaining after taking control measures',
            )}</strong></p>
            <p style="font-size: 16px;">${spacingHTML}</p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><strong>${t(
                'The evaluation will be made according to the Residual Risk and the Individual Maximum Risk and can have 3 results',
            )}</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><strong>${t(
                'Favorable Opinion (FO), Conditionally Favorable Opinion (CFO) and Negative Opinion (NO)',
            )}</strong></p>
            <p style="font-size: 16px;">${spacingHTML}</p>
            <p style="color: ${errorColor}; font-size: 16px;"><strong>${t(
                'Final residual risk assessment',
            )}:</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>${t(
                'Score',
            )} < 12</strong></span><strong> - ${t('Favorable Opinion')}</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>${t(
                'Score',
            )} 12 - 20</strong></span><strong> - ${t('Conditionally Favorable Opinion')}</strong></p>
            <p style="color: ${pdfTextColor}; font-size: 16px;"><span style="color: ${errorColor}"><strong>${t(
                'Score',
            )} > 20</strong></span><strong> - ${t('Negative Opinion')}</strong></p>`,
        },
    };
};

const DEFAULT_ITEM = {
    id: null,
    stage: '',
    identifiedDescription: '',
    identifiedScore: 0,
    identifiedProbability: 0,
    identifiedTotal: 0,
    measures: '',
    residualDescription: '',
    residualScore: 0,
    residualProbability: 0,
    residualTotal: 0,
    identificationDate: new Date().toDateString(),
    lastUpdate: new Date().toDateString(),
};

const defaultValues = {
    canEdit: true,
    isSaving: false,
    riems: [],
    displayedRiem: 0,
    isOperatingOnRowAt: null,
    setIsOperatingOnRowAt: () => {},
    onAddItem: () => {},
    onRemoveItem: () => {},
    onChangeItem: () => {},
    onMoveItem: () => {},
    onEditInfo: () => {},
};

export const RiemContext = createContext(defaultValues);

const Riem = ({ project }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { tenant, language } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);

    const [isSaving, setIsSaving] = useState(false);

    const [loading, setLoading] = useState(true);
    const [companyLogo, setCompanyLogo] = useState(null);

    const [riems, setRiems] = useState([]);
    const [displayedRiem, setDisplayedRiem] = useState(0);

    const [isAddingLanguage, setIsAddingLanguage] = useState(false);
    const [languageName, setLanguageName] = useState('');
    const languageInputRef = useRef(null);

    const [isOperatingOnRowAt, setIsOperatingOnRowAt] = useState(null);

    const [loadingExport, setLoadingExport] = useState(false);
    const [openExport, setOpenExport] = useState(false);

    const [openEditor, setOpenEditor] = useState(false);

    const canView = checkPerm([
        {
            permissionId: '54',
            permissionType: 'VIEW',
        },
    ]);
    const canAll = checkPerm([
        {
            permissionId: '54',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canView) {
            history.push('/');
            return;
        }

        (async () => {
            try {
                const [{ logoUrl: rawLogo }, rawRiems] = await Promise.all([
                    getCompanyLogoAndIcon(),
                    API.get('/riem', {
                        params: {
                            projectId: project.id,
                        },
                    }),
                ]);

                if (rawRiems.data.riem.length) {
                    setRiems(
                        rawRiems.data.riem.map((r) => ({
                            ...r,
                            items: r.items.map((item) => ({
                                ...item,
                                identificationDate: new Date(
                                    item.identificationDate,
                                ).toDateString(),
                                lastUpdate: new Date(
                                    item.lastUpdate,
                                ).toDateString(),
                            })),
                            project,
                        })),
                    );
                } else setRiems([{ ...DEFAULT_RIEM(t), project }]);

                const logoBase64 = await getRawImageFromPath(rawLogo);
                setCompanyLogo(logoBase64);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleAddLanguage = (e) => {
        e.preventDefault();

        if (languageName.trim().length === 0) {
            enqueueSnackbar(t('You must add the name of the language'), {
                variant: 'error',
            });
            return;
        }

        setRiems((prev) => [
            ...prev,
            {
                ...riems[0],
                items: riems[0].items.map((item) => ({
                    ...item,
                    id: generateUUID(),
                })),
                name: languageName,
                id: generateUUID(),
            },
        ]);

        setLanguageName('');
        setIsAddingLanguage(false);

        enqueueSnackbar(t('Language added successfully'), {
            variant: 'success',
        });
    };
    const handleDeleteLanguage = (id) =>
        setRiems((prev) => prev.filter((riem) => riem.id !== id));

    const handlePrevLanguage = () =>
        setDisplayedRiem((prev) => (prev === 0 ? 0 : --prev));
    const handleNextLanguage = () =>
        setDisplayedRiem((prev) =>
            prev === riems.length - 1 ? riems.length - 1 : ++prev,
        );

    const handleAddItem = (item, newRowAt) =>
        setRiems((prev) =>
            prev.map((riem) => {
                const clonedItems = structuredClone(riem.items);
                let newItems = clonedItems.slice(0, newRowAt);
                newItems.push(item);
                newItems = [
                    ...newItems,
                    ...clonedItems.slice(newRowAt, clonedItems.length + 1),
                ];

                return { ...riem, items: newItems };
            }),
        );
    const handleRemoveItem = (rowIndex) =>
        setRiems((prev) =>
            prev.map((riem) => {
                return {
                    ...riem,
                    items: structuredClone(riem.items).filter(
                        (_, index) => index !== rowIndex,
                    ),
                };
            }),
        );
    const handleMoveItem = (start, end) =>
        setRiems((prev) =>
            prev.map((riem) => {
                const newItems = structuredClone(riem.items);
                [newItems[start], newItems[end]] = [
                    newItems[end],
                    newItems[start],
                ];

                return { ...riem, items: newItems };
            }),
        );
    const handleChangeItem = (formData, id, index, riemId) =>
        setRiems((prev) => {
            return prev.map((riem) => {
                const newItems = riem.items.map((cItem, cIndex) => {
                    if (cIndex !== index) return cItem;

                    if (!id || riem.id === riemId)
                        return { ...formData, id: id ?? generateUUID() };

                    const newFormData = structuredClone(cItem);
                    Object.entries({
                        ...formData,
                        id: id ?? generateUUID(),
                    }).forEach(([key, value]) => {
                        if (typeof value === 'number') newFormData[key] = value;
                    });

                    return newFormData;
                });

                return { ...riem, items: newItems };
            });
        });
    const handleEditInfo = (formData, id, key) =>
        setRiems((prev) =>
            prev.map((riem) =>
                riem.id === id ? { ...riem, [key]: formData } : riem,
            ),
        );

    const handleCloseModal = () => setOpenEditor(false);
    const handleEditText = (value) => {
        setRiems((prev) =>
            prev.map((riem) =>
                riem.id === riems[displayedRiem].id
                    ? { ...riem, legend: { ...riem.legend, content: value } }
                    : riem,
            ),
        );
        setOpenEditor(false);
    };

    const handleSaveRiem = async () => {
        try {
            const clonedRiems = structuredClone(riems);
            await API.post('/riem', {
                riem: clonedRiems.map((riem) => {
                    riem.projectId = riem.project.id;
                    delete riem.project;

                    return { ...riem };
                }),
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const handleSaveRiemChanges = async () => {
        try {
            setIsSaving(true);
            await handleSaveRiem();
            enqueueSnackbar(t('Changes saved successfully!'), {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleExport = async (order, pageOrientation) => {
        try {
            setLoadingExport(true);

            await handleSaveRiem();

            if (openExport === 'images') {
                await exportImagesToPDF(
                    pageOrientation,
                    language,
                    [
                        {
                            ids: order.map(
                                (element) => `riem-table-${element.position}`,
                            ),
                            type: 'riem-table',
                            data: {
                                order,
                                pageOrientation,
                            },
                            request: {
                                params: {
                                    projectId: project.id,
                                },
                            },
                        },
                    ],
                    [
                        {
                            ids: order.map(
                                (element) => `riem-legend-${element.position}`,
                            ),
                            type: 'riem-legend',
                            data: {
                                order,
                            },
                            request: {
                                params: {
                                    projectId: project.id,
                                },
                            },
                        },
                    ],
                    () => setOpenExport(false),
                    () => setLoadingExport(false),
                    true,
                    'multi-page',
                    null,
                    `RIEM_Export_${new Date().toISOString().slice(0, 5)}_${tenant.companyName}.pdf`,
                );
            }

            if (openExport === 'table') {
                const documentStyles = getComputedStyle(
                    document.documentElement,
                );
                const pdfTextColor = documentStyles
                    .getPropertyValue('--pdf-text')
                    .replaceAll(' ', ', ');
                const pdfLayoutTextColor = documentStyles
                    .getPropertyValue('--pdf-layout-text')
                    .replaceAll(' ', ', ');
                const pdfLayoutBackgroundColor = rgbToHex(
                    ...documentStyles
                        .getPropertyValue('--base-pdf-layout-background')
                        .split(' '),
                );
                const disabledColor = rgbToHex(
                    ...documentStyles
                        .getPropertyValue('--base-disabled')
                        .split(' '),
                );
                const successColor = rgbToHex(
                    ...documentStyles
                        .getPropertyValue('--base-success')
                        .split(' '),
                );
                const errorColor = rgbToHex(
                    ...documentStyles
                        .getPropertyValue('--base-error')
                        .split(' '),
                );
                const warningColor = rgbToHex(
                    ...documentStyles
                        .getPropertyValue('--base-warning')
                        .split(' '),
                );

                const totalCellsBackgroundClasses = (value) => {
                    if (value < 12) return successColor;
                    if (value >= 12 && value <= 20) return warningColor;
                    if (value > 20) return errorColor;
                };

                const mappedWidths = {
                    portrait: [
                        '2%',
                        '14.5%',
                        '14.5%',
                        '4%',
                        '4%',
                        '4%',
                        '14.5%',
                        '14.5%',
                        '4%',
                        '4%',
                        '4%',
                        '8%',
                        '8%',
                    ],
                    landscape: [
                        '2%',
                        '15.5%',
                        '15.5%',
                        '4%',
                        '4%',
                        '4%',
                        '15.5%',
                        '15.5%',
                        '4%',
                        '4%',
                        '4%',
                        '6%',
                        '6%',
                    ],
                };

                const riemTables = order.map((element) => {
                    const riem = riems[element.position];

                    return `<table>
                                <thead>
                                    <tr>
                                        <th colspan="2" data-pdfmake='${JSON.stringify(
                                            {
                                                fillColor:
                                                    pdfLayoutBackgroundColor,
                                                border: [
                                                    true,
                                                    true,
                                                    false,
                                                    true,
                                                ],
                                            },
                                        )}'>
                                            <img src="${companyLogo} alt="Company Logo" style="width: 108px;" />
                                        </th>
                                        <th colspan="7" data-pdfmake='${JSON.stringify(
                                            {
                                                fillColor:
                                                    pdfLayoutBackgroundColor,
                                                border: [
                                                    false,
                                                    true,
                                                    false,
                                                    true,
                                                ],
                                            },
                                        )}'>
                                            <p style="color: ${pdfLayoutTextColor}; text-align: center; font-weight: bold; font-size: 24px;">${
                                                riem.info.title
                                            }</p>
                                        </th>
                                        <th colspan="4" data-pdfmake='${JSON.stringify(
                                            {
                                                fillColor:
                                                    pdfLayoutBackgroundColor,
                                                border: [
                                                    false,
                                                    true,
                                                    true,
                                                    true,
                                                ],
                                            },
                                        )}'>
                                            <div>
                                                 <p style="color: ${pdfLayoutTextColor}; text-align: right; font-weight: bold; font-size: 14px;">${`${riem.info.project}: ${riem.project.name}`}</p>
                                                 <p style="color: ${pdfLayoutTextColor}; text-align: right; font-weight: bold; font-size: 14px;">${`${
                                                     riem.info.beneficiary
                                                 }: ${
                                                     typeof riem.project.contact
                                                         .data === 'string'
                                                         ? JSON.parse(
                                                               riem.project
                                                                   .contact
                                                                   .data,
                                                           ).standard.name
                                                         : riem.project.contact
                                                               .data.standard
                                                               .name
                                                 }`}</p>
                                                 <p style="color: ${pdfLayoutTextColor}; text-align: right; font-weight: bold; font-size: 14px; white-space: pre-wrap;">${`${
                                                     riem.info.dates
                                                 }\n${formatDate(riem.createdAt, true, true)} / ${formatDate(riem.updatedAt, true, true)}`}</p>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.number
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.stage
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header
                                                     .identifiedDescription
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.identifiedScore
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header
                                                     .identifiedProbability
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.identifiedTotal
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.measures
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.residualDescription
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.residualScore
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.residualProbability
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.residualTotal
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.identificationDate
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: disabledColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.header.lastUpdate
                                             }</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                   ${riem.items
                                       .map((item, index) => {
                                           return `<tr>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px;  text-align: center;">${
                                                                    index + 1
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px;">${
                                                                    item.stage
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px;">${
                                                                    item.identifiedDescription
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                                    item.identifiedScore
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                                    item.identifiedProbability
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                totalCellsBackgroundClasses(
                                                                    item.identifiedTotal,
                                                                ),
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                                    item.identifiedTotal
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px;">${
                                                                    item.measures
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px;">${
                                                                    item.residualDescription
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                                    item.residualScore
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                                    item.residualProbability
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                totalCellsBackgroundClasses(
                                                                    item.residualTotal,
                                                                ),
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                                    item.residualTotal
                                                                }</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${formatDate(
                                                                    item.identificationDate,
                                                                    true,
                                                                    true,
                                                                )}</p>
                                                    </th>
                                                    <th data-pdfmake='${JSON.stringify(
                                                        {
                                                            fillColor:
                                                                index % 2 === 0
                                                                    ? '#FFFFFF'
                                                                    : disabledColor,
                                                            margin: [
                                                                -2, -15, -2,
                                                                -20,
                                                            ],
                                                        },
                                                    )}'>
                                                                <p style="color: ${pdfTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${formatDate(
                                                                    item.lastUpdate,
                                                                    true,
                                                                    true,
                                                                )}</p>
                                                    </th>
                                                </tr>`;
                                       })
                                       .join('')}
                                    <tr>
                                        <th colspan="3" data-pdfmake='${JSON.stringify(
                                            {
                                                fillColor:
                                                    pdfLayoutBackgroundColor,
                                                margin: [-2, -15, -2, -20],
                                            },
                                        )}'>
                                             <p style="color: ${pdfLayoutTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${
                                                 riem.footer.total
                                             }</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfLayoutTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${riem.items.reduce(
                                                 (acc, curr) =>
                                                     (acc +=
                                                         curr.identifiedScore),
                                                 0,
                                             )}</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfLayoutTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${riem.items.reduce(
                                                 (acc, curr) =>
                                                     (acc +=
                                                         curr.identifiedProbability),
                                                 0,
                                             )}</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfLayoutTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${riem.items.reduce(
                                                 (acc, curr) =>
                                                     (acc +=
                                                         curr.identifiedTotal),
                                                 0,
                                             )}</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}' />
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}' />
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfLayoutTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${riem.items.reduce(
                                                 (acc, curr) =>
                                                     (acc +=
                                                         curr.residualScore),
                                                 0,
                                             )}</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfLayoutTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${riem.items.reduce(
                                                 (acc, curr) =>
                                                     (acc +=
                                                         curr.residualProbability),
                                                 0,
                                             )}</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}'>
                                             <p style="color: ${pdfLayoutTextColor}; font-weight: bold; font-size: 10px; text-align: center;">${riem.items.reduce(
                                                 (acc, curr) =>
                                                     (acc +=
                                                         curr.residualTotal),
                                                 0,
                                             )}</p>
                                        </th>
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}' />
                                        <th data-pdfmake='${JSON.stringify({
                                            fillColor: pdfLayoutBackgroundColor,
                                            margin: [-2, -15, -2, -20],
                                        })}' />
                                    </tr>
                                <tbody/>
                            </table>`;
                });

                const riemLegends = order.map(
                    (element) =>
                        `<div>${riems[element.position].legend.content}</div>`,
                );

                await exportHtmlAsPDF(
                    pageOrientation,
                    riemTables,
                    riemLegends,
                    () => setOpenExport(false),
                    () => setLoadingExport(false),
                    true,
                    null,
                    `RIEM_Export_${new Date().toISOString().slice(0, 5)}_${tenant.companyName}.pdf`,
                    new Array(riemTables.length).fill({
                        props: {
                            pageBreak: 'after',
                        },
                        elements: [
                            {
                                key: 'table',
                                props: {
                                    widths: mappedWidths[pageOrientation],
                                },
                            },
                        ],
                    }),
                    Array.from({ length: riemLegends.length }, (_, i) => ({
                        props: {
                            pageBreak:
                                i < riemLegends.length - 1
                                    ? 'after'
                                    : undefined,
                            lineHeight: 0.25,
                        },
                    })),
                );
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };

    return (
        <>
            <Helmet>
                <title>RIEM</title>
            </Helmet>
            <Header
                pageTitle="RIEM"
                action={
                    <>
                        <BasicTooltip
                            disabled={isOperatingOnRowAt === null}
                            tip={t(
                                "You can't export a PDF while you are operating on a row",
                            )}
                        >
                            <Button
                                style={{ borderRadius: '999px' }}
                                startIcon={<ImportExportIcon />}
                                color="secondary"
                                disabled={
                                    isSaving ||
                                    loadingExport ||
                                    isOperatingOnRowAt !== null
                                }
                                onClick={() => setOpenExport('images')}
                            >
                                {t('Export as PDF using images')}
                            </Button>
                        </BasicTooltip>
                        <BasicTooltip
                            disabled={isOperatingOnRowAt === null}
                            tip={t(
                                "You can't export a PDF while you are operating on a row",
                            )}
                        >
                            <Button
                                style={{ borderRadius: '999px' }}
                                startIcon={<ImportExportIcon />}
                                color="secondary"
                                disabled={
                                    isSaving ||
                                    loadingExport ||
                                    isOperatingOnRowAt !== null
                                }
                                onClick={() => setOpenExport('table')}
                            >
                                {t('Export as PDF using table')}
                            </Button>
                        </BasicTooltip>
                    </>
                }
            />

            <div className="page-container">
                {loading ? (
                    <Loading />
                ) : (
                    <div className="flex flex-col gap-16">
                        <div className="flex flex-col gap-4 rounded-md bg-layout-light p-4">
                            <h3 className="text-center text-2xl">
                                {t('Languages section')}
                            </h3>
                            <div className="flex flex-col gap-4">
                                <div className="flex w-full flex-col gap-2 rounded-md bg-layout-lighter p-2">
                                    <h4>{t('RIEM languages')}</h4>
                                    <div className="flex flex-wrap gap-2">
                                        {riems.map((riem, index) => {
                                            return (
                                                <div
                                                    key={riem.id}
                                                    className="flex flex-col gap-2 rounded-sm bg-layout-light p-2"
                                                >
                                                    <p>{riem.name}</p>

                                                    <BasicTooltip
                                                        tip={t(
                                                            isOperatingOnRowAt !==
                                                                null
                                                                ? "You can't interact with the languages while you are operation on a row"
                                                                : "The main language can't be deleted",
                                                        )}
                                                        disabled={
                                                            !canAll ||
                                                            (index !== 0 &&
                                                                isOperatingOnRowAt ===
                                                                    null)
                                                        }
                                                    >
                                                        <ErrorButton
                                                            disabled={
                                                                !canAll ||
                                                                isSaving ||
                                                                index === 0 ||
                                                                isOperatingOnRowAt !==
                                                                    null
                                                            }
                                                            onClick={() =>
                                                                handleDeleteLanguage(
                                                                    riem.id,
                                                                )
                                                            }
                                                            endIcon={
                                                                <DeleteIcon />
                                                            }
                                                            size="small"
                                                        >
                                                            {t(
                                                                'Delete language',
                                                            )}
                                                        </ErrorButton>
                                                    </BasicTooltip>
                                                </div>
                                            );
                                        })}
                                        {!isAddingLanguage && (
                                            <BasicTooltip
                                                disabled={
                                                    !canAll ||
                                                    isOperatingOnRowAt === null
                                                }
                                                tip={t(
                                                    "You can't interact with the languages while you are operation on a row",
                                                )}
                                            >
                                                <Button
                                                    style={{
                                                        minWidth: '3rem',
                                                        width: '3rem',
                                                        height: '3rem',
                                                    }}
                                                    onClick={() => {
                                                        setIsAddingLanguage(
                                                            true,
                                                        );
                                                        setTimeout(() => {
                                                            const actualInput =
                                                                languageInputRef.current.querySelector(
                                                                    'input',
                                                                );
                                                            actualInput.focus();
                                                        });
                                                    }}
                                                    disabled={
                                                        !canAll ||
                                                        isSaving ||
                                                        isOperatingOnRowAt !==
                                                            null
                                                    }
                                                >
                                                    <AddIcon fontSize="large" />
                                                </Button>
                                            </BasicTooltip>
                                        )}
                                    </div>
                                </div>
                                {isAddingLanguage && (
                                    <form
                                        className="flex max-w-xl items-center gap-4 rounded-md bg-layout-lighter p-2 lg:flex-col"
                                        onSubmit={handleAddLanguage}
                                    >
                                        <TextField
                                            ref={languageInputRef}
                                            placeholder={t('Language')}
                                            type="string"
                                            value={languageName}
                                            onChange={(e) =>
                                                setLanguageName(e.target.value)
                                            }
                                        />

                                        <div className="ml-auto flex gap-2">
                                            <Button
                                                color="secondary"
                                                type="button"
                                                onClick={() => {
                                                    setLanguageName('');
                                                    setIsAddingLanguage(false);
                                                }}
                                            >
                                                {t('Cancel')}
                                            </Button>
                                            <BasicTooltip
                                                disabled={
                                                    isOperatingOnRowAt === null
                                                }
                                                tip={t(
                                                    "You can't interact with the languages while you are operation on a row",
                                                )}
                                            >
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    disabled={
                                                        isOperatingOnRowAt !==
                                                        null
                                                    }
                                                >
                                                    {t('Add language')}
                                                </Button>
                                            </BasicTooltip>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col gap-4">
                            <div className="flex gap-4">
                                <div className="flex w-full items-center justify-center gap-4 rounded-md bg-layout-light p-4">
                                    <BasicTooltip
                                        disabled={isOperatingOnRowAt === null}
                                        tip={t(
                                            "You can't change the language while you are operating on a row",
                                        )}
                                    >
                                        <ActionButton
                                            size={9}
                                            icon={<ChevronLeftIcon />}
                                            onClick={handlePrevLanguage}
                                            disabled={
                                                isSaving ||
                                                displayedRiem === 0 ||
                                                isOperatingOnRowAt !== null
                                            }
                                        />
                                    </BasicTooltip>
                                    <p className="whitespace-nowrap text-2xl sm:text-xl">
                                        {riems[displayedRiem].name}
                                    </p>
                                    <BasicTooltip
                                        disabled={isOperatingOnRowAt === null}
                                        tip={t(
                                            "You can't change the language while you are operating on a row",
                                        )}
                                    >
                                        <ActionButton
                                            size={9}
                                            icon={<ChevronRightIcon />}
                                            onClick={handleNextLanguage}
                                            disabled={
                                                isSaving ||
                                                displayedRiem ===
                                                    riems.length - 1 ||
                                                isOperatingOnRowAt !== null
                                            }
                                        />
                                    </BasicTooltip>
                                </div>
                            </div>

                            <div className="flex flex-col gap-8">
                                <div
                                    className="grid w-full"
                                    style={{
                                        gridTemplateColumns:
                                            'minmax(0, 1fr) 9rem',
                                        gridTemplateRows: `repeat(${
                                            riems[displayedRiem].items.length +
                                            3
                                        }, min-content)`,
                                    }}
                                >
                                    <div
                                        className="row-span-full grid overflow-x-auto"
                                        style={{
                                            gridTemplateRows: 'subgrid',
                                        }}
                                    >
                                        <RiemContext.Provider
                                            value={{
                                                canEdit: canAll,
                                                isSaving,
                                                riems,
                                                displayedRiem,
                                                isOperatingOnRowAt,
                                                setIsOperatingOnRowAt,
                                                onAddItem: handleAddItem,
                                                onRemoveItem: handleRemoveItem,
                                                onChangeItem: handleChangeItem,
                                                onMoveItem: handleMoveItem,
                                                onEditInfo: handleEditInfo,
                                            }}
                                        >
                                            <RiemTable
                                                companyLogo={companyLogo}
                                                riem={riems[displayedRiem]}
                                            />
                                        </RiemContext.Provider>
                                    </div>

                                    <div
                                        className="row-span-full grid"
                                        style={{
                                            gridTemplateRows: 'subgrid',
                                        }}
                                    >
                                        <div
                                            className="row-start-1 flex items-center justify-center border border-layout-main bg-layout-light p-2"
                                            id={`riem-info-container`}
                                        ></div>
                                        <div
                                            className="row-start-2 flex items-center justify-center border border-layout-main bg-layout-light p-2"
                                            id={`riem-header-container`}
                                        ></div>

                                        {Array.from(
                                            {
                                                length: riems[displayedRiem]
                                                    .items.length,
                                            },
                                            (_, i) => i,
                                        ).map((_, index) => (
                                            <div
                                                className="flex items-center justify-center border border-layout-main bg-layout-light p-2"
                                                key={index}
                                                style={{
                                                    gridRowStart: index + 3,
                                                }}
                                                id={`riem-actions-container-${index}`}
                                            ></div>
                                        ))}

                                        <div
                                            className="flex items-center justify-center border border-layout-main bg-layout-light p-2"
                                            id={`riem-footer-container`}
                                            style={{
                                                gridRowStart:
                                                    riems[displayedRiem].items
                                                        .length + 4,
                                            }}
                                        ></div>
                                    </div>
                                </div>

                                <div className="inline-flex">
                                    <BasicTooltip
                                        position="right"
                                        tip={t(
                                            displayedRiem !== 0
                                                ? 'You can add new rows only on the main language table'
                                                : "You can't add new rows while you create or edit an existing one",
                                        )}
                                        disabled={
                                            !canAll ||
                                            (isOperatingOnRowAt === null &&
                                                displayedRiem === 0)
                                        }
                                    >
                                        <Button
                                            disabled={
                                                !canAll ||
                                                isSaving ||
                                                isOperatingOnRowAt !== null ||
                                                displayedRiem !== 0
                                            }
                                            startIcon={<AddIcon />}
                                            onClick={() => {
                                                setIsOperatingOnRowAt(
                                                    riems[displayedRiem].items
                                                        .length,
                                                );
                                                handleAddItem(
                                                    DEFAULT_ITEM,
                                                    riems[displayedRiem].items
                                                        .length,
                                                );
                                            }}
                                        >
                                            {t('Add new row')}
                                        </Button>
                                    </BasicTooltip>
                                </div>

                                <div className="mt-8 flex flex-col gap-8">
                                    <RiemLegend riem={riems[displayedRiem]} />
                                    <div className="inline-flex">
                                        <BasicTooltip
                                            position="right"
                                            tip={t(
                                                "You can't edit the legend while you are operating on a row",
                                            )}
                                            disabled={
                                                !canAll ||
                                                isOperatingOnRowAt === null
                                            }
                                        >
                                            <Button
                                                disabled={
                                                    !canAll ||
                                                    isSaving ||
                                                    isOperatingOnRowAt !== null
                                                }
                                                startIcon={<EditIcon />}
                                                onClick={() => {
                                                    setOpenEditor(true);
                                                }}
                                            >
                                                {t('Edit legend')}
                                            </Button>
                                        </BasicTooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inline-flex">
                            <BasicTooltip
                                position="right"
                                tip={t(
                                    "You can't save the RIEM changes while you are operating on a row",
                                )}
                                disabled={
                                    !canAll || isOperatingOnRowAt === null
                                }
                            >
                                <Button
                                    color="secondary"
                                    disabled={
                                        !canAll ||
                                        isSaving ||
                                        isOperatingOnRowAt !== null
                                    }
                                    onClick={handleSaveRiemChanges}
                                >
                                    {isSaving ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        t('Save RIEM changes')
                                    )}
                                </Button>
                            </BasicTooltip>
                        </div>
                    </div>
                )}
            </div>

            {openExport && (
                <RiemExportModal
                    open={openExport}
                    setOpen={setOpenExport}
                    riems={riems}
                    disabled={loadingExport}
                    handleExport={handleExport}
                />
            )}

            {openEditor && (
                <CustomModal open={open}>
                    <EditElementModal
                        onClose={handleCloseModal}
                        value={riems[displayedRiem].legend.content}
                        onFinish={handleEditText}
                    />
                </CustomModal>
            )}
        </>
    );
};

Riem.propTypes = {
    project: PropTypes.object,
};

Riem.defaultProps = {
    project: null,
};

export default Riem;
