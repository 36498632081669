import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CustomModal from 'components/modals/custom_modal';
import EmailNotificationButtons from 'components/shared/email-notification/email-notification-buttons';
import EmailNotificationDocuments from 'components/shared/email-notification/email-notification-documents';
import EmailNotificationInfo from 'components/shared/email-notification/email-notification-info';
import EmailNotificationLoading from 'components/shared/email-notification/email-notification-loading';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useScreenSizes from 'hooks/useScreenSizes';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { errorHandling, generateUUID, uploadMultiFiles } from 'utils';
import API from 'utils/axios';
import { getProjectAffiliates, getProjectUsers } from 'utils/getterFunctions';

const EmailNotification = ({ open, cancel }) => {
    const { t } = useTranslation();
    const { tenant } = useContext(GlobalContext);
    const { user } = useContext(UserContext);
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [width] = useScreenSizes();
    const [loading, setLoading] = useState(true);

    const [emailSubject, setEmailSubject] = useState('');
    const [emailTo, setEmailTo] = useState([]);
    const [emailCC, setEmailCC] = useState([]);
    const [emailBCC, setEmailBCC] = useState([]);
    const [emailReplyTo, setEmailReplyTo] = useState([]);
    const [emailOptions, setEmailOptions] = useState([]);
    const [emailOptionsCC, setEmailOptionsCC] = useState([]);
    const [emailOptionsBCC, setEmailOptionsBCC] = useState([]);
    const [sendingEmail, setSendingEmail] = useState(false);

    const [files, setFiles] = useState([]);

    const setCompanyDetails = () => {
        setEmailSubject(tenant.companyName);
    };

    const getProjectDetails = async (projectId) => {
        try {
            const response = await API.get('/pm_projectPlanningOverview', {
                params: {
                    id: projectId,
                },
            });

            setEmailSubject(response.data.pm.name);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                let users = [];
                let emailReplyToUsers = [];

                const handleFormatClients = (clients) =>
                    clients.data.data.contacts.map((client) => {
                        const clientFields =
                            typeof client.data === 'string'
                                ? JSON.parse(client.data)
                                : client.data;
                        const profile = Object.assign(
                            {},
                            ...Object.values(clientFields),
                        );
                        client.email = profile.email;
                        delete profile.email;
                        delete client.data;

                        return { ...client, profile };
                    });

                if (!projectId) {
                    const [tenantUsers, clientAccounts] = await Promise.all([
                        API.get('/tenant'),
                        API.get('/contacts', {
                            params: {
                                currentPage: 0,
                                perPage: 99999,
                                pagesToLoad: 1,
                                type: 'ALL',
                            },
                        }),
                    ]);

                    const formattedClients =
                        handleFormatClients(clientAccounts);

                    users = [
                        ...tenantUsers.data.data.tenantAccounts,
                        ...formattedClients,
                    ];
                    emailReplyToUsers = users.filter(
                        (value) => value.id === user.id,
                    );

                    setCompanyDetails();
                }

                if (projectId) {
                    const [projectAccounts, projectAffiliates, clientAccounts] =
                        await Promise.all([
                            getProjectUsers({ pmProjectId: projectId }),
                            getProjectAffiliates({ pmProjectId: projectId }),
                            API.get('/contacts', {
                                params: {
                                    currentPage: 0,
                                    perPage: 99999,
                                    pagesToLoad: 1,
                                    type: 'ALL',
                                },
                            }),
                        ]);

                    const formattedClients =
                        handleFormatClients(clientAccounts);

                    users = [
                        ...projectAccounts,
                        ...projectAffiliates,
                        ...formattedClients,
                    ];
                    emailReplyToUsers = users.filter(
                        (value) => value.id === user.id,
                    );

                    await getProjectDetails(projectId);
                }

                setEmailReplyTo(emailReplyToUsers);
                setEmailOptions(users);
                setEmailOptionsCC(users);
                setEmailOptionsBCC(users);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleSubjectChange = (e) => setEmailSubject(e.target.value);

    const handleAddOption = (data, type) => {
        if (type === 'to') {
            setEmailOptions((prev) => [
                ...prev,
                { email: data.email, profile: { name: data.alias } },
            ]);
            setEmailTo((prev) => [
                ...prev,
                { email: data.email, profile: { name: data.alias } },
            ]);
        }

        if (type === 'cc') {
            setEmailOptionsCC((prev) => [
                ...prev,
                { email: data.email, profile: { name: data.alias } },
            ]);
            setEmailCC((prev) => [
                ...prev,
                { email: data.email, profile: { name: data.alias } },
            ]);
        }

        if (type === 'bcc') {
            setEmailOptionsBCC((prev) => [
                ...prev,
                { email: data.email, profile: { name: data.alias } },
            ]);
            setEmailBCC((prev) => [
                ...prev,
                { email: data.email, profile: { name: data.alias } },
            ]);
        }
    };

    const handleAddFiles = (e) => {
        uploadMultiFiles(
            e,
            ({ message, blob, info }) => {
                if (message) {
                    enqueueSnackbar(t(message), {
                        variant: 'error',
                    });
                } else if (blob && info) {
                    const newFiles = [];

                    for (let i = 0; i < blob.length; i++) {
                        newFiles.push({
                            id: generateUUID(),
                            blob: blob[i],
                            name: info[i].name,
                        });
                    }

                    setFiles((prev) => [...prev, ...newFiles]);
                }
            },
            'all',
        );
    };

    const handleDeleteFile = (id) => {
        setFiles((prev) => prev.filter((file) => file.id !== id));
    };

    const validateForm = (emailTo, emailSubject, emailReplyTo) => {
        if (emailTo.length === 0) {
            enqueueSnackbar(t('You must select at least one recipient'), {
                variant: 'error',
            });
            return false;
        }

        if (!emailReplyTo) {
            enqueueSnackbar(
                t('You must select one person to witch you respond'),
                { variant: 'error' },
            );
            return false;
        }
        if (emailSubject.trim().length === 0) {
            enqueueSnackbar(t('You must add a subject'), { variant: 'error' });
            return false;
        }

        return true;
    };

    const sendNotification = async (
        html,
        emailTo,
        emailCC,
        emailBCC,
        emailSubject,
        emailReplyTo,
    ) => {
        if (!validateForm(emailTo, emailSubject, emailReplyTo)) return;

        setSendingEmail(true);

        const reqBody = new FormData();
        reqBody.append(
            'data',
            JSON.stringify({
                html,
                emailTo: emailTo.map((item) => item.email),
                emailCC: emailCC.map((item) => item.email),
                emailBCC: emailBCC.map((item) => item.email),
                emailSubject,
                emailReplyTo: emailReplyTo.email,
            }),
        );

        if (files.length) {
            files.forEach((file) => {
                reqBody.append('files', file.blob);
            });
        }

        try {
            await API.post('/sendNotification', reqBody, {
                'Content-Type': 'multipart/form-data',
            });
            enqueueSnackbar(t('Notification sent successfully!'), {
                variant: 'success',
            });
            cancel();
        } catch (err) {
            console.error(err);
            enqueueSnackbar(t(errorHandling(err)), { variant: 'error' });
        } finally {
            setSendingEmail(false);
        }
    };

    if (loading) {
        return <EmailNotificationLoading />;
    }

    return (
        <CustomModal open={open}>
            <div
                className="w-full overflow-y-auto overflow-x-hidden rounded-xl p-px"
                style={{
                    maxHeight: '65vh',
                    width: width > 600 ? '80vw' : '90vw',
                }}
            >
                <div className="m-0 flex w-full flex-col items-center justify-center rounded-t-xl">
                    <EmailNotificationInfo
                        emailToUsers={emailTo}
                        emailOptions={emailOptions}
                        handleEmailToChange={(_, value) => setEmailTo(value)}
                        emailCcUsers={emailCC}
                        emailCcOptions={emailOptionsCC}
                        handleEmailCcChange={(_, value) => setEmailCC(value)}
                        emailBccUsers={emailBCC}
                        emailBccOptions={emailOptionsBCC}
                        handleEmailBccChange={(_, value) => setEmailBCC(value)}
                        handleEmailReplyToChange={(_, value, reason) => {
                            if (reason === 'remove-option') {
                                setEmailReplyTo([]);
                            }
                            if (reason === 'select-option') {
                                const len = value.length;
                                setEmailReplyTo([value[len - 1]]);
                            }
                        }}
                        emailReplyToUsers={emailReplyTo}
                        handleAddOption={handleAddOption}
                        emailSubject={emailSubject}
                        handleSubjectChange={handleSubjectChange}
                    />
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbarStyle={{
                            margin: 2,
                            padding: 2,
                            borderRadius: 0,
                            backgroundColor: `var(--layout-light)`,
                            border: 'none',
                            marginTop: '8px',
                            color: 'black',
                        }}
                        wrapperStyle={{
                            width: '100%',
                        }}
                        editorStyle={{
                            borderRadius: 0,
                            backgroundColor: 'white',
                            padding: '0px 8px',
                            minHeight: '20rem',
                            maxHeight: width <= 750 ? '' : '40rem',
                            lineHeight: 1.2,
                            color: 'black',
                        }}
                        toolbar={{
                            options: [
                                'inline',
                                'blockType',
                                'fontSize',
                                'list',
                                'textAlign',
                                'colorPicker',
                                'link',
                                'remove',
                                'history',
                            ],
                        }}
                    />
                    <EmailNotificationDocuments
                        files={files}
                        setFiles={setFiles}
                        onAddFiles={handleAddFiles}
                        onDeleteFile={handleDeleteFile}
                    />
                </div>
            </div>

            <EmailNotificationButtons
                send={() => {
                    const htmlContent = DOMPurify.sanitize(
                        draftToHtml(
                            convertToRaw(editorState.getCurrentContent()),
                        ).replaceAll('color: currentcolor;', ''),
                    );

                    sendNotification(
                        htmlContent,
                        emailTo,
                        emailCC,
                        emailBCC,
                        emailSubject,
                        emailReplyTo[0],
                    );
                }}
                cancel={cancel}
                disableSend={sendingEmail}
            />
        </CustomModal>
    );
};

EmailNotification.propTypes = {
    open: PropTypes.bool,
    cancel: PropTypes.func,
};

EmailNotification.defaultProps = {
    open: PropTypes.false,
    cancel: () => {},
};

export default EmailNotification;
