import React, { useState } from 'react';

import { CircleCheck, CircleX } from 'lucide-react';

import { Button } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    DatePicker,
    Dropdown,
    LabelWrapper,
    MultiDropdown,
} from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const types = ['USERS', 'PROJECT'];

const ModalReportAgentsContacts = ({
    open,
    onClose,
    onFilter,
    users,
    projects,
    filteredOptions,
    setFilteredOptions,
}) => {
    const { t } = useTranslation();

    const [selection, setSelection] = useState(filteredOptions);

    const mappedOptionsList = {
        USERS: users,
        PROJECT: projects,
    };
    const optionsList = mappedOptionsList[selection.type];

    const handleChange = (key, value) =>
        setSelection((prev) => ({ ...prev, [key]: value }));
    const handleMultiDropdownChange = (i, key) => {
        const value = optionsList[i].id;
        let newOptions = [...selection.options];

        const isPresent = newOptions.indexOf(value) >= 0;
        if (isPresent) newOptions = newOptions.filter((v) => v !== value);
        else newOptions.push(value);

        handleChange(key, newOptions);
    };

    const handleFinish = () => {
        setFilteredOptions(selection);
        onFilter(selection);
        onClose();
    };

    return (
        <CustomModal open={open}>
            <div className="flex w-2xl flex-col items-center gap-16 p-10 sm:w-full">
                <h2>{t('Filter data')}</h2>

                <div className="flex w-full flex-col gap-4">
                    <LabelWrapper label={t('Choose filter method')}>
                        <Dropdown
                            options={types.map((type) =>
                                t(
                                    `${type.charAt(0)}${type.slice(1).toLowerCase()}`,
                                ),
                            )}
                            placeholder={t('Choose filter method')}
                            selectedOption={types.indexOf(selection.type)}
                            setSelectedOption={(i) => {
                                handleChange('type', types[i]);
                                handleChange('options', []);
                            }}
                        />
                    </LabelWrapper>

                    {selection.type === 'USERS' && (
                        <LabelWrapper label={t('Select agents')}>
                            <MultiDropdown
                                options={users.map((user) => user.profile.name)}
                                placeholder={t('Select agents')}
                                selectedOptions={selection.options.map(
                                    (option) =>
                                        users.findIndex((p) => p.id === option),
                                )}
                                setSelectedOptions={(i) =>
                                    handleMultiDropdownChange(i, 'options')
                                }
                            />
                        </LabelWrapper>
                    )}

                    {selection.type === 'PROJECT' && (
                        <LabelWrapper label={t('Select project')}>
                            <MultiDropdown
                                options={projects.map(
                                    (project) => project.name,
                                )}
                                placeholder={t('Select project')}
                                selectedOptions={selection.options.map(
                                    (option) =>
                                        projects.findIndex(
                                            (p) => p.id === option,
                                        ),
                                )}
                                setSelectedOptions={(i) =>
                                    handleMultiDropdownChange(i, 'options')
                                }
                            />
                        </LabelWrapper>
                    )}

                    <div className="flex items-center gap-4">
                        <LabelWrapper label={t('Start date')}>
                            <DatePicker
                                date={selection.startDate}
                                setDate={(d) => handleChange('startDate', d)}
                            />
                        </LabelWrapper>
                        <LabelWrapper label={t('End date')}>
                            <DatePicker
                                date={selection.endDate}
                                setDate={(d) => handleChange('endDate', d)}
                            />
                        </LabelWrapper>
                    </div>
                </div>

                <div className="flex w-full justify-end gap-4">
                    <Button
                        onClick={onClose}
                        startIcon={<CircleX />}
                        color="secondary"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        onClick={handleFinish}
                        startIcon={<CircleCheck />}
                        color="secondary"
                    >
                        {t('Filter data')}
                    </Button>
                </div>
            </div>
        </CustomModal>
    );
};

ModalReportAgentsContacts.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFilter: PropTypes.func,
    users: PropTypes.array,
    projects: PropTypes.array,
    filteredOptions: PropTypes.array,
    setFilteredOptions: PropTypes.func,
};

ModalReportAgentsContacts.defaultProps = {
    open: false,
    onClose: () => {},
    onFilter: () => {},
    users: [],
    projects: [],
    filteredOptions: { type: 'COMPANY', options: [] },
    setFilteredOptions: () => {},
};

export default ModalReportAgentsContacts;
