import { getTextHeight } from 'utils';

const INITIAL_HEIGHT = 90;
const DEFAULT_DISABLED_CROP_HEIGHTS_INTERVALS = [[0, INITIAL_HEIGHT]];

export const getRowsHeights = (ref, data, keys, lengthDivider, offsetY) => {
    let currentHeight = INITIAL_HEIGHT;
    const newDisabledCropHeightsIntervals = [];

    const heights = data.excel.map((xlsx) => {
        const height =
            Math.max(
                ...keys.map((key) => getTextHeight(xlsx[key] || '', 'text-xl font-medium break-words', lengthDivider)),
            ) + offsetY;

        newDisabledCropHeightsIntervals.push([currentHeight, currentHeight + height]);
        currentHeight += height;

        return `${height}px`;
    });

    if (ref && ref.current)
        ref.current = [...DEFAULT_DISABLED_CROP_HEIGHTS_INTERVALS, ...newDisabledCropHeightsIntervals];

    return heights;
};
