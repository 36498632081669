import React from 'react';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';

const NavLinkTab = (props) => {
    const { children, route, small, disabled } = props;

    return (
        <NavLink
            to={route}
            className={(isActive) =>
                `nav-link-tab ${isActive ? 'active-nav-link-tab' : ''} ${disabled ? 'disabled-nav-link-tab' : ''}`
            }
        >
            <div className="flex h-full items-center justify-center overflow-hidden rounded-lg bg-transparent transition-colors">
                <p
                    className={`user-select-none flex cursor-pointer items-center gap-2 whitespace-nowrap font-medium transition-colors ${
                        small ? 'px-4 py-3' : 'px-6 py-4 sm:px-4 sm:py-3'
                    }`}
                >
                    {children}
                </p>
            </div>
        </NavLink>
    );
};

NavLinkTab.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    route: PropTypes.string,
};

NavLinkTab.defaultProps = {
    children: null,
    small: false,
    disabled: true,
    route: '',
};

export const NavLinkTabs = (props) => {
    const { tabs, small, disabled } = props;

    return (
        <div className="no-user-select-recursive relative inline-block">
            <div className="text-main-text ">
                <div className="flex flex-wrap justify-center gap-2 rounded-xl bg-layout-transparent p-1 md:flex-row">
                    {tabs.map((tab, index) => {
                        const { children, route } = tab;

                        return (
                            <div
                                // eslint-disable-next-line
                                key={`TAB-${index}`}
                                className={`last:mr-0 ${small ? 'mr-0.5' : 'mr-1'} ${
                                    disabled ? 'pointer-events-none' : ''
                                }`}
                            >
                                <NavLinkTab route={route} small={small} disabled={disabled}>
                                    {children}
                                </NavLinkTab>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

NavLinkTabs.propTypes = {
    tabs: PropTypes.array,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
};
NavLinkTabs.defaultProps = {
    tabs: [],
    small: false,
    disabled: false,
};
