import React, { useEffect, useRef } from 'react';

import useElementRect from 'hooks/useElementRect';
import PropTypes from 'prop-types';
import { findParentWithoutCSS } from 'utils';

const placementOrders = {
    top: ['top', 'right', 'bottom', 'left'],
    right: ['right', 'top', 'bottom', 'left'],
    bottom: ['bottom', 'top', 'right', 'left'],
    left: ['left', 'top', 'right', 'bottom'],
};

const BasicTooltip = (props) => {
    const { children, tip, textStyles, layoutStyles, position, disabled } =
        props;

    const wrapperRef = useRef(null);
    const viewportRef = useRef(null);

    useEffect(() => {
        viewportRef.current = findParentWithoutCSS(
            wrapperRef.current,
            'overflow',
            'visible',
        );
    }, []);

    const {
        viewport: {
            top: viewportTop,
            bottom: viewportBottom,
            left: viewportLeft,
            right: viewportRight,
        },
    } = useElementRect(viewportRef);

    useEffect(() => {
        if (!viewportRef.current) return;

        const containerElement =
            wrapperRef.current.querySelector('#tooltip-container');
        const childrenElement =
            wrapperRef.current.querySelector('#tooltip-children');

        const containerRect = containerElement.getBoundingClientRect();
        const childrenRect = childrenElement.getBoundingClientRect();

        //? We calculate the position of the container if placed above the children
        const topResult = childrenRect.top - containerRect.height;
        //? We calculate the position of the container if placed to the left of the children
        const leftResult = childrenRect.left - containerRect.width;
        //? We calculate the position of the container if placed below the children
        const bottomResult = childrenRect.bottom + containerRect.height;
        //? We calculate the position of the container if placed to the right of the children
        const rightResult = childrenRect.right + containerRect.width;

        const exceedsX =
            containerRect.left < viewportLeft ||
            containerRect.right > viewportRight;
        const exceedsY =
            containerRect.top < viewportTop ||
            containerRect.bottom > viewportBottom;

        //? We select the order based on the specified position
        const order = placementOrders[position];
        //? We will try to place the element in the specified position
        //! If we can't place it in the specified position, we will try to place it in any other position based on the specified order
        for (const placement of order) {
            if (placement === 'top') {
                const overflowsTop = topResult < viewportTop;

                if (!overflowsTop && !exceedsX) {
                    containerElement.style.top = 0;
                    containerElement.style.bottom = 'auto';
                    containerElement.style.left = '50%';
                    containerElement.style.right = 'auto';
                    containerElement.style.transform = `translate(-50%, -${containerRect.height}px)`;
                    break;
                }
            }

            if (placement === 'right' && !exceedsY) {
                const overflowsRight = rightResult > viewportRight;

                if (!overflowsRight) {
                    containerElement.style.top = '50%';
                    containerElement.style.bottom = 'auto';
                    containerElement.style.left = 'auto';
                    containerElement.style.right = 0;
                    containerElement.style.transform = `translate(${containerRect.width}px, -50%)`;
                    break;
                }
            }

            if (placement === 'bottom' && !exceedsX) {
                const overflowsBottom = bottomResult > viewportBottom;

                if (!overflowsBottom) {
                    containerElement.style.top = 'auto';
                    containerElement.style.bottom = 0;
                    containerElement.style.left = '50%';
                    containerElement.style.right = 'auto';
                    containerElement.style.transform = `translate(-50%, ${containerRect.height}px)`;
                    break;
                }
            }

            if (placement === 'left' && !exceedsY) {
                const overflowsLeft = leftResult < viewportLeft;

                if (!overflowsLeft) {
                    containerElement.style.top = '50%';
                    containerElement.style.bottom = 'auto';
                    containerElement.style.left = 0;
                    containerElement.style.right = 'auto';
                    containerElement.style.transform = `translate(-${containerRect.width}px, -50%)`;
                    break;
                }
            }
        }
    });

    return (
        <div ref={wrapperRef} id="tooltip" className="basic-tooltip relative">
            <div
                id="tooltip-container"
                className={`pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-md p-2 transition-all duration-200 sm:hidden ${
                    !disabled
                        ? 'basic-tooltip-toggle'
                        : 'basic-tooltip-disabled'
                }`}
                style={{
                    zIndex: 'calc(infinity)',
                    backgroundColor: 'rgb(var(--base-layout-light) / 90%)',
                    ...layoutStyles,
                }}
            >
                <p
                    className="w-max max-w-xs text-center"
                    style={{
                        ...textStyles,
                    }}
                >
                    {tip}
                </p>
            </div>

            <div id="tooltip-children">{children}</div>
        </div>
    );
};

BasicTooltip.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    tip: PropTypes.string,
    textStyles: PropTypes.object,
    layoutStyles: PropTypes.object,
    position: PropTypes.string,
    disabled: PropTypes.bool,
};

BasicTooltip.defaultProps = {
    children: null,
    tip: '',
    textStyles: {},
    layoutStyles: {},
    position: 'top',
    disabled: false,
};

export default BasicTooltip;
