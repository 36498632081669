import React from 'react';

import { Checkbox } from '@material-ui/core';

import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';

/**
     * expenseRevenue is the array that goes in the request body when adding a new contract type
     * Example:
        "expenseRevenueType": [
            {
                "expenseRevenueId": "cl4p8z2qj0003uywfd07i7804",
                "reportingToolsId": ["cl4p8zpqc0013uywfi00ajrgk"],
                "type": 'expense'
            },
            {
                "expenseRevenueId": "cl4p8z2qj0003uywfd07i7804",
                "reportingToolsId": ["cl4p8zpqc0013uywfi00ajrgk"],
                "type": 'revenue'
            }
	    ]
     */

const ReportingTools = (props) => {
    const { t } = useTranslation();

    const {
        reportingTools,
        expenseRevenue,
        setExpenseRevenue,
        expenses,
        revenues,
        expensesAndRevenues, // This is the array used for displaying the selected expenses/revenues
        setExpensesAndRevenues,
        selectedExpenses,
        setSelectedExpenses,
        setSelectedRevenues,
        selectedRevenues,
        hideElements,
    } = props;

    const handleChange = (e, expenseRevenueType, tool) => {
        // Find out if this expenseRevenueType has already been added to the array
        const shallow = [...expenseRevenue];
        const expenseRevenueIndex = shallow.findIndex((er) => er.expenseRevenueId === expenseRevenueType.id);

        if (expenseRevenueIndex > -1) {
            // If the expenseRevenueType is on expenseRevenue we add the checked tool id on reportingToolsId
            if (e.target.checked) shallow[expenseRevenueIndex].reportingToolsId.push(tool.id);
            // Else we delete from reporting tools ids array the id of the unchecked tool
            else {
                shallow[expenseRevenueIndex].reportingToolsId = shallow[expenseRevenueIndex].reportingToolsId.filter(
                    (t) => t !== tool.id,
                );

                // If there are no tool ids left, delete the whole item
                if (!shallow[expenseRevenueIndex].reportingToolsId.length) shallow.splice(expenseRevenueIndex, 1);
            }
        } else {
            const newItem = {
                expenseRevenueId: expenseRevenueType.id,
                reportingToolsId: [tool.id],
                type: expenseRevenueType.type,
            };

            shallow.push(newItem);
        }
        setExpenseRevenue(shallow);
    };

    return (
        <div
            className="mb-8 inline-flex w-full max-w-xl flex-col rounded-md sm:max-w-full"
            style={{
                backgroundColor: 'rgb(var(--base-layout-transparent) / 5%)',
                border: '1px solid var(--layout-transparent)',
            }}
        >
            {/* Section with the reporting tools name - this is displayed only if are at least one expense/revenue selected */}
            {expensesAndRevenues.length > 0 && (
                <div
                    className="flex items-center justify-end gap-6  py-2 pb-4 pl-8 pr-8 pt-4 text-main-text"
                    style={{ borderBottom: '1px solid var(--layout-transparent)' }}
                >
                    {reportingTools.map((tool) => (
                        <div
                            key={tool.id}
                            className="flex items-center justify-center text-lg font-semibold"
                            style={{ maxWidth: '20rem' }}
                        >
                            {tool.name}
                        </div>
                    ))}
                </div>
            )}

            {/* Section with the name of the selected expenses and revenues and with the reporting tools checkboxes */}
            {expensesAndRevenues.map((expenseRevenueType, index) => {
                let expenseRevenueItem = expenseRevenue.filter((e) => e.expenseRevenueId === expenseRevenueType.id);

                if (expenseRevenueItem.length > 0) expenseRevenueItem = expenseRevenueItem[0];
                else expenseRevenueItem = null;

                return (
                    <div
                        key={expenseRevenueType.id}
                        className={`flex items-center  pl-8 pr-8 ${
                            index % 2 === 0 ? '' : 'bg-layout-transparent-dark'
                        }`}
                        style={{
                            borderBottom: '1px solid var(--layout-transparent)',
                        }}
                    >
                        <div className="flex py-3 text-lg text-main-text">{expenseRevenueType.name}</div>
                        <div className="flex flex-grow justify-end">
                            {reportingTools.map((tool) => {
                                const checked = expenseRevenueItem
                                    ? expenseRevenueItem.reportingToolsId.findIndex((t) => t === tool.id) > -1
                                        ? true
                                        : false
                                    : false;
                                return (
                                    <div key={tool.id}>
                                        <Checkbox
                                            color="primary"
                                            onChange={(e) => {
                                                if (!hideElements) {
                                                    handleChange(e, expenseRevenueType, tool);
                                                }
                                            }}
                                            checked={checked}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}

            {/* Section with the expenses and revenues dropdowns */}
            {!hideElements && (
                <div className="flex items-center gap-4 pb-4 pl-8 pr-8 pt-4 text-lg text-main-text sm:flex-col sm:justify-center">
                    <div>
                        <LabelWrapper label={t('Expenses')}>
                            <MultiDropdown
                                variant="black"
                                placeholder={t('Add expenses')}
                                options={expenses.map((expense) => expense.name)}
                                selectedOptions={selectedExpenses}
                                setSelectedOptions={(newId) => {
                                    if (selectedExpenses.indexOf(newId) > -1) {
                                        /**
                                         * If the selected expense is already selected we will remove from selectedExpenses
                                         * and we remove the selected expense from the expensesAndRevenues array and from expenseRevenue array
                                         * ATTENTION: this is also available for the revenues
                                         */
                                        setSelectedExpenses(selectedExpenses.filter((opt) => opt !== newId));

                                        setExpensesAndRevenues(
                                            expensesAndRevenues.filter((e) => e.id !== expenses[newId].id),
                                        );

                                        setExpenseRevenue(
                                            expenseRevenue.filter((e) => e.expenseRevenueId !== expenses[newId].id),
                                        );
                                    } else {
                                        /**
                                         * else we will add the selected expense to selectedExpenses and add a new object in expensesAndRevenues with expense info
                                         * ATTENTION: this is also available for the revenues
                                         */
                                        setSelectedExpenses([...selectedExpenses, newId]);

                                        // We unshift the expenses objects on array to preserve the order - expenses first and revenues after
                                        expensesAndRevenues.unshift({
                                            id: expenses[newId].id,
                                            name: expenses[newId].name,
                                            type: 'expense',
                                        });
                                    }
                                }}
                            />
                        </LabelWrapper>
                    </div>

                    <div>
                        <LabelWrapper label={t('Revenues')}>
                            <MultiDropdown
                                variant="black"
                                placeholder={t('Add revenues')}
                                options={revenues.map((revenue) => revenue.name)}
                                selectedOptions={selectedRevenues}
                                setSelectedOptions={(newId) => {
                                    if (selectedRevenues.indexOf(newId) > -1) {
                                        setSelectedRevenues(selectedRevenues.filter((opt) => opt !== newId));
                                        setExpensesAndRevenues(
                                            expensesAndRevenues.filter((e) => e.id !== revenues[newId].id),
                                        );
                                        setExpenseRevenue(
                                            expenseRevenue.filter((r) => r.expenseRevenueId !== revenues[newId].id),
                                        );
                                    } else {
                                        setSelectedRevenues([...selectedRevenues, newId]);

                                        // We push the revenues objects on array to preserve the order - expenses first and revenues after
                                        expensesAndRevenues.push({
                                            id: revenues[newId].id,
                                            name: revenues[newId].name,
                                            type: 'revenue',
                                        });
                                    }
                                }}
                            />
                        </LabelWrapper>
                    </div>
                </div>
            )}
        </div>
    );
};

ReportingTools.propTypes = {
    reportingTools: PropTypes.arrayOf(PropTypes.object),
    expenseRevenue: PropTypes.arrayOf(PropTypes.object),
    setExpenseRevenue: PropTypes.func,
    expenses: PropTypes.arrayOf(PropTypes.object),
    revenues: PropTypes.arrayOf(PropTypes.object),
    expensesAndRevenues: PropTypes.arrayOf(PropTypes.object),
    setExpensesAndRevenues: PropTypes.func,
    selectedExpenses: PropTypes.arrayOf(PropTypes.number),
    selectedRevenues: PropTypes.arrayOf(PropTypes.number),
    setSelectedExpenses: PropTypes.func,
    setSelectedRevenues: PropTypes.func,
    hideElements: PropTypes.bool,
};

ReportingTools.defaultProps = {
    reportingTools: [],
    expenseRevenue: [],
    setExpenseRevenue: () => {},
    expenses: [],
    revenues: [],
    expensesAndRevenues: [],
    setExpensesAndRevenues: () => [],
    selectedExpenses: [],
    selectedRevenues: [],
    setSelectedExpenses: () => [],
    setSelectedRevenues: () => [],
    hideElements: false,
};

export default ReportingTools;
