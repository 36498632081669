import React, {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/Delete';

import { Button, CircularProgress } from '@material-ui/core';

import FileUploadContainer from 'components/shared/file-upload-container';
import PDFPreview from 'components/shared/pdf-preview/pdf-preview';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { accountancy } from 'routes';
import API from 'utils/axios';
import {
    getContacts,
    getPartners,
    getStocksArticles,
    getCompanyVAT as handleGetCompanyVAT,
} from 'utils/getterFunctions';
import {
    calculateSumWithVAT,
    errorHandling,
    formatExchangeRate,
    formatPositiveNumberWithDigits,
    formatVATnumber,
    toLocaleNumber,
    toRoundedSignificantDigits,
    uploadSingleFile,
} from 'utils/index';
import {
    canRenderArticlesAndAccountancy,
    canRenderClientSection,
    canRenderContractAndProjectSection,
    canRenderInfoFields,
    canRenderInvoiceReversal,
    canRenderInvoiceValues,
    canRenderSubmitButton,
    canRenderSubtypeChange,
    canRenderSubtypeSelection,
    canRenderTypeChange,
    canRenderTypeSelection,
    createInvoiceBody,
    DEFAULT_INVOICE,
    DEFAULT_REVENUES_AND_EXPENSES,
    handleChangeTitle,
} from 'utils/invoicesUtils';
import * as yup from 'yup';

import InvoiceArticlesSelection from './invoice-articles-selection';
import InvoiceClientDropdown from './invoice-client-dropdown';
import InvoiceCrmDataSelection from './invoice-crm-data-selection';
import InvoiceExpenseBudget from './invoice-expense-budget';
import InvoiceInfoFields from './invoice-info-fields';
import InvoicePartnerDropdown from './invoice-partner-dropdown';
import InvoicePmDataSelection from './invoice-pm-data-selection';
import InvoiceRevenueBudget from './invoice-revenue-budget';
import InvoiceReversal from './invoice-reversal';
import InvoiceSubmitButton from './invoice-submit-button';
import InvoiceSubtypeSelection from './invoice-subtype-selection';
import InvoiceTypeSelection from './invoice-type-selection';
import InvoiceValues from './invoice-values';

const InvoiceForm = (props) => {
    const {
        invoiceToEdit,
        viewOnly,
        displayCrmDataSelection,
        displayPmDataSelection,
    } = props;

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const { invoiceId } = useParams();
    const { currencyObj, language } = useContext(GlobalContext);

    const [invoice, setInvoice] = useState({ ...DEFAULT_INVOICE });

    const [clients, setClients] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [invoiceClient, setInvoiceClient] = useState(null);

    const [selectedPartner, setSelectedPartner] = useState(null);
    const [partners, setPartners] = useState([]);
    const [partnersOptions, setPartnersOptions] = useState([]);

    // States for PDF preview
    const [invoicePDF, setInvoicePDF] = useState(null);
    const [pageNumberPDF, setPageNumberPDF] = useState(1);

    // States for the CRM contracts
    const [invoiceOnAContract, setInvoiceOnAContract] = useState(false);

    // CRM & PM Contracts
    const [contractsOptions, setContractsOptions] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [crmProjects, setCrmProjects] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [selectedCrmProject, setSelectedCrmProject] = useState(null);

    // States for the Projects
    const [invoiceOnAProject, setInvoiceOnAProject] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    // States for the reverse invoice
    const [advanceInvoices, setAdvanceInvoices] = useState({
        EXPENSE: [],
        INCOME: [],
    });
    const currentAdvanceInvoices =
        advanceInvoices[invoice.type] || advanceInvoices[invoice.subType] || [];
    const [selectedAdvanceInvoices, setSelectedAdvanceInvoices] = useState([]);

    // States for the articles
    const [articlesFromNomenclature, setArticlesFromNomenclature] = useState(
        [],
    );
    const [articlesFromStocks, setArticlesFromStocks] = useState([]);
    const [selectedArticleIds, setSelectedArticleIds] = useState({
        nomenclature: [],
        stocks: [],
    });

    const handlePDFUpload = useCallback(async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }

                setInvoicePDF(blob);
            },
            'file',
        );
    }, []);

    /**
     * Function for editing the invoice info
     * @param {String} key - the key in the object to be modified
     * @param {String} value - the new value for the key
     */
    const editInvoiceInfo = (key, value) => {
        setInvoice((prev) => ({ ...prev, [key]: value }));
    };

    /**
     *
     * @param {*} resetAllInvoice - can be all to reset 'all' invoices and the pdfTemplates, and
     * if it is empty, will reset all invoice Info, but no pdf
     */
    const handleResetInvoiceInfo = (all = false, type = null) => {
        setInvoice({ ...DEFAULT_INVOICE, type });
        setInvoiceClient(null);
        setSelectedPartner(null);

        setSelectedContract(null);
        setSelectedProject(null);

        setSelectedCrmProject(null);

        setInvoiceOnAContract(false);
        setInvoiceOnAProject(false);

        setSelectedAdvanceInvoices([]);

        setCheckedExpenses(DEFAULT_REVENUES_AND_EXPENSES);
        setCheckedRevenues(DEFAULT_REVENUES_AND_EXPENSES);

        if (all) {
            setInvoicePDF(null);
            setPageNumberPDF(1);
        }

        getCompanyVAT();
        getReferenceExchangeRate(new Date(), true);
    };

    const handleResetMiddleFields = () => {
        setSelectedContract(null);
        setSelectedProject(null);
        setInvoiceOnAContract(false);
        setInvoiceOnAProject(false);
        setCheckedExpenses(DEFAULT_REVENUES_AND_EXPENSES);
        setCheckedRevenues(DEFAULT_REVENUES_AND_EXPENSES);
    };

    // ------------------ EXCHANGE RATES AND VAT LOGIC --------------------------------
    const [apiExchangeRate, setApiExchangeRate] = useState(0);
    const [loadingExchangeRate, setLoadingExchangeRate] = useState(false);
    const [loadingVAT, setLoadingVAT] = useState(false);

    const disableInputs = loadingExchangeRate || loadingVAT;

    const handleUpdateExchangeRate = (e) => {
        const value = formatExchangeRate(e.target.value);
        const secondValue = invoice.value / value;
        const secondValueWithVat = calculateSumWithVAT(
            secondValue,
            invoice.VAT,
        );

        setCheckedRevenues((prev) => ({
            ...prev,
            list: prev.list.map((r) => ({
                ...r,
                secondCurrencyValue: r.value / value,
            })),
        }));
        setCheckedExpenses((prev) => ({
            ...prev,
            list: prev.list.map((r) => ({
                ...r,
                secondCurrencyValue: r.value / value,
            })),
        }));

        editInvoiceInfo('secondValue', secondValue);
        editInvoiceInfo('secondValueWithVat', secondValueWithVat);
        editInvoiceInfo('exchangeRate', value);
    };

    const handleUpdateVAT = (e) => {
        const value = formatVATnumber(e.target.value);
        const invoiceValueWithVat = calculateSumWithVAT(invoice.value, value);
        const invoiceSecondValueWithVat = calculateSumWithVAT(
            invoice.value / invoice.exchangeRate,
            value,
        );

        editInvoiceInfo('VAT', value);
        editInvoiceInfo('valueWithVat', invoiceValueWithVat);
        editInvoiceInfo('secondValueWithVat', invoiceSecondValueWithVat);
    };

    const getExchangeRate = async (e) => {
        try {
            let selectedDate = new Date(e);
            if (selectedDate > new Date()) {
                enqueueSnackbar(
                    t(
                        "The selected date can't be greater then the current date!",
                    ),
                    {
                        variant: 'error',
                    },
                );
                selectedDate = new Date();
            }

            editInvoiceInfo('exchangeDate', selectedDate);
            setLoadingExchangeRate(true);

            const res = await API.get('currencyByDate', {
                params: {
                    date: selectedDate,
                },
            });

            const { rates } = res.data.data;

            handleUpdateExchangeRate({ target: { value: +rates } });
            setApiExchangeRate(+rates);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingExchangeRate(false);
        }
    };

    const getReferenceExchangeRate = async (e, canSetInitial = false) => {
        try {
            setLoadingExchangeRate(true);

            const res = await API.get('currencyByDate', {
                params: {
                    date: new Date(e),
                },
            });

            const { rates } = res.data.data;

            if (canSetInitial) {
                editInvoiceInfo('exchangeDate', e);
                editInvoiceInfo('exchangeRate', +rates);
            }
            setApiExchangeRate(+rates);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingExchangeRate(false);
        }
    };

    const getCompanyVAT = async () => {
        try {
            setLoadingVAT(true);
            const resVat = await handleGetCompanyVAT();
            editInvoiceInfo('VAT', resVat);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingVAT(false);
        }
    };

    /**
     * Logic for updating the invoice value
     */
    const handleUpdateInvoiceValue = (e) => {
        const value = formatPositiveNumberWithDigits(e.target.value);
        const secondValue = value / invoice.exchangeRate;
        const invoiceValueWithVat = calculateSumWithVAT(value, invoice.VAT);
        const invoiceSecondValueWithVat = calculateSumWithVAT(
            value / invoice.exchangeRate,
            invoice.VAT,
        );

        editInvoiceInfo('value', value);
        editInvoiceInfo('secondValue', secondValue);
        editInvoiceInfo('valueWithVat', invoiceValueWithVat);
        editInvoiceInfo('secondValueWithVat', invoiceSecondValueWithVat);
    };

    const getAllClients = async () => {
        try {
            const res = await getContacts();

            setClients(res);
            setClientOptions(
                res.map((c) => {
                    const data = JSON.parse(c.data);
                    return 'standard' in data && 'name' in data['standard']
                        ? data['standard'].name
                        : 'Nume inexistent';
                }),
            );

            return res;
        } catch (err) {
            console.error(err);
        }
    };

    const getAllPartners = async () => {
        try {
            const partnersAccounts = await getPartners();

            setPartners(partnersAccounts);
            setPartnersOptions(
                partnersAccounts.map((partner) => {
                    const data =
                        typeof partner.data === 'string'
                            ? JSON.parse(partner.data)
                            : partner.data;
                    return data['standard'].name;
                }),
            );

            return partnersAccounts;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
        }
    };

    const getAllArticles = async () => {
        try {
            const [articlesRes, articlesFromStocks] = await Promise.all([
                await API.get('/articles', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                        pagesToLoad: 1,
                        type: null,
                        categorieId: null,
                        many: 'ALL',
                    },
                }),
                getStocksArticles(),
            ]);

            setArticlesFromNomenclature(articlesRes.data.articles);
            setArticlesFromStocks(articlesFromStocks);

            return {
                nomenclatureArticles: articlesRes.data.articles,
                stocksArticles: articlesFromStocks,
            };
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
            throw err;
        }
    };

    useEffect(() => {
        if (invoiceToEdit) return;

        const getInitialData = async () => {
            try {
                await Promise.all([
                    getCompanyVAT(),
                    getReferenceExchangeRate(new Date(), true),
                    getAllClients(),
                    getAllPartners(),
                    getAllArticles(),
                    getAdvanceInvoices(),
                ]);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getInitialData();
    }, []);

    const getNewContracts = async (client, partner) => {
        let newDisplayedContracts = [];

        if (client !== null) {
            const res = await API.get('/contactsContracts', {
                params: { contactId: client },
            });
            newDisplayedContracts = res.data.contactsContracts.contracts;
        } else if (client === null && partner !== null) {
            const res = await API.get('/contactsContracts', {
                params: { partnerId: partner },
            });
            newDisplayedContracts = res.data.contactsContracts.contracts;
        } else {
            newDisplayedContracts = [];
        }

        setContracts(newDisplayedContracts);

        if (newDisplayedContracts.length !== 0) {
            const contractsOptions = newDisplayedContracts.map((c) => {
                const data =
                    typeof c.data === 'string' ? JSON.parse(c.data) : c.data;
                return 'standard' in data && 'number' in data['standard']
                    ? data['standard'].number
                    : 'Numar contract inexistent';
            });

            setContractsOptions(contractsOptions);
        } else {
            setContractsOptions([]);
        }

        return newDisplayedContracts;
    };

    const getNewCrmProjects = async (clientId) => {
        try {
            if (!clientId) {
                setCrmProjects([]);
                return [];
            }

            const rowProjects = await API.get('/client_crmProjects', {
                params: {
                    id: clientId,
                },
            });

            setCrmProjects(rowProjects.data.contactsCrmProjects.projects);

            return rowProjects.data.contactsCrmProjects.projects;
        } catch (error) {
            console.error(error);
        }
    };

    const getNewCrmData = async (clientId, partnerId) => {
        try {
            await getNewContracts(clientId, partnerId);
            await getNewCrmProjects(clientId);
        } catch (error) {
            console.error(error);
        }
    };

    const getNewProjects = async (client, partner) => {
        let newDisplayedProjects = [];

        if (client !== null) {
            const res = await API.get('/contactsPm_projects', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    contactId: client,
                },
            });
            newDisplayedProjects = res.data.projects;
        } else if (client === null && partner !== null) {
            const res = await API.get('/partnersPm_projects', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    partnerId: partner,
                },
            });
            newDisplayedProjects = res.data.projects;
        } else {
            newDisplayedProjects = [];
        }

        setProjects(newDisplayedProjects);

        return newDisplayedProjects;
    };

    const getPMContracts = async (pmId) => {
        try {
            const response = await API.get('project_contracts_pm', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                    projectId: pmId,
                },
            });

            const resContracts = response.data.contracts;
            setContracts(resContracts);

            const contractsOptions = resContracts.map((c) => {
                const data =
                    typeof c.data === 'string' ? JSON.parse(c.data) : c.data;
                return 'standard' in data && 'number' in data['standard']
                    ? data['standard'].number
                    : 'Numar contract inexistent';
            });
            setContractsOptions(contractsOptions);

            return resContracts;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
        }
    };

    const getAdvanceInvoices = async () => {
        try {
            const res = await API.get('/invoices', {
                params: {
                    type: 'ADVANCE',
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    isUsed: invoiceToEdit ? undefined : false,
                },
            });

            const newInvoices = res.data.invoices
                .filter(
                    (invoice) =>
                        invoice.isValidated === true &&
                        invoice.payment === 'PAID',
                )
                .reduce(
                    (acc, cur) => ({
                        ...acc,
                        [cur.subType]: [...acc[cur.subType], cur],
                    }),
                    {
                        EXPENSE: [],
                        INCOME: [],
                    },
                );

            setAdvanceInvoices(newInvoices);

            return newInvoices;
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
        }
    };

    /**
     * With this function we reset al the info from an income invoice when the user change the income invoice type
     */
    const handleSubtypeChangeReset = () => {
        editInvoiceInfo('subType', null);
        handleResetInvoiceInfo(false, invoice.type);
    };

    const handleCrmDataCheckChange = () => {
        if (invoiceOnAProject && !invoiceOnAContract) {
            enqueueSnackbar(
                t(
                    'The invoice can be based on a contract or on a project, but not both!',
                ),
                {
                    variant: 'error',
                },
            );
            return;
        }

        if (invoiceOnAContract) {
            setSelectedContract(null);
            setSelectedProject(null);
            setCheckedExpenses(DEFAULT_REVENUES_AND_EXPENSES);
            setCheckedRevenues(DEFAULT_REVENUES_AND_EXPENSES);
            getNewProjects(
                clients[invoiceClient]?.id ?? null,
                partners[selectedPartner]?.id ?? null,
            );
        }

        setInvoiceOnAContract(!invoiceOnAContract);
    };

    const handlePmDataCheckChange = () => {
        if (invoiceOnAContract && invoiceOnAProject === false) {
            enqueueSnackbar(
                t(
                    'The invoice can be based on a contract or on a project, but not both!',
                ),
                {
                    variant: 'error',
                },
            );
            return;
        }
        if (invoiceOnAProject === true) {
            setSelectedContract(null);
            setSelectedProject(null);
            setCheckedExpenses(DEFAULT_REVENUES_AND_EXPENSES);
            setCheckedRevenues(DEFAULT_REVENUES_AND_EXPENSES);
            getNewCrmData(
                clients[invoiceClient]?.id ?? null,
                partners[selectedPartner]?.id ?? null,
            );
        }
        setInvoiceOnAProject(!invoiceOnAProject);
    };

    const invoiceInfoSchema = yup.object().shape({
        invoiceName: yup
            .string()
            .trim()
            .typeError(t('The invoice name is required'))
            .required(t('The invoice name is required')),
        invoiceNumber: yup
            .string()
            .trim()
            .typeError(t('The invoice number is required'))
            .required(t('The invoice number is required'))
            .min(
                3,
                t('The invoice number must be at least 3 characters long!'),
            ),
    });

    const handleInvoiceCreationOrUpdate = async () => {
        try {
            await invoiceInfoSchema.validate({
                invoiceName: invoice.name,
                invoiceNumber: invoice.billingNumber,
            });

            try {
                setLoading(true);

                const data = createInvoiceBody({
                    invoice,
                    clients,
                    partners,
                    contracts,
                    crmProjects,
                    projects,
                    invoiceClient,
                    selectedPartner,
                    selectedContract,
                    selectedCrmProject,
                    selectedProject,
                    selectedArticleIds,
                    selectedAdvanceInvoices,
                    advanceInvoices: currentAdvanceInvoices,
                    apiExchangeRate,
                    invoiceOnAContract,
                    invoiceOnAProject,
                    checkedExpenses,
                    checkedRevenues,
                });

                if (invoiceId) {
                    await API.put('/invoice', {
                        id: invoiceId,
                        data,
                    });

                    enqueueSnackbar(
                        t('The invoice has been updated successfully!'),
                        {
                            variant: 'success',
                        },
                    );
                } else {
                    const reqBody = new FormData();

                    reqBody.append('data', JSON.stringify(data));
                    reqBody.append('files', invoicePDF);

                    await API.post('/invoice', reqBody, {
                        'Content-Type': 'multipart/form-data',
                    });

                    enqueueSnackbar(
                        t('The invoice has been added successfully!'),
                        {
                            variant: 'success',
                        },
                    );

                    if (
                        invoice.type === 'REVERSAL' ||
                        invoice.subType === 'REVERSE'
                    ) {
                        await Promise.all(
                            data.invoiceIds.map(
                                async (invoice) =>
                                    await API.put('/invoice', {
                                        id: invoice.id,
                                        data: {
                                            isUsed: true,
                                        },
                                    }),
                            ),
                        );
                    }
                }

                history.push(accountancy.base + accountancy.invoice.base);
            } catch (err) {
                console.error(err);
                enqueueSnackbar(
                    errorHandling(err).length > 100
                        ? errorHandling(err)
                        : t(errorHandling(err)),
                    {
                        variant: 'error',
                    },
                );
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], {
                variant: 'error',
            });
        }
    };

    /**
     * This function transform invoiceToEdit object in an object like DEFAULT_INVOICE
     */
    const transformInvoiceToEdit = async () => {
        try {
            const newInvoice = {
                ...DEFAULT_INVOICE,
                name: invoiceToEdit.name,
                isValidated: invoiceToEdit.isValidated,
                billingNumber: invoiceToEdit.billingNumber,
                issueDate: invoiceToEdit.issueDate,
                type: invoiceToEdit.type,
                subType: invoiceToEdit.subType,
                projectStructureActivityId:
                    invoiceToEdit.projectStructureActivityId,
                contractId: invoiceToEdit.contractId,
                contractPMId: invoiceToEdit.contractPMId,
                partnerId: invoiceToEdit.partnerId,
                crmProjectId: invoiceToEdit.crmProjectId,
                pmProjectOverviewPlanId: invoiceToEdit.pmProjectOverviewPlanId,
                value: invoiceToEdit.value,
                secondValue: invoiceToEdit.secondValue,
                manualExchange: invoiceToEdit.manualExchange,
                exchangeDate: invoiceToEdit.exchangeDate,
                exchangeRate: invoiceToEdit.exchangeRate,
                VAT: invoiceToEdit.VAT,
                valueWithVat: invoiceToEdit.valueWithVat,
                secondValueWithVat: invoiceToEdit.secondValueWithVat,
            };

            const contractPromise = { promise: null, args: [] };
            const projectPromise = { promise: null, args: [] };

            if (
                invoiceToEdit.contractId !== null ||
                invoiceToEdit.crmProjectId !== null
            ) {
                contractPromise.promise = getNewContracts;
                contractPromise.args = [
                    invoiceToEdit.contactId,
                    invoiceToEdit.partnerId,
                ];

                projectPromise.promise = getNewCrmProjects;
                projectPromise.args = [invoiceToEdit.contactId];
            }

            if (invoiceToEdit.contractPMId !== null) {
                contractPromise.promise = getPMContracts;
                contractPromise.args = [invoiceToEdit.pmProjectOverviewPlanId];
            }

            if (invoiceToEdit.pmProjectOverviewPlanId !== null) {
                projectPromise.promise = getNewProjects;
                projectPromise.args = [
                    invoiceToEdit.contactId,
                    invoiceToEdit.partnerId,
                ];
            }

            const [
                returnedClients,
                returnedPartners,
                returnedAdvanceInvoices,
                returnedContracts = [],
                returnedProjects,
                returnedArticles,
            ] = await Promise.all([
                getAllClients(),
                getAllPartners(),
                getAdvanceInvoices(),
                contractPromise.promise
                    ? contractPromise.promise(...contractPromise.args)
                    : undefined,
                projectPromise.promise
                    ? projectPromise.promise(...projectPromise.args)
                    : undefined,
                getAllArticles(),
                getReferenceExchangeRate(invoiceToEdit.exchangeDate),
            ]);

            setInvoicePDF({
                httpHeaders: {
                    'Access-Control-Allow-Origin': '*',
                },
                url: invoiceToEdit.fileArray[0]?.signedUrl,
            });

            // We set the invoice client dropdown with the selected invoice client
            if (invoiceToEdit.contactId !== null) {
                const clientIndex = returnedClients.findIndex(
                    (c) => c.id === invoiceToEdit.contactId,
                );
                setInvoiceClient(clientIndex);
            }

            if (invoiceToEdit.partnerId !== null) {
                const partnerIndex = returnedPartners.findIndex(
                    (p) => p.id === invoiceToEdit.partnerId,
                );
                setSelectedPartner(partnerIndex);
            }

            if (invoiceToEdit.pmProjectOverviewPlanId !== null) {
                setInvoiceOnAProject(true);
                setSelectedProject(
                    returnedProjects.findIndex(
                        (p) => p.id === invoiceToEdit.pmProjectOverviewPlanId,
                    ),
                );
                if (invoiceToEdit.contractPMId)
                    setSelectedContract(
                        returnedContracts.findIndex(
                            (c) => c.id === invoiceToEdit.contractPMId,
                        ),
                    );
            } else {
                setInvoiceOnAContract(true);
                if (invoiceToEdit.contractId)
                    setSelectedContract(
                        returnedContracts.findIndex(
                            (c) => c.id === invoiceToEdit.contractId,
                        ),
                    );

                if (invoiceToEdit.crmProjectId)
                    setSelectedCrmProject(
                        returnedProjects.findIndex(
                            (p) => p.id === invoiceToEdit.crmProjectId,
                        ),
                    );
            }

            // For advance invoices dropdown
            if (invoiceToEdit.invoiceIds.length) {
                const currentAdvanceInvoices =
                    returnedAdvanceInvoices[invoiceToEdit.type] ||
                    returnedAdvanceInvoices[invoiceToEdit.subType];
                const selectedAdvanceInvoicesIndexes =
                    invoiceToEdit.invoiceIds.reduce((acc, curr) => {
                        const invoiceIdx = currentAdvanceInvoices.findIndex(
                            (invoice) => invoice.id === curr.id,
                        );
                        if (invoiceIdx >= 0) return [...acc, invoiceIdx];

                        return acc;
                    }, []);

                setSelectedAdvanceInvoices(selectedAdvanceInvoicesIndexes);
            }

            if (invoiceToEdit.InvoiceArticles.length) {
                const selectedArticleIds = invoiceToEdit.InvoiceArticles.reduce(
                    (acc, curr) => {
                        const nomenclatureArticleId =
                            returnedArticles.nomenclatureArticles.find(
                                (article) => article.id === curr.articleId,
                            )?.id;

                        if (nomenclatureArticleId)
                            return {
                                ...acc,
                                nomenclature: [
                                    ...acc.nomenclature,
                                    nomenclatureArticleId,
                                ],
                            };

                        const stocksArticleId =
                            returnedArticles.stocksArticles.find(
                                (article) => article.id === curr.articleId,
                            )?.id;

                        if (stocksArticleId)
                            return {
                                ...acc,
                                stocks: [...acc.stocks, stocksArticleId],
                            };

                        return acc;
                    },
                    { nomenclature: [], stocks: [] },
                );

                setSelectedArticleIds(selectedArticleIds);
            }

            setInvoice(newInvoice);

            if (
                invoiceToEdit.type === 'EXPENSE' ||
                invoiceToEdit.subType === 'EXPENSE'
            ) {
                const grandParentId =
                    invoiceToEdit.InvoiceToExpenseName[0].expenseName
                        .subExpenseName.subExpenseName === null
                        ? invoiceToEdit.InvoiceToExpenseName[0].expenseName
                              .subExpenseName.id
                        : invoiceToEdit.InvoiceToExpenseName[0].expenseName
                              .subExpenseName.subExpenseName.id;

                const checkedExpenses = {
                    grandParentId,
                    list: invoiceToEdit.InvoiceToExpenseName.map((expense) => ({
                        id: expense.expenseNameId,
                        value: expense.value,
                        secondCurrencyValue: expense.secondCurrencyValue,
                    })),
                };

                setCheckedExpenses(checkedExpenses);
            }

            if (
                invoiceToEdit.type === 'INCOME' ||
                invoiceToEdit.subType === 'INCOME'
            ) {
                const grandParentId =
                    invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue
                        .subRevenue === null
                        ? invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue
                              .id
                        : invoiceToEdit.InvoiceToRevenue[0].revenue.subRevenue
                              .subRevenue.id;

                const checkedRevenues = {
                    grandParentId,
                    list: invoiceToEdit.InvoiceToRevenue.map((revenue) => ({
                        id: revenue.revenueId,
                        value: revenue.value,
                        secondCurrencyValue: revenue.secondCurrencyValue,
                    })),
                };

                setCheckedRevenues(checkedRevenues);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!invoiceToEdit) return;

        transformInvoiceToEdit();
    }, [invoiceToEdit]);

    // ------------------------------ Logic for the expenses ----------------------------------

    const [checkedExpenses, setCheckedExpenses] = useState(
        DEFAULT_REVENUES_AND_EXPENSES,
    );

    const checkedExpensesSum = toRoundedSignificantDigits(
        checkedExpenses.list.reduce(
            (sum, current) => (sum += current.value),
            0,
        ),
    );
    const validExpenseValue = checkedExpensesSum === invoice.value;

    // ------------------------------ Logic for the revenue ----------------------------------
    const [checkedRevenues, setCheckedRevenues] = useState(
        DEFAULT_REVENUES_AND_EXPENSES,
    );

    const checkedRevenuesSum = toRoundedSignificantDigits(
        checkedRevenues.list.reduce(
            (sum, current) => (sum += current.value),
            0,
        ),
    );
    const validRevenueValue = checkedRevenuesSum === invoice.value;

    // ------------------------------ Render logic ----------------------------------
    const renderClientSection = canRenderClientSection(
        invoice,
        selectedAdvanceInvoices,
    );
    const renderContractAndProjectSection =
        renderClientSection &&
        canRenderContractAndProjectSection(
            invoice,
            invoiceClient,
            selectedPartner,
        );
    const renderArticlesAndAccountancy = canRenderArticlesAndAccountancy(
        invoice,
        selectedContract,
        selectedCrmProject,
        invoiceOnAContract,
        selectedProject,
        invoiceOnAProject,
    );
    const renderInfoFields =
        renderArticlesAndAccountancy &&
        canRenderInfoFields(
            invoice,
            checkedExpenses,
            validExpenseValue,
            checkedRevenues,
            validRevenueValue,
            selectedAdvanceInvoices,
            currentAdvanceInvoices,
        );
    const renderSubmitButton =
        renderInfoFields && canRenderSubmitButton(invoice, viewOnly);

    const renderTypeSelection = canRenderTypeSelection(
        invoicePDF,
        invoice.type,
    );
    const renderTypeChange = canRenderTypeChange(
        invoicePDF,
        invoice.type,
        invoiceId,
        viewOnly,
    );
    const renderSubtypeSelection = canRenderSubtypeSelection(invoice);
    const renderSubtypeChange = canRenderSubtypeChange(invoice, viewOnly);
    const renderInvoiceValues = canRenderInvoiceValues(invoice);
    const renderInvoiceReversal = canRenderInvoiceReversal(invoice);

    return (
        <>
            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <div className="flex gap-24 lg:flex-col lg:items-center">
                    <div className="flex flex-col items-center">
                        {/* Button for pdf upload - this will disappear after the upload of the invoice PDF */}
                        {!invoicePDF && (
                            <FileUploadContainer
                                onUpload={(e) => handlePDFUpload(e)}
                            >
                                <Button
                                    color="secondary"
                                    startIcon={
                                        <i className="fa-solid fa-file-invoice" />
                                    }
                                >
                                    {t('Upload invoice PDF')}
                                </Button>
                            </FileUploadContainer>
                        )}

                        {/* PDF preview */}
                        <PDFPreview
                            file={invoicePDF}
                            pageNumber={pageNumberPDF}
                            setPageNumber={setPageNumberPDF}
                        />
                        <InvoiceTypeSelection
                            editInvoiceInfo={editInvoiceInfo}
                            onResetInvoiceInfo={handleResetInvoiceInfo}
                            renderTypeSelection={renderTypeSelection}
                            renderTypeChange={renderTypeChange}
                        />
                    </div>

                    {/* Invoice configuration */}
                    <div className={`${viewOnly ? 'pointer-events-none' : ''}`}>
                        {/* Invoice configurator title */}
                        {invoice.type !== null && (
                            <h4 className="mb-20 text-left text-2xl text-main-text">
                                {t(handleChangeTitle(invoice))}
                            </h4>
                        )}

                        <InvoiceSubtypeSelection
                            type={invoice.type}
                            renderSubtypeSelection={renderSubtypeSelection}
                            renderSubtypeChange={renderSubtypeChange}
                            editInvoiceInfo={editInvoiceInfo}
                            onSubtypeChangeReset={handleSubtypeChangeReset}
                        />

                        {renderInvoiceValues && (
                            <InvoiceValues
                                invoice={invoice}
                                disableInputs={disableInputs}
                                getExchangeRate={getExchangeRate}
                                onUpdateVAT={handleUpdateVAT}
                                onUpdateExchangeRate={handleUpdateExchangeRate}
                                onUpdateInvoiceValue={handleUpdateInvoiceValue}
                            />
                        )}

                        {renderInvoiceReversal && (
                            <InvoiceReversal
                                invoice={invoice}
                                advanceInvoices={currentAdvanceInvoices}
                                selectedAdvanceInvoices={
                                    selectedAdvanceInvoices
                                }
                                setSelectedAdvanceInvoices={
                                    setSelectedAdvanceInvoices
                                }
                                handleUpdateInvoiceValue={
                                    handleUpdateInvoiceValue
                                }
                            />
                        )}

                        {renderClientSection && (
                            <div className="mb-6 flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <InvoiceClientDropdown
                                        clients={clients}
                                        clientOptions={clientOptions}
                                        invoiceClient={invoiceClient}
                                        setInvoiceClient={setInvoiceClient}
                                        onResetMiddleFields={
                                            handleResetMiddleFields
                                        }
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                        selectedPartnerId={
                                            partners[selectedPartner]?.id ??
                                            null
                                        }
                                        disabled={
                                            invoice.type === 'ADVANCE'
                                                ? selectedPartner !== null
                                                : false
                                        }
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={
                                                invoiceClient !== null
                                                    ? 'var(--error)'
                                                    : 'var(--disabled)'
                                            }
                                            disabled={
                                                invoiceClient !== null
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => {
                                                setInvoiceClient(null);
                                                getNewCrmData(
                                                    null,
                                                    partners[selectedPartner]
                                                        ?.id ?? null,
                                                );
                                                getNewProjects(
                                                    null,
                                                    partners[selectedPartner]
                                                        ?.id ?? null,
                                                );
                                                handleResetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center gap-3">
                                    <InvoicePartnerDropdown
                                        partnersOptions={partnersOptions}
                                        selectedPartner={selectedPartner}
                                        setSelectedPartner={setSelectedPartner}
                                        onResetMiddleFields={
                                            handleResetMiddleFields
                                        }
                                        partners={partners}
                                        selectedClientId={
                                            clients[invoiceClient]?.id ?? null
                                        }
                                        getNewCrmData={getNewCrmData}
                                        getNewProjects={getNewProjects}
                                        disabled={
                                            invoice.type === 'ADVANCE'
                                                ? invoiceClient !== null
                                                : false
                                        }
                                    />

                                    <div className="mt-6">
                                        <ActionButton
                                            icon={<DeleteIcon />}
                                            color={
                                                selectedPartner !== null
                                                    ? 'var(--error)'
                                                    : 'var(--disabled)'
                                            }
                                            disabled={
                                                selectedPartner !== null
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => {
                                                setSelectedPartner(null);
                                                getNewCrmData(
                                                    clients[invoiceClient]
                                                        ?.id ?? null,
                                                    null,
                                                );
                                                getNewProjects(
                                                    clients[invoiceClient]
                                                        ?.id ?? null,
                                                    null,
                                                );
                                                handleResetMiddleFields();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Section with contracts and projects for the invoice */}
                        {renderContractAndProjectSection && (
                            <div className="mb-10 flex flex-row justify-start gap-5 lg:flex-col">
                                {displayCrmDataSelection && (
                                    <InvoiceCrmDataSelection
                                        invoiceOnAContract={invoiceOnAContract}
                                        contractsOptions={contractsOptions}
                                        crmProjects={crmProjects}
                                        selectedContract={selectedContract}
                                        selectedCrmProject={selectedCrmProject}
                                        invoiceClient={invoiceClient}
                                        setSelectedContract={
                                            setSelectedContract
                                        }
                                        setSelectedCrmProject={
                                            setSelectedCrmProject
                                        }
                                        onCheck={handleCrmDataCheckChange}
                                    />
                                )}
                                {displayPmDataSelection && (
                                    <InvoicePmDataSelection
                                        invoiceOnAProject={invoiceOnAProject}
                                        contractsOptions={contractsOptions}
                                        projects={projects}
                                        selectedContract={selectedContract}
                                        selectedProject={selectedProject}
                                        setSelectedContract={
                                            setSelectedContract
                                        }
                                        setSelectedProject={setSelectedProject}
                                        getPMContracts={getPMContracts}
                                        onCheck={handlePmDataCheckChange}
                                    />
                                )}
                            </div>
                        )}

                        {renderArticlesAndAccountancy && (
                            <Fragment>
                                <InvoiceArticlesSelection
                                    articlesFromNomenclature={
                                        articlesFromNomenclature
                                    }
                                    articlesFromStocks={articlesFromStocks}
                                    selectedArticleIds={selectedArticleIds}
                                    setSelectedArticleIds={
                                        setSelectedArticleIds
                                    }
                                />

                                {(invoice.type === 'EXPENSE' ||
                                    invoice.subType === 'EXPENSE') && (
                                    <Fragment>
                                        <InvoiceExpenseBudget
                                            checkedExpenses={checkedExpenses}
                                            setCheckedExpenses={
                                                setCheckedExpenses
                                            }
                                            isEdit={
                                                invoiceToEdit ? true : false
                                            }
                                            viewOnly={viewOnly}
                                            exchangeRate={invoice.exchangeRate}
                                            disableInputs={disableInputs}
                                        />
                                        {checkedExpenses.list.length > 0 && (
                                            <h4
                                                className={`mb-10 max-w-2xl ${
                                                    validExpenseValue
                                                        ? 'text-success'
                                                        : 'text-error'
                                                }`}
                                            >
                                                {`${t('The value of the invoice')}: ${toLocaleNumber(invoice.value, language, 2, 2)} ${
                                                    currencyObj.currency
                                                } ${t(
                                                    'must be equal to the sum of values of the checked expenses',
                                                )}: ${toLocaleNumber(checkedExpensesSum, language, 2, 2)} ${currencyObj.currency}.`}
                                            </h4>
                                        )}
                                    </Fragment>
                                )}

                                {(invoice.type === 'INCOME' ||
                                    invoice.subType === 'INCOME') && (
                                    <Fragment>
                                        <InvoiceRevenueBudget
                                            checkedRevenues={checkedRevenues}
                                            setCheckedRevenues={
                                                setCheckedRevenues
                                            }
                                            isEdit={
                                                invoiceToEdit ? true : false
                                            }
                                            viewOnly={viewOnly}
                                            exchangeRate={invoice.exchangeRate}
                                            disableInputs={disableInputs}
                                        />
                                        {checkedRevenues.list.length > 0 && (
                                            <h4
                                                className={`mb-10 max-w-2xl ${
                                                    validRevenueValue
                                                        ? 'text-success'
                                                        : 'text-error'
                                                }`}
                                            >
                                                {`${t('The value of the invoice')}: ${toLocaleNumber(invoice.value, language, 2, 2)} ${
                                                    currencyObj.currency
                                                } ${t(
                                                    'must be equal to the sum of values of the checked revenues',
                                                )}: ${toLocaleNumber(checkedRevenuesSum, language, 2, 2)} ${currencyObj.currency}.`}
                                            </h4>
                                        )}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}

                        {/* The final steps with the info of the invoice */}
                        {renderInfoFields && (
                            <InvoiceInfoFields
                                invoice={invoice}
                                editInvoiceInfo={editInvoiceInfo}
                            />
                        )}
                        {/* Button for the add of the invoice and button for the reset of the invoice*/}
                        {renderSubmitButton && (
                            <InvoiceSubmitButton
                                invoiceId={invoiceId}
                                disableInputs={disableInputs}
                                onResetInvoiceInfo={handleResetInvoiceInfo}
                                onInvoiceCreationOrUpdate={
                                    handleInvoiceCreationOrUpdate
                                }
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default InvoiceForm;

InvoiceForm.propTypes = {
    invoiceToEdit: PropTypes.object,
    viewOnly: PropTypes.bool,
    displayCrmDataSelection: PropTypes.bool,
    displayPmDataSelection: PropTypes.bool,
};

InvoiceForm.defaultProps = {
    invoiceToEdit: null,
    viewOnly: false,
    displayCrmDataSelection: true,
    displayPmDataSelection: true,
};
