import React, { useState } from 'react';

import { CircleCheck, CircleX } from 'lucide-react';

import { Button } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const detailsTypes = ['SYNTHETIC', 'ANALYTICAL'];
const types = ['COMPANY', 'PROJECT', 'PERSONALIZED'];

const ModalReportsExport = ({ open, onClose, onFilter, stocks, projects, filteredOptions, setFilteredOptions }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [selection, setSelection] = useState(filteredOptions);
    const disabledFilter = _.isEqual(filteredOptions, selection);

    const mappedOptionsList = {
        COMPANY: [],
        PROJECT: projects,
        PERSONALIZED: stocks,
    };
    const optionsList = mappedOptionsList[selection.type];

    const handleDetailsTypeChange = (i) =>
        setSelection((prev) => ({
            ...prev,
            detailsType: detailsTypes[i],
        }));

    const handleTypeChange = (i) => setSelection((prev) => ({ ...prev, type: types[i], options: [] }));

    const handleMultiDropdownChange = (i) => {
        const value = optionsList[i].id;
        let newOptions = [...selection.options];

        const isPresent = newOptions.indexOf(value) >= 0;
        if (isPresent) newOptions = newOptions.filter((v) => v !== value);
        else newOptions.push(value);

        setSelection((prev) => ({ ...prev, options: newOptions }));
    };

    const handleFinish = () => {
        if (!selection.options.length && selection.type === 'PROJECT') {
            enqueueSnackbar(t('You must select a project!'), { variant: 'error' });
            return;
        }

        if (!selection.options.length && selection.type === 'PERSONALIZED') {
            enqueueSnackbar(t('You must select a stock!'), { variant: 'error' });
            return;
        }

        setFilteredOptions(selection);
        onFilter(selection);
        onClose();
    };

    return (
        <CustomModal open={open}>
            <div className="flex w-2xl flex-col items-center gap-16 p-10 sm:w-full">
                <h2>{t('Filter data')}</h2>

                <div className="flex w-full flex-col gap-4">
                    <LabelWrapper label={t('Select what type of details the report will have')}>
                        <Dropdown
                            options={detailsTypes.map((type) => t(`${type.charAt(0)}${type.slice(1).toLowerCase()}`))}
                            placeholder={t('Select what type of details the report will have')}
                            selectedOption={detailsTypes.indexOf(selection.detailsType)}
                            setSelectedOption={handleDetailsTypeChange}
                        />
                    </LabelWrapper>

                    <LabelWrapper label={t('Choose filter method')}>
                        <Dropdown
                            options={types.map((type) => t(`${type.charAt(0)}${type.slice(1).toLowerCase()}`))}
                            placeholder={t('Choose filter method')}
                            selectedOption={types.indexOf(selection.type)}
                            setSelectedOption={handleTypeChange}
                        />
                    </LabelWrapper>

                    {selection.type === 'PROJECT' && (
                        <LabelWrapper label={t('Select project')}>
                            <MultiDropdown
                                options={optionsList.map((project) => project.name)}
                                placeholder={t('Select project')}
                                selectedOptions={selection.options.map((option) =>
                                    optionsList.findIndex((o) => o.id === option),
                                )}
                                setSelectedOptions={handleMultiDropdownChange}
                            />
                        </LabelWrapper>
                    )}

                    {selection.type === 'PERSONALIZED' && (
                        <LabelWrapper label={t('Stocks')}>
                            <MultiDropdown
                                options={optionsList.map((stock) => stock.name)}
                                placeholder={t('Choose options')}
                                selectedOptions={selection.options.map((option) =>
                                    optionsList.findIndex((o) => o.id === option),
                                )}
                                setSelectedOptions={handleMultiDropdownChange}
                            />
                        </LabelWrapper>
                    )}
                </div>

                <div className="flex w-full justify-end gap-4">
                    <Button onClick={onClose} startIcon={<CircleX />} color="secondary">
                        {t('Cancel')}
                    </Button>
                    <Button
                        onClick={handleFinish}
                        startIcon={<CircleCheck />}
                        color="secondary"
                        disabled={disabledFilter}
                    >
                        {t('Filter data')}
                    </Button>
                </div>
            </div>
        </CustomModal>
    );
};

ModalReportsExport.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFilter: PropTypes.func,
    stocks: PropTypes.array,
    projects: PropTypes.array,
    filteredOptions: PropTypes.array,
    setFilteredOptions: PropTypes.func,
};

ModalReportsExport.defaultProps = {
    open: false,
    onClose: () => {},
    onFilter: () => {},
    stocks: [],
    projects: [],
    filteredOptions: { type: 'COMPANY', options: [] },
    setFilteredOptions: () => {},
};

export default ModalReportsExport;
