import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const InvoiceClientDropdown = (props) => {
    const { t } = useTranslation();
    const {
        clients,
        clientOptions,
        invoiceClient,
        setInvoiceClient,
        onResetMiddleFields,
        getNewCrmData,
        getNewProjects,
        disabled = false,
        selectedPartnerId,
    } = props;

    return (
        <>
            {/* Dropdown for the invoice client */}
            <div className="flex justify-start">
                <div className="inline-block">
                    <LabelWrapper label={t('Client invoice')}>
                        <Dropdown
                            variant="black"
                            options={clientOptions}
                            placeholder={t('Choose the client of the invoice')}
                            selectedOption={invoiceClient}
                            setSelectedOption={(e) => {
                                setInvoiceClient(e);
                                getNewCrmData(clients[e].id, selectedPartnerId);
                                getNewProjects(clients[e].id, selectedPartnerId);
                                onResetMiddleFields();
                            }}
                            disabled={disabled}
                        />
                    </LabelWrapper>
                </div>
            </div>
        </>
    );
};

InvoiceClientDropdown.propTypes = {
    clientOptions: PropTypes.arrayOf(PropTypes.string),
    invoiceClient: PropTypes.number,
    setInvoiceClient: PropTypes.func,
    onResetMiddleFields: PropTypes.func,
    disabled: PropTypes.bool,
    getNewCrmData: PropTypes.func,
    getNewProjects: PropTypes.func,
    clients: PropTypes.array,
    selectedPartnerId: PropTypes.string,
};

export default InvoiceClientDropdown;
