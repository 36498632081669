import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Trash } from 'lucide-react';
import PersonIcon from '@material-ui/icons/Person';

import { Button, LinearProgress, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import Gallery from 'components/crm/estate/gallery';
import GalleryEditable from 'components/crm/estate/gallery-editable';
import ErrorButton from 'components/shared/error-button';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import useConfirm from 'hooks/useConfirm';
import { validate } from 'jsonschema';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
    CheckItem,
    DatePicker,
    Dropdown,
    InputGroup,
    LabelWrapper,
    MultiDropdown,
    SelectList,
    Tags,
} from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { crm, internalActivity, projectInManagement as pmRoute } from 'routes';
import {
    calculateSumWithVAT,
    errorHandling,
    formatPositiveNumberWithDigits,
    formatVATnumber,
} from 'utils';
import API from 'utils/axios';
import { getEntityFields } from 'utils/getterFunctions';

import { LocaleTextField } from '../../../RaisisComponents/Inputs';
import FileDisplay from '../file-display/file-display';
import GroupsSelector from '../groups-selector/groups-selector';
import ProjectSelection from './project-selection';

const ConfiguratorForm = (props) => {
    /**
     * contractInitialPrices - prices from offer (with/without TVA) readonly
     * onSubmit - function to override default submit, made specially for contracts because it has 3 steps
     */
    const {
        defaultValues,
        entityId,
        entity,
        images,
        customSubmitButton,
        contactData,
        partnerData,
        pmProjectId,
        canEditCRMFiles,
        onOffer,
        data,
        setData,
        VAT,
        setVAT,
        updatePaymentValues,
        initialGroups,
        agents,
        initialAgentId,
    } = props;

    const confirm = useConfirm();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [initialIndex, setInitialIndex] = useState(0);

    const { currencyObj } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);

    const canAllPmProjects = checkPerm([
        {
            permissionId: '59',
            permissionType: 'ALL',
        },
    ]);

    const canAllGroups = checkPerm([
        {
            permissionId: '43',
            permissionType: 'ALL',
        },
    ]);

    const config = {
        'Content-Type': 'multipart/form-data',
    };

    /*
        Gallery states
    */
    const [estateGallery, setEstateGallery] = useState(
        images?.map((img) => {
            return { id: img.id ?? Math.random(), blob: null, url: img.url };
        }),
    );
    const [galleryOpen, setGalleryOpen] = useState(false);

    /*
        Selected project state
    */
    const [selectedProject, setSelectedProject] = useState(null);

    /*
        Groups state
    */
    const [groups, setGroups] = useState(initialGroups);

    /*
        Agent state
    */
    const [agentId, setAgentId] = useState(initialAgentId);

    const [entityFields, setEntityFields] = useState(null);
    /*
        They are called Contact Values, but they are actually entity values
        Too much code to change carafully if we want to rename it properly
    */
    const [contactValues, setContactValues] = useState(null);

    useEffect(() => {
        (async () => {
            setEntityFields(
                await getEntityFields(
                    entity === 'contact'
                        ? 'Contacts'
                        : entity === 'contract'
                          ? 'Contracts'
                          : entity === 'estate'
                            ? 'Immobile'
                            : entity === 'contractPM'
                              ? 'ContractPM'
                              : 'Partners',
                ),
            );
        })();
    }, [entity]);

    const changeValue = (colName, fieldName, newValue) => {
        const newContactValues = { ...contactValues };
        newContactValues[colName][fieldName] = newValue;
        setContactValues(newContactValues);
    };

    const nullifyOptions = (option) => {
        const newOption = {
            name: null,
            options: [],
        };
        if (option.options.length > 0) {
            option.options.forEach((subOpt) => {
                newOption.options.push(nullifyOptions(subOpt));
            });
        }
        return newOption;
    };

    useEffect(() => {
        if (!entityFields) return;

        /*
            This means that we are editing the entity
            Id is provided, and the current values
        */

        let newContactValues = {};
        // [Note to self]: if at any point in the future this component breaks, return the code to the commented version :)

        if (defaultValues) {
            newContactValues = { ...defaultValues };
        }

        entityFields.objectToRender?.forEach((col) => {
            if (defaultValues) {
                if (!(col.name in newContactValues)) {
                    newContactValues[col.name] = {};
                }
            } else {
                newContactValues[col.name] = {};
            }

            col.fields.forEach((field) => {
                if (defaultValues) {
                    if (!(field.name in newContactValues[col.name])) {
                        if (field.type === 'Structure') {
                            newContactValues[col.name][field.name] =
                                nullifyOptions(field);
                        } else if (field.type === 'Toggle') {
                            newContactValues[col.name][field.name] = false;
                        } else if (
                            field.type === 'Reference' ||
                            field.type === 'Criterion'
                        ) {
                            newContactValues[col.name][field.name] = {
                                table: [],
                                column: null,
                            };
                        } else {
                            newContactValues[col.name][field.name] = null;
                        }
                    }
                } else {
                    if (field.type === 'Structure') {
                        newContactValues[col.name][field.name] =
                            nullifyOptions(field);
                    } else if (field.type === 'Toggle') {
                        newContactValues[col.name][field.name] = false;
                    } else if (
                        field.type === 'Reference' ||
                        field.type === 'Criterion'
                    ) {
                        newContactValues[col.name][field.name] = {
                            table: [],
                            column: null,
                        };
                    } else {
                        newContactValues[col.name][field.name] = null;
                    }
                }
            });
        });

        setContactValues(newContactValues);
    }, [defaultValues, entityFields]);

    const submitCallback = (action = 'create') => {
        if (action === 'create') {
            if (entity === 'contact') {
                enqueueSnackbar(t('Contact created successfully!'), {
                    variant: 'success',
                });
                history.push(crm.base + crm.contacts.base + crm.contacts.read);
            } else if (entity === 'contract') {
                enqueueSnackbar(t('Contract created successfully!'), {
                    variant: 'success',
                });
                history.push(
                    crm.base + crm.contracts.base + crm.contracts.read,
                );
            } else if (entity === 'estate') {
                enqueueSnackbar(t('Catalog entity created successfully!'), {
                    variant: 'success',
                });
                history.push(crm.base + crm.estate.base + crm.estate.read);
            } else if (entity === 'partner') {
                enqueueSnackbar(t('Partner created successfully!'), {
                    variant: 'success',
                });
                history.push(
                    internalActivity.base +
                        internalActivity.partners.base +
                        internalActivity.partners.read,
                );
            } else if (entity === 'contractPM') {
                enqueueSnackbar(t('Contract created successfully!'), {
                    variant: 'success',
                });
                history.push(
                    pmRoute.base +
                        '/' +
                        pmProjectId +
                        pmRoute.projectContracts.base +
                        pmRoute.projectContracts.read,
                );
            }
        } else if (action === 'update') {
            if (entity === 'contact') {
                enqueueSnackbar(t('Contact updated successfully!'), {
                    variant: 'success',
                });
                history.push(crm.base + crm.contacts.base + crm.contacts.read);
            } else if (entity === 'contract') {
                enqueueSnackbar(t('Contract updated successfully!'), {
                    variant: 'success',
                });
                history.push(
                    crm.base + crm.contracts.base + crm.contracts.read,
                );
            } else if (entity === 'estate') {
                enqueueSnackbar(t('Catalog entity updated successfully!'), {
                    variant: 'success',
                });
                history.push(crm.base + crm.estate.base + crm.estate.read);
            } else if (entity === 'partner') {
                enqueueSnackbar(t('Partner updated successfully!'), {
                    variant: 'success',
                });
                history.push(
                    internalActivity.base +
                        internalActivity.partners.base +
                        internalActivity.partners.read,
                );
            } else if (entity === 'contractPM') {
                enqueueSnackbar(t('Contract updated successfully!'), {
                    variant: 'success',
                });
                history.push(
                    pmRoute.base +
                        '/' +
                        pmProjectId +
                        pmRoute.projectContracts.base +
                        pmRoute.projectContracts.read,
                );
            }
        } else if (action === 'delete') {
            if (entity === 'contact') {
                enqueueSnackbar(t('Contact deleted successfully!'), {
                    variant: 'success',
                });
                history.push(crm.base + crm.contacts.base + crm.contacts.read);
            } else if (entity === 'contract') {
                enqueueSnackbar(t('Contract deleted successfully!'), {
                    variant: 'success',
                });
                history.push(
                    crm.base + crm.contracts.base + crm.contracts.read,
                );
            } else if (entity === 'estate') {
                enqueueSnackbar(t('Catalog entity deleted successfully!'), {
                    variant: 'success',
                });
                history.push(crm.base + crm.estate.base + crm.estate.read);
            } else if (entity === 'partner') {
                enqueueSnackbar(t('Partner deleted successfully!'), {
                    variant: 'success',
                });
                history.push(
                    internalActivity.base +
                        internalActivity.partners.base +
                        internalActivity.partners.read,
                );
            } else if (entity === 'contractPM') {
                enqueueSnackbar(t('Contract deleted successfully!'), {
                    variant: 'success',
                });
                history.push(
                    pmRoute.base +
                        '/' +
                        pmProjectId +
                        pmRoute.projectContracts.base +
                        pmRoute.projectContracts.read,
                );
            }
        }
    };

    const translateHandleErrors = (error, message) => {
        let errorName = error[0].toUpperCase() + error.slice(1);

        if (message.includes('length'))
            enqueueSnackbar(t('key1', { fieldName: errorName }), {
                variant: 'error',
            });
        else if (message.includes('S+@'))
            enqueueSnackbar(t('key2', { fieldName: errorName }), {
                variant: 'error',
            });
        else if (message.includes('zA-Z'))
            enqueueSnackbar(t('key3', { fieldName: errorName }), {
                variant: 'error',
            });
        else
            enqueueSnackbar(t('key', { fieldName: errorName }), {
                variant: 'error',
            });
    };

    const submitHandle = async () => {
        if (loading) return;
        /*
            Validate JSON data
        */

        const { errors } = validate(
            contactValues,
            JSON.parse(entityFields.jsonSchema),
        );

        if (errors.length) {
            let e = errors[0].property.split('.');
            e = e[e.length - 1];
            translateHandleErrors(e, errors[0].message);
            e = e[0].toUpperCase() + e.slice(1) + ' ' + errors[0].message;
            // enqueueSnackbar(e, { variant: 'success' });
        } else {
            if (!entityId) {
                /**
                 * No entity id provided => we create it
                 */
                setLoading(true);
                try {
                    if (entity === 'estate') {
                        // if (!estateGallery.length) {
                        //     enqueueSnackbar(t('Add at least one photo!'), { variant: 'error' });
                        //     return;
                        // }
                        const reqBody = new FormData();

                        reqBody.append('data', JSON.stringify(contactValues));
                        for (const photo of estateGallery) {
                            if (photo.blob)
                                reqBody.append('images', photo.blob);
                        }

                        await API.post('immobile_create', reqBody, config);
                    } else if (entity === 'contact') {
                        const reqBody = {
                            data: { ...contactValues },
                            pm_planningOverviewProjectId:
                                selectedProject?.id ?? null,
                            groups,
                            agentId,
                        };

                        await API.post('contact', reqBody);
                    } else {
                        const reqBody = { ...contactValues };

                        await API.post('partners', reqBody);
                    }

                    submitCallback();
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            } else {
                /**
                 * Entity id provided => we update it
                 */
                setLoading(true);
                try {
                    if (entity === 'estate') {
                        // if (!estateGallery.length) {
                        //     enqueueSnackbar(t('Add at least one photo!'), { variant: 'error' });
                        //     return;
                        // }
                        const reqBody = new FormData();
                        reqBody.append(
                            'data',
                            JSON.stringify({
                                id: entityId,
                                data: { ...contactValues },
                            }),
                        );
                        for (const photo of estateGallery) {
                            if (photo.blob)
                                reqBody.append('images', photo.blob);
                        }

                        await API.put('/immobile', reqBody, config);
                    } else if (entity === 'contact') {
                        const reqBody = {
                            id: entityId,
                            data: { ...contactValues },
                            pm_planningOverviewProjectId:
                                selectedProject?.id ?? null,
                            groups,
                            agentId,
                        };

                        await API.put('contact', reqBody);
                    } else if (entity === 'partner') {
                        const reqBody = { ...contactValues };

                        await API.put('partners', {
                            id: entityId,
                            data: reqBody,
                        });
                    } else {
                        const reqBody = { ...contactValues };

                        await API.put(
                            entity === 'contract'
                                ? 'contracts'
                                : 'contracts_pm',
                            {
                                id: entityId,
                                data: reqBody,
                                priceWithoutVat: data.withoutTva,
                                totalPrice: data.withTva,
                                TVA: VAT,
                            },
                        );
                    }

                    submitCallback('update');
                } catch (err) {
                    console.error(err);
                } finally {
                    setLoading(false);
                }
            }
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);

            const params = {
                id: entityId,
            };

            if (entity === 'estate') {
                await API.delete('immobile', {
                    params,
                });
            }

            if (entity === 'contact') {
                await API.delete('contact', {
                    params,
                });
            }

            if (entity === 'partner') {
                await API.delete('partners', {
                    params,
                });
            }

            if (entity === 'contract') {
                await API.delete('contracts', {
                    params,
                });
            }

            if (entity === 'contractPM') {
                await API.delete('contracts_pm', {
                    params,
                });
            }

            submitCallback('delete');
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {galleryOpen && (
                <Gallery
                    open={galleryOpen}
                    setOpen={setGalleryOpen}
                    initialIndex={initialIndex}
                    urlList={
                        entityFields ? estateGallery.map((img) => img.url) : []
                    }
                />
            )}

            {entityFields && (
                <>
                    {loading && (
                        <div className="mb-8">
                            <LinearProgress />
                        </div>
                    )}

                    {contactValues ? (
                        <div className="flex flex-col gap-10">
                            <div className="relative z-20 grid w-full grid-cols-4 gap-20 2xl:gap-12 xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 sm:gap-8">
                                {entityFields.objectToRender?.map((col) => (
                                    <div key={col.displayName}>
                                        <h3 className="mb-10">
                                            {col.displayName}
                                        </h3>

                                        {col.fields.map((field, fieldIndex) => (
                                            <div
                                                key={
                                                    field.name +
                                                    field.displayName
                                                }
                                                className="relative mb-5"
                                                // style={{ zIndex: 9999 - fieldIndex }}
                                            >
                                                {field.type === 'Text' ? (
                                                    <TextField
                                                        name={field.name}
                                                        label={
                                                            field.displayName
                                                        }
                                                        placeholder={
                                                            field.displayName
                                                        }
                                                        value={
                                                            contactValues[
                                                                col.name
                                                            ][field.name]
                                                        }
                                                        onChange={(e) =>
                                                            changeValue(
                                                                col.name,
                                                                field.name,
                                                                e.target.value,
                                                            )
                                                        }
                                                        multiline={
                                                            field.selectedTypeOptions ===
                                                            'Long'
                                                        }
                                                        rows={
                                                            field.selectedTypeOptions ===
                                                            'Long'
                                                                ? 3
                                                                : 1
                                                        }
                                                    />
                                                ) : field.type === 'Number' ? (
                                                    field.selectedTypeOptions ===
                                                        '€' ||
                                                    field.selectedTypeOptions ===
                                                        'Lei' ? (
                                                        <LocaleTextField
                                                            name={field.name}
                                                            label={
                                                                field.displayName
                                                            }
                                                            placeholder={
                                                                field.displayName
                                                            }
                                                            value={
                                                                contactValues[
                                                                    col.name
                                                                ][field.name]
                                                            }
                                                            onChange={(e) => {
                                                                const value =
                                                                    formatPositiveNumberWithDigits(
                                                                        e.target
                                                                            .value,
                                                                    );

                                                                changeValue(
                                                                    col.name,
                                                                    field.name,
                                                                    value,
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <TextField
                                                            name={field.name}
                                                            type="number"
                                                            label={
                                                                field.displayName
                                                            }
                                                            placeholder={
                                                                field.displayName
                                                            }
                                                            value={
                                                                contactValues[
                                                                    col.name
                                                                ][field.name]
                                                            }
                                                            onChange={(e) =>
                                                                changeValue(
                                                                    col.name,
                                                                    field.name,
                                                                    parseInt(
                                                                        e.target
                                                                            .value,
                                                                    ),
                                                                )
                                                            }
                                                        />
                                                    )
                                                ) : field.type === 'Phone' ? (
                                                    <TextField
                                                        name={field.name}
                                                        label={
                                                            field.displayName
                                                        }
                                                        placeholder={
                                                            field.displayName
                                                        }
                                                        value={
                                                            contactValues[
                                                                col.name
                                                            ][field.name]
                                                        }
                                                        onChange={(e) =>
                                                            changeValue(
                                                                col.name,
                                                                field.name,
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                ) : field.type === 'Email' ? (
                                                    <TextField
                                                        name={field.name}
                                                        label={
                                                            field.displayName
                                                        }
                                                        placeholder={
                                                            field.displayName
                                                        }
                                                        value={
                                                            contactValues[
                                                                col.name
                                                            ][field.name]
                                                        }
                                                        onChange={(e) =>
                                                            changeValue(
                                                                col.name,
                                                                field.name,
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                ) : field.type === 'Link' ? (
                                                    <TextField
                                                        name={field.name}
                                                        label={
                                                            field.displayName
                                                        }
                                                        placeholder={
                                                            field.displayName
                                                        }
                                                        value={
                                                            contactValues[
                                                                col.name
                                                            ][field.name]
                                                        }
                                                        onChange={(e) =>
                                                            changeValue(
                                                                col.name,
                                                                field.name,
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                ) : field.type ===
                                                  'Selector' ? (
                                                    <LabelWrapper
                                                        label={
                                                            field.displayName
                                                        }
                                                    >
                                                        {field.selectedTypeOptions ===
                                                        'Multi-select' ? (
                                                            <MultiDropdown
                                                                options={
                                                                    field.options
                                                                }
                                                                placeholder={
                                                                    t(
                                                                        'Choose',
                                                                    ) +
                                                                    ' ' +
                                                                    field.displayName
                                                                }
                                                                selectedOptions={
                                                                    contactValues[
                                                                        col.name
                                                                    ][
                                                                        field
                                                                            .name
                                                                    ]
                                                                        ? contactValues[
                                                                              col
                                                                                  .name
                                                                          ][
                                                                              field
                                                                                  .name
                                                                          ]
                                                                        : []
                                                                }
                                                                setSelectedOptions={(
                                                                    i,
                                                                ) => {
                                                                    if (
                                                                        !contactValues[
                                                                            col
                                                                                .name
                                                                        ][
                                                                            field
                                                                                .name
                                                                        ]
                                                                    ) {
                                                                        changeValue(
                                                                            col.name,
                                                                            field.name,
                                                                            [i],
                                                                        );
                                                                        return;
                                                                    } else {
                                                                        if (
                                                                            contactValues[
                                                                                col
                                                                                    .name
                                                                            ][
                                                                                field
                                                                                    .name
                                                                            ].indexOf(
                                                                                i,
                                                                            ) >=
                                                                            0
                                                                        ) {
                                                                            changeValue(
                                                                                col.name,
                                                                                field.name,
                                                                                contactValues[
                                                                                    col
                                                                                        .name
                                                                                ][
                                                                                    field
                                                                                        .name
                                                                                ].filter(
                                                                                    (
                                                                                        o,
                                                                                    ) =>
                                                                                        o !=
                                                                                        i,
                                                                                ),
                                                                            );
                                                                        } else {
                                                                            changeValue(
                                                                                col.name,
                                                                                field.name,
                                                                                [
                                                                                    ...contactValues[
                                                                                        col
                                                                                            .name
                                                                                    ][
                                                                                        field
                                                                                            .name
                                                                                    ],
                                                                                    i,
                                                                                ],
                                                                            );
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        ) : field.selectedTypeOptions ===
                                                          'Uni-select' ? (
                                                            <Dropdown
                                                                options={
                                                                    field.options
                                                                }
                                                                selectedOption={
                                                                    contactValues[
                                                                        col.name
                                                                    ][
                                                                        field
                                                                            .name
                                                                    ]
                                                                        ? contactValues[
                                                                              col
                                                                                  .name
                                                                          ][
                                                                              field
                                                                                  .name
                                                                          ][0]
                                                                        : null
                                                                }
                                                                setSelectedOption={(
                                                                    i,
                                                                ) =>
                                                                    changeValue(
                                                                        col.name,
                                                                        field.name,
                                                                        [i],
                                                                    )
                                                                }
                                                                placeholder={
                                                                    t(
                                                                        'Choose',
                                                                    ) +
                                                                    ' ' +
                                                                    field.displayName
                                                                }
                                                            />
                                                        ) : field.selectedTypeOptions ===
                                                          'Etichete' ? (
                                                            <Tags
                                                                tags={
                                                                    field.options
                                                                        .filter(
                                                                            (
                                                                                f,
                                                                            ) =>
                                                                                contactValues[
                                                                                    col
                                                                                        .name
                                                                                ][
                                                                                    field
                                                                                        .name
                                                                                ]?.includes(
                                                                                    field.options.indexOf(
                                                                                        f,
                                                                                    ),
                                                                                ),
                                                                        )
                                                                        .map(
                                                                            (
                                                                                o,
                                                                            ) => ({
                                                                                id: field.options.indexOf(
                                                                                    o,
                                                                                ),
                                                                                value: o,
                                                                                name: o,
                                                                            }),
                                                                        ) ?? []
                                                                }
                                                                allTags={field.options.map(
                                                                    (
                                                                        o,
                                                                        index,
                                                                    ) => ({
                                                                        id: index,
                                                                        value: o,
                                                                        name: o,
                                                                    }),
                                                                )}
                                                                setTags={(i) =>
                                                                    changeValue(
                                                                        col.name,
                                                                        field.name,
                                                                        i.map(
                                                                            (
                                                                                el,
                                                                            ) =>
                                                                                el.id,
                                                                        ),
                                                                    )
                                                                }
                                                            />
                                                        ) : (
                                                            <SelectList
                                                                options={
                                                                    field.options
                                                                }
                                                                selectedOptions={
                                                                    contactValues[
                                                                        col.name
                                                                    ][
                                                                        field
                                                                            .name
                                                                    ]
                                                                }
                                                                setSelectedOptions={(
                                                                    i,
                                                                ) =>
                                                                    changeValue(
                                                                        col.name,
                                                                        field.name,
                                                                        i,
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </LabelWrapper>
                                                ) : field.type ===
                                                  'Structure' ? (
                                                    <LabelWrapper
                                                        label={
                                                            field.displayName
                                                        }
                                                    >
                                                        {field.options.map(
                                                            (
                                                                option,
                                                                optionIndex,
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            optionIndex
                                                                        }
                                                                        className="mb-2 last:mb-0"
                                                                    >
                                                                        <InputGroup
                                                                            placeholder={
                                                                                option.name
                                                                            }
                                                                            name={
                                                                                contactValues[
                                                                                    col
                                                                                        .name
                                                                                ][
                                                                                    field
                                                                                        .name
                                                                                ]
                                                                                    .options[
                                                                                    optionIndex
                                                                                ]
                                                                                    .name
                                                                            }
                                                                            setName={(
                                                                                newName,
                                                                            ) => {
                                                                                const newStructure =
                                                                                    {
                                                                                        ...contactValues[
                                                                                            col
                                                                                                .name
                                                                                        ][
                                                                                            field
                                                                                                .name
                                                                                        ],
                                                                                    };
                                                                                newStructure.options[
                                                                                    optionIndex
                                                                                ].name =
                                                                                    newName;
                                                                                changeValue(
                                                                                    col.name,
                                                                                    field.name,
                                                                                    newStructure,
                                                                                );
                                                                            }}
                                                                        >
                                                                            {option.options.map(
                                                                                (
                                                                                    opt,
                                                                                    optIndex,
                                                                                ) => {
                                                                                    return (
                                                                                        <div
                                                                                            className="mb-2 last:mb-0"
                                                                                            key={
                                                                                                optIndex
                                                                                            }
                                                                                        >
                                                                                            <InputGroup
                                                                                                placeholder={
                                                                                                    opt.name
                                                                                                }
                                                                                                name={
                                                                                                    contactValues[
                                                                                                        col
                                                                                                            .name
                                                                                                    ][
                                                                                                        field
                                                                                                            .name
                                                                                                    ]
                                                                                                        .options[
                                                                                                        optionIndex
                                                                                                    ]
                                                                                                        .options[
                                                                                                        optIndex
                                                                                                    ]
                                                                                                        .name
                                                                                                }
                                                                                                setName={(
                                                                                                    newName,
                                                                                                ) => {
                                                                                                    const newStructure =
                                                                                                        {
                                                                                                            ...contactValues[
                                                                                                                col
                                                                                                                    .name
                                                                                                            ][
                                                                                                                field
                                                                                                                    .name
                                                                                                            ],
                                                                                                        };
                                                                                                    newStructure.name =
                                                                                                        field.displayName;
                                                                                                    newStructure.options[
                                                                                                        optionIndex
                                                                                                    ].options[
                                                                                                        optIndex
                                                                                                    ].name =
                                                                                                        newName;
                                                                                                    changeValue(
                                                                                                        col.name,
                                                                                                        field.name,
                                                                                                        newStructure,
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                {opt.options.map(
                                                                                                    (
                                                                                                        o,
                                                                                                        oIndex,
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                className="mb-2 last:mb-0"
                                                                                                                key={
                                                                                                                    oIndex
                                                                                                                }
                                                                                                            >
                                                                                                                <InputGroup
                                                                                                                    placeholder={
                                                                                                                        o.name
                                                                                                                    }
                                                                                                                    name={
                                                                                                                        contactValues[
                                                                                                                            col
                                                                                                                                .name
                                                                                                                        ][
                                                                                                                            field
                                                                                                                                .name
                                                                                                                        ]
                                                                                                                            .options[
                                                                                                                            optionIndex
                                                                                                                        ]
                                                                                                                            .options[
                                                                                                                            optIndex
                                                                                                                        ]
                                                                                                                            .options[
                                                                                                                            oIndex
                                                                                                                        ]
                                                                                                                            .name
                                                                                                                    }
                                                                                                                    setName={(
                                                                                                                        newName,
                                                                                                                    ) => {
                                                                                                                        const newStructure =
                                                                                                                            {
                                                                                                                                ...contactValues[
                                                                                                                                    col
                                                                                                                                        .name
                                                                                                                                ][
                                                                                                                                    field
                                                                                                                                        .name
                                                                                                                                ],
                                                                                                                            };
                                                                                                                        newStructure.options[
                                                                                                                            optionIndex
                                                                                                                        ].options[
                                                                                                                            optIndex
                                                                                                                        ].options[
                                                                                                                            oIndex
                                                                                                                        ].name =
                                                                                                                            newName;
                                                                                                                        changeValue(
                                                                                                                            col.name,
                                                                                                                            field.name,
                                                                                                                            newStructure,
                                                                                                                        );
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        );
                                                                                                    },
                                                                                                )}
                                                                                            </InputGroup>
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </InputGroup>
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </LabelWrapper>
                                                ) : field.type === 'Toggle' ? (
                                                    <LabelWrapper
                                                        label={
                                                            field.displayName
                                                        }
                                                    >
                                                        <CheckItem
                                                            name={
                                                                field.options[
                                                                    contactValues[
                                                                        col.name
                                                                    ][
                                                                        field
                                                                            .name
                                                                    ]
                                                                        ? 1
                                                                        : 0
                                                                ]
                                                            }
                                                            checked={
                                                                contactValues[
                                                                    col.name
                                                                ][field.name]
                                                            }
                                                            setChecked={(
                                                                state,
                                                            ) =>
                                                                changeValue(
                                                                    col.name,
                                                                    field.name,
                                                                    state,
                                                                )
                                                            }
                                                        />
                                                    </LabelWrapper>
                                                ) : field.type === 'Date' ? (
                                                    <LabelWrapper
                                                        label={
                                                            field.displayName
                                                        }
                                                    >
                                                        <DatePicker
                                                            date={
                                                                contactValues[
                                                                    col.name
                                                                ][field.name]
                                                            }
                                                            setDate={(date) => {
                                                                changeValue(
                                                                    col.name,
                                                                    field.name,
                                                                    new Date(
                                                                        date,
                                                                    ).toJSON(),
                                                                );
                                                            }}
                                                        />
                                                    </LabelWrapper>
                                                ) : field.type ===
                                                  'Reference' ? (
                                                    <div className="rounded-md bg-layout-transparent p-2">
                                                        <LabelWrapper
                                                            label={
                                                                field.displayName
                                                            }
                                                        >
                                                            <div className="relative z-20">
                                                                <Dropdown
                                                                    options={
                                                                        field
                                                                            .refField
                                                                            .fields
                                                                    }
                                                                    selectedOption={
                                                                        contactValues[
                                                                            col
                                                                                .name
                                                                        ][
                                                                            field
                                                                                .name
                                                                        ].table
                                                                            .length
                                                                            ? field.refField.fields.indexOf(
                                                                                  contactValues[
                                                                                      col
                                                                                          .name
                                                                                  ][
                                                                                      field
                                                                                          .name
                                                                                  ]
                                                                                      .table[0],
                                                                              )
                                                                            : null
                                                                    }
                                                                    setSelectedOption={(
                                                                        i,
                                                                    ) => {
                                                                        const newReference =
                                                                            {
                                                                                ...contactValues[
                                                                                    col
                                                                                        .name
                                                                                ][
                                                                                    field
                                                                                        .name
                                                                                ],
                                                                            };
                                                                        newReference.table =
                                                                            [
                                                                                field
                                                                                    .refField
                                                                                    .fields[
                                                                                    i
                                                                                ],
                                                                            ];
                                                                        changeValue(
                                                                            col.name,
                                                                            field.name,
                                                                            newReference,
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="relative z-10 mt-2 border-l border-layout-light pl-2">
                                                                <Dropdown
                                                                    options={
                                                                        field
                                                                            .refField
                                                                            .subFields
                                                                    }
                                                                    selectedOption={
                                                                        contactValues[
                                                                            col
                                                                                .name
                                                                        ][
                                                                            field
                                                                                .name
                                                                        ].column
                                                                            ? field.refField.subFields.indexOf(
                                                                                  contactValues[
                                                                                      col
                                                                                          .name
                                                                                  ][
                                                                                      field
                                                                                          .name
                                                                                  ]
                                                                                      .column,
                                                                              )
                                                                            : null
                                                                    }
                                                                    setSelectedOption={(
                                                                        i,
                                                                    ) => {
                                                                        const newReference =
                                                                            {
                                                                                ...contactValues[
                                                                                    col
                                                                                        .name
                                                                                ][
                                                                                    field
                                                                                        .name
                                                                                ],
                                                                            };
                                                                        newReference.column =
                                                                            field.refField.subFields[
                                                                                i
                                                                            ];
                                                                        changeValue(
                                                                            col.name,
                                                                            field.name,
                                                                            newReference,
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </LabelWrapper>
                                                    </div>
                                                ) : field.type ===
                                                  'Criterion' ? (
                                                    <LabelWrapper
                                                        label={
                                                            field.displayName
                                                        }
                                                    >
                                                        <Dropdown
                                                            options={
                                                                field.refField
                                                                    .fields
                                                            }
                                                            selectedOption={
                                                                contactValues[
                                                                    col.name
                                                                ][field.name]
                                                                    .table
                                                                    .length
                                                                    ? field.refField.fields.indexOf(
                                                                          contactValues[
                                                                              col
                                                                                  .name
                                                                          ][
                                                                              field
                                                                                  .name
                                                                          ]
                                                                              .table[0],
                                                                      )
                                                                    : null
                                                            }
                                                            setSelectedOption={(
                                                                i,
                                                            ) => {
                                                                const newCriterion =
                                                                    {
                                                                        ...contactValues[
                                                                            col
                                                                                .name
                                                                        ][
                                                                            field
                                                                                .name
                                                                        ],
                                                                    };
                                                                newCriterion.table =
                                                                    [
                                                                        field
                                                                            .refField
                                                                            .fields[
                                                                            i
                                                                        ],
                                                                    ];
                                                                newCriterion.column =
                                                                    'default-col';
                                                                changeValue(
                                                                    col.name,
                                                                    field.name,
                                                                    newCriterion,
                                                                );
                                                            }}
                                                        />
                                                    </LabelWrapper>
                                                ) : field.type === 'File' ? (
                                                    <LabelWrapper
                                                        label={
                                                            field.displayName
                                                        }
                                                    >
                                                        <FileDisplay
                                                            purpose={field.type}
                                                            utilityOptions={{
                                                                canDelete: true,
                                                                canCopyLink: true,
                                                                canDownload: true,
                                                            }}
                                                            entity={entity}
                                                            type={
                                                                field.selectedTypeOptions
                                                            }
                                                            files={
                                                                contactValues[
                                                                    col.name
                                                                ][
                                                                    field.name
                                                                ]?.map(
                                                                    (item) => ({
                                                                        id: item.id,
                                                                        blob:
                                                                            item.blob ??
                                                                            null,
                                                                        url: item.url,
                                                                        name:
                                                                            item.name ??
                                                                            null,
                                                                    }),
                                                                ) ?? []
                                                            }
                                                            setFiles={(el) =>
                                                                changeValue(
                                                                    col.name,
                                                                    field.name,
                                                                    el,
                                                                )
                                                            }
                                                        />
                                                    </LabelWrapper>
                                                ) : field.type === 'Image' ? (
                                                    <LabelWrapper
                                                        label={
                                                            field.displayName
                                                        }
                                                    >
                                                        <FileDisplay
                                                            purpose={field.type}
                                                            entity={entity}
                                                            type={
                                                                field.selectedTypeOptions
                                                            }
                                                            files={
                                                                contactValues[
                                                                    col.name
                                                                ][
                                                                    field.name
                                                                ]?.map(
                                                                    (item) => ({
                                                                        id: item.id,
                                                                        blob:
                                                                            item.blob ??
                                                                            null,
                                                                        url: item.url,
                                                                        name:
                                                                            item.name ??
                                                                            null,
                                                                    }),
                                                                ) ?? []
                                                            }
                                                            setFiles={(el) =>
                                                                changeValue(
                                                                    col.name,
                                                                    field.name,
                                                                    el,
                                                                )
                                                            }
                                                        />
                                                    </LabelWrapper>
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                ))}

                                {/* Gallery */}
                                {entity === 'estate' && (
                                    <div>
                                        <h3 className="mb-10">
                                            {t('Gallery')}
                                        </h3>
                                        <GalleryEditable
                                            onEstate={true}
                                            gallery={estateGallery}
                                            setGallery={setEstateGallery}
                                            setOpen={setGalleryOpen}
                                            photoClickCallback={(
                                                photoIndex,
                                            ) => {
                                                setInitialIndex(photoIndex);
                                            }}
                                            view={
                                                canEditCRMFiles ? false : true
                                            }
                                        />
                                    </div>
                                )}

                                {/* Contact */}
                                {entity === 'contact' && (
                                    <div className="flex flex-col gap-20 2xl:gap-12 sm:gap-8">
                                        {canAllPmProjects && (
                                            <ProjectSelection
                                                title={t(
                                                    'Assign the client on a project',
                                                )}
                                                label={t(
                                                    'Select an option only if you want the client to be on a project',
                                                )}
                                                entityId={entityId}
                                                pmProjectId={pmProjectId}
                                                style={{
                                                    headerType: 'h3',
                                                }}
                                                selectedProject={
                                                    selectedProject
                                                }
                                                setSelectedProject={
                                                    setSelectedProject
                                                }
                                            />
                                        )}

                                        {canAllGroups && (
                                            <div className="flex flex-col gap-10">
                                                <h3>
                                                    {t(
                                                        'Assign client to user groups',
                                                    )}
                                                </h3>
                                                <GroupsSelector
                                                    variant="black"
                                                    groups={groups}
                                                    setGroups={(groups) => {
                                                        setGroups(groups);

                                                        if (agentId) {
                                                            const agent =
                                                                agents.find(
                                                                    (agent) =>
                                                                        agent.id ===
                                                                        agentId,
                                                                );

                                                            const agentGroups =
                                                                agent.groups.map(
                                                                    (group) =>
                                                                        group.groupId,
                                                                );

                                                            if (
                                                                !agentGroups.length
                                                            ) {
                                                                return;
                                                            }

                                                            const agentInGroups =
                                                                agentGroups.some(
                                                                    (id) =>
                                                                        groups.includes(
                                                                            id,
                                                                        ),
                                                                );

                                                            if (
                                                                !agentInGroups
                                                            ) {
                                                                setAgentId(
                                                                    null,
                                                                );
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                        )}

                                        <div className="flex flex-col gap-10">
                                            <h3>
                                                {t(
                                                    'Assign an agent to the client',
                                                )}
                                            </h3>
                                            <LabelWrapper
                                                label={t(
                                                    'Options will be displayed according to the groups selected above or on what resources groups you are on',
                                                )}
                                                style={{
                                                    whiteSpace: 'normal',
                                                }}
                                            >
                                                <Dropdown
                                                    options={(() => {
                                                        if (!groups.length) {
                                                            return agents.map(
                                                                (agent) =>
                                                                    agent
                                                                        .profile
                                                                        .name,
                                                            );
                                                        }

                                                        return agents
                                                            .filter((agent) =>
                                                                agent.groups
                                                                    .map(
                                                                        (
                                                                            group,
                                                                        ) =>
                                                                            group.groupId,
                                                                    )
                                                                    .some(
                                                                        (id) =>
                                                                            groups.includes(
                                                                                id,
                                                                            ),
                                                                    ),
                                                            )
                                                            .map(
                                                                (agent) =>
                                                                    agent
                                                                        .profile
                                                                        .name,
                                                            );
                                                    })()}
                                                    selectedOption={(() => {
                                                        const indexAgent =
                                                            agents.findIndex(
                                                                (agent) =>
                                                                    agent.id ===
                                                                    agentId,
                                                            );
                                                        return indexAgent >= 0
                                                            ? indexAgent
                                                            : null;
                                                    })()}
                                                    setSelectedOption={(i) =>
                                                        setAgentId(agents[i].id)
                                                    }
                                                    variant="black"
                                                />
                                            </LabelWrapper>
                                        </div>
                                    </div>
                                )}

                                {/* Contract */}
                                {(entity === 'contract' ||
                                    entity === 'contractPM') && (
                                    <>
                                        <div className="flex flex-col gap-10">
                                            <div className="flex flex-col gap-5">
                                                <h3 className="mb-5">
                                                    {t('VAT')}
                                                </h3>
                                                <div>
                                                    <LocaleTextField
                                                        className="w-full"
                                                        name="vat"
                                                        label={`${t('Enter VAT')} %`}
                                                        placeholder={`${t('Enter VAT')} %`}
                                                        value={VAT}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    %
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        // disabled={entityId ? true : false}
                                                        onChange={(e) => {
                                                            const VAT =
                                                                formatVATnumber(
                                                                    e.target
                                                                        .value,
                                                                );

                                                            setVAT(VAT);

                                                            updatePaymentValues(
                                                                VAT,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-5">
                                                <h3 className="mb-5">
                                                    {t('Contract value')}
                                                </h3>
                                                <div>
                                                    <LocaleTextField
                                                        className="w-full"
                                                        name="withoutTva"
                                                        label={
                                                            onOffer || entity
                                                                ? t(
                                                                      'Contract value without VAT',
                                                                  )
                                                                : t(
                                                                      'Enter the amount',
                                                                  )
                                                        }
                                                        placeholder={
                                                            onOffer || entity
                                                                ? t(
                                                                      'Contract value without VAT',
                                                                  )
                                                                : t(
                                                                      'Enter the amount',
                                                                  )
                                                        }
                                                        value={data.withoutTva}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    {
                                                                        currencyObj.currency
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled={
                                                            onOffer && !entityId
                                                        }
                                                        onChange={(e) => {
                                                            const value =
                                                                formatPositiveNumberWithDigits(
                                                                    e.target
                                                                        .value,
                                                                );

                                                            setData({
                                                                ...data,
                                                                withoutTva:
                                                                    value,
                                                                withTva:
                                                                    calculateSumWithVAT(
                                                                        value,
                                                                        VAT,
                                                                    ),
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <LocaleTextField
                                                        className="w-full"
                                                        name="withTva"
                                                        label={t(
                                                            'Contract value with VAT',
                                                        )}
                                                        placeholder={t(
                                                            'Contract value with VAT',
                                                        )}
                                                        value={data.withTva}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    {
                                                                        currencyObj.currency
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* Contact info based on selected offer */}
                                        {contactData && (
                                            <div>
                                                <h3 className="mb-10">
                                                    {t('Client info')}
                                                </h3>
                                                <div className="mb-5 space-y-5">
                                                    <TextField
                                                        label={t('Name')}
                                                        value={
                                                            contactData.standard
                                                                ?.name
                                                        }
                                                    />
                                                    <TextField
                                                        label="CNP"
                                                        value={
                                                            contactData.standard
                                                                ?.cnp
                                                        }
                                                    />
                                                    <TextField
                                                        label="Email"
                                                        value={
                                                            contactData.standard
                                                                ?.email
                                                        }
                                                    />
                                                    <TextField
                                                        label={t('Phone')}
                                                        value={
                                                            contactData.standard
                                                                ?.phone_number
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {/* Contact info based on selected offer */}
                                        {partnerData && (
                                            <div>
                                                <h3 className="mb-10">
                                                    {t('Partner info')}
                                                </h3>
                                                <div className="mb-5 space-y-5">
                                                    <TextField
                                                        label={t('Name')}
                                                        value={
                                                            partnerData.standard
                                                                ?.name
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ) : null}

                    <div className="h-10" />

                    <div className="flex items-center gap-4">
                        {customSubmitButton ? (
                            customSubmitButton(contactValues)
                        ) : (
                            <Button
                                color="primary"
                                startIcon={<PersonIcon />}
                                onClick={submitHandle}
                            >
                                {entityId ? t('edit') : t('create')}{' '}
                                {entity === 'contact'
                                    ? t('contact')
                                    : entity === 'estate'
                                      ? t('catalogue entity')
                                      : entity === 'contract' ||
                                          entity === 'contractPM'
                                        ? t('contract')
                                        : t('partner')}
                            </Button>
                        )}

                        <ErrorButton
                            startIcon={<Trash />}
                            onClick={() =>
                                confirm(
                                    t('Are you sure you want to delete?'),
                                    () => handleDelete(),
                                )
                            }
                        >
                            {t('Delete')}{' '}
                            {entity === 'contact'
                                ? t('contact')
                                : entity === 'estate'
                                  ? t('catalogue entity')
                                  : entity === 'contract' ||
                                      entity === 'contractPM'
                                    ? t('contract')
                                    : t('partner')}
                        </ErrorButton>
                    </div>
                </>
            )}
        </>
    );
};

ConfiguratorForm.propTypes = {
    entity: PropTypes.oneOf(['contact', 'contract', 'estate', 'partner']),
    entityId: PropTypes.string,
    defaultValues: PropTypes.object,
    images: PropTypes.array,
    customSubmitButton: PropTypes.element,
    contactData: PropTypes.object,
    partnerData: PropTypes.object,
    contractValueWithTVA: PropTypes.number,
    onOffer: PropTypes.bool,
    pmProjectId: PropTypes.string,
    canEditCRMFiles: PropTypes.bool,
    data: PropTypes.object,
    setData: PropTypes.func,
    VAT: PropTypes.number,
    setVAT: PropTypes.func,
    updatePaymentValues: PropTypes.func,
    initialGroups: PropTypes.array,
    agents: PropTypes.array,
    initialAgentId: PropTypes.string,
};

ConfiguratorForm.defaultProps = {
    entity: null,
    entityId: null,
    defaultValues: null,
    images: [],
    customSubmitButton: null,
    contactData: null,
    partnerData: null,
    contractValueWithTVA: 0,
    onOffer: false,
    pmProjectId: null,
    canEditCRMFiles: false,
    data: {
        withTva: 0,
        withoutTva: 0,
        date: null,
        advance: 0,
        advanceWithTva: 0,
        numberOfPayments: 0,
        payments: [],
    },
    setData: () => null,
    VAT: 0,
    setVAT: () => {},
    updatePaymentValues: () => null,
    initialGroups: [],
    agents: [],
    initialAgentId: null,
};

export default ConfiguratorForm;
