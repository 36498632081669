import React from 'react';

import PropTypes from 'prop-types';
import { generateUUID } from 'utils';

const KpiChartTooltipCursor = ({ points }) => {
    const cursorId = generateUUID();
    const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = points;

    return (
        <>
            <defs>
                <linearGradient id={cursorId} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="var(--disabled)" />
                    <stop offset="100%" stopColor="var(--layout-transparent)" />
                </linearGradient>
            </defs>
            <line x1={x1 - 1} x2={x2} y1={y1} y2={y2} stroke={`url(#${cursorId})`} />
        </>
    );
};

KpiChartTooltipCursor.propTypes = {
    points: PropTypes.array,
};

KpiChartTooltipCursor.defaultProps = {
    points: [],
};

export default KpiChartTooltipCursor;
