import React from 'react';

import Departments from 'pages/configurator/departments';
import CreateDepartments from 'pages/configurator/departments/create-departments';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InitializeButtons from './initialize-buttons';
import InitializeStepInfo from './initialize-step-info';

const InitializeDepartments = ({ handleIncrementStep, handleDecrementStep, initializeData, setInitializeData }) => {
    const { t } = useTranslation();

    return (
        <>
            <InitializeStepInfo
                mainInfo={[
                    {
                        id: 0,
                        content: t(
                            'You must add departments which are needed in order to create new tasks, comments or add files in the timeline',
                        ),
                    },
                ]}
                className="mb-10"
            />
            {initializeData.tab === 0 && <Departments setInitializeData={setInitializeData} />}
            {initializeData.tab === 1 && <CreateDepartments setInitializeData={setInitializeData} />}
            <InitializeButtons
                handleIncrementStep={handleIncrementStep}
                handleDecrementStep={handleDecrementStep}
                className={'mt-20 flex justify-start gap-4'}
                canSkip={false}
                nextDisabled={initializeData.nextDisabled || initializeData.loading}
                prevDisabled={initializeData.loading}
            />
        </>
    );
};

InitializeDepartments.propTypes = {
    handleIncrementStep: PropTypes.func,
    handleDecrementStep: PropTypes.func,
    initializeData: PropTypes.object,
    setInitializeData: PropTypes.func,
};

InitializeDepartments.defaultProps = {
    handleIncrementStep: null,
    handleDecrementStep: null,
    initializeData: {},
    setInitializeData: () => {},
};

export default InitializeDepartments;
