import React from 'react';

import UserDetails from 'components/crm/users/user-details';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateUser = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new user')}</title>
            </Helmet>

            <Header pageTitle={t('Add new user')} />
            <div className="page-container">
                <UserDetails editableField={false} noDate />
            </div>
        </>
    );
};

export default CreateUser;
