import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, CircularProgress } from '@material-ui/core';

import { Revenues } from 'api/Expenses_Revenues';
import Configurator from 'components/shared/configurator/configurator';
import ConfiguratorForm from 'components/shared/configurator/configurator-form';
import UserContext from 'contexts/UserContext';
import { validate } from 'jsonschema';
import { useSnackbar } from 'notistack';
import { articlesInfoForPDF } from 'pages/quotes/newBidding/pdInfo-functions';
import { PropTypes } from 'prop-types';
import {
    Dropdown,
    Header,
    LabelWrapper,
    Stepper,
    Tabs,
} from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm, projectInManagement as pmRoute } from 'routes';
import API from 'utils/axios';
import {
    getCompanyVAT,
    getEntityFields,
    getPartners,
} from 'utils/getterFunctions';
import { calculateSumWithVAT, errorHandling } from 'utils/index';
import * as yup from 'yup';

import CreateContractGenerateDocument from './creation-steps/create-contract-generate-document';
import CreateContractPaymentTypeSelection from './creation-steps/create-contract-payment-type-selection';

const defaultData = {
    withTva: 0,
    withoutTva: 0,
    date: null,
    advance: 0,
    advanceWithTva: 0,
    numberOfPayments: 0,
    payments: [],
};

const CreateContract = (props) => {
    const { pmProjectId } = props;
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const params = new URL(document.location).searchParams;
    const { checkPerm } = useContext(UserContext);

    const offerId = params.get('offerId');

    const [step, setStep] = useState(0);

    const [clients, setClients] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);

    const [selectedPartner, setSelectedPartner] = useState(null);
    const [partners, setPartners] = useState([]);
    const [partnersOptions, setPartnersOptions] = useState([]);

    const [contractTypes, setContractTypes] = useState([]);

    // Step 1 info
    const [contractValues, setContractValues] = useState(null);
    const [contractMode, setContractMode] = useState(pmProjectId ? 4 : 0);
    const [selectedContractType, setSelectedContractType] = useState(null);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [VAT, setVAT] = useState(0);
    const [data, setData] = useState(defaultData);

    // Step 2 info
    const [paymentType, setPaymentType] = useState(0);

    // Step 3 info
    const [documentMode, setDocumentMode] = useState(0);
    const [templateData, setTemplateData] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [pdf, setPdf] = useState({ info: null, blob: null });

    // Data
    const [loading, setLoading] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [entityFields, setEntityFields] = useState(null);
    const [offers, setOffers] = useState([]);
    const [milestones, setMilestones] = useState([]);

    const canAll = checkPerm([
        {
            permissionId: '8',
            permissionType: 'ALL',
        },
    ]);

    const canAllPmContracts = checkPerm([
        {
            permissionId: '60',
            permissionType: 'ALL',
        },
    ]);

    const allTabs = [
        {
            id: '8',
            tab: t('Preview'),
        },
        {
            id: '13',
            tab: 'Configurator',
        },
    ];
    const tabs = [];
    allTabs.forEach((t) => {
        if (
            checkPerm([
                {
                    permissionId: t.id,
                    permissionType: 'ALL',
                },
            ])
        )
            tabs.push(t.tab);
    });

    const getAllRevenues = async () => {
        try {
            const res = await Revenues.get(999999, 0);
            if (res.ok) return res.data.revenuName;

            throw res.error;
        } catch (error) {
            console.error(error);
            enqueueSnackbar(
                errorHandling(error).length > 100
                    ? errorHandling(error)
                    : t(errorHandling(error)),
                {
                    variant: 'error',
                },
            );
            throw 'Error fetching revenues';
        }
    };

    const handleOfferChange = (selection, offersList, clientsList) => {
        const totalPrice = Number(offersList[selection].totalPrice);
        const priceWithoutVat = Number(offersList[selection].priceWithoutVat);

        setData({
            ...data,
            withTva: totalPrice,
            withoutTva: priceWithoutVat,
        });

        const offerClientId = offersList[selection].contact.id;
        const indexOfClient = clientsList.findIndex(
            (client) => client.id === offerClientId,
        );

        const vatAmount = totalPrice - priceWithoutVat;
        const vatRate = (vatAmount / priceWithoutVat) * 100;

        setVAT(vatRate || 0);
        setSelectedClient(indexOfClient);
    };

    useEffect(() => {
        if ((!canAll && !pmProjectId) || (!canAllPmContracts && pmProjectId)) {
            history.push('/');
        }

        (async () => {
            try {
                setLoading(true);

                const params = {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                };

                const [
                    resTypes,
                    resEntity,
                    resActivities,
                    resTemplates,
                    resVat,
                    resAcceptedOffer,
                    resRevenues,
                    resContacts = null,
                    resPartners = null,
                ] = await Promise.all([
                    API.get('/contractTypes', {
                        params,
                    }),
                    getEntityFields('Contracts'),
                    API.get(
                        pmProjectId
                            ? '/pmProjectActivity'
                            : '/internal_activities',
                        {
                            params: {
                                ...params,
                                pmProjectPlanId: pmProjectId ?? undefined,
                            },
                        },
                    ),
                    API.get('docTemplates', {
                        params,
                    }),
                    getCompanyVAT(),
                    API.get('/acceptedOffer'),
                    getAllRevenues(),
                    ...(!pmProjectId
                        ? [
                              API.get('/contacts', {
                                  params: {
                                      ...params,
                                      type: 'ALL',
                                  },
                              }),
                              getPartners(),
                          ]
                        : []),
                ]);

                setContractTypes(resTypes.data.contractTypes.contractTypes);

                setEntityFields(resEntity);

                const fetchedMilestones = pmProjectId
                    ? resActivities.data.activity.map(
                          (activity) => activity.activityInfo,
                      )
                    : resActivities.data.activity;
                setMilestones(
                    fetchedMilestones.filter(
                        (activity) => activity.activitiesTasks.length > 0,
                    ),
                );

                setTemplates(resTemplates.data.data);

                setVAT(resVat);

                let fetchedClients = [];
                if (!pmProjectId) {
                    fetchedClients = resContacts.data.data.contacts;
                    setClients(fetchedClients);
                    setClientOptions(
                        fetchedClients.map((c) => {
                            const data = JSON.parse(c.data);
                            return 'standard' in data &&
                                'name' in data['standard']
                                ? data['standard'].name
                                : 'Nume inexistent';
                        }),
                    );

                    setPartners(resPartners);
                    setPartnersOptions(
                        resPartners.map((partner) => {
                            const data =
                                typeof partner.data === 'string'
                                    ? JSON.parse(partner.data)
                                    : partner.data;
                            return data['standard'].name;
                        }),
                    );
                }

                const fetchedOffers = resAcceptedOffer.data.updatedOffers;
                for (const offer of fetchedOffers) {
                    let articleItems = [];
                    if (offer.offerArticles.length) {
                        const newSelectedArticlesForm = offer.offerArticles.map(
                            (art) => {
                                const newArt = {
                                    ...art,
                                    selectedAttributes: [],
                                    selectedVariations: [],
                                    amount: art.amount || art.quantity,
                                };

                                delete newArt.quantity;
                                return newArt;
                            },
                        );

                        articleItems = articlesInfoForPDF(
                            newSelectedArticlesForm,
                            resRevenues,
                            offer.id,
                        );
                    }

                    offer.items = articleItems;
                }
                setOffers(fetchedOffers);
                if (offerId) {
                    const idx = fetchedOffers.findIndex(
                        (offer) => offer.id === offerId,
                    );
                    setSelectedOffer(idx);
                    handleOfferChange(idx, fetchedOffers, fetchedClients);
                }
            } catch (err) {
                console.error(err);
                enqueueSnackbar(
                    errorHandling(err).length > 100
                        ? errorHandling(err)
                        : t(errorHandling(err)),
                    {
                        variant: 'error',
                    },
                );
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const translateHandleErrors = (key, message) => {
        let errorName = key[0].toUpperCase() + key.slice(1);

        if (message.includes('length'))
            enqueueSnackbar(t('key1', { fieldName: errorName }), {
                variant: 'error',
            });
        else if (message.includes('S+@'))
            enqueueSnackbar(t('key2', { fieldName: errorName }), {
                variant: 'error',
            });
        else if (message.includes('zA-Z'))
            enqueueSnackbar(t('key3', { fieldName: errorName }), {
                variant: 'error',
            });
        else
            enqueueSnackbar(t('key', { fieldName: errorName }), {
                variant: 'error',
            });
    };

    const validateContractValues = (values) => {
        const { errors } = validate(
            values,
            JSON.parse(entityFields.jsonSchema),
        );

        const key = errors[0]?.property.split('.').at(-1) || null;
        const message = errors[0]?.message || null;

        return { key, message };
    };

    const validateFirstStep = (values) => {
        const { key, message } = validateContractValues(values);

        if (key && message) {
            translateHandleErrors(key, message);
            return false;
        } else if (selectedOffer === null && contractMode === 0) {
            enqueueSnackbar(t('Contract must be based on an offer!'), {
                variant: 'error',
            });
            return false;
        } else if (selectedClient === null && contractMode === 2) {
            enqueueSnackbar(t('Contract must have one client!'), {
                variant: 'error',
            });
            return false;
        } else if (selectedPartner === null && contractMode === 1) {
            enqueueSnackbar(t('Contract must have one partner!'), {
                variant: 'error',
            });
            return false;
        } else if (selectedContractType === null) {
            enqueueSnackbar(t('Contract must have one contract type!'), {
                variant: 'error',
            });
            return false;
        }

        return true;
    };

    const schema = yup.object().shape({
        elements: yup.array().of(
            yup.object().shape({
                value: yup
                    .mixed()
                    .test(
                        'validValue',
                        t('Every element must have content'),
                        (value, context) => {
                            const { itemType } = context.parent;

                            if (!value === true) return false;
                            else if (Array.isArray(value))
                                return value.length !== 0;
                            else if (typeof value === 'object') {
                                try {
                                    if (itemType === 'table') {
                                        return yup
                                            .object()
                                            .shape({
                                                columns: yup
                                                    .array()
                                                    .min(1)
                                                    .required(),
                                                rows: yup
                                                    .array()
                                                    .min(1)
                                                    .required(),
                                            })
                                            .validateSync(value);
                                    }

                                    if (itemType === 'image') {
                                        return yup
                                            .object()
                                            .shape({
                                                url: yup
                                                    .string()
                                                    .typeError()
                                                    .required(),
                                            })
                                            .validateSync(value);
                                    }

                                    return true;
                                } catch (error) {
                                    console.error(error);
                                    return false;
                                }
                            }

                            return true;
                        },
                    ),
                entity: yup.array().of(
                    yup.object().shape({
                        entityId: yup
                            .string()
                            .nullable()
                            .test(
                                'validEntity',
                                t(
                                    "One element doesn't have the selected entity",
                                ),
                                (id, context) => {
                                    const { itemType, purpose } =
                                        context.parent;

                                    try {
                                        if (
                                            purpose === 'milestone' ||
                                            purpose === 'milestones' ||
                                            itemType === 'offer'
                                        ) {
                                            return yup
                                                .string()
                                                .typeError()
                                                .validateSync(id);
                                        }

                                        return true;
                                    } catch (error) {
                                        console.error(error);
                                        return false;
                                    }
                                },
                            ),
                    }),
                ),
            }),
        ),
    });

    const generateTemplateRequestData = async () => {
        try {
            const newElements = structuredClone(templateData.elements);
            const newColumns = structuredClone(templateData.columns);

            for (const key of Object.keys(newColumns)) {
                const column = newColumns[key];
                const isMainColumn = key.includes('column-main');

                if (!column.isActive) {
                    for (const elementId of column.elementsIds) {
                        const element = newElements[elementId];

                        if (
                            isMainColumn &&
                            !element.isMultiLanguage &&
                            !element.flagDisabled
                        )
                            continue;

                        delete newElements[elementId];
                    }

                    delete newColumns[key];
                } else {
                    for (let i = 0; i < column.elementsIds.length; i++) {
                        const elementId = column.elementsIds[i];

                        if (newElements[elementId].flagDisabled) {
                            delete newElements[elementId];
                            column.elementsIds = column.elementsIds.filter(
                                (eI) => eI !== elementId,
                            );
                        }
                    }
                }
            }

            const newColumnOrder = structuredClone(
                templateData.columnOrder,
            ).filter((column) => Object.keys(newColumns).includes(column));

            await schema.validate({
                elements: Object.values(newElements).filter((element) => {
                    if (element.id.includes('column-main')) return true;
                    return element.isMultiLanguage;
                }),
            });

            const images = [];
            const filesMetadata = [];
            for (const key of Object.keys(newElements)) {
                const element = newElements[key];

                if (element.itemType === 'image') {
                    if (!element.value.blob) {
                        element.value = { blob: null, url: element.value.url };
                        element.entity = element.entity.map((entry) => ({
                            ...entry,
                            data: entry.data,
                        }));
                        continue;
                    }

                    const file = element.value.blob;
                    images.push(file);
                    filesMetadata.push({
                        id: element.id,
                        position: null,
                    });
                    element.value = { blob: null, url: null };
                    element.entity = element.entity.map((entry) => ({
                        ...entry,
                        data: entry.data,
                    }));
                } else if (element.itemType === 'gallery') {
                    const newElementValue = [];

                    for (let i = 0; i < element.value.length; i++) {
                        const value = element.value[i];
                        if (!value.blob) {
                            newElementValue.push({
                                blob: null,
                                url: value.url,
                            });
                            continue;
                        }

                        const file = value.blob;
                        images.push(file);
                        filesMetadata.push({
                            id: element.id,
                            position: i,
                        });
                        newElementValue.push({ blob: null, url: null });
                    }
                    element.value = newElementValue;
                    element.entity = element.entity.map((entry) => ({
                        ...entry,
                        data: entry.data,
                    }));
                }
            }

            const reqTemplateData = {
                ...templateData,
                columns: newColumns,
                columnOrder: newColumnOrder,
                elements: newElements,
            };

            return { images, filesMetadata, reqTemplateData };
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const submitHandle = async () => {
        const { key, message } = validateContractValues(contractValues);
        if (key && message) {
            translateHandleErrors(key, message);
            return;
        }

        setIsCreating(true);

        const config = {
            'Content-Type': 'multipart/form-data',
        };

        try {
            const reqBody = new FormData();

            if (documentMode === 0 && pdf.blob) {
                reqBody.append('files', pdf.blob);
                reqBody.append('isPersonalized', false);
            }
            if (documentMode === 1) {
                reqBody.append('templateId', templates[selectedTemplate].id);

                const { images, filesMetadata, reqTemplateData } =
                    await generateTemplateRequestData();
                reqBody.append('templateData', JSON.stringify(reqTemplateData));
                reqBody.append('filesMetadata', JSON.stringify(filesMetadata));
                for (const image of images) {
                    reqBody.append('files', image);
                }
                reqBody.append('isPersonalized', true);
            }

            reqBody.append('data', JSON.stringify(contractValues));
            if (contractMode === 0)
                reqBody.append('offerId', offers[selectedOffer].id);
            if (contractMode === 0 || contractMode === 2)
                reqBody.append('contactId', clients[selectedClient].id);
            if (contractMode === 1)
                reqBody.append('partnerId', partners[selectedPartner].id);
            if (contractMode === 3) {
                reqBody.append('credit', true);
                if (pmProjectId) reqBody.append('projectId', pmProjectId);
            }
            if (contractMode === 4) reqBody.append('projectId', pmProjectId);

            if (paymentType === 0) {
                reqBody.append(
                    'forecastedPayments',
                    JSON.stringify([
                        {
                            sum: data.withTva,
                            sumWithoutVAT: data.withoutTva,
                            status: 'NOT_PAID',
                            description: '',
                            observation: '',
                            date: data.date,
                        },
                    ]),
                );
            } else if (paymentType === 1) {
                reqBody.append(
                    'forecastedPayments',
                    JSON.stringify([
                        ...data.payments.map((p) => ({
                            sum: p.amountWithTva,
                            sumWithoutVAT: p.amount,
                            status: 'NOT_PAID',
                            description: '',
                            observation: '',
                            date: p.date,
                        })),
                        {
                            sum: data.advanceWithTva,
                            sumWithoutVAT: data.advance,
                            status: 'NOT_PAID',
                            description: '',
                            observation: '',
                            date: new Date(),
                        },
                    ]),
                );
            }

            reqBody.append(
                'contractTypeId',
                contractTypes[selectedContractType].id,
            );

            reqBody.append(
                'additionalValues',
                JSON.stringify({
                    additionalSum: 0,
                    discount: 0,
                    TVA: VAT,
                    priceWithoutVat:
                        paymentType === 0
                            ? data.withoutTva
                            : paymentType === 1
                              ? data.payments.reduce(
                                    (sum, el) => sum + el.amount,
                                    0,
                                ) + data.advance
                              : 0,
                    totalPrice:
                        paymentType === 0
                            ? data.withTva
                            : paymentType === 1
                              ? data.payments.reduce(
                                    (sum, el) => sum + el.amountWithTva,
                                    0,
                                ) + data.advanceWithTva
                              : 0,
                }),
            );

            if (pmProjectId) await API.post('contracts_pm', reqBody, config);
            else await API.post('contracts', reqBody, config);

            enqueueSnackbar(t('Contract created successfully!'), {
                variant: 'success',
            });

            if (pmProjectId) {
                history.push(
                    pmRoute.base +
                        '/' +
                        pmProjectId +
                        pmRoute.projectContracts.base +
                        pmRoute.projectContracts.read,
                );
            } else {
                history.push(
                    crm.base + crm.contracts.base + crm.contracts.read,
                );
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsCreating(false);
        }
    };

    const checkParseJSON = (data) => {
        let dataContract;

        if (typeof data === 'object' && data !== null) {
            dataContract = JSON.parse(JSON.stringify(data));
        } else {
            dataContract = JSON.parse(data);
        }

        return dataContract;
    };

    const handleUpdatePaymentValues = (VAT) => {
        if (paymentType === 0) {
            setData((prev) => ({
                ...prev,
                withTva: calculateSumWithVAT(prev.withoutTva, VAT),
            }));
        }

        if (paymentType === 1) {
            setData((prev) => ({
                ...prev,
                withTva: calculateSumWithVAT(prev.withoutTva, VAT),
                advanceWithTva: calculateSumWithVAT(prev.advance, VAT),
                payments: prev.payments.map((payment) => ({
                    ...payment,
                    amountWithTva: calculateSumWithVAT(payment.amount, VAT),
                })),
            }));
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {pmProjectId ? 'PM' : 'CRM'} | {t('create-contract')}
                </title>
            </Helmet>

            <Header
                pageTitle={t('create-contract')}
                toolbar={
                    <Tabs
                        disabled={isCreating}
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                }
            />

            {((canAll && !pmProjectId) || (canAllPmContracts && pmProjectId)) &&
                (loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="page-container">
                        {activeTab === 0 ? (
                            <>
                                {/* Steps */}
                                <div className="mb-10 block">
                                    <Stepper
                                        step={step}
                                        steps={[
                                            t('Information'),
                                            t('Payment type'),
                                            t('Document'),
                                        ]}
                                    />
                                </div>

                                {step === 0 ? (
                                    <>
                                        {/* Offer & Contract Type */}
                                        <div className="mb-12 flex items-start gap-10 md:flex-col">
                                            <div className="flex gap-4 rounded-xl border border-layout-lighter p-4 sm:w-full sm:flex-col">
                                                <LabelWrapper
                                                    label={t(
                                                        'Choose on what the contract will be based on',
                                                    )}
                                                >
                                                    <Dropdown
                                                        options={[
                                                            t('With offer'),
                                                            t('On partner'),
                                                            t('On client'),
                                                            t(
                                                                'Shareholder credit',
                                                            ),
                                                            t('Simple'),
                                                        ]}
                                                        selectedOption={
                                                            contractMode
                                                        }
                                                        setSelectedOption={(
                                                            i,
                                                        ) => {
                                                            setSelectedOffer(
                                                                null,
                                                            );
                                                            setSelectedClient(
                                                                null,
                                                            );
                                                            setSelectedPartner(
                                                                null,
                                                            );
                                                            setData(
                                                                defaultData,
                                                            );
                                                            setContractMode(i);
                                                        }}
                                                        disabled={offerId}
                                                        hiddenOptions={
                                                            pmProjectId
                                                                ? [0, 1, 2]
                                                                : [4]
                                                        }
                                                    />
                                                </LabelWrapper>

                                                {activeTab !== 1 &&
                                                    contractMode === 0 && (
                                                        <LabelWrapper
                                                            label={t(
                                                                'Base offer for the contract',
                                                            )}
                                                        >
                                                            <Dropdown
                                                                options={offers.map(
                                                                    (offer) =>
                                                                        offer.name,
                                                                )}
                                                                selectedOption={
                                                                    selectedOffer
                                                                }
                                                                setSelectedOption={(
                                                                    i,
                                                                ) => {
                                                                    setSelectedOffer(
                                                                        i,
                                                                    );
                                                                    handleOfferChange(
                                                                        i,
                                                                        offers,
                                                                        clients,
                                                                    );
                                                                }}
                                                                disabled={
                                                                    offerId
                                                                }
                                                            />
                                                        </LabelWrapper>
                                                    )}
                                                {contractMode === 1 && (
                                                    <LabelWrapper
                                                        label={t(
                                                            'Base partner for the contract',
                                                        )}
                                                    >
                                                        <Dropdown
                                                            options={
                                                                partnersOptions
                                                            }
                                                            selectedOption={
                                                                selectedPartner
                                                            }
                                                            setSelectedOption={(
                                                                e,
                                                            ) => {
                                                                setSelectedPartner(
                                                                    e,
                                                                );
                                                            }}
                                                        />
                                                    </LabelWrapper>
                                                )}
                                                {activeTab !== 1 &&
                                                    contractMode === 2 && (
                                                        <LabelWrapper
                                                            label={t(
                                                                'Base client for the contract',
                                                            )}
                                                        >
                                                            <Dropdown
                                                                options={
                                                                    clientOptions
                                                                }
                                                                selectedOption={
                                                                    selectedClient
                                                                }
                                                                setSelectedOption={(
                                                                    newClientIndex,
                                                                ) =>
                                                                    setSelectedClient(
                                                                        newClientIndex,
                                                                    )
                                                                }
                                                            />
                                                        </LabelWrapper>
                                                    )}
                                            </div>

                                            <div className="flex gap-4 rounded-xl border border-layout-lighter p-4 sm:w-full sm:flex-col">
                                                <LabelWrapper
                                                    label={t(
                                                        'The type of the contract',
                                                    )}
                                                >
                                                    <Dropdown
                                                        options={contractTypes.map(
                                                            (ct) => ct.name,
                                                        )}
                                                        selectedOption={
                                                            selectedContractType
                                                        }
                                                        setSelectedOption={
                                                            setSelectedContractType
                                                        }
                                                    />
                                                </LabelWrapper>
                                            </div>
                                        </div>

                                        <ConfiguratorForm
                                            defaultValues={contractValues}
                                            entity={
                                                pmProjectId
                                                    ? 'contractPM'
                                                    : 'contract'
                                            }
                                            customSubmitButton={(values) => (
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        if (
                                                            validateFirstStep(
                                                                values,
                                                            )
                                                        ) {
                                                            setContractValues(
                                                                values,
                                                            );
                                                            setStep(
                                                                (prev) =>
                                                                    prev + 1,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {t('Next step')}
                                                </Button>
                                            )}
                                            contactData={
                                                selectedOffer !== null
                                                    ? checkParseJSON(
                                                          offers[selectedOffer]
                                                              .contact.data,
                                                      )
                                                    : selectedClient !== null
                                                      ? checkParseJSON(
                                                            clients[
                                                                selectedClient
                                                            ].data,
                                                        )
                                                      : null
                                            }
                                            partnerData={
                                                selectedPartner !== null
                                                    ? checkParseJSON(
                                                          partners[
                                                              selectedPartner
                                                          ].data,
                                                      )
                                                    : null
                                            }
                                            data={data}
                                            setData={setData}
                                            onOffer={contractMode === 0}
                                            VAT={VAT}
                                            setVAT={setVAT}
                                            updatePaymentValues={
                                                handleUpdatePaymentValues
                                            }
                                        />
                                    </>
                                ) : step === 1 ? (
                                    <CreateContractPaymentTypeSelection
                                        paymentType={paymentType}
                                        setPaymentType={setPaymentType}
                                        data={data}
                                        setData={setData}
                                        setStep={setStep}
                                        VAT={VAT}
                                    />
                                ) : step === 2 ? (
                                    <CreateContractGenerateDocument
                                        disabled={isCreating}
                                        canSkip={contractMode === 3}
                                        documentMode={documentMode}
                                        setDocumentMode={setDocumentMode}
                                        pdf={pdf}
                                        setPdf={setPdf}
                                        templates={templates}
                                        selectedTemplate={selectedTemplate}
                                        setSelectedTemplate={
                                            setSelectedTemplate
                                        }
                                        templateData={templateData}
                                        setTemplateData={setTemplateData}
                                        setStep={setStep}
                                        milestones={milestones}
                                        offers={offers}
                                        initialData={
                                            contractMode === 0
                                                ? [
                                                      {
                                                          itemType: 'content',
                                                          purpose: 'offer',
                                                          id: offers[
                                                              selectedOffer
                                                          ].id,
                                                          position: 0,
                                                      },
                                                      {
                                                          itemType: 'signature',
                                                          purpose: 'offer',
                                                          id: offers[
                                                              selectedOffer
                                                          ].id,
                                                          position: 0,
                                                      },
                                                  ]
                                                : []
                                        }
                                        pmProjectId={pmProjectId}
                                        onSubmit={submitHandle}
                                    />
                                ) : null}
                            </>
                        ) : null}
                        {activeTab === 1 ? (
                            <Configurator
                                entity={pmProjectId ? 'contractPM' : 'contract'}
                            />
                        ) : null}
                    </div>
                ))}
        </>
    );
};

CreateContract.propTypes = {
    pmProjectId: PropTypes.string,
};

CreateContract.defaultProps = {
    pmProjectId: null,
};

export default CreateContract;
