import React, { memo, useEffect } from 'react';

import currentDayHighlightPath from 'assets/frappe-gantt/svgs/current-day-highlight.svg';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import useQuery from 'hooks/useQuery';
import PropTypes from 'prop-types';
import { Tabs } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { getRawImageFromPath } from 'utils';

import Gantt from './frappe-gantt';
import GanttLegend from './gantt-legend';
import GanttTable from './gantt-table';

const FrappeGanttLayout = memo(function FrappeGanttLayout({ milestones, customGanttDates, getTasksFunction, type }) {
    const { t, i18n } = useTranslation();
    const { resolvedLanguage } = i18n;

    const viewModes = ['Day', 'Week', 'Month'];

    const [query, updateQuery] = useQuery();
    const viewType = query.get('view') ?? 'Day';
    const handleChangeViewType = (i) => updateQuery([{ key: 'view', value: viewModes[i] }]);
    const [tasks] = getTasksFunction(milestones);

    useEffect(() => {
        (async () => {
            try {
                // ? We avoid creating an empty gantt if there are no milestones
                if (!milestones.length) return;

                // ? We fetch the eye icon file from the assets in order to display it into the gantt\
                const currentDayHighlightEl = await getRawImageFromPath(currentDayHighlightPath);

                new Gantt('#gantt-container', tasks, {
                    language: resolvedLanguage,
                    start_date: customGanttDates?.startDate ?? null,
                    end_date: customGanttDates?.endDate ?? null,
                    view_mode: viewType,
                    current_day_highlight_element: currentDayHighlightEl,
                });
            } catch (error) {
                console.error(error);
            }
        })();
    }, [milestones, viewType, resolvedLanguage]);

    return milestones.length > 0 ? (
        <div className="relative">
            <div className="mb-4">
                <Tabs
                    tabs={[t('Day'), t('Week'), t('Month')]}
                    activeTab={viewModes.indexOf(viewType)}
                    setActiveTab={handleChangeViewType}
                />
            </div>

            <div className="overflow-y-hidden overflow-x-scroll">
                <div className="gantt-wrapper relative table overflow-hidden rounded-md border-none">
                    <div className="table-cell align-top">
                        <GanttTable tasks={tasks} type={type} />
                    </div>
                    <div id="gantt-container" className="table-cell align-top"></div>
                </div>
            </div>
            <div className="mt-10">
                <GanttLegend type={type} />
            </div>
        </div>
    ) : (
        <NoDataPlaceholder />
    );
});

FrappeGanttLayout.propTypes = {
    milestones: PropTypes.array.isRequired,
    customGanttDates: PropTypes.object,
    getTasksFunction: PropTypes.func,
    type: PropTypes.string,
};

FrappeGanttLayout.defaultProps = {
    milestones: [],
    customGanttDates: {},
    getTasksFunction: () => {},
    type: 'milestones',
};

export default FrappeGanttLayout;
