import React from 'react';

import { Trash2 } from 'lucide-react';

import { TextField } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import PropTypes from 'prop-types';
import { Dropdown, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import { FILTERS, handleUpdateFormulaElement } from 'utils/kpiUtils';

const KpiFormulaItemFilters = ({
    option,
    elementId,
    formula,
    filterOptions,
    hiddenOptions,
    editable,
    onChangeKpi,
    disabled,
}) => {
    const { t } = useTranslation();

    const { key, criterion, value } = option;
    const { input, options } = filterOptions.find((item) => item.key === key);
    const { criteria } = FILTERS[input];

    const handleReplaceFilter = (i) => {
        const { key: optionKey, input: optionInput } = filterOptions[i];
        const { value: optionValue, criteria: optionCriteria } =
            FILTERS[optionInput];

        onChangeKpi(
            'formula',
            handleUpdateFormulaElement(formula, elementId, {
                filters: formula.elements[elementId].filters.map((filter) =>
                    filter.key === key
                        ? {
                              key: optionKey,
                              value: optionValue,
                              criterion: optionCriteria.length
                                  ? optionCriteria[0].criterion
                                  : null,
                          }
                        : filter,
                ),
            }),
        );
    };

    const handleMutateFilter = (mutateKey, mutateValue) => {
        onChangeKpi(
            'formula',
            handleUpdateFormulaElement(formula, elementId, {
                filters: formula.elements[elementId].filters.map((filter) =>
                    filter.key === key
                        ? {
                              ...filter,
                              [mutateKey]: mutateValue,
                          }
                        : filter,
                ),
            }),
        );
    };

    const handleDeleteFilter = () => {
        onChangeKpi(
            'formula',
            handleUpdateFormulaElement(formula, elementId, {
                filters: formula.elements[elementId].filters.filter(
                    (filter) => filter.key !== key,
                ),
            }),
        );
    };

    if (!editable) {
        return (
            <div className="flex flex-col rounded-lg border border-layout-transparent bg-layout-transparent">
                <div className="px-4 py-2.5">
                    <p className="text-lg font-medium leading-5">
                        {t(capitalizeFirstLetter(key))}
                    </p>
                </div>
                {criteria.length > 0 && (
                    <>
                        <hr className="h-px border-layout-transparent-dark" />
                        <div className="px-4 py-2.5">
                            <p className="text-lg font-medium leading-5">
                                {t(
                                    criteria.find(
                                        (item) => item.criterion === criterion,
                                    ).label,
                                )}
                            </p>
                        </div>
                    </>
                )}
                {criterion && (
                    <>
                        <hr className="h-px border-layout-transparent-dark" />
                        <div className="px-4 py-2.5">
                            <p className="text-lg font-medium leading-5">
                                {Array.isArray(value)
                                    ? value
                                          .map((item) =>
                                              t(capitalizeFirstLetter(item)),
                                          )
                                          .join(', ')
                                    : value}
                            </p>
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-2 rounded-2xl border border-layout-transparent bg-layout-transparent p-3">
            <Dropdown
                disabled={disabled}
                variant="black"
                options={filterOptions.map((option) =>
                    t(capitalizeFirstLetter(option.key)),
                )}
                hiddenOptions={hiddenOptions}
                selectedOption={(() => {
                    const index = filterOptions.findIndex(
                        (item) => item.key === key,
                    );
                    return index >= 0 ? index : null;
                })()}
                setSelectedOption={handleReplaceFilter}
            />
            {criteria.length > 0 && (
                <Dropdown
                    disabled={disabled}
                    variant="black"
                    options={criteria.map((item) => t(item.label))}
                    selectedOption={(() => {
                        const index = criteria.findIndex(
                            (item) => item.criterion === criterion,
                        );
                        return index >= 0 ? index : null;
                    })()}
                    setSelectedOption={(i) =>
                        handleMutateFilter('criterion', criteria[i].criterion)
                    }
                />
            )}
            {criterion && (
                <>
                    {input === 'text' && (
                        <TextField
                            disabled={disabled}
                            type="text"
                            placeholder="Text"
                            value={value}
                            onChange={(e) =>
                                handleMutateFilter('value', e.target.value)
                            }
                        />
                    )}
                    {input === 'number' && (
                        <TextField
                            disabled={disabled}
                            type="number"
                            placeholder={t('Number')}
                            value={Number(value).toString()}
                            onChange={(e) =>
                                handleMutateFilter(
                                    'value',
                                    Number(e.target.value),
                                )
                            }
                        />
                    )}
                    {input === 'multiselect' && (
                        <MultiDropdown
                            disabled={disabled}
                            variant="black"
                            options={options.map((option) =>
                                t(capitalizeFirstLetter(option.label)),
                            )}
                            selectedOptions={value.reduce((acc, cur) => {
                                const index = options.findIndex(
                                    (item) => item.key === cur,
                                );
                                if (index >= 0) return [...acc, index];
                                else return index;
                            }, [])}
                            setSelectedOptions={(i) => {
                                const { key: optionKey } = options[i];

                                if (value.includes(optionKey)) {
                                    handleMutateFilter(
                                        'value',
                                        value.filter((v) => v !== optionKey),
                                    );
                                } else {
                                    handleMutateFilter('value', [
                                        ...value,
                                        optionKey,
                                    ]);
                                }
                            }}
                        />
                    )}
                </>
            )}

            <div className="ml-auto">
                <BasicTooltip tip={t('Delete filter')} disabled={disabled}>
                    <div
                        className={`group flex h-8 w-8 cursor-pointer items-center justify-center ${disabled ? 'pointer-events-none' : ''}`}
                        onClick={handleDeleteFilter}
                    >
                        <Trash2 className="h-5 w-5 opacity-60 group-hover:opacity-100" />
                    </div>
                </BasicTooltip>
            </div>
        </div>
    );
};

KpiFormulaItemFilters.propTypes = {
    option: PropTypes.object,
    elementId: PropTypes.string,
    formula: PropTypes.object,
    hiddenOptions: PropTypes.array,
    filterOptions: PropTypes.array,
    editable: PropTypes.bool,
    onChangeKpi: PropTypes.func,
    disabled: PropTypes.bool,
};

KpiFormulaItemFilters.defaultProps = {
    option: null,
    elementId: null,
    formula: null,
    hiddenOptions: [],
    filterOptions: [],
    editable: false,
    onChangeKpi: () => {},
    disabled: false,
};

export default KpiFormulaItemFilters;
