import React, { useContext, useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { projectManagement } from 'routes';
import API from 'utils/axios';

const AffiliateRow = ({ affiliateInfo, deleteAffiliateFromPm, canAll }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const confirm = useConfirm();

    return (
        <TableRow colSpan={4} padding={false}>
            <TableCell>
                <div className="flex items-center">
                    <div
                        className="mr-4 h-12 w-12 flex-shrink-0 rounded-full bg-cover bg-center"
                        style={{
                            backgroundImage: `url(${affiliateInfo?.profileImageUrl})`,
                        }}
                    />
                    {affiliateInfo.user.profile.name}
                </div>
            </TableCell>
            <TableCell>{affiliateInfo.user.profile.role}</TableCell>
            <TableCell>{affiliateInfo.user.profile.phoneNo}</TableCell>
            <TableCell>{affiliateInfo.user.email}</TableCell>
            <TableCell>
                {affiliateInfo.user.isActive === true
                    ? t('Active')
                    : t('Inactive')}
            </TableCell>
            <TableCell>{affiliateInfo.user.observation}</TableCell>

            <TableCell>
                {canAll && (
                    <div className="flex justify-end gap-2 p-1">
                        <div>
                            <Button
                                startIcon={<EditIcon />}
                                color="primary"
                                size="small"
                                onClick={() =>
                                    history.push(
                                        projectManagement.base +
                                            projectManagement
                                                .projectManagementConfigurator
                                                .base +
                                            '/edit-affiliate/' +
                                            affiliateInfo.id,
                                    )
                                }
                            >
                                {t('Edit affiliate account')}
                            </Button>
                        </div>
                        <div>
                            <Button
                                startIcon={<DeleteIcon />}
                                size="small"
                                onClick={() =>
                                    confirm(
                                        t(
                                            'Are you sure you want to delete this affiliate?',
                                        ),
                                        () =>
                                            deleteAffiliateFromPm(
                                                affiliateInfo.id,
                                            ),
                                    )
                                }
                            >
                                {t('Delete')}
                            </Button>
                        </div>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};

AffiliateRow.propTypes = {
    affiliateInfo: PropTypes.object,
    deleteAffiliateFromPm: PropTypes.func,
    canAll: PropTypes.bool,
};

AffiliateRow.defaultProps = {
    affiliateInfo: {},
    deleteAffiliateFromPm: () => null,
    canAll: false,
};

const AffiliateAccounts = () => {
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '61',
            permissionType: 'ALL',
        },
    ]);

    const { enqueueSnackbar } = useSnackbar();

    const [affiliates, setAffiliates] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAffiliates = async (currentPage, perPage) => {
        try {
            const response = await API.get('/PmAffiliates', {
                params: {
                    perPage: perPage,
                    currentPage: currentPage,
                    pagesToLoad: 1,
                },
            });

            setAffiliates(response.data.PmAffiliates.content);
            return response.data.PmAffiliates.count;
        } catch (err) {
            throw new Error(err);
        }
    };

    const deleteAffiliateFromPm = async (affiliateId) => {
        try {
            await API.delete(`/PmAffiliate?affiliateId=${affiliateId}`);
            enqueueSnackbar(t('Affiliate deleted from PM successfully!'), {
                variant: 'success',
            });
            getAffiliates(0, 10);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t('The affiliate can not be deleted! Try again!'), {
                variant: 'success',
            });
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('PM Configurator')} | {t('Affiliated accounts')}
                </title>
            </Helmet>

            <Pagination
                loading={loading}
                setLoading={setLoading}
                getFunction={getAffiliates}
                perPageItems={11}
            >
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : (
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('User')}</TableCell>
                                    <TableCell>{t('Role')}</TableCell>
                                    <TableCell>{t('Phone')}</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>{t('Observations')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableSeparator />
                                {affiliates.map((affiliate) => (
                                    <AffiliateRow
                                        key={affiliate.id}
                                        affiliateInfo={affiliate}
                                        deleteAffiliateFromPm={
                                            deleteAffiliateFromPm
                                        }
                                        canAll={canAll}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Pagination>
        </React.Fragment>
    );
};

export default AffiliateAccounts;
