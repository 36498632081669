import React, { useMemo, useState } from 'react';

import { ReactComponent as FilterIcon } from 'assets/pipelines/svgs/filter-icon.svg';

import { Button, makeStyles, Popover, Switch } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => {
    return {
        inactive: {
            borderColor: `var(--buttons-text)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                color: `rgb(var(--base-buttons-text) / 75%)`,
                borderColor: `rgb(var(--base-buttons-text) / 75%)`,
            },
        },
        active: {
            borderColor: `var(--secondary-light)`,
            color: `var(--secondary-light)`,
            '&:hover': {
                color: `rgb(var(--base-secondary-light) / 75%)`,
                borderColor: `rgb(var(--base-secondary-light) / 75%)`,
            },
        },
    };
});

const Sort = ({ sort, setSort, onSort, disabled }) => {
    const { t } = useTranslation();
    const { inactive, active } = useStyles();

    const [lastAppliedSort, setLastAppliedSort] = useState(sort);

    const isActive = lastAppliedSort.some((element) => Boolean(element.value));
    const canApply = useMemo(
        () =>
            !sort.map((e) => e.value).every((element, index) => element === lastAppliedSort.map((e) => e.value)[index]),
        [sort, lastAppliedSort],
    );

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'sort-id' : undefined;

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleChangeValue = (key, value) => {
        setSort((prev) => prev.map((element) => (element.key === key ? { ...element, value } : element)));
    };

    return (
        <>
            <Button
                disabled={disabled}
                aria-describedby={id}
                variant="outlined"
                startIcon={<FilterIcon />}
                className={isActive ? active : inactive}
                style={{ borderRadius: '9999px' }}
                onClick={handleOpen}
            >
                {t('Sort')}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                marginThreshold={12}
            >
                <div className="flex flex-col rounded-xl bg-layout-lighter px-4 py-3 shadow">
                    {sort.map((element, index) => (
                        <div
                            key={element.key}
                            className={`flex items-center justify-between gap-4 ${index !== 0 ? 'mt-2' : ''}`}
                        >
                            <p className="text-lg font-semibold">{element.label}</p>

                            {element.type === 'switch' && (
                                <Switch
                                    disabled={disabled}
                                    checked={element.value}
                                    onChange={(e) => handleChangeValue(element.key, e.target.checked)}
                                />
                            )}

                            {element.type === 'toggle' && (
                                <ToggleButtonGroup
                                    value={element.value}
                                    onChange={(_, value) => handleChangeValue(element.key, value)}
                                    exclusive
                                    size="small"
                                >
                                    {element.options.map((option) => (
                                        <ToggleButton
                                            key={option.value}
                                            value={option.value}
                                            disabled={element.value === option.value || disabled}
                                        >
                                            {option.icon}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            )}
                        </div>
                    ))}

                    <div
                        className={`grid transform transition-all duration-300 ${
                            canApply ? 'mb-6 translate-y-6 opacity-100' : 'translate-y-0 opacity-0'
                        }`}
                        style={{
                            gridTemplateRows: canApply ? '1fr' : '0fr',
                        }}
                    >
                        <div className="overflow-hidden">
                            <Button
                                startIcon={<FilterIcon />}
                                className="w-full"
                                color="secondary"
                                onClick={() => {
                                    onSort(sort, lastAppliedSort);
                                    setLastAppliedSort(sort);
                                    handleClose();
                                }}
                            >
                                {t('Apply sort')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};

Sort.propTypes = {
    sort: PropTypes.array,
    setSort: PropTypes.func,
    onSort: PropTypes.func,
    disabled: PropTypes.bool,
};

Sort.defaultProps = {
    sort: [],
    setSort: () => {},
    onSort: () => {},
    disabled: false,
};

export default Sort;
