import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import { getAverage, getAverageStatus, getPercent } from 'utils/kpiUtils';

import KpiProgressBar from './kpi-progress-bar';

const KpiCardHeader = ({ kpi, period }) => {
    const { t } = useTranslation();
    const { data, previousAverage, indexes } = kpi;
    const { selectedDivision } = period;

    const average = getAverage(data[selectedDivision]);
    const { percent, color, icon: Icon } = getPercent(average, previousAverage[selectedDivision]);

    const currentIndexes = indexes.at(-1).divisions.find((division) => division.type === selectedDivision);
    const { status, color: statusColor } = getAverageStatus(average, currentIndexes);

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-4">
                <p className="text-6xl font-light">{average.toFixed(2)}</p>
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1">
                        {Icon && <Icon className="h-5 w-5" style={{ color }} />}
                        <p className="text-xl font-medium leading-5" style={{ color }}>
                            {percent.toFixed(2)}%
                        </p>
                    </div>
                    <div className="self-start rounded-2.5xl px-3 py-1" style={{ backgroundColor: statusColor }}>
                        <p className="text-lg font-medium leading-4">{t(capitalizeFirstLetter(status))}</p>
                    </div>
                </div>
            </div>
            <KpiProgressBar average={average} indexes={currentIndexes} />
        </div>
    );
};

KpiCardHeader.propTypes = {
    kpi: PropTypes.object,
    period: PropTypes.object,
};

KpiCardHeader.defaultProps = {
    kpi: null,
    period: null,
};

export default KpiCardHeader;
