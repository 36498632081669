import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import PropTypes from 'prop-types';

export const ExpandableRow = (props) => {
    const { row, customLastCell, collapse, padding } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow className={`relative ${open ? 'open-row' : ''}`} onClick={() => setOpen(!open)}>
                {row}

                {customLastCell ?? (
                    <TableCell align="right">
                        <div
                            className={`inline-block transform transition-transform duration-200 ${open ? 'rotate-180' : 'rotate-0'}`}
                        >
                            <KeyboardArrowDownIcon />
                        </div>
                    </TableCell>
                )}

                <div
                    className={`pointer-events-none absolute left-0 top-0 h-full w-full rounded-t-2xl border transition-colors duration-200 ${open ? 'border-layout-transparent bg-layout-transparent-dark' : 'border-transparent bg-transparent'}`}
                />
            </TableRow>

            <TableRow className="relative">
                <TableCell
                    colSpan="100%"
                    style={{
                        padding: 0,
                        borderStyle: 'none',
                        backgroundColor: 'transparent',
                        cursor: 'default',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                    }}
                >
                    <Collapse in={open} timeout={200}>
                        <div
                            className={`rounded-b-2xl border-b border-l border-r transition-colors duration-200 ${open ? 'border-layout-transparent bg-layout-transparent' : 'border-transparent bg-transparent'} ${padding ? 'p-4' : ''}`}
                        >
                            {collapse}
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

ExpandableRow.propTypes = {
    row: PropTypes.element,
    customLastCell: PropTypes.element,
    collapse: PropTypes.element,
    padding: PropTypes.bool,
};

ExpandableRow.defaultProps = {
    row: null,
    customLastCell: null,
    collapse: null,
    padding: true,
};
