import React, { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { TextField } from '@material-ui/core';

import ErrorButton from 'components/shared/error-button';
import SuccessButton from 'components/shared/success-button';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import * as yup from 'yup';

import { RiemContext } from '../riem';

const RiemHeaderForm = ({ riem }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { setIsOperatingOnRowAt, onEditInfo } = useContext(RiemContext);
    const [isMounted, setIsMounted] = useState(false);
    const [formState, setFormState] = useState(riem.header);
    const formRef = useRef(null);
    const handleChangeField = (e) => setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    const schema = yup.object().shape({
        number: yup.string().typeError(t('The no. label is mandatory')).required(t('The no. label is mandatory')),
        stage: yup.string().typeError(t('The stage label is mandatory')).required(t('The stage label is mandatory')),
        identifiedDescription: yup
            .string()
            .typeError(t('The description of the identified risks label is mandatory'))
            .required(t('The description of the identified risks label is mandatory')),
        identifiedScore: yup
            .string()
            .typeError(t('The identified risk score label is mandatory'))
            .required(t('The identified risk score label is mandatory')),
        identifiedProbability: yup
            .string()
            .typeError(t('The risk probability label is mandatory'))
            .required(t('The risk probability label is mandatory')),
        identifiedTotal: yup
            .string()
            .typeError(t('The risk score before control measures label is mandatory'))
            .required(t('The risk score before control measures label is mandatory')),
        measures: yup
            .string()
            .typeError(t('The mitigation / control measures label is mandatory'))
            .required(t('The mitigation / control measures label is mandatory')),
        residualDescription: yup
            .string()
            .typeError(t('The description of residual risks label is mandatory'))
            .required(t('The description of residual risks label is mandatory')),
        residualScore: yup
            .string()
            .typeError(t('The residual risk score label is mandatory'))
            .required(t('The residual risk score label is mandatory')),
        residualProbability: yup
            .string()
            .typeError(t('The residual risk probability label is mandatory'))
            .required(t('The residual risk probability label is mandatory')),
        residualTotal: yup
            .string()
            .typeError(t('The total score residual risk label is mandatory'))
            .required(t('The total score residual risk label is mandatory')),
        identificationDate: yup
            .string()
            .typeError(t('The identification date label is mandatory'))
            .required(t('The identification date label is mandatory')),
        lastUpdate: yup
            .string()
            .typeError(t('The last update label is mandatory'))
            .required(t('The last update label is mandatory')),
    });
    const handleSuccessAction = async () => {
        try {
            await schema.validate(formState);
            onEditInfo(formState, riem.id, 'header');
            setIsOperatingOnRowAt(null);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };
    useEffect(() => {
        const form = formRef.current;
        if (!form) return;
        const firstInput = form.querySelector('input');
        firstInput.focus({ preventScroll: true });
        firstInput.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        setIsMounted(true);
    }, []);
    return (
        <form
            ref={formRef}
            role="row"
            className="col-span-full grid bg-layout-transparent-dark"
            style={{
                gridTemplateColumns: 'subgrid',
                height: '90px',
            }}
        >
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('No. label')}>
                    <TextField
                        name="number"
                        placeholder={t('No. label')}
                        value={formState.number}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Stage label')}>
                    <TextField
                        name="stage"
                        placeholder={t('Stage label')}
                        value={formState.stage}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Description of the identified risks label')}>
                    <TextField
                        name="identifiedDescription"
                        placeholder={t('Description of the identified risks label')}
                        value={formState.identifiedDescription}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Identified risk score label')}>
                    <TextField
                        name="identifiedScore"
                        placeholder={t('Identified risk score label')}
                        value={formState.identifiedScore}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Risk probability label')}>
                    <TextField
                        name="identifiedProbability"
                        placeholder={t('Risk probability label')}
                        value={formState.identifiedProbability}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Risk score before control measures label')}>
                    <TextField
                        name="identifiedTotal"
                        placeholder={t('Risk score before control measures label')}
                        value={formState.identifiedTotal}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Mitigation / control measures label')}>
                    <TextField
                        name="measures"
                        placeholder={t('Mitigation / control measures label')}
                        value={formState.measures}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Description of residual risks label')}>
                    <TextField
                        name="residualDescription"
                        placeholder={t('Description of residual risks label')}
                        value={formState.residualDescription}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Residual risk score label')}>
                    <TextField
                        name="residualScore"
                        placeholder={t('Residual risk score label')}
                        value={formState.residualScore}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Residual risk probability label')}>
                    <TextField
                        name="residualProbability"
                        placeholder={t('Residual risk probability label')}
                        value={formState.residualProbability}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Total score residual risk label')}>
                    <TextField
                        name="residualTotal"
                        placeholder={t('Total score residual risk label')}
                        value={formState.residualTotal}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Identification date label')}>
                    <TextField
                        name="identificationDate"
                        placeholder={t('Identification date label')}
                        value={formState.identificationDate}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="columnheader" className="flex items-center justify-center border border-black px-2 py-1">
                <LabelWrapper label={t('Last update label')}>
                    <TextField
                        name="lastUpdate"
                        placeholder={t('Last update label')}
                        value={formState.lastUpdate}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            {isMounted &&
                createPortal(
                    <div className="flex flex-col items-center justify-center gap-2">
                        <SuccessButton startIcon={<CheckCircleIcon />} size="small" onClick={handleSuccessAction}>
                            {t('Complete')}
                        </SuccessButton>
                        <ErrorButton
                            startIcon={<CancelIcon />}
                            size="small"
                            onClick={() => setIsOperatingOnRowAt(null)}
                        >
                            {t('Cancel')}
                        </ErrorButton>
                    </div>,
                    document.getElementById('riem-header-container'),
                )}
        </form>
    );
};
RiemHeaderForm.propTypes = {
    riem: PropTypes.object,
};
RiemHeaderForm.defaultProps = {
    riem: {},
};
export default RiemHeaderForm;
