import React from 'react';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import { Button } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import FileUploadContainer from 'components/shared/file-upload-container';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const EmailNotificationDocuments = ({ files, onAddFiles, onDeleteFile }) => {
    const { t } = useTranslation();

    return (
        <div className="my-6 w-full px-2">
            <div className="flex w-full flex-col gap-6 rounded-md bg-layout-transparent p-4">
                <div className="flex items-center gap-2 ">
                    <div className="text-primary-light">
                        <AttachFileIcon
                            style={{ width: '2rem', height: '2rem' }}
                        />
                    </div>
                    <h2>{t('Email Attachments')}</h2>
                </div>
                {files.length === 0 && (
                    <h3 className="text-center">
                        {t('There are no files uploaded!')}
                    </h3>
                )}
                {files.length > 0 && (
                    <div className="flex flex-col gap-2">
                        {files.map((file) => (
                            <div
                                key={file.id}
                                className="flex w-full items-center justify-between gap-2 rounded-md bg-layout-transparent-dark p-2"
                            >
                                <div className="flex items-center gap-2">
                                    <div className="text-primary-light">
                                        <InsertDriveFileIcon />
                                    </div>
                                    <p className="break-words">{file.name}</p>
                                </div>
                                <BasicTooltip
                                    position="left"
                                    tip={t('Delete file')}
                                >
                                    <ActionButton
                                        icon={<DeleteIcon />}
                                        color={`var(--error)`}
                                        size={9}
                                        onClick={() => onDeleteFile(file.id)}
                                    />
                                </BasicTooltip>
                            </div>
                        ))}
                    </div>
                )}
                <div className="flex justify-end">
                    <FileUploadContainer onUpload={onAddFiles}>
                        <Button
                            startIcon={<NoteAddIcon />}
                            color="primary"
                            className="self-center"
                        >
                            {t('Add document/s')}
                            <input
                                name="image"
                                hidden
                                accept="image/*, application/pdf"
                                type="file"
                            />
                        </Button>
                    </FileUploadContainer>
                </div>
            </div>
        </div>
    );
};

EmailNotificationDocuments.propTypes = {
    files: PropTypes.array,
    onAddFiles: PropTypes.func,
    onDeleteFile: PropTypes.func,
};

EmailNotificationDocuments.defaultProps = {
    files: [],
    onAddFiles: () => {},
    onDeleteFile: () => {},
};
export default EmailNotificationDocuments;
