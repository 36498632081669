import React, { useState } from 'react';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import PropTypes from 'prop-types';

import { Search } from '../Search/Search';

export const TabbedDropdown = (props) => {
    const { tabs, placeholder, variant, icon } = props;
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [activeTab, setActiveTab] = useState(0);

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className="dropdown-z-index relative inline-block w-full flex-shrink-0 cursor-pointer">
                {/* eslint-disable-next-line */}
                <div
                    className={`dropdown-selected ${variant} ${open ? 'clicked' : ''} ${
                        tabs[activeTab].selectedOptions.length > 0 && 'hasValue'
                    } ${icon !== null && 'is-icon'}`}
                    onClick={() => setOpen(!open)}
                >
                    {icon !== null ? (
                        <>{icon}</>
                    ) : (
                        <>
                            <p className="pointer-events-none font-medium">
                                {tabs[activeTab].selectedOptions.length > 0
                                    ? `${tabs[activeTab].selectedOptions.length} selected`
                                    : placeholder}
                            </p>
                            <KeyboardArrowDownIcon
                                className={`flex-shrink-0 transform text-buttons-text ${
                                    open ? 'rotate-180' : 'rotate-0'
                                }`}
                                style={{ transition: 'transform .2s ease' }}
                            />
                        </>
                    )}
                </div>

                {/* Options */}
                {open && (
                    <div className={`dropdown-options ${variant}`}>
                        <div className="bg-primary flex w-full items-center justify-start space-x-1 bg-layout-transparent p-1">
                            {tabs.map((tab, i) => (
                                // eslint-disable-next-line
                                <div
                                    key={tab.name}
                                    className={`rounded-md bg-layout-transparent px-4 py-2 text-lg font-semibold text-buttons-text ${
                                        i === activeTab
                                            ? 'bg-layout-transparent-dark'
                                            : ''
                                    }`}
                                    onClick={() => setActiveTab(i)}
                                >
                                    {tab.name}
                                </div>
                            ))}
                        </div>

                        <div className="search-li sticky top-0 w-full bg-primary-light">
                            <Search
                                light
                                value={searchValue}
                                setValue={setSearchValue}
                            />
                        </div>

                        <ul>
                            {tabs[activeTab].options.map((option, index) => {
                                if (
                                    String(option)
                                        .toLowerCase()
                                        .search(
                                            searchValue.toLocaleLowerCase(),
                                        ) >= 0
                                )
                                    return (
                                        // eslint-disable-next-line
                                        <li
                                            key={Math.random()}
                                            onClick={() =>
                                                tabs[
                                                    activeTab
                                                ].setSelectedOptions(index)
                                            }
                                        >
                                            {tabs[
                                                activeTab
                                            ].selectedOptions.indexOf(index) >
                                            -1 ? (
                                                <CheckBoxIcon
                                                    className="text-buttons-text"
                                                    style={{
                                                        fontSize: '1.20rem',
                                                    }}
                                                />
                                            ) : (
                                                <CheckBoxOutlineBlankIcon
                                                    className="text-buttons-text"
                                                    style={{
                                                        fontSize: '1.20rem',
                                                    }}
                                                />
                                            )}
                                            <p className="ml-2 text-sm font-medium text-buttons-text">
                                                {option}
                                            </p>
                                        </li>
                                    );
                                return null;
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

TabbedDropdown.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    icon: PropTypes.element,
    variant: PropTypes.string,
};

TabbedDropdown.defaultProps = {
    tabs: [],
    placeholder: 'Alege opțiunile',
    icon: null,
    variant: 'default',
};
