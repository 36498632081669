import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Pen, Plus, Trash2 } from 'lucide-react';

import { Button, TextField } from '@material-ui/core';

import ErrorButton from 'components/shared/error-button';
import PermissionContext from 'contexts/PermissionContext';
import UserContext from 'contexts/UserContext';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Permission } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const UserRole = ({ initialDisabled, initialRole }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const confirm = useConfirm();
    const { user } = useContext(UserContext);

    const { id } = initialRole || { id: null };

    const schema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .typeError(t('Role name is required!'))
            .required(t('Role name is required!'))
            .min(2, t('Role name must be at least 2 characters long!')),
        rolePermissions: yup
            .array()
            .min(1, t('Role must have at least one permission!')),
    });

    const permissions = useContext(PermissionContext);

    const [disabled, setDisabled] = useState(initialDisabled);
    const [role, setRole] = useState(() => {
        const { name, rolePermissions } = initialRole || {
            name: '',
            rolePermissions: [],
        };

        return { name, rolePermissions };
    });

    const handleChangeRole = (key, value) =>
        setRole((prev) => ({ ...prev, [key]: value }));

    const handleCreateOrUpdate = async () => {
        try {
            setDisabled(true);

            await schema.validate(role);

            if (id) {
                await API.put(`userRoles/${id}`, { id, data: role });
                enqueueSnackbar(t('Role created successfully!'), {
                    variant: 'success',
                });
            } else {
                await API.post('userRoles', role);
                enqueueSnackbar(t('Role updated successfully!'), {
                    variant: 'success',
                });
            }

            history.push(configurator.base + '?tab=Manage users');
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
            console.error(error);
        } finally {
            setDisabled(false);
        }
    };

    const handleDelete = async () => {
        try {
            setDisabled(true);

            await API.delete('userRoles', {
                params: {
                    id,
                },
            });

            enqueueSnackbar(t('Role deleted successfully!'), {
                variant: 'success',
            });

            history.push(configurator.base + '?tab=Manage users');
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
            console.error(error);
        } finally {
            setDisabled(false);
        }
    };

    return (
        <div className="flex w-full flex-col gap-10">
            <div className="flex max-w-md flex-col gap-10">
                <h4 className="text-2xl text-dark-text">{t('Role info')}</h4>

                <div className="relative">
                    <TextField
                        name="name"
                        label={t('Role name')}
                        placeholder={t('Role name')}
                        value={role.name}
                        onChange={(e) =>
                            handleChangeRole(e.target.name, e.target.value)
                        }
                        disabled={disabled}
                    />
                </div>
            </div>

            <div className="grid w-full grid-cols-3 gap-6 xl:grid-cols-2 sm:grid-cols-1">
                {permissions
                    .filter((pg) => pg.Permissions.length > 0)
                    .map((pg) => (
                        <div key={pg.id}>
                            <Permission
                                enabled={!disabled}
                                permissionGroup={pg}
                                permissions={role.rolePermissions}
                                setPermissions={(value) =>
                                    handleChangeRole('rolePermissions', value)
                                }
                            />
                        </div>
                    ))}
            </div>

            {!initialDisabled && user.Role.id !== role.id && (
                <div className="flex items-center gap-4">
                    <Button
                        startIcon={id ? <Pen /> : <Plus />}
                        color="primary"
                        disabled={disabled}
                        onClick={handleCreateOrUpdate}
                    >
                        {t(id ? 'Edit role' : 'Add new role')}
                    </Button>
                    {id && (
                        <ErrorButton
                            startIcon={<Trash2 />}
                            disabled={disabled}
                            onClick={() =>
                                confirm(
                                    t(
                                        'Are you sure you want to delete this role?',
                                    ),
                                    () => handleDelete(),
                                )
                            }
                        >
                            {t('Delete')}
                        </ErrorButton>
                    )}
                </div>
            )}
        </div>
    );
};

UserRole.propTypes = {
    initialRole: PropTypes.object,
    initialDisabled: PropTypes.bool,
};

UserRole.defaultProps = {
    initialRole: null,
    initialDisabled: false,
};

export default UserRole;
