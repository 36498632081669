import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import {
    ExpandableRow,
    Header,
    TableSeparator,
} from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm, projectInManagement, projectManagement } from 'routes';
import { formatDate, formatTime } from 'utils';
import API from 'utils/axios';

const PMProjectRow = ({ project }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { language } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);

    const canViewProject = checkPerm([
        {
            permissionId: '59',
            permissionType: 'VIEW',
        },
    ]);
    const canAllProject = checkPerm([
        {
            permissionId: '59',
            permissionType: 'ALL',
        },
    ]);
    const canViewContactActivity = checkPerm([
        {
            permissionId: '5',
            permissionType: 'VIEW',
        },
    ]);
    const canViewContracts = checkPerm([
        {
            permissionId: '8',
            permissionType: 'VIEW',
        },
    ]);

    const { name, createAt, updateAt, statusHistory } = project;
    const statusStr = statusHistory.at(-1).status.split('_').join(' ');
    const status = t(statusStr.slice(0, 1) + statusStr.slice(1).toLowerCase());

    const teamString = useMemo(() => {
        let string = project.teams
            .slice(0, 3)
            .map((t) => t.name)
            .join(', ');
        if (project.teams.length > 3)
            string += ` + ${t('others')} ${project.teams.length - 3}`;
        return string;
    }, [project, language]);

    const getClientName = () => {
        if (project.contactId) {
            const name =
                typeof project.contact.data === 'string'
                    ? JSON.parse(project.contact.data)
                    : project.contact.data;

            return name['standard'].name;
        }
    };

    const getContractName = () => {
        if (project.contractId) {
            const name =
                typeof project.Contract.data === 'string'
                    ? JSON.parse(project.Contract.data)
                    : project.Contract.data;

            return name['standard'].number;
        } else {
            return t('Without Contract');
        }
    };

    return (
        <ExpandableRow
            colSpan={6}
            padding={false}
            row={
                <>
                    <TableCell>
                        <div
                            className={`inline font-bold ${canViewProject && 'hover:text-primary-light'}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!canViewProject) return;

                                history.push(
                                    projectInManagement.base + '/' + project.id,
                                );
                            }}
                        >
                            {name}
                        </div>
                    </TableCell>
                    <TableCell>
                        <div
                            className={`inline font-bold ${canViewContactActivity && 'hover:text-primary-light'}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!canViewContactActivity) return;

                                if (project.contactId)
                                    history.push(
                                        crm.base +
                                            crm.contactActivity.base +
                                            '/' +
                                            project.contactId,
                                    );
                            }}
                        >
                            {getClientName()}
                        </div>
                    </TableCell>

                    <TableCell>
                        <div
                            className={`inline font-bold ${canViewContracts && 'hover:text-primary-light'}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!canViewContracts) return;

                                if (project.contractId)
                                    history.push(
                                        crm.base +
                                            crm.contracts.base +
                                            crm.contracts.update +
                                            '/' +
                                            project.contractId,
                                    );
                            }}
                        >
                            {getContractName()}
                        </div>
                    </TableCell>

                    <TableCell>{teamString}</TableCell>
                    <TableCell>{status}</TableCell>
                    <TableCell>{formatDate(createAt)}</TableCell>
                    <TableCell>
                        {formatDate(updateAt)}, {formatTime(updateAt)}
                    </TableCell>
                </>
            }
            collapse={
                <div className="relative overflow-visible">
                    <div className="mb-2 flex justify-end gap-3 p-5">
                        {canAllProject && (
                            <Button
                                startIcon={<EditIcon />}
                                color="secondary"
                                onClick={() =>
                                    history.push(
                                        projectManagement.base +
                                            projectManagement.projectEntity
                                                .base +
                                            projectManagement.projectEntity
                                                .update +
                                            '/' +
                                            project.id,
                                    )
                                }
                            >
                                {t('Edit project details')}
                            </Button>
                        )}

                        <Button
                            startIcon={<VisibilityIcon />}
                            color="primary"
                            onClick={() =>
                                history.push(
                                    projectInManagement.base +
                                        '/' +
                                        project.id +
                                        projectInManagement
                                            .planingOverviewOnProject.base,
                                )
                            }
                        >
                            {t('View project management')}
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

PMProjectRow.propTypes = {
    project: PropTypes.object,
};

PMProjectRow.defaultProps = {
    project: null,
};

const AllProjectsManagement = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);
    const canView = checkPerm([
        {
            permissionId: '59',
            permissionType: 'VIEW',
        },
    ]);
    const canAll = checkPerm([
        {
            permissionId: '59',
            permissionType: 'ALL',
        },
    ]);

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }
    }, [canView]);

    const getPMProjects = async (currentPage, perPage) => {
        try {
            const response = await API.get('/PmProjects', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setProjects(response.data.PmProjects.content);
            return response.data.PmProjects.count;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>PM - {t('Projects')}</title>
            </Helmet>

            <Header
                pageTitle={t('Projects')}
                action={
                    canAll && (
                        <>
                            <Button
                                startIcon={<AddIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={() =>
                                    history.push(
                                        projectManagement.base +
                                            projectManagement.projectEntity
                                                .base +
                                            projectManagement.projectEntity
                                                .create,
                                    )
                                }
                            >
                                {t('Add new project')}
                            </Button>
                        </>
                    )
                }
            />

            <div className="page-container">
                <Pagination
                    loading={loading}
                    setLoading={setLoading}
                    getFunction={getPMProjects}
                >
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : projects.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('Project name')}
                                        </TableCell>
                                        <TableCell>Client</TableCell>
                                        <TableCell>Contract</TableCell>
                                        <TableCell>
                                            {t('Allocated teams')}
                                        </TableCell>
                                        <TableCell>{t('Status')}</TableCell>
                                        <TableCell>
                                            {t('Created on')}{' '}
                                        </TableCell>
                                        <TableCell>
                                            {t('Last update')}
                                        </TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {projects.map((project) => (
                                        <PMProjectRow
                                            key={project.id}
                                            project={project}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            </div>
        </React.Fragment>
    );
};

AllProjectsManagement.propTypes = {
    pmId: PropTypes.string,
};

AllProjectsManagement.defaultProps = {
    pmId: '',
};

export default AllProjectsManagement;
