import React from 'react';

import { TextField } from '@material-ui/core';

import PropTypes from 'prop-types';
import { INDEXES_INPUT } from 'utils/kpiUtils';

const KpiIndexesInput = ({ name, value, inputType, disabled, onChangeIndexes }) => {
    const { type, style } = INDEXES_INPUT[inputType];

    return (
        <div className="relative">
            <div className="absolute -top-3 -translate-x-1/2 -translate-y-full transform">
                <TextField
                    name={name}
                    inputType={type}
                    disabled={disabled}
                    value={value.toString()}
                    onChange={onChangeIndexes}
                    placeholder={0}
                    inputProps={{
                        style,
                    }}
                />
            </div>
            <div className="h-24 w-px bg-layout-transparent-dark" />
        </div>
    );
};

KpiIndexesInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inputType: PropTypes.string,
    disabled: PropTypes.bool,
    onChangeIndexes: PropTypes.func,
};

KpiIndexesInput.defaultProps = {
    name: undefined,
    value: null,
    inputType: 'input',
    disabled: false,
    onChangeIndexes: undefined,
};

export default KpiIndexesInput;
