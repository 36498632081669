import React, { useEffect, useState } from 'react';

import { List } from 'lucide-react';

import DisplayInfiniteScrollResults from 'components/shared/display-infinite-scroll-results';
import Filter from 'components/shared/filter/filter';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import useMonths from 'hooks/useMonths';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header, Search } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling, generateYears } from 'utils';
import { DUMMY_KPI, DUMMY_TYPES, getPeriodsFilter, KPI_VIEW, PERIODS, WIDTH_CLASSES } from 'utils/kpiUtils';

import KpiToolbar from './kpi-toolbar';

const KpiView = ({ type }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { title, card: Card, getFunction } = KPI_VIEW[type];

    const months = useMonths();
    const years = generateYears(2015, new Date().getFullYear() + 5);

    const [periodsFilter, setPeriodsFilter] = useState(
        getPeriodsFilter(
            { value: years.indexOf(new Date().getFullYear()), options: years },
            { value: new Date().getMonth(), options: months },
        ),
    );

    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState('');

    const [kpiArr, setKpiArr] = useState([
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
    ]);

    const [period, setPeriod] = useState(PERIODS.at(0));

    const [filter, setFilter] = useState([]);
    //TODO: Replace with the actual kpi types
    const filterOptions = [
        {
            id: 'types',
            icon: <List />,
            label: t('KPI types'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('KPI types'),
                    render: (value) => {
                        return DUMMY_TYPES.filter((v) => value.includes(v.id))
                            .map((v) => v.name)
                            .join(', ');
                    },
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: DUMMY_TYPES,
                        label: t('Select KPI types'),
                        render: (item) => item.name,
                    },
                ],
            },
            list: null,
        },
    ];

    const handleFilter = async (filter) => {
        try {
            setLoading(true);

            //TODO: Request all filtered personal kpi's
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleData = async (currentPage, perPage) => {
        try {
            //TODO: Uncomment when request are done in the backend
            // const kpiArrData = await getFunction(currentPage, perPage);

            // setKpiArr(kpiArrData.data);

            // return kpiArrData.length;

            console.log('Is here just for the constrains error');
            return 0;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    useEffect(() => {
        //TODO: Check user kpi permission and redirect if not present
    }, []);

    const handleChangePeriod = (i) => setPeriod(PERIODS.at(i));
    const handleChangeDivision = (i) => setPeriod((prev) => ({ ...prev, selectedDivision: prev.divisions[i] }));

    //TODO: Request the new kpi's
    const handleChangeFilter = (k, v) =>
        setPeriodsFilter((prev) => ({ ...prev, selectedFilter: { ...prev.selectedFilter, [k]: v } }));

    return (
        <>
            <Helmet>
                <title>{t(title)}</title>
            </Helmet>
            <Header
                action={<Search searchIconBig={true} value={query} setValue={setQuery} />}
                toolbar={
                    <KpiToolbar
                        period={period}
                        periodsFilter={periodsFilter}
                        disabled={loading}
                        onChangePeriod={handleChangePeriod}
                        onChangeDivision={handleChangeDivision}
                        onChangeFilter={handleChangeFilter}
                    />
                }
                toolbarSecondary={
                    <Filter
                        disabled={loading}
                        filter={filter}
                        setFilter={setFilter}
                        filterOptions={filterOptions}
                        onFilter={handleFilter}
                    />
                }
            />
            <div className={`page-container grid gap-8 ${WIDTH_CLASSES[type][period.selectedDivision]}`}>
                <DisplayInfiniteScrollResults query={query} setLoading={setLoading} getFunction={handleData}>
                    {loading ? (
                        <Loading />
                    ) : kpiArr.length === 0 ? (
                        <NoDataPlaceholder />
                    ) : (
                        kpiArr.map((kpi) => <Card key={kpi.id} kpi={kpi} period={period} />)
                    )}
                </DisplayInfiniteScrollResults>
            </div>
        </>
    );
};

KpiView.propTypes = {
    type: PropTypes.string,
};

KpiView.defaultProps = {
    type: 'PERSONAL',
};

export default KpiView;
