import React, { useState } from 'react';

import { Check, Plus, Trash2 } from 'lucide-react';

import { Button, FormControlLabel, makeStyles, Switch } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import useConfirm from 'hooks/useConfirm';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Tabs } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { formatDate, isToday } from 'utils';
import { INDEXES_TABS } from 'utils/kpiUtils';

import KpiIndexesView from './kpi-indexes-view';

const useClasses = makeStyles(() => {
    return {
        cancel: {
            border: '1px solid var(--layout-transparent-dark)',
            backgroundColor: `transparent`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--layout-transparent-dark)`,
            },
        },
        update: {
            border: '1px solid var(--layout-transparent)',
            backgroundColor: `var(--layout-transparent)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--layout-transparent-dark)`,
            },
        },
    };
});

const KpiIndexesContent = ({
    update,
    indexes,
    onChangeIndexes,
    onAddIndexes,
    onRemoveIndexes,
    onChangeMonitorIndexes,
    onUpdateIndexes,
    onCancelUpdateIndexes,
    disabled,
    periods,
    remove,
    monitor,
    operations,
    ignoreToday,
    disabledIndexes,
}) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const classes = useClasses();

    const [cachedIndexes, setCachedIndexes] = useState(indexes);

    const [selectedIndexes, setSelectedIndexes] = useState(indexes.length - 1);

    const disabledIndex = disabledIndexes.includes(selectedIndexes) || disabled;
    const currentIndexes = indexes[selectedIndexes];

    const different = !_.isEqual(currentIndexes, cachedIndexes[selectedIndexes]);

    const { id: indexesId, createAt = new Date(), monitor: monitorIndexes } = currentIndexes;

    const [division, setDivision] = useState('MONTHS');

    const handleAdd = () => {
        onAddIndexes(indexes);
        setSelectedIndexes(indexes.length);
    };

    const handleRemove = () => {
        confirm(t('Are you sure you want to delete this period?'), async () => {
            try {
                setSelectedIndexes(indexes.length - 2);

                await onRemoveIndexes(indexesId, indexes);
                setCachedIndexes((prev) => prev.filter((item) => item.id !== indexesId));
            } catch (error) {
                setSelectedIndexes(indexes.length - 1);
                console.error(error);
                throw error;
            }
        });
    };

    const handleUpdate = async () => {
        try {
            const newItem = await onUpdateIndexes(indexesId, indexes);

            setCachedIndexes((prev) => {
                const present = prev.find((item) => item.id === indexesId);

                if (present) return prev.map((item) => (item.id === indexesId ? newItem : item));
                else return [...prev, newItem];
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const handleCancelUpdate = () => {
        const newItem = cachedIndexes[selectedIndexes];
        onCancelUpdateIndexes(indexesId, newItem, indexes);
        if (!newItem) setSelectedIndexes(indexes.length - 2);
    };

    return (
        <div className="flex flex-col">
            {periods && (
                <div className="flex items-center gap-3 p-4">
                    <Tabs
                        disabled={disabled}
                        activeTab={selectedIndexes}
                        setActiveTab={setSelectedIndexes}
                        tabs={indexes.map((item) => formatDate(item.createAt || new Date()))}
                    />

                    <BasicTooltip
                        disabled={disabled || ignoreToday || !isToday(createAt)}
                        tip={t(
                            "You can add other indexes only if this KPI is not new and if you don't already have indexes on the current date!",
                        )}
                    >
                        <Plus
                            className={`h-8 w-8 opacity-40 ${disabled ? 'pointer-events-none' : ignoreToday || !isToday(createAt) ? 'cursor-pointer hover:opacity-100' : 'pointer-events-none'}`}
                            onClick={handleAdd}
                        />
                    </BasicTooltip>
                </div>
            )}

            <div className="flex flex-col gap-3 border-t border-layout-transparent p-4">
                <div className="flex gap-3">
                    <Tabs
                        disabled={disabled}
                        activeTab={(() => {
                            const index = INDEXES_TABS.findIndex((item) => item.key === division);
                            return index >= 0 ? index : null;
                        })()}
                        setActiveTab={(i) => setDivision(INDEXES_TABS[i].key)}
                        tabs={INDEXES_TABS.map((item) => {
                            const { key, label, icon: Icon } = item;

                            return (
                                <span key={key} className="flex items-center gap-3">
                                    {Icon && <Icon className="h-6 w-6" />}
                                    {t(label)}
                                </span>
                            );
                        })}
                    />
                </div>
                <p className="text-lg leading-5 opacity-60">
                    {t(
                        '*After filling in the values for the monthly KPI, the KPIs for the remaining periods will be automatically calculated based on it',
                    )}
                </p>
            </div>
            <KpiIndexesView
                indexes={indexes}
                currentIndexes={currentIndexes}
                division={division}
                onChangeIndexes={onChangeIndexes}
                disabled={disabledIndex}
            />
            {(monitor || remove) && (
                <div className="flex items-center gap-8 border-t border-layout-transparent p-4">
                    {monitor && (
                        <div className="flex items-center gap-3">
                            <FormControlLabel
                                disabled={disabledIndex}
                                control={
                                    <Switch
                                        name="monitor"
                                        color="primary"
                                        checked={!monitorIndexes}
                                        onChange={(e) => onChangeMonitorIndexes(e, indexesId, indexes)}
                                    />
                                }
                                label={
                                    <p
                                        className={`text-xl font-medium leading-5 ${monitorIndexes ? 'opacity-60' : 'opacity-100'}`}
                                    >
                                        {t("Don't monitor the period")}
                                    </p>
                                }
                            />
                        </div>
                    )}
                    {remove && (
                        <Button
                            variant="text"
                            size="large"
                            disabled={disabledIndex}
                            startIcon={<Trash2 />}
                            onClick={handleRemove}
                        >
                            {t('Delete period')}
                        </Button>
                    )}
                </div>
            )}
            {update && operations && different && (
                <div className="flex items-center justify-end gap-3 border-t border-layout-transparent p-4">
                    <Button
                        size="large"
                        disabled={disabledIndex}
                        className={classes.cancel}
                        onClick={handleCancelUpdate}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        disabled={disabledIndex}
                        startIcon={<Check />}
                        className={classes.update}
                        onClick={handleUpdate}
                    >
                        {t('Update')}
                    </Button>
                </div>
            )}
        </div>
    );
};

KpiIndexesContent.propTypes = {
    update: PropTypes.bool,
    indexes: PropTypes.array,
    onChangeIndexes: PropTypes.func,
    onAddIndexes: PropTypes.func,
    onRemoveIndexes: PropTypes.func,
    onChangeMonitorIndexes: PropTypes.func,
    onUpdateIndexes: PropTypes.func,
    onCancelUpdateIndexes: PropTypes.func,
    disabled: PropTypes.bool,
    periods: PropTypes.bool,
    remove: PropTypes.bool,
    monitor: PropTypes.bool,
    operations: PropTypes.bool,
    ignoreToday: PropTypes.bool,
    disabledIndexes: PropTypes.array,
};

KpiIndexesContent.defaultProps = {
    update: false,
    indexes: [],
    onChangeIndexes: () => {},
    onAddIndexes: () => {},
    onRemoveIndexes: () => {},
    onChangeMonitorIndexes: () => {},
    onUpdateIndexes: () => {},
    onCancelUpdateIndexes: () => {},
    disabled: false,
    periods: true,
    remove: false,
    monitor: false,
    operations: false,
    ignoreToday: false,
    disabledIndexes: [],
};

export default KpiIndexesContent;
