import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { List, PlusCircle } from 'lucide-react';
import { ReactComponent as AuthorIcon } from 'assets/pipelines/svgs/author-icon.svg';
import { ReactComponent as CalendarBlankIcon } from 'assets/pipelines/svgs/calendar-blank-icon.svg';
import { ReactComponent as CalendarRangeIcon } from 'assets/pipelines/svgs/calendar-range-icon.svg';
import { ReactComponent as CalendarSelectionIcon } from 'assets/pipelines/svgs/calendar-selection-icon.svg';
import { ReactComponent as CurrentDayIcon } from 'assets/pipelines/svgs/current-day-icon.svg';
import { ReactComponent as LastThirtyDaysIcon } from 'assets/pipelines/svgs/last-thirty-days-icon.svg';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import DisplayPaginatedResults from 'components/shared/display-paginated-results';
import Filter from 'components/shared/filter/filter';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header, Search, TableSeparator } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { kpi } from 'routes';
import { errorHandling, formatDate, getDayBeginningAndEnding } from 'utils';
import { getKpiArr, getTenantUsers } from 'utils/getterFunctions';
import { DUMMY_KPI, DUMMY_TYPES } from 'utils/kpiUtils';

import KpiNomenclatureRow from './kpi-nomenclature-row';

export const KpiNomenclature = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '64',
            permissionType: 'ALL',
        },
    ]);

    const [loading, setLoading] = useState(true);
    const [loadingAux, setLoadingAux] = useState(true);
    const [query, setQuery] = useState('');

    const [users, setUsers] = useState([]);
    const [kpiArr, setKpiArr] = useState([
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
        DUMMY_KPI,
    ]);

    const [filter, setFilter] = useState([]);
    //TODO: Replace with the actual kpi types
    const filterOptions = [
        {
            id: 'authorId',
            icon: <AuthorIcon />,
            label: t('Users'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('Users'),
                    render: (value) =>
                        users
                            .filter((u) => value.includes(u.id))
                            .map((v) => v.profile.name)
                            .join(', '),
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: users,
                        label: t('Select users'),
                        render: (item) => item.profile.name,
                    },
                ],
            },
            list: null,
        },
        {
            id: 'types',
            icon: <List />,
            label: t('KPI types'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('KPI types'),
                    render: (value) => {
                        return DUMMY_TYPES.filter((v) => value.includes(v.id))
                            .map((v) => v.label)
                            .join(', ');
                    },
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: DUMMY_TYPES,
                        label: t('Select KPI types'),
                        render: (item) => item.label,
                    },
                ],
            },
            list: null,
        },
        {
            id: 'date-group',
            icon: <CalendarBlankIcon />,
            label: t('Date'),
            data: null,
            list: [
                {
                    id: 'date',
                    icon: <CurrentDayIcon />,
                    label: t('Today'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date())
                                .startDate,
                            endDate: getDayBeginningAndEnding(new Date())
                                .endDate,
                        },
                        filterData: {
                            label: t('Date'),
                            render: (value) =>
                                formatDate(value.startDate, true, true),
                            metadata: {},
                        },
                        inputs: [],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <LastThirtyDaysIcon />,
                    label: t('Last 30 days'),
                    data: {
                        defaultValue: {
                            startDate: (() => {
                                const date = getDayBeginningAndEnding(
                                    new Date(),
                                ).startDate;
                                date.setDate(date.getDate() - 29);
                                return date;
                            })(),
                            endDate: getDayBeginningAndEnding(new Date())
                                .endDate,
                        },
                        filterData: {
                            label: 'Interval',
                            render: (value) =>
                                `${formatDate(value.startDate, true, true)} - ${formatDate(value.endDate, true, true)}`,
                            metadata: {},
                        },
                        inputs: [],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <CalendarSelectionIcon />,
                    label: t('Selected date'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date())
                                .startDate,
                            endDate: getDayBeginningAndEnding(new Date())
                                .endDate,
                        },
                        filterData: {
                            label: t('Date'),
                            render: (value) =>
                                formatDate(value.startDate, true, true),
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['startDate', 'endDate'],
                                type: 'date',
                                options: null,
                                label: t('Choose a date'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <CalendarRangeIcon />,
                    label: t('Selection range'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date())
                                .startDate,
                            endDate: getDayBeginningAndEnding(new Date())
                                .endDate,
                        },
                        filterData: {
                            label: 'Interval',
                            render: (value) =>
                                `${formatDate(value.startDate, true, true)} - ${formatDate(value.endDate, true, true)}`,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['startDate'],
                                type: 'date',
                                options: null,
                                label: t('Select the end date'),
                                render: null,
                            },
                            {
                                key: ['endDate'],
                                type: 'date',
                                options: null,
                                label: t('Select the start date'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
    ];

    const handleFilter = async (filter) => {
        try {
            setLoading(true);

            //TODO: Request all filtered kpi's
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleData = async (currentPage, perPage) => {
        try {
            //TODO: Uncomment when request are done in the backend

            // const kpiArrData = await getKpiArr(currentPage, perPage);

            // setKpiArr(kpiArrData.data);

            // return kpiArrData.length;

            console.log('Is here just for the constrains error');
            return 0;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const handleFilterData = async () => {
        try {
            //TODO: Request users for filter kpi's
            const users = await getTenantUsers();
            setUsers(users);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoadingAux(false);
        }
    };

    useEffect(() => {
        //TODO: Check user kpi permission and redirect if not present
        handleFilterData();
    }, []);

    const handleCreateNewKpi = () => {
        history.push(
            kpi.base + kpi.nomenclature.base + kpi.nomenclature.create.base,
        );
    };

    return (
        <>
            <Helmet>
                <title>{t("KPI's nomenclature")}</title>
            </Helmet>
            <Header
                pageTitle={t('Create new KPI')}
                pageIcon={<PlusCircle />}
                titleAction={canAll ? handleCreateNewKpi : undefined}
                action={
                    <Search
                        searchIconBig={true}
                        value={query}
                        setValue={setQuery}
                    />
                }
                toolbar={
                    <Filter
                        disabled={loadingAux || loading}
                        filter={filter}
                        setFilter={setFilter}
                        filterOptions={filterOptions}
                        onFilter={handleFilter}
                    />
                }
            />
            <div className="page-container">
                <DisplayPaginatedResults
                    loading={loading}
                    setLoading={setLoading}
                    query={query}
                    getFunction={handleData}
                    getSearchFunction={handleData}
                >
                    {loading ? (
                        <Loading />
                    ) : kpiArr.length === 0 ? (
                        <NoDataPlaceholder />
                    ) : (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Name')}</TableCell>
                                        <TableCell>{t('KPI type')}</TableCell>
                                        <TableCell>{t('Created at')}</TableCell>
                                        <TableCell>
                                            {t('Assigned users')}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {kpiArr.map((kpi) => (
                                        <KpiNomenclatureRow
                                            key={kpi.id}
                                            kpi={kpi}
                                            onData={handleData}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DisplayPaginatedResults>
            </div>
        </>
    );
};

export default KpiNomenclature;
