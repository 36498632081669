import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { handleAddIndexes, handleChangeIndexes } from 'utils/kpiUtils';

import KpiDetails from '../components/kpi-details';
import KpiFormula from '../components/kpi-formula';
import KpiIndexes from '../components/kpi-indexes';

const KpiNomenclatureConfigurator = ({ kpi, onChangeKpi, types, disabled }) => {
    const { t } = useTranslation();
    const { id, indexes, usersIndexes } = kpi;

    const handleChange = (key, e, id, indexes) => {
        onChangeKpi(key, handleChangeIndexes(e, id, indexes));
    };

    const handleAdd = (key, indexes) => onChangeKpi(key, handleAddIndexes(indexes));

    return (
        <>
            <KpiDetails kpi={kpi} onChangeKpi={onChangeKpi} types={types} disabled={disabled} />
            <KpiFormula kpi={kpi} editable onChangeKpi={onChangeKpi} disabled={disabled} />
            <KpiIndexes
                update={Boolean(id)}
                indexes={indexes}
                onChangeIndexes={handleChange.bind(null, 'indexes')}
                onAddIndexes={handleAdd.bind(null, 'indexes')}
                title={<p className="text-xl font-bold uppercase leading-5 tracking-widest">{t('KPI indexes')}</p>}
                disabled={disabled}
                disabledIndexes={Array.from({ length: indexes.length - 1 }, (_, i) => i)}
            />
            <KpiIndexes
                update={Boolean(id)}
                indexes={usersIndexes}
                onChangeIndexes={handleChange.bind(null, 'usersIndexes')}
                onAddIndexes={handleAdd.bind(null, 'usersIndexes')}
                title={
                    <div className="flex w-full items-center justify-between gap-8">
                        <p className="text-xl font-bold uppercase leading-5 tracking-widest">
                            {t('KPI indexes - employee')}
                        </p>
                        <p className="text-lg leading-5 opacity-60">
                            {t('*Template can be further adjusted for each user')}
                        </p>
                    </div>
                }
                periods={false}
                disabled={disabled}
                disabledIndexes={Array.from({ length: usersIndexes.length - 1 }, (_, i) => i)}
            />
        </>
    );
};

KpiNomenclatureConfigurator.propTypes = {
    kpi: PropTypes.object,
    onChangeKpi: PropTypes.func,
    types: PropTypes.array,
    disabled: PropTypes.bool,
};

KpiNomenclatureConfigurator.defaultProps = {
    kpi: null,
    onChangeKpi: () => {},
    types: [],
    disabled: false,
};

export default KpiNomenclatureConfigurator;
