import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';

import {
    Box,
    Button,
    CircularProgress,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header, TableSeparator } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { internalActivity } from 'routes';
import { formatDate } from 'utils';
import API from 'utils/axios';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
    };
});

const ProjectStructureRow = ({ projectStructure, onDelete, disabled }) => {
    const styles = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    // ? We get the actual project structure by taking the last element in the array or revisions if we have any, otherwise we use the default value
    const actualProjectStructure = projectStructure.Revisions.length
        ? projectStructure.Revisions.at(-1)
        : projectStructure;

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '42',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '42',
            permissionType: 'VIEW',
        },
    ]);

    return (
        <TableRow>
            <TableCell>{actualProjectStructure.name}</TableCell>
            <TableCell>{formatDate(actualProjectStructure.createAt)}</TableCell>
            <TableCell>
                {canView ? (
                    <div className="flex justify-end gap-2">
                        {canView && (
                            <Button
                                disabled={disabled}
                                size="small"
                                startIcon={<ListIcon />}
                                color="secondary"
                                onClick={() => {
                                    history.push(
                                        internalActivity.base +
                                            internalActivity.projectStructure
                                                .base +
                                            internalActivity.projectStructure
                                                .gantt +
                                            '/' +
                                            actualProjectStructure.id,
                                    );
                                }}
                            >
                                {t('Gantt Project structure')}
                            </Button>
                        )}
                        {canAll && (
                            <Button
                                disabled={disabled}
                                size="small"
                                startIcon={<EditIcon />}
                                color="primary"
                                onClick={() => {
                                    history.push(
                                        internalActivity.base +
                                            internalActivity.projectStructure
                                                .base +
                                            internalActivity.projectStructure
                                                .update +
                                            '/' +
                                            actualProjectStructure.id,
                                    );
                                }}
                            >
                                {t('View & Edit Project structure')}
                            </Button>
                        )}

                        {canAll && (
                            <Button
                                disabled={disabled}
                                size="small"
                                startIcon={<DeleteIcon />}
                                className={styles.error}
                                onClick={() => onDelete(projectStructure.id)}
                            >
                                {t('Delete Project structure')}
                            </Button>
                        )}
                    </div>
                ) : (
                    <>&nbsp;</>
                )}
            </TableCell>
        </TableRow>
    );
};

ProjectStructureRow.propTypes = {
    projectStructure: PropTypes.object,
    onDelete: PropTypes.func,
    disabled: PropTypes.bool,
};

ProjectStructureRow.defaultProps = {
    projectStructure: {},
    onDelete: () => {},
    disabled: false,
};

const ProjectStructures = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [isDeleting, setIsDeleting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [projectStructures, setProjectStructures] = useState([]);
    const currentPageRef = useRef(null);
    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '42',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '42',
            permissionType: 'ALL',
        },
    ]);

    const handleDelete = async (id) => {
        try {
            setIsDeleting(true);

            await API.delete('projectStructure', { params: { id } });

            enqueueSnackbar(t('Project structure was deleted successfully!'), {
                variant: 'success',
            });

            await getProjectStructures(currentPageRef.current, 10);
        } catch (error) {
            console.error(error);
        } finally {
            setIsDeleting(false);
        }
    };

    const getProjectStructures = async (currentPage, perPage, controller) => {
        try {
            const resProjectStructures = await API.get('projectStructures', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
                signal: controller?.signal ?? undefined,
            });

            currentPageRef.current = currentPage;

            setProjectStructures(resProjectStructures.data.data.data);

            return resProjectStructures.data.data.length;
        } catch (error) {
            throw new Error(error);
        }
    };

    useEffect(() => {
        if (!canView) history.goBack();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('Project structures')}</title>
            </Helmet>

            <Header
                pageTitle={t('Project structures')}
                //  <Search searchIconBig={true} value={query} setValue={setQuery} withSearchIcon={false} />
                action={
                    canAll && (
                        <Button
                            startIcon={<AddIcon />}
                            color="secondary"
                            style={{ borderRadius: '999px' }}
                            onClick={() =>
                                history.push(
                                    internalActivity.base +
                                        internalActivity.projectStructure.base +
                                        internalActivity.projectStructure
                                            .create,
                                )
                            }
                        >
                            {t('Add new Project structure')}
                        </Button>
                    )
                }
            />

            <div className="page-container">
                <Pagination
                    loading={loading}
                    setLoading={setLoading}
                    getFunction={getProjectStructures}
                >
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : projectStructures.length > 0 ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('Project structure name')}
                                        </TableCell>
                                        <TableCell>{t('Created on')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {projectStructures?.map((p) => (
                                        <ProjectStructureRow
                                            key={p.id}
                                            projectStructure={p}
                                            onDelete={handleDelete}
                                            disabled={isDeleting}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            </div>
        </>
    );
};

export default ProjectStructures;
