import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import ArticleForm from 'components/crm/quotes/article-form';
import ErrorButton from 'components/shared/error-button';
import GroupsSelector from 'components/shared/groups-selector/groups-selector';
import UserContext from 'contexts/UserContext';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import { Header, LabelWrapper, Tabs } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import { errorHandling, formatPositiveNumber } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const EditStock = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm();
    const history = useHistory();
    const { search } = useLocation();
    const { user, checkPerm } = useContext(UserContext);
    const searchParams = new URLSearchParams(search);
    const articleId = searchParams.get('articleId');
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(0);
    const [stock, setStock] = useState(null);
    const [isEditName, setIsEditName] = useState(false);
    const [isAddArticles, setIsAddArticles] = useState(false);
    const [isGroupsUpdate, setIsGroupsUpdate] = useState(false);
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [groups, setGroups] = useState([]);

    const canAll = checkPerm([
        {
            permissionId: '49',
            permissionType: 'ALL',
        },
    ]);

    const canAllGroups = checkPerm([
        {
            permissionId: '43',
            permissionType: 'ALL',
        },
    ]);

    useEffect(
        () =>
            (async () => {
                try {
                    const res = await API.get(`stock`, { params: { id } });
                    const stock = res.data.stock;

                    setStock(stock);
                    setName(stock.name);
                    setGroups(stock.groups.map((group) => group.groupId));

                    if (articleId) {
                        const articleIdx = stock.articles.findIndex(
                            (a) => a.id === articleId,
                        );
                        setSelectedArticle(articleIdx >= 0 ? articleIdx : 0);
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            })(),
        [id],
    );

    const editStockSchema = yup.object().shape({
        name: yup
            .string()
            .typeError(t('The stock name is required!'))
            .required(t('The stock name is required!')),
        id: yup
            .string()
            .typeError(t('The article is required!'))
            .required(t('The article is required!')),
    });

    const addArticleSchema = yup.object().shape({
        quantity: yup
            .number()
            .typeError(t('The number of items value is required!'))
            .min(1, t('You must have at least have one item!'))
            .required(t('The number of items value is required!')),
        id: yup
            .string()
            .typeError(t('The article is required!'))
            .required(t('The article is required!')),
    });

    const handleEditStock = async () => {
        try {
            setIsUpdating(true);

            const formData = { id, name };

            await editStockSchema.validate(formData);
            await API.patch('stock', formData);

            enqueueSnackbar(t('Stock edited successfully!'), {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
            setIsEditName(false);
        }
    };

    const handleAddArticles = async () => {
        try {
            setIsUpdating(true);

            const formData = { id, quantity };

            await addArticleSchema.validate(formData);
            const res = await API.patch('stock', formData);
            setStock(res.data.stock);

            enqueueSnackbar(t('Articles added successfully!'), {
                variant: 'success',
            });
        } catch (error) {
            setName(stock.name);
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
            setIsAddArticles(false);
        }
    };

    const handleUpdateGroups = async () => {
        try {
            setIsUpdating(true);

            const formData = { id, groups };
            const res = await API.patch('stock', formData);
            setStock(res.data.stock);

            enqueueSnackbar(t('Groups updated successfully!'), {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
            setIsGroupsUpdate(false);
        }
    };

    const handleDeleteStock = async () => {
        try {
            setIsUpdating(true);

            await API.delete('stock', {
                params: {
                    id: stock.id,
                },
            });

            enqueueSnackbar(t('The stock was deleted successfully!'), {
                variant: 'success',
            });
            history.push(
                quotes.base +
                    quotes.nomenclature.base +
                    quotes.nomenclature.read,
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
        }
    };

    const handleDeleteArticleCallback = (artId) => {
        const artIdx = stock.articles.findIndex((a) => a.id === artId);
        const newArticles = structuredClone(stock.articles);
        newArticles.splice(artIdx, 1);

        if (artIdx > 0) setSelectedArticle((prev) => prev - 1);
        setStock((prev) => ({ ...prev, articles: newArticles }));
    };

    return (
        <>
            <Helmet>
                <title>{t('Edit stock')}</title>
            </Helmet>
            <Header
                pageTitle={`${t('Edit stock')}${stock ? ` - ${stock.name}` : ''}`}
            />
            <div className="page-container flex flex-col gap-16">
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div className="flex flex-wrap gap-4">
                            {canAllGroups && (
                                <div className="flex items-end gap-4 rounded-md bg-layout-transparent p-4 sm:w-full">
                                    <GroupsSelector
                                        disabled={!canAll || !isGroupsUpdate}
                                        groups={groups}
                                        setGroups={setGroups}
                                    />
                                    {!isGroupsUpdate && (
                                        <div className="inline-block">
                                            <Button
                                                disabled={!canAll || isUpdating}
                                                color="primary"
                                                startIcon={<EditIcon />}
                                                onClick={() =>
                                                    setIsGroupsUpdate(true)
                                                }
                                            >
                                                {t('Update group/s')}
                                            </Button>
                                        </div>
                                    )}

                                    {isGroupsUpdate && (
                                        <div className="flex gap-2">
                                            <Button
                                                disabled={!canAll || isUpdating}
                                                onClick={() =>
                                                    setIsGroupsUpdate(false)
                                                }
                                            >
                                                {t('Cancel')}
                                            </Button>
                                            <Button
                                                disabled={!canAll || isUpdating}
                                                color="secondary"
                                                onClick={handleUpdateGroups}
                                            >
                                                {t('Complete')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div className="flex items-end gap-4 rounded-md bg-layout-transparent p-4 sm:w-full">
                                <LabelWrapper label={t('Stock name')}>
                                    <TextField
                                        disabled={
                                            !canAll || !isEditName || isUpdating
                                        }
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        name="name"
                                        placeholder={t('Name')}
                                    />
                                </LabelWrapper>

                                {!isEditName && (
                                    <div className="inline-block">
                                        <Button
                                            disabled={!canAll || isUpdating}
                                            color="secondary"
                                            startIcon={<EditIcon />}
                                            onClick={() => setIsEditName(true)}
                                        >
                                            {t('Edit stock name')}
                                        </Button>
                                    </div>
                                )}

                                {isEditName && (
                                    <div className="flex gap-2">
                                        <Button
                                            disabled={!canAll || isUpdating}
                                            onClick={() => setIsEditName(false)}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                        <Button
                                            disabled={!canAll || isUpdating}
                                            color="secondary"
                                            onClick={handleEditStock}
                                        >
                                            {t('Complete')}
                                        </Button>
                                    </div>
                                )}
                            </div>

                            <div className="flex items-end gap-4 rounded-md bg-layout-transparent p-4 sm:w-full">
                                <LabelWrapper label={t('Number of items')}>
                                    <TextField
                                        disabled={
                                            !canAll ||
                                            !isAddArticles ||
                                            isUpdating
                                        }
                                        placeholder={t('Number of items')}
                                        value={quantity}
                                        type="number"
                                        name="quantity"
                                        onChange={(e) =>
                                            setQuantity(
                                                formatPositiveNumber(
                                                    e.target.value,
                                                ),
                                            )
                                        }
                                    />
                                </LabelWrapper>
                                {!isAddArticles && (
                                    <div className="inline-block">
                                        <Button
                                            disabled={!canAll || isUpdating}
                                            color="primary"
                                            startIcon={<AddIcon />}
                                            onClick={() =>
                                                setIsAddArticles(true)
                                            }
                                        >
                                            {t('Add article/s')}
                                        </Button>
                                    </div>
                                )}

                                {isAddArticles && (
                                    <div className="flex gap-2">
                                        <Button
                                            disabled={!canAll || isUpdating}
                                            onClick={() =>
                                                setIsAddArticles(false)
                                            }
                                        >
                                            {t('Cancel')}
                                        </Button>
                                        <Button
                                            disabled={!canAll || isUpdating}
                                            color="secondary"
                                            onClick={handleAddArticles}
                                        >
                                            {t('Complete')}
                                        </Button>
                                    </div>
                                )}
                            </div>

                            <div className="flex items-center justify-center gap-4 rounded-md bg-layout-transparent p-4 sm:w-full">
                                <ErrorButton
                                    disabled={!canAll || isUpdating}
                                    size="large"
                                    startIcon={<DeleteIcon />}
                                    onClick={() =>
                                        confirm(
                                            t(
                                                'Are you sure you want to delete this stock?',
                                            ),
                                            () => handleDeleteStock(),
                                        )
                                    }
                                >
                                    {t('Delete stock')}
                                </ErrorButton>
                            </div>
                        </div>
                        {stock.articles.length > 0 ? (
                            <>
                                <div className="flex flex-col gap-3">
                                    <h2>{t('Select article')}</h2>
                                    <div>
                                        <Tabs
                                            disabled={isUpdating}
                                            small
                                            activeTab={selectedArticle}
                                            setActiveTab={setSelectedArticle}
                                            tabs={Array.from(
                                                {
                                                    length: stock.articles
                                                        .length,
                                                },
                                                (_, i) => i + 1,
                                            )}
                                        />
                                    </div>
                                </div>
                                <ArticleForm
                                    articleId={
                                        stock.articles[selectedArticle].id
                                    }
                                    isStockCase
                                    setDisabledParent={setIsUpdating}
                                    onDeleteArticleCallback={
                                        handleDeleteArticleCallback
                                    }
                                    canAll={canAll}
                                />
                            </>
                        ) : (
                            <div className="mb-5 flex max-w-7xl flex-col items-center justify-center rounded-md bg-layout-transparent px-4 py-8 shadow 2xl:max-w-4xl">
                                <h3>
                                    {t('There are no articles on this stock')}
                                </h3>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default EditStock;
