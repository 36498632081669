import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button } from '@material-ui/core';

import DisplayEmployeeOnProject from 'components/employees/display_employees_on_project';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { projectInManagement } from 'routes';
import API from 'utils/axios';

import DisplayAffiliateAccountsOnProject from './display_affiliate_accounts_on_project';
import DisplayCompletedAccountsOnProject from './display_completed_accounts_on_project';
import SelectResourcesOnProject from './select-resources-on-project';

export default function SinglePMConfiguratorRouter({ projectName }) {
    const rootUrl =
        projectInManagement.base +
        '/:projectId' +
        projectInManagement.configurator;

    return (
        <Switch>
            <Route exact path={rootUrl}>
                <SinglePMConfigurator projectName={projectName} />
            </Route>

            <Route exact path={rootUrl + '/select-completed-accounts'}>
                <SelectResourcesOnProject />
            </Route>

            {/* <Route exact path={rootUrl + '/select-affiliate-accounts'}>
                <SelectResourcesOnProject />
            </Route> */}

            <Route exact path={rootUrl + '/select-employees'}>
                <SelectResourcesOnProject />
            </Route>
        </Switch>
    );
}

SinglePMConfiguratorRouter.propTypes = {
    projectName: PropTypes.string,
};

function SinglePMConfigurator({ projectName }) {
    const history = useHistory();
    const params = new URL(document.location).searchParams;
    const { t } = useTranslation();
    const { projectId } = useParams();
    const tabs = [
        t('Accounts'),
        // t('Affiliated accounts'),
        t('Human resources'),
        t('External resources'),
    ];
    const [activeTab, setActiveTab] = useState(
        params.get('tab') ? Number(params.get('tab')) : 0,
    );

    const { checkPerm } = useContext(UserContext);
    const canView = checkPerm([
        {
            permissionId: '61',
            permissionType: 'VIEW',
        },
    ]);
    const canAll = checkPerm([
        {
            permissionId: '61',
            permissionType: 'ALL',
        },
    ]);

    const rootUrl =
        projectInManagement.base +
        '/' +
        location.pathname.split('/')[2] +
        projectInManagement.configurator;

    const [resources, setResources] = useState({
        completedAccounts: [],
        // affiliateAccounts: [],
        internalResources: [],
        externalResources: [],
    });

    const getResourcesByTypeOnProject = async () => {
        let response;

        try {
            switch (activeTab) {
                case 0:
                    response = await API.get(
                        `/ProjectUsers?pmProjectId=${projectId}`,
                        {
                            params: {
                                perPage: 999999,
                                pagesToLoad: 1,
                                currentPage: 0,
                            },
                        },
                    );

                    setResources({
                        ...resources,
                        completedAccounts: response.data.Users.users.map(
                            (user) => user,
                        ),
                    });

                    break;
                // case 1:
                //     response = await API.get(
                //         `/ProjectAffiliates?pmProjectId=${projectId}`,
                //         {
                //             params: {
                //                 perPage: 999999,
                //                 pagesToLoad: 1,
                //                 currentPage: 0,
                //             },
                //         },
                //     );

                //     setResources({
                //         ...resources,
                //         affiliateAccounts: response.data.Affiliates,
                //     });

                //     break;
                case 1:
                    response = await API.get(
                        `/ProjectEmployees?pmProjectId=${projectId}`,
                        {
                            params: {
                                perPage: 999999,
                                pagesToLoad: 1,
                                currentPage: 0,
                            },
                        },
                    );

                    setResources({
                        ...resources,
                        internalResources: response.data.Employees.filter(
                            (employee) =>
                                employee.Employees.employeeType === 'INTERNAL',
                        ),
                    });

                    break;
                case 2:
                    response = await API.get(
                        `/ProjectEmployees?pmProjectId=${projectId}`,
                        {
                            params: {
                                perPage: 999999,
                                pagesToLoad: 1,
                                currentPage: 0,
                            },
                        },
                    );

                    setResources({
                        ...resources,
                        externalResources: response.data.Employees.filter(
                            (employee) =>
                                employee.Employees.employeeType === 'EXTERNAL',
                        ),
                    });

                    break;

                default:
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!canView) {
            history.push('/');
            return;
        }

        getResourcesByTypeOnProject();
    }, [activeTab]);

    return (
        <React.Fragment>
            <Header
                pageTitle={
                    tabs[activeTab] + ' ' + `${t('into the')} ${projectName}`
                }
                toolbar={
                    <Tabs
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                }
                action={
                    canAll &&
                    (activeTab === 0 ? (
                        /* Add a PM Internal employee Button */
                        <Button
                            color="secondary"
                            startIcon={<AddIcon />}
                            style={{ borderRadius: '999px' }}
                            onClick={() =>
                                history.push({
                                    pathname:
                                        rootUrl + '/select-completed-accounts',
                                    state: {
                                        resourceType: 'completed',
                                    },
                                })
                            }
                        >
                            {t('Select the completed accounts')}
                        </Button>
                    ) : // : activeTab === 1 ? (
                    //     /* Add a PM Internal employee Button */
                    //     <Button
                    //         color="secondary"
                    //         startIcon={<AddIcon />}
                    //         style={{ borderRadius: '999px' }}
                    //         onClick={() =>
                    //             history.push({
                    //                 pathname:
                    //                     rootUrl + '/select-affiliate-accounts',
                    //                 state: {
                    //                     resourceType: 'affiliate',
                    //                 },
                    //             })
                    //         }
                    //     >
                    //         {t('Select the affiliate accounts')}
                    //     </Button>
                    // )
                    activeTab === 1 ? (
                        /* Add a PM Internal employee Button */
                        <Button
                            color="secondary"
                            startIcon={<AddIcon />}
                            style={{ borderRadius: '999px' }}
                            onClick={() =>
                                history.push({
                                    pathname: rootUrl + '/select-employees',
                                    state: {
                                        resourceType: 'internal',
                                    },
                                })
                            }
                        >
                            {t('Select internal resources')}
                        </Button>
                    ) : activeTab === 2 ? (
                        /* Add a PM External employee Button */
                        <Button
                            color="secondary"
                            startIcon={<AddIcon />}
                            style={{ borderRadius: '999px' }}
                            onClick={() =>
                                history.push({
                                    pathname: rootUrl + '/select-employees',
                                    state: {
                                        resourceType: 'external',
                                    },
                                })
                            }
                        >
                            {t('Select external resources')}
                        </Button>
                    ) : null)
                }
            />

            <div className="page-container relative">
                {activeTab === 0 && (
                    <React.Fragment>
                        <Helmet>
                            <title>
                                {t('PM Configurator')} |{' '}
                                {t('Completed accounts')}
                            </title>
                        </Helmet>
                        <DisplayCompletedAccountsOnProject
                            completedAccounts={resources.completedAccounts}
                            getResources={getResourcesByTypeOnProject}
                        />
                    </React.Fragment>
                )}
                {/* {activeTab === 1 && (
                    <React.Fragment>
                        <Helmet>
                            <title>
                                {t('PM Configurator')} |{' '}
                                {t('Affiliate accounts')}
                            </title>
                        </Helmet>
                        <DisplayAffiliateAccountsOnProject
                            affiliateAccounts={resources.affiliateAccounts}
                            getResources={getResourcesByTypeOnProject}
                        />
                    </React.Fragment>
                )} */}
                {activeTab === 1 && (
                    <React.Fragment>
                        <Helmet>
                            <title>
                                {t('PM Configurator')} |{' '}
                                {t('Internal resources')}
                            </title>
                        </Helmet>
                        {resources.internalResources.map((resource) => (
                            <DisplayEmployeeOnProject
                                key={resource.id}
                                employee={resource}
                                getResources={getResourcesByTypeOnProject}
                                canAll={canAll}
                            />
                        ))}
                    </React.Fragment>
                )}
                {activeTab === 2 && (
                    <React.Fragment>
                        <Helmet>
                            <title>
                                {t('PM Configurator')} |{' '}
                                {t('External resources')}
                            </title>
                        </Helmet>
                        {resources.externalResources.map((resource) => (
                            <DisplayEmployeeOnProject
                                key={resource.id}
                                employee={resource}
                                getResources={getResourcesByTypeOnProject}
                                canAll={canAll}
                            />
                        ))}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

SinglePMConfigurator.propTypes = {
    projectName: PropTypes.string,
};
