import React from 'react';

import { GripHorizontal, Plus, Trash2 } from 'lucide-react';

import { TextField } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, generateUUID } from 'utils';
import {
    DEFAULT_FORMULA_ELEMENT,
    FILTERS,
    FORMULA_SELECTION,
    handleUpdateFormulaElement,
} from 'utils/kpiUtils';

import KpiFormulaItemFilters from './kpi-formula-item-filters';
import KpiFormulaItemSelection from './kpi-formula-item-selection';
import KpiFormulaItemSign from './kpi-formula-item-sign';

const KpiFormulaItem = ({
    item,
    formula,
    column,
    editable,
    onChangeKpi,
    disabled,
    index,
}) => {
    const { t } = useTranslation();
    const { id, name, filters, item: selectionItem } = item;

    const filterOptions = (() => {
        if (!selectionItem) return [];

        let current = selectionItem;
        let selection = FORMULA_SELECTION.find(
            (item) => item.key === current.key,
        );

        while (current.item) {
            current = current.item;
            selection = selection.options.find(
                (item) => item.key === current.key,
            );
        }

        return selection.filters;
    })();

    const filterKeys = filters.map((item) => item.key);
    const hiddenFilterOptions = filterOptions.reduce(
        (acc, cur, index) =>
            filterKeys.includes(cur.key) ? [...acc, index] : acc,
        [],
    );

    const handleChangeName = (e) => {
        onChangeKpi(
            'formula',
            handleUpdateFormulaElement(formula, id, { name: e.target.value }),
        );
    };

    const handleAddFilter = (i) => {
        const { key, input } = filterOptions[i];
        const { value, criteria } = FILTERS[input];

        onChangeKpi(
            'formula',
            handleUpdateFormulaElement(formula, id, {
                filters: [
                    ...formula.elements[id].filters,
                    {
                        key,
                        value,
                        criterion: criteria.length
                            ? criteria[0].criterion
                            : null,
                    },
                ],
            }),
        );
    };

    const handleDeleteElement = () => {
        const elementsIds = [...formula.columns[column.id].elementsIds];
        const elementId = elementsIds[index];
        elementsIds.splice(index, 1);

        const newColumns = structuredClone(formula.columns);
        newColumns[column.id] = {
            ...column,
            elementsIds,
        };

        let newLastId = null;
        if (index === elementsIds.length) {
            newLastId = elementsIds.at(-1);
        }

        const newElements = structuredClone(formula.elements);
        delete newElements[elementId];

        if (newLastId) {
            newElements[newLastId] = {
                ...newElements[newLastId],
                sign: 'equal',
            };
        }

        onChangeKpi('formula', {
            ...formula,
            columns: newColumns,
            elements: newElements,
        });
    };

    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => {
                return (
                    <div
                        className="inline-flex w-full max-w-sm gap-1"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                    >
                        <div className="flex w-full flex-col items-center">
                            <BasicTooltip tip={t('Move')} disabled={disabled}>
                                <div
                                    className={`group flex h-8 w-8 cursor-pointer items-center justify-center ${!editable ? 'opacity-0' : ''} ${disabled ? 'pointer-events-none' : ''}`}
                                    {...provided.dragHandleProps}
                                >
                                    <GripHorizontal
                                        className={`h-5 w-5 opacity-40 group-hover:opacity-100 ${snapshot.isDragging ? 'opacity-100' : ''}`}
                                    />
                                </div>
                            </BasicTooltip>

                            <div
                                className={`flex w-full flex-col overflow-hidden border border-layout-transparent ${editable ? 'rounded-3xl' : '-mt-8 rounded-2xl'} ${snapshot.isDragging ? 'bg-layout-main' : 'bg-layout-transparent'}`}
                            >
                                <div
                                    className={`border-b p-4 ${editable ? 'border-layout-transparent' : 'border-layout-transparent-dark'}`}
                                >
                                    {editable ? (
                                        <LabelWrapper label={t('Column name')}>
                                            <TextField
                                                placeholder={t('Column name')}
                                                value={name}
                                                onChange={handleChangeName}
                                            />
                                        </LabelWrapper>
                                    ) : (
                                        <p className="px-4 text-xl leading-6">
                                            {name}
                                        </p>
                                    )}
                                </div>
                                <div
                                    className={`flex flex-col ${editable ? 'gap-2 border-b border-layout-transparent p-4' : 'bg-layout-transparent'}`}
                                >
                                    <KpiFormulaItemSelection
                                        item={selectionItem}
                                        elementId={id}
                                        formula={formula}
                                        options={FORMULA_SELECTION}
                                        editable={editable}
                                        onChangeKpi={onChangeKpi}
                                        disabled={disabled}
                                    />
                                </div>
                                {filterOptions.length > 0 && (
                                    <div
                                        className={`flex flex-col gap-4 p-4 ${editable ? 'border-b border-layout-transparent' : ''}`}
                                    >
                                        <div className="flex items-center justify-between gap-4">
                                            <p
                                                className={`text-xl leading-6 ${editable ? 'pl-1' : 'pl-4 opacity-60'}`}
                                            >
                                                {t('Filters')}
                                            </p>
                                            {editable && (
                                                <BasicTooltip
                                                    tip={t('Add new filter')}
                                                    disabled={disabled}
                                                >
                                                    <Dropdown
                                                        disabled={disabled}
                                                        onlyIcon
                                                        variant="black"
                                                        icon={<Plus />}
                                                        selectedOption={null}
                                                        setSelectedOption={
                                                            handleAddFilter
                                                        }
                                                        options={filterOptions.map(
                                                            (option) =>
                                                                t(
                                                                    capitalizeFirstLetter(
                                                                        option.key,
                                                                    ),
                                                                ),
                                                        )}
                                                        hiddenOptions={
                                                            hiddenFilterOptions
                                                        }
                                                    />
                                                </BasicTooltip>
                                            )}
                                        </div>
                                        {filters.map((option, index) => {
                                            return (
                                                <KpiFormulaItemFilters
                                                    key={index}
                                                    option={option}
                                                    elementId={id}
                                                    formula={formula}
                                                    hiddenOptions={
                                                        hiddenFilterOptions
                                                    }
                                                    filterOptions={
                                                        filterOptions
                                                    }
                                                    editable={editable}
                                                    onChangeKpi={onChangeKpi}
                                                    disabled={disabled}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                                {editable && (
                                    <div className="flex justify-center p-1">
                                        <BasicTooltip
                                            tip={t('Delete column')}
                                            disabled={
                                                disabled ||
                                                Object.keys(formula.elements)
                                                    .length === 1
                                            }
                                        >
                                            <div
                                                className={`group flex h-8 w-8 cursor-pointer items-center justify-center ${
                                                    disabled ||
                                                    Object.keys(
                                                        formula.elements,
                                                    ).length === 1
                                                        ? 'pointer-events-none'
                                                        : ''
                                                }`}
                                                onClick={handleDeleteElement}
                                            >
                                                <Trash2 className="h-5 w-5 opacity-60 group-hover:opacity-100" />
                                            </div>
                                        </BasicTooltip>
                                    </div>
                                )}
                            </div>
                        </div>
                        <KpiFormulaItemSign
                            item={item}
                            elementId={id}
                            formula={formula}
                            column={column}
                            index={index}
                            editable={editable}
                            onChangeKpi={onChangeKpi}
                            disabled={disabled}
                            dragging={snapshot.isDragging}
                        />
                    </div>
                );
            }}
        </Draggable>
    );
};

KpiFormulaItem.propTypes = {
    item: PropTypes.object,
    formula: PropTypes.object,
    column: PropTypes.object,
    editable: PropTypes.bool,
    onChangeKpi: PropTypes.func,
    disabled: PropTypes.bool,
    index: PropTypes.bool,
};

KpiFormulaItem.defaultProps = {
    item: null,
    formula: null,
    column: null,
    editable: false,
    onChangeKpi: () => {},
    disabled: false,
    index: 0,
};

export default KpiFormulaItem;
