import React from 'react';

import PropTypes from 'prop-types';

const ExportTableFooter = ({ info, rowLength, textColor }) => {
    const labelColSpan = Math.max(1, Math.round(rowLength / 3));
    const infoColSpan = Math.max(1, rowLength - labelColSpan);

    const { description, elements } = info;

    return (
        <tfoot>
            <tr className="bg-white">
                <td
                    colSpan={labelColSpan}
                    className="border border-black"
                    data-pdfmake={JSON.stringify({
                        fillColor: '#FFFFFF',
                        margin: [0, 0, 0, -5],
                    })}
                >
                    <p
                        className="px-0.5 py-1 leading-7"
                        style={{
                            textAlign: 'center',
                            fontSize: '15px',
                            fontWeight: 'bold',
                            color: textColor,
                        }}
                    >
                        {description}
                    </p>
                </td>
                <td
                    colSpan={infoColSpan}
                    className="border border-black"
                    data-pdfmake={JSON.stringify({
                        fillColor: '#FFFFFF',
                        margin: [0, 0, 0, -5],
                    })}
                >
                    <div className="flex flex-col items-end justify-end">
                        {elements.map((entry, index) => {
                            const { label, content } = entry;

                            return (
                                <p
                                    key={index}
                                    className="px-0.5 py-1 leading-7"
                                    style={{
                                        fontSize: '15px',
                                        color: textColor,
                                    }}
                                >
                                    {label}:{' '}
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {content}
                                    </span>
                                </p>
                            );
                        })}
                    </div>
                </td>
            </tr>
        </tfoot>
    );
};

ExportTableFooter.propTypes = {
    info: PropTypes.object,
    rowLength: PropTypes.number,
    textColor: PropTypes.string,
};

ExportTableFooter.defaultProps = {
    info: { description: null, elements: [] },
    rowLength: 0,
    textColor: null,
};

export default ExportTableFooter;
