import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, makeStyles, TableCell, TableRow } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import API from 'utils/axios';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
    };
});

const ResourcesAccessGroupsRow = ({ group, callback }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [isAction, setIsAction] = useState(false);

    const styles = useStyles();

    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '43',
            permissionType: 'ALL',
        },
    ]);

    const handleEditTemplate = () => {
        history.push(
            configurator.base +
                configurator.resourcesAccessGroups.base +
                configurator.resourcesAccessGroups.update +
                '/' +
                group.id,
        );
    };

    const handleDeleteTemplate = async () => {
        try {
            setIsAction(true);

            await API.delete('group', {
                params: {
                    id: group.id,
                },
            });
            enqueueSnackbar(t('The group was deleted successfully!'), {
                variant: 'success',
            });

            callback();
        } catch (error) {
            enqueueSnackbar(error.errors[0], { variant: 'error' });
        } finally {
            setIsAction(false);
        }
    };

    return (
        <TableRow>
            <TableCell>{group.name}</TableCell>
            <TableCell>{group.userGroups.length}</TableCell>
            <TableCell>
                {canAll ? (
                    <div className="flex justify-end gap-2">
                        <div className="flex">
                            <Button
                                onClick={handleEditTemplate}
                                size="small"
                                color="primary"
                                disabled={isAction}
                            >
                                {t('Edit group')}
                            </Button>
                        </div>
                        <div className="flex">
                            <Button
                                onClick={handleDeleteTemplate}
                                size="small"
                                className={styles.error}
                                disabled={isAction}
                            >
                                {t('Delete group')}
                            </Button>
                        </div>
                    </div>
                ) : (
                    '&nbsp;'
                )}
            </TableCell>
        </TableRow>
    );
};

ResourcesAccessGroupsRow.propTypes = {
    group: PropTypes.object,
    callback: PropTypes.func,
};

ResourcesAccessGroupsRow.defaultProps = {
    group: {},
    callback: () => {},
};

export default ResourcesAccessGroupsRow;
