import M2MLogo from '../assets/common/m2m-logo.png';
import RaisisLogo from '../assets/common/raisis-logo.png';

import ExcelJS from 'exceljs';
import { defaultTheme, getRawImageFromPath, rgbToHex } from 'utils';
import { mergeExcelCellStyles } from 'utils/exportUtils';
import { getCompanyDetails } from 'utils/getterFunctions';

const exportExcel = async (
    alias,
    fileName,
    title,
    columns,
    rows,
    lastRow,
    applyRowStyles = null,
) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(alias);

        // ? We create the headers content
        worksheet.columns = columns;

        // ? We add the info content
        worksheet.spliceRows(1, 0, ['', title]);
        worksheet.mergeCells(1, 2, 1, worksheet.columnCount);

        // ? We create the excel rows
        rows.forEach((row) => worksheet.addRow(row));

        const {
            logoUrl,
            ThemeConfigurator: themes,
            activeThemeId,
        } = await getCompanyDetails();

        // ? We get the M2M, RaisisSoftware and company logos and we add them to the workbook
        const imagesExcel = [M2MLogo, RaisisLogo, logoUrl];
        const imagesExcelPromises = imagesExcel.map((imagePath) =>
            getRawImageFromPath(imagePath),
        );
        const [m2mLogoBase64, raisisLogoBase64, companyLogoBase64] =
            await Promise.all([...imagesExcelPromises]);

        // ? We add the image to the workbook
        const companyLogoId = workbook.addImage({
            base64: companyLogoBase64,
            extension: 'png',
        });
        // ? We add the image inside a cell of the worksheet
        worksheet.addImage(companyLogoId, 'A1:A1');

        const m2mLogoId = workbook.addImage({
            base64: m2mLogoBase64,
            extension: 'png',
        });

        const raisisLogoId = workbook.addImage({
            base64: raisisLogoBase64,
            extension: 'png',
        });

        if (lastRow) {
            worksheet.addRow(lastRow);
            worksheet.mergeCells(
                worksheet.rowCount,
                2,
                worksheet.rowCount,
                worksheet.columnCount,
            );
        }

        // ? We add an empty row at the end in order to insert M2M and RaisisSoftware logos
        worksheet.addRow(
            Array.from({ length: worksheet.columnCount }, () => ''),
        );

        let theme = JSON.parse(defaultTheme.theme);
        if (themes.length > 0) {
            theme = JSON.parse(
                themes.find((theme) => theme.id === activeThemeId).theme,
            );
        }

        // ? Default styles for the header of the table
        const infoStyles = {
            backgroundColor: rgbToHex(
                ...theme['PDF-COLORS']['PDF-LAYOUT-BACKGROUND'].color.split(
                    ' ',
                ),
            ),
        };

        const headerStyles = {
            backgroundColor: rgbToHex(
                ...theme['STATE-COLORS']['DISABLED'].color.split(' '),
            ),
        };

        // ? Default styles for the cells in the rows of the table
        const infoCellStyles = {
            color: rgbToHex(
                ...theme['PDF-COLORS']['PDF-LAYOUT-TEXT'].color.split(' '),
            ),
        };

        // ? We add default styles for each row
        worksheet.eachRow({ includeEmpty: true }, (row, iRow) => {
            // ? If is the last row we add a height of 50 in order to fit the M2M and RaisisSoftware logos
            if (iRow === worksheet.rowCount || iRow < 3) {
                row.height = 50;
            }

            // ? We add styles for each cell in every row of the export
            row.eachCell({ includeEmpty: true }, (cell, iCell) => {
                // ? If is the first row we add the necessary styles for the header
                if (iRow === 1) {
                    cell.font = {
                        color: {
                            argb: `FF${infoCellStyles.color.slice(1, infoCellStyles.color.length)}`,
                        },
                    };

                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: `FF${infoStyles.backgroundColor.slice(1, infoStyles.backgroundColor.length)}`,
                        },
                    };

                    cell.border = {
                        top: { style: 'thin', color: { argb: 'FFD3D3D3' } },
                        left: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                        bottom: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                        right: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                    };

                    cell.alignment = {
                        horizontal: 'center',
                        vertical: 'middle',
                    };

                    if (iCell !== 1) {
                        cell.font = { ...cell.font, size: 27 };
                        cell.alignment = {
                            ...cell.alignment,
                            horizontal: 'right',
                        };
                    }
                } else if (iRow === 2) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: `FF${headerStyles.backgroundColor.slice(1, headerStyles.backgroundColor.length)}`,
                        },
                    };

                    cell.border = {
                        top: { style: 'thin', color: { argb: 'FFD3D3D3' } },
                        left: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                        bottom: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                        right: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                    };

                    cell.alignment = {
                        vertical: 'middle',
                        horizontal: 'center',
                    };

                    mergeExcelCellStyles(cell, iCell - 1, columns);
                }
                // ? If the current row is not the last row we only add the proper borders, otherwise we add the footer styles
                else if (iRow !== worksheet.rowCount) {
                    cell.border = {
                        top: { style: 'thin', color: { argb: 'FFD3D3D3' } },
                        left: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                        bottom: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                        right: {
                            style: 'thin',
                            color: { argb: 'FFD3D3D3' },
                        },
                    };

                    cell.alignment = {
                        vertical: 'middle',
                        horizontal: 'center',
                    };

                    if (
                        lastRow &&
                        iCell !== 1 &&
                        iRow === worksheet.rowCount - 1
                    ) {
                        row.height = 100;

                        cell.font = {
                            bold: true,
                        };

                        cell.alignment = {
                            ...cell.alignment,
                            horizontal: 'right',
                        };
                    }
                }
                // ? If is the last row we add the necessary styles for cells of the logos
                else {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: `FF${infoStyles.backgroundColor.slice(1, infoStyles.backgroundColor.length)}`,
                        },
                    };

                    cell.border = {
                        top: {
                            style: 'thin',
                            color: {
                                argb: `FF${infoStyles.backgroundColor.slice(
                                    1,
                                    infoStyles.backgroundColor.length,
                                )}`,
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                argb: `FF${infoStyles.backgroundColor.slice(
                                    1,
                                    infoStyles.backgroundColor.length,
                                )}`,
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                argb: `FF${infoStyles.backgroundColor.slice(
                                    1,
                                    infoStyles.backgroundColor.length,
                                )}`,
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                argb: `FF${infoStyles.backgroundColor.slice(
                                    1,
                                    infoStyles.backgroundColor.length,
                                )}`,
                            },
                        },
                    };

                    cell.alignment = {
                        vertical: 'middle',
                        horizontal: 'center',
                    };
                }

                if (
                    iRow > 2 &&
                    iRow < worksheet.rowCount - Number(Boolean(lastRow)) &&
                    applyRowStyles
                ) {
                    applyRowStyles(cell, iCell - 1, row, iRow - 3);
                }
            });
        });

        // ? We add the logo inside a cell of the worksheet
        worksheet.addImage(m2mLogoId, {
            tl: { col: 0, row: worksheet.rowCount - 1 },
            br: { col: 1, row: worksheet.rowCount },
            hyperlinks: {
                hyperlink: 'https://www.m2msolutions.ro',
                tooltip: 'https://www.m2msolutions.ro',
            },
        });

        // ! Inserting a image into the last row of the document will create a new additional row, which i believe is a bug in the library
        // ! For now we will delete the new row, but this might be fixed in the future. When this happens this line will be removed
        worksheet.spliceRows(worksheet.rowCount, 1);

        // ? We add the logo inside a cell of the worksheet
        worksheet.addImage(raisisLogoId, {
            tl: {
                col: worksheet.columnCount - 1,
                row: worksheet.rowCount - 1,
            },
            br: { col: worksheet.columnCount, row: worksheet.rowCount },
            hyperlinks: {
                hyperlink: 'https://raisissoftware.com',
                tooltip: 'https://raisissoftware.com',
            },
        });

        // ! Inserting a image into the last row of the document will create a new additional row, which i believe is a bug in the library
        // ! For now we will delete the new row, but this might be fixed in the future. When this happens this line will be removed
        worksheet.spliceRows(worksheet.rowCount, 1);

        // ? We create the URL object of the export and download it
        const buffer = await workbook.xlsx.writeBuffer(`${fileName}.xlsx`);
        const blob = new Blob([buffer]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        return blob;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export default exportExcel;
