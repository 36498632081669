import React, { createContext, Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import GlobalContext from 'contexts/GlobalContext';
import useScreenSizes from 'hooks/useScreenSizes';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, Header, LabelWrapper, Toggle } from 'RaisisComponents';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { errorHandling, generateUUID } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

import ConfiguratorColumn from './documents_items/configurator-column';
import ElementsColumn from './documents_items/elements-column';

const useStyles = makeStyles(() => {
    return {
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
        success: {
            backgroundColor: `var(--success)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--success-light)`,
            },
        },
    };
});

const configuratorElements = {
    title: {
        id: 'title',
        itemType: 'title',
        isUsed: false,
        isMultiLanguage: true,
        value: '',
        flagManualEdit: true,
        flagDisabled: false,
        templateElements: null,
        pairKey: null,
        purpose: 'default',
        referenceId: null,
        entity: [],
    },
    subtitle: {
        id: 'subtitle',
        itemType: 'subtitle',
        isUsed: false,
        isMultiLanguage: true,
        value: '',
        flagManualEdit: true,
        flagDisabled: false,
        templateElements: null,
        pairKey: null,
        purpose: 'default',
        referenceId: null,
        entity: [],
    },
    content: {
        id: 'content',
        itemType: 'content',
        isUsed: false,
        isMultiLanguage: true,
        value: '',
        flagManualEdit: true,
        flagDisabled: false,
        templateElements: null,
        pairKey: null,
        purpose: 'default',
        referenceId: null,
        entity: [],
    },
    image: {
        id: 'image',
        itemType: 'image',
        isUsed: false,
        isMultiLanguage: true,
        value: { blob: null, url: null },
        flagManualEdit: true,
        flagDisabled: false,
        templateElements: null,
        pairKey: null,
        purpose: 'default',
        referenceId: null,
        entity: [],
    },
    gallery: {
        id: 'gallery',
        itemType: 'gallery',
        isUsed: false,
        isMultiLanguage: true,
        value: [],
        flagManualEdit: true,
        flagDisabled: false,
        templateElements: null,
        pairKey: null,
        purpose: 'default',
        referenceId: null,
        entity: [],
    },
    table: {
        id: 'table',
        itemType: 'table',
        isUsed: false,
        isMultiLanguage: true,
        value: {
            columns: ['<p>Placeholder</p>'],
            rows: [['<p>Placeholder</p>']],
        },
        flagManualEdit: true,
        flagDisabled: false,
        templateElements: null,
        pairKey: null,
        purpose: 'default',
        referenceId: null,
        entity: [],
    },
    //     id: 'milestone',
    //     itemType: 'milestone',
    //     isUsed: false,
    //     isMultiLanguage: true,
    //     value: null,
    //     flagManualEdit: false,
    //     flagDisabled: false,
    //     templateElements: [
    //         {
    //             id: 'content',
    //             props: {
    //                 isMultiLanguage: true,
    //                 isUsed: true,
    //                 purpose: 'milestone',
    //                 entity: [
    //                     {
    //                         entityId: null,
    //                         data: {
    //                             viewMode: null,
    //                         },
    //                     },
    //                 ],
    //             },
    //         },
    //         {
    //             id: 'gallery',
    //             props: {
    //                 isMultiLanguage: false,
    //                 isUsed: true,
    //                 flagManualEdit: false,
    //                 purpose: 'milestone',
    //                 entity: [
    //                     {
    //                         entityId: null,
    //                         data: {
    //                             viewMode: null,
    //                         },
    //                     },
    //                 ],
    //             },
    //         },
    //     ],
    //     pairKey: null,
    //     purpose: null,
    //     referenceId: null,
    //     entity: [],
    // },
    milestones: {
        id: 'milestones',
        itemType: 'milestones',
        isUsed: false,
        isMultiLanguage: true,
        value: null,
        flagManualEdit: false,
        flagDisabled: false,
        templateElements: [
            {
                id: 'content',
                props: {
                    isMultiLanguage: true,
                    isUsed: true,
                    purpose: 'milestones',
                },
            },
            {
                id: 'gallery',
                props: {
                    isMultiLanguage: false,
                    isUsed: true,
                    purpose: 'milestones',
                },
            },
        ],
        pairKey: null,
        purpose: null,
        referenceId: null,
        entity: [],
    },
    offer: {
        id: 'offer',
        itemType: 'offer',
        isUsed: false,
        isMultiLanguage: true,
        value: null,
        flagManualEdit: false,
        flagDisabled: false,
        templateElements: [
            {
                id: 'content',
                props: {
                    isUsed: true,
                    purpose: 'offer',
                    entity: [],
                },
            },
            {
                id: 'signature',
                props: {
                    isUsed: true,
                    purpose: 'offer',
                    entity: [],
                },
            },
        ],
        pairKey: null,
        purpose: 'default',
        referenceId: null,
        entity: [],
    },
    signature: {
        id: 'signature',
        itemType: 'signature',
        isUsed: false,
        isMultiLanguage: true,
        value: '',
        flagManualEdit: true,
        flagDisabled: false,
        templateElements: null,
        pairKey: null,
        purpose: 'default',
        referenceId: null,
        entity: [],
    },
};

const defaultValues = {
    contextData: {},
    isMultiView: false,
    highlightPair: '',
    highlightReference: { id: '', referenceId: '' },
    setHighlightPair: {},
    setHighlightReference: () => {},
    handleRemoveItem: () => {},
    handleRemovePair: () => {},
    handleChangeItem: () => {},
};

export const ConfiguratorContext = createContext(defaultValues);

const columnElementsIds = [
    'title',
    'subtitle',
    'content',
    'image',
    'gallery',
    'table',
    // 'milestone',
    'milestones',
    'offer',
    'signature',
];
const columnElements = {
    id: 'column-elements',
    title: 'Configurator elements',
    elementsIds: columnElementsIds,
    isActive: null,
    isCompleted: null,
};

const AddDocumentTemplate = () => {
    const { id } = useParams();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const { setGlobalModalOpen, setGlobalModalChildren } = useContext(GlobalContext);

    const [width] = useScreenSizes();
    const [isMultiView, setIsMultiView] = useState(false);
    const [displayedColumn, setDisplayedColumn] = useState(0);
    const [highlightPair, setHighlightPair] = useState('');
    const [highlightReference, setHighlightReference] = useState({ id: '', referenceId: '' });

    const classes = useStyles();

    const queryAttr = 'data-rbd-drag-handle-draggable-id';
    const getDraggedDom = (draggableId) => {
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        return draggedDOM;
    };
    const [placeholderProps, setPlaceholderProps] = useState({});

    const columnInputRef = useRef(null);
    const [columnTitle, setColumnTitle] = useState('');
    const [isAddingColumn, setIsAddingColumn] = useState(false);

    const [loading, setLoading] = useState(id ? true : false);
    const [isAction, setIsAction] = useState(false);
    const [templateName, setTemplateName] = useState('');

    const [mainColumnId, setMainColumnId] = useState(`column-main-${generateUUID()}`);

    const [contextData, setContextData] = useState({
        elements: {
            ...configuratorElements,
        },
        columns: {
            [mainColumnId]: {
                id: mainColumnId,
                title: t('Main language'),
                elementsIds: [],
                isActive: false,
                isCompleted: false,
            },
            'column-elements': columnElements,
        },
        columnOrder: [mainColumnId, 'column-elements'],
    });

    const numberOfColumns = contextData.columnOrder.length - 1;

    useEffect(() => {
        if (!id) return;

        (async () => {
            try {
                const templateRes = await API.get('docTemplate', {
                    params: {
                        id: id,
                    },
                });

                const rowContextData = templateRes.data;
                rowContextData.columnOrder.push('column-elements');

                Object.keys(rowContextData.elements).forEach((key) => {
                    rowContextData.elements[key].value = JSON.parse(rowContextData.elements[key].value);
                    rowContextData.elements[key].entity = rowContextData.elements[key].entity.map((entry) => ({
                        ...entry,
                        data: JSON.parse(entry.data),
                    }));
                });

                const frontContextData = {
                    ...rowContextData,
                    columns: { ...rowContextData.columns, 'column-elements': { ...columnElements } },
                    elements: { ...rowContextData.elements, ...configuratorElements },
                };

                setTemplateName(templateRes.data.name);
                setMainColumnId(templateRes.data.columnOrder.find((columnId) => columnId.includes('column-main')));
                setContextData(frontContextData);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    /**
     * The handleAddColumn function adds a new column with elements to the context data.
     * @param {object} e - Thr form object used to prevent the reloading of the page
     * @returns The function `handleAddColumn` returns nothing (`undefined`) if the column title is
     * empty, and returns a success message after adding a new column with elements to the context data.
     */
    const handleAddColumn = (e) => {
        e.preventDefault();

        if (columnTitle.trim().length === 0) {
            enqueueSnackbar(t('You must add the name of the column'), {
                variant: 'error',
            });
            return;
        }

        const newElements = contextData.elements;
        const newColumnElementsIds = [];
        const newColumnId = generateUUID();

        const elementsIdsOfTheMainColumn = contextData.columns[mainColumnId].elementsIds;
        const cachedPairKeys = [];

        //? We create a copy the elements of the main column for the new column
        for (let i = 0; i < elementsIdsOfTheMainColumn.length; i++) {
            const currentElement = contextData.elements[elementsIdsOfTheMainColumn[i]];

            const elementId = `element-${generateUUID()}-column-${newColumnId}`;
            newColumnElementsIds.push(elementId);

            //? We cache the newly created pair keys in order to assign them to the corresponding items
            const currentElementPairKey = currentElement.pairKey;
            let newElementPairKey = null;
            if (currentElementPairKey) {
                const cachedPairKeyExists = cachedPairKeys.find((pair) => pair.old === currentElementPairKey);

                if (cachedPairKeyExists) {
                    newElementPairKey = cachedPairKeyExists.new;
                } else {
                    newElementPairKey = generateUUID();
                    cachedPairKeys.push({ old: currentElementPairKey, new: newElementPairKey });
                }
            }

            newElements[elementId] = {
                ...currentElement,
                pairKey: newElementPairKey,
                id: elementId,
                referenceId: currentElement.id,
            };
        }

        const newColumn = {
            id: `column-${newColumnId}`,
            title: columnTitle,
            elementsIds: newColumnElementsIds,
            isActive: false,
            isCompleted: false,
        };

        const newCurrentContext = {
            ...contextData,
            elements: newElements,
            columns: { ...contextData.columns, [newColumn.id]: newColumn },
            columnOrder: [
                ...contextData.columnOrder.slice(0, contextData.columnOrder.length - 1),
                newColumn.id,
                contextData.columnOrder.at(-1),
            ],
        };

        setContextData(newCurrentContext);
        setColumnTitle('');
        setIsAddingColumn(false);

        enqueueSnackbar(t('Column added successfully'), {
            variant: 'success',
        });
    };

    /**
     * The function `handleDeleteColumn` deletes a column from the context data and updates the state
     * accordingly.
     * @param {string} columnId - The column id used to find and delete the column
     * @param {number} columnIdx - Is used to determine if this columns is currently being displayed
     * @returns {undefined}
     */
    const handleDeleteColumn = (columnId, columnIdx) => {
        const newElements = {};

        //? We recreate the elements object without the elements of the column to be deleted
        Object.entries(contextData.elements).forEach(
            ([key, value]) => !key.includes(columnId.split('column-')[1]) && (newElements[key] = value),
        );

        const newColumns = contextData.columns;
        delete newColumns[columnId];

        const newCurrentContext = {
            ...contextData,
            elements: newElements,
            columns: newColumns,
            columnOrder: contextData.columnOrder.filter((cId) => cId !== columnId),
        };

        //? If the current column is currently being displayed, we have to display the previous column
        if (columnIdx === displayedColumn) setDisplayedColumn((prev) => --prev);
        setContextData(newCurrentContext);

        enqueueSnackbar(t('Column deleted successfully'), {
            variant: 'success',
        });
    };

    /**
     * The function `handleToggleIsCompletedColumn` toggles the `isCompleted` and `isActive` properties
     * of a column in the context data object.
     * @prop {string} columnId - The column id of the column to be updated
     * @returns {undefined}
     */
    const handleToggleIsCompletedColumn = (columnId) => {
        const newColumns = structuredClone(contextData.columns);

        //? We update the isComplete prop (the column having it's translation completed or not) and the isActive prop, because a column can only be active when the translation completed
        newColumns[columnId].isCompleted = !newColumns[columnId].isCompleted;
        newColumns[columnId].isActive = !newColumns[columnId].isActive;

        const newCurrentContext = {
            ...contextData,
            columns: newColumns,
        };

        setContextData(newCurrentContext);
    };

    /**
     * The function `handlePrevColumn` updates the displayed column by showing the previous one.
     */
    const handlePrevColumn = () => setDisplayedColumn((prev) => (prev === 0 ? 0 : --prev));

    /**
     * The function `handleNextColumn` updates the displayed column by showing the next one.
     */
    const handleNextColumn = () =>
        setDisplayedColumn((prev) => (prev === numberOfColumns - 1 ? numberOfColumns - 1 : ++prev));

    /**
     * The `handleRemoveItem` function removes an element and its references from a data structure and
     * updates the context accordingly.
     * @returns {undefined}
     */
    const handleRemoveItem = (elementId) => {
        const newElements = structuredClone(contextData.elements);

        // ? We delete the element on main columns
        delete newElements[elementId];

        // ? We delete the element on every other columns
        const referencesIds = Object.values(newElements)
            .filter((el) => el.referenceId === elementId)
            .map((el) => el.id);

        for (const elId of referencesIds) {
            delete newElements[elId];
        }

        // ? We remove the element form each column and we update it
        const newColumns = structuredClone(contextData.columns);
        [elementId, ...referencesIds].forEach((elId) => {
            const columnId = elId.split('column-')[1];
            const newElementIds = newColumns[`column-${columnId}`].elementsIds.filter((eI) => eI !== elId);

            newColumns[`column-${columnId}`] = {
                ...newColumns[`column-${columnId}`],
                elementsIds: newElementIds,
                isCompleted: newElementIds.length === 0 ? false : newColumns[`column-${columnId}`].isCompleted,
                isActive: newElementIds.length === 0 ? false : newColumns[`column-${columnId}`].isActive,
            };
        });

        const newCurrentContext = {
            ...contextData,
            elements: newElements,
            columns: newColumns,
        };

        setContextData(newCurrentContext);
    };

    /**
     * The function `handleRemovePair` removes a pair of elements and their references from a data
     * structure and updates the context accordingly.
     */
    const handleRemovePair = (pairKey) => {
        const newElements = structuredClone(contextData.elements);

        //? We get the elements ids with this pair
        const pairElementsIds = Object.values(newElements)
            .filter((el) => el.pairKey === pairKey)
            .map((el) => el.id);

        //? We delete the elements with this pair on the main column
        for (const elementId of pairElementsIds) {
            delete newElements[elementId];
        }

        // ? We delete the element on every other columns
        const referencesIds = Object.values(newElements)
            .filter((el) => pairElementsIds.includes(el.referenceId))
            .map((el) => el.id);

        for (const elId of referencesIds) {
            delete newElements[elId];
        }

        // ? We remove the element form each column and we update it
        const newColumns = structuredClone(contextData.columns);
        [...pairElementsIds, ...referencesIds].forEach((elId) => {
            const columnId = elId.split('column-')[1];
            const newElementIds = newColumns[`column-${columnId}`].elementsIds.filter((eI) => eI !== elId);

            newColumns[`column-${columnId}`] = {
                ...newColumns[`column-${columnId}`],
                elementsIds: newElementIds,
                isCompleted: newElementIds.length === 0 ? false : newColumns[`column-${columnId}`].isCompleted,
                isActive: newElementIds.length === 0 ? false : newColumns[`column-${columnId}`].isActive,
            };
        });

        const newCurrentContext = {
            ...contextData,
            elements: newElements,
            columns: newColumns,
        };
        setContextData(newCurrentContext);
    };

    /**
     * The `handleChangeItem` function updates a specific key-value pair in an element object and
     * propagates the change to related elements if applicable.
     * @param {string} elementId - The id of the element to be updated
     * @param {string} key - The field to be updated
     * @param {string|number|object|null} value - The new value
     * @returns {undefined}
     */
    const handleChangeItem = (elementId, key, value) => {
        const newElements = structuredClone(contextData.elements);
        newElements[elementId][key] = value;

        //? We only update the elements in the other columns if the key is different from value and entity
        if (key !== 'value' && key !== 'entity') {
            const referencesIds = Object.values(newElements)
                .filter((el) => el.referenceId === elementId)
                .map((el) => el.id);

            for (const elId of referencesIds) {
                newElements[elId][key] = value;
            }
        }

        const newCurrentContext = {
            ...contextData,
            elements: newElements,
        };

        setContextData(newCurrentContext);
    };

    /**
     * The function `handleConfiguratorMove` updates the column order in the context data based on the
     * source, destination, and draggable ID.
     * @param {object} source - The object containing the information about the start of the dragging
     * @param {object} destination - The object containing the information about the end of the dragging
     * @param {string} draggableId - The id of the dragged column
     * @returns {undefined}
     */
    const handleConfiguratorMove = (source, destination, draggableId) => {
        const newColumnOrder = Array.from(contextData.columnOrder);
        newColumnOrder.splice(source.index, 1);
        newColumnOrder.splice(destination.index, 0, draggableId);

        const newCurrentContext = {
            ...contextData,
            columnOrder: newColumnOrder,
        };

        setContextData(newCurrentContext);
    };

    /**
     * The function `handleElementsMove` handles the movement of elements between
     * columns and updates the context data accordingly.
     * @param {object} source - The object containing the information about the start of the dragging
     * @param {object} destination - The object containing the information about the end of the dragging
     * @param {string} draggableId - The id of the dragged element
     * @returns {undefined} The `handleElementsMove` function is returning undefined after updating the
     * context data and setting the new context data using `setContextData`.
     */
    const handleElementsMove = (source, destination, draggableId) => {
        const start = contextData.columns[source.droppableId];
        const finish = contextData.columns[destination.droppableId];
        const newColumns = structuredClone(contextData.columns);

        //? If the moved elements are on the same column we have a specific logic just for that
        if (start.id === finish.id) {
            //? When the move is on the elements column, we use a basic mutation logic for that column
            if (start.id === 'column-elements') {
                const newElementsIds = Array.from(start.elementsIds);
                newElementsIds.splice(source.index, 1);
                newElementsIds.splice(destination.index, 0, draggableId);
                newColumns[start.id].elementsIds = newElementsIds;
            }
            //? When the move is on any other column we have to move the element on every column in order to maintain the document structure
            else {
                contextData.columnOrder.slice(0, numberOfColumns).forEach((columnId) => {
                    const column = newColumns[columnId];
                    const newElementsIds = Array.from(column.elementsIds);
                    newElementsIds.splice(source.index, 1);

                    const elementId =
                        start.id === columnId
                            ? draggableId
                            : Object.values(contextData.elements).find((element) => element.referenceId === draggableId)
                                  .id;

                    newElementsIds.splice(destination.index, 0, elementId);
                    newColumns[columnId].elementsIds = newElementsIds;
                });
            }

            const newCurrentContext = {
                ...contextData,
                columns: newColumns,
            };

            setContextData(newCurrentContext);
            return;
        }

        const newElements = structuredClone(contextData.elements);
        const templateElements = contextData.elements[draggableId].templateElements;

        const columnOrder = Array.from(contextData.columnOrder);
        //? We make the main column to be the first in order to properly assign the reference id
        const mainColumnIdx = columnOrder.findIndex((column) => column.includes('column-main'));
        [([columnOrder[0], columnOrder[mainColumnIdx]] = [columnOrder[mainColumnIdx], columnOrder[0]])];

        if (templateElements) {
            let newReferenceIds = Array.from({ length: templateElements.length }, () => null);

            columnOrder.slice(0, numberOfColumns).forEach((columnId, index) => {
                const column = newColumns[columnId];
                const pairKey = templateElements.length > 1 ? generateUUID() : null;

                for (let i = 0; i < templateElements.length; i++) {
                    const { id: elementId, props: elementProps } = templateElements[i];

                    const newElementId = `element-${generateUUID()}-${columnId}`;
                    //? We create the references using the ids of the elements from the main column
                    if (index === 0) newReferenceIds[i] = newElementId;
                    const newElementsIds = Array.from(column.elementsIds);
                    newElementsIds.splice(destination.index + i, 0, newElementId);
                    newElements[newElementId] = {
                        ...contextData.elements[elementId],
                        ...elementProps,
                        id: newElementId,
                        pairKey,
                        //? We only assign the reference when we are not on the main column
                        referenceId: index !== 0 ? newReferenceIds[i] : null,
                    };
                    newColumns[columnId].elementsIds = newElementsIds;
                }
            });
        }

        if (!templateElements) {
            let newReferenceId = null;

            columnOrder.slice(0, numberOfColumns).forEach((columnId, index) => {
                const column = newColumns[columnId];
                const newElementId = `element-${generateUUID()}-${columnId}`;
                //? We create the references using the id of the element from the main column
                if (index === 0) newReferenceId = newElementId;
                const newElementsIds = Array.from(column.elementsIds);
                newElementsIds.splice(destination.index, 0, newElementId);

                newElements[newElementId] = {
                    ...contextData.elements[draggableId],
                    id: newElementId,
                    isUsed: true,
                    //? We only assign the reference when we are not on the main column
                    referenceId: index !== 0 ? newReferenceId : null,
                };
                newColumns[columnId].elementsIds = newElementsIds;
            });
        }

        const newCurrentContext = {
            ...contextData,
            elements: newElements,
            columns: newColumns,
        };

        setContextData(newCurrentContext);
    };

    /**
     * The function `handleOnDragEnd` in handles drag and drop events for different
     * types of draggable elements.
     * @returns {undefined}
     */
    const handleOnDragEnd = (result) => {
        const { source, destination, draggableId, type } = result;

        //? We clear the placeholder object when the start of the drag action is the elements column
        if (source.droppableId === 'column-elements') setPlaceholderProps({});

        //? We return is the element is dropped in the same position as it started
        if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index))
            return;

        //? We call the correct function based on the type of the drag action
        //! `configurator` for the move of a column
        if (type === 'configurator') {
            handleConfiguratorMove(source, destination, draggableId);
            return;
        }

        //! `elements` for the move of a column
        if (type === 'elements') {
            handleElementsMove(source, destination, draggableId);
            return;
        }
    };

    /**
     * The `handleOnDragStart` function sets placeholder properties based on the
     * dragged element's position within a column.
     * @returns {undefined}
     */
    const handleOnDragStart = (start) => {
        const { source, draggableId } = start;

        const draggedDOM = getDraggedDom(draggableId);

        if (!draggedDOM) return;

        //? The placeholder is being set only when the start of the drop action is the elements column
        if (source.droppableId === 'column-elements') {
            const { clientHeight, clientWidth } = draggedDOM;
            const sourceIndex = start.source.index;
            const clientY =
                parseFloat(getComputedStyle(draggedDOM.parentNode).paddingTop) +
                [...draggedDOM.parentNode.children].slice(0, sourceIndex).reduce((total, curr) => {
                    const style = curr.currentStyle || getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

            setPlaceholderProps({
                clientHeight,
                clientWidth,
                clientY,
                clientX: parseFloat(getComputedStyle(draggedDOM.parentNode).paddingLeft),
            });
        }
    };

    /**
     * The handleDragUpdate function in JavaScript React is used to update the position of a dragged
     * element within a list based on drag and drop interactions.
     * @returns {undefined}
     */
    const handleDragUpdate = (update) => {
        const { destination, source, draggableId } = update;

        //? The placeholder is being updated only when the start of the drop action is the elements column
        if (source.droppableId === 'column-elements') {
            const draggedDOM = getDraggedDom(draggableId);

            if (!draggedDOM || !destination) return;

            const { clientHeight, clientWidth } = draggedDOM;
            const destinationIndex = destination.index;
            const sourceIndex = source.index;

            const childrenArray = [...draggedDOM.parentNode.children];
            const movedItem = childrenArray[sourceIndex];
            childrenArray.splice(sourceIndex, 1);

            const updatedArray = [
                ...childrenArray.slice(0, destinationIndex),
                movedItem,
                ...childrenArray.slice(destinationIndex + 1),
            ];

            const clientY =
                parseFloat(getComputedStyle(draggedDOM.parentNode).paddingTop) +
                updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
                    const style = curr.currentStyle || getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);
                    return total + curr.clientHeight + marginBottom;
                }, 0);

            setPlaceholderProps({
                clientHeight,
                clientWidth,
                clientY,
                clientX: parseFloat(getComputedStyle(draggedDOM.parentNode).paddingLeft),
            });
        }
    };

    const schema = yup.object().shape({
        name: yup
            .string()
            .min(3, t('The template name length must be at least 3 characters long'))
            .required(t('The template name is required')),
        columns: yup
            .array()
            .of(
                yup
                    .number()
                    .min(1, t('The column must have at least one element'))
                    .required(t('The column must have at least one element')),
            )
            .min(1, t('The template must have at least one column'))
            .required(t('The template must have at least one column')),
    });

    const handleAddOrUpdateTemplate = async () => {
        try {
            setIsAction(true);

            const newElements = structuredClone(contextData.elements);
            //? The images array holds the images that will be processed by multer
            const images = [];
            //? The filesMetadata array holds the information about each image, such as the element id or the position in the gallery
            const filesMetadata = [];

            for (const key of Object.keys(newElements)) {
                const element = newElements[key];

                if (columnElementsIds.includes(key)) delete newElements[key];
                else if (element.itemType === 'image') {
                    //? If we don't have a new image, we only assign the previews value
                    if (!element.value.blob) {
                        element.value = { blob: null, url: element.value.url };
                        element.entity = element.entity.map((entry) => ({
                            ...entry,
                            data: entry.data,
                        }));
                        continue;
                    }
                    //? If we have a new image, we create a new image and filesMetadata entry
                    const file = element.value.blob;
                    images.push(file);
                    filesMetadata.push({
                        id: element.id,
                        position: null,
                    });
                    //? We set the value to null, because we can't send images (base64) and blobs as JSON.
                    //? We will set the url back on the backend after the upload to S3Bucket.
                    element.value = { blob: null, url: null };
                    element.entity = element.entity.map((entry) => ({
                        ...entry,
                        data: entry.data,
                    }));
                } else if (element.itemType === 'gallery') {
                    const newElementValue = [];

                    for (let i = 0; i < element.value.length; i++) {
                        const value = element.value[i];
                        //? If we don't have a new image, we only assign the previews value
                        if (!value.blob) {
                            newElementValue.push({ blob: null, url: value.url });
                            continue;
                        }

                        //? If we have a new image, we create a new image and filesMetadata entry
                        const file = value.blob;
                        images.push(file);
                        filesMetadata.push({
                            id: element.id,
                            position: i,
                        });
                        //? We set the value to null, because we can't send images (base64) and blobs as JSON.
                        //? We will set the url back on the backend after the upload to S3Bucket.
                        newElementValue.push({ blob: null, url: null });
                    }
                    element.value = newElementValue;
                    element.entity = element.entity.map((entry) => ({
                        ...entry,
                        data: entry.data,
                    }));
                }
            }

            const newColumns = structuredClone(contextData.columns);
            delete newColumns['column-elements'];

            const newColumnOrder = structuredClone(contextData.columnOrder).filter(
                (column) => column !== 'column-elements',
            );

            const reqContextData = {
                ...contextData,
                elements: newElements,
                columns: newColumns,
                columnOrder: newColumnOrder,
            };

            await schema.validate({
                name: templateName,
                columns: Array.from(
                    { length: Array.from(reqContextData.columnOrder).length },
                    (_, i) => reqContextData.columns[reqContextData.columnOrder[i]].elementsIds.length,
                ),
            });

            const config = {
                'Content-Type': 'multipart/form-data',
            };

            const reqForm = new FormData();
            reqForm.append('name', templateName);
            if (id) reqForm.append('id', id);
            reqForm.append('data', JSON.stringify(reqContextData));
            reqForm.append('filesMetadata', JSON.stringify(filesMetadata));
            for (const image of images) {
                reqForm.append('images', image);
            }

            if (id) {
                await API.patch('docTemplates', reqForm, config);
                enqueueSnackbar(t('The template was update successfully'), { variant: 'success' });
            } else {
                await API.post('docTemplates', reqForm, config);
                enqueueSnackbar(t('The template was added successfully'), { variant: 'success' });
            }

            history.push(configurator.base + `?tab=${'Documents templates'}`);
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsAction(false);
        }
    };

    const handleStartAddOrUpdateTemplateProcess = () => {
        const foundActiveColumn = Object.values(contextData.columns).find((column) => column.isActive);

        if (foundActiveColumn) {
            handleAddOrUpdateTemplate();
            return;
        }

        setGlobalModalOpen(true);
        setGlobalModalChildren(
            <div className="flex-col">
                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                    {t("You didn't activate any of the languages.")}
                    <br />
                    {t('Are you sure you want to proceed?')}
                </p>
                <div className="flex items-center justify-center">
                    <Button
                        color="primary"
                        onClick={() => {
                            handleAddOrUpdateTemplate();
                            setGlobalModalOpen(false);
                        }}
                    >
                        {t('Yes')}
                    </Button>
                    <div className="w-4" />
                    <Button onClick={() => setGlobalModalOpen(false)}>{t('No')}</Button>
                </div>
            </div>,
        );
    };

    return (
        <>
            <Fragment>
                <Helmet>
                    <title>{t('Configurator template')}</title>
                </Helmet>

                <Header pageTitle={t('Configurator template')} />
            </Fragment>

            <div className={`page-container ${isAction ? 'pointer-events-none' : ''}`}>
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : (
                    <ConfiguratorContext.Provider
                        value={{
                            contextData,
                            isMultiView,
                            highlightPair,
                            highlightReference,
                            setHighlightPair,
                            setHighlightReference,
                            handleRemoveItem,
                            handleRemovePair,
                            handleChangeItem,
                        }}
                    >
                        <DragDropContext
                            onDragEnd={handleOnDragEnd}
                            onDragStart={handleOnDragStart}
                            onDragUpdate={handleDragUpdate}
                        >
                            <div className="flex flex-col gap-8">
                                <div className="flex max-w-full flex-grow flex-col gap-4 rounded-md bg-layout-light p-4">
                                    <h3 className="text-center text-2xl">{t('Configurator section')}</h3>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex flex-col gap-4">
                                            <div className="flex items-end gap-4 sm:flex-col sm:items-start">
                                                <div className="flex w-full max-w-sm sm:max-w-full">
                                                    <LabelWrapper label={t('Template name')}>
                                                        <TextField
                                                            placeholder={t('Template name')}
                                                            type="text"
                                                            value={templateName}
                                                            onChange={(e) => setTemplateName(e.target.value)}
                                                        />
                                                    </LabelWrapper>
                                                </div>

                                                <div className="flex w-max items-center gap-2 rounded-md bg-layout-transparent p-2.5 sm:w-full">
                                                    <Toggle checked={isMultiView} setChecked={setIsMultiView} />
                                                    <p>{t('See all languages')}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-2 rounded-md bg-layout-lighter p-2">
                                                <h4>{t('Document languages')}</h4>
                                                <div className="flex flex-wrap gap-2">
                                                    {contextData.columnOrder
                                                        .filter((columnId) => columnId !== 'column-elements')
                                                        .map((columnId, index) => {
                                                            const column = contextData.columns[columnId];

                                                            return (
                                                                <div
                                                                    key={column.id}
                                                                    className="flex flex-col gap-2 rounded-sm bg-layout-light p-2"
                                                                >
                                                                    <p>{column.title}</p>

                                                                    <Button
                                                                        onClick={() =>
                                                                            handleDeleteColumn(column.id, index)
                                                                        }
                                                                        endIcon={<DeleteIcon />}
                                                                        disabled={column.id === mainColumnId}
                                                                        className={classes.error}
                                                                        size="small"
                                                                    >
                                                                        {t('Delete language')}
                                                                    </Button>

                                                                    <BasicTooltip
                                                                        tip={t(
                                                                            'You can mark this language as being finalized when you have at least one element',
                                                                        )}
                                                                        disabled={
                                                                            contextData.columns[column.id].elementsIds
                                                                                .length > 0
                                                                        }
                                                                    >
                                                                        <div className="flex w-full items-center gap-2 rounded-md bg-layout-transparent p-2">
                                                                            <Toggle
                                                                                disabled={
                                                                                    contextData.columns[column.id]
                                                                                        .elementsIds.length === 0
                                                                                }
                                                                                checked={column.isCompleted}
                                                                                setChecked={() =>
                                                                                    handleToggleIsCompletedColumn(
                                                                                        column.id,
                                                                                    )
                                                                                }
                                                                            />
                                                                            <p>{t('Finished translation')}</p>
                                                                        </div>
                                                                    </BasicTooltip>
                                                                </div>
                                                            );
                                                        })}
                                                    {!isAddingColumn && (
                                                        <div>
                                                            <BasicTooltip
                                                                disabled={contextData.columns[mainColumnId].isCompleted}
                                                                tip={t(
                                                                    "You can't add a new language if the main language doesn't have its translation completed",
                                                                )}
                                                            >
                                                                <Button
                                                                    disabled={
                                                                        !contextData.columns[mainColumnId].isCompleted
                                                                    }
                                                                    style={{
                                                                        minWidth: '3rem',
                                                                        width: '3rem',
                                                                        height: '3rem',
                                                                    }}
                                                                    onClick={() => {
                                                                        setIsAddingColumn(true);
                                                                        setTimeout(() => {
                                                                            const actualInput =
                                                                                columnInputRef.current.querySelector(
                                                                                    'input',
                                                                                );
                                                                            actualInput.focus();
                                                                        });
                                                                    }}
                                                                >
                                                                    <AddIcon fontSize="large" />
                                                                </Button>
                                                            </BasicTooltip>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {isAddingColumn && (
                                            <form
                                                className="flex max-w-xl items-center gap-4 rounded-md bg-layout-lighter p-2 sm:flex-col"
                                                onSubmit={handleAddColumn}
                                            >
                                                <TextField
                                                    ref={columnInputRef}
                                                    placeholder={t('Language')}
                                                    type="string"
                                                    value={columnTitle}
                                                    onChange={(e) => setColumnTitle(e.target.value)}
                                                />

                                                <div className="ml-auto flex gap-2 sm:mx-auto">
                                                    <Button
                                                        color="secondary"
                                                        type="button"
                                                        onClick={() => {
                                                            setColumnTitle('');
                                                            setIsAddingColumn(false);
                                                        }}
                                                    >
                                                        {t('Cancel')}
                                                    </Button>
                                                    <Button color="primary" type="submit">
                                                        {t('Add language')}
                                                    </Button>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                </div>

                                <div className="flex gap-16 lg:gap-8 sm:gap-4">
                                    <Droppable
                                        droppableId="configurator"
                                        direction={
                                            isMultiView && numberOfColumns > 3
                                                ? 'vertical'
                                                : isMultiView && numberOfColumns > 2 && width <= 1500
                                                  ? 'vertical'
                                                  : isMultiView && numberOfColumns > 1 && width <= 1250
                                                    ? 'vertical'
                                                    : 'horizontal'
                                        }
                                        type="configurator"
                                    >
                                        {(provided) => {
                                            return (
                                                <div
                                                    className={`relative flex flex-grow gap-8 rounded-3xl border border-layout-light bg-dark-text p-8 ${
                                                        !isMultiView ? 'pt-14' : ''
                                                    } ${
                                                        isMultiView && numberOfColumns > 3
                                                            ? 'flex-col'
                                                            : isMultiView && numberOfColumns > 2
                                                              ? '2xl:flex-col'
                                                              : isMultiView && numberOfColumns > 1
                                                                ? 'xl:flex-col'
                                                                : ''
                                                    }`}
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {!isMultiView && (
                                                        <div className="absolute left-1/2 top-2 flex -translate-x-1/2 transform items-center gap-4 text-layout-lighter">
                                                            <ActionButton
                                                                size={9}
                                                                icon={<ChevronLeftIcon fontSize="large" />}
                                                                onClick={handlePrevColumn}
                                                                disabled={displayedColumn === 0}
                                                            />
                                                            <p className="whitespace-nowrap text-2xl text-layout-lighter sm:text-xl">
                                                                {
                                                                    contextData.columns[
                                                                        contextData.columnOrder[displayedColumn]
                                                                    ].title
                                                                }
                                                            </p>
                                                            <ActionButton
                                                                size={9}
                                                                icon={<ChevronRightIcon fontSize="large" />}
                                                                onClick={handleNextColumn}
                                                                disabled={displayedColumn === numberOfColumns - 1}
                                                            />
                                                        </div>
                                                    )}
                                                    {contextData.columnOrder
                                                        .slice(0, numberOfColumns)
                                                        .map((columnId, index) => {
                                                            if (!isMultiView && index !== displayedColumn) return null;

                                                            const column = contextData.columns[columnId];
                                                            const elements = column.elementsIds.map(
                                                                (elementId) => contextData.elements[elementId],
                                                            );

                                                            return (
                                                                <ConfiguratorColumn
                                                                    key={column.id}
                                                                    column={column}
                                                                    elements={elements}
                                                                    isMultiColumn={isMultiView && numberOfColumns > 1}
                                                                    index={index}
                                                                />
                                                            );
                                                        })}
                                                    {provided.placeholder}
                                                </div>
                                            );
                                        }}
                                    </Droppable>
                                    {(() => {
                                        if (
                                            !isMultiView &&
                                            contextData.columns[contextData.columnOrder[displayedColumn]].id !==
                                                mainColumnId
                                        )
                                            return null;

                                        const column = contextData.columns['column-elements'];
                                        const elements = column.elementsIds.map(
                                            (elementId) => contextData.elements[elementId],
                                        );

                                        return (
                                            <ElementsColumn
                                                key={column.id}
                                                column={column}
                                                elements={elements}
                                                placeholderProps={placeholderProps}
                                            />
                                        );
                                    })()}
                                </div>
                            </div>
                        </DragDropContext>

                        <div className="mt-10">
                            <Button color="primary" onClick={handleStartAddOrUpdateTemplateProcess} disabled={isAction}>
                                {t(id ? 'Edit template' : 'Save template')}
                            </Button>
                        </div>
                    </ConfiguratorContext.Provider>
                )}
            </div>
        </>
    );
};

AddDocumentTemplate.propTypes = {
    dynamicId: PropTypes.string,
};

AddDocumentTemplate.defaultProps = {
    dynamicId: null,
};

export default AddDocumentTemplate;
