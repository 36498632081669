import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { ChevronDown } from 'lucide-react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import GetAppIcon from '@material-ui/icons/GetApp';
import LinkIcon from '@material-ui/icons/Link';

import { Button } from '@material-ui/core';

import ActionArrow from 'components/internal-activity/action-arrow';
import BasicTooltip from 'components/shared/basic-tooltip';
import Comments from 'components/shared/comments/comments';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { configurator, internalActivity } from 'routes';
import {
    daysFrom,
    errorHandling,
    fileNameFromKey,
    formatDate,
    formatTime,
    linkStyle,
} from 'utils';
import API from 'utils/axios';

import PMTaskManagementSubmilestoneDisplay from './pm-task-management-submilestone';

const MAPPED_STATUS_ICONS = {
    TODO: null,
    DONE: (
        <CheckIcon
            className="text-buttons-text transition-opacity"
            style={{
                fontSize: '1rem',
            }}
        />
    ),
    FAILED: (
        <CloseIcon
            className="text-buttons-text transition-opacity"
            style={{
                fontSize: '1rem',
            }}
        />
    ),
};

const COMMENTS_ROUTES = {
    activities: 'activities_comments',
    subActivities: 'tasks_comments',
    tasks: 'tasks_comments',
};

const TaskManagementDisplay = (props) => {
    const {
        activity,
        deleteActivity,
        updateActivity,
        userLink,
        fetchActivities,
        onNotify,
        actionArrow,
        setSeeGantt,
    } = props;

    const confirm = useConfirm();

    const history = useHistory();
    const { checkPerm, user } = useContext(UserContext);
    const globalContext = useContext(GlobalContext);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const canAll = checkPerm([
        {
            permissionId: '19',
            permissionType: 'ALL',
        },
    ]);

    const userIsResponsible =
        canAll &&
        (user.id === activity.activityInfo.authorId ||
            activity.activityInfo.users
                .filter((u) => u.userType === 'RESPONSIBLE')
                .map((u) => u.userId)
                .includes(user.id));

    const responsible = activity.activityInfo.users
        .filter((u) => u.userType === 'RESPONSIBLE')
        .map((u) => u.user.profile.name)
        .join(', ');

    const supervisor = activity.activityInfo.users
        .filter((u) => u.userType === 'ACCEPTER')
        .map((u) => u.user.profile.name)
        .join(', ');

    const watcher = activity.activityInfo.users
        .filter((u) => u.userType === 'WATCHER')
        .map((u) => u.user.profile.name)
        .join(', ');

    const partner =
        activity.activityInfo.partners &&
        (typeof activity.activityInfo.partners.data === 'string'
            ? JSON.parse(activity.activityInfo.partners.data)
            : activity.activityInfo.partners.data);

    const formattedComments = useMemo(
        () =>
            activity.activityInfo.comments.map((element) => ({
                ...element,
                text: element.comment,
                createdAt: element.createAt,
                files: element.files.map((file) => ({
                    ...file,
                    id: file.fileId,
                    url: file.urls,
                    fileName: fileNameFromKey(file.file.key),
                })),
            })),
        [activity.activityInfo.comments],
    );

    const activityBorderColors = {
        COMMENT: 'border-blue-500',
        TASK: 'border-green-500',
        FILES: 'border-purple-500',
        DOCUMENTS: 'border-yellow-500',
        INVOICES_AND_BILLS: 'border-red-500',
    };

    const activityBackgroundColors = {
        COMMENT: 'bg-blue-500',
        TASK: 'bg-green-500',
        FILES: 'bg-purple-500',
        DOCUMENTS: 'bg-yellow-500',
        INVOICES_AND_BILLS: 'bg-red-500',
    };

    const activityTextColors = {
        COMMENT: 'text-blue-500',
        TASK: 'text-green-500',
        FILES: 'text-purple-500',
        DOCUMENTS: 'text-yellow-500',
        INVOICES_AND_BILLS: 'text-red-500',
    };

    const handleAddComment = async (type, id, data) => {
        try {
            const { comment, files } = data;

            const formData = new FormData();

            const reqBody = {
                activityId: id,
                comment,
            };

            formData.append('data', JSON.stringify(reqBody));
            if (files.length > 0) {
                files.forEach((element) =>
                    formData.append('files', element.file),
                );
            }

            await API.post(COMMENTS_ROUTES[type], formData);
            enqueueSnackbar(t('Comment added successfully!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            fetchActivities();
        }
    };

    const handleDeleteComment = async (type, id) => {
        try {
            await API.delete(COMMENTS_ROUTES[type], {
                data: {
                    id,
                },
            });

            enqueueSnackbar(t('Comment deleted!'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
            console.error(err);
        } finally {
            fetchActivities();
        }
    };

    return (
        <>
            {activity !== null && (
                <div className="relative mb-12 last:mb-0">
                    {/* Activity checkbox and date */}
                    <div
                        className="absolute -left-8 top-5 sm:-left-4 sm:top-12"
                        style={{ transform: 'translateX(calc(-50% - 1px))' }}
                    >
                        <div
                            className={`flex h-6 w-6 items-center justify-center rounded-full border-2 transition-colors ${
                                activity.activityInfo.status === 'FAILED'
                                    ? 'border-error bg-error'
                                    : activity.activityInfo.status === 'DONE'
                                      ? `${activityBorderColors[activity.activityInfo.actionType]} ${
                                            activityBackgroundColors[
                                                activity.activityInfo.actionType
                                            ]
                                        }`
                                      : `${activityBorderColors[activity.activityInfo.actionType]} bg-layout-main`
                            }`}
                        >
                            {MAPPED_STATUS_ICONS[activity.activityInfo.status]}
                        </div>

                        <div className="absolute top-1/2 -translate-x-full -translate-y-1/2 transform p-2.5 pr-5 text-right sm:flex sm:translate-x-10 sm:gap-6">
                            <p
                                style={{ userSelect: 'none' }}
                                className={`whitespace-nowrap text-right font-semibold ${
                                    activityTextColors[
                                        activity.activityInfo.actionType
                                    ]
                                }`}
                            >{`${
                                daysFrom(activity.activityInfo.createAt) !== 0
                                    ? Math.abs(
                                          daysFrom(
                                              activity.activityInfo.createAt,
                                          ),
                                      )
                                    : t('today')
                            } ${
                                daysFrom(activity.activityInfo.createAt) > 0
                                    ? t('days ago')
                                    : daysFrom(
                                            activity.activityInfo.createAt,
                                        ) === 1
                                      ? t('Yesterday')
                                      : ''
                            }`}</p>
                            <p
                                style={{ userSelect: 'none' }}
                                className="whitespace-nowrap text-right font-semibold"
                            >
                                {formatDate(
                                    activity.activityInfo.createAt,
                                    false,
                                )}
                            </p>
                            <p
                                style={{ userSelect: 'none' }}
                                className="whitespace-nowrap text-right text-dark-text"
                            >
                                {formatTime(activity.activityInfo.createAt)}
                            </p>
                        </div>
                    </div>

                    {/* Activity department */}
                    <div className="absolute left-6 top-0 -translate-y-1/2 transform">
                        <div className="flex gap-4">
                            {activity.activityInfo.activitiesTasks.length >
                                0 && (
                                <p
                                    className={`rounded-full border-2 border-primary-main bg-primary-main px-5 py-3 font-bold`}
                                    style={{
                                        backdropFilter: 'blur(6px)',
                                    }}
                                >
                                    {t('Milestone')}
                                </p>
                            )}

                            <p
                                className={`border-2 ${
                                    activityBorderColors[
                                        activity.activityInfo.actionType
                                    ]
                                } cursor-pointer rounded-full px-5 py-3 font-bold`}
                                style={{
                                    backdropFilter: 'blur(6px)',
                                }}
                            >
                                {activity.activityInfo.pmDepartment.name}
                            </p>

                            {partner && (
                                <div
                                    className="flex cursor-pointer items-center justify-center rounded-full bg-primary-main px-5 py-3 transition-colors hover:bg-primary-light"
                                    onClick={() => {
                                        if (activity.activityInfo.partners.id)
                                            history.push(
                                                internalActivity.base +
                                                    internalActivity.partners
                                                        .base +
                                                    internalActivity.partners
                                                        .update +
                                                    '/' +
                                                    activity.activityInfo
                                                        .partners.id,
                                            );
                                    }}
                                >
                                    <p className="user-select-none font-semibold text-buttons-text">
                                        {partner.standard.name}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Action Arrow */}
                    <div className="absolute right-2 top-2">
                        <ActionArrow
                            actions={(() => {
                                // const displayArrow = actionArrow.edit || actionArrow.notify;
                                const displayArrow =
                                    actionArrow.mark ||
                                    actionArrow.edit ||
                                    actionArrow.gantt ||
                                    actionArrow.notify;

                                return userIsResponsible && displayArrow
                                    ? [
                                          {
                                              name: t('Mark as done'),
                                              render:
                                                  actionArrow.mark &&
                                                  activity.activityInfo
                                                      .status !== 'DONE',
                                              action: () => {
                                                  confirm(
                                                      t(
                                                          'Are you sure you want to set as done this activity?',
                                                      ),
                                                      () => {
                                                          setTimeout(() => {
                                                              updateActivity(
                                                                  activity.activityInfo,
                                                                  'DONE',
                                                              );
                                                          }, 250);
                                                      },
                                                  );
                                              },
                                          },
                                          {
                                              name: t('Mark as failed'),
                                              render:
                                                  actionArrow.mark &&
                                                  activity.activityInfo
                                                      .status !== 'FAILED',
                                              action: () => {
                                                  confirm(
                                                      t(
                                                          'Are you sure you want to set as failed this activity?',
                                                      ),
                                                      () => {
                                                          setTimeout(() => {
                                                              updateActivity(
                                                                  activity.activityInfo,
                                                                  'FAILED',
                                                              );
                                                          }, 250);
                                                      },
                                                  );
                                              },
                                          },
                                          {
                                              name: t('Mark as todo'),
                                              render:
                                                  actionArrow.mark &&
                                                  activity.activityInfo
                                                      .status !== 'TODO',
                                              action: () => {
                                                  confirm(
                                                      t(
                                                          'Are you sure you want to set as todo this activity?',
                                                      ),
                                                      () => {
                                                          setTimeout(() => {
                                                              updateActivity(
                                                                  activity.activityInfo,
                                                                  'TODO',
                                                              );
                                                          }, 250);
                                                      },
                                                  );
                                              },
                                          },
                                          // {
                                          //     name: t('Edit'),
                                          //     render: canEditTasks || canEditDocuments,
                                          //     action: () => {
                                          //         setEditActivity();
                                          //     },
                                          // },
                                          {
                                              name: t('Delete'),
                                              render: actionArrow.edit,
                                              action: () => {
                                                  globalContext.setGlobalModalOpen(
                                                      true,
                                                  );
                                                  globalContext.setGlobalModalChildren(
                                                      <div className="flex-col">
                                                          <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                              {t(
                                                                  'Are you sure you want to',
                                                              )}
                                                              <br />
                                                              {t(
                                                                  'delete this activity?',
                                                              )}
                                                          </p>
                                                          <div className="flex items-center justify-center">
                                                              <Button
                                                                  color="primary"
                                                                  onClick={() => {
                                                                      deleteActivity(
                                                                          activity.activityInfo,
                                                                      );
                                                                      globalContext.setGlobalModalOpen(
                                                                          false,
                                                                      );
                                                                  }}
                                                              >
                                                                  {t('Yes')}
                                                              </Button>
                                                              <div className="w-4" />
                                                              <Button
                                                                  onClick={() =>
                                                                      globalContext.setGlobalModalOpen(
                                                                          false,
                                                                      )
                                                                  }
                                                              >
                                                                  {t('No')}
                                                              </Button>
                                                          </div>
                                                      </div>,
                                                  );
                                              },
                                          },
                                          {
                                              name: 'Gantt',
                                              render: actionArrow.gantt,
                                              action: setSeeGantt,
                                          },
                                          {
                                              name: t('Notify'),
                                              render: actionArrow.notify,
                                              action: () => {
                                                  onNotify(
                                                      activity.activityInfo.id,
                                                  );
                                              },
                                          },
                                      ]
                                    : [];
                            })()}
                        />
                    </div>

                    <div className="mb-1.5 rounded-xl border border-layout-transparent bg-layout-transparent px-6 py-4 pt-10 text-main-text transition-colors sm:pt-24">
                        {/* Actitity name (header) */}
                        <h3 className="mb-5 font-semibold">
                            {activity.activityInfo.title}
                        </h3>

                        {/* Activity body */}
                        {activity.activityInfo.actionType === 'COMMENT' && (
                            <>
                                {activity.activityInfo.description && (
                                    <p className="mb-5">
                                        {activity.activityInfo.description}
                                    </p>
                                )}
                            </>
                        )}

                        {activity.activityInfo.actionType === 'TASK' && (
                            <Fragment>
                                {activity.activityInfo.description &&
                                    activity.activityInfo.activitiesTasks
                                        .length > 0 && (
                                        <iframe
                                            className="mb-5 h-64 w-full rounded-xl border border-layout-lighter bg-white p-2 md:h-96"
                                            srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(
                                                activity.activityInfo
                                                    .description,
                                            )}`}
                                        />
                                    )}

                                {activity.activityInfo.description &&
                                    activity.activityInfo.activitiesTasks
                                        .length === 0 && (
                                        <p className="mb-5 font-semibold">
                                            {activity.activityInfo.description}
                                        </p>
                                    )}

                                {activity.activityInfo.activitiesTasks.length <=
                                    0 && (
                                    <div className="mb-1 flex gap-2">
                                        <span className="text-dark-text">
                                            {t('Task interval')}:
                                        </span>
                                        <div className="font-bold text-main-text opacity-70">
                                            {formatDate(
                                                activity.activityInfo.startDate,
                                            )}
                                        </div>
                                        <span className="font-bold text-main-text">
                                            -
                                        </span>
                                        <div className="font-bold text-main-text opacity-70">
                                            {formatDate(
                                                activity.activityInfo.endDate
                                                    ? activity.activityInfo
                                                          .endDate
                                                    : activity.activityInfo
                                                          .startDate,
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        )}

                        {(activity.activityInfo.actionType === 'FILES' ||
                            activity.activityInfo.actionType ===
                                'DOCUMENTS') && (
                            <>
                                {activity.activityInfo.Files?.length > 0 && (
                                    <div className="mb-4">
                                        {activity.activityInfo.Files.map(
                                            (file) => (
                                                <div
                                                    key={file.id}
                                                    className="mb-1 flex items-center justify-between rounded-lg bg-layout-transparent p-1 pr-2 transition-colors hover:bg-layout-transparent-dark"
                                                >
                                                    <div
                                                        className={`flex w-36 flex-shrink-0 items-center justify-center rounded-md ${
                                                            activityBackgroundColors[
                                                                activity
                                                                    .activityInfo
                                                                    .actionType
                                                            ]
                                                        } mr-4 px-4 py-2`}
                                                    >
                                                        <EuroSymbolIcon
                                                            className="mr-2 text-main-text"
                                                            style={{
                                                                fontSize:
                                                                    '1.5rem',
                                                            }}
                                                        />
                                                        <p className="font-semibold">
                                                            {activity
                                                                .activityInfo
                                                                .actionType ===
                                                            'FILES'
                                                                ? t('Files')
                                                                : activity
                                                                        .activityInfo
                                                                        .actionType ===
                                                                    'DOCUMENTS'
                                                                  ? t(
                                                                        'Documents',
                                                                    )
                                                                  : t('Bills')}
                                                        </p>
                                                    </div>
                                                    <p className="w-full font-semibold">
                                                        {file.file.name}
                                                    </p>

                                                    <div className="flex flex-shrink-0 items-center justify-self-end">
                                                        <div className="group relative mr-1">
                                                            <a
                                                                href={
                                                                    file.file
                                                                        .url
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <BasicTooltip tip="Link">
                                                                    <ActionButton
                                                                        onClick={() =>
                                                                            null
                                                                        }
                                                                        icon={
                                                                            <LinkIcon />
                                                                        }
                                                                    />
                                                                </BasicTooltip>
                                                            </a>
                                                        </div>

                                                        <div className="group relative mr-1">
                                                            <BasicTooltip
                                                                tip={t(
                                                                    'Download',
                                                                )}
                                                            >
                                                                <a
                                                                    href={
                                                                        file
                                                                            .file
                                                                            .url
                                                                    }
                                                                    download={
                                                                        file
                                                                            .file
                                                                            .name
                                                                    }
                                                                >
                                                                    <ActionButton
                                                                        onClick={() =>
                                                                            null
                                                                        }
                                                                        icon={
                                                                            <GetAppIcon />
                                                                        }
                                                                    />
                                                                </a>
                                                            </BasicTooltip>
                                                        </div>

                                                        {/* {deleteIcon && (
                                                        <div className="relative group">
                                                            <BasicTooltip tip="Șterge">
                                                                <ActionButton onClick={onDelete} icon={<CloseIcon />} />
                                                            </BasicTooltip>
                                                        </div>
                                                    )} */}
                                                    </div>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                )}
                            </>
                        )}

                        {/* Activity footer */}
                        {/* Activity Author */}
                        <div className="mb-5 flex items-center">
                            <div className="mr-5">
                                <span className="pointer-events-none text-dark-text">
                                    {t('Activity author')}:{' '}
                                </span>
                                <span
                                    className={`font-semibold text-main-text ${canAll && userLink && linkStyle}`}
                                    onClick={() => {
                                        if (canAll) {
                                            history.push(
                                                configurator.base +
                                                    configurator.users.base +
                                                    configurator.users.update +
                                                    '/' +
                                                    activity.activityInfo
                                                        .authorId +
                                                    '?tab=1',
                                            );
                                        }
                                    }}
                                >
                                    {
                                        activity.activityInfo.author?.profile
                                            ?.name
                                    }
                                </span>
                            </div>

                            {/* Activity Asignee */}
                            {responsible && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">
                                        {t('Responsible')}:{' '}
                                    </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">
                                        {responsible}
                                    </span>
                                </div>
                            )}
                            {supervisor && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">
                                        {t('Supervisor')}:{' '}
                                    </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">
                                        {supervisor}
                                    </span>
                                </div>
                            )}
                            {watcher && (
                                <div className="mr-5">
                                    <span className="pointer-events-none text-dark-text">
                                        {t('Watcher / Beneficiary')}:{' '}
                                    </span>

                                    <span className="mr-1 cursor-default font-semibold text-main-text">
                                        {watcher}
                                    </span>
                                </div>
                            )}
                        </div>

                        {/* Submilestone section */}
                        {activity.activityInfo.actionType === 'TASK' &&
                            activity.activityInfo.activitiesTasks?.length >
                                0 && (
                                <>
                                    <div
                                        className={`relative grid transform transition-all duration-300 ${open ? 'mb-5' : ''}`}
                                        style={{
                                            gridTemplateRows: open
                                                ? '1fr'
                                                : `0fr`,
                                        }}
                                    >
                                        <div className="overflow-hidden">
                                            {activity.activityInfo.activitiesTasks.map(
                                                (subMilestone, index) => (
                                                    <div
                                                        key={subMilestone.id}
                                                        style={{
                                                            // zIndex: activity.activityInfo.activitiesTasks.length - index,
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        <PMTaskManagementSubmilestoneDisplay
                                                            submilestone={
                                                                subMilestone
                                                            }
                                                            userIsResponsible={
                                                                userIsResponsible
                                                            }
                                                            canViewComments={
                                                                true
                                                            }
                                                            canAllComments={
                                                                canAll
                                                            }
                                                            onAddComment={
                                                                handleAddComment
                                                            }
                                                            onDeleteComment={
                                                                handleDeleteComment
                                                            }
                                                        />
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        className={`mx-auto flex h-14 w-14 transform cursor-pointer items-center justify-center rounded-full border border-layout-transparent bg-layout-transparent transition-transform duration-300 hover:bg-layout-transparent-dark ${
                                            open ? 'rotate-180' : 'rotate-0'
                                        }`}
                                        onClick={() => setOpen((prev) => !prev)}
                                    >
                                        <ChevronDown className="h-9 w-9 opacity-60" />
                                    </div>
                                </>
                            )}
                    </div>

                    {/* Comment section */}
                    <div className="pb-8">
                        <Comments
                            comments={formattedComments}
                            canAdd={canAll}
                            canDelete={canAll}
                            onSubmit={handleAddComment.bind(
                                null,
                                'activities',
                                activity.activityInfo.id,
                            )}
                            onDelete={handleDeleteComment.bind(
                                null,
                                'activities',
                            )}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

TaskManagementDisplay.propTypes = {
    onEdit: PropTypes.func,
    onNotify: PropTypes.func,
    activity: PropTypes.object,
    deleteActivity: PropTypes.func,
    updateActivity: PropTypes.func,
    actionArrow: PropTypes.object,
    deleteIcon: PropTypes.bool,
    userLink: PropTypes.bool,
    fetchActivities: PropTypes.func,
    setSeeGantt: PropTypes.func,
};

TaskManagementDisplay.defaultProps = {
    onEdit: () => null,
    onNotify: () => null,
    activity: null,
    deleteActivity: () => null,
    updateActivity: () => null,
    actionArrow: {
        mark: false,
        edit: false,
        gantt: false,
        notify: false,
    },
    deleteIcon: false,
    userLink: false,
    fetchActivities: () => null,
    setSeeGantt: () => null,
};

export default TaskManagementDisplay;
