import React from 'react';

import { Button } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getStyles, SUBTYPES_DATA } from 'utils/invoicesUtils';

const InvoiceSubtypeSelection = ({
    type,
    renderSubtypeSelection,
    renderSubtypeChange,
    editInvoiceInfo,
    onSubtypeChangeReset,
}) => {
    const styles = getStyles();
    const { t } = useTranslation();
    const { translation, options } = SUBTYPES_DATA[type] || {};

    return (
        <>
            {renderSubtypeSelection && (
                <div className="mb-10 flex flex-wrap justify-start gap-5">
                    {options.map((option) => (
                        <Button
                            key={option.value}
                            className={styles.income}
                            startIcon={<i className="fa-solid fa-file-invoice" />}
                            onClick={() => editInvoiceInfo('subType', option.value)}
                        >
                            {t(option.translation)}
                        </Button>
                    ))}
                </div>
            )}

            {renderSubtypeChange && (
                <div className="mb-20 flex justify-start">
                    <Button
                        color="primary"
                        startIcon={<i className="fa-solid fa-file-invoice" />}
                        onClick={onSubtypeChangeReset}
                    >
                        {t(translation)}
                    </Button>
                </div>
            )}
        </>
    );
};

InvoiceSubtypeSelection.propTypes = {
    type: PropTypes.string,
    editInvoiceInfo: PropTypes.func,
    onSubtypeChangeReset: PropTypes.func,
    renderSubtypeSelection: PropTypes.bool,
    renderSubtypeChange: PropTypes.bool,
};

InvoiceSubtypeSelection.defaultProps = {
    type: null,
    editInvoiceInfo: () => {},
    onSubtypeChangeReset: () => {},
    renderSubtypeSelection: false,
    renderSubtypeChange: false,
};

export default InvoiceSubtypeSelection;
