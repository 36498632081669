import React from 'react';

import PropTypes from 'prop-types';

import ExportTableCell from './export-table-cell';

const ExportTableBody = ({ data, columns, textColor }) => {
    return (
        <tbody>
            {data.map((entry, index) => {
                const { textColor: cellTextColor = textColor, backgroundColor = '#FFFFFF' } = entry;

                return (
                    <tr key={index} style={{ backgroundColor }}>
                        {columns.map((column) => {
                            const { key, render, pdfMakeData, style } = column;
                            const content = entry[key];

                            return (
                                <ExportTableCell
                                    key={key}
                                    textColor={cellTextColor}
                                    backgroundColor={backgroundColor}
                                    pdfMakeData={pdfMakeData}
                                    style={style}
                                >
                                    {render(content, entry, index, cellTextColor, backgroundColor)}
                                </ExportTableCell>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

ExportTableBody.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    textColor: PropTypes.string,
};

ExportTableBody.defaultProps = {
    data: [],
    columns: [],
    textColor: null,
};

export default ExportTableBody;
