import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { CircleCheck, Lock, LockOpen } from 'lucide-react';
import GetAppIcon from '@material-ui/icons/GetApp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Revenues } from 'api/Expenses_Revenues';
import BasicTooltip from 'components/shared/basic-tooltip';
import ConfiguratorForm from 'components/shared/configurator/configurator-form';
import DocumentTemplate from 'components/shared/document-template/document-template';
import ErrorButton from 'components/shared/error-button';
import FileUploadContainer from 'components/shared/file-upload-container';
import PDFPreview from 'components/shared/pdf-preview/pdf-preview';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { articlesInfoForPDF } from 'pages/quotes/newBidding/pdInfo-functions';
import { PropTypes } from 'prop-types';
import {
    Dropdown,
    Header,
    LabelWrapper,
    Tabs,
} from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
    calculateSumWithVAT,
    errorHandling,
    formatDate,
    getRawImageFromPath,
    uploadSingleFile,
} from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

import Cashflow from './cashflow-contracts';

const useStyles = makeStyles(() => {
    return {
        draft: {
            backgroundColor: `#FB924B`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#FB924B`,
            },
        },
        sent: {
            backgroundColor: `#083469`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#083469`,
            },
        },
        finished: {
            backgroundColor: `#3EC356`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#3EC356`,
            },
        },
        blocked: {
            backgroundColor: `#3587A4`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#3587A4`,
            },
        },
    };
});

const EditContract = (props) => {
    const styles = useStyles();
    const { pmProjectId } = props;
    const { id } = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const [contractValues, setContractValues] = useState(null);
    const [payments, setPayments] = useState([]);
    const [documentMode, setDocumentMode] = useState(0);
    const [pdf, setPdf] = useState({ info: null, blob: null });
    const [templateData, setTemplateData] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [usedTemplates, setUsedTemplates] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [VAT, setVAT] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);
    const [loading, setLoading] = useState(true);
    const [previewPdf, setPreviewPdf] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState(null);
    const [statusHistory, setStatusHistory] = useState([]);
    const [statusData, setStatusData] = useState(null);
    const [offers, setOffers] = useState([]);
    const [milestones, setMilestones] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [t('Actions'), t('Document'), t('Forecasted payments')];

    const [contractData, setContractData] = useState({
        withTva: 0,
        withoutTva: 0,
    });

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '8',
            permissionType: 'ALL',
        },
    ]);

    const canAllPmContracts = checkPerm([
        {
            permissionId: '60',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if ((!canAll && !pmProjectId) || (!canAllPmContracts && pmProjectId)) {
            history.push('/');
        }
    }, [canAll, canAllPmContracts]);

    const getAllRevenues = async () => {
        try {
            const res = await Revenues.get(999999, 0);
            if (res.ok) return res.data.revenuName;

            throw res.error;
        } catch (error) {
            console.error(error);
            enqueueSnackbar(
                errorHandling(error).length > 100
                    ? errorHandling(error)
                    : t(errorHandling(error)),
                {
                    variant: 'error',
                },
            );
            throw 'Error fetching revenues';
        }
    };

    const fetchContract = async (contractId) => {
        try {
            let res;
            if (pmProjectId) {
                res = await API.get(`contract_pm/${contractId}`);
            } else {
                res = await API.get(`contract/${contractId}`);
            }

            const dataContract =
                typeof res.data.contract.data === 'string' &&
                res.data.contract.data !== null
                    ? JSON.parse(res.data.contract.data)
                    : res.data.contract.data;

            setContractValues(dataContract);
            setPayments(res.data.contract.forecastedPayments);

            return res.data.contract;
        } catch (error) {
            console.error(error);
            return {};
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const params = {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                };

                const [
                    resContract,
                    resTemplates,
                    resActivities,
                    resAcceptedOffer,
                    resRevenues,
                ] = await Promise.all([
                    fetchContract(id),
                    API.get('docTemplates', {
                        params,
                    }),
                    API.get(
                        pmProjectId
                            ? '/pmProjectActivity'
                            : '/internal_activities',
                        {
                            params: {
                                ...params,
                                pmProjectPlanId: pmProjectId ?? undefined,
                            },
                        },
                    ),
                    API.get('/acceptedOffer'),
                    getAllRevenues(),
                ]);

                setStatusData(resContract.statusHistory.at(-1));
                setStatusHistory(resContract.statusHistory);

                setVAT(resContract.TVA);
                setContractData((prev) => ({
                    ...prev,
                    withTva: resContract.totalPrice ?? 0,
                    withoutTva: resContract.priceWithoutVat ?? 0,
                }));

                setTemplates(resTemplates.data.data);

                const documentTemplate = resContract.ContractDocuments.at(-1);
                if (documentTemplate) {
                    const templateIdx = resTemplates.data.data.findIndex(
                        (template) =>
                            template.id === documentTemplate.templateId,
                    );

                    if (templateIdx >= 0) setSelectedTemplate(templateIdx);
                }

                const pdfFile = resContract.contractFiles
                    .filter((file) => file.type === 'contractPdf')
                    .at(-1);
                if (pdfFile) {
                    const spl = pdfFile.file.key.split('/');
                    const uuidPart = spl[spl.length - 1];
                    const name = uuidPart.split('-').slice(5).join('');
                    const blob = await getRawImageFromPath(
                        pdfFile.file.url,
                        true,
                    );

                    setPdf({
                        blob,
                        info: {
                            name,
                        },
                    });
                }

                setDocumentMode(Number(resContract.isPersonalized));

                setUsedTemplates(resContract.ContractDocuments);

                if (resContract.templatePdf)
                    setFile({
                        httpHeaders: {
                            'Access-Control-Allow-Origin': '*',
                        },
                        url: resContract.templatePdf,
                    });

                const fetchedMilestones = pmProjectId
                    ? resActivities.data.activity.map(
                          (activity) => activity.activityInfo,
                      )
                    : resActivities.data.activity;
                setMilestones(
                    fetchedMilestones.filter(
                        (activity) => activity.activitiesTasks.length > 0,
                    ),
                );

                const fetchedOffers = resAcceptedOffer.data.updatedOffers;
                for (const offer of fetchedOffers) {
                    let articleItems = [];
                    if (offer.offerArticles.length) {
                        const newSelectedArticlesForm = offer.offerArticles.map(
                            (art) => {
                                const newArt = {
                                    ...art,
                                    selectedAttributes: [],
                                    selectedVariations: [],
                                    amount: art.amount || art.quantity,
                                };

                                delete newArt.quantity;
                                return newArt;
                            },
                        );

                        articleItems = articlesInfoForPDF(
                            newSelectedArticlesForm,
                            resRevenues,
                            offer.id,
                        );
                    }

                    offer.items = articleItems;
                }
                setOffers(fetchedOffers);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    const handleAddFiles = (e) => {
        uploadSingleFile(
            e,
            async ({ message, blob, info }) => {
                if (message) {
                    enqueueSnackbar(t(message), {
                        variant: 'error',
                    });
                } else if (blob && info) {
                    try {
                        setIsUpdating(true);

                        const reqForm = new FormData();

                        reqForm.append('files', blob);
                        reqForm.append('isPersonalized', false);

                        const res = await API.put(
                            pmProjectId
                                ? `/pm_contract_upload_pdf`
                                : `/contract_upload_pdf`,
                            reqForm,
                            {
                                params: {
                                    id,
                                },
                            },
                        );

                        setPdf({ info, blob });

                        setFile({
                            httpHeaders: {
                                'Access-Control-Allow-Origin': '*',
                            },
                            url: res.data.pdfUrl,
                        });

                        enqueueSnackbar(
                            t('The PDF was uploaded successfully!'),
                            { variant: 'success' },
                        );
                    } catch (error) {
                        console.error(error);
                        enqueueSnackbar(errorHandling(error), {
                            variant: 'error',
                        });
                    } finally {
                        setIsUpdating(false);
                    }
                }
            },
            'file',
        );
    };

    const handleUpdatePaymentValues = (VAT) => {
        setContractData((prev) => ({
            ...prev,
            withTva: calculateSumWithVAT(prev.withoutTva, VAT),
        }));
    };

    const schema = yup.object().shape({
        elements: yup.array().of(
            yup.object().shape({
                value: yup
                    .mixed()
                    .test(
                        'validValue',
                        t('Every element must have content'),
                        (value, context) => {
                            const { itemType } = context.parent;

                            if (!value === true) return false;
                            else if (Array.isArray(value))
                                return value.length !== 0;
                            else if (typeof value === 'object') {
                                try {
                                    if (itemType === 'table') {
                                        return yup
                                            .object()
                                            .shape({
                                                columns: yup
                                                    .array()
                                                    .min(1)
                                                    .required(),
                                                rows: yup
                                                    .array()
                                                    .min(1)
                                                    .required(),
                                            })
                                            .validateSync(value);
                                    }

                                    if (itemType === 'image') {
                                        return yup
                                            .object()
                                            .shape({
                                                url: yup
                                                    .string()
                                                    .typeError()
                                                    .required(),
                                            })
                                            .validateSync(value);
                                    }

                                    return true;
                                } catch (error) {
                                    console.error(error);
                                    return false;
                                }
                            }

                            return true;
                        },
                    ),
                entity: yup.array().of(
                    yup.object().shape({
                        entityId: yup
                            .string()
                            .nullable()
                            .test(
                                'validEntity',
                                t(
                                    "One element doesn't have the selected entity",
                                ),
                                (id, context) => {
                                    const { itemType, purpose } =
                                        context.parent;

                                    try {
                                        if (
                                            purpose === 'milestone' ||
                                            purpose === 'milestones' ||
                                            itemType === 'offer'
                                        ) {
                                            return yup
                                                .string()
                                                .typeError()
                                                .validateSync(id);
                                        }

                                        return true;
                                    } catch (error) {
                                        console.error(error);
                                        return false;
                                    }
                                },
                            ),
                    }),
                ),
            }),
        ),
    });

    const generateTemplateRequestData = async () => {
        try {
            const newElements = structuredClone(templateData.elements);
            const newColumns = structuredClone(templateData.columns);

            for (const key of Object.keys(newColumns)) {
                const column = newColumns[key];
                const isMainColumn = key.includes('column-main');

                if (!column.isActive) {
                    for (const elementId of column.elementsIds) {
                        const element = newElements[elementId];

                        if (
                            isMainColumn &&
                            !element.isMultiLanguage &&
                            !element.flagDisabled
                        )
                            continue;

                        delete newElements[elementId];
                    }

                    delete newColumns[key];
                } else {
                    for (let i = 0; i < column.elementsIds.length; i++) {
                        const elementId = column.elementsIds[i];

                        if (newElements[elementId].flagDisabled) {
                            delete newElements[elementId];
                            column.elementsIds = column.elementsIds.filter(
                                (eI) => eI !== elementId,
                            );
                        }
                    }
                }
            }

            const newColumnOrder = structuredClone(
                templateData.columnOrder,
            ).filter((column) => Object.keys(newColumns).includes(column));

            await schema.validate({
                elements: Object.values(newElements).filter((element) => {
                    if (element.id.includes('column-main')) return true;
                    return element.isMultiLanguage;
                }),
            });

            const images = [];
            const filesMetadata = [];
            for (const key of Object.keys(newElements)) {
                const element = newElements[key];

                if (element.itemType === 'image') {
                    if (!element.value.blob) {
                        element.value = { blob: null, url: element.value.url };
                        element.entity = element.entity.map((entry) => ({
                            ...entry,
                            data: entry.data,
                        }));
                        continue;
                    }

                    const file = element.value.blob;
                    images.push(file);
                    filesMetadata.push({
                        id: element.id,
                        position: null,
                    });
                    element.value = { blob: null, url: null };
                    element.entity = element.entity.map((entry) => ({
                        ...entry,
                        data: entry.data,
                    }));
                } else if (element.itemType === 'gallery') {
                    const newElementValue = [];

                    for (let i = 0; i < element.value.length; i++) {
                        const value = element.value[i];
                        if (!value.blob) {
                            newElementValue.push({
                                blob: null,
                                url: value.url,
                            });
                            continue;
                        }

                        const file = value.blob;
                        images.push(file);
                        filesMetadata.push({
                            id: element.id,
                            position: i,
                        });
                        newElementValue.push({ blob: null, url: null });
                    }
                    element.value = newElementValue;
                    element.entity = element.entity.map((entry) => ({
                        ...entry,
                        data: entry.data,
                    }));
                }
            }

            const reqTemplateData = {
                ...templateData,
                columns: newColumns,
                columnOrder: newColumnOrder,
                elements: newElements,
            };

            return { images, filesMetadata, reqTemplateData };
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const handleRegeneratePDF = async () => {
        try {
            setIsUpdating(true);
            setPreviewPdf(false);
            setPageNumber(1);

            const reqForm = new FormData();
            reqForm.append('templateId', templates[selectedTemplate].id);

            const { images, filesMetadata, reqTemplateData } =
                await generateTemplateRequestData();
            reqForm.append('templateData', JSON.stringify(reqTemplateData));
            reqForm.append('filesMetadata', JSON.stringify(filesMetadata));
            for (const image of images) {
                reqForm.append('files', image);
            }
            reqForm.append('isPersonalized', true);

            const res = await API.put(
                pmProjectId
                    ? `/pm_contract_generate_pdf`
                    : `/contract_generate_pdf`,
                reqForm,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    params: {
                        id,
                    },
                },
            );

            setFile({
                httpHeaders: {
                    'Access-Control-Allow-Origin': '*',
                },
                url: res.data.pdfUrl,
            });

            enqueueSnackbar(t('The contract PDF is successfully updated!'), {
                variant: 'success',
            });
            setPreviewPdf(true);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
        }
    };

    const handleUpdateStatus = async (statusData) => {
        try {
            setIsUpdating(true);

            await API.patch(
                pmProjectId ? `/pm_contract_status` : `/contract_status`,
                undefined,
                {
                    params: {
                        id,
                        ...statusData,
                    },
                },
            );

            enqueueSnackbar(
                t('The contract statusData.status was successfully updated!'),
                {
                    variant: 'success',
                },
            );

            setStatusData(statusData);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {pmProjectId ? 'PM' : 'CRM'} | {t('edit-contract')}
                </title>
            </Helmet>

            <Header pageTitle={t('edit-contract')} />

            <div className="page-container">
                {loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        <ConfiguratorForm
                            pmProjectId={pmProjectId}
                            entity={pmProjectId ? 'contractPM' : 'contract'}
                            entityId={id}
                            defaultValues={contractValues}
                            data={contractData}
                            setData={setContractData}
                            VAT={VAT}
                            setVAT={setVAT}
                            updatePaymentValues={handleUpdatePaymentValues}
                        />
                        <div className="mt-24 flex flex-col gap-10">
                            <div className="inline-flex">
                                <Tabs
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    tabs={tabs}
                                    disabled={isUpdating}
                                />
                            </div>

                            {activeTab === 0 && (
                                <div className="flex flex-col gap-8 sm:max-w-full">
                                    <h2 className="font-semibold">{`Status: ${t(
                                        statusData.status.slice(0, 1) +
                                            statusData.status
                                                .slice(1)
                                                .toLowerCase(),
                                    )}, ${t('updated on')}: ${formatDate(statusData.createdAt)}`}</h2>

                                    <div className="flex gap-4 sm:w-full sm:flex-col">
                                        {statusData.status === 'DRAFT' && (
                                            <Button
                                                className={styles.draft}
                                                //TODO Add this redirect function later
                                                // onClick={}
                                                startIcon={<CircleCheck />}
                                                disabled={isUpdating}
                                            >
                                                {t(
                                                    'Continue contract creation',
                                                )}
                                            </Button>
                                        )}
                                        {statusData.status === 'SENT' && (
                                            <Button
                                                className={styles.finished}
                                                startIcon={<CircleCheck />}
                                                onClick={() =>
                                                    handleUpdateStatus({
                                                        status: 'FINISHED',
                                                        createdAt: new Date(),
                                                    })
                                                }
                                                disabled={isUpdating}
                                            >
                                                {t('Finish the contract')}
                                            </Button>
                                        )}
                                        <Button
                                            className={styles.blocked}
                                            startIcon={
                                                statusData.status ===
                                                'BLOCKED' ? (
                                                    <LockOpen />
                                                ) : (
                                                    <Lock />
                                                )
                                            }
                                            onClick={() =>
                                                handleUpdateStatus(
                                                    statusData.status ===
                                                        'BLOCKED'
                                                        ? statusHistory.at(-2)
                                                        : {
                                                              status: 'BLOCKED',
                                                              createdAt:
                                                                  new Date(),
                                                          },
                                                )
                                            }
                                            disabled={isUpdating}
                                        >
                                            {t(
                                                statusData.status === 'BLOCKED'
                                                    ? 'Unblock the contract'
                                                    : 'Block the contract',
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {activeTab === 1 && (
                                <div className="flex flex-col gap-8">
                                    <div className="w-min">
                                        <Dropdown
                                            disabled={isUpdating}
                                            selectedOption={documentMode}
                                            setSelectedOption={setDocumentMode}
                                            options={[
                                                t('Upload PDF document'),
                                                t('Customize PDF document'),
                                            ]}
                                        />
                                    </div>

                                    {documentMode === 0 && (
                                        <h2 className="font-semibold">
                                            {t('Upload document')}
                                        </h2>
                                    )}

                                    {documentMode === 1 && (
                                        <>
                                            <h2 className="font-semibold">
                                                {t('Document interface')}
                                            </h2>
                                            <div className="w-min">
                                                <LabelWrapper
                                                    label={t(
                                                        'Choose a document template to generate offer PDF',
                                                    )}
                                                >
                                                    <Dropdown
                                                        options={templates.map(
                                                            (t) => t.name,
                                                        )}
                                                        selectedOption={
                                                            selectedTemplate
                                                        }
                                                        setSelectedOption={(
                                                            i,
                                                        ) =>
                                                            setSelectedTemplate(
                                                                i,
                                                            )
                                                        }
                                                        placeholder={t(
                                                            'Choose a document template',
                                                        )}
                                                    />
                                                </LabelWrapper>
                                            </div>

                                            {selectedTemplate !== null && (
                                                <DocumentTemplate
                                                    templateData={templateData}
                                                    setTemplateData={
                                                        setTemplateData
                                                    }
                                                    rawTemplate={
                                                        templates[
                                                            selectedTemplate
                                                        ]
                                                    }
                                                    usedTemplate={usedTemplates.at(
                                                        -1,
                                                    )}
                                                    milestones={milestones}
                                                    offers={offers}
                                                    pmProjectId={pmProjectId}
                                                />
                                            )}
                                        </>
                                    )}

                                    <div className="flex flex-wrap gap-10">
                                        {documentMode === 0 && (
                                            <div className="flex items-center gap-8">
                                                <FileUploadContainer
                                                    onUpload={handleAddFiles}
                                                    disabled={isUpdating}
                                                >
                                                    <Button
                                                        disabled={isUpdating}
                                                        startIcon={
                                                            isUpdating ? (
                                                                <CircularProgress
                                                                    size={20}
                                                                />
                                                            ) : (
                                                                <NoteAddIcon />
                                                            )
                                                        }
                                                        color={
                                                            pdf.blob
                                                                ? 'secondary'
                                                                : 'primary'
                                                        }
                                                        className="self-center"
                                                    >
                                                        {t(
                                                            pdf.blob
                                                                ? t(
                                                                      'Change document',
                                                                  )
                                                                : 'Add document',
                                                        )}
                                                    </Button>
                                                </FileUploadContainer>

                                                {pdf.info && (
                                                    <h4>{`${t('Uploaded document')}: ${pdf.info.name}`}</h4>
                                                )}
                                            </div>
                                        )}
                                        {documentMode === 1 && (
                                            <BasicTooltip
                                                tip={t(
                                                    "You can't generate a PDF if you don't have at least one language selected",
                                                )}
                                                disabled={
                                                    pdf.blob ||
                                                    !templateData ||
                                                    (templateData &&
                                                        Object.values(
                                                            templateData.columns,
                                                        ).find(
                                                            (column) =>
                                                                column.isActive,
                                                        ))
                                                }
                                            >
                                                <Button
                                                    color="secondary"
                                                    startIcon={
                                                        isUpdating ? (
                                                            <CircularProgress
                                                                size={20}
                                                            />
                                                        ) : (
                                                            <PictureAsPdfIcon />
                                                        )
                                                    }
                                                    onClick={() =>
                                                        handleRegeneratePDF()
                                                    }
                                                    disabled={
                                                        isUpdating ||
                                                        (documentMode === 0 &&
                                                            !pdf.blob) ||
                                                        (documentMode === 1 &&
                                                            (!templateData ||
                                                                (templateData &&
                                                                    Object.values(
                                                                        templateData.columns,
                                                                    ).every(
                                                                        (
                                                                            column,
                                                                        ) =>
                                                                            !column.isActive ||
                                                                            !column.isCompleted,
                                                                    ))))
                                                    }
                                                >
                                                    {t('Generate PDF')}
                                                </Button>
                                            </BasicTooltip>
                                        )}
                                        {file && (
                                            <>
                                                <Button
                                                    disabled={isUpdating}
                                                    color="secondary"
                                                    startIcon={
                                                        previewPdf ? (
                                                            <VisibilityOffIcon />
                                                        ) : (
                                                            <VisibilityIcon />
                                                        )
                                                    }
                                                    onClick={() =>
                                                        setPreviewPdf(
                                                            !previewPdf,
                                                        )
                                                    }
                                                >
                                                    {previewPdf
                                                        ? t('Hide PDF Preview')
                                                        : t(
                                                              'Visualize PDF Preview',
                                                          )}
                                                </Button>
                                                <a
                                                    className="flex items-center"
                                                    href={file.url}
                                                    download
                                                    rel="noreferrer"
                                                >
                                                    <ErrorButton
                                                        disabled={isUpdating}
                                                        startIcon={
                                                            <GetAppIcon />
                                                        }
                                                    >
                                                        {t('Download PDF')}
                                                    </ErrorButton>
                                                </a>
                                            </>
                                        )}
                                    </div>

                                    {previewPdf && (
                                        <Fragment>
                                            <h2 className="mb-8">
                                                {t('PDF Preview')}
                                            </h2>
                                            <PDFPreview
                                                file={file}
                                                pageNumber={pageNumber}
                                                setPageNumber={setPageNumber}
                                            />
                                        </Fragment>
                                    )}
                                </div>
                            )}

                            {activeTab === 2 && (
                                <div className="flex flex-col gap-8">
                                    <h2 className="font-semibold">
                                        {t('Add forecasted payments')}
                                    </h2>
                                    <Cashflow
                                        fetchContract={fetchContract}
                                        payments={payments}
                                        contractId={id}
                                        VAT={VAT}
                                        pmProjectId={pmProjectId}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

EditContract.propTypes = {
    pmProjectId: PropTypes.string,
    canAddExpensesAndRevenues: PropTypes.bool,
};

EditContract.defaultProps = {
    pmProjectId: null,
    canAddExpensesAndRevenues: true,
};

export default EditContract;
