import React, { useCallback, useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';

import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
} from '@material-ui/core';

import SubMilestoneActivity from 'components/internal-activity/milestone-activity';
import GlobalContext from 'contexts/GlobalContext';
import TaskManagementContext from 'contexts/TaskManagementContext';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useScreenSizes from 'hooks/useScreenSizes.js';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
    DatePicker,
    Dropdown,
    LabelWrapper,
    MultiDropdown,
    Tabs,
} from 'RaisisComponents/index.js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getDayBeginningAndEnding } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const PMTaskManagementTasks = (props) => {
    const { theme } = useContext(GlobalContext);
    const { departmentId, partnerId, tenantUsers, closeModal } = props;
    const { t } = useTranslation();
    const taskManagementContext = useContext(TaskManagementContext);
    const { projectId } = useParams();

    const [width] = useScreenSizes();

    const { user } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();
    const [activityType, setActivityType] = useState(0);

    const [title, setTitle] = useState('');
    const [selectedTitle, setSelectedTitle] = useState([]);
    const [description, setDescription] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    /*
        Milestone states/functions
    */
    const [milestones, setMilestones] = useState([]);
    const [milestonesConfig, setMilestonesConfig] = useState([]);
    const [milestonesNames, setMilestonesNames] = useState([]);
    const [milestoneInfo, setMilestoneInfo] = useState(null);
    const [checkboxMilestones, setCheckboxMilestones] = useState(false);
    const [titleMilestone, setTitleMilestone] = useState('');
    const [selectedMilestone, setSelectedMilestone] = useState(null);

    //Milestone description logic
    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap,
        );
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [descriptionMilestone, setDescriptionMilestone] = useState(
        htmlToDraftBlocks(
            `<h2 style="color: rgb(0,0,0);>${t('Add description for the Milestone')}</h2>`,
        ),
    );

    const [supervisor, setSupervisor] = useState([]);
    const [watcher, setWatcher] = useState([]);
    const [responsible, setResponsible] = useState([]);
    const [pipeline, setPipeline] = useState(null);
    const [active, setActive] = useState(false);

    const [milestoneParentId, setMilestoneParentId] = useState(null);

    const [projectAffiliates, setProjectAffiliates] = useState([]);
    const [selectedAffiliate, setSelectedAffiliate] = useState(null);

    const [milestoneStartDate, setMilestoneStartDate] = useState(new Date());

    useEffect(() => {
        setResponsible([]);
    }, [activityType]);

    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/milestones-list');
                setMilestonesConfig(response.data.data);

                const res = await API.get('/getProjectAffiliates', {
                    params: { projectId },
                });
                setProjectAffiliates(res.data.users);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    /**
     * Create milestones config names for dropdown
     */
    useEffect(() => {
        if (!milestonesConfig.length) return;
        setMilestonesNames(milestonesConfig?.map((m) => m.name));
    }, [milestonesConfig]);

    const formatSubMilestone = (subMilestone) => {
        return {
            ...subMilestone,
            mileStoneResponsible: setDropdowns(
                subMilestone.mileStoneResponsible,
                tenantUsers,
            ),
            estimatedStartDate: null,
            estimatedEndDate: null,
            children: subMilestone.children.map((c) => ({
                ...c,
                mileStoneResponsible: setDropdowns(
                    c.mileStoneResponsible,
                    tenantUsers,
                ),
                estimatedStartDate: null,
                priority: 1,
                estimatedEndDate: null,
            })),
        };
    };

    useEffect(() => {
        (async () => {
            if (selectedMilestone === null || !milestonesConfig.length) return;

            try {
                const response = await API.get(
                    `/mileStone/${milestonesConfig[selectedMilestone].id}`,
                );
                const milestoneInf = response.data.data;

                // Let's format our api response
                const milestone = milestoneInf?.mileStoneTask?.map((m) =>
                    formatSubMilestone(m),
                );
                setTitleMilestone(milestoneInf?.name);
                setDescriptionMilestone(
                    htmlToDraftBlocks(milestoneInf?.description),
                );
                setResponsible(
                    setDropdowns(milestoneInf?.responsible, tenantUsers),
                );
                setWatcher(setDropdowns(milestoneInf?.watcher, tenantUsers));
                setSupervisor(
                    setDropdowns(milestoneInf?.accepter, tenantUsers),
                );
                setPipeline(getPipelineType());
                setActive(milestoneInf?.isActive);
                setMilestoneInfo(milestoneInf);

                setMilestones(calculateDuration(milestone));
            } catch (err) {
                console.error(err);
            }
        })();
    }, [selectedMilestone, milestonesConfig]);

    // UseCallback-ul face ca mileStoneResponsible sa fie null :) E reparat
    const defaultMilestone = () => {
        return {
            id: Math.random(),
            name: '',
            estimatedDays: 0,
            estimatedStartDate: null,
            estimatedEndDate: null,
            mileStoneResponsible: [],
            children: [],
        };
    };

    const addMilestone = () => {
        const newMilestones = [...milestones, defaultMilestone()];
        setMilestones(calculateDuration(newMilestones));
    };

    const deleteMilestone = (id) => {
        const newMilestones = milestones.filter((m) => m.id !== id);
        setMilestones(
            newMilestones.length > 0 ? calculateDuration(newMilestones) : [],
        );
    };

    const updateMilestone = (id, key, value) => {
        const milestoneIndex = milestones.findIndex((m) => m.id === id);
        const newMilestones = [...milestones];
        newMilestones[milestoneIndex][key] = value;
        setMilestones(calculateDuration(newMilestones));
    };

    /**
     * @param {*} usersList - Is the state of the every dropdown with users - Array of numbers, returned by dropdown
     * @returns An array of users ids
     */
    const getUsersIds = (usersList, users) => {
        return usersList.map((user) => users[user].id);
    };

    /**
     * Function take the info about user from an dropdown and transform data into an array on indexes for the dropdown to be set on edit
     * @param {*} usersId is an array of objects from the milestone data
     * @returns  An array of index for the dropdowns
     */
    const setDropdowns = (usersId, users) => {
        if (users.length) {
            return usersId
                ?.map((user) => user.userId)
                .reduce((acc, curr) => {
                    const index = users.findIndex((user) => user.id === curr);
                    return index >= 0 ? [...acc, index] : acc;
                }, []);
        }
        return [];
    };

    const getPipelineType = useCallback(() => {
        switch (milestoneInfo?.pipelineType) {
            case 'NOT_ASSUMED':
                return 0;
            case 'OFFER':
                return 1;
            default:
                return;
        }
    }, [milestoneInfo]);

    /**
     * [ARCADIE]
     * We gon keep a milestone date in the state, which is actually the date of the first milestone
     */

    const calculateDuration = (mils, date = null) => {
        if (mils.length > 0) {
            const newSubmilestones = [];
            let daysPassed = 0;
            const secondsPerDay = 1000 * 60 * 60 * 24;

            for (const [i, submilestone] of mils.entries()) {
                const ns = { ...submilestone };
                ns.children = [];
                let newStartDate = new Date(
                    date ?? milestoneStartDate,
                ).getTime();

                // Calculate submilestone start date
                if (i > 0) {
                    newStartDate += daysPassed * secondsPerDay;
                }

                // Calculate estimated days and end date (they depend on tasks, so we map on them)
                let allTasksDays = 0;

                for (const task of submilestone.children) {
                    const newTask = { ...task };
                    // Task start date
                    const startMilis =
                        newStartDate + allTasksDays * secondsPerDay;
                    newTask.estimatedStartDate = new Date(startMilis);

                    // Task end date
                    newTask.estimatedEndDate = new Date(
                        startMilis + newTask.estimatedDays * secondsPerDay,
                    );

                    allTasksDays += newTask.estimatedDays;

                    ns.children.push(newTask);
                }

                ns.estimatedStartDate = new Date(newStartDate);
                ns.estimatedDays = allTasksDays;
                ns.estimatedEndDate =
                    newStartDate + allTasksDays * secondsPerDay;

                // Update days passed
                daysPassed += Number(ns.estimatedDays);

                newSubmilestones.push(ns);
            }

            return newSubmilestones;
        } else setMilestones([]);
    };

    let schemaMilestone = yup.object().shape({
        // descriptionMilestone: yup
        //     .object()
        //     .typeError(t('The description is required!'))
        //     .required(t('The description is required!')),
        responsible: yup
            .array()
            .typeError(
                t('Choosing at least one Milestone responsible is mandatory!'),
            )
            .min(
                1,
                t('Choosing at least one Milestone responsible is mandatory!'),
            )
            .required(
                t('Choosing at least one Milestone responsible is mandatory!'),
            ),
        supervisor: yup
            .array()
            .typeError(
                t('Choosing at least one Milestone supervisor is mandatory!'),
            )
            .min(
                1,
                t('Choosing at least one Milestone supervisor is mandatory!'),
            )
            .required(
                t('Choosing at least one Milestone supervisor is mandatory!'),
            ),
        watcher: yup
            .array()
            .typeError(
                t(
                    'Choosing at least one Milestone watcher/beneficiary is mandatory!',
                ),
            )
            .min(
                1,
                t(
                    'Choosing at least one Milestone watcher/beneficiary is mandatory!',
                ),
            )
            .required(
                t(
                    'Choosing at least one Milestone watcher/beneficiary is mandatory!',
                ),
            ),
        titleMilestone: yup
            .string()
            .typeError(t('The name of the Milestone is mandatory!'))
            .required(t('The name of the Milestone is mandatory!')),
        milestone: yup
            .array()
            .of(
                yup.object().shape({
                    estimatedDays: yup
                        .number()
                        .typeError(
                            t(
                                'The Sub-Milestone must have allocated the required number of days for completion!',
                            ),
                        )
                        .required(
                            t(
                                'The Sub-Milestone must have allocated the required number of days for completion!',
                            ),
                        ),
                    name: yup
                        .string()
                        .typeError(t('Sub-Milestone name is mandatory!'))
                        .required(t('Sub-Milestone name is mandatory!')),
                    mileStoneResponsible: yup
                        .array()
                        .of(
                            yup
                                .number()
                                .typeError(
                                    t(
                                        'Choosing at least one Sub-Milestone responsible is mandatory!',
                                    ),
                                ),
                        )
                        .min(
                            1,
                            t(
                                'Choosing at least one Sub-Milestone responsible is mandatory!',
                            ),
                        )
                        .required(
                            t(
                                'Choosing at least one Sub-Milestone responsible is mandatory!',
                            ),
                        ),
                    children: yup
                        .array()
                        .of(
                            yup.object().shape({
                                estimatedDays: yup
                                    .number()
                                    .typeError(
                                        t(
                                            'The Task must have allocated the required number of days for completion!',
                                        ),
                                    )
                                    .required(
                                        t(
                                            'The Task must have allocated the required number of days for completion!',
                                        ),
                                    ),
                                name: yup
                                    .string()
                                    .typeError(t('Task name is mandatory!'))
                                    .required(t('Task name is mandatory!')),
                                priority: yup
                                    .number()
                                    .typeError(
                                        t('Priority for the task is required!'),
                                    )
                                    .required(
                                        t('Priority for the task is required!'),
                                    ),
                                mileStoneResponsible: yup
                                    .array()
                                    .of(
                                        yup
                                            .number()
                                            .typeError(
                                                t(
                                                    'Choosing at least one Task responsible is mandatory!',
                                                ),
                                            ),
                                    )
                                    .min(
                                        1,
                                        t(
                                            'Choosing at least one Task responsible is mandatory!',
                                        ),
                                    )
                                    .required(
                                        t(
                                            'Choosing at least one Task responsible is mandatory!',
                                        ),
                                    ),
                            }),
                        )
                        .min(
                            1,
                            t('Sub-Milestone must contain at least one task'),
                        ),
                }),
            )
            .min(1, t('The Milestone must have at least one Sub - Milestone!')),
    });

    const createPMTaskManagementMilestone = async () => {
        if (taskManagementContext.isCreating) return;

        try {
            await schemaMilestone.validate({
                titleMilestone,
                responsible,
                // commentMilestone,
                supervisor,
                watcher,
                milestone: milestones.map((m) => m),
            });

            try {
                const formData = new FormData();
                const htmlContent = DOMPurify.sanitize(
                    draftToHtml(
                        convertToRaw(descriptionMilestone.getCurrentContent()),
                    ).replaceAll('color: currentcolor;', ''),
                );

                const reqBody = {
                    milestoneId: milestoneParentId ?? undefined,
                    title: titleMilestone,
                    description: htmlContent,
                    note: '-',
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible, tenantUsers),
                    watcher: getUsersIds(watcher, tenantUsers),
                    supervisor: getUsersIds(supervisor, tenantUsers),
                    partnerId: partnerId ? partnerId : undefined,
                    pmDepartmentId: departmentId,
                    pmProjectPlanId: projectId,
                    tasks: milestones?.map((m) => ({
                        mileStoneTaskId:
                            typeof m.id === 'string' ? m.id : undefined,
                        name: m.name,
                        estimatedStartDate: m.estimatedStartDate,
                        estimatedEndDate: m.estimatedEndDate,
                        responsible: getUsersIds(
                            m.mileStoneResponsible,
                            tenantUsers,
                        ),
                        subTasks: m?.children?.map((c) => ({
                            mileStoneTaskId:
                                typeof c.id === 'string' ? c.id : undefined,
                            name: c.name,
                            priority:
                                c.priority === 0
                                    ? 'LOW'
                                    : c.priority === 1
                                      ? 'MEDIUM'
                                      : c.priority === 2
                                        ? 'HIGH'
                                        : '',
                            estimatedStartDate: c.estimatedStartDate,
                            estimatedEndDate: c.estimatedEndDate,
                            responsible: getUsersIds(
                                c.mileStoneResponsible,
                                tenantUsers,
                            ),
                        })),
                    })),
                };

                formData.append('data', JSON.stringify(reqBody));
                taskManagementContext.submitTaskManagementActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };
    /**
     * Sarcina simpla
     */
    const titleOptions = [
        t('Phone'),
        t('Office meeting'),
        t('Visit the property'),
        t('Whatsapp offer'),
        t('Mail Offer'),
        t('Pre-contract signing'),
    ];

    /**
     * Pre-defined names for activity
     */
    useEffect(() => {
        if (selectedTitle.length) setTitle(titleOptions[selectedTitle[0]]);
    }, [selectedTitle]);

    /**
     * Create Activity Function
     */
    let schema = yup.object().shape({
        responsible: yup
            .array()
            .typeError(t('You must select at least one responsible!'))
            .min(1, t('You must select at least one responsible!'))
            .required(t('You must select at least one responsible!')),
        startDate: yup
            .date()
            .typeError(t('Start date for the task is mandatory!'))
            .required(t('Start date for the task is mandatory!')),
        endDate: yup
            .date()
            .typeError(t('End date for the task is mandatory!'))
            .required(t('End date for the task is mandatory!')),
        description: yup
            .string(t('The description is required!'))
            .trim()
            .required(t('The description is required!')),
        title: yup
            .string(t('Task name is mandatory!'))
            .trim()
            .min(3, t('Task name must contain at least 3 characters!'))
            .required(),
    });

    const createPMTaskManagementTasks = async () => {
        if (taskManagementContext.isCreating) return;

        try {
            await schema.validate({
                title,
                description,
                responsible,
                startDate,
                endDate,
            });

            if (
                getDayBeginningAndEnding(startDate).startDate.getTime() <
                getDayBeginningAndEnding(new Date()).startDate.getTime()
            ) {
                enqueueSnackbar(t('Task can not have a date from the past'), {
                    variant: 'error',
                });
                return;
            } else if (
                getDayBeginningAndEnding(endDate).endDate.getTime() <
                getDayBeginningAndEnding(new Date()).endDate.getTime()
            ) {
                enqueueSnackbar(t('Task can not have a date from the past'), {
                    variant: 'error',
                });
                return;
            } else if (
                getDayBeginningAndEnding(startDate).startDate.getTime() >
                getDayBeginningAndEnding(endDate).endDate.getTime()
            ) {
                enqueueSnackbar(t('Task can not have a date from the past'), {
                    variant: 'error',
                });
                return;
            }

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description,
                    note: '-',
                    actionType: 'TASK',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: getUsersIds(responsible, tenantUsers),
                    supervisor: [],
                    watcher: [],
                    partnerId: partnerId ? partnerId : undefined,
                    pmDepartmentId: departmentId,
                    pmProjectPlanId: projectId,
                    startDate: startDate,
                    endDate: endDate,
                };

                formData.append('data', JSON.stringify(reqBody));
                taskManagementContext.submitTaskManagementActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            if (err.errors) {
                enqueueSnackbar(err.errors[0], { variant: 'error' });
            }
        }
    };

    return (
        <div>
            <div className="mb-5">
                <Tabs
                    tabs={[t('Simple task'), 'Milestone']}
                    activeTab={activityType}
                    setActiveTab={setActivityType}
                />
            </div>

            {activityType === 0 && (
                <form>
                    <div className="mb-3">
                        <TextField
                            placeholder={t('Task name')}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                setSelectedTitle([]);
                            }}
                        />
                    </div>

                    <div className="mb-5">
                        <TextField
                            placeholder={t('Add description')}
                            multiline
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows={3}
                        />
                    </div>

                    <div className="mb-5">
                        <div className="flex items-center gap-5">
                            <LabelWrapper label={t('From')}>
                                <DatePicker
                                    date={startDate}
                                    setDate={setStartDate}
                                    variant="black"
                                />
                            </LabelWrapper>

                            <LabelWrapper label={t('Until')}>
                                <DatePicker
                                    date={endDate}
                                    setDate={setEndDate}
                                    variant="black"
                                />
                            </LabelWrapper>
                        </div>
                    </div>

                    <div className="mb-10 w-1/3 sm:w-full">
                        <LabelWrapper label={t('Responsible')}>
                            <MultiDropdown
                                variant="black"
                                options={tenantUsers.map(
                                    (user) => user.profile?.name,
                                )}
                                placeholder={t('Choose user')}
                                selectedOptions={responsible}
                                setSelectedOptions={(i) => {
                                    if (responsible) {
                                        if (responsible.indexOf(i) > -1) {
                                            setResponsible(
                                                responsible.filter(
                                                    (opt) => opt !== i,
                                                ),
                                            );
                                        } else {
                                            setResponsible([...responsible, i]);
                                        }
                                    }
                                }}
                            />
                        </LabelWrapper>
                    </div>

                    {/* Choose Affiliate */}
                    {projectAffiliates.length > 0 && (
                        <div className="mb-10 inline-block">
                            <LabelWrapper label="Choose affiliate">
                                <Dropdown
                                    placeholder="Choose affiliate"
                                    options={projectAffiliates.map(
                                        (a, i) => `Affiliate no. ${i + 1}`,
                                    )}
                                    selectedOption={selectedAffiliate}
                                    setSelectedOption={setSelectedAffiliate}
                                />
                            </LabelWrapper>
                        </div>
                    )}

                    <div className="flex gap-2">
                        <Button
                            color="secondary"
                            startIcon={<AddIcon />}
                            onClick={createPMTaskManagementTasks}
                        >
                            {t('Add task')}
                        </Button>

                        <Button color="secondary" onClick={() => closeModal()}>
                            {t('Cancel')}
                        </Button>
                    </div>
                </form>
            )}

            {activityType === 1 && (
                <form>
                    <div className="mb-5">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxMilestones}
                                    onChange={() =>
                                        setCheckboxMilestones(
                                            !checkboxMilestones,
                                        )
                                    }
                                    name="email"
                                    style={{
                                        color: `rgb(${theme['UI-COLORS']['MAIN-PRIMARY'].color})`,
                                    }}
                                />
                            }
                            label={
                                <p className="user-select-none">
                                    {t('Pick a preconfigured milestone')}
                                </p>
                            }
                        />
                    </div>

                    {checkboxMilestones === true && (
                        <div className="relative z-50 mb-5">
                            <Dropdown
                                options={milestonesNames}
                                selectedOption={selectedMilestone}
                                setSelectedOption={setSelectedMilestone}
                                placeholder={t('Add from Milestones')}
                                variant="black"
                            />
                            <p className="mt-2 text-disabled">
                                {t(
                                    '* If you choose a template, all the fields below will be preconfigured.',
                                )}
                            </p>
                        </div>
                    )}

                    <div className="mb-10">
                        <TextField
                            placeholder={t('Milestone name')}
                            value={titleMilestone}
                            onChange={(e) => setTitleMilestone(e.target.value)}
                        />
                    </div>

                    <div className="mb-10">
                        <LabelWrapper
                            label={t('Add description for the Milestone')}
                        >
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorState={descriptionMilestone}
                                onEditorStateChange={setDescriptionMilestone}
                                toolbarStyle={{
                                    margin: 0,
                                    padding: 0,
                                    borderRadius: 0,
                                    color: 'black',
                                    border: 'none',
                                    backgroundColor: 'inherit',
                                }}
                                wrapperStyle={{
                                    width: '100%',
                                }}
                                editorStyle={{
                                    borderRadius: 0,
                                    backgroundColor: 'white',
                                    padding: '0px 8px',
                                    color: 'black',
                                    minHeight: '20rem',
                                    maxHeight: width <= 750 ? '' : '40rem',
                                    lineHeight: 1.2,
                                }}
                                toolbar={{
                                    options: [
                                        'inline',
                                        'blockType',
                                        'fontSize',
                                        'list',
                                        'textAlign',
                                        'colorPicker',
                                        'link',
                                        'remove',
                                        'history',
                                    ],
                                }}
                            />
                        </LabelWrapper>
                    </div>

                    {/* Submilestones */}
                    <div className="relative z-40 mb-10">
                        {milestones?.length > 0 && (
                            <div>
                                {milestones.map((subMile, index) => (
                                    <div
                                        key={subMile.id}
                                        className="relative"
                                        style={{
                                            position: 'relative',
                                            zIndex:
                                                milestones.length - index + 1,
                                        }}
                                    >
                                        <SubMilestoneActivity
                                            onDelete={deleteMilestone}
                                            onUpdate={updateMilestone}
                                            milestone={subMile}
                                            allUsers={tenantUsers.map(
                                                (user) => user.profile?.name,
                                            )}
                                            letter={String.fromCharCode(
                                                index + 65,
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                        <Button
                            startIcon={<AddIcon />}
                            fullWidth
                            onClick={() => {
                                if (milestones === undefined) {
                                    setMilestones([]);
                                    addMilestone();
                                } else {
                                    addMilestone();
                                }
                            }}
                            color="primary"
                        >
                            {t('Add Sub-Milestone')}
                        </Button>
                    </div>

                    {/* 
                        Bottom dropdowns
                    */}
                    <div className="relative z-30 mb-4 flex gap-4">
                        <LabelWrapper label={t('From')}>
                            <DatePicker
                                date={milestoneStartDate}
                                setDate={(e) => {
                                    setMilestoneStartDate(e);
                                    setMilestones((prev) =>
                                        calculateDuration(prev, e),
                                    );
                                }}
                                variant="black"
                            />
                        </LabelWrapper>
                        <LabelWrapper label={t('Up to')}>
                            <DatePicker
                                disabled
                                date={
                                    milestones?.length > 0 &&
                                    milestones[milestones.length - 1]
                                        .estimatedEndDate
                                }
                                variant="black"
                            />
                        </LabelWrapper>
                    </div>

                    <div className="mb-10 flex gap-4">
                        <LabelWrapper label={t('Responsible')}>
                            <MultiDropdown
                                variant="black"
                                options={tenantUsers.map(
                                    (user) => user.profile?.name,
                                )}
                                placeholder={t('Choose user')}
                                selectedOptions={responsible}
                                setSelectedOptions={(i) => {
                                    if (responsible?.indexOf(i) > -1) {
                                        setResponsible(
                                            responsible.filter(
                                                (opt) => opt !== i,
                                            ),
                                        );
                                    } else {
                                        setResponsible([...responsible, i]);
                                    }
                                }}
                            />
                        </LabelWrapper>

                        <LabelWrapper label={t('Supervisor')}>
                            <MultiDropdown
                                variant="black"
                                options={tenantUsers.map(
                                    (user) => user.profile?.name,
                                )}
                                placeholder={t('Choose user')}
                                selectedOptions={supervisor}
                                setSelectedOptions={(i) => {
                                    if (supervisor?.indexOf(i) > -1) {
                                        setSupervisor(
                                            supervisor.filter(
                                                (opt) => opt !== i,
                                            ),
                                        );
                                    } else {
                                        setSupervisor([...supervisor, i]);
                                    }
                                }}
                            />
                        </LabelWrapper>

                        <LabelWrapper label={t('Watcher / Beneficiary')}>
                            <MultiDropdown
                                variant="black"
                                options={tenantUsers.map(
                                    (user) => user.profile?.name,
                                )}
                                placeholder={t('Choose user')}
                                selectedOptions={watcher}
                                setSelectedOptions={(i) => {
                                    if (watcher?.indexOf(i) > -1) {
                                        setWatcher(
                                            watcher.filter((opt) => opt !== i),
                                        );
                                    } else {
                                        setWatcher([...watcher, i]);
                                    }
                                }}
                            />
                        </LabelWrapper>
                    </div>

                    <div className="flex items-center gap-2">
                        <Button
                            color="secondary"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                if (milestoneInfo) {
                                    const myMilestone = {
                                        name: titleMilestone,
                                        description: descriptionMilestone,
                                        mileStoneTask: [],
                                    };

                                    const confMilestone = {
                                        name: milestoneInfo.name,
                                        description: milestoneInfo.description,
                                        mileStoneTask: [],
                                    };

                                    /**
                                     * Construim in functie de milestone-ul nostru
                                     */
                                    for (const submilestone of milestones) {
                                        const newSubmilestone = {};
                                        newSubmilestone.name =
                                            submilestone.name;
                                        newSubmilestone.children = [];

                                        for (const task of submilestone.children) {
                                            newSubmilestone.children.push({
                                                name: task.name,
                                            });
                                        }

                                        myMilestone.mileStoneTask.push(
                                            newSubmilestone,
                                        );
                                    }

                                    /**
                                     * Construim in functie de milestone-ul din configurat
                                     */

                                    for (const submilestone of milestoneInfo.mileStoneTask) {
                                        const newSubmilestone = {};
                                        newSubmilestone.name =
                                            submilestone.name;
                                        newSubmilestone.children = [];

                                        for (const task of submilestone.children) {
                                            newSubmilestone.children.push({
                                                name: task.name,
                                            });
                                        }

                                        confMilestone.mileStoneTask.push(
                                            newSubmilestone,
                                        );
                                    }

                                    if (
                                        JSON.stringify(confMilestone) ===
                                        JSON.stringify(myMilestone)
                                    ) {
                                        setMilestoneParentId(milestoneInfo.id);
                                    }
                                }

                                createPMTaskManagementMilestone();
                            }}
                        >
                            {t('Add milestone')}
                        </Button>

                        <Button color="secondary" onClick={() => closeModal()}>
                            {t('Cancel')}
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

PMTaskManagementTasks.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    closeModal: PropTypes.func,
    beneficiaryCheck: PropTypes.bool,
};

PMTaskManagementTasks.defaultProps = {
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    closeModal: () => null,
    beneficiaryCheck: false,
};

export default PMTaskManagementTasks;
