import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import UserActivity from 'components/crm/users/user-activity';
import UserDetails from 'components/crm/users/user-details';
import UserPassword from 'components/crm/users/user-password';
import UserContext from 'contexts/UserContext';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const EditUser = () => {
    const { user } = useContext(UserContext);
    const { id: userId } = useParams();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(1);
    const tabs = [t('User activity'), t('User details')];
    const [userInfo, setUserInfo] = useState(null);

    const params = new URL(document.location).searchParams;
    const tab = Number(params.get('tab'));

    useEffect(() => {
        setActiveTab(tab);
    }, [tab]);

    if (user?.id === userId) tabs.push(t('Change the password'));

    const getUserById = async () => {
        try {
            const response = await API.get(`/user/${userId}`);
            setUserInfo(response.data.user);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getUserById();
    }, [userId]);

    const [userActions, setUserActions] = useState([]);

    const getUserActivity = async () => {
        try {
            const response = await API.get(`/user_actions/${userId}`);
            setUserActions(response.data.userActivity);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getUserActivity();
    }, []);

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {`${userInfo?.profile?.name}`}</title>
            </Helmet>

            <Header
                pageTitle={
                    <div className="flex items-center">
                        <div
                            className="mr-6 h-20 w-20 rounded-full bg-cover bg-center"
                            style={{
                                backgroundImage: `url(${userInfo?.profileImage})`,
                            }}
                        />
                        {/* <img src={userInfo?.profileImage} className="w-16 h-16 mr-6 rounded-full" /> */}
                        {userInfo?.profile?.name}
                    </div>
                }
            />

            <div className="page-container">
                <div className="flex items-center">
                    <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                </div>
                {activeTab === 0 ? (
                    <UserActivity userActions={userActions} />
                ) : activeTab === 1 ? (
                    <UserDetails editableField={false} userInfo={userInfo} />
                ) : (
                    <UserPassword />
                )}
            </div>
        </>
    );
};

export default EditUser;
