import React, { useContext, useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import API from 'utils/axios';

const CompleteAccountRow = ({ userInfo, deleteUserFromProject, canAll }) => {
    const { t } = useTranslation();
    const confirm = useConfirm();

    return (
        <TableRow>
            <TableCell>
                <div className="flex items-center">
                    <div
                        className="mr-4 h-12 w-12 flex-shrink-0 rounded-full bg-cover bg-center"
                        style={{
                            backgroundImage: `url(${userInfo?.profileImageUrl})`,
                        }}
                    />
                    {userInfo.User.profile?.name}
                </div>
            </TableCell>
            <TableCell>{userInfo.User.Role.name}</TableCell>
            <TableCell>
                {userInfo.User.isActive ? t('active') : t('inactive')}
            </TableCell>
            <TableCell>{userInfo.User.profile?.phoneNo}</TableCell>
            <TableCell>{userInfo.User.email || ''}</TableCell>
            <TableCell>{userInfo.User.observation}</TableCell>
            <TableCell>
                {canAll && (
                    <div className="flex justify-end">
                        <Button
                            size="small"
                            startIcon={<DeleteIcon />}
                            onClick={() =>
                                confirm(
                                    t(
                                        'Are you sure you want to delete this completed account from project?',
                                    ),
                                    () =>
                                        deleteUserFromProject(userInfo.userId),
                                )
                            }
                        >
                            {t('Delete user from project')}
                        </Button>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};

CompleteAccountRow.propTypes = {
    userInfo: PropTypes.object,
    deleteUserFromProject: PropTypes.func,
    canAll: PropTypes.bool,
};

CompleteAccountRow.defaultProps = {
    userInfo: null,
    deleteUserFromProject: () => null,
    canAll: false,
};

export default function DisplayCompletedAccountsOnProject({
    completedAccounts,
    getResources,
}) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { projectId } = useParams();
    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '61',
            permissionType: 'ALL',
        },
    ]);

    const [loading, setLoading] = useState(false);

    const deleteUserFromProject = async (userId) => {
        try {
            await API.delete(
                `/ProjectUsers?&userId=${userId}&pmProjectId=${projectId}`,
            );
            enqueueSnackbar(
                t('Completed account deleted from project successfully!'),
                { variant: 'success' },
            );
            getResources();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('PM Configurator')} | {t('Completed accounts')}
                </title>
            </Helmet>

            {/* <Pagination getFunction={getAccounts}> */}
            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('User')}</TableCell>
                                <TableCell>{t('Role')}</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>{t('Phone')}</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>{t('Observations')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableSeparator />
                            {completedAccounts.map((user) => (
                                <CompleteAccountRow
                                    key={user.id}
                                    userInfo={user}
                                    deleteUserFromProject={
                                        deleteUserFromProject
                                    }
                                    canAll={canAll}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {/* </Pagination> */}
        </React.Fragment>
    );
}

DisplayCompletedAccountsOnProject.propTypes = {
    pmId: PropTypes.string,
    completedAccounts: PropTypes.array,
    getResources: PropTypes.func,
};

DisplayCompletedAccountsOnProject.defaultProps = {
    pmId: '',
    completedAccounts: [],
    getResources: () => null,
};
