import React, { useState } from 'react';

import { EllipsisVertical, Eye, Trash } from 'lucide-react';

import { TableCell } from '@material-ui/core';

import ActionsPopover from 'components/shared/actions-popover/actions-popover';
import Avatar from 'components/shared/avatar/avatar';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ExpandableRow } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { errorHandling, formatDate, generateUUID } from 'utils';
import {
    handleAddIndexes,
    handleCancelUpdateIndexes,
    handleChangeIndexes,
    handleChangeMonitorIndexes,
    handleRemoveIndexes,
    handleUpdateIndexes,
} from 'utils/kpiUtils';

import KpiIndexesContent from '../components/kpi-indexes-content';

const KpiNomenclatureUsersRow = ({ user, kpi, onChangeKpi, disabled }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { users, usersIndexes } = kpi;
    const { id, userId, profile, indexes, createAt = new Date(), updateAt = new Date() } = user;
    const { name, profileImageUrl } = profile;

    const [loading, setLoading] = useState(false);

    const handleDelete = () => {
        try {
            setLoading(true);

            //TODO: Add API call to delete current users if we have an id

            onChangeKpi(
                'users',
                users.filter((user) => user.userId !== userId),
            );
        } catch (error) {
            console.log(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleMonitor = (e, indexesId, indexes) => {
        const userIndexes = indexes.slice(usersIndexes.length);

        onChangeKpi(
            'users',
            users.map((user) =>
                user.userId === userId
                    ? {
                          ...user,
                          indexes: handleChangeMonitorIndexes(e, indexesId, userIndexes),
                      }
                    : user,
            ),
        );
    };

    const handleRemove = (indexesId, indexes) => {
        try {
            setLoading(true);

            const userIndexes = indexes.slice(usersIndexes.length);

            //TODO: Add API call to delete current indexes if we have a indexesId

            onChangeKpi(
                'users',
                users.map((user) =>
                    user.userId === userId
                        ? {
                              ...user,
                              indexes: handleRemoveIndexes(indexesId, userIndexes),
                          }
                        : user,
                ),
            );
        } catch (error) {
            console.log(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e, indexesId, indexes) => {
        const userIndexes = indexes.slice(usersIndexes.length);

        onChangeKpi(
            'users',
            users.map((user) =>
                user.userId === userId
                    ? {
                          ...user,
                          indexes: handleChangeIndexes(e, indexesId, userIndexes),
                      }
                    : user,
            ),
        );
    };

    const handleAdd = (indexes) => {
        const userIndexes = indexes.slice(usersIndexes.length);

        onChangeKpi(
            'users',
            users.map((user) =>
                user.userId === userId
                    ? {
                          ...user,
                          indexes: handleAddIndexes(userIndexes),
                      }
                    : user,
            ),
        );
    };

    const handleUpdate = async (indexesId, indexes) => {
        try {
            setLoading(true);

            const userIndexes = indexes.slice(usersIndexes.length);

            //TODO: Add API call to update current indexes
            const newItem = {
                createAt: new Date(),
                updateAt: new Date(),
                ...userIndexes.find((item) => item.id === indexesId),
                id: indexesId ?? generateUUID(),
            };

            onChangeKpi(
                'users',
                users.map((user) =>
                    user.userId === userId
                        ? {
                              ...user,
                              // TODO: This is only a simulation, here we need to replace with the object coming form backend in the future
                              indexes: handleUpdateIndexes(indexesId, newItem, userIndexes),
                          }
                        : user,
                ),
            );

            return newItem;
        } catch (error) {
            console.log(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCancelUpdate = (indexesId, newItem, indexes) => {
        const userIndexes = indexes.slice(usersIndexes.length);

        onChangeKpi(
            'users',
            users.map((user) =>
                user.userId === userId
                    ? {
                          ...user,
                          indexes: handleCancelUpdateIndexes(indexesId, newItem, userIndexes),
                      }
                    : user,
            ),
        );
    };

    const actions = [
        {
            icon: <Eye />,
            text: t('View'),
            action: () =>
                history.push(configurator.base + configurator.users.base + configurator.users.update + '/' + user.id),
            confirm: false,
        },
        {
            icon: <Trash />,
            text: t('Delete'),
            action: () => handleDelete(),
            confirm: true,
        },
    ];

    return (
        <ExpandableRow
            padding={false}
            row={
                <>
                    <TableCell className="items-center gap-4" style={{ display: 'flex' }}>
                        <div className="h-14 w-14">
                            <Avatar url={profileImageUrl} />
                        </div>
                        {name}
                    </TableCell>
                    <TableCell>{formatDate(createAt)}</TableCell>
                    <TableCell>{formatDate(updateAt)}</TableCell>
                </>
            }
            customLastCell={
                <TableCell>
                    <div className="flex h-10 justify-end">
                        <div onClick={(e) => e.stopPropagation()}>
                            <ActionsPopover
                                icon={<EllipsisVertical className="h-6 w-6" />}
                                opacity={{ default: 0.8, hover: 1 }}
                                actions={actions}
                                disabled={disabled || loading}
                            />
                        </div>
                    </div>
                </TableCell>
            }
            collapse={
                <KpiIndexesContent
                    update={Boolean(id)}
                    indexes={[...usersIndexes, ...indexes]}
                    onChangeIndexes={handleChange}
                    onAddIndexes={handleAdd}
                    onChangeMonitorIndexes={handleMonitor}
                    onRemoveIndexes={handleRemove}
                    onUpdateIndexes={handleUpdate}
                    onCancelUpdateIndexes={handleCancelUpdate}
                    monitor={true}
                    remove={true}
                    operations={true}
                    disabled={disabled || loading}
                    ignoreToday={indexes.length === 0}
                    disabledIndexes={Array.from({ length: usersIndexes.length }, (_, i) => i)}
                />
            }
        />
    );
};

KpiNomenclatureUsersRow.propTypes = {
    user: PropTypes.object,
    kpi: PropTypes.object,
    onChangeKpi: PropTypes.func,
    disabled: PropTypes.bool,
};

KpiNomenclatureUsersRow.defaultProps = {
    user: null,
    kpi: null,
    onChangeKpi: () => {},
    disabled: false,
};

export default KpiNomenclatureUsersRow;
