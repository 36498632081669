import React, { cloneElement, useState } from 'react';

import PropTypes from 'prop-types';

import FilterAdd from './filter-add';

const breakpointClasses = {
    sm: {
        wrapper: 'sm:shadow-none sm:border sm:border-main-text',
    },
    md: {
        wrapper: 'md:shadow-none md:border md:border-main-text',
    },
    lg: {
        wrapper: 'lg:shadow-none lg:border lg:border-main-text',
    },
    xl: {
        wrapper: 'xl:shadow-none xl:border xl:border-main-text',
    },
    '2xl': {
        wrapper: '2xl:shadow-none 2xl:border 2xl:border-main-text',
    },
};

const FilterList = ({ relations, list, filter, onChangeFilter, disabled, mobileBP }) => {
    const [operatingRow, setOperatingRow] = useState(null);
    const handleOperatingRow = (index) => setOperatingRow((prev) => (prev !== index ? index : null));

    return (
        <>
            <div
                className={`flex h-full flex-col gap-2 rounded-xl bg-layout-light px-4 py-3 shadow ${breakpointClasses[mobileBP].wrapper}`}
                style={{
                    minWidth: '200px',
                }}
            >
                {list.map((option, index) => (
                    <div
                        className={`flex cursor-pointer items-center gap-2.5 rounded-lg bg-transparent px-3.5 py-2.5 transition-all hover:bg-layout-transparent-dark ${
                            operatingRow === index && !disabled ? 'bg-layout-transparent' : ''
                        } ${disabled ? 'pointer-events-none' : ''}`}
                        key={`${option.id}-${index}`}
                        onClick={() => handleOperatingRow(index)}
                    >
                        {cloneElement(option.icon, {
                            className: `flex-shrink-0 ${
                                disabled
                                    ? 'text-disabled'
                                    : operatingRow === index
                                      ? 'text-secondary-light'
                                      : 'text-main-text'
                            }`,
                            style: {
                                fontSize: '2.25rem',
                            },
                        })}
                        <p
                            className={`text-xl font-semibold ${
                                disabled
                                    ? 'text-disabled'
                                    : operatingRow === index
                                      ? 'text-secondary-light'
                                      : 'text-main-text'
                            }`}
                        >
                            {option.label}
                        </p>
                    </div>
                ))}
            </div>
            {operatingRow !== null ? (
                Array.isArray(list[operatingRow].list) ? (
                    <FilterList
                        key={`${list[operatingRow].id}-${operatingRow}`}
                        mobileBP={mobileBP}
                        disabled={disabled}
                        relations={[...relations, `${list[operatingRow].id}-${operatingRow}`]}
                        list={list[operatingRow].list}
                        filter={filter}
                        onChangeFilter={onChangeFilter}
                    />
                ) : (
                    <FilterAdd
                        key={`${list[operatingRow].id}-${operatingRow}`}
                        mobileBP={mobileBP}
                        disabled={disabled}
                        relations={[...relations, `${list[operatingRow].id}-${operatingRow}`]}
                        element={list[operatingRow]}
                        filter={filter}
                        onChangeFilter={onChangeFilter}
                    />
                )
            ) : null}
        </>
    );
};

FilterList.propTypes = {
    relations: PropTypes.array,
    list: PropTypes.array,
    filter: PropTypes.array,
    onChangeFilter: PropTypes.func,
    disabled: PropTypes.bool,
    mobileBP: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
};

FilterList.defaultProps = {
    relations: [],
    list: [],
    filter: [],
    onChangeFilter: () => {},
    disabled: false,
    mobileBP: 'sm',
};

export default FilterList;
