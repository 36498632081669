import React from 'react';

import { TextField } from '@material-ui/core';

import PropTypes from 'prop-types';
import { Dropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, formatPositiveNumber } from 'utils';
import { handleUpdateFormulaElement } from 'utils/kpiUtils';

const KpiFormulaItemSelection = ({
    item,
    elementId,
    formula,
    editable,
    onChangeKpi,
    disabled,
    options,
}) => {
    const { t } = useTranslation();
    const { key, value, input, item: nextItem } = item || {};

    const { selection, Icon, nextOptions } = (() => {
        const index = options.findIndex((o) => o.key === key);
        if (index < 0) return { selection: null, icon: null, nextOptions: [] };

        const { icon: Icon, options: nextOptions } = options[index];

        return { selection: index, Icon, nextOptions };
    })();

    const handleUpdateChain = (current, targetKey, newItem) => {
        if (current.key === targetKey) {
            return { ...newItem };
        }
        if (!current.item) {
            return { ...current, item: newItem };
        }

        return {
            ...current,
            item: handleUpdateChain(current.item, targetKey, newItem),
        };
    };

    const handleChangeSelection = (i) => {
        const { key: optionKey, input: optionInput } = options[i];
        const newItem = {
            key: optionKey,
            input: optionInput,
            value: optionInput ? 0 : null,
            item: null,
        };

        const currentChain = formula.elements[elementId].item;
        const updatedChain = currentChain
            ? handleUpdateChain(structuredClone(currentChain), key, newItem)
            : newItem;

        onChangeKpi(
            'formula',
            handleUpdateFormulaElement(formula, elementId, {
                item: updatedChain,
                filters: [],
            }),
        );
    };

    const handleChangeNumber = (e) => {
        onChangeKpi(
            'formula',
            handleUpdateFormulaElement(formula, elementId, {
                item: {
                    ...item,
                    value: formatPositiveNumber(e.target.value),
                },
                filters: [],
            }),
        );
    };

    return (
        <>
            {editable ? (
                <>
                    <Dropdown
                        disabled={disabled}
                        icon={Icon && <Icon />}
                        variant="black"
                        options={options.map((option) =>
                            t(
                                option.upperCase
                                    ? option.key
                                          .split('-')
                                          .join(' ')
                                          .toUpperCase()
                                    : capitalizeFirstLetter(
                                          option.key.split('-').join(' '),
                                      ),
                            ),
                        )}
                        selectedOption={selection}
                        setSelectedOption={handleChangeSelection}
                    />

                    {input && (
                        <TextField
                            type="number"
                            value={Number(value).toString()}
                            onChange={handleChangeNumber}
                            placeholder={t('Number')}
                        />
                    )}
                </>
            ) : (
                <>
                    <div className="flex gap-3 px-4 py-3">
                        {Icon && <Icon className="h-6 w-6" />}
                        <p className="text-xl font-medium leading-6">
                            {(() => {
                                const { key, upperCase } = options[selection];
                                return upperCase
                                    ? key.split('-').join(' ').toUpperCase()
                                    : capitalizeFirstLetter(
                                          key.split('-').join(' '),
                                      );
                            })()}
                        </p>
                    </div>
                    <hr className="h-px border-layout-transparent-dark" />
                    {input && (
                        <div className="px-4 py-3">
                            <p className="text-xl font-medium leading-6">
                                {value}
                            </p>
                        </div>
                    )}
                </>
            )}

            {nextOptions.length > 0 && (
                <KpiFormulaItemSelection
                    item={nextItem}
                    elementId={elementId}
                    formula={formula}
                    options={nextOptions}
                    editable={editable}
                    onChangeKpi={onChangeKpi}
                    disabled={disabled}
                />
            )}
        </>
    );
};

KpiFormulaItemSelection.propTypes = {
    item: PropTypes.object,
    elementId: PropTypes.string,
    formula: PropTypes.object,
    options: PropTypes.array,
    editable: PropTypes.bool,
    onChangeKpi: PropTypes.func,
    disabled: PropTypes.bool,
};

KpiFormulaItemSelection.defaultProps = {
    item: null,
    elementId: null,
    formula: null,
    options: [],
    editable: false,
    onChangeKpi: () => {},
    disabled: false,
};

export default KpiFormulaItemSelection;
