import React from 'react';

import { Plus } from 'lucide-react';

import BasicTooltip from 'components/shared/basic-tooltip';
import RoundedButton from 'components/shared/rounded-button/rounded-button';
import PropTypes from 'prop-types';
import { Dropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, generateUUID } from 'utils';
import {
    DEFAULT_FORMULA_ELEMENT,
    handleUpdateFormulaElement,
    SIGNS,
} from 'utils/kpiUtils';

const KpiFormulaItemSign = ({
    item,
    elementId,
    formula,
    column,
    index,
    editable,
    onChangeKpi,
    disabled,
    dragging,
}) => {
    const { t } = useTranslation();
    const { sign } = item;
    const Sign = SIGNS[sign];
    const signs = Object.keys(SIGNS);
    const hiddenOptions = signs.reduce(
        (acc, cur, index) =>
            cur === sign || cur === 'equal' ? [...acc, index] : acc,
        [],
    );

    const handleChangeSign = (newSign) => {
        onChangeKpi(
            'formula',
            handleUpdateFormulaElement(formula, elementId, { sign: newSign }),
        );
    };

    const handleAddElement = () => {
        const id = `element-${generateUUID()}`;

        const elementsIds = [...formula.columns[column.id].elementsIds];
        const newElementsIds = [
            ...elementsIds.slice(0, index + 1),
            id,
            ...elementsIds.slice(index + 1),
        ];

        const newColumns = structuredClone(formula.columns);
        newColumns[column.id] = {
            ...column,
            elementsIds: newElementsIds,
        };

        let sign = 'add';
        let previousLastId = null;
        if (index === elementsIds.length - 1) {
            sign = 'equal';
            previousLastId = newElementsIds.at(-2);
        }

        const newElements = structuredClone(formula.elements);
        newElements[id] = {
            ...DEFAULT_FORMULA_ELEMENT,
            id,
            sign,
        };

        if (previousLastId) {
            newElements[previousLastId] = {
                ...newElements[previousLastId],
                sign: 'add',
            };
        }

        onChangeKpi('formula', {
            ...formula,
            columns: newColumns,
            elements: newElements,
        });
    };

    if (!editable) {
        return (
            <div className="px-4 py-11">
                <BasicTooltip
                    tip={t(capitalizeFirstLetter(sign))}
                    disabled={disabled}
                >
                    <Sign className="h-7 w-7 opacity-80" />
                </BasicTooltip>
            </div>
        );
    }

    return (
        <div
            className="flex flex-col px-6"
            style={{
                marginTop: '4.5rem',
                gap: '3.75rem',
            }}
        >
            {sign !== 'equal' ? (
                <BasicTooltip tip={t('Change the sign')} disabled={disabled}>
                    <Dropdown
                        disabled={disabled}
                        onlyIcon
                        variant="black"
                        icon={<Sign />}
                        options={signs.map((key) =>
                            t(capitalizeFirstLetter(key)),
                        )}
                        hiddenOptions={hiddenOptions}
                        selectedOption={(() => {
                            const index = signs.findIndex(
                                (item) => item === sign,
                            );
                            return index >= 0 ? index : null;
                        })()}
                        setSelectedOption={(i) => handleChangeSign(signs[i])}
                    />
                </BasicTooltip>
            ) : (
                <div className="flex h-11 w-11 items-center justify-center">
                    <Sign className="h-7 w-7 opacity-80" />
                </div>
            )}
            <BasicTooltip tip={t('Add new field')} disabled={disabled}>
                <RoundedButton
                    disabled={disabled}
                    variant="dark"
                    height="2.75rem"
                    style={{
                        backgroundColor: dragging ? 'var(--layout-main)' : '',
                    }}
                    icon={
                        <Plus
                            style={{
                                width: '1.75rem',
                                height: '1.75rem',
                                color: 'var(--buttons-text)',
                            }}
                        />
                    }
                    onClick={handleAddElement}
                />
            </BasicTooltip>
        </div>
    );
};

KpiFormulaItemSign.propTypes = {
    item: PropTypes.object,
    elementId: PropTypes.string,
    formula: PropTypes.object,
    column: PropTypes.object,
    index: PropTypes.number,
    editable: PropTypes.bool,
    onChangeKpi: PropTypes.func,
    disabled: PropTypes.bool,
    dragging: PropTypes.bool,
};

KpiFormulaItemSign.defaultProps = {
    item: null,
    elementId: null,
    formula: null,
    column: null,
    index: 0,
    editable: false,
    onChangeKpi: () => {},
    disabled: false,
    dragging: false,
};
export default KpiFormulaItemSign;
