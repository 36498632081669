import React from 'react';

import PropTypes from 'prop-types';
import { getAverage } from 'utils/kpiUtils';

import KpiProgressBar from './kpi-progress-bar';
import KpiWrapper from './kpi-wrapper';

const KpiAverageProgressBar = ({ kpi, period }) => {
    const { data, indexes } = kpi;
    const { selectedDivision } = period;

    const average = getAverage(data[selectedDivision]);
    const currentIndexes = indexes.at(-1).divisions.find((division) => division.type === selectedDivision);

    return (
        <KpiWrapper>
            <div className="p-8">
                <KpiProgressBar average={average} indexes={currentIndexes} detailed />
            </div>
        </KpiWrapper>
    );
};

KpiAverageProgressBar.propTypes = {
    kpi: PropTypes.object,
    period: PropTypes.object,
};

KpiAverageProgressBar.defaultProps = {
    kpi: null,
    period: null,
};

export default KpiAverageProgressBar;
