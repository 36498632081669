import React from 'react';

import ScrollableContainer from 'components/scrollable-container/scrollable-container';
import PropTypes from 'prop-types';
import { getAverageStatus } from 'utils/kpiUtils';

import KpiCardFooter from '../components/kpi-card-footer';
import KpiCardHeader from '../components/kpi-card-header';
import KpiChart from '../components/kpi-chart';
import KpiUser from '../components/kpi-user';
import KpiWrapper from '../components/kpi-wrapper';

const KpiCrmCard = ({ kpi, period }) => {
    const { name, users, usersIndexes } = kpi;
    const { selectedDivision } = period;

    return (
        <div className="w-full">
            <KpiWrapper title={<p className="text-2xl font-semibold leading-7">{name}</p>}>
                <div className="flex items-start sm:flex-col">
                    <div className="flex flex-grow flex-col lg:w-7/12 sm:w-full">
                        <div className="px-6 pb-10 pt-5">
                            <KpiCardHeader kpi={kpi} period={period} />
                        </div>
                        <div className="h-48 px-6 pb-4">
                            <KpiChart kpi={kpi} division={selectedDivision} padding={6} />
                        </div>
                    </div>
                    <div
                        className="w-full max-w-lg border-l border-layout-transparent lg:w-5/12 lg:max-w-none sm:w-full sm:border-l-0 sm:border-t"
                        ref={(node) => {
                            if (!node) return;

                            const siblingHeight = node.previousElementSibling.clientHeight;
                            node.style.height = `${siblingHeight}px`;
                        }}
                    >
                        <ScrollableContainer>
                            <div className="flex flex-col p-2">
                                {users.map((user, index) => {
                                    const { averages, indexes } = user;
                                    const currentIndexes = (
                                        indexes
                                            .slice()
                                            .reverse()
                                            .find((item) => item.monitor) || usersIndexes.at(-1)
                                    ).divisions.find((d) => d.type === selectedDivision);

                                    const average = averages[selectedDivision];
                                    const { color } = getAverageStatus(average, currentIndexes);

                                    return (
                                        <KpiUser
                                            key={user.id}
                                            user={{ ...user, average, color }}
                                            division={selectedDivision}
                                            indexes={currentIndexes}
                                            divider={index !== users.length - 1}
                                        />
                                    );
                                })}
                            </div>
                        </ScrollableContainer>
                    </div>
                </div>
                <div className="border-t border-layout-transparent">
                    <KpiCardFooter kpi={kpi} type="CRM" />
                </div>
            </KpiWrapper>
        </div>
    );
};

KpiCrmCard.propTypes = {
    kpi: PropTypes.object,
    period: PropTypes.object,
};

KpiCrmCard.defaultProps = {
    kpi: null,
    period: null,
};

export default KpiCrmCard;
