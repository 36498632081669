import React, { useState } from 'react';

import { X } from 'lucide-react';

import { Button } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const KpiNomenclatureUsersAdd = ({ open, onClose, users, kpi, onChangeKpi }) => {
    const { t } = useTranslation();
    const { users: kpiUsers = [] } = kpi || {};

    const mappedKpiUsersIds = kpiUsers.map((user) => user.userId);
    const availableUsers = users.filter((user) => !mappedKpiUsersIds.includes(user.id));

    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleAdd = () => {
        onChangeKpi('users', [
            ...kpiUsers,
            ...selectedUsers.map((i) => ({
                ...availableUsers[i],
                id: undefined,
                userId: availableUsers[i].id,
                indexes: [],
            })),
        ]);
        setSelectedUsers([]);
        onClose();
    };

    return (
        <CustomModal open={open}>
            <div className="flex w-full flex-col gap-4 px-12 py-12 sm:px-6">
                <div className="flex items-start justify-between gap-32">
                    <h2>{t('Add users')}</h2>

                    <X
                        className="text-prim ml-10 h-10 w-10 flex-shrink-0 cursor-pointer text-primary-main opacity-75 transition-opacity duration-200 hover:opacity-100"
                        onClick={onClose}
                    />
                </div>

                <div className="flex flex-col gap-6">
                    <LabelWrapper label={t('Select users')}>
                        <MultiDropdown
                            options={availableUsers.map((user) => user.profile.name)}
                            selectedOptions={selectedUsers}
                            setSelectedOptions={(i) => {
                                const present = selectedUsers.findIndex((si) => si === i) >= 0;
                                if (present) setSelectedUsers((prev) => prev.filter((si) => si !== i));
                                else setSelectedUsers((prev) => [...prev, i]);
                            }}
                        />
                    </LabelWrapper>
                </div>

                <div className="mt-6 flex justify-end">
                    <Button onClick={handleAdd}>{t('Add users')}</Button>
                </div>
            </div>
        </CustomModal>
    );
};

KpiNomenclatureUsersAdd.defaultProps = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    users: PropTypes.array,
    kpi: PropTypes.object,
    onChangeKpi: PropTypes.func,
};

KpiNomenclatureUsersAdd.propTypes = {
    open: false,
    onClose: () => {},
    users: [],
    kpi: null,
    onChangeKpi: () => {},
};

export default KpiNomenclatureUsersAdd;
