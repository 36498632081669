import React from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import KpiFormulaItem from './kpi-formula-item';
import KpiWrapper from './kpi-wrapper';

const KpiFormula = ({ kpi, editable, onChangeKpi, disabled }) => {
    const { t } = useTranslation();
    const { formula } = kpi;

    const handleOnDragEnd = (result) => {
        const { source, destination, draggableId } = result;

        if (!destination || destination.index === source.index) return;

        const newColumns = structuredClone(formula.columns);
        const newElementsIds = newColumns[source.droppableId].elementsIds;

        if (!newElementsIds.length > 1) return;

        const newElements = structuredClone(formula.elements);

        if (source.index === newElementsIds.length - 1) {
            const newLastElementId = newElementsIds[source.index - 1];
            const movedElementId = newElementsIds[source.index];
            newElements[movedElementId].sign =
                newElements[newLastElementId].sign;
            newElements[newLastElementId].sign = 'equal';
        }

        if (destination.index === newElementsIds.length - 1) {
            const newLastElementId = newElementsIds[source.index];
            const movedElementId = newElementsIds[destination.index];
            newElements[movedElementId].sign =
                newElements[newLastElementId].sign;
            newElements[newLastElementId].sign = 'equal';
        }

        newElementsIds.splice(source.index, 1);
        newElementsIds.splice(destination.index, 0, draggableId);

        onChangeKpi('formula', {
            ...formula,
            columns: newColumns,
            elements: newElements,
        });
    };

    return (
        <KpiWrapper
            title={
                <p className="text-xl font-bold leading-5 tracking-widest">
                    {t('KPI FORMULA')}
                </p>
            }
        >
            <DragDropContext onDragEnd={handleOnDragEnd}>
                {formula.columnOrder.map((columnId) => {
                    const column = formula.columns[columnId];
                    const elements = column.elementsIds.map(
                        (elementId) => formula.elements[elementId],
                    );

                    return (
                        <Droppable
                            key={columnId}
                            droppableId={columnId}
                            direction="horizontal"
                        >
                            {(provided) => {
                                return (
                                    <div
                                        className="flex overflow-y-auto p-8"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {elements.map((item, index) => {
                                            const { id } = item;

                                            return (
                                                <KpiFormulaItem
                                                    key={id}
                                                    item={item}
                                                    formula={formula}
                                                    column={column}
                                                    editable={editable}
                                                    onChangeKpi={onChangeKpi}
                                                    disabled={disabled}
                                                    index={index}
                                                />
                                            );
                                        })}

                                        {provided.placeholder}

                                        <div
                                            className="self-start rounded-xl border border-layout-transparent bg-layout-transparent-dark px-5 py-3"
                                            style={{
                                                marginTop: editable
                                                    ? '4.25rem'
                                                    : '2rem',
                                            }}
                                        >
                                            KPI
                                        </div>
                                    </div>
                                );
                            }}
                        </Droppable>
                    );
                })}
            </DragDropContext>
        </KpiWrapper>
    );
};

KpiFormula.propTypes = {
    kpi: PropTypes.object,
    editable: PropTypes.bool,
    onChangeKpi: PropTypes.func,
    disabled: PropTypes.bool,
};

KpiFormula.defaultProps = {
    kpi: null,
    editable: false,
    onChangeKpi: () => {},
    disabled: false,
};

export default KpiFormula;
