import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import FolderSharedIcon from '@material-ui/icons/FolderShared';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ExpandableRow, TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator, projectManagement } from 'routes';
import { formatDate } from 'utils';
import API from 'utils/axios';

const DepartmentsRow = ({ department }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '14',
            permissionType: 'ALL',
        },
    ]);

    return (
        <TableRow colSpan={4} padding={false}>
            <TableCell>{department.name}</TableCell>
            <TableCell>{formatDate(department.createAt)}</TableCell>
            <TableCell>{department.observation}</TableCell>
            <TableCell>
                {canAll && (
                    <div className="flex justify-end p-1">
                        <Button
                            startIcon={<FolderSharedIcon />}
                            color="primary"
                            size="small"
                            onClick={() =>
                                history.push(
                                    projectManagement.base +
                                        projectManagement.projectManagementConfigurator.base +
                                        '/edit-department' +
                                        '/' +
                                        department.id,
                                )
                            }
                        >
                            {t('Edit department')}
                        </Button>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};

DepartmentsRow.propTypes = {
    department: PropTypes.object,
};

DepartmentsRow.defaultProps = {
    department: null,
};

const PmDepartments = () => {
    const [departments, setDepartments] = useState([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const getDepartments = async (currentPage, perPage) => {
        try {
            const response = await API.get('pmDepartments', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                    type: 'PLANNING_OVERVIEW',
                },
            });

            setDepartments(response.data.pmDepartments);
            return response.data.pmDepartments.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {t('ERP Configurator')} | {t('PM Departments')}
                </title>
            </Helmet>

            <Pagination loading={loading} setLoading={setLoading} getFunction={getDepartments}>
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : departments.length ? (
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Department')}</TableCell>
                                    <TableCell>{t('Created on')}</TableCell>
                                    <TableCell>{t('Observations')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableSeparator />
                                {departments.map((department) => (
                                    <DepartmentsRow key={department.id} department={department} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <NoDataPlaceholder />
                )}
            </Pagination>
        </>
    );
};

export default PmDepartments;
