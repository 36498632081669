import { useContext, useMemo } from 'react';

import GlobalContext from 'contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

const useMonths = () => {
    const { t } = useTranslation();
    const { language } = useContext(GlobalContext);

    const months = useMemo(
        () => [
            t('January'),
            t('February'),
            t('March'),
            t('April'),
            t('May'),
            t('June'),
            t('July'),
            t('August'),
            t('September'),
            t('October'),
            t('November'),
            t('December'),
        ],
        [language],
    );

    return months;
};

export default useMonths;
