import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PMProjectForm from 'components/modules/project-management/pm-project-form';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PMCreateProject = ({ pmId }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '59',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, [canAll]);

    return (
        canAll && (
            <>
                <Helmet>
                    <title>PM - {t('Create new project')}</title>
                </Helmet>

                <Header pageTitle={t('Create new project')} />

                <div className="page-container relative">
                    <PMProjectForm pmId={pmId} />
                </div>
            </>
        )
    );
};

PMCreateProject.propTypes = {
    pmId: PropTypes.string,
};

PMCreateProject.defaultProps = {
    pmId: '',
};

export default PMCreateProject;
