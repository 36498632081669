import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddIcon from '@material-ui/icons/Add';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import { Expenses } from 'api/Expenses_Revenues';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import {
    ExpandableRow,
    Header,
    TableSeparator,
} from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { accountancy } from 'routes';

import ExpenseForm from '../../../components/accountancy/expense-form';

const ExpenseRow = ({ expense, canAll, isInitializeCase }) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <ExpandableRow
            colSpan={2}
            padding={false}
            row={
                <>
                    <TableCell>{expense.name}</TableCell>
                </>
            }
            collapse={
                <>
                    {canAll && !isInitializeCase && (
                        <div className="p-5">
                            <Button
                                color="primary"
                                startIcon={<AccountBalanceIcon />}
                                onClick={() =>
                                    history.push(
                                        accountancy.base +
                                            accountancy.expenseNomenclature
                                                .base +
                                            accountancy.expenseNomenclature
                                                .update +
                                            '/' +
                                            expense.id,
                                    )
                                }
                            >
                                {t('Edit expense')}
                            </Button>
                        </div>
                    )}
                    <div className="p-5">
                        <ExpenseForm
                            expenseId={expense.id}
                            canEdit={canAll && !isInitializeCase}
                        />
                    </div>
                </>
            }
        />
    );
};

ExpenseRow.propTypes = {
    expense: PropTypes.object,
    canAll: PropTypes.bool,
    isInitializeCase: PropTypes.bool,
};

ExpenseRow.defaultProps = {
    expense: null,
    canAll: null,
    isInitializeCase: false,
};

const ManageExpenses = ({ setInitializeData }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);
    const canView = checkPerm([
        {
            permissionId: '35',
            permissionType: 'VIEW',
        },
    ]);
    const canAll = checkPerm([
        {
            permissionId: '35',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canView) history.push('/');
    }, [canView]);

    const [loadingExpenses, setLoadingExpenses] = useState(true);
    const [expenses, setExpenses] = useState([]);

    const getExpenses = async (currentPage, perPage) => {
        try {
            const res = await Expenses.get(currentPage, perPage);

            if (res.ok) {
                setExpenses(res.data.expenseNames);
                setInitializeData?.((prev) => ({
                    ...prev,
                    nextDisabled: !res.data.length,
                }));
                return res.data.length;
            } else {
                throw new Error(res.error);
            }
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            {!setInitializeData && (
                <>
                    <Helmet>
                        <title>{t('Expenses management')}</title>
                    </Helmet>

                    <Header
                        pageTitle={t('Expenses management')}
                        action={
                            canAll && (
                                <Button
                                    color="primary"
                                    style={{ borderRadius: '999px' }}
                                    startIcon={<AddIcon />}
                                    onClick={() =>
                                        history.push(
                                            accountancy.base +
                                                accountancy.expenseNomenclature
                                                    .base +
                                                accountancy.expenseNomenclature
                                                    .create,
                                        )
                                    }
                                >
                                    {t('Add expense')}
                                </Button>
                            )
                        }
                    />
                </>
            )}

            <div className={`${!setInitializeData ? 'page-container' : ''}`}>
                <Pagination
                    loading={loadingExpenses}
                    setLoading={setLoadingExpenses}
                    getFunction={getExpenses}
                >
                    {loadingExpenses ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : expenses.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('Expense type')}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {expenses?.map((expense) => (
                                        <ExpenseRow
                                            key={expense.id}
                                            expense={expense}
                                            canAll={canAll}
                                            isInitializeCase={
                                                !!setInitializeData
                                            }
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            </div>
        </>
    );
};

ManageExpenses.propTypes = {
    setInitializeData: PropTypes.func,
};

ManageExpenses.defaultProps = {
    setInitializeData: null,
};

export default ManageExpenses;
