import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import PaginationSearch from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import UserRolesRow from 'pages/configurator/user-roles/user-roles-row';
import { TableSeparator } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const UserRoles = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);
    const canView = checkPerm([
        {
            permissionId: '55',
            permissionType: 'VIEW',
        },
    ]);

    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [triggerRefetch, setTriggerRefetch] = useState(false);

    const getRoles = async (currentPage, perPage, controller) => {
        try {
            if (!canView) {
                history.push('/');
                return;
            }

            const response = await API.get('/userRoles', {
                params: {
                    currentPage,
                    perPage,
                },
                signal: controller?.signal ?? undefined,
            });

            setRoles(response.data.roles);

            return response.data.length;
        } catch (err) {
            console.log(err);
            throw new Error(err);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {t('ERP Configurator')} | {t('User roles')}
                </title>
            </Helmet>

            <PaginationSearch
                loading={loading}
                setLoading={setLoading}
                getFunction={getRoles}
                refreshList={[triggerRefetch]}
            >
                {loading ? (
                    <Loading />
                ) : roles.length === 0 ? (
                    <NoDataPlaceholder />
                ) : (
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Role')}</TableCell>
                                    <TableCell>{t('Permissions')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableSeparator />
                                {roles.map((role) => (
                                    <UserRolesRow
                                        key={role.id}
                                        role={role}
                                        triggerRefetch={() =>
                                            setTriggerRefetch((prev) => !prev)
                                        }
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </PaginationSearch>
        </>
    );
};

export default UserRoles;
