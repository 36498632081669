import React, { useRef } from 'react';

import useElementRect from 'hooks/useElementRect';
import PropTypes from 'prop-types';
import { remToPx } from 'utils';

import Avatar from '../avatar/avatar';

const USERS_AVATAR = {
    width: 3.5,
    overflow: 0.5,
};

const getNumberOfDisplayedUsers = (cw, length) => {
    const { width, overflow } = USERS_AVATAR;

    const avatarWidth = remToPx(width);
    const overflowWidth = remToPx(overflow);
    const overflowingAvatarWidth = avatarWidth - overflowWidth;

    if (!cw) return { displayedValue: 0, placeholderValue: 0 };

    const totalWidth = overflowingAvatarWidth * length + overflowWidth;

    if (cw > totalWidth) return { displayedValue: length, placeholderValue: 0 };

    let displayedValue = 0,
        avatarsWidth = avatarWidth;

    for (let i = 1; i < length; i++) {
        if (avatarsWidth + overflowingAvatarWidth < cw) {
            displayedValue++;
            avatarsWidth += overflowingAvatarWidth;
        } else break;
    }

    return { displayedValue, placeholderValue: length - displayedValue };
};

const UsersAvatars = ({ users }) => {
    const ref = useRef(null);
    const {
        box: { width: cw },
    } = useElementRect(ref);
    const { displayedValue, placeholderValue } = getNumberOfDisplayedUsers(cw, users.length);
    const { width, overflow } = USERS_AVATAR;

    return (
        <div
            className="flex w-full items-center"
            style={{
                height: `${width}rem`,
            }}
            ref={ref}
        >
            {users.slice(0, displayedValue).map((user, index) => (
                <div
                    key={user.id}
                    style={{
                        width: `${width}rem`,
                        height: `${width}rem`,
                        marginLeft: index !== 0 ? `-${overflow}rem` : undefined,
                    }}
                >
                    <Avatar url={user.profile.profileImageUrl} />
                </div>
            ))}
            {!!placeholderValue && displayedValue !== users.length && (
                <div
                    style={{
                        width: `${width}rem`,
                        height: `${width}rem`,
                        marginLeft: `-${overflow}rem`,
                    }}
                >
                    <Avatar placeholder={`+${placeholderValue}`} />
                </div>
            )}
        </div>
    );
};

UsersAvatars.propTypes = {
    users: PropTypes.array,
};

UsersAvatars.defaultProps = {
    users: null,
};

export default UsersAvatars;
