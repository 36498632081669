import React, { useContext, useState } from 'react';

import EditIcon from '@material-ui/icons/Edit';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import { UsersRow } from 'pages/configurator/users';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
    useHistory,
    useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { projectManagement } from 'routes';
import API from 'utils/axios';

const AffiliateRow = ({
    affiliateInfo,
    deleteAffiliateFromProject,
    canAll,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const confirm = useConfirm();

    return (
        <TableRow colSpan={4} padding={false}>
            <TableCell>
                <div className="flex items-center">
                    <div
                        className="mr-4 h-12 w-12 flex-shrink-0 rounded-full bg-cover bg-center"
                        style={{
                            backgroundImage: `url(${affiliateInfo?.profileImageUrl})`,
                        }}
                    />
                    {affiliateInfo.Affiliate.user.profile.name}
                </div>
            </TableCell>
            <TableCell>{affiliateInfo.Affiliate.user.profile.role}</TableCell>
            <TableCell>
                {affiliateInfo.Affiliate.user.profile.phoneNo}
            </TableCell>
            <TableCell>{affiliateInfo.Affiliate.user.email}</TableCell>
            <TableCell>{affiliateInfo.Affiliate.user.observation}</TableCell>
            <TableCell>
                {canAll && (
                    <div className="flex justify-end">
                        <Button
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() =>
                                confirm(
                                    t(
                                        'Are you sure you want to delete this affiliate account from project?',
                                    ),
                                    () =>
                                        deleteAffiliateFromProject(
                                            affiliateInfo.affiliateId,
                                        ),
                                )
                            }
                        >
                            {t('Delete affiliate account from project')}
                        </Button>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};

AffiliateRow.propTypes = {
    affiliateInfo: PropTypes.object,
    deleteAffiliateFromProject: PropTypes.func,
    canAll: PropTypes.bool,
};

AffiliateRow.defaultProps = {
    affiliateInfo: {},
    deleteAffiliateFromProject: () => null,
    canAll: false,
};

const DisplayAffiliateAccountsOnProject = ({
    affiliateAccounts,
    getResources,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { projectId } = useParams();
    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '61',
            permissionType: 'ALL',
        },
    ]);

    const deleteAffiliateFromProject = async (affiliateId) => {
        try {
            await API.delete(
                `/ProjectAffiliates?&affiliateId=${affiliateId}&pmProjectId=${projectId}`,
            );
            enqueueSnackbar(
                t('Affiliate account was deleted from project successfully!'),
                { variant: 'success' },
            );
            getResources();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('PM Configurator')} | {t('Affiliated accounts')}
                </title>
            </Helmet>

            {/* <Pagination getFunction={getAffiliates} perPageItems={20}> */}

            {affiliateAccounts.affiliates?.length > 0 && (
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('User')}</TableCell>
                                <TableCell>{t('Role')}</TableCell>
                                <TableCell>{t('Phone')}</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>{t('Observations')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableSeparator />
                            {affiliateAccounts.affiliates?.map((affiliate) => (
                                <AffiliateRow
                                    key={affiliate.id}
                                    affiliateInfo={affiliate}
                                    deleteAffiliateFromProject={
                                        deleteAffiliateFromProject
                                    }
                                    canAll={canAll}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* </Pagination> */}
        </React.Fragment>
    );
};

DisplayAffiliateAccountsOnProject.propTypes = {
    affiliateAccounts: PropTypes.array,
    getResources: PropTypes.func,
};

DisplayAffiliateAccountsOnProject.defaultProps = {
    affiliateAccounts: [],
    getResources: () => null,
};

export default DisplayAffiliateAccountsOnProject;
