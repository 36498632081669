import React, { useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';

import FileUploadContainer from 'components/shared/file-upload-container';
import ActivityContext from 'contexts/ActivityContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { uploadSingleFile } from 'utils';
import * as yup from 'yup';

const File = (props) => {
    const { file, deleteFile } = props;

    return (
        <div className="group mb-2 flex items-center justify-between rounded-md bg-layout-transparent px-4 py-3 transition-colors hover:bg-primary-transparent">
            <div className="flex items-center">
                <AttachFileIcon
                    className="mr-2 rotate-45 transform text-buttons-text"
                    style={{ fontSize: '1.75rem' }}
                />
                <p className="user-select-none text-buttons-text">{file.info.name}</p>
            </div>

            <div className="flex items-center">
                <div
                    className="flex h-7 w-7 -translate-y-2 transform cursor-pointer items-center justify-center rounded-full bg-error opacity-0 transition-all hover:bg-error-light group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100"
                    onClick={() => deleteFile(file.id)}
                >
                    <CloseIcon className="text-buttons-text" style={{ fontSize: '1.25rem' }} />
                </div>
            </div>
        </div>
    );
};

File.propTypes = {
    file: PropTypes.object,
    deleteFile: PropTypes.func,
};

File.defaultProps = {
    file: null,
    deleteFile: () => null,
};

const DocumentActivity = (props) => {
    const { departmentId, partnerId, tenantUsers, contactId, groups } = props;
    const { user } = useContext(UserContext);
    const activityContext = useContext(ActivityContext);

    const [files, setFiles] = useState([]);
    const [title, setTitle] = useState('');
    const [responsible, setResponsible] = useState([]);
    const [comment, setComment] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const deleteFile = (id) => {
        const copy = files.filter((file) => file.id !== id);
        setFiles(copy);
    };

    let schema = yup.object().shape({
        title: yup
            .string()
            .typeError(t('The title for the activity is mandatory!'))
            .required(t('The title for the activity is mandatory!')),
        comment: yup.string().typeError(t('The comment is required!')).required(t('The comment is required!')),
        responsible: yup
            .array()
            .typeError(t('You must select at least one responsible!'))
            .min(1, t('You must select at least one responsible!'))
            .required(t('You must select at least one responsible!')),
        files: yup
            .array()
            .typeError(t('You must upload at least one document!'))
            .min(1, t('You must upload at least one document!'))
            .required(t('You must upload at least one document!')),
    });

    const userIndexToId = (users) => {
        return users.map((user) => tenantUsers[user].id);
    };

    const createDocumentActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schema.validate({
                title,
                files,
                responsible,
                comment,
            });

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description: comment,
                    note: '-',
                    actionType: 'DOCUMENTS',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: userIndexToId(responsible),
                    supervisor: [],
                    watcher: [],
                    partnerId: partnerId ? partnerId : undefined,
                    departmentId,
                    groups,
                };

                if (contactId) reqBody.contactId = contactId;

                formData.append('data', JSON.stringify(reqBody));

                if (files.length) {
                    files.forEach((file) => formData.append('files', file.blob));
                }

                activityContext.submitNewActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    const editDocumentActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schema.validate({
                title,
                files,
                responsible,
                comment,
            });

            try {
                const formData = new FormData();
                const reqBody = {
                    title,
                    description: comment,
                    note: '-',
                    actionType: 'DOCUMENTS',
                    status: 'TODO',
                    ownerId: user.id,
                    responsible: userIndexToId(responsible),
                    supervisor: [],
                    watcher: [],
                    partnerId: partnerId ? partnerId : undefined,
                    departmentId,
                    groups,
                };

                if (contactId) reqBody.contactId = contactId;

                formData.append('data', JSON.stringify(reqBody));
                formData.append('id', JSON.stringify(activityContext.activityForEdit.id));

                //TODO: must be changed in the backend
                // if (files.length) {
                //     files.forEach((file) => formData.append('files', file.blob));
                // }

                activityContext.editActivity(formData);
            } catch (err) {
                console.error(err);
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    useEffect(() => {
        const { activityForEdit } = activityContext;

        if (activityForEdit) {
            const { title, description, users: responsibles, Files: files } = activityForEdit;

            setTitle(title);
            setComment(description);
            setResponsible(
                responsibles.map((responsible) => tenantUsers.findIndex((res) => res.id === responsible.userId)),
            );
            setFiles(
                files.map((file) => ({
                    id: file.fileId,
                    blob: file.file.url,
                    info: {
                        name: file.file.name,
                    },
                })),
            );
        }
    }, [activityContext]);

    return (
        <form>
            <div className="flex flex-col">
                {files.map((file) => (
                    <File key={file.id} file={file} deleteFile={deleteFile} />
                ))}
            </div>

            <FileUploadContainer
                onUpload={(e) => {
                    uploadSingleFile(
                        e,
                        ({ message, blob, info }) => {
                            if (message) {
                                enqueueSnackbar(t(message), {
                                    variant: 'error',
                                });
                            } else if (blob && info) {
                                setFiles([
                                    ...files,
                                    {
                                        id: Math.random(),
                                        blob,
                                        info,
                                    },
                                ]);
                            }
                        },
                        'all',
                    );
                }}
            >
                <div className="mb-5">
                    <Button startIcon={<AddIcon />}>{t('Attach new document')}</Button>
                </div>
            </FileUploadContainer>

            <div className="mb-5">
                <TextField
                    label={t('Activity name')}
                    placeholder={t('Add a name for the activity')}
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                />
            </div>

            <div className="mb-5">
                <MultiDropdown
                    options={tenantUsers.map((user) => user?.profile?.name)}
                    placeholder={t('Responsible')}
                    selectedOptions={responsible}
                    variant="black"
                    setSelectedOptions={(i) => {
                        if (responsible.indexOf(i) > -1) {
                            setResponsible(responsible.filter((opt) => opt !== i));
                        } else {
                            setResponsible([...responsible, i]);
                        }
                    }}
                />
            </div>

            <div className="mb-10">
                <TextField
                    placeholder={t('Add comment/Note')}
                    label={t('Comment')}
                    value={comment}
                    multiline
                    rows={3}
                    onChange={(e) => setComment(e.target.value)}
                />
            </div>

            <Button
                color="secondary"
                startIcon={activityContext.activityForEdit !== null ? <EditIcon /> : <AddIcon />}
                onClick={activityContext.activityForEdit !== null ? editDocumentActivity : createDocumentActivity}
            >
                {`${activityContext.activityForEdit !== null ? t('Edit') : t('Add')} ${t('documents')}`}
            </Button>
        </form>
    );
};

DocumentActivity.propTypes = {
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    groups: PropTypes.array,
};

DocumentActivity.defaultProps = {
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    contactId: null,
    groups: [],
};

export default DocumentActivity;
