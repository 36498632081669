import React from 'react';

import Loading from 'components/shared/loading';
import { useTranslation } from 'react-i18next';

const EmailNotificationLoading = () => {
    const { t } = useTranslation();

    return (
        <div className="flex w-96 flex-col items-center justify-center gap-8 px-10 py-20 sm:w-64">
            <Loading />
            <h2 className="text-2xl sm:text-xl">
                {t(
                    'Data is loading, please wait and do not close or reload the page!',
                )}
            </h2>
        </div>
    );
};

export default EmailNotificationLoading;
