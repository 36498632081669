import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import UserContext from 'contexts/UserContext';
import NotFound from 'pages/common/not-found';
import { kpi } from 'routes';

import KpiView from './components/kpi-view';
import KpiCrmView from './crm/kpi-crm-view';
import KpiNomenclature from './nomenclature/kpi-nomenclature';
import KpiNomenclatureView from './nomenclature/kpi-nomenclature-view';
import KpiPersonalView from './personal/kpi-personal-view';

export const KPI = () => {
    const { checkPerm } = useContext(UserContext);
    const canViewPersonal = checkPerm([
        {
            permissionId: '62',
            permissionType: 'VIEW',
        },
    ]);

    const canViewCRM = checkPerm([
        {
            permissionId: '63',
            permissionType: 'VIEW',
        },
    ]);

    const canViewNomenclature = checkPerm([
        {
            permissionId: '64',
            permissionType: 'VIEW',
        },
    ]);

    const canAllNomenclature = checkPerm([
        {
            permissionId: '64',
            permissionType: 'ALL',
        },
    ]);

    return (
        <Switch>
            <>
                {canViewPersonal && (
                    <>
                        <Route exact path={kpi.base}>
                            <Redirect to={kpi.base + kpi.personal.base} />
                        </Route>

                        <Route exact path={kpi.base + kpi.personal.base}>
                            <KpiView key="PERSONAL" type="PERSONAL" />
                        </Route>

                        <Route exact path={kpi.base + kpi.personal.view}>
                            <KpiPersonalView />
                        </Route>
                    </>
                )}

                {canViewCRM && (
                    <>
                        <Route exact path={kpi.base + kpi.crm.base}>
                            <KpiView key="CRM" type="CRM" />
                        </Route>

                        <Route exact path={kpi.base + kpi.crm.view}>
                            <KpiCrmView />
                        </Route>
                    </>
                )}

                {canViewNomenclature && (
                    <Route exact path={kpi.base + kpi.nomenclature.base}>
                        <KpiNomenclature />
                    </Route>
                )}

                {canAllNomenclature && (
                    <>
                        <Route
                            path={
                                kpi.base +
                                kpi.nomenclature.base +
                                kpi.nomenclature.create.base
                            }
                        >
                            <KpiNomenclatureView />
                        </Route>

                        <Route
                            path={
                                kpi.base +
                                kpi.nomenclature.base +
                                kpi.nomenclature.update.full
                            }
                        >
                            <KpiNomenclatureView />
                        </Route>
                    </>
                )}

                <Route path="*" component={NotFound} />
            </>
        </Switch>
    );
};

export default KPI;
