import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FileUp, ListFilter, Send } from 'lucide-react';

import { Button, CircularProgress } from '@material-ui/core';

import EmailNotification from 'components/shared/email-notification/email-notification';
import ExportTable from 'components/shared/export/export-table';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import ModalReportAgentsContacts from 'pages/crm/contacts/modal-report-agents-contacts';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling, getRawImageFromPath } from 'utils';
import exportExcel from 'utils/exportExcel';
import exportHtmlAsPDF from 'utils/exportHtmlAsPDF';
import { formatExcelData, mergeExcelCellStyles } from 'utils/exportUtils';
import {
    getAgentsReport,
    getCompanyLogoAndIcon,
    getProjects,
    getTenantUsers,
} from 'utils/getterFunctions';

const ReportAgentsContacts = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        tenant: { companyName },
    } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);
    const history = useHistory();

    const canView = checkPerm([
        {
            permissionId: '47',
            permissionType: 'VIEW',
        },
    ]);

    const canNotify = checkPerm([
        {
            permissionId: '31',
            permissionType: 'VIEW',
        },
    ]);

    const columns = [
        {
            key: 'name',
            content: t('First and last name'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'leads',
            content: t('No. leads'),
            render: (content, entry, index) => content,
        },
        {
            key: 'contracts',
            content: t('Contracts'),
            render: (content, entry, index) => content,
        },
        {
            key: 'campaigns',
            content: t('Campaigns'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'offered',
            content: t('Offered'),
            render: (content, entry, index) => content,
        },
        {
            key: 'notOffered',
            content: t('Not offered'),
            render: (content, entry, index) => content,
        },
        {
            key: 'pending',
            content: t('Pending'),
            render: (content, entry, index) => content,
        },
        {
            key: 'accepted',
            content: t('Accepted'),
            render: (content, entry, index) => content,
        },
        {
            key: 'rejected',
            content: t('Rejected'),
            render: (content, entry, index) => content,
        },
        {
            key: 'blocked',
            content: t('Blocked'),
            render: (content, entry, index) => content,
        },
    ];

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [exportingExcel, setExportingExcel] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [users, setUsers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [companyLogo, setCompanyLogo] = useState(null);

    const [open, setOpen] = useState(false);
    const [notify, setNotify] = useState(false);
    const [data, setData] = useState([]);

    const [filteredOptions, setFilteredOptions] = useState({
        type: 'USERS',
        options: [],
        startDate: null,
        endDate: null,
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleToggleNotify = () => setNotify((prev) => !prev);

    const handleFilter = async (params) => {
        try {
            setFetching(true);

            const dataRes = await getAgentsReport(params);

            setData(dataRes);
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
            console.error(error);
        } finally {
            setFetching(false);
        }
    };

    const handleExport = async () => {
        try {
            setExporting(true);

            const html = document.getElementById(
                'contacts-report-table',
            ).innerHTML;

            const columnsWidths = [
                '14%',
                '9%',
                '9%',
                '14%',
                '9%',
                '9%',
                '9%',
                '9%',
                '9%',
                '9%',
            ];

            await exportHtmlAsPDF(
                'landscape',
                [html],
                [],
                null,
                null,
                true,
                null,
                `Agents_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                [
                    {
                        elements: [
                            {
                                key: 'table',
                                props: {
                                    widths: columnsWidths,
                                    dontBreakRows: true,
                                },
                            },
                        ],
                    },
                ],
                [],
            );
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
            console.error(error);
        } finally {
            setExporting(false);
        }
    };

    const handleExportExcel = async () => {
        try {
            setExportingExcel(true);

            const {
                columns: exportColumns,
                rows,
                lastRow,
            } = formatExcelData(columns, data);

            await exportExcel(
                `Agents_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                `Agents_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                t('Agents report'),
                exportColumns,
                rows,
                lastRow,
                (cell, iCell, row, iRow) => {
                    mergeExcelCellStyles(cell, iCell, exportColumns);
                },
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setExportingExcel(false);
        }
    };

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }

        (async () => {
            try {
                const [dataRes, usersRes, projectsRes, { logoUrl }] =
                    await Promise.all([
                        getAgentsReport(),
                        getTenantUsers(),
                        getProjects({
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        }),
                        getCompanyLogoAndIcon(),
                    ]);

                const logoBase64 = await getRawImageFromPath(logoUrl);
                setCompanyLogo(logoBase64);

                setData(dataRes);
                setUsers(usersRes);
                setProjects(projectsRes.content);
            } catch (error) {
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('Agents report')}</title>
            </Helmet>
            <Header
                pageTitle={t('Agents report')}
                action={
                    <>
                        <Button
                            color="primary"
                            startIcon={
                                fetching ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <ListFilter />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleOpen}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel
                            }
                        >
                            {t(fetching ? 'Loading...' : 'Filter data')}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={
                                exportingExcel ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <FileUp />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleExportExcel}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel ||
                                !data.length
                            }
                        >
                            {t(
                                exportingExcel
                                    ? 'Loading...'
                                    : 'Download Excel file',
                            )}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={
                                exporting ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <FileUp />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleExport}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel ||
                                !data.length
                            }
                        >
                            {t(exporting ? 'Loading...' : 'Export as PDF')}
                        </Button>
                        {canNotify && (
                            <Button
                                startIcon={<Send />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={handleToggleNotify}
                                disabled={
                                    loading ||
                                    fetching ||
                                    exporting ||
                                    exportingExcel
                                }
                            >
                                {t('Notify')}
                            </Button>
                        )}
                    </>
                }
            />
            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '100%', margin: 'auto 0' }} />
                ) : data.length ? (
                    <div className="overflow-x-auto" id="contacts-report-table">
                        <ExportTable
                            companyLogo={companyLogo}
                            title={t('Agents report')}
                            columns={columns}
                            data={data}
                        />
                    </div>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>

            <ModalReportAgentsContacts
                open={open}
                onClose={handleClose}
                onFilter={handleFilter}
                users={users}
                projects={projects}
                filteredOptions={filteredOptions}
                setFilteredOptions={setFilteredOptions}
            />

            <EmailNotification open={notify} cancel={handleToggleNotify} />
        </>
    );
};

export default ReportAgentsContacts;
