import React, { useContext } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { ActionButton, Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const InvoicePmDataSelection = ({
    invoiceOnAProject,
    contractsOptions,
    projects,
    selectedContract,
    selectedProject,
    setSelectedContract,
    setSelectedProject,
    getPMContracts,
    onCheck,
}) => {
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);
    const canAllPmContracts = checkPerm([
        {
            permissionId: '60',
            permissionType: 'ALL',
        },
    ]);

    return (
        <div className="flex flex-col gap-4">
            <div>
                {/* Checkbox for projects */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={invoiceOnAProject}
                            onChange={onCheck}
                            name="CRM contracts"
                            style={{
                                color: 'primary-main',
                            }}
                            color="primary"
                        />
                    }
                    label={
                        <p className="user-select-none">
                            {t('Invoice based on a PM project')}
                        </p>
                    }
                />
            </div>

            {/* Dropdown for invoice project */}
            {invoiceOnAProject &&
                (projects.map((project) => project.name).length ? (
                    <div className="inline-block max-w-min">
                        <LabelWrapper label={t('Projects')}>
                            <Dropdown
                                variant="black"
                                options={projects.map(
                                    (project) => project.name,
                                )}
                                placeholder={t(
                                    'Choose the project for the invoice',
                                )}
                                selectedOption={selectedProject}
                                setSelectedOption={(e) => {
                                    setSelectedProject(e);
                                    setSelectedContract(null);
                                    getPMContracts(projects[e].id);
                                }}
                            />
                        </LabelWrapper>
                    </div>
                ) : (
                    <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                        {t(
                            'The selected client or partner has no project yet! Create a new one or pick another client for the invoice!',
                        )}
                    </div>
                ))}

            {canAllPmContracts &&
                selectedProject !== null &&
                (contractsOptions.length ? (
                    <div className="flex items-center gap-3">
                        <div className="inline-block max-w-min">
                            <LabelWrapper label={t('PM contracts')}>
                                <Dropdown
                                    variant="black"
                                    options={contractsOptions}
                                    placeholder={t(
                                        'Choose the contract for the invoice',
                                    )}
                                    selectedOption={selectedContract}
                                    setSelectedOption={(e) =>
                                        setSelectedContract(e)
                                    }
                                />
                            </LabelWrapper>
                        </div>

                        <div className="mt-5 flex-shrink-0">
                            <ActionButton
                                icon={<DeleteIcon />}
                                color={
                                    selectedContract !== null
                                        ? 'var(--error)'
                                        : 'var(--disabled)'
                                }
                                disabled={
                                    selectedContract !== null ? false : true
                                }
                                onClick={() => setSelectedContract(null)}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                        {t(
                            'The selected client or partner has no contract yet! If you want the invoice to be on a contract you have to create one first!',
                        )}
                    </div>
                ))}
        </div>
    );
};

InvoicePmDataSelection.propTypes = {
    invoiceOnAProject: PropTypes.bool,
    contractsOptions: PropTypes.array,
    projects: PropTypes.array,
    selectedContract: PropTypes.number,
    selectedProject: PropTypes.number,
    setSelectedContract: PropTypes.func,
    setSelectedProject: PropTypes.func,
    getPMContracts: PropTypes.func,
    onCheck: PropTypes.func,
};

InvoicePmDataSelection.defaultProps = {
    invoiceOnAProject: false,
    contractsOptions: [],
    projects: [],
    selectedContract: null,
    selectedProject: null,
    setSelectedContract: () => {},
    setSelectedProject: () => {},
    getPMContracts: () => {},
    onCheck: () => {},
};

export default InvoicePmDataSelection;
