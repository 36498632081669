import React, {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import InputAdornment from '@material-ui/core/InputAdornment';

import GlobalContext from 'contexts/GlobalContext';
import useScreenSizes from 'hooks/useScreenSizes';
import PropTypes from 'prop-types';
import { MultiDropdown } from 'RaisisComponents/index.js';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { formatPositiveNumberWithDigits, toLocaleNumber } from 'utils';

const BudgetaryContext = createContext();

const GrandparentExpenseRow = ({ expense, expenseIndex }) => {
    const { accounts, isEdit, checkedExpenses, isDisabled } =
        useContext(BudgetaryContext);
    const currentCheckedExpense = checkedExpenses[expenseIndex];

    const [open, setOpen] = useState(
        currentCheckedExpense.grandParentId === expense.id ? true : false,
    );

    const accountingAccount =
        accounts[
            accounts.findIndex(
                (account) => account.id === expense.accountingAccountsId,
            )
        ];

    const parentList = isEdit
        ? expense.subExpensesName
        : expense.subExpensesName.filter(
              (subRev) => subRev.isDeleted === false,
          );

    return (
        <div className="budgetary-border-selector relative">
            <div
                className={`flex w-full items-center gap-4 rounded-t-md p-4 ${
                    !open ? 'budgetary-border-bottom' : ''
                } sm:gap-2 sm:p-2`}
                style={{
                    backgroundColor: expense.isDeleted
                        ? 'rgb(var(--base-error) / 40%)'
                        : 'var(--layout-transparent)',
                }}
            >
                <div className="flex w-full items-center gap-4 p-4 sm:gap-2 sm:p-2">
                    {/* Grandparent expense name */}
                    <div className="flex-grow">
                        <p className="break-all text-2xl font-extrabold text-main-text">
                            {expense.name}
                        </p>
                        <p className="text-sm">
                            <span className="text-primary-light">
                                {accountingAccount.code}
                            </span>{' '}
                            -{' '}
                            <span className="text-dark-text">
                                {accountingAccount.name}
                            </span>
                        </p>
                    </div>
                </div>

                <div className="w-13 ml-8 sm:ml-2">
                    <div
                        className={`group flex cursor-pointer items-center justify-center rounded-full border border-success-light p-1 transition-all duration-150 hover:border-success ${
                            open ? 'rotate-180 transform' : ''
                        }`}
                        onClick={() => setOpen(!open)}
                    >
                        <ExpandMoreIcon
                            style={{ fontSize: '15px' }}
                            className={`text-success-light transition-all duration-150 group-hover:text-success`}
                        />
                    </div>
                </div>
            </div>

            {/* Map of the parent expenses */}
            <div
                className={`flex flex-col ${isDisabled ? 'pointer-events-none' : ''}`}
            >
                {parentList.map((parentExpense) => (
                    <ParentExpenseRow
                        key={parentExpense.id}
                        parentExpense={parentExpense}
                        expense={expense}
                        open={open}
                        isDisabled={isDisabled}
                        expenseIndex={expenseIndex}
                    />
                ))}
            </div>
        </div>
    );
};

GrandparentExpenseRow.propTypes = {
    expense: PropTypes.object,
    expenseIndex: PropTypes.number,
};

const ParentExpenseRow = ({
    parentExpense,
    expense,
    open,
    isDisabled,
    expenseIndex,
}) => {
    const {
        accounts,
        checkedExpenses,
        setCheckedExpenses,
        currencyObj,
        isEdit,
        disableInputs,
        referenceCurrencyObj,
        exchangeRate,
        isMultiCurrency,
        isEstimateCase,
        language,
    } = useContext(BudgetaryContext);

    const [width] = useScreenSizes();

    const currentCheckedExpense = checkedExpenses[expenseIndex];

    const memoizedCheckedExpensesList = useMemo(
        () => currentCheckedExpense.list,
        [currentCheckedExpense.list],
    );

    const [elementHeight, setElementHeight] = useState(0);

    const elementId = `${expense.id}-${parentExpense.id}`;

    const accountingAccount =
        accounts[
            accounts.findIndex(
                (account) => account.id === parentExpense.accountingAccountsId,
            )
        ];

    const isChecked =
        memoizedCheckedExpensesList.findIndex(
            (r) => r.id === parentExpense.id,
        ) >= 0;

    const canDisplayCheck = disableInputs
        ? false
        : !isEstimateCase && isChecked && parentExpense.isDeleted
          ? true
          : parentExpense.isDeleted
            ? false
            : isEstimateCase
              ? false
              : true;

    const handleCheckExpense = () => {
        if (isChecked) {
            setCheckedExpenses((prev) =>
                prev.map((e, index) =>
                    index === expenseIndex
                        ? {
                              ...e,
                              list: e.list.filter(
                                  (r) => r.id !== parentExpense.id,
                              ),
                          }
                        : e,
                ),
            );
        } else {
            setCheckedExpenses((prev) =>
                prev.map((e, index) =>
                    index === expenseIndex
                        ? {
                              ...e,
                              list: [
                                  ...e.list,
                                  {
                                      id: parentExpense.id,
                                      value: 0,
                                      secondCurrencyValue: isMultiCurrency
                                          ? 0
                                          : undefined,
                                  },
                              ],
                          }
                        : e,
                ),
            );
        }
    };

    const handleUpdateExpenseValue = (value) => {
        setCheckedExpenses((prev) =>
            prev.map((e, index) =>
                index === expenseIndex
                    ? {
                          ...e,
                          list: e.list.map((r) =>
                              r.id === parentExpense.id
                                  ? {
                                        ...r,
                                        value,
                                        secondCurrencyValue: isMultiCurrency
                                            ? value / exchangeRate
                                            : undefined,
                                    }
                                  : r,
                          ),
                      }
                    : e,
            ),
        );
    };

    const childList = isEdit
        ? parentExpense.subExpensesName
        : parentExpense.subExpensesName.filter(
              (subRev) => subRev.isDeleted === false,
          );

    const handleResize = useCallback(
        (node) => {
            if (!node) return;

            const elementData = node.scrollHeight;
            setElementHeight(elementData);
        },
        [width, memoizedCheckedExpensesList],
    );

    return (
        <div
            className={`budgetary-parent-selector relative transition-all duration-200 ease-in-out ${
                open
                    ? `visible border-t border-secondary-main`
                    : 'invisible max-h-0'
            } ${open && !isDisabled ? 'opacity-100' : 'opacity-0'} ${open && isDisabled ? 'opacity-75' : 'opacity-0'} `}
            style={{
                maxHeight: open ? `${elementHeight}px` : '',
            }}
            id={elementId}
            ref={handleResize}
        >
            <div
                className={`flex w-full items-center gap-4 p-4 sm:gap-2 sm:p-2`}
                style={{
                    backgroundColor:
                        isChecked && parentExpense.isDeleted
                            ? 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))'
                            : isChecked
                              ? 'rgb(var(--base-secondary-light) / 20%)'
                              : parentExpense.isDeleted
                                ? 'rgb(var(--base-error) / 40%)'
                                : '',
                }}
            >
                {/* Parent expense name */}
                <div className="ml-6 flex-grow">
                    <p className="break-all text-xl font-bold text-main-text">
                        {parentExpense.name}
                    </p>
                    <p className="text-sm">
                        <span className="text-primary-light">
                            {accountingAccount.code}
                        </span>{' '}
                        -{' '}
                        <span className="text-dark-text">
                            {accountingAccount.name}
                        </span>
                    </p>
                </div>
                {isChecked && (
                    <div
                        className="flex flex-col gap-2"
                        style={{ minWidth: '10rem' }}
                    >
                        <LocaleTextField
                            disabled={disableInputs}
                            placeholder={`${toLocaleNumber(100, language, 2)} ${currencyObj.currency}`}
                            value={
                                memoizedCheckedExpensesList.find(
                                    (r) => r.id === parentExpense.id,
                                )?.value
                            }
                            onChange={(e) => {
                                const value = formatPositiveNumberWithDigits(
                                    e.target.value,
                                );

                                handleUpdateExpenseValue(value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {currencyObj.currency}
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {isMultiCurrency && (
                            <LocaleTextField
                                placeholder={`${toLocaleNumber(100, language, 2)} ${referenceCurrencyObj.currency}`}
                                value={
                                    memoizedCheckedExpensesList.find(
                                        (r) => r.id === parentExpense.id,
                                    )?.secondCurrencyValue
                                }
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {referenceCurrencyObj.currency}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </div>
                )}
                {canDisplayCheck && (
                    <div className="w-13 ml-8 sm:ml-2">
                        <div
                            className={`group flex cursor-pointer items-center justify-center rounded-full border border-secondary-lighter p-1 transition-all duration-150 hover:border-secondary-main `}
                            onClick={handleCheckExpense}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '15px' }}
                                className="text-secondary-lighter transition-all duration-150 group-hover:text-secondary-main"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Map of the children expenses */}
            <div className="flex flex-col">
                {childList.map((childExpense) => (
                    <ChildExpenseRow
                        key={childExpense.id}
                        childExpense={childExpense}
                        expenseIndex={expenseIndex}
                    />
                ))}
            </div>
        </div>
    );
};

ParentExpenseRow.propTypes = {
    parentExpense: PropTypes.object,
    expense: PropTypes.object,
    open: PropTypes.bool,
    isDisabled: PropTypes.bool,
    expenseIndex: PropTypes.number,
};

const ChildExpenseRow = ({ childExpense, expenseIndex }) => {
    const {
        accounts,
        checkedExpenses,
        setCheckedExpenses,
        currencyObj,
        disableInputs,
        referenceCurrencyObj,
        exchangeRate,
        isMultiCurrency,
        isEstimateCase,
        language,
    } = useContext(BudgetaryContext);

    const currentCheckedExpense = checkedExpenses[expenseIndex];

    const accountingAccount =
        accounts[
            accounts.findIndex(
                (account) => account.id === childExpense.accountingAccountsId,
            )
        ];

    const isChecked =
        currentCheckedExpense.list.findIndex((r) => r.id === childExpense.id) >=
        0;

    const canDisplayCheck = disableInputs
        ? false
        : isChecked && childExpense.isDeleted
          ? true
          : childExpense.isDeleted
            ? false
            : isEstimateCase
              ? false
              : true;

    const handleCheckExpense = () => {
        if (isChecked) {
            setCheckedExpenses((prev) =>
                prev.map((e, index) =>
                    index === expenseIndex
                        ? {
                              ...e,
                              list: e.list.filter(
                                  (r) => r.id !== childExpense.id,
                              ),
                          }
                        : e,
                ),
            );
        } else {
            setCheckedExpenses((prev) =>
                prev.map((e, index) =>
                    index === expenseIndex
                        ? {
                              ...e,
                              list: [
                                  ...e.list,
                                  {
                                      id: childExpense.id,
                                      value: 0,
                                      secondCurrencyValue: isMultiCurrency
                                          ? 0
                                          : undefined,
                                  },
                              ],
                          }
                        : e,
                ),
            );
        }
    };

    const handleUpdateExpenseValue = (value) => {
        setCheckedExpenses((prev) =>
            prev.map((e, index) =>
                index === expenseIndex
                    ? {
                          ...e,
                          list: e.list.map((r) =>
                              r.id === childExpense.id
                                  ? {
                                        ...r,
                                        value,
                                        secondCurrencyValue: isMultiCurrency
                                            ? value / exchangeRate
                                            : undefined,
                                    }
                                  : r,
                          ),
                      }
                    : e,
            ),
        );
    };

    return (
        <div
            className="budgetary-child-selector relative border-t border-secondary-main"
            style={{
                backgroundColor:
                    isChecked && childExpense.isDeleted
                        ? 'color-mix(in srgb, rgb(var(--base-secondary-light) / 20%), rgb(var(--base-error) / 40%))'
                        : isChecked
                          ? 'rgb(var(--base-secondary-light) / 20%)'
                          : childExpense.isDeleted
                            ? 'rgb(var(--base-error) / 40%)'
                            : '',
            }}
        >
            <div className="flex w-full items-center gap-4 p-4 sm:gap-2 sm:p-2">
                <div className="ml-12 flex-grow">
                    <p className="break-all text-lg text-main-text">
                        {childExpense.name}
                    </p>
                    <p className="text-sm">
                        <span className="text-primary-light">
                            {accountingAccount.code}
                        </span>{' '}
                        -{' '}
                        <span className="text-dark-text">
                            {accountingAccount.name}
                        </span>
                    </p>
                </div>

                {isChecked && (
                    <div
                        className="flex flex-col gap-2"
                        style={{ minWidth: '10rem' }}
                    >
                        <LocaleTextField
                            disabled={disableInputs}
                            placeholder={`${toLocaleNumber(100, language, 2)} ${currencyObj.currency}`}
                            value={
                                currentCheckedExpense.list.find(
                                    (r) => r.id === childExpense.id,
                                )?.value
                            }
                            onChange={(e) => {
                                const value = formatPositiveNumberWithDigits(
                                    e.target.value,
                                );

                                handleUpdateExpenseValue(value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        {currencyObj.currency}
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {isMultiCurrency && (
                            <LocaleTextField
                                placeholder={`${toLocaleNumber(100, language, 2)} ${referenceCurrencyObj.currency}`}
                                value={
                                    currentCheckedExpense.list.find(
                                        (r) => r.id === childExpense.id,
                                    )?.secondCurrencyValue
                                }
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            {referenceCurrencyObj.currency}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </div>
                )}

                {canDisplayCheck && (
                    <div className="w-13 ml-8 sm:ml-2">
                        <div
                            className={`group flex cursor-pointer items-center justify-center rounded-full border border-secondary-lighter p-1 transition-all duration-150 hover:border-secondary-main `}
                            onClick={handleCheckExpense}
                        >
                            <CheckCircleIcon
                                style={{ fontSize: '15px' }}
                                className="text-secondary-lighter transition-all duration-150 group-hover:text-secondary-main"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ChildExpenseRow.propTypes = {
    childExpense: PropTypes.object,
    expenseIndex: PropTypes.number,
};

const UtilityExpenseBudget = (props) => {
    const { t } = useTranslation();

    const {
        checkedExpenses,
        setCheckedExpenses,
        isEdit,
        disableInputs,
        exchangeRate,
        isDisabled,
        isEstimateCase,
        allExpenses,
        accounts,
        style,
    } = props;
    const isMultiCurrency = exchangeRate !== null;

    const { currencyObj, referenceCurrencyObj, language } =
        useContext(GlobalContext);

    return (
        <BudgetaryContext.Provider
            value={{
                accounts,
                checkedExpenses,
                setCheckedExpenses,
                isEdit,
                currencyObj,
                disableInputs,
                isDisabled,
                isMultiCurrency,
                exchangeRate,
                referenceCurrencyObj,
                isEstimateCase,
                language,
            }}
        >
            <div className="mb-10 flex flex-col items-start" style={style}>
                {!isEstimateCase &&
                    (() => {
                        const options = isEdit
                            ? allExpenses
                            : allExpenses.filter(
                                  (expense) => expense.isDeleted === false,
                              );

                        return (
                            <div className="relative mb-6 mt-2 inline-block">
                                <MultiDropdown
                                    disabled={disableInputs}
                                    variant="green"
                                    icon={
                                        checkedExpenses.length > 0 ? (
                                            <EditIcon className="text-buttons-text" />
                                        ) : (
                                            <AddIcon className="text-buttons-text" />
                                        )
                                    }
                                    placeholder={
                                        checkedExpenses.length > 0
                                            ? t('Change expenses')
                                            : t('Add expenses')
                                    }
                                    options={options.map((name) => name.name)}
                                    selectedOptions={checkedExpenses.map(
                                        (e) => e.selectionIndex,
                                    )}
                                    setSelectedOptions={(i) => {
                                        if (
                                            checkedExpenses
                                                .map((e) => e.selectionIndex)
                                                .indexOf(i) >= 0
                                        ) {
                                            setCheckedExpenses((prev) =>
                                                prev.filter(
                                                    (p) =>
                                                        p.selectionIndex !== i,
                                                ),
                                            );
                                        } else {
                                            setCheckedExpenses((prev) => [
                                                ...prev,
                                                {
                                                    selectionIndex: i,
                                                    grandParentId:
                                                        allExpenses[i].id,
                                                    list: [],
                                                },
                                            ]);
                                        }
                                    }}
                                />
                            </div>
                        );
                    })()}
                {checkedExpenses.length > 0 && (
                    <div
                        className="grid w-full items-start gap-6"
                        style={{
                            gridTemplateColumns:
                                'repeat(auto-fill, minmax(26rem, max-content))',
                        }}
                    >
                        {checkedExpenses.map((e, index) => (
                            <div
                                key={e.id}
                                className={`w-full rounded-md border border-secondary-light`}
                            >
                                <GrandparentExpenseRow
                                    expense={allExpenses[e.selectionIndex]}
                                    expenseIndex={index}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </BudgetaryContext.Provider>
    );
};

UtilityExpenseBudget.propTypes = {
    checkedExpenses: PropTypes.array,
    setCheckedExpenses: PropTypes.func,
    isEdit: PropTypes.bool,
    viewOnly: PropTypes.bool,
    isEstimateCase: PropTypes.bool,
    disableInputs: PropTypes.bool,
    isDisabled: PropTypes.bool,
    exchangeRate: PropTypes.number,
    allExpenses: PropTypes.array,
    accounts: PropTypes.array,
    style: PropTypes.object,
};

UtilityExpenseBudget.defaultProps = {
    checkedExpenses: [],
    setCheckedExpenses: () => null,
    isEdit: false,
    viewOnly: false,
    disableInputs: false,
    isEstimateCase: false,
    isDisabled: false,
    exchangeRate: null,
    allExpenses: [],
    accounts: [],
    style: {},
};

export default UtilityExpenseBudget;
