import React from 'react';

import PropTypes from 'prop-types';

const Blur = ({ children }) => {
    return (
        <div
            className="pointer-events-none absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 transform backdrop-blur-sm backdrop-filter"
            style={{
                zIndex: 'calc(infinity)',
            }}
        >
            {children}
        </div>
    );
};

Blur.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Blur.defaultProps = {
    children: null,
};

export default Blur;
