import React, { useContext } from 'react';

import CloseIcon from '@material-ui/icons/Close';

import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { toLocaleNumber } from 'utils';

const SipRow = ({ deleteSip, sip, disabled }) => {
    const { t } = useTranslation();
    const { currencyObj, language } = useContext(GlobalContext);
    const sipInfo = typeof sip.data === 'string' ? JSON.parse(sip.data) : sip.data;

    return (
        <div>
            <div
                className={`inline-flex w-full gap-4 bg-layout-transparent-dark p-5 text-main-text ${
                    disabled ? 'rounded-md' : 'rounded-tl-md rounded-tr-md'
                }`}
            >
                <div className="w-1/2">
                    <div className="flex flex-col">
                        <h4 className="pb-2 text-xl">{sipInfo.standard.name ? sipInfo.standard.name : '-'}</h4>
                        <h3 className="text-lg text-dark-text">
                            {sipInfo.standard.description ? sipInfo.standard.description : '-'}
                        </h3>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="flex flex-col gap-4">
                        <LabelWrapper label={t('Price')}>
                            <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark bg-layout-transparent p-2 text-main-text">
                                {sipInfo.standard.price ? toLocaleNumber(sipInfo.standard.price, language, 2) : '-'}{' '}
                                {currencyObj.currency}
                            </div>
                        </LabelWrapper>
                        <LabelWrapper label={t('Phone number')}>
                            <div className="pointer-events-none whitespace-nowrap rounded-md border border-layout-transparent-dark p-2 text-main-text">
                                {sipInfo.standard.phone_number ? sipInfo.standard.phone_number : '-'}
                            </div>
                        </LabelWrapper>
                    </div>
                </div>
            </div>
            {!disabled && (
                <div className="left-0 top-full flex w-full justify-end rounded-bl-md rounded-br-md border-t border-layout-transparent-dark bg-layout-transparent-dark">
                    <div className="text-main-text">
                        <p
                            className="ml-2 flex cursor-pointer items-center p-4 text-main-text transition-colors hover:text-secondary-main"
                            style={{ userSelect: 'none' }}
                            onClick={deleteSip}
                        >
                            <CloseIcon className="mr-2 text-base" />
                            {t('Delete catalog entity from the offer')}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

SipRow.propTypes = {
    deleteSip: PropTypes.func,
    sip: PropTypes.object,
    disabled: PropTypes.bool,
};

SipRow.defaultProps = {
    deleteSip: () => null,
    sip: null,
    disabled: false,
};

export default SipRow;
