import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ContractTypesForm from 'components/accountancy/contract-types-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CreateContractType = () => {
    const { t } = useTranslation();
    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '38',
            permissionType: 'ALL',
        },
    ]);
    const history = useHistory();

    useEffect(() => {
        if (!canAll) history.push('/');
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>{t('Add contract type')}</title>
            </Helmet>

            <Header pageTitle={t('Add contract type')} />

            <div className="page-container">
                <ContractTypesForm hideElements={false} />
            </div>
        </>
    );
};

export default CreateContractType;
