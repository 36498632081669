import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import {
    Button,
    createStyles,
    InputAdornment,
    makeStyles,
    Popper,
    TextField,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Autocomplete } from '@material-ui/lab';

import BasicTooltip from 'components/shared/basic-tooltip';
import useScreenSizes from 'hooks/useScreenSizes';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { EMAIL_REGEX } from 'utils';
import * as yup from 'yup';

const useStyles = makeStyles(() => {
    return {
        textField: {
            '& .MuiTypography-colorTextSecondary': {
                fontSize: '1.2rem',
            },
        },
    };
});

const useStylesAutocomplete = makeStyles(() => {
    return createStyles({
        root: {
            '& .MuiAutocomplete-inputRoot': {
                alignItems: 'start',
                border: `1px solid var(--primary-main)`,

                ' & ::placeholder': {
                    opacity: 1,
                    color: `var(--secondary-text)`,
                    fontSize: '1.2rem',
                    fontWeight: 600,
                },

                '& .MuiIconButton-label .MuiSvgIcon-root': {
                    fill: `var(--primary-main)`,
                    width: '1.5rem',
                    height: '1.5rem',
                },
            },
            '& .MuiAutocomplete-popper': {
                zIndex: 9999,
            },

            '& .MuiChip-root': {
                backgroundColor: `var(--primary-main)`,
                color: `var(--buttons-text)`,
                fontSize: '0.9rem',
                fontWeight: '600',
            },
        },
        option: {
            '&[data-focus="true"]': {
                backgroundColor: `var(--layout-lighter)`,
                color: `var(--main-text)`,
            },
        },
    });
});

const EmailNotificationInfo = ({
    disableSend,
    emailOptions,
    handleEmailToChange,
    emailToUsers,
    handleEmailReplyToChange,
    emailReplyToUsers,
    handleSubjectChange,
    emailSubject,
    handleAddOption,
    emailCcUsers,
    emailCcOptions,
    emailBccUsers,
    emailBccOptions,
    handleEmailCcChange,
    handleEmailBccChange,
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const classesAutocomplete = useStylesAutocomplete();
    const [open, setOpen] = useState(false);
    const [openCC, setOpenCC] = useState(false);
    const [openBCC, setOpenBCC] = useState(false);
    const defaultData = { alias: '', email: '' };
    const [data, setData] = useState(defaultData);
    const [dataCC, setDataCC] = useState(defaultData);
    const [dataBCC, setDataBCC] = useState(defaultData);
    const [width] = useScreenSizes();

    const CustomPopper = function (props) {
        return (
            <Popper
                {...props}
                style={{
                    width: '48%',
                    position: 'relative',
                    zIndex: 9999,
                }}
            />
        );
    };

    const CustomPaper = (props) => {
        return (
            <Paper
                elevation={8}
                {...props}
                style={{
                    backgroundColor: `var(--layout-light)`,
                    color: `var(--main-text)`,
                }}
            />
        );
    };

    const schema = yup.object().shape({
        alias: yup.string().trim().typeError(t('The alias is not valid')),
        email: yup
            .string()
            .trim()
            .typeError(t('The email address is not valid'))
            .required(t('The email address is mandatory'))
            .matches(EMAIL_REGEX, t('The email address is not valid')),
    });

    const handleCancel = (type) => {
        if (type === 'to') {
            setData(defaultData);
            setOpen(false);
        }

        if (type === 'cc') {
            setDataCC(defaultData);
            setOpenCC(false);
        }

        if (type === 'bcc') {
            setDataBCC(defaultData);
            setOpenBCC(false);
        }
    };

    const handleAddRecipient = async (type) => {
        try {
            if (type === 'to') {
                await schema.validate(data);

                handleAddOption(data, 'to');
                handleCancel('to');
            }

            if (type === 'cc') {
                await schema.validate(dataCC);

                handleAddOption(dataCC, 'cc');
                handleCancel('cc');
            }

            if (type === 'bcc') {
                await schema.validate(dataBCC);

                handleAddOption(dataBCC, 'bcc');
                handleCancel('bcc');
            }
        } catch (err) {
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    };

    const handleChange = (e, type) => {
        if (type === 'to') {
            setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }

        if (type === 'cc') {
            setDataCC((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        }

        if (type === 'bcc') {
            setDataBCC((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
            }));
        }
    };

    return (
        <div className="flex w-full flex-col gap-3 p-2 pb-0">
            <div className="flex w-full gap-3 sm:flex-col">
                <div
                    className="flex-shrink"
                    style={{
                        width:
                            open || width < 600
                                ? '100%'
                                : 'calc(100% - 5.15rem)',
                    }}
                >
                    <Autocomplete
                        multiple
                        autoComplete
                        fullWidth
                        openOnFocus
                        classes={{
                            root: classesAutocomplete.root,
                            option: classesAutocomplete.option,
                        }}
                        PopperComponent={CustomPopper}
                        filterSelectedOptions
                        id="email-to-users"
                        disabled={disableSend}
                        options={emailOptions.sort(
                            (a, b) =>
                                -b.profile.name
                                    .toUpperCase()
                                    .localeCompare(
                                        a.profile.name.toUpperCase(),
                                    ),
                        )}
                        getOptionLabel={(option) =>
                            `${option.profile.name}${option.profile.name ? ', ' : ''}<${option.email}>`
                        }
                        onChange={handleEmailToChange}
                        value={emailToUsers}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t('TO')}
                                className={classes.textField}
                            />
                        )}
                        style={{ zIndex: 10000 }}
                    />
                </div>

                {!open && (
                    <div className="flex-shrink-0" style={{ zIndex: 11000 }}>
                        <BasicTooltip
                            position="left"
                            tip={t('Add new recipient option')}
                        >
                            <Button
                                style={{
                                    minWidth: width < 600 ? 'auto' : '4.4rem',
                                    width: width < 600 ? 'auto' : '4.4rem',
                                    height: width < 600 ? 'auto' : '4.4rem',
                                }}
                                onClick={() => setOpen(true)}
                            >
                                <AddIcon />
                                {width < 600 && t('Add new recipient option')}
                            </Button>
                        </BasicTooltip>
                    </div>
                )}
            </div>

            {open && (
                <form className="flex w-full max-w-5xl items-center gap-3 self-end rounded-md bg-layout-transparent p-2 sm:flex-col">
                    <TextField
                        label="Alias"
                        placeholder="Alias"
                        name="alias"
                        value={data.alias}
                        onChange={(e) => handleChange(e, 'to')}
                    />
                    <TextField
                        label="Email*"
                        placeholder="Email*"
                        name="email"
                        value={data.email}
                        onChange={(e) => handleChange(e, 'to')}
                    />
                    <div className="mt-5 flex gap-3 sm:mt-0">
                        <BasicTooltip position="top" tip={t('Add')}>
                            <ActionButton
                                icon={<AddIcon />}
                                color={`var(--success)`}
                                size={9}
                                onClick={() => handleAddRecipient('to')}
                            />
                        </BasicTooltip>
                        <BasicTooltip position="top" tip={t('Cancel')}>
                            <ActionButton
                                icon={<CloseIcon />}
                                color={`var(--error)`}
                                size={9}
                                onClick={() => handleCancel('to')}
                            />
                        </BasicTooltip>
                    </div>
                </form>
            )}

            <Autocomplete
                multiple
                autoComplete
                fullWidth
                openOnFocus
                classes={{
                    root: classesAutocomplete.root,
                    option: classesAutocomplete.option,
                }}
                PopperComponent={CustomPopper}
                filterSelectedOptions
                id="email-reply-to-users"
                disabled={disableSend}
                options={emailOptions.sort(
                    (a, b) =>
                        -b.profile.name
                            .toUpperCase()
                            .localeCompare(a.profile.name.toUpperCase()),
                )}
                getOptionLabel={(option) =>
                    `${option.profile.name}${option.profile.name ? ', ' : ''}<${option.email}>`
                }
                onChange={handleEmailReplyToChange}
                value={emailReplyToUsers}
                PaperComponent={CustomPaper}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={t('REPLY TO')}
                        className={classes.textField}
                    />
                )}
                style={{ zIndex: 10000 }}
            />

            <div className="flex w-full gap-3 sm:flex-col">
                <div
                    className="flex-shrink"
                    style={{
                        width:
                            openCC || width < 600
                                ? '100%'
                                : 'calc(100% - 5.15rem)',
                    }}
                >
                    <Autocomplete
                        multiple
                        autoComplete
                        fullWidth
                        openOnFocus
                        classes={{
                            root: classesAutocomplete.root,
                            option: classesAutocomplete.option,
                        }}
                        PopperComponent={CustomPopper}
                        filterSelectedOptions
                        id="email-cc-users"
                        disabled={disableSend}
                        options={emailCcOptions.sort(
                            (a, b) =>
                                -b.profile.name
                                    .toUpperCase()
                                    .localeCompare(
                                        a.profile.name.toUpperCase(),
                                    ),
                        )}
                        getOptionLabel={(option) =>
                            `${option.profile.name}${option.profile.name ? ', ' : ''}<${option.email}>`
                        }
                        onChange={handleEmailCcChange}
                        value={emailCcUsers}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t('CARBON COPY')}
                                className={classes.textField}
                            />
                        )}
                        style={{ zIndex: 10000 }}
                    />
                </div>

                {!openCC && (
                    <div className="flex-shrink-0" style={{ zIndex: 11000 }}>
                        <BasicTooltip
                            position="left"
                            tip={t('Add new carbon copy option')}
                        >
                            <Button
                                style={{
                                    minWidth: width < 600 ? 'auto' : '4.4rem',
                                    width: width < 600 ? 'auto' : '4.4rem',
                                    height: width < 600 ? 'auto' : '4.4rem',
                                }}
                                onClick={() => setOpenCC(true)}
                            >
                                <AddIcon />
                                {width < 600 && t('Add new carbon copy option')}
                            </Button>
                        </BasicTooltip>
                    </div>
                )}
            </div>

            {openCC && (
                <form className="flex w-full max-w-5xl items-center gap-3 self-end rounded-md bg-layout-transparent p-2 sm:flex-col">
                    <TextField
                        label="Alias"
                        placeholder="Alias"
                        name="alias"
                        value={dataCC.alias}
                        onChange={(e) => handleChange(e, 'cc')}
                    />
                    <TextField
                        label="Email*"
                        placeholder="Email*"
                        name="email"
                        value={dataCC.email}
                        onChange={(e) => handleChange(e, 'cc')}
                    />
                    <div className="mt-5 flex gap-3 sm:mt-0">
                        <BasicTooltip position="top" tip={t('Add')}>
                            <ActionButton
                                icon={<AddIcon />}
                                color={`var(--success)`}
                                size={9}
                                onClick={() => handleAddRecipient('cc')}
                            />
                        </BasicTooltip>
                        <BasicTooltip position="top" tip={t('Cancel')}>
                            <ActionButton
                                icon={<CloseIcon />}
                                color={`var(--error)`}
                                size={9}
                                onClick={() => handleCancel('cc')}
                            />
                        </BasicTooltip>
                    </div>
                </form>
            )}

            <div className="flex w-full gap-3 sm:flex-col">
                <div
                    className="flex-shrink"
                    style={{
                        width:
                            openBCC || width < 600
                                ? '100%'
                                : 'calc(100% - 5.15rem)',
                    }}
                >
                    <Autocomplete
                        multiple
                        autoComplete
                        fullWidth
                        openOnFocus
                        classes={{
                            root: classesAutocomplete.root,
                            option: classesAutocomplete.option,
                        }}
                        PopperComponent={CustomPopper}
                        filterSelectedOptions
                        id="email-bcc-users"
                        disabled={disableSend}
                        options={emailBccOptions.sort(
                            (a, b) =>
                                -b.profile.name
                                    .toUpperCase()
                                    .localeCompare(
                                        a.profile.name.toUpperCase(),
                                    ),
                        )}
                        getOptionLabel={(option) =>
                            `${option.profile.name}${option.profile.name ? ', ' : ''}<${option.email}>`
                        }
                        onChange={handleEmailBccChange}
                        value={emailBccUsers}
                        PaperComponent={CustomPaper}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={t('BLIND CARBON COPY')}
                                className={classes.textField}
                            />
                        )}
                        style={{ zIndex: 10000 }}
                    />
                </div>

                {!openBCC && (
                    <div className="flex-shrink-0" style={{ zIndex: 11000 }}>
                        <BasicTooltip
                            position="left"
                            tip={t('Add new blind carbon copy option')}
                        >
                            <Button
                                style={{
                                    minWidth: width < 600 ? 'auto' : '4.4rem',
                                    width: width < 600 ? 'auto' : '4.4rem',
                                    height: width < 600 ? 'auto' : '4.4rem',
                                }}
                                onClick={() => setOpenBCC(true)}
                            >
                                <AddIcon />
                                {width < 600 &&
                                    t('Add new blind carbon copy option')}
                            </Button>
                        </BasicTooltip>
                    </div>
                )}
            </div>

            {openBCC && (
                <form className="flex w-full max-w-5xl items-center gap-3 self-end rounded-md bg-layout-transparent p-2 sm:flex-col">
                    <TextField
                        label="Alias"
                        placeholder="Alias"
                        name="alias"
                        value={dataBCC.alias}
                        onChange={(e) => handleChange(e, 'bcc')}
                    />
                    <TextField
                        label="Email*"
                        placeholder="Email*"
                        name="email"
                        value={dataBCC.email}
                        onChange={(e) => handleChange(e, 'bcc')}
                    />
                    <div className="mt-5 flex gap-3 sm:mt-0">
                        <BasicTooltip position="top" tip={t('Add')}>
                            <ActionButton
                                icon={<AddIcon />}
                                color={`var(--success)`}
                                size={9}
                                onClick={() => handleAddRecipient('bcc')}
                            />
                        </BasicTooltip>
                        <BasicTooltip position="top" tip={t('Cancel')}>
                            <ActionButton
                                icon={<CloseIcon />}
                                color={`var(--error)`}
                                size={9}
                                onClick={() => handleCancel('bcc')}
                            />
                        </BasicTooltip>
                    </div>
                </form>
            )}

            <TextField
                variant="outlined"
                color="primary"
                className={classes.textField}
                name="emailSubject"
                disabled={disableSend}
                onChange={handleSubjectChange}
                value={emailSubject}
                InputProps={{
                    startAdornment: (
                        <InputAdornment className="w-24 px-3" position="start">
                            {t('SUBJECT')}
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

EmailNotificationInfo.propTypes = {
    disableSend: PropTypes.bool,
    emailOptions: PropTypes.array,
    handleEmailToChange: PropTypes.func,
    emailToUsers: PropTypes.array,
    handleEmailReplyToChange: PropTypes.func,
    emailReplyToUsers: PropTypes.array,
    handleSubjectChange: PropTypes.func,
    emailSubject: PropTypes.string,
    handleAddOption: PropTypes.func,
    emailCcUsers: PropTypes.array,
    emailCcOptions: PropTypes.array,
    emailBccUsers: PropTypes.array,
    emailBccOptions: PropTypes.array,
    handleEmailCcChange: PropTypes.func,
    handleEmailBccChange: PropTypes.func,
};

EmailNotificationInfo.defaultProps = {
    disableSend: false,
    emailOptions: [],
    handleEmailToChange: () => {},
    emailToUsers: [],
    handleEmailReplyToChange: () => {},
    emailReplyToUsers: [],
    handleSubjectChange: () => {},
    emailSubject: '',
    handleAddOption: () => {},
    emailCcUsers: [],
    emailCcOptions: [],
    emailBccUsers: [],
    emailBccOptions: [],
    handleEmailCcChange: () => {},
    handleEmailBccChange: () => {},
};

export default EmailNotificationInfo;
