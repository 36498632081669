import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button } from '@material-ui/core';

import TableDisplay from 'components/shared/configurator/table-display';
import UserContext from 'contexts/UserContext';
import { Header, Search } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { internalActivity } from 'routes';

const ManagePartners = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '4',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '4',
            permissionType: 'VIEW',
        },
    ]);

    const [query, setQuery] = useState('');

    useEffect(() => {
        if (!canView) history.push('/');
    }, [canView]);

    return (
        <>
            <Helmet>
                <title>{t('Manage Partners')}</title>
            </Helmet>

            <Header
                pageTitle={t('Manage Partners')}
                action={
                    <>
                        <Search
                            searchIconBig={true}
                            value={query}
                            setValue={setQuery}
                            withSearchIcon={false}
                        />
                        {canAll && (
                            <Button
                                startIcon={<AddIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={() =>
                                    history.push(
                                        internalActivity.base +
                                            internalActivity.partners.base +
                                            internalActivity.partners.create,
                                    )
                                }
                            >
                                {t('Add new partner')}
                            </Button>
                        )}
                    </>
                }
            />

            {canView && (
                <div className="page-container">
                    <TableDisplay entity="Partners" query={query} />
                </div>
            )}
        </>
    );
};

export default ManagePartners;
