import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import CallMergeIcon from '@material-ui/icons/CallMerge';
import DeleteIcon from '@material-ui/icons/Delete';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ListAltIcon from '@material-ui/icons/ListAlt';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { Button, TableCell } from '@material-ui/core';

import ProjectDisplay from 'components/shared/configurator/project-display';
import ErrorButton from 'components/shared/error-button';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ExpandableRow, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import {
    calculateTotal,
    calculateVATValue,
    errorHandling,
    toLocaleNumber,
} from 'utils';
import API from 'utils/axios';

const ArticleRowWithStocks = (props) => {
    const {
        nomenclature: article,
        isInitializationCase,
        onOpensStockModal,
        setLoading,
        getArticlesCallback,
    } = props;
    const history = useHistory();
    const {
        currencyObj,
        setGlobalModalOpen,
        setGlobalModalChildren,
        language,
    } = useContext(GlobalContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const optionClass = 'text-md text-dark-text mb-4';
    const optionClassValues = 'text-md text-dark-text mb-4 flex flex-col';
    const optionSpanClass = 'text-main-text font-bold';

    const stocksStr = article.Stocks.reduce(
        (acc, curr, index) =>
            index === 0 ? acc + curr.name : acc + `, ${curr.name}`,
        '',
    );

    /** Functions for calculation of VAT and finale price with VAT  */
    const totalPrice =
        Number(article.price.pricePerUnit) +
        article.options
            .map((a) => Number(a.price.pricePerUnit))
            ?.reduce((a, b) => a + b, 0);
    const totalVatValue =
        Number(
            calculateVATValue(
                article.price.pricePerUnit,
                article.price.procentualVAT,
            ),
        ) +
        article.options
            .map((a) =>
                Number(
                    calculateVATValue(
                        a.price.pricePerUnit,
                        a.price.procentualVAT,
                    ),
                ),
            )
            ?.reduce((a, b) => a + b, 0);

    const { checkPerm } = useContext(UserContext);

    const canAllArticles = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    const canAllStocks = checkPerm([
        {
            permissionId: '49',
            permissionType: 'ALL',
        },
    ]);

    const handleDuplicateRequest = async () => {
        try {
            setLoading(true);
            setGlobalModalOpen(false);

            await API.post('duplicateArticle', undefined, {
                params: {
                    id: article.id,
                },
            });
            await getArticlesCallback();

            enqueueSnackbar(t('The article was duplicated successfully!'), {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setGlobalModalChildren(null);
            setLoading(false);
        }
    };

    const handleDuplicateArticle = () => {
        setGlobalModalOpen(true);
        setGlobalModalChildren(
            <div className="flex-col">
                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                    {t('Are you sure you want to duplicate this article?')}
                </p>
                <div className="flex items-center justify-center">
                    <Button
                        id="global-yes-button-root"
                        color="primary"
                        onClick={handleDuplicateRequest}
                    >
                        {t('Yes')}
                    </Button>
                    <div className="w-4" />
                    <Button
                        id="global-no-button-root"
                        onClick={() => setGlobalModalOpen(false)}
                    >
                        {t('No')}
                    </Button>
                </div>
            </div>,
        );
    };

    const handleDeleteRequest = async () => {
        try {
            setGlobalModalOpen(false);
            setLoading(true);

            await API.patch('deleteArticle', undefined, {
                params: {
                    id: article.id,
                },
            });

            await getArticlesCallback();

            enqueueSnackbar(t('The article was deleted successfully!'), {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setGlobalModalChildren(null);
            setLoading(false);
        }
    };

    const handleDeleteArticle = () => {
        setGlobalModalOpen(true);
        setGlobalModalChildren(
            <div className="flex-col">
                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                    {t('Are you sure you want to delete this article?')}
                </p>
                <div className="flex items-center justify-center">
                    <Button
                        id="global-yes-button-root"
                        color="primary"
                        onClick={handleDeleteRequest}
                    >
                        {t('Yes')}
                    </Button>
                    <div className="w-4" />
                    <Button
                        id="global-no-button-root"
                        onClick={() => setGlobalModalOpen(false)}
                    >
                        {t('No')}
                    </Button>
                </div>
            </div>,
        );
    };

    return (
        <ExpandableRow
            colSpan={7}
            row={
                <>
                    <TableCell>{article.name}</TableCell>
                    <TableCell>
                        {article.categories.map(
                            (c, index) =>
                                c.categorie.name +
                                (index < article.categories.length - 1
                                    ? ', '
                                    : ''),
                        )}
                    </TableCell>
                    <TableCell>
                        {toLocaleNumber(totalPrice, language, 2)}{' '}
                        {currencyObj.currency}
                    </TableCell>
                    <TableCell>
                        {toLocaleNumber(
                            article.price.procentualVAT,
                            language,
                            2,
                        )}{' '}
                        %
                    </TableCell>
                    <TableCell>
                        {toLocaleNumber(totalVatValue, language, 2)}{' '}
                        {currencyObj.currency}
                    </TableCell>
                    <TableCell>
                        {toLocaleNumber(
                            totalPrice + totalVatValue,
                            language,
                            2,
                        )}{' '}
                        {currencyObj.currency}
                    </TableCell>
                    <TableCell>{article.options.length}</TableCell>
                    <TableCell>{stocksStr}</TableCell>
                    <TableCell>
                        <p
                            style={{
                                color: article.pm_planningOverviewProject?.name
                                    ? 'var(--main-text)'
                                    : 'var(--dark-text)',
                            }}
                        >
                            {article.pm_planningOverviewProject?.name ??
                                `${t('Not assigned to a project')}`}
                        </p>
                    </TableCell>
                </>
            }
            collapse={
                <>
                    {(canAllArticles || canAllStocks) &&
                        !isInitializationCase && (
                            <div className="flex justify-between gap-3 p-3">
                                <div className="flex gap-3">
                                    {canAllArticles && (
                                        <Button
                                            color="secondary"
                                            startIcon={
                                                <VisibilityOutlinedIcon
                                                    style={{ fontSize: 25 }}
                                                />
                                            }
                                            onClick={() =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.nomenclature
                                                            .base +
                                                        quotes.nomenclature
                                                            .update +
                                                        '/' +
                                                        article.id,
                                                )
                                            }
                                        >
                                            {t('View article & edit')}
                                        </Button>
                                    )}

                                    {canAllStocks && (
                                        <Button
                                            color="secondary"
                                            startIcon={
                                                <ListAltIcon
                                                    style={{ fontSize: 25 }}
                                                />
                                            }
                                            onClick={() =>
                                                onOpensStockModal(article.id)
                                            }
                                        >
                                            {t('Create stock')}
                                        </Button>
                                    )}

                                    {canAllArticles && (
                                        <>
                                            <Button
                                                color="secondary"
                                                startIcon={
                                                    <FileCopyIcon
                                                        style={{ fontSize: 25 }}
                                                    />
                                                }
                                                onClick={handleDuplicateArticle}
                                            >
                                                {t('Duplicate article')}
                                            </Button>
                                            <ErrorButton
                                                startIcon={<DeleteIcon />}
                                                onClick={handleDeleteArticle}
                                            >
                                                {t('Delete article')}
                                            </ErrorButton>
                                        </>
                                    )}
                                </div>

                                {canAllArticles && (
                                    <div className="flex gap-3">
                                        <Button
                                            color="primary"
                                            startIcon={
                                                <EditAttributesIcon
                                                    style={{ fontSize: 25 }}
                                                />
                                            }
                                            onClick={() =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.attributes.base +
                                                        '/' +
                                                        article.id,
                                                )
                                            }
                                        >
                                            {article.ArticleAttribute.length
                                                ? t('Add or view attributes')
                                                : t('Add attributes')}
                                        </Button>

                                        <Button
                                            color="primary"
                                            startIcon={
                                                <CallMergeIcon
                                                    style={{ fontSize: 25 }}
                                                />
                                            }
                                            onClick={() =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.variations.base +
                                                        '/' +
                                                        article.id,
                                                )
                                            }
                                        >
                                            {article.ArticleVariations.length
                                                ? t('Add or view variations')
                                                : t('Add variations')}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    <div className="-ml-3 grid w-4/5 grid-cols-2 gap-16 2xl:w-3/4 xl:w-full lg:-ml-0  lg:grid-cols-1 lg:p-4">
                        <div>
                            <div className="mt-10 bg-layout-transparent p-6">
                                <h4 className="mb-5 break-words text-2xl">
                                    {article.name}
                                </h4>
                                <p className={optionClass}>
                                    {t('Article type')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.type === 'SERVICES'
                                            ? t('Service')
                                            : article.type === 'FIZIC'
                                              ? t('Physical product')
                                              : article.type === 'DIGITAL'
                                                ? t('Digital product')
                                                : ''}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Article category')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.categories.map(
                                            (c, index) =>
                                                c.categorie.name +
                                                (index <
                                                article.categories.length - 1
                                                    ? ', '
                                                    : ''),
                                        )}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Article code(SKU)')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.sku}
                                    </span>
                                </p>
                                <p className={optionClass}>
                                    {t('Description')}:{' '}
                                    <span className={optionSpanClass}>
                                        {article.description}
                                    </span>
                                </p>

                                <div className="inline-flex gap-6">
                                    <p className={optionClassValues}>
                                        {t('Price without VAT')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                article.price.pricePerUnit,
                                                language,
                                                2,
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('VAT rate')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                article.price.procentualVAT,
                                                language,
                                                2,
                                            )}{' '}
                                            %
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('VAT value')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                calculateVATValue(
                                                    article.price.pricePerUnit,
                                                    article.price.procentualVAT,
                                                ),
                                                language,
                                                2,
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                    <p className={optionClassValues}>
                                        {t('Final price with VAT')}:{' '}
                                        <span className={optionSpanClass}>
                                            {toLocaleNumber(
                                                calculateTotal(
                                                    calculateVATValue(
                                                        article.price
                                                            .pricePerUnit,
                                                        article.price
                                                            .procentualVAT,
                                                    ),
                                                    article.price.pricePerUnit,
                                                ),
                                                language,
                                                2,
                                            )}{' '}
                                            {currencyObj.currency}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {article.options.map((o) => (
                                <div
                                    className="border-t-2 border-layout-transparent-dark p-6 hover:bg-layout-transparent"
                                    key={o.id}
                                >
                                    <h4 className="mb-5 text-2xl">{o.name}</h4>
                                    <p className={optionClass}>
                                        {t('Variable code(SKU)')}:{' '}
                                        <span className={optionSpanClass}>
                                            {o.sku}
                                        </span>
                                    </p>
                                    <p className={optionClass}>
                                        {t('Description')}:{' '}
                                        <span className={optionSpanClass}>
                                            {o.description}
                                        </span>
                                    </p>
                                    {o.price.type === 'BASE_PROCENT' ? (
                                        <p className={optionClass}>
                                            {t('Percentage of basic item')}:{' '}
                                            <span className={optionSpanClass}>
                                                {o.price.priceProcent} %
                                            </span>
                                        </p>
                                    ) : o.price.type ===
                                      'BASE_PROCENT_AND_VARIABLE' ? (
                                        <p className={optionClass}>
                                            {t(
                                                'Percentage of the entire article(article + all variables)',
                                            )}
                                            :{' '}
                                            <span className={optionSpanClass}>
                                                {o.price.priceProcent} %
                                            </span>
                                        </p>
                                    ) : null}

                                    <div className="inline-flex gap-6">
                                        <p className={optionClassValues}>
                                            {t('Price without VAT')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    o.price.pricePerUnit,
                                                    language,
                                                    2,
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('VAT rate')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    o.price.procentualVAT,
                                                    language,
                                                    2,
                                                )}{' '}
                                                %
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('VAT value')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    calculateVATValue(
                                                        o.price.pricePerUnit,
                                                        o.price.procentualVAT,
                                                    ),
                                                    language,
                                                    2,
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                        <p className={optionClassValues}>
                                            {t('Final price with VAT')}:{' '}
                                            <span className={optionSpanClass}>
                                                {toLocaleNumber(
                                                    calculateTotal(
                                                        calculateVATValue(
                                                            o.price
                                                                .pricePerUnit,
                                                            o.price
                                                                .procentualVAT,
                                                        ),
                                                        o.price.pricePerUnit,
                                                    ),
                                                    language,
                                                    2,
                                                )}{' '}
                                                {currencyObj.currency}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-9/12 sm:w-full">
                            <div className="mt-10 bg-layout-transparent p-7">
                                <h4 className="mb-4 text-2xl">
                                    {t('Price with all variables')}
                                </h4>
                                <div className="grid grid-cols-3 gap-2 xl:grid-cols-1">
                                    <div>
                                        <LabelWrapper
                                            label={t('Price without VAT')}
                                        >
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(
                                                    totalPrice,
                                                    language,
                                                    2,
                                                )}{' '}
                                                {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>

                                    <div>
                                        <LabelWrapper label={t('VAT value')}>
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(
                                                    totalVatValue,
                                                    language,
                                                    2,
                                                )}{' '}
                                                {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>

                                    <div>
                                        <LabelWrapper
                                            label={t('Final price with VAT')}
                                        >
                                            <div className="pointer-events-none rounded-md bg-layout-transparent p-3 text-main-text">
                                                {toLocaleNumber(
                                                    totalPrice + totalVatValue,
                                                    language,
                                                    2,
                                                )}{' '}
                                                {currencyObj.currency}
                                            </div>
                                        </LabelWrapper>
                                    </div>
                                </div>
                            </div>
                            {article.pm_planningOverviewProject && (
                                <ProjectDisplay
                                    project={article.pm_planningOverviewProject}
                                    style={{
                                        containerStyle: {
                                            marginTop: '4rem',
                                            padding: '1.75rem',
                                            backgroundColor:
                                                'var(--layout-transparent)',
                                        },
                                        headerStyle: {
                                            fontSize: '1.5rem',
                                            lineHeight: '1.5rem',
                                        },
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </>
            }
        />
    );
};

ArticleRowWithStocks.propTypes = {
    nomenclature: PropTypes.object,
    isInitializationCase: PropTypes.bool,
    onOpensStockModal: PropTypes.func,
    getArticlesCallback: PropTypes.func,
    setLoading: PropTypes.func,
};

ArticleRowWithStocks.defaultValues = {
    nomenclature: null,
    isInitializationCase: false,
    onOpensStockModal: () => {},
    getArticlesCallback: () => {},
    setLoading: () => {},
};

export default ArticleRowWithStocks;
