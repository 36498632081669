import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { EllipsisVertical, Eye, Pencil, Trash } from 'lucide-react';

import { TableCell, TableRow } from '@material-ui/core';

import ActionsPopover from 'components/shared/actions-popover/actions-popover';
import UsersAvatars from 'components/shared/users-avatars/users-avatars';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { kpi as kpiRoutes } from 'routes';
import { errorHandling, formatDate } from 'utils';

const KpiNomenclatureRow = ({ kpi, onData }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '64',
            permissionType: 'ALL',
        },
    ]);

    const { id, name, type, createAt, users, monitorUsers } = kpi;
    const { name: typeName } = type;

    const [loading, setLoading] = useState(false);

    const handleDeleteKpi = () => {
        try {
            setLoading(true);
            onData(0, 10);

            //TODO: Handle delete kpi
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const actions = [
        {
            icon: <Eye />,
            text: t('View'),
            action: () =>
                history.push(
                    kpiRoutes.base +
                        kpiRoutes[monitorUsers ? 'crm' : 'personal'].base +
                        '/' +
                        id,
                ),
            confirm: false,
        },
        {
            icon: <Pencil />,
            text: t('Edit'),
            action: () =>
                history.push(
                    kpiRoutes.base +
                        kpiRoutes.nomenclature.base +
                        kpiRoutes.nomenclature.update.base +
                        '/' +
                        id,
                ),
            confirm: false,
            disabled: !canAll,
        },
        {
            icon: <Trash />,
            text: t('Delete'),
            action: () => handleDeleteKpi(),
            confirm: true,
            disabled: !canAll,
        },
    ];

    return (
        <TableRow>
            <TableCell>{name}</TableCell>
            <TableCell>{typeName}</TableCell>
            <TableCell>{formatDate(createAt)}</TableCell>
            <TableCell className="w-full">
                <UsersAvatars users={users} />
            </TableCell>
            <TableCell>
                <div className="flex h-10 justify-end">
                    <ActionsPopover
                        icon={<EllipsisVertical className="h-6 w-6" />}
                        opacity={{ default: 0.8, hover: 1 }}
                        actions={actions}
                        disabled={loading}
                    />
                </div>
            </TableCell>
        </TableRow>
    );
};

KpiNomenclatureRow.propTypes = {
    kpi: PropTypes.object,
    onData: PropTypes.func,
};

KpiNomenclatureRow.defaultProps = {
    kpi: null,
    onData: () => {},
};

export default KpiNomenclatureRow;
