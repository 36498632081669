import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Button, TextField } from '@material-ui/core';

import Project from 'components/planning-overview/project';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import NotResponsiveYet from 'components/shared/not-responsive-yet';
import GantContext from 'contexts/GantContext';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import useMonths from 'hooks/useMonths';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';
import { formatDate, ganttColorPallette } from 'utils/index';

import { cellHeight, cellStyle } from '../config';
import EmployeeData from '../employee-data';

const Gantt = ({ tasksRequest, projects, setProjects, getProjects }) => {
    const { theme } = useContext(GlobalContext);
    const { type, viewOnly = false } = useContext(GantContext);
    const history = useHistory();
    const [teamToUpdate, setTeamToUpdate] = useState('');
    const DAY = 1000 * 60 * 60 * 24;
    const { t } = useTranslation();

    const isModulePM =
        history.location.pathname.search('/project-management') > -1 ||
        history.location.pathname.search('/projects-in-management') > -1;

    const { checkPerm } = useContext(UserContext);

    const canAllCrmPlanningOverview = checkPerm([
        {
            permissionId: '3',
            permissionType: 'ALL',
        },
    ]);
    const canAllPmPlanningOverview = checkPerm([
        {
            permissionId: '58',
            permissionType: 'ALL',
        },
    ]);

    const canAll = isModulePM
        ? canAllPmPlanningOverview
        : canAllCrmPlanningOverview;

    const canViewContacts = checkPerm([
        {
            permissionId: '6',
            permissionType: 'ALL',
        },
    ]);

    const { enqueueSnackbar } = useSnackbar();

    const [zeroMoment, setZeroMoment] = useState(null);

    /**
     * Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
     * but by using 0 as the day it will give us the last day of the prior
     * month. So passing in 1 as the month number will return the last day
     * of January, not February
     */
    const getMonthDays = useCallback((month, year) => {
        const days = new Date(year, month, 0).getDate();
        return days;
    }, []);

    const getWeekDay = useCallback(() => {
        let day = new Date().getDay();
        if (day === 0) day = 7;
        return day;
    }, []);

    /**
     * Function to get miliseconds of a day (default is today) at 00:00
     */
    const getDateFirstMoment = (date = null) => {
        let newDate;
        if (date) {
            newDate = new Date(date);
        } else {
            newDate = new Date();
        }
        newDate.getTime();
        return newDate;
    };

    /**
     * Function to convert day column to timestamp
     */
    const getColDate = useCallback(
        (col) => {
            if (!zeroMoment) return 0;

            let zeroMilis = zeroMoment.getTime();
            zeroMilis += col * DAY;
            const newDate = new Date(zeroMilis);
            return newDate;
        },
        [zeroMoment],
    );

    const [selectedMonths, setSelectedMonths] = useState([]);
    const [dragInfo, setDragInfo] = useState(null);
    const [newTaskInfo, setNewTaskInfo] = useState(null);
    const totalDaysDisplayed = selectedMonths.reduce((total, mon) => {
        if (!zeroMoment) return 0;
        const actualDate = new Date(zeroMoment);
        actualDate.setMonth(mon);
        return (
            total +
            getMonthDays(actualDate.getMonth() + 1, actualDate.getFullYear())
        );
    }, 0);

    // Getting the initial 2 months in the calendar header
    useEffect(() => {
        const newZeroMoment = new Date();
        newZeroMoment.setDate(1);
        newZeroMoment.getTime();
        setZeroMoment(newZeroMoment);
    }, []);

    useEffect(() => {
        if (!zeroMoment) return;
        const currentMonth = zeroMoment.getMonth();
        const newSelectedMonths = [currentMonth, currentMonth + 1];
        setSelectedMonths(newSelectedMonths);
    }, [zeroMoment]);

    // Create task toggle
    const [newTaskOpened, setNewTaskOpened] = useState(false);
    const [newTaskName, setNewTaskName] = useState('');

    // Employee data modal
    const [employeeModal, setEmployeeModal] = useState(null);

    // Close employee modal when creating a new task
    useEffect(() => {
        if (newTaskOpened) {
            setEmployeeModal(null);
        }
    }, [newTaskOpened]);

    // Close new task modal when viewing an employee data
    useEffect(() => {
        if (employeeModal !== null) {
            setNewTaskOpened(false);
        }
    }, [employeeModal]);

    const months = useMonths();

    /**
     * Function to add a new task
     */
    const addEmployeeTask = useCallback(async () => {
        if (!canAll || viewOnly) return;
        const {
            employeeId,
            employeeIndex,
            teamIndex,
            teamId,
            projectId,
            projectIndex,
            fromCol,
            toCol,
        } = newTaskInfo;

        let body;
        if (fromCol > toCol) {
            body = {
                projectId,
                employeeId,
                teamId,
                name: newTaskName,
                startDate: getColDate(toCol),
                endDate: getColDate(fromCol),
            };
        } else {
            body = {
                projectId,
                employeeId,
                teamId,
                name: newTaskName,
                startDate: getColDate(fromCol),
                endDate: getColDate(toCol),
            };
        }

        try {
            const res = await API.post(tasksRequest, body);
            const newProjects = [...projects];

            let newTask;
            if (type === 'internal') newTask = res.data.createdEmployeeTask;
            else newTask = res.data.employeeTask;

            const newTasks = [
                ...newProjects[projectIndex].teams[teamIndex].employees[
                    employeeIndex
                ].employee.tasks,
            ];
            newTasks.push(newTask);
            newProjects[projectIndex].teams[teamIndex].employees[
                employeeIndex
            ].employee.tasks = newTasks;
            setProjects(newProjects);
            setNewTaskName('');

            setTeamToUpdate(teamId);
        } catch (err) {
            console.error(err);

            if (
                err.response.status === 400 &&
                err.response.data.type === 'string'
            ) {
                enqueueSnackbar(
                    t('The task is overlapping with another task!'),
                    {
                        variant: 'error',
                    },
                );
            } else if (
                err.response.status === 400 &&
                err.response.data?.type === 'string.empty'
            ) {
                enqueueSnackbar(t('The task must have a name!'), {
                    variant: 'error',
                });
            }
        }
    }, [newTaskName, newTaskInfo, viewOnly, canAll]);

    /**
     * Function to update a task (when extending the task length)
     */
    const updateTask = useCallback(
        async (task, teamId, callback) => {
            if (!canAll || viewOnly) return;

            try {
                await API.put(tasksRequest, {
                    id: task.id,
                    data: {
                        projectId: task.projectId,
                        employeeId: task.employeeId,
                        teamId,
                        name: task.name,
                        startDate: task.startDate,
                        endDate: task.endDate,
                    },
                });
                callback();

                setTeamToUpdate(teamId);
            } catch (err) {
                console.error(err);
                if (err.response.status === 400) {
                    enqueueSnackbar(
                        t('The task is overlapping with another task!'),
                        {
                            variant: 'error',
                        },
                    );
                }
            }
        },
        [viewOnly, canAll],
    );

    const SpaceRow = ({ height = cellHeight }) => {
        return (
            <tr>
                <td colSpan={totalDaysDisplayed} style={{ height }}></td>
            </tr>
        );
    };

    SpaceRow.propTypes = {
        height: PropTypes.number,
    };

    /**
     * Window events
     */
    useEffect(() => {
        const handleMouseUp = (e) => {
            if (!dragInfo) return;
            else {
                e.preventDefault();
                setDragInfo(null);
            }
        };
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragInfo]);

    return !projects.length ? (
        <NoDataPlaceholder />
    ) : (
        <>
            {/* lg:hidden */}
            <div className="relative flex w-full p-16 ">
                {/**
                 * Create new TASK modal
                 */}
                {canAll && !viewOnly && (
                    <div
                        className="fixed left-1/2 top-1/2 z-50 w-96 rounded-lg bg-layout-light p-6 shadow-md transition-all"
                        style={{
                            transform: newTaskOpened
                                ? 'translate(-50%, -50%)'
                                : 'translate(-50%, calc(-50% - 20px))',
                            opacity: newTaskOpened ? '1' : '0',
                            pointerEvents: newTaskOpened ? 'auto' : 'none',
                        }}
                    >
                        <div className="space-y-8">
                            <h5 className="mb-6 text-center">
                                {t('Add task')}
                            </h5>
                            <div className="flex flex-col space-y-2">
                                {/* <p className="text-sm text-layout-transparent-dark">
                                Asignee:{' '}
                                {newTaskInfo && newTaskInfo.projectIndex in projects ? (
                                    <span className="text-white">
                                        {
                                            projects[newTaskInfo.projectIndex].teams[newTaskInfo.teamIndex].employees[
                                                newTaskInfo.employeeIndex
                                            ].employee.firstName
                                        }
                                    </span>
                                ) : (
                                    ''
                                )}
                            </p> */}
                                <p className="text-sm text-main-text">
                                    Start:{' '}
                                    <span className="font-semibold text-main-text  opacity-70">
                                        {formatDate(
                                            getColDate(newTaskInfo?.fromCol),
                                        )}
                                    </span>
                                </p>
                                <p className="text-sm text-main-text">
                                    {t('End')}:{' '}
                                    <span className="font-semibold text-main-text opacity-70">
                                        {formatDate(
                                            getColDate(newTaskInfo?.toCol),
                                        )}
                                    </span>
                                </p>
                            </div>

                            <TextField
                                value={newTaskName}
                                onChange={(e) => setNewTaskName(e.target.value)}
                                placeholder={t('Add new task name')}
                            />

                            <div className="flex w-full justify-end space-x-2">
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        addEmployeeTask();
                                        setNewTaskOpened(false);
                                        setNewTaskName('');
                                    }}
                                >
                                    {t('Add task')}
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => {
                                        setNewTaskOpened(false);
                                        setNewTaskName('');
                                    }}
                                >
                                    {t('Cancel')}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                <EmployeeData
                    employee={employeeModal}
                    setEmployeeModal={setEmployeeModal}
                    getProjects={getProjects}
                />

                <div
                    style={{
                        width: '350px',
                        flexShrink: 0,
                    }}
                />

                <div className="relative flex w-full flex-col">
                    {/* Month sliders next/prev */}
                    <div className="mb-1 flex items-center space-x-1">
                        {/* Month backward */}
                        <div
                            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-layout-transparent text-sm font-bold text-main-text hover:bg-layout-transparent-dark"
                            onClick={() => {
                                const newZeroMoment = new Date(zeroMoment);
                                newZeroMoment.setMonth(
                                    zeroMoment.getMonth() - 1,
                                );
                                setZeroMoment(newZeroMoment);
                            }}
                        >
                            <ArrowBackIcon style={{ fontSize: 12 }} />
                        </div>

                        {/* Month forward */}
                        <div
                            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-layout-transparent text-sm font-bold text-main-text hover:bg-layout-transparent-dark"
                            onClick={() => {
                                const newZeroMoment = new Date(zeroMoment);
                                newZeroMoment.setMonth(
                                    zeroMoment.getMonth() + 1,
                                );
                                setZeroMoment(newZeroMoment);
                            }}
                        >
                            <ArrowForwardIcon style={{ fontSize: 12 }} />
                        </div>
                    </div>

                    {/* Calendar */}
                    <div className="relative w-full flex-1">
                        {/* Left sidebar */}
                        <div
                            className="absolute right-full pr-4"
                            style={{
                                width: '350px',
                                top: `calc(${cellHeight * 2}px + 1px)`,
                            }}
                        >
                            {/* Projects */}
                            {projects.map((project, index) => {
                                return (
                                    <div
                                        key={project.id}
                                        className="relative"
                                        style={{
                                            zIndex:
                                                projects.length + 100 - index,
                                        }}
                                    >
                                        <Project
                                            project={project}
                                            getProjects={getProjects}
                                            teamToUpdate={teamToUpdate}
                                            setTeamToUpdate={setTeamToUpdate}
                                            canEditProject={canAll}
                                            canViewContacts={canViewContacts}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        {/* Right table */}
                        <div
                            className="overflow-x-scroll"
                            style={{ maxWidth: 32 * cellHeight + 2 }}
                        >
                            <table className="max-w-7xl border-collapse overflow-x-scroll text-main-text">
                                <thead>
                                    {/* Month Row */}
                                    <tr className="bg-layout-transparent">
                                        {selectedMonths.map(
                                            (month, monthIndex) => {
                                                const actualDate = new Date(
                                                    zeroMoment,
                                                );
                                                actualDate.setMonth(month);

                                                return (
                                                    <td
                                                        key={`${actualDate.getTime()}${monthIndex}`}
                                                        colSpan={getMonthDays(
                                                            actualDate.getMonth() +
                                                                1,
                                                            actualDate.getFullYear(),
                                                        )}
                                                        className="whitespace-nowrap border border-layout-lighter px-4 text-base"
                                                        style={{
                                                            height: `${cellHeight}px`,
                                                            userSelect: 'none',
                                                        }}
                                                    >
                                                        {
                                                            months[
                                                                actualDate.getMonth()
                                                            ]
                                                        }{' '}
                                                        {actualDate.getFullYear()}
                                                    </td>
                                                );
                                            },
                                        )}
                                    </tr>

                                    {/* Weeks Row */}
                                    {/* <tr className="bg-layout-transparent">
                                    {Array.from(Array(Math.ceil(totalDaysDisplayed / 7)).keys()).map((week) => (
                                        <td key={week} colSpan={week === 0 ? 7 - getWeekDay() + 1 : 7} className="border border-gray-500 px-4 whitespace-nowrap text-base" style={{ height: cellHeight, userSelect: 'none' }}>
                                            {t('Week')} {week + 1}
                                        </td>
                                    ))}
                                </tr> */}

                                    {/* Days */}
                                    <tr className="bg-layout-transparent">
                                        {selectedMonths.map((month) => {
                                            const actualDate = new Date(
                                                zeroMoment,
                                            );
                                            actualDate.setMonth(month);

                                            return Array.from(
                                                Array(
                                                    getMonthDays(
                                                        actualDate.getMonth() +
                                                            1,
                                                        actualDate.getFullYear(),
                                                    ),
                                                ).keys(),
                                            ).map((day) => (
                                                <td
                                                    key={`${month}${day}`}
                                                    className="border border-layout-lighter"
                                                    style={{
                                                        height: `${cellHeight}px`,
                                                    }}
                                                >
                                                    <div style={cellStyle}>
                                                        <p>{day + 1}</p>
                                                    </div>
                                                </td>
                                            ));
                                        })}
                                    </tr>
                                </thead>

                                <tbody
                                    onMouseLeave={() => setDragInfo(null)}
                                    style={{
                                        cursor:
                                            dragInfo !== null
                                                ? 'ew-resize'
                                                : 'auto',
                                    }}
                                >
                                    {projects.map((project, projectIndex) => (
                                        <>
                                            {/* Row between project top and first team */}
                                            <SpaceRow />

                                            {project.teams?.map(
                                                (team, teamIndex) => (
                                                    <>
                                                        {/* Team info 3 row spacing */}
                                                        <SpaceRow />
                                                        <SpaceRow />
                                                        <SpaceRow />

                                                        {team.employees.map(
                                                            (
                                                                employee,
                                                                employeeIndex,
                                                            ) => (
                                                                <tr
                                                                    key={`${project.id}${team.id}${employeeIndex}`}
                                                                >
                                                                    {Array.from(
                                                                        Array(
                                                                            totalDaysDisplayed,
                                                                        ).keys(),
                                                                    ).map(
                                                                        (
                                                                            _,
                                                                            col,
                                                                        ) => {
                                                                            let busy = false;
                                                                            let currentTask =
                                                                                null;
                                                                            let taskStart =
                                                                                null;
                                                                            let taskEnd =
                                                                                null;

                                                                            /**
                                                                             * Find out if current cell is busy by any task
                                                                             * If so, save the task and mark busy as true
                                                                             */
                                                                            employee.employee.tasks.every(
                                                                                (
                                                                                    task,
                                                                                ) => {
                                                                                    /*
                                                                                     * Here is a big problem, time gets converted from +0200 GTM to +0300 GTM, which is one hour difference, and it cannot be converted to an integer day difference
                                                                                     * Hence the Math.ceil()
                                                                                     */
                                                                                    taskStart =
                                                                                        Math.ceil(
                                                                                            (getDateFirstMoment(
                                                                                                task.startDate,
                                                                                            ).getTime() -
                                                                                                zeroMoment.getTime()) /
                                                                                                DAY,
                                                                                        );
                                                                                    taskEnd =
                                                                                        Math.ceil(
                                                                                            (getDateFirstMoment(
                                                                                                task.endDate,
                                                                                            ).getTime() -
                                                                                                zeroMoment.getTime()) /
                                                                                                DAY,
                                                                                        );

                                                                                    if (
                                                                                        task.teamId ===
                                                                                            team.id &&
                                                                                        col >=
                                                                                            taskStart &&
                                                                                        col <=
                                                                                            taskEnd
                                                                                    ) {
                                                                                        busy = true;
                                                                                        currentTask =
                                                                                            {
                                                                                                ...task,
                                                                                                from: taskStart,
                                                                                                to: taskEnd,
                                                                                            };
                                                                                    }
                                                                                    return !busy;
                                                                                },
                                                                            );

                                                                            /**
                                                                             * [...]
                                                                             */
                                                                            if (
                                                                                dragInfo &&
                                                                                !dragInfo.create
                                                                            ) {
                                                                                const {
                                                                                    projectId,
                                                                                    teamId,
                                                                                    employeeId,
                                                                                    task,
                                                                                    dir,
                                                                                    toCol,
                                                                                } =
                                                                                    dragInfo;
                                                                                if (
                                                                                    projectId ===
                                                                                        project.id &&
                                                                                    teamId ===
                                                                                        team.id &&
                                                                                    employeeId ===
                                                                                        employee
                                                                                            .employee
                                                                                            .id &&
                                                                                    (!busy ||
                                                                                        (busy &&
                                                                                            !currentTask) ||
                                                                                        (busy &&
                                                                                            currentTask.id ===
                                                                                                task.id))
                                                                                ) {
                                                                                    if (
                                                                                        dir ===
                                                                                        'left'
                                                                                    ) {
                                                                                        if (
                                                                                            col >=
                                                                                                toCol &&
                                                                                            col <=
                                                                                                task.to
                                                                                        )
                                                                                            busy = true;
                                                                                        else
                                                                                            busy = false;
                                                                                    }

                                                                                    if (
                                                                                        dir ===
                                                                                        'right'
                                                                                    ) {
                                                                                        if (
                                                                                            col <=
                                                                                                toCol &&
                                                                                            col >=
                                                                                                task.from
                                                                                        )
                                                                                            busy = true;
                                                                                        else
                                                                                            busy = false;
                                                                                    }
                                                                                }
                                                                            }

                                                                            /**
                                                                             * Dates at the task start or end
                                                                             * Display only if task has enough space (2 day slots at least)
                                                                             */
                                                                            let DATE =
                                                                                null;
                                                                            if (
                                                                                currentTask &&
                                                                                (col ===
                                                                                    currentTask.from ||
                                                                                    col ===
                                                                                        currentTask.to) &&
                                                                                currentTask.to -
                                                                                    currentTask.from >=
                                                                                    2
                                                                            ) {
                                                                                DATE =
                                                                                    new Date(
                                                                                        zeroMoment,
                                                                                    );
                                                                                DATE.setDate(
                                                                                    DATE.getDate() +
                                                                                        col,
                                                                                );
                                                                                DATE =
                                                                                    formatDate(
                                                                                        DATE,
                                                                                        false,
                                                                                    );
                                                                            }

                                                                            /**
                                                                             * Dynamic classes
                                                                             */
                                                                            const zIndex =
                                                                                col ===
                                                                                    currentTask?.to ||
                                                                                (col ===
                                                                                    currentTask?.from &&
                                                                                    'z-50')
                                                                                    ? 'z-40'
                                                                                    : 'z-10';
                                                                            const bgOpacity =
                                                                                busy
                                                                                    ? (col +
                                                                                          getWeekDay()) %
                                                                                          7 ===
                                                                                          0 ||
                                                                                      (col +
                                                                                          getWeekDay()) %
                                                                                          7 ===
                                                                                          6
                                                                                        ? '.85'
                                                                                        : '1'
                                                                                    : '0';
                                                                            const bgColor =
                                                                                busy
                                                                                    ? `rgba(${
                                                                                          ganttColorPallette[
                                                                                              employeeIndex %
                                                                                                  ganttColorPallette.length
                                                                                          ]
                                                                                      }, ${bgOpacity})`
                                                                                    : `rgb(${theme['LAYOUT-COLORS']['TRANSPARENT-LAYOUT'].color} / 10%)`;
                                                                            const rightHandle =
                                                                                col ===
                                                                                currentTask?.from
                                                                                    ? `border-l-2 gantt-extreme-border`
                                                                                    : '';
                                                                            const leftHandle =
                                                                                col ===
                                                                                currentTask?.to
                                                                                    ? `border-r-2 gantt-extreme-border`
                                                                                    : '';

                                                                            return (
                                                                                <td
                                                                                    key={`${project.id}${team.id}${employee.employee.id}${col}`}
                                                                                    style={{
                                                                                        height: `${cellHeight}px`,
                                                                                        position:
                                                                                            'relative',
                                                                                    }}
                                                                                    onMouseUp={(
                                                                                        e,
                                                                                    ) => {
                                                                                        if (
                                                                                            (canAll &&
                                                                                                viewOnly) ||
                                                                                            !dragInfo
                                                                                        )
                                                                                            return;

                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();

                                                                                        if (
                                                                                            dragInfo.create
                                                                                        ) {
                                                                                            setNewTaskOpened(
                                                                                                true,
                                                                                            );
                                                                                            setNewTaskInfo(
                                                                                                dragInfo,
                                                                                            );
                                                                                        } else {
                                                                                            const newProjects =
                                                                                                [
                                                                                                    ...projects,
                                                                                                ];
                                                                                            const newTasks =
                                                                                                [
                                                                                                    ...projects[
                                                                                                        dragInfo
                                                                                                            .projectIndex
                                                                                                    ]
                                                                                                        .teams[
                                                                                                        dragInfo
                                                                                                            .teamIndex
                                                                                                    ]
                                                                                                        .employees[
                                                                                                        dragInfo
                                                                                                            .employeeIndex
                                                                                                    ]
                                                                                                        .employee
                                                                                                        .tasks,
                                                                                                ];
                                                                                            const taskIndex =
                                                                                                newTasks.findIndex(
                                                                                                    (
                                                                                                        task,
                                                                                                    ) =>
                                                                                                        task.id ===
                                                                                                        dragInfo
                                                                                                            .task
                                                                                                            .id,
                                                                                                );
                                                                                            /**
                                                                                             * Calculate the new date of the start/end of the task
                                                                                             */

                                                                                            const newTime =
                                                                                                new Date(
                                                                                                    zeroMoment.getTime() +
                                                                                                        dragInfo.toCol *
                                                                                                            DAY,
                                                                                                );

                                                                                            const taskObj =
                                                                                                {
                                                                                                    ...newTasks[
                                                                                                        taskIndex
                                                                                                    ],
                                                                                                };
                                                                                            if (
                                                                                                dragInfo.dir ===
                                                                                                'left'
                                                                                            )
                                                                                                taskObj.startDate =
                                                                                                    newTime;
                                                                                            else
                                                                                                taskObj.endDate =
                                                                                                    newTime;

                                                                                            updateTask(
                                                                                                taskObj,
                                                                                                dragInfo.teamId,
                                                                                                () => {
                                                                                                    newTasks[
                                                                                                        taskIndex
                                                                                                    ] =
                                                                                                        taskObj;
                                                                                                    newProjects[
                                                                                                        dragInfo.projectIndex
                                                                                                    ].teams[
                                                                                                        dragInfo.teamIndex
                                                                                                    ].employees[
                                                                                                        dragInfo.employeeIndex
                                                                                                    ].employee.tasks =
                                                                                                        newTasks;
                                                                                                    setProjects(
                                                                                                        newProjects,
                                                                                                    );
                                                                                                },
                                                                                            );
                                                                                        }

                                                                                        setDragInfo(
                                                                                            null,
                                                                                        );
                                                                                    }}
                                                                                    onMouseEnter={(
                                                                                        e,
                                                                                    ) => {
                                                                                        if (
                                                                                            (canAll &&
                                                                                                viewOnly) ||
                                                                                            !dragInfo
                                                                                        )
                                                                                            return;

                                                                                        e.preventDefault();

                                                                                        if (
                                                                                            dragInfo.create
                                                                                        ) {
                                                                                            if (
                                                                                                !busy
                                                                                            ) {
                                                                                                setDragInfo(
                                                                                                    {
                                                                                                        ...dragInfo,
                                                                                                        toCol: col,
                                                                                                    },
                                                                                                );
                                                                                            }
                                                                                        } else {
                                                                                            const {
                                                                                                task,
                                                                                                dir,
                                                                                            } =
                                                                                                dragInfo;

                                                                                            /**
                                                                                             * Stop dragging over another task
                                                                                             * [IMPORTANT] currentTask?.id does not work, we have to specifically check if we have a current task, becouse it may not be the case and still want to go further the code
                                                                                             */
                                                                                            if (
                                                                                                currentTask &&
                                                                                                currentTask.id !==
                                                                                                    task.id
                                                                                            )
                                                                                                return;

                                                                                            /**
                                                                                             * Stop dragging over the other side
                                                                                             */
                                                                                            if (
                                                                                                dir ===
                                                                                                    'left' &&
                                                                                                col >
                                                                                                    task.to
                                                                                            )
                                                                                                return;

                                                                                            if (
                                                                                                dir ===
                                                                                                    'right' &&
                                                                                                col <
                                                                                                    task.from
                                                                                            )
                                                                                                return;

                                                                                            setDragInfo(
                                                                                                {
                                                                                                    ...dragInfo,
                                                                                                    toCol: col,
                                                                                                },
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className={`absolute top-1/2 w-full -translate-y-1/2 transform transition-colors ${zIndex} ${bgOpacity} ${rightHandle} ${leftHandle}`}
                                                                                        style={{
                                                                                            height:
                                                                                                cellHeight -
                                                                                                2,
                                                                                            userSelect:
                                                                                                'none',
                                                                                            backgroundColor:
                                                                                                bgColor,
                                                                                        }}
                                                                                        onClick={(
                                                                                            e,
                                                                                        ) => {
                                                                                            if (
                                                                                                !busy ||
                                                                                                !canAll ||
                                                                                                viewOnly
                                                                                            )
                                                                                                return;
                                                                                            e.preventDefault();
                                                                                            e.stopPropagation();
                                                                                            setEmployeeModal(
                                                                                                employee,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {currentTask && (
                                                                                            <>
                                                                                                {/* Drag Left Handle */}
                                                                                                {canAll &&
                                                                                                    !viewOnly &&
                                                                                                    col ===
                                                                                                        currentTask.from && (
                                                                                                        <div
                                                                                                            className="absolute left-0 top-0 h-full w-5 bg-transparent"
                                                                                                            style={{
                                                                                                                userSelect:
                                                                                                                    'none',
                                                                                                                cursor: 'grab',
                                                                                                            }}
                                                                                                            onMouseDown={(
                                                                                                                e,
                                                                                                            ) => {
                                                                                                                e.preventDefault();
                                                                                                                setDragInfo(
                                                                                                                    {
                                                                                                                        create: false,
                                                                                                                        task: currentTask,
                                                                                                                        projectIndex,
                                                                                                                        projectId:
                                                                                                                            project.id,
                                                                                                                        teamIndex,
                                                                                                                        teamId: team.id,
                                                                                                                        employeeIndex,
                                                                                                                        employeeId:
                                                                                                                            employee
                                                                                                                                .employee
                                                                                                                                .id,
                                                                                                                        dir: 'left',
                                                                                                                        fromCol:
                                                                                                                            col,
                                                                                                                        toCol: col,
                                                                                                                    },
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    )}

                                                                                                {canAll &&
                                                                                                    !viewOnly &&
                                                                                                    col ===
                                                                                                        currentTask?.to && (
                                                                                                        <div
                                                                                                            className="absolute right-0 top-0 h-full w-5 bg-transparent"
                                                                                                            style={{
                                                                                                                userSelect:
                                                                                                                    'none',
                                                                                                                cursor: 'grab',
                                                                                                            }}
                                                                                                            onMouseDown={(
                                                                                                                e,
                                                                                                            ) => {
                                                                                                                e.preventDefault();
                                                                                                                setDragInfo(
                                                                                                                    {
                                                                                                                        create: false,
                                                                                                                        task: currentTask,
                                                                                                                        projectIndex,
                                                                                                                        projectId:
                                                                                                                            project.id,
                                                                                                                        teamIndex,
                                                                                                                        teamId: team.id,
                                                                                                                        employeeIndex,
                                                                                                                        employeeId:
                                                                                                                            employee
                                                                                                                                .employee
                                                                                                                                .id,
                                                                                                                        dir: 'right',
                                                                                                                        fromCol:
                                                                                                                            col,
                                                                                                                        toCol: col,
                                                                                                                    },
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                            </>
                                                                                        )}

                                                                                        {/* New Task Handle */}
                                                                                        {canAll &&
                                                                                            !viewOnly &&
                                                                                            !busy && (
                                                                                                <div
                                                                                                    className={`absolute left-0 top-0 h-full w-full ${
                                                                                                        (dragInfo?.create ||
                                                                                                            (newTaskInfo &&
                                                                                                                newTaskOpened)) &&
                                                                                                        (dragInfo?.employeeId ===
                                                                                                            employee
                                                                                                                .employee
                                                                                                                .id ||
                                                                                                            newTaskInfo?.employeeId ===
                                                                                                                employee
                                                                                                                    .employee
                                                                                                                    .id) &&
                                                                                                        (dragInfo?.teamIndex ===
                                                                                                            teamIndex ||
                                                                                                            newTaskInfo?.teamIndex ===
                                                                                                                teamIndex) &&
                                                                                                        (dragInfo?.projectIndex ===
                                                                                                            projectIndex ||
                                                                                                            newTaskInfo?.projectIndex ===
                                                                                                                projectIndex) &&
                                                                                                        (col >=
                                                                                                            Math.min(
                                                                                                                dragInfo?.fromCol,
                                                                                                                dragInfo?.toCol,
                                                                                                            ) ||
                                                                                                            col >=
                                                                                                                Math.min(
                                                                                                                    newTaskInfo?.fromCol,
                                                                                                                    newTaskInfo?.toCol,
                                                                                                                )) &&
                                                                                                        (col <=
                                                                                                            Math.max(
                                                                                                                dragInfo?.fromCol,
                                                                                                                dragInfo?.toCol,
                                                                                                            ) ||
                                                                                                            col <=
                                                                                                                Math.max(
                                                                                                                    newTaskInfo?.fromCol,
                                                                                                                    newTaskInfo?.toCol,
                                                                                                                )) &&
                                                                                                        'bg-layout-transparent-dark'
                                                                                                    }`}
                                                                                                    onMouseDown={(
                                                                                                        e,
                                                                                                    ) => {
                                                                                                        e.preventDefault();
                                                                                                        if (
                                                                                                            newTaskOpened
                                                                                                        )
                                                                                                            return;
                                                                                                        setNewTaskInfo(
                                                                                                            null,
                                                                                                        );
                                                                                                        setDragInfo(
                                                                                                            {
                                                                                                                create: true,
                                                                                                                projectIndex,
                                                                                                                projectId:
                                                                                                                    project.id,
                                                                                                                teamIndex,
                                                                                                                teamId: team.id,
                                                                                                                employeeIndex,
                                                                                                                employeeId:
                                                                                                                    employee
                                                                                                                        .employee
                                                                                                                        .id,
                                                                                                                fromCol:
                                                                                                                    col,
                                                                                                                toCol: col,
                                                                                                            },
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            )}

                                                                                        {/* Date at the extremes */}
                                                                                        {DATE && (
                                                                                            <div
                                                                                                className={`pointer-events-none absolute top-0 z-50 flex items-center whitespace-nowrap px-2 ${
                                                                                                    col ===
                                                                                                    currentTask.from
                                                                                                        ? 'left-0'
                                                                                                        : 'right-0'
                                                                                                }`}
                                                                                                style={{
                                                                                                    height:
                                                                                                        cellHeight +
                                                                                                        'px',
                                                                                                    userSelect:
                                                                                                        'none',
                                                                                                }}
                                                                                            >
                                                                                                <p
                                                                                                    className="pointer-events-none text-xs"
                                                                                                    style={{
                                                                                                        userSelect:
                                                                                                            'none',
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        DATE
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                            );
                                                                        },
                                                                    )}
                                                                </tr>
                                                            ),
                                                        )}

                                                        {/* Row for the add resource button */}
                                                        <SpaceRow height={33} />

                                                        {/* Row for in between one team and the next one */}
                                                        <SpaceRow />
                                                    </>
                                                ),
                                            )}
                                            {/*
                                            Teams End  
                                        */}
                                            <SpaceRow />
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* <NotResponsiveYet>
                {
                    <p className="text-white text-5xl">
                        {t('The Gantt can only be viewed on the desktop version of the platform')}.
                    </p>
                }
            </NotResponsiveYet> */}
        </>
    );
};

Gantt.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.object),
    setProjects: PropTypes.func,
    getProjects: PropTypes.func,
    tasksRequest: PropTypes.string,
    viewOnly: PropTypes.bool,
};

Gantt.defaultProps = {
    projects: [],
    setProjects: () => null,
    getProjects: () => null,
    tasksRequest: '',
    viewOnly: false,
};

export default Gantt;
