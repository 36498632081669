import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import {
    Button,
    FormControlLabel,
    LinearProgress,
    Radio,
    RadioGroup,
} from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header, Search, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';
import API from 'utils/axios';

import CreateStockModal from '../stocks/create-stock-modal';
import ManageStocks from '../stocks/manage-stocks';
import ArticleCategories from './article-categories';
import ManageArticles from './manage-articles';
import ManageArticlesWithStocks from './manage-articles-with-stocks';

const ManageNomenclatures = ({ setInitializeData }) => {
    const history = useHistory();

    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    const [query, setQuery] = useState('');
    const [purposeSortType, setPurposeSortType] = useState('ALL');
    const [sortType, setSortType] = useState('ALL');
    const [categorySortCrit, setCategorySortCrit] = useState('ALL');

    const articleType = ['SERVICES', 'FIZIC', 'DIGITAL'];
    const type = [t('Service'), t('Physical product'), t('Digital product')];
    const tabs = [
        t('Articles'),
        t('Articles with stocks'),
        t('Stocks'),
        t('Article categories'),
    ];

    const [isOpenStockModal, setIsOpenStockModal] = useState({
        articleId: null,
        open: false,
    });

    const handleOpenStockModal = (articleId) =>
        setIsOpenStockModal({ articleId, open: true });
    const handleCloseStockModal = () =>
        setIsOpenStockModal({ articleId: null, open: false });

    const { checkPerm } = useContext(UserContext);

    const canAllArticles = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    const canViewArticles = checkPerm([
        {
            permissionId: '18',
            permissionType: 'VIEW',
        },
    ]);

    const canAllStocks = checkPerm([
        {
            permissionId: '49',
            permissionType: 'ALL',
        },
    ]);

    const canViewStocks = checkPerm([
        {
            permissionId: '49',
            permissionType: 'VIEW',
        },
    ]);

    const { hiddenOptions, initialTab } = (() => {
        let hiddenOptions = [];
        let initialTab =
            new URL(document.location).searchParams.get('tab') ?? 0;

        if (!canViewArticles) {
            const options = [0, 1, 3];
            hiddenOptions = options;

            if (hiddenOptions.includes(initialTab)) {
                initialTab = 2;
            }
        }

        if (!canViewStocks) {
            hiddenOptions = [...hiddenOptions, 2];

            if (hiddenOptions.includes(initialTab)) {
                initialTab = 0;
            }
        }

        return { hiddenOptions, initialTab };
    })();

    const [activeTab, setActiveTab] = useState(initialTab);

    const getCategories = async () => {
        try {
            const response = await API.get('/categories');
            const rawCategories = response.data.getCategory;
            setCategories(rawCategories);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!canViewArticles && !canViewStocks) {
            history.goBack();
            return;
        }
        getCategories();
    }, []);

    return (
        <>
            {!setInitializeData && (
                <>
                    <Helmet>
                        <title>{t('Article nomenclature')}</title>
                    </Helmet>
                    <Header
                        pageTitle={tabs[activeTab]}
                        action={
                            <>
                                {(activeTab === 0 || activeTab === 1) && (
                                    <Search
                                        searchIconBig={true}
                                        value={query}
                                        setValue={setQuery}
                                    />
                                )}

                                {(activeTab === 0 || activeTab === 1) &&
                                    canAllArticles && (
                                        <Button
                                            color="secondary"
                                            startIcon={<AddIcon />}
                                            style={{
                                                borderRadius: '62.4375rem',
                                            }}
                                            onClick={() =>
                                                history.push(
                                                    quotes.base +
                                                        quotes.nomenclature
                                                            .base +
                                                        quotes.nomenclature
                                                            .create,
                                                )
                                            }
                                        >
                                            {t('Add new article')}
                                        </Button>
                                    )}

                                {activeTab === 2 && canAllStocks && (
                                    <Button
                                        color="secondary"
                                        startIcon={<AddIcon />}
                                        style={{
                                            borderRadius: '62.4375rem',
                                        }}
                                        onClick={() =>
                                            handleOpenStockModal(null)
                                        }
                                    >
                                        {t('Create stock')}
                                    </Button>
                                )}

                                {activeTab === 3 && canAllArticles && (
                                    <Button
                                        color="secondary"
                                        startIcon={<AddIcon />}
                                        style={{
                                            borderRadius: '62.4375rem',
                                        }}
                                        onClick={() =>
                                            history.push(
                                                quotes.base +
                                                    quotes.nomenclature.base +
                                                    quotes.categories.base +
                                                    quotes.categories.create,
                                            )
                                        }
                                    >
                                        {t('Add new article category')}
                                    </Button>
                                )}
                            </>
                        }
                        toolbar={
                            <Tabs
                                tabs={tabs}
                                activeTab={activeTab}
                                setActiveTab={(i) => {
                                    setIsOpenStockModal({
                                        articleId: null,
                                        open: false,
                                    });
                                    setActiveTab(i);
                                }}
                                hiddenOptions={hiddenOptions}
                            />
                        }
                        toolbarSecondary={
                            activeTab === 0 || activeTab === 1 ? (
                                <div className="flex items-center gap-10 text-main-text">
                                    <RadioGroup
                                        aria-label="status"
                                        name="status-filter"
                                        value={purposeSortType}
                                        onChange={(e) =>
                                            setPurposeSortType(e.target.value)
                                        }
                                    >
                                        <div className="no-user-select-recursive flex items-center text-main-text">
                                            <div className="flex flex-wrap">
                                                <FormControlLabel
                                                    key={'ALL'}
                                                    value={'ALL'}
                                                    control={<Radio />}
                                                    label={t('All')}
                                                />
                                                <FormControlLabel
                                                    key="CRM"
                                                    value="CRM"
                                                    control={<Radio />}
                                                    label={t('CRM Articles')}
                                                />
                                                <FormControlLabel
                                                    key="PM"
                                                    value="PM"
                                                    control={<Radio />}
                                                    label={t('PM Articles')}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>

                                    <div className="h-10 w-1 bg-layout-transparent" />

                                    <RadioGroup
                                        aria-label="status"
                                        name="status-filter"
                                        value={categorySortCrit}
                                        onChange={(e) =>
                                            setCategorySortCrit(e.target.value)
                                        }
                                    >
                                        <div className="no-user-select-recursive -mr-3 flex items-center text-main-text">
                                            {loading ? (
                                                <LinearProgress className="w-48 sm:w-24" />
                                            ) : (
                                                <div className="flex flex-wrap">
                                                    <FormControlLabel
                                                        key={'ALL'}
                                                        value={'ALL'}
                                                        control={<Radio />}
                                                        label={t('All')}
                                                    />
                                                    {categories.map((c) => (
                                                        <FormControlLabel
                                                            key={c.id}
                                                            value={c.id}
                                                            control={<Radio />}
                                                            label={c.name}
                                                        />
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </RadioGroup>

                                    <div className="h-10 w-1 bg-layout-transparent" />

                                    <RadioGroup
                                        aria-label="status"
                                        name="status-filter"
                                        value={sortType}
                                        onChange={(e) =>
                                            setSortType(e.target.value)
                                        }
                                    >
                                        <div className="no-user-select-recursive flex items-center text-main-text">
                                            <div className="flex flex-wrap">
                                                <FormControlLabel
                                                    key={'ALL'}
                                                    value={'ALL'}
                                                    control={<Radio />}
                                                    label={t('All')}
                                                />
                                                {type.map((t, index) => (
                                                    <FormControlLabel
                                                        key={t}
                                                        value={
                                                            articleType[index]
                                                        }
                                                        control={<Radio />}
                                                        label={t}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </div>
                            ) : null
                        }
                    ></Header>
                </>
            )}

            <div className={`${!setInitializeData ? 'page-container' : ''}`}>
                <div className="flex gap-16">
                    <div className="z-40 flex-grow">
                        {activeTab === 0 && (
                            <ManageArticles
                                query={query}
                                purposeSortType={purposeSortType}
                                sortType={sortType}
                                categorySortCrit={categorySortCrit}
                                onOpensStockModal={handleOpenStockModal}
                                setInitializeData={setInitializeData}
                            />
                        )}
                        {activeTab === 1 && (
                            <ManageArticlesWithStocks
                                query={query}
                                purposeSortType={purposeSortType}
                                sortType={sortType}
                                categorySortCrit={categorySortCrit}
                                onOpensStockModal={handleOpenStockModal}
                                setInitializeData={setInitializeData}
                            />
                        )}
                        {activeTab === 2 && <ManageStocks />}
                        {activeTab === 3 && <ArticleCategories />}
                    </div>
                    {isOpenStockModal.open && (
                        <>
                            <div className="sticky top-6 z-50 h-full rounded-lg bg-layout-transparent xl:fixed xl:left-2/4 xl:top-2/4 xl:h-auto xl:-translate-x-2/4 xl:-translate-y-2/4 xl:transform xl:bg-layout-light">
                                <CreateStockModal
                                    onClose={handleCloseStockModal}
                                    articleId={isOpenStockModal.articleId}
                                />
                            </div>
                            <div
                                className={`fixed left-0 top-0 z-40 hidden h-svh w-screen ${
                                    isOpenStockModal.open ? 'xl:flex' : ''
                                }`}
                                style={{
                                    backgroundColor: 'rgba(0,0,0,0.3)',
                                    backdropFilter: 'blur(5px)',
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

ManageNomenclatures.propTypes = {
    setInitializeData: PropTypes.func,
};

ManageNomenclatures.defaultValues = {
    setInitializeData: null,
};

export default ManageNomenclatures;
