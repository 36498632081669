import React, { Fragment, useEffect, useState } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';

import {
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';

import { useResources } from 'contexts/ResourcesContext';
import { useActiveNavLink } from 'hooks/useActiveNavLink';
import { Header, NavLinkTabs, Search } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';
import API from 'utils/axios';

import WarehouseAdd from './warehouse-add';
import WarehouseCategories from './warehouse-categories';
import WarehouseHuman from './warehouse-human';
import WarehouseMaterial from './warehouse-material';

const WarehousePage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);

    const isEdit = useRouteMatch(resourceModule.warehouse.editFull);
    const onHuman = useRouteMatch(resourceModule.warehouse.humanResourcesFull);
    const onMaterial = useRouteMatch(
        resourceModule.warehouse.materialResourcesFull,
    );
    const warehouseId = onHuman?.params?.id || onMaterial?.params?.id;

    const {
        query,
        filterParameters,
        setQuery,
        onUpdateFilterParameters,
        title,
        setTitle,
    } = useResources();

    const warehouseTabs = [
        {
            children: t('Locations categories'),
            route: resourceModule.warehouse.categories,
        },
        {
            children: !isEdit ? t('Add warehouse') : t('Edit warehouse'),
            route: !isEdit
                ? resourceModule.warehouse.add
                : resourceModule.warehouse.edit + '/' + isEdit.params.id,
        },
    ];

    const warehouseResourcesTabs = [
        {
            children: t('Human resources'),
            route: resourceModule.warehouse.humanResources + '/' + warehouseId,
        },
        {
            children: t('Material resources'),
            route:
                resourceModule.warehouse.materialResources + '/' + warehouseId,
        },
    ];

    const warehouseActiveTab = useActiveNavLink(
        warehouseTabs.map((tab) => tab.route),
    );
    const warehouseResourcesActiveTab = useActiveNavLink(
        warehouseResourcesTabs.map((tab) => tab.route),
    );

    useEffect(() => {
        if (onHuman || onMaterial) return;

        if (warehouseActiveTab === 0) setTitle(t('Locations categories'));
        if (warehouseActiveTab === 1)
            setTitle(!isEdit ? t('Add warehouse') : t('Edit warehouse'));
    }, [location.pathname]);

    useEffect(() => {
        if (!warehouseId) return;

        (async () => {
            try {
                setIsLoading(true);
                setTitle(`${t('Warehouse resources')} -`);

                const res = await API.get(`warehouse/${warehouseId}`);
                const warehouseData = res.data.getWarehouseById;

                setTitle(`${t('Warehouse resources')} - ${warehouseData.name}`);
            } catch (error) {
                console.error(error);

                setTitle(`${t('Warehouse resources')} - Error`);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [warehouseId]);

    return (
        <Fragment>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Header
                pageTitle={
                    <Fragment>
                        {title}
                        {isLoading && (
                            <span className="ml-4">
                                <CircularProgress size={23} />
                            </span>
                        )}
                    </Fragment>
                }
                action={
                    warehouseResourcesActiveTab >= 0 && (
                        <span
                            className="mr-4 inline-flex cursor-pointer items-center justify-center hover:text-primary-light"
                            onClick={() =>
                                history.push(resourceModule.warehouse.base)
                            }
                        >
                            &larr;
                        </span>
                    )
                }
                toolbar={
                    <Fragment>
                        {warehouseActiveTab >= 0 && (
                            <NavLinkTabs tabs={warehouseTabs} />
                        )}
                        {warehouseResourcesActiveTab >= 0 && (
                            <NavLinkTabs tabs={warehouseResourcesTabs} />
                        )}
                    </Fragment>
                }
                toolbarSecondary={
                    warehouseActiveTab === 0 ||
                    warehouseResourcesActiveTab >= 0 ? (
                        <div className="flex items-center justify-between gap-10 sm:flex-col-reverse sm:items-start sm:gap-4">
                            <Fragment>
                                {warehouseActiveTab === 0 && (
                                    <RadioGroup
                                        aria-label="status"
                                        name="type"
                                        value={filterParameters.type}
                                        onChange={onUpdateFilterParameters}
                                    >
                                        <div className="no-user-select-recursive -mr-3 flex items-center text-main-text">
                                            <div className="flex flex-wrap">
                                                <FormControlLabel
                                                    key={'All'}
                                                    value={'All'}
                                                    control={<Radio />}
                                                    label={t('All')}
                                                />
                                                <FormControlLabel
                                                    key={'Office'}
                                                    value={'OFFICE'}
                                                    control={<Radio />}
                                                    label={t('Office')}
                                                />

                                                <FormControlLabel
                                                    key={'Warehouse'}
                                                    value={'WAREHOUSE'}
                                                    control={<Radio />}
                                                    label={t('Warehouse')}
                                                />

                                                <FormControlLabel
                                                    key={'Site'}
                                                    value={'SITE'}
                                                    control={<Radio />}
                                                    label={t('Site')}
                                                />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                )}

                                {warehouseResourcesActiveTab === 0 && (
                                    <span>TODO</span>
                                )}

                                {warehouseResourcesActiveTab === 1 && (
                                    <RadioGroup
                                        aria-label="status"
                                        name="type"
                                        value={filterParameters.type}
                                        onChange={onUpdateFilterParameters}
                                    >
                                        <div className="no-user-select-recursive -mr-3 flex items-center text-main-text">
                                            <div className="flex flex-wrap">
                                                <FormControlLabel
                                                    key={'All'}
                                                    value={'All'}
                                                    control={<Radio />}
                                                    label={t('All')}
                                                />
                                                <FormControlLabel
                                                    key={'Fixed means'}
                                                    value={'FIXED_ASSETS'}
                                                    control={<Radio />}
                                                    label={t('Fixed means')}
                                                />

                                                <FormControlLabel
                                                    key={'Inventory objects'}
                                                    value={'INVENTORY_OBJECTS'}
                                                    control={<Radio />}
                                                    label={t(
                                                        'Inventory objects',
                                                    )}
                                                />

                                                <FormControlLabel
                                                    key={'Consumables'}
                                                    value={'CONSUMABLES'}
                                                    control={<Radio />}
                                                    label={t('Consumables')}
                                                />

                                                <FormControlLabel
                                                    key={'Intangible assets'}
                                                    value={'INTANGIBLE_ASSETS'}
                                                    control={<Radio />}
                                                    label={t(
                                                        'Intangible assets',
                                                    )}
                                                />

                                                {/* <FormControlLabel
                                            key={'External resources'}
                                            value={'EXTERNAL'}
                                            control={<Radio  />}
                                            label={t('External resources')}
                                        /> */}
                                            </div>
                                        </div>
                                    </RadioGroup>
                                )}
                            </Fragment>
                            <Search
                                searchIconBig={true}
                                value={query}
                                setValue={setQuery}
                                withSearchIcon={false}
                            />
                        </div>
                    ) : null
                }
            />
            <Switch>
                <Route exact path={resourceModule.warehouse.base}>
                    <Redirect to={resourceModule.warehouse.categories} />
                </Route>
                <Route path={resourceModule.warehouse.add}>
                    <WarehouseAdd />
                </Route>
                <Route path={resourceModule.warehouse.editFull}>
                    <WarehouseAdd />
                </Route>
                <Route path={resourceModule.warehouse.categories}>
                    <WarehouseCategories />
                </Route>
                <Route path={resourceModule.warehouse.humanResourcesFull}>
                    <WarehouseHuman />
                </Route>
                <Route path={resourceModule.warehouse.materialResourcesFull}>
                    <WarehouseMaterial />
                </Route>
            </Switch>
        </Fragment>
    );
};

export default WarehousePage;
