import M2MLogo from '../assets/common/m2m-logo.png';
import RaisisLogo from '../assets/common/raisis-logo.png';

import { t } from 'i18next';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { puppeteer } from 'routes';
import { capitalizeFirstLetter, getRawImageFromPath } from 'utils';
import API from 'utils/axios';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// ! This are magic numbers and I determined some of them through just testing
// ? The max width in points of a PDF page in portrait mode
const fitWidth = { landscape: 800, portrait: 550 };

// ? The max height in points of a PDF page in portrait mode
const fitHeight = { landscape: 425, portrait: 675 };

// ? The value needed in order to convert pixels to points
const CONVERSION_VALUE = 1.33;

export const getImagesToCanvas = async (query, lang) => {
    const response = await API.get('puppeteer', {
        params: {
            url: `${location.protocol}/${location.host}${puppeteer.base}?components=${JSON.stringify(query)}`,
            lang,
        },
    });

    const urls = response.data.filesUrls;
    const images = await Promise.all(
        urls.map(async (url) => {
            const { fileUrl } = url;

            const response = await fetch(fileUrl);
            const blob = await response.blob();

            return new Promise((resolve, reject) => {
                const blobUrl = URL.createObjectURL(blob);
                const img = new Image();

                img.src = blobUrl;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    URL.revokeObjectURL(blobUrl);
                    resolve({ ...url, canvas });
                };
                img.onerror = (error) => reject(error);
            });
        }),
    );

    return images;
};

/**
 * The function `getImageRealSizes` calculates the real width and height of an image based on the given
 * canvas width and height, taking into account whether the image is overflowing or not.
 * @param {Number} canvasWidth - The width of the canvas in pixels.
 * @param {Number} canvasHeight - The height of the canvas on which the image is being displayed.
 * @returns {object} an object with properties `realFitWidth` and `realFitHeight`.
 */
const getImageRealSizes = (
    canvasWidth,
    canvasHeight,
    pageOrientation = 'landscape',
) => {
    const FIT_WIDTH = fitWidth[pageOrientation];
    const FIT_HEIGHT = fitHeight[pageOrientation];

    // ? We convert the pixels to points
    const imageWidthPT = canvasWidth / CONVERSION_VALUE;
    const imageHeightPT = canvasHeight / CONVERSION_VALUE;

    // ? We check if the image is overflowing the PDF page container
    const isImageOverflowing =
        imageWidthPT > FIT_WIDTH || imageHeightPT > FIT_HEIGHT;

    // ? If the content is overflowing we set the max width and max height of the image to the PDF container sizes, otherwise we use the image sizes
    const realFitWidth = isImageOverflowing ? FIT_WIDTH : imageWidthPT;
    const realFitHeight = isImageOverflowing ? FIT_HEIGHT : imageHeightPT;

    return { realFitWidth, realFitHeight };
};

/**
 * The function handles the export of an element by extracting its dimensions, calculating the real fit
 * sizes, and returning an object with the fit sizes, margin, page break, and image data URL.
 * @param {Element} extractedElement - The `extractedElement` parameter is an object that represents an element
 * extracted from a canvas. It likely has properties such as `width` and `height`, which represent the
 * dimensions of the element.
 * @returns {object} The data witch will be used to create a new PDF page
 */
export const handleElementExport = (
    extractedElement,
    pageOrientation = 'landscape',
) => {
    const canvasWidth = extractedElement.width;
    const canvasHeight = extractedElement.height;

    // ? We have to get the width and height of the image witch can fit the PDF page container
    const { realFitWidth, realFitHeight } = getImageRealSizes(
        canvasWidth,
        canvasHeight,
        pageOrientation,
    );

    return {
        fit: [realFitWidth, realFitHeight],
        pageBreak: 'after',
        image: extractedElement.toDataURL('image/png'),
    };
};

/**
 * The function `handleMultiPageExport` takes an extracted element, calculates the necessary dimensions
 * for cropping, and returns an array of data objects representing each cropped image.
 * @param {Element} extractedElement - The extractedElement parameter is the HTML element that you want to export
 * as multiple pages. It could be an image, a canvas, or any other HTML element that can be drawn on a
 * canvas.
 * @returns an array of objects. Each object in the array represents a page of the exported document
 * and contains the following properties:
 */
export const handleMultiPageExport = (
    extractedElement,
    disabledCropHeightsIntervals = [],
    pageOrientation = 'landscape',
) => {
    const FIT_WIDTH = fitWidth[pageOrientation];
    const FIT_HEIGHT = fitHeight[pageOrientation];

    const dataImages = [];

    const canvasWidth = extractedElement.width;
    const canvasHeight = extractedElement.height;

    const pdfWidth = FIT_WIDTH * CONVERSION_VALUE;
    const pdfHeight = FIT_HEIGHT * CONVERSION_VALUE;
    const scaleFactor = Math.max(
        Math.max(pdfWidth, canvasWidth) / Math.min(pdfWidth, canvasWidth),
        1,
    );
    const cropHeight = pdfHeight * scaleFactor;

    // ? We increase i with the cropHeight every time in order to find from where we need to cut the next image
    let j = 0,
        k = 0;

    for (let i = 0; i < canvasHeight; i += cropHeight) {
        let topIntersectedInterval = null;
        let bottomIntersectedInterval = null;
        let sourceY = i;
        let sourceAndDestinationHeight = cropHeight;

        if (disabledCropHeightsIntervals.length > 0)
            for (; j < disabledCropHeightsIntervals.length; j++) {
                const interval = disabledCropHeightsIntervals[j];

                if (i > interval[0] && i < interval[1]) {
                    topIntersectedInterval = interval;
                    break;
                }

                if (i <= interval[1]) break;
            }

        if (
            topIntersectedInterval &&
            !(
                topIntersectedInterval[1] - topIntersectedInterval[0] >
                cropHeight
            )
        ) {
            i = sourceY = topIntersectedInterval[0];
        }

        if (disabledCropHeightsIntervals.length > 0) {
            const bottomHeight = i + cropHeight;

            for (; k < disabledCropHeightsIntervals.length; k++) {
                const interval = disabledCropHeightsIntervals[k];

                if (bottomHeight > interval[0] && bottomHeight < interval[1]) {
                    bottomIntersectedInterval = interval;
                    break;
                }

                if (bottomHeight <= interval[1]) break;
            }
        }

        if (
            bottomIntersectedInterval &&
            !(
                bottomIntersectedInterval[1] - bottomIntersectedInterval[0] >
                cropHeight
            )
        ) {
            sourceAndDestinationHeight = bottomIntersectedInterval[0] - i;
        }

        const imageCanvas = document.createElement('canvas');

        imageCanvas.width = canvasWidth;
        imageCanvas.height = sourceAndDestinationHeight;

        const imageCtx = imageCanvas.getContext('2d');

        // ? We cut and draw the new image to a canvas using the i as the start of the cutting point
        imageCtx.drawImage(
            extractedElement,
            0,
            sourceY,
            canvasWidth,
            sourceAndDestinationHeight,
            0,
            0,
            canvasWidth,
            sourceAndDestinationHeight,
        );

        // ? We have to get the width and height of the image witch can fit the PDF page container
        const { realFitWidth, realFitHeight } = getImageRealSizes(
            canvasWidth,
            sourceAndDestinationHeight,
            pageOrientation,
        );

        dataImages.push({
            fit: [realFitWidth, realFitHeight],
            pageBreak: 'after',
            image: imageCanvas.toDataURL('image/png'),
        });
    }

    return dataImages;
};

const headerInfoMargin = {
    landscape: 40,
    portrait: 10,
};

const headerInfoFont = {
    landscape: 8,
    portrait: 7,
};

/**
 * The `generatePdf` function generates a PDF document object using the provided data and images.
 * @param {Array} content - An array of images or other content to be included in the PDF document.
 * @param {String} alias - The `alias` parameter is an optional string that represents an alias or title
 * for the PDF document. It will be displayed in the header section of the PDF document. If no alias is
 * provided, the header section will not display any title.
 * @param {object} headerDetails - The `headerDetails` parameter is an object that contains the details for
 * the header section of the PDF.
 * @param {object} colorsDetails - The `colorsDetails` parameter is an object that contains the color details
 * for the PDF document.
 * @returns {object} The function `generatePdf` is returning a `pdfGenerator` object.
 */
export const generatePdf = async (
    content,
    alias = null,
    headerDetails = {
        name: 'M2M Design&Property Solution S.R.L.',
        identifiers: `J7 / 149 / 2017 * RO37279002`,
        site: 'm2msolutions.ro',
        address: 'Calea Națională 42C, Botoșani.',
        phone: '0744 691 659',
        email: 'corneliu.scutariu@m2msolutions.ro',
    },
    colorsDetails = {
        mainText: '#000000',
        linkText: '#2D7AFF',
        footerText: '#FFFFFF',
        footerBackground: '#2C7EE5',
    },
    pageOrientation = 'landscape',
    companyLogo = null,
) => {
    try {
        const HEADER_INFO_MARGIN = headerInfoMargin[pageOrientation];
        const HEADER_INFO_FONT = headerInfoFont[pageOrientation];

        const imagesPdf = [companyLogo, M2MLogo, RaisisLogo];

        // ? We fetch all the images needed for the PDF layout (we need them as row files)
        const imagesPdfPromises = imagesPdf.map((imagePath) =>
            getRawImageFromPath(imagePath),
        );
        const [companyLogoBase64, m2mLogoBase64, raisisLogoBase64] =
            await Promise.all([...imagesPdfPromises]);

        // ? We define the layout for the PDF pages
        const docDefinition = {
            pageMargins: [20, 90, 20, 70],
            pageOrientation,
            pageSize: 'A4',
            header: {
                margin: [20, 10, 20, 0],
                stack: [
                    {
                        columns: [
                            [
                                {
                                    text: headerDetails.name,
                                    margin: [0, 0, 0, 5],
                                    fontSize: HEADER_INFO_FONT,
                                    bold: true,
                                    alignment: 'left',
                                    color: colorsDetails.mainText,
                                },
                                {
                                    text: headerDetails.registerNo,
                                    fontSize: HEADER_INFO_FONT,
                                    margin: [0, 0, 0, 0],
                                    bold: true,
                                    alignment: 'left',
                                    color: colorsDetails.mainText,
                                },
                            ],
                            [
                                {
                                    text: headerDetails.cui,
                                    margin: [HEADER_INFO_MARGIN, 0, 0, 5],
                                    fontSize: HEADER_INFO_FONT,
                                    bold: true,
                                    alignment: 'left',
                                    color: colorsDetails.mainText,
                                },
                                {
                                    text: headerDetails.address,
                                    margin: [HEADER_INFO_MARGIN, 0, 0, 0],
                                    fontSize: HEADER_INFO_FONT,
                                    bold: true,
                                    alignment: 'left',
                                    color: colorsDetails.mainText,
                                },
                            ],
                            [
                                {
                                    text: headerDetails.phone,
                                    margin: [HEADER_INFO_MARGIN, 0, 0, 5],
                                    fontSize: HEADER_INFO_FONT,
                                    bold: true,
                                    alignment: 'left',
                                    color: colorsDetails.mainText,
                                },
                                {
                                    text: headerDetails.email,
                                    margin: [HEADER_INFO_MARGIN, 0, 0, 0],
                                    fontSize: HEADER_INFO_FONT,
                                    bold: true,
                                    mailto: headerDetails.email,
                                    color: colorsDetails.linkText,
                                    decoration: 'underline',
                                    alignment: 'left',
                                },
                            ],
                        ],
                    },
                    alias && {
                        text: alias,
                        fontSize: 12,
                        bold: true,
                        alignment: 'center',
                        margin: [0, -27.5, 0, 0],
                        color: colorsDetails.mainText,
                    },
                ],
            },
            footer: function (pageCount) {
                return {
                    stack: [
                        {
                            layout: 'noBorders',
                            table: {
                                headerRows: 0,
                                widths: ['*', '*'],
                                body: [
                                    [
                                        {
                                            image: m2mLogoBase64,
                                            fit: [35, 35],
                                            alignment: 'left',
                                            link: 'https://www.m2msolutions.ro',
                                            fillColor:
                                                colorsDetails.footerBackground,
                                            margin: [10, 5, 0, 5],
                                        },
                                        {
                                            image: raisisLogoBase64,
                                            fit: [70, 70],
                                            alignment: 'right',
                                            link: 'https://raisissoftware.com',
                                            fillColor:
                                                colorsDetails.footerBackground,
                                            margin: [0, 11.5, 5, 5],
                                        },
                                    ],
                                ],
                            },
                        },
                        {
                            text: pageCount.toString(),
                            alignment: 'center',
                            style: 'normalText',
                            color: colorsDetails.footerText,
                            margin: [0, -30, 0, 0],
                            fontSize: 12,
                        },
                    ],
                    margin: [20, 10, 20, 0],
                };
            },
            content,
        };

        docDefinition.header.stack[0].columns.unshift([
            {
                image: companyLogoBase64,
                fit: [180, 70],
            },
        ]);

        const pdfGenerator = pdfMake.createPdf(docDefinition);

        return pdfGenerator;
    } catch (error) {
        throw new Error(error);
    }
};

export const formatExcelData = (headers, data, info = null) => {
    const columns = headers.flatMap((header) => {
        const { key, content, excelData } = header;
        const { style = {}, list = false } = excelData || {};

        if (list) {
            const length = Math.max(...data.map((item) => item[key].length));

            if (!length) {
                return {
                    key,
                    header: content,
                    style,
                    width: 15,
                };
            }

            return Array.from({ length }, (_, i) => ({
                key: `${key}_${i}`,
                header: `${content} ${i + 1}`,
                style,
                width: 30,
            }));
        }

        return {
            key,
            header: content,
            style,
            width: 15,
        };
    });

    const rows = data.map((item, index) => {
        const row = {};
        headers.forEach((header) => {
            const { key, excelData } = header;
            const { list = false } = excelData || {};
            if (list) {
                if (!item[key].length) {
                    row[key] = '';
                }

                for (let i = 0; i < item[key].length; i++) {
                    const subItem = item[key][i];
                    row[`${key}_${i}`] = Object.entries(subItem)
                        .map(
                            ([key, value]) =>
                                `${t(capitalizeFirstLetter(key))}: ${value}`,
                        )
                        .join(', ');
                }
            } else {
                row[key] = header.render(item[key], item, index);
            }
        });

        return row;
    });

    let lastRow = null;
    if (info) {
        const { description, elements } = info;

        lastRow = [
            description,
            elements.reduce((acc, cur, index) => {
                const { label, content } = cur;
                return (acc += `${label}: ${content}${index !== elements.length - 1 ? '\n' : ''}`);
            }, ``),
        ];
    }

    return { columns, rows, lastRow };
};

export const mergeExcelCellStyles = (cell, index, data) => {
    const customStyle = data[index].style || {};
    Object.keys(customStyle).forEach((key) => {
        cell[key] = { ...cell[key], ...customStyle[key] };
    });
};
