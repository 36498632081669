import React, { memo, useContext, useState } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';

import { Button, TextField } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import BasicTooltip from 'components/shared/basic-tooltip';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import EditElementModal from 'pages/configurator/documents_configurator/documents_items/edit-element-modal';
import UtilityExpenseBudget from 'pages/crm/contracts/creation-steps/utility-expense-budget';
import UtilityRevenueBudget from 'pages/crm/contracts/creation-steps/utility-revenue-budget';
import PropTypes from 'prop-types';
import { ActionButton, DatePicker, Dropdown, LabelWrapper, Tabs } from 'RaisisComponents';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { formatPositiveNumber, formatVATnumber } from 'utils';

import { ProjectContext } from './project-structure-form';

/**
 * ? This component renders one activity of the project structure
 * @param {object} props - Component properties
 * @param {number} props.activity - Props of the section
 * @param {number} props.index - Index of the section
 * @param {object} props.milestone - Object of the parent section
 * @returns
 */
const ProjectStructureActivity = ({ activity, index, milestone }) => {
    const { t } = useTranslation();
    const {
        onDeleteActivity,
        onUpdateActivity,
        isLoading,
        onBatchedUpdateActivity,
        projectStructureInfo,
        allExpenses,
        allRevenues,
        accounts,
        exchangeRate,
        canEdit,
        onUpdateActivityDate,
        onUpdateActivityDependency,
        onUpdateActivityRealizationDays,
    } = useContext(ProjectContext);

    const statuses = ['NEW', 'IN_PROGRESS', 'DONE'];

    const [localRealizationDays, setLocalRealizationDays] = useState(activity.realizationDays);
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [t('Expenses'), t('Revenues')];

    const [open, setOpen] = useState(false);
    const handleCloseModal = () => setOpen(false);
    const handleOpenModal = () => setOpen(true);

    const handleUpdateActivity = (e) => onUpdateActivity(milestone.id, activity.id, e.target.name, e.target.value);

    const handleUpdateActivityDescription = (value) => {
        onUpdateActivity(milestone.id, activity.id, 'description', value);
        handleCloseModal();
    };

    const handleDeleteActivity = () => onDeleteActivity(milestone.id, activity.id, activity.referenceKey);

    /**
     * ? We calculate new values for the expenses or revenues array based on the new percent
     * @param {array} elements - Array of expenses or revenues witch needs updating
     * @param {*} percent - New percentage value
     * @returns The modified array
     */
    const handleUpdateEstimatedValues = (elements, percent) => {
        return elements.map((el) => ({
            ...el,
            list: el.list.map((l) => ({
                ...l,
                value: (l.value * percent) / 100,
                secondCurrencyValue: (l.value * percent) / 100 / exchangeRate,
            })),
        }));
    };

    // TODO: MAYBE CHANGE THIS IN THE FUTURE
    /**
     * ? We call the function received for the UtilityExpenseBudget component with the previous state of that array
     * ? Here we also update the both expenses values (forecasted and estimated)
     * @param {object} prev - Current state of the expenses array
     * @param {Function} callback - The function that will be received from the UtilityExpenseBudget
     */
    function handleChangeForecastedCheckedExpenses(prev, callback) {
        const newForecastedExpenses = callback(prev);
        // const newEstimatedExpenses = handleUpdateEstimatedValues(newForecastedExpenses, activity.percent);
        const newEstimatedExpenses = handleUpdateEstimatedValues(newForecastedExpenses, 100);

        onBatchedUpdateActivity(milestone.id, activity.id, [
            { name: 'forecastedExpenses', value: newForecastedExpenses },
            { name: 'estimatedExpenses', value: newEstimatedExpenses },
        ]);
    }

    // TODO: MAYBE CHANGE THIS IN THE FUTURE
    /**
     * ? We call the function received for the UtilityRevenueBudget component with the previous state of that array
     * ? Here we also update the both revenues values (forecasted and estimated)
     * @param {object} prev - Current state of the revenues array
     * @param {Function} callback - The function that will be received from the UtilityRevenueBudget
     */
    function handleChangeForecastedCheckedRevenues(prev, callback) {
        const newForecastedRevenues = callback(prev);
        const newEstimatedRevenues = handleUpdateEstimatedValues(newForecastedRevenues, activity.percent);

        onBatchedUpdateActivity(milestone.id, activity.id, [
            { name: 'forecastedRevenues', value: newForecastedRevenues },
            { name: 'estimatedRevenues', value: newEstimatedRevenues },
        ]);
    }

    /**
     * ? We call the function received for the UtilityExpenseBudget component with the previous state of that array
     * @param {object} prev - Current state of the expenses array
     * @param {Function} callback - The function that will be received from the UtilityExpenseBudget
     */
    function handleChangeEstimatedCheckedExpenses(prev, callback) {
        const newEstimatedExpenses = callback(prev);

        handleUpdateActivity({
            target: {
                name: 'estimatedExpenses',
                value: newEstimatedExpenses,
            },
        });
    }

    /**
     * ? We call the function received for the UtilityRevenueBudget component with the previous state of that array
     * @param {object} prev - Current state of the revenues array
     * @param {Function} callback - The function that will be received from the UtilityRevenueBudget
     */
    function handleChangeEstimatedCheckedRevenues(prev, callback) {
        const newEstimatedRevenues = callback(prev);

        handleUpdateActivity({
            target: {
                name: 'estimatedRevenues',
                value: newEstimatedRevenues,
            },
        });
    }

    // TODO: MAYBE CHANGE THIS IN THE FUTURE
    /**
     * ? We update the activity percentage (revenues only) and the forecasted revenues based on it
     * @param {number} e - New percentage
     */
    const handleUpdateActivityPercent = (e) => {
        const value = formatVATnumber(e.target.value);

        // const newEstimatedExpenses = handleUpdateEstimatedValues(structuredClone(activity.forecastedExpenses), value);
        const newEstimatedRevenues = handleUpdateEstimatedValues(structuredClone(activity.forecastedRevenues), value);

        onBatchedUpdateActivity(milestone.id, activity.id, [
            { name: 'percent', value: value },
            // { name: 'estimatedExpenses', value: newEstimatedExpenses },
            { name: 'estimatedRevenues', value: newEstimatedRevenues },
        ]);
    };

    const handleConfirmUpdateActivityRealizationDays = () =>
        onUpdateActivityRealizationDays(localRealizationDays, milestone.id, index);

    const handleCancelUpdateActivityRealizationDays = () => setLocalRealizationDays(activity.realizationDays);

    return (
        <>
            <div
                style={{
                    zIndex: milestone.activities.length - index + 1,
                }}
            >
                <div className="flex flex-col gap-6 rounded-sm bg-layout-transparent p-2">
                    <div className="flex items-center justify-between gap-2">
                        <h3>
                            {t('Activity')} {index + 1}
                        </h3>

                        {canEdit && (
                            <div
                                className="transition:opacity flex h-11 w-11 flex-shrink-0 cursor-pointer items-center justify-center text-center text-main-text opacity-50 hover:opacity-100 sm:self-start"
                                onClick={handleDeleteActivity}
                            >
                                <CloseIcon className="text-error" style={{ fontSize: '2rem' }} />
                            </div>
                        )}
                    </div>

                    <div className="flex w-full gap-2 md:flex-col">
                        <LabelWrapper label={t('Activity name')}>
                            <TextField
                                disabled={!canEdit}
                                name="name"
                                placeholder={t('Activity name')}
                                value={activity.name}
                                onChange={handleUpdateActivity}
                            />
                        </LabelWrapper>

                        <div className="inline-flex items-center gap-2 sm:w-full sm:flex-col">
                            <LabelWrapper label={t('Start date')}>
                                <DatePicker
                                    disabled={!canEdit}
                                    date={activity.startDate}
                                    setDate={(value) => onUpdateActivityDate(value, milestone.id, index)}
                                />
                            </LabelWrapper>
                            <div className="flex w-full items-center gap-2 rounded-md bg-layout-transparent px-2">
                                <LabelWrapper label={t('Number of days of realization')}>
                                    <TextField
                                        disabled={!canEdit}
                                        name="realizationDays"
                                        type="number"
                                        placeholder={t('Number of days of realization')}
                                        value={localRealizationDays}
                                        onChange={(e) => setLocalRealizationDays(formatPositiveNumber(e.target.value))}
                                    />
                                </LabelWrapper>

                                <div className="mt-6 flex gap-2">
                                    <BasicTooltip tip={t('Confirm')}>
                                        <ActionButton
                                            disabled={activity.realizationDays === localRealizationDays}
                                            color="var(--success)"
                                            icon={<CheckIcon />}
                                            onClick={handleConfirmUpdateActivityRealizationDays}
                                        />
                                    </BasicTooltip>
                                    <BasicTooltip tip={t('Cancel')}>
                                        <ActionButton
                                            disabled={activity.realizationDays === localRealizationDays}
                                            color="var(--error)"
                                            icon={<CancelIcon />}
                                            onClick={handleCancelUpdateActivityRealizationDays}
                                        />
                                    </BasicTooltip>
                                </div>
                            </div>
                        </div>

                        <div className="inline-flex">
                            <LabelWrapper label={'Status'}>
                                <Dropdown
                                    disabled={!canEdit}
                                    icon={<EditIcon />}
                                    placeholder={'Status'}
                                    options={statuses.map((status) => t(status))}
                                    selectedOption={statuses.findIndex((status) => activity.status === status)}
                                    setSelectedOption={(e) =>
                                        handleUpdateActivity({
                                            target: {
                                                name: 'status',
                                                value: statuses[e],
                                            },
                                        })
                                    }
                                />
                            </LabelWrapper>
                        </div>

                        {index !== 0 && (
                            <div className="inline-flex items-center gap-2 rounded-md bg-layout-transparent px-2">
                                <LabelWrapper label={t('Depends on')}>
                                    <Dropdown
                                        disabled={!canEdit}
                                        icon={<SettingsEthernetIcon />}
                                        placeholder={t('Depends on')}
                                        options={milestone.activities.map(
                                            (a, index) => a.name || `${t('Activity')} ${index + 1}`,
                                        )}
                                        selectedOption={(() => {
                                            const selection = milestone.activities.findIndex(
                                                (a) => a.referenceKey === activity.dependency,
                                            );

                                            return selection > -1 ? selection : null;
                                        })()}
                                        setSelectedOption={(rIndex) =>
                                            onUpdateActivityDependency(milestone.id, index, rIndex)
                                        }
                                        hiddenOptions={Array.from(
                                            { length: milestone.activities.length - index },
                                            (_, i) => milestone.activities.length - (i + 1),
                                        )}
                                    />
                                </LabelWrapper>

                                <div className="mt-6">
                                    <ActionButton
                                        disabled={!canEdit}
                                        icon={<DeleteIcon />}
                                        color={activity.dependency !== null ? 'var(--error)' : 'var(--disabled)'}
                                        onClick={() =>
                                            handleUpdateActivity({
                                                target: {
                                                    name: 'dependency',
                                                    value: null,
                                                },
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col gap-2">
                        {activity.description && (
                            <LabelWrapper label={t('Description')}>
                                <iframe
                                    className="h-40 w-full rounded-md border border-layout-transparent-dark bg-white p-2"
                                    srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(activity.description)}`}
                                />
                            </LabelWrapper>
                        )}

                        <div
                            className={`inline-flex ${
                                activity.description
                                    ? 'self-end'
                                    : 'w-full justify-center rounded-md bg-layout-transparent-dark p-2'
                            }`}
                        >
                            <Button color="primary" onClick={handleOpenModal} disabled={!canEdit}>
                                {t(projectStructureInfo ? 'Edit description' : 'Add description')}
                            </Button>
                        </div>
                    </div>

                    <div className="grid grid-cols-psf-big gap-16 xl:grid-cols-psf-small xl:place-items-start xl:gap-2">
                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-4">
                                <h2 className="text-2xl">{t('Forecasts')}</h2>
                                <div className="inline-flex">
                                    <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                                </div>
                            </div>
                            {activeTab === 0 && (
                                <UtilityExpenseBudget
                                    checkedExpenses={activity.forecastedExpenses}
                                    setCheckedExpenses={handleChangeForecastedCheckedExpenses.bind(
                                        null,
                                        activity.forecastedExpenses,
                                    )}
                                    exchangeRate={exchangeRate}
                                    disableInputs={isLoading || !canEdit}
                                    isEdit={!!projectStructureInfo}
                                    allExpenses={allExpenses}
                                    accounts={accounts}
                                />
                            )}

                            {activeTab === 1 && (
                                <UtilityRevenueBudget
                                    checkedRevenues={activity.forecastedRevenues}
                                    setCheckedRevenues={handleChangeForecastedCheckedRevenues.bind(
                                        null,
                                        activity.forecastedRevenues,
                                    )}
                                    exchangeRate={exchangeRate}
                                    disableInputs={isLoading || !canEdit}
                                    isEdit={!!projectStructureInfo}
                                    allRevenues={allRevenues}
                                    accounts={accounts}
                                />
                            )}
                        </div>

                        <div className="inline-flex h-full xl:row-span-2">
                            <div className="sticky top-6" style={{ height: 'min-content' }}>
                                {/* TODO: MAYBE CHANGE THIS IN THE FUTURE */}
                                <LabelWrapper label={t('Forecasted/Estimated %')}>
                                    <LocaleTextField
                                        disabled={!canEdit || activeTab === 0}
                                        name="percent"
                                        placeholder={t('Forecasted/Estimated %')}
                                        value={activeTab === 0 ? 100 : activity.percent}
                                        onChange={(e) => handleUpdateActivityPercent(e)}
                                    />
                                </LabelWrapper>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-4">
                                <h2 className="text-2xl">{t('Estimates')}</h2>
                                <div className="inline-flex">
                                    <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                                </div>
                            </div>
                            <div className="mt-16 xl:mt-2">
                                {activeTab === 0 && (
                                    <UtilityExpenseBudget
                                        checkedExpenses={activity.estimatedExpenses}
                                        setCheckedExpenses={handleChangeEstimatedCheckedExpenses.bind(
                                            null,
                                            activity.estimatedExpenses,
                                        )}
                                        exchangeRate={exchangeRate}
                                        disableInputs={isLoading || !canEdit}
                                        isEstimateCase={true}
                                        isEdit={!!projectStructureInfo}
                                        allExpenses={allExpenses}
                                        accounts={accounts}
                                    />
                                )}

                                {activeTab === 1 && (
                                    <UtilityRevenueBudget
                                        checkedRevenues={activity.estimatedRevenues}
                                        setCheckedRevenues={handleChangeEstimatedCheckedRevenues.bind(
                                            null,
                                            activity.estimatedRevenues,
                                        )}
                                        exchangeRate={exchangeRate}
                                        disableInputs={isLoading || !canEdit}
                                        isEstimateCase={true}
                                        isEdit={!!projectStructureInfo}
                                        allRevenues={allRevenues}
                                        accounts={accounts}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {open && (
                <CustomModal open={open}>
                    <EditElementModal
                        onClose={handleCloseModal}
                        value={activity.description}
                        onFinish={handleUpdateActivityDescription}
                    />
                </CustomModal>
            )}
        </>
    );
};

ProjectStructureActivity.propTypes = {
    activity: PropTypes.object,
    milestone: PropTypes.object,
    index: PropTypes.number,
};

ProjectStructureActivity.defaultProps = {
    activity: {},
    milestone: {},
    index: 0,
};

export default memo(
    ProjectStructureActivity,
    (prev, next) =>
        prev.index === next.index &&
        _.isEqual(prev.activity, next.activity) &&
        _.isEqual(prev.milestone, next.milestone),
);
