import React from 'react';

import KpiCardFooter from 'pages/kpi/components/kpi-card-footer';
import KpiCardHeader from 'pages/kpi/components/kpi-card-header';
import KpiChart from 'pages/kpi/components/kpi-chart';
import KpiWrapper from 'pages/kpi/components/kpi-wrapper';
import PropTypes from 'prop-types';

const KpiPersonalCard = ({ kpi, period }) => {
    const { name } = kpi;
    const { selectedDivision } = period;

    return (
        <KpiWrapper>
            <div className="flex flex-col gap-6 px-6 pb-10 pt-5">
                <p className="text-2xl font-semibold leading-7">{name}</p>
                <KpiCardHeader kpi={kpi} period={period} />
            </div>
            <div className="h-44 px-6 pb-4">
                <KpiChart kpi={kpi} division={selectedDivision} padding={6} />
            </div>
            <KpiCardFooter kpi={kpi} type="PERSONAL" />
        </KpiWrapper>
    );
};

KpiPersonalCard.propTypes = {
    kpi: PropTypes.object,
    period: PropTypes.object,
};

KpiPersonalCard.defaultProps = {
    kpi: null,
    period: null,
};

export default KpiPersonalCard;
