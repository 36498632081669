import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import {
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';

import Wiki from 'components/modules/wiki/wiki';
import DisplayInfiniteScrollResults from 'components/shared/display-infinite-scroll-results';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header, Search, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';

import PMWikiModal from '../../../components/modules/wiki/pm-wiki-modal';

const PMWikiTimeline = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { projectId } = useParams();
    const { checkPerm } = useContext(UserContext);
    const { enqueueSnackbar } = useSnackbar();

    const [query, setQuery] = useState('');

    const [loading, setLoading] = useState(true);
    const [operating, setOperating] = useState(false);

    const [open, setOpen] = useState(false);
    const [fileType, setFileType] = useState('all');

    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [departments, setDepartments] = useState([]);

    const [wikis, setWikis] = useState([]);
    const [wikiForEdit, setWikiForEdit] = useState(null);

    const canView = checkPerm([
        {
            permissionId: '51',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '51',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }
    }, [canView]);

    const getWikis = async (controller) => {
        try {
            const response = await API.get('/wikis', {
                params: {
                    query,
                    projectId,
                    departmentId:
                        selectedDepartment === 0
                            ? null
                            : departments[selectedDepartment - 1].id,
                    fileType: fileType === 'all' ? null : fileType,
                },
                signal: controller?.signal ?? undefined,
            });

            setWikis(response.data.wikiArray);
        } catch (err) {
            throw new Error(err);
        }
    };

    const handleDeleteWiki = async (id) => {
        try {
            setOperating(true);

            await API.delete('/wikis', {
                params: {
                    id,
                },
            });

            await getWikis();
            enqueueSnackbar(t('The wiki was deleted successfully!'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
            console.error(err);
        } finally {
            setOperating(false);
        }
    };

    const handleDeleteFile = async (fileId, wikiId) => {
        try {
            setOperating(true);

            await API.delete('/wikiFileDeletion', {
                params: {
                    wikiId,
                    fileId,
                },
            });

            await getWikis();
            enqueueSnackbar(t('The file was deleted successfully!'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
            console.error(err);
        } finally {
            setOperating(false);
        }
    };

    const handleAddComment = async (wikiId, data) => {
        try {
            setOperating(true);

            const { comment, files } = data;

            const formData = new FormData();

            const reqBody = {
                wikiId,
                comment,
            };

            formData.append('data', JSON.stringify(reqBody));
            if (files.length > 0) {
                files.forEach((element) =>
                    formData.append('files', element.file),
                );
            }

            await API.post('/wikiComment', formData);
            await getWikis();

            enqueueSnackbar(t('Comment added!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            setOperating(false);
        }
    };

    const handleDeleteComment = async (id) => {
        try {
            setOperating(true);

            await API.delete('wikiComment', {
                data: {
                    id,
                },
            });

            await getWikis();

            enqueueSnackbar(t('Comment deleted!'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
            console.error(err);
        } finally {
            setOperating(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const [resDepartments] = await Promise.all([
                    API.get('pmDepartments', {
                        params: {
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                            type: 'PLANNING_OVERVIEW',
                        },
                    }),
                    getWikis(),
                ]);

                setDepartments(resDepartments.data.pmDepartments);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setOperating(true);
                await getWikis();
            } catch (error) {
                console.error(error);
                throw error;
            } finally {
                setOperating(false);
            }
        })();
    }, [selectedDepartment, fileType]);

    return (
        <>
            <Helmet>
                <title>{`Wiki - ${t('Documentation')}`}</title>
            </Helmet>

            <Header
                pageTitle={`Wiki - ${t('Documentation')}`}
                action={
                    <>
                        <Search
                            searchIconBig={true}
                            value={query}
                            setValue={setQuery}
                            withSearchIcon={false}
                        />
                        {canAll && (
                            <div
                                className={`transform transition-all duration-300 ${
                                    open
                                        ? 'translate-x-8 opacity-0'
                                        : 'translate-x-0 opacity-100'
                                }`}
                            >
                                <Button
                                    disabled={operating}
                                    startIcon={<AddIcon />}
                                    color="secondary"
                                    style={{ borderRadius: '999px' }}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    {t('Add new documentation')}
                                </Button>
                            </div>
                        )}
                    </>
                }
                toolbar={
                    <Tabs
                        disabled={operating}
                        tabs={[
                            t('All'),
                            ...(departments?.map((d) => d.name) ?? []),
                        ]}
                        activeTab={selectedDepartment}
                        setActiveTab={setSelectedDepartment}
                    />
                }
                toolbarSecondary={
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="status"
                            name="status-filter"
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                        >
                            <div className="no-user-select-recursive flex items-center text-main-text">
                                <FormControlLabel
                                    disabled={operating}
                                    value="all"
                                    control={<Radio />}
                                    label={t('All')}
                                />
                                <FormControlLabel
                                    disabled={operating}
                                    value="FILE"
                                    control={<Radio />}
                                    label={t('Files')}
                                />
                                <FormControlLabel
                                    disabled={operating}
                                    value="DOCUMENT"
                                    control={<Radio />}
                                    label={t('Documents')}
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>
                }
            />

            <div className="page-container">
                <div className="relative flex justify-center gap-16">
                    <DisplayInfiniteScrollResults
                        query={query}
                        setLoading={setLoading}
                        getFunction={getWikis}
                        refreshList={[selectedDepartment, fileType]}
                    >
                        <div className="flex w-full max-w-7xl flex-col items-center p-16 sm:p-0">
                            {loading ? (
                                <Loading
                                    style={{ height: '100%', margin: 'auto 0' }}
                                />
                            ) : wikis.length > 0 ? (
                                wikis.map((wiki) => (
                                    <Wiki
                                        key={wiki.id}
                                        wiki={wiki}
                                        disabled={operating}
                                        onDeleteWiki={handleDeleteWiki}
                                        onDeleteFile={handleDeleteFile}
                                        onAddComment={handleAddComment}
                                        onDeleteComment={handleDeleteComment}
                                        openEdit={() => {
                                            setOpen(true);
                                            setWikiForEdit(wiki);
                                        }}
                                    />
                                ))
                            ) : (
                                <NoDataPlaceholder />
                            )}
                        </div>
                    </DisplayInfiniteScrollResults>
                    <PMWikiModal
                        open={open}
                        closeModal={() => {
                            setOpen(false);
                            if (wikiForEdit) setWikiForEdit(null);
                        }}
                        getWikis={getWikis}
                        initialData={wikiForEdit}
                        setWikiForEdit={setWikiForEdit}
                        departments={departments}
                    />
                </div>
            </div>
        </>
    );
};

export default PMWikiTimeline;
