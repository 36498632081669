import React from 'react';

import PropTypes from 'prop-types';

const Indicator = ({ children, style }) => {
    return (
        <div
            className="relative -mt-12 rounded-lg px-3 py-2"
            style={{
                zIndex: 'calc(infinity)',
                backgroundColor: `rgb(var(--base-layout-light) / 90%)`,
                ...style,
            }}
        >
            {children}
            <div
                className="absolute left-1/2 -translate-x-1/2 transform"
                style={{
                    bottom: '-0.75rem',
                    borderLeft: '0.25rem solid transparent',
                    borderRight: '0.25rem solid transparent',
                    borderTop: `0.75rem solid ${style.backgroundColor ?? 'rgb(var(--base-layout-light) / 90%)'}`,
                }}
            />
        </div>
    );
};

Indicator.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    style: PropTypes.object,
};

Indicator.defaultProps = {
    children: null,
    style: {},
};

export default Indicator;
