import React from 'react';

import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

import KpiNomenclatureUsersRow from './kpi-nomenclature-users-row';

const KpiNomenclatureUsers = ({ kpi, onChangeKpi, disabled, search }) => {
    const { t } = useTranslation();
    const { users } = kpi;
    const filteredUsers = users.filter((user) => user.profile.name.toLowerCase().includes(search.toLowerCase()));

    return (
        <>
            {filteredUsers.length === 0 ? (
                <NoDataPlaceholder />
            ) : (
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Name')}</TableCell>
                                <TableCell>{t('Monitored from')}</TableCell>
                                <TableCell>{t('Last kpi adjustment')}</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableSeparator />
                            {filteredUsers.map((user) => (
                                <KpiNomenclatureUsersRow
                                    key={user.id}
                                    user={user}
                                    kpi={kpi}
                                    onChangeKpi={onChangeKpi}
                                    disabled={disabled}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

KpiNomenclatureUsers.propTypes = {
    kpi: PropTypes.object,
    onChangeKpi: PropTypes.func,
    disabled: PropTypes.bool,
    search: PropTypes.string,
};

KpiNomenclatureUsers.defaultProps = {
    kpi: null,
    onChangeKpi: () => {},
    disabled: false,
    search: '',
};

export default KpiNomenclatureUsers;
