import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import ContractTypesForm from 'components/accountancy/contract-types-form';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import {
    ExpandableRow,
    Header,
    TableSeparator,
} from 'RaisisComponents/index.js';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { accountancy } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const ContractTypeRow = (props) => {
    const { contractType, canAll } = props;
    const { enqueueSnackbar } = useSnackbar();

    const globalContext = useContext(GlobalContext);

    const history = useHistory();
    const { t } = useTranslation();

    /**
     * This is the array for contract type form, who will be sent to the reporting tools component
     * This array has the same form like expenseRevenue array from contract type form
     * and is used to set the reporting tools component with the selected expenses and revenues and related reporting tools
     */
    const [expenseRevenue, setExpenseRevenue] = useState([]);

    useEffect(() => {
        (() => {
            /**
             * We format the response from the backend to have an array un objects with same form like on frontend
             * to be used by the reporting tools component and contract type form
             * You can see an example on reporting-tools.jsx
             */

            const newFormat = contractType.ContractFinancialTools.map((ct) => {
                let obj = {
                    // From ContractToReportingTools we build an array only with the reportingToolsId from every object
                    reportingToolsId: ct.ContractToReportingTools.map(
                        (tool) => tool.reportingToolsId,
                    ),
                };

                if (ct.expenseNameId === null) {
                    obj.type = 'revenue';
                    obj.expenseRevenueId = ct.revenueNameId;
                } else if (ct.revenueNameId === null) {
                    obj.type = 'expense';
                    obj.expenseRevenueId = ct.expenseNameId;
                }

                return obj;
            });

            setExpenseRevenue(newFormat);
        })();
    }, []);

    const deleteContractType = async () => {
        try {
            await API.delete('/contractType', {
                params: {
                    id: contractType.id,
                },
            });

            enqueueSnackbar(t('The contract type was deleted successfully!'), {
                variant: 'success',
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
        }
    };

    return (
        <ExpandableRow
            colSpan={2}
            padding={false}
            row={
                <>
                    <TableCell>{contractType.name}</TableCell>
                </>
            }
            collapse={
                <>
                    {canAll && (
                        <div className="flex flex-wrap justify-between gap-4 p-5">
                            <Button
                                color="primary"
                                startIcon={<EditIcon />}
                                onClick={() =>
                                    history.push(
                                        accountancy.base +
                                            accountancy.contractTypeNomenclature
                                                .base +
                                            accountancy.contractTypeNomenclature
                                                .update +
                                            '/' +
                                            contractType.id,
                                    )
                                }
                            >
                                {t('Edit the contract type')}
                            </Button>

                            <Button
                                style={{ backgroundColor: 'var(--error)' }}
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                    globalContext.setGlobalModalOpen(true);
                                    globalContext.setGlobalModalChildren(
                                        <div className="flex-col">
                                            <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                {t('Are you sure you want to')}
                                                <br />
                                                {t(
                                                    'delete this contract type?',
                                                )}
                                            </p>
                                            <div className="flex items-center justify-center">
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        globalContext.setGlobalModalOpen(
                                                            false,
                                                        );
                                                        deleteContractType();
                                                    }}
                                                >
                                                    {t('Yes')}
                                                </Button>
                                                <div className="w-4" />
                                                <Button
                                                    onClick={() => {
                                                        globalContext.setGlobalModalOpen(
                                                            false,
                                                        );
                                                    }}
                                                >
                                                    {t('No')}
                                                </Button>
                                            </div>
                                        </div>,
                                    );
                                }}
                            >
                                {t('Delete the contract type')}
                            </Button>
                        </div>
                    )}
                    <div className="p-5">
                        {/* We call the contract type form, but we only display the reporting tools component */}
                        <ContractTypesForm
                            expenseRevenueInfo={expenseRevenue}
                            hideElements={true}
                        />
                    </div>
                </>
            }
        />
    );
};

ContractTypeRow.propTypes = {
    contractType: PropTypes.object,
    canAll: PropTypes.bool,
};

ContractTypeRow.defaultProps = {
    contractType: null,
    canAll: false,
};

const ManageContractType = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);
    const canView = checkPerm([
        {
            permissionId: '38',
            permissionType: 'VIEW',
        },
    ]);
    const canAll = checkPerm([
        {
            permissionId: '38',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canView) history.push('/');
    }, [canView]);

    const [loading, setLoading] = useState(false);

    const [contractTypes, setContractTypes] = useState([]);

    /**
     * Fetch all the Contract Types
     */
    const getContractTypes = async (currentPage, perPage) => {
        try {
            const res = await API.get('contractTypes', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setContractTypes(res.data.contractTypes.contractTypes);

            return res.data.contractTypes.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Manage contract types')}</title>
            </Helmet>

            <Header
                pageTitle={t('Manage contract types')}
                action={
                    canAll && (
                        <Button
                            color="primary"
                            style={{ borderRadius: '999px' }}
                            startIcon={<AddIcon />}
                            onClick={() =>
                                history.push(
                                    accountancy.base +
                                        accountancy.contractTypeNomenclature
                                            .base +
                                        accountancy.contractTypeNomenclature
                                            .create,
                                )
                            }
                        >
                            {t('Add contract type')}
                        </Button>
                    )
                }
            />

            <div className="page-container">
                <Pagination
                    loading={loading}
                    setLoading={setLoading}
                    getFunction={getContractTypes}
                >
                    {loading ? (
                        <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                            <CircularProgress />
                        </div>
                    ) : contractTypes.length ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('Contract type')}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableSeparator />
                                    {contractTypes.map((contractType) => (
                                        <ContractTypeRow
                                            key={contractType.id}
                                            contractType={contractType}
                                            canAll={canAll}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <NoDataPlaceholder />
                    )}
                </Pagination>
            </div>
        </>
    );
};

export default ManageContractType;
