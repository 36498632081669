import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@material-ui/core';

import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { affiliate, projectInManagement } from 'routes';
import { formatDate, formatTime } from 'utils';

const ProjectDisplay = ({ project, style }) => {
    const { t } = useTranslation();
    const optionClass = 'text-base text-dark-text mb-2';
    const optionSpanClass = 'text-main-text font-bold';

    const history = useHistory();

    const { checkPerm, user } = useContext(UserContext);

    const canViewPM = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    return (
        <div style={{ ...style?.containerStyle }}>
            <h4
                className="mb-5 whitespace-nowrap"
                style={{ ...style?.headerStyle }}
            >
                {t('Project info')}
            </h4>

            <p className={optionClass}>
                {t('Project name')}:{' '}
                <span className={optionSpanClass}>{project.name}</span>
            </p>
            <p className={optionClass}>
                {t('Allocated teams')}:{' '}
                <span className={optionSpanClass}>
                    {(() => {
                        let string = project.teams
                            .slice(0, 3)
                            .map((t) => t.name)
                            .join(', ');
                        if (project.teams.length > 3)
                            string += ` + ${t('others')} ${project.teams.length - 3}`;
                        return string;
                    })()}
                </span>
            </p>
            <p className={optionClass}>
                {t('Created on')}:{' '}
                <span className={optionSpanClass}>
                    {formatDate(project.createAt)}
                </span>
            </p>
            <p className={optionClass + ' mb-8'}>
                {t('Last update')}:{' '}
                <span className={optionSpanClass}>
                    {formatDate(project.updateAt)},{' '}
                    {formatTime(project.updateAt)}
                </span>
            </p>

            {canViewPM && (
                <Button
                    color="primary"
                    onClick={() =>
                        history.push(
                            projectInManagement.base +
                                '/' +
                                project.id +
                                projectInManagement.planingOverviewOnProject
                                    .base,
                        )
                    }
                >
                    {t('See project')}
                </Button>
            )}
        </div>
    );
};

ProjectDisplay.propTypes = {
    project: PropTypes.object,
    style: PropTypes.object,
};

ProjectDisplay.defaultProps = {
    project: {},
    style: null,
};

export default ProjectDisplay;
