/**
 * There are 3 configurators in total
 * 1 on Internal Activity / Planning Overview
 * 2 this one
 * 3 on single project management module
 */

import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button } from '@material-ui/core';

import Employee from 'api/Employee';
import AddEmployeePage from 'components/employees/add-employee-page';
import EditEmployeePage from 'components/employees/edit-employee-page';
import ViewEmployees from 'components/employees/view-employees';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { projectManagement } from 'routes';
import API from 'utils/axios';

import AddPmDepartment from './add_pm_department';
import AddAffiliate from './add-affiliate';
import AddUser from './add-user';
import AffiliateAccounts from './affiliate-accounts';
import CompleteProfiles from './complete-profiles';
import EditAffiliate from './edit_affiliate';
import EditPmDepartments from './edit_pm_department';
import PmContracts from './pm_contracts';
import PmDepartments from './pm_departments';

const rootUrl =
    projectManagement.base +
    projectManagement.projectManagementConfigurator.base;

export default function AllPMConfiguratorRouter() {
    const [pmId, setPmId] = useState('');

    const getPmModuleInfo = async () => {
        try {
            const response = await API.get('/Pm');
            setPmId(response.data.pm.id);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getPmModuleInfo();
    }, []);

    return (
        <Switch>
            <Route exact path={rootUrl}>
                <AllPMConfigurator pmId={pmId} />
            </Route>

            <Route exact path={rootUrl + '/add-employee'}>
                <AddEmployeePage moduleType="pm" />
            </Route>
            <Route exact path={rootUrl + '/edit-employee/:employeeId'}>
                <EditEmployeePage moduleType="pm" />
            </Route>

            <Route path={rootUrl + '/add-user'}>
                <AddUser pmId={pmId} />
            </Route>

            {/* <Route path={rootUrl + '/add-affiliate'}>
                <AddAffiliate pmId={pmId} />
            </Route> */}

            <Route path={rootUrl + '/edit-affiliate/:affiliateId'}>
                <EditAffiliate pmId={pmId} />
            </Route>

            <Route exact path={rootUrl + '/add-department'}>
                <AddPmDepartment />
            </Route>
            <Route exact path={rootUrl + '/edit-department/:departmentId'}>
                <EditPmDepartments />
            </Route>
        </Switch>
    );
}

function AllPMConfigurator({ pmId }) {
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);
    const canView = checkPerm([
        {
            permissionId: '61',
            permissionType: 'ALL',
        },
    ]);
    const canAll = checkPerm([
        {
            permissionId: '61',
            permissionType: 'ALL',
        },
    ]);

    const params = new URL(document.location).searchParams;
    const { t } = useTranslation();
    const userTabs = [
        t('Accounts'),
        // t('Affiliated accounts'),
        t('Internal resources'),
        t('External resources'),
    ];
    const tabs = [t('Users'), t('PM Departments'), t('PM Contracts')];
    const [activeTab, setActiveTab] = useState(
        params.get('tab') ? Number(params.get('tab')) : 0,
    );
    const [activeUsersTab, setActiveUsersTab] = useState(
        params.get('subTab') ? Number(params.get('subTab')) : 0,
    );

    const [internalEmployees, setInternalEmployees] = useState([]);
    const [externalEmployees, setExternalEmployees] = useState([]);

    const fetchEmployees = () => {
        Employee.pm.get().then((res) => {
            setInternalEmployees(
                res.data.filter((e) => e.employeeType === 'INTERNAL'),
            );
            setExternalEmployees(
                res.data.filter((e) => e.employeeType === 'EXTERNAL'),
            );
        });
    };

    useEffect(() => {
        if (!canView) {
            history.push('/');
            return;
        }

        fetchEmployees();
    }, []);

    return (
        <React.Fragment>
            <Header
                pageTitle={tabs[activeTab]}
                toolbar={
                    <div className="flex flex-col gap-4">
                        <div>
                            <Tabs
                                tabs={tabs}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        </div>

                        {activeTab === 0 && (
                            <div>
                                <Tabs
                                    tabs={userTabs}
                                    activeTab={activeUsersTab}
                                    setActiveTab={setActiveUsersTab}
                                />
                            </div>
                        )}
                    </div>
                }
                action={
                    canAll &&
                    (activeTab === 0 ? (
                        activeUsersTab === 0 ? (
                            <Button
                                color="secondary"
                                startIcon={<AddIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() =>
                                    history.push(`${rootUrl}/add-user`)
                                }
                            >
                                {t('Add new complete account')}
                            </Button>
                        ) : // : activeUsersTab === 1 ? (
                        //     <Button
                        //         color="secondary"
                        //         startIcon={<AddIcon />}
                        //         style={{ borderRadius: '999px' }}
                        //         onClick={() =>
                        //             history.push(`${rootUrl}/add-affiliate`)
                        //         }
                        //     >
                        //         {t('Add new affiliate account')}
                        //     </Button>
                        // )
                        activeUsersTab === 1 ? (
                            /* Add a PM Internal employee Button */
                            <Button
                                color="secondary"
                                startIcon={<AddIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() =>
                                    history.push({
                                        pathname: rootUrl + '/add-employee',
                                        state: {
                                            employeeType: 'internal',
                                        },
                                    })
                                }
                            >
                                {t('Add new internal employee')}
                            </Button>
                        ) : activeUsersTab === 2 ? (
                            /* Add a PM External employee Button */
                            <Button
                                color="secondary"
                                startIcon={<AddIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() =>
                                    history.push({
                                        pathname: rootUrl + '/add-employee',
                                        state: {
                                            employeeType: 'external',
                                        },
                                    })
                                }
                            >
                                {t('Add new external employee')}
                            </Button>
                        ) : null
                    ) : activeTab === 1 ? (
                        <Button
                            color="secondary"
                            startIcon={<AddIcon />}
                            style={{ borderRadius: '999px' }}
                            onClick={() =>
                                history.push({
                                    pathname: rootUrl + '/add-department',
                                })
                            }
                        >
                            {t('Add new Project Management department')}
                        </Button>
                    ) : null)
                }
            />

            <div className="page-container relative">
                {activeTab === 0 ? (
                    <React.Fragment>
                        {activeUsersTab === 0 && (
                            <CompleteProfiles pmId={pmId} />
                        )}
                        {/* {activeUsersTab === 1 && <AffiliateAccounts />} */}
                        {activeUsersTab === 1 && (
                            <React.Fragment>
                                <Helmet>
                                    <title>
                                        {t('PM Configurator')} |{' '}
                                        {t('Internal resources')}
                                    </title>
                                </Helmet>
                                <ViewEmployees
                                    moduleType="pm"
                                    employees={internalEmployees}
                                    actionCallback={fetchEmployees}
                                    canEditEmploy={canAll}
                                />
                            </React.Fragment>
                        )}
                        {activeUsersTab === 2 && (
                            <React.Fragment>
                                <Helmet>
                                    <title>
                                        {t('PM Configurator')} |{' '}
                                        {t('External resources')}
                                    </title>
                                </Helmet>
                                <ViewEmployees
                                    moduleType="pm"
                                    employees={externalEmployees}
                                    actionCallback={fetchEmployees}
                                    canEditEmploy={canAll}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : activeTab === 1 ? (
                    <PmDepartments />
                ) : activeTab === 2 ? (
                    <PmContracts />
                ) : null}
            </div>
        </React.Fragment>
    );
}

AllPMConfigurator.propTypes = {
    pmId: PropTypes.string,
};

AllPMConfigurator.defaultProps = {
    pmId: '',
};
