import React, { cloneElement } from 'react';

import { Box } from 'lucide-react';

import PropTypes from 'prop-types';

const mappedVariants = {
    dark: {
        wrapper: 'bg-layout-transparent border-layout-transparent',
        hoverElement: 'bg-layout-transparent-dark',
        icon: 'text-secondary-light',
    },
    secondary: {
        wrapper: 'bg-gradient-to-tr from-secondary-main to-secondary-light border-layout-transparent',
        hoverElement: 'bg-gradient-to-tr from-buttons-text via-buttons-text to-secondary-light',
        icon: 'text-buttons-texts group-hover:text-secondary-light',
    },
};

const RoundedButton = ({ icon, onClick, disabled, variant, height, style, hoverStyle }) => {
    return (
        <div
            className={`group relative z-0 flex cursor-pointer items-center justify-center overflow-hidden rounded-full border ${disabled ? 'pointer-events-none bg-disabled' : mappedVariants[variant].wrapper}`}
            style={{
                aspectRatio: '1 / 1',
                height,
                ...style,
            }}
            onClick={onClick}
        >
            {cloneElement(icon, {
                style: {
                    width: '50%',
                    height: '50%',
                    ...icon.props.style,
                },
                className: `transition-colors opacity-40 group-hover:opacity-100 ${disabled ? 'text-buttons-text' : mappedVariants[variant].icon}`,
            })}
            <div
                className={`absolute left-0 top-0 h-full w-full opacity-0 transition-opacity group-hover:opacity-100 ${mappedVariants[variant].hoverElement}`}
                style={{
                    zIndex: 'calc(-infinity)',
                    ...hoverStyle,
                }}
            />
        </div>
    );
};

RoundedButton.propTypes = {
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    height: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    hoverStyle: PropTypes.object,
};

RoundedButton.defaultProps = {
    icon: <Box />,
    disabled: false,
    variant: 'dark',
    height: '4rem',
    onClick: () => {},
    style: {},
    hoverStyle: {},
};

export default RoundedButton;
