import React, { useEffect, useState } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useParams,
} from 'react-router-dom';

import { Check, Pencil, PlusCircle, UserPlus, X } from 'lucide-react';

import { Button } from '@material-ui/core';

import Loading from 'components/shared/loading';
import { useActiveNavLink } from 'hooks/useActiveNavLink';
import useQuery from 'hooks/useQuery';
import { useSnackbar } from 'notistack';
import { Header, NavLinkTabs, Search } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { kpi } from 'routes';
import { errorHandling } from 'utils';
import { getTenantUsers } from 'utils/getterFunctions';
import {
    DEFAULT_KPI,
    DUMMY_KPI,
    DUMMY_TYPES,
    handleFormat,
} from 'utils/kpiUtils';

import KpiNomenclatureConfigurator from './kpi-nomenclature-configurator';
import KpiNomenclatureUsers from './kpi-nomenclature-users';
import KpiNomenclatureUsersAdd from './kpi-nomenclature-users-add';

const KpiNomenclatureView = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const history = useHistory();

    const tabs = [
        {
            children: t('Configure KPI'),
            route:
                kpi.base +
                kpi.nomenclature.base +
                (id
                    ? kpi.nomenclature.update.base +
                      `/${id}` +
                      kpi.nomenclature.update.configurator
                    : kpi.nomenclature.create.base +
                      kpi.nomenclature.create.configurator),
        },
        {
            children: t('Assign users'),
            route:
                kpi.base +
                kpi.nomenclature.base +
                (id
                    ? kpi.nomenclature.update.base +
                      `/${id}` +
                      kpi.nomenclature.update.users
                    : kpi.nomenclature.create.base +
                      kpi.nomenclature.create.users),
        },
    ];
    const activeTab = useActiveNavLink(tabs.map((tab) => tab.route));

    const [loading, setLoading] = useState(true);
    const [operating, setOperating] = useState(false);
    const [kpiObj, setKpiObj] = useState(
        id ? handleFormat(DUMMY_KPI) : DEFAULT_KPI,
    );
    const [types, setTypes] = useState(DUMMY_TYPES);

    const [query, updateQuery] = useQuery();
    const search = query.get('uq') ?? '';
    const [users, setUsers] = useState([]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (key, value) =>
        setKpiObj((prev) => ({ ...prev, [key]: value }));

    const handleRedirect = () => history.push(kpi.base + kpi.nomenclature.base);

    const handleSave = () => {
        try {
            setOperating(true);

            //TODO: Add a validation schema for the new kpi
            //TODO: Add save and edit kpi route
            enqueueSnackbar(t('The KPI has been successfully saved!'), {
                variant: 'success',
            });
            handleRedirect();
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setOperating(false);
        }
    };

    const handleData = async () => {
        try {
            //TODO: Request the kpi
            const [users] = await Promise.all([getTenantUsers()]);
            setUsers(users);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        //TODO: Check user kpi permission and redirect if not present
        handleData();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t(id ? 'Edit KPI' : 'Create new KPI')}</title>
            </Helmet>

            <Header
                pageTitle={t(id ? 'Edit KPI' : 'Create new KPI')}
                pageIcon={id ? <Pencil /> : <PlusCircle />}
                action={
                    activeTab === 1 && (
                        <Button
                            startIcon={<UserPlus />}
                            style={{ borderRadius: '9999px' }}
                            color="secondary"
                            onClick={handleOpen}
                            disabled={loading || operating}
                        >
                            {t('Add users')}
                        </Button>
                    )
                }
                toolbar={
                    <div className="flex w-full flex-wrap items-center justify-between gap-3">
                        <NavLinkTabs
                            tabs={tabs}
                            activeTab={activeTab}
                            disabled={
                                loading ||
                                operating ||
                                (kpiObj && !kpiObj.monitorUsers)
                            }
                        />
                        {activeTab === 1 && (
                            <Search
                                disabled={loading || operating}
                                searchIconBig={true}
                                value={search}
                                setValue={(value) =>
                                    updateQuery([{ key: 'uq', value }])
                                }
                            />
                        )}
                    </div>
                }
            />

            <div className="page-container flex flex-col gap-8">
                {loading ? (
                    <Loading />
                ) : (
                    <Switch>
                        <Route
                            exact
                            path={
                                kpi.base +
                                kpi.nomenclature.base +
                                kpi.nomenclature.create.base +
                                kpi.nomenclature.create.configurator
                            }
                        >
                            <KpiNomenclatureConfigurator
                                kpi={kpiObj}
                                onChangeKpi={handleChange}
                                types={types}
                                disabled={operating}
                            />
                        </Route>
                        <Route
                            exact
                            path={
                                kpi.base +
                                kpi.nomenclature.base +
                                kpi.nomenclature.create.base +
                                kpi.nomenclature.create.users
                            }
                        >
                            <KpiNomenclatureUsers
                                kpi={kpiObj}
                                onChangeKpi={handleChange}
                                disabled={operating}
                                search={search}
                            />
                        </Route>

                        <Route
                            exact
                            path={
                                kpi.base +
                                kpi.nomenclature.base +
                                kpi.nomenclature.update.full +
                                kpi.nomenclature.update.configurator
                            }
                        >
                            <KpiNomenclatureConfigurator
                                kpi={kpiObj}
                                onChangeKpi={handleChange}
                                types={types}
                                disabled={operating}
                            />
                        </Route>
                        <Route
                            exact
                            path={
                                kpi.base +
                                kpi.nomenclature.base +
                                kpi.nomenclature.update.full +
                                kpi.nomenclature.update.users
                            }
                        >
                            <KpiNomenclatureUsers
                                kpi={kpiObj}
                                onChangeKpi={handleChange}
                                disabled={operating}
                                search={search}
                            />
                        </Route>

                        <Route
                            path={
                                kpi.base +
                                kpi.nomenclature.base +
                                kpi.nomenclature.create.base
                            }
                        >
                            <Redirect
                                to={
                                    kpi.base +
                                    kpi.nomenclature.base +
                                    kpi.nomenclature.create.base +
                                    kpi.nomenclature.create.configurator
                                }
                            />
                        </Route>
                        <Route
                            exact
                            path={
                                kpi.base +
                                kpi.nomenclature.base +
                                kpi.nomenclature.update.full
                            }
                            render={({ match }) => (
                                <Redirect
                                    to={
                                        kpi.base +
                                        kpi.nomenclature.base +
                                        kpi.nomenclature.update.base +
                                        `/${match.params.id}` +
                                        kpi.nomenclature.update.configurator
                                    }
                                />
                            )}
                        />
                    </Switch>
                )}
                <div className="flex justify-end gap-4 py-8">
                    <Button
                        disabled={loading || operating}
                        startIcon={<X />}
                        size="large"
                        variant="text"
                        onClick={handleRedirect}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={loading || operating}
                        startIcon={<Check />}
                        size="large"
                        color="secondary"
                        onClick={handleSave}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </div>

            {!loading && (
                <KpiNomenclatureUsersAdd
                    open={open}
                    onClose={handleClose}
                    users={users}
                    kpi={kpiObj}
                    onChangeKpi={handleChange}
                />
            )}
        </>
    );
};

export default KpiNomenclatureView;
