import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PERSONAL_KPI_TITLES } from 'utils/kpiUtils';

import KpiChart from '../components/kpi-chart';
import KpiWrapper from '../components/kpi-wrapper';

const KpiPersonalViewChart = ({ kpi, division }) => {
    const { t } = useTranslation();

    return (
        <KpiWrapper
            title={<p className="text-xl font-bold leading-5 tracking-widest">{t(PERSONAL_KPI_TITLES[division])}</p>}
        >
            <div className="h-60 p-8">
                <KpiChart kpi={kpi} division={division} padding={6} detailed />
            </div>
        </KpiWrapper>
    );
};

KpiPersonalViewChart.propTypes = {
    kpi: PropTypes.object,
    division: PropTypes.string,
};

KpiPersonalViewChart.defaultProps = {
    kpi: null,
    division: null,
};

export default KpiPersonalViewChart;
