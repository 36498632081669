import { makeStyles } from '@material-ui/core';

const DEFAULT_INVOICE = {
    name: '',
    isValidated: true,
    billingNumber: '',
    issueDate: null,
    type: null,
    subType: null,
    projectStructureActivityId: null,
    contractId: null,
    contractPMId: null,
    partnerId: null,
    expenses: [],
    expenseNames: [],
    revenues: [],
    invoiceIds: [],
    invoiceArticles: [],
    crmProjectId: null,
    pmProjectOverviewPlanId: null,
    value: 0,
    secondValue: 0,
    manualExchange: false,
    exchangeDate: null,
    exchangeRate: 1,
    VAT: 0,
    valueWithVat: 0,
    secondValueWithVat: 0,
};

const DEFAULT_REVENUES_AND_EXPENSES = {
    grandParentId: null,
    list: [],
};

const SUBTYPES_DATA = {
    INCOME: {
        translation: 'Change the income invoice type',
        options: [
            {
                value: 'SIMPLE',
                translation: 'Simple income invoice',
            },
            {
                value: 'REVERSE',
                translation: 'Income invoice with advance reversal',
            },
        ],
    },
    EXPENSE: {
        translation: 'Change the expense invoice type',
        options: [
            {
                value: 'SIMPLE',
                translation: 'Simple expense invoice',
            },
            {
                value: 'REVERSE',
                translation: 'Expense invoice with advance reversal',
            },
        ],
    },
    ADVANCE: {
        translation: 'Change the advance invoice type',
        options: [
            {
                value: 'INCOME',
                translation: 'Advance invoice on income',
            },
            {
                value: 'EXPENSE',
                translation: 'Advance invoice on expense',
            },
        ],
    },
    REVERSAL: {
        translation: 'Change the reverse invoice type',
        options: [
            {
                value: 'INCOME',
                translation: 'Reversal of invoice of income',
            },
            {
                value: 'EXPENSE',
                translation: 'Reversal of invoice of expense',
            },
        ],
    },
};

const getStyles = makeStyles(() => {
    return {
        expense: {
            backgroundColor: `#05668D`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#05668D`,
            },
        },
        income: {
            backgroundColor: `#00A896`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#00A896`,
            },
        },
        advance: {
            backgroundColor: `#9B5094`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#9B5094`,
            },
        },
        reverse: {
            backgroundColor: `#F5A65B`,
            color: 'var(--buttons-text)',
            '&:hover': {
                opacity: 0.8,
                backgroundColor: `#F5A65B`,
            },
        },
        error: {
            backgroundColor: `var(--error)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--error-light)`,
            },
        },
        success: {
            backgroundColor: `var(--success)`,
            color: `var(--buttons-text)`,
            '&:hover': {
                backgroundColor: `var(--success-light)`,
            },
        },
    };
});

const handleChangeTitle = (invoice) => {
    let title = '';

    switch (invoice.type) {
        case 'INCOME':
            if (invoice.subType === null || invoice.subType === 'SIMPLE') title = 'Configure the income invoice';
            if (invoice.subType === 'REVERSE') title = 'Configure the income invoice with advance reversal';
            break;
        case 'EXPENSE':
            if (invoice.subType === null || invoice.subType === 'SIMPLE') title = 'Configure the expense invoice';
            if (invoice.subType === 'REVERSE') title = 'Configure the expense invoice with advance reversal';
            break;
        case 'ADVANCE':
            if (invoice.subType === 'INCOME') title = 'Configure the advance invoice of income';
            if (invoice.subType === 'EXPENSE') title = 'Configure the advance invoice of expense';
            title = 'Configure the advance invoice';
            break;
        case 'REVERSAL':
            if (invoice.subType === 'INCOME') title = 'Configure the reversal invoice on income';
            if (invoice.subType === 'EXPENSE') title = 'Configure the reversal invoice on expense';
            title = 'Configure the reversal invoice';
            break;
        default:
            title = '';
    }

    return title;
};

const canRenderClientSection = (invoice, selectedAdvanceInvoices) => {
    let render = false;

    switch (invoice.type) {
        case 'INCOME':
            switch (invoice.subType) {
                case 'SIMPLE':
                    if (invoice.value !== 0) render = true;
                    break;
                case 'REVERSE':
                    if (selectedAdvanceInvoices.length > 0) render = true;
                    break;
                default:
                    render = false;
            }
            break;
        case 'EXPENSE':
            switch (invoice.subType) {
                case 'SIMPLE':
                    if (invoice.value !== 0) render = true;
                    break;
                case 'REVERSE':
                    if (selectedAdvanceInvoices.length > 0) render = true;
                    break;
                default:
                    render = false;
            }
            break;
        case 'ADVANCE':
            if (invoice.value !== 0) render = true;
            break;
        default:
            render = false;
    }

    return render;
};

const canRenderContractAndProjectSection = (invoice, invoiceClient, selectedPartner) =>
    (invoiceClient !== null || selectedPartner !== null) && invoice.type !== 'REVERSAL';

const canRenderArticlesAndAccountancy = (
    invoice,
    selectedContract,
    selectedCrmProject,
    invoiceOnAContract,
    selectedProject,
    invoiceOnAProject,
) => {
    let render = false;

    const defaultValidation =
        ((selectedContract !== null || selectedCrmProject !== null) && invoiceOnAContract) ||
        (invoiceOnAProject && selectedProject !== null);

    switch (invoice.type) {
        case 'INCOME':
            if (defaultValidation) render = true;
            break;
        case 'EXPENSE':
            if (defaultValidation) render = true;
            break;
        case 'ADVANCE':
            if (defaultValidation) render = true;
            break;
        case 'REVERSAL':
            if (invoice.value !== 0) render = true;
            break;
        default:
            render = false;
    }

    return render;
};

const canRenderInfoFields = (
    invoice,
    checkedExpenses,
    validExpenseValue,
    checkedRevenues,
    validRevenueValue,
    selectedAdvanceInvoices,
    advanceInvoices,
) => {
    let render = false;

    switch (invoice.type) {
        case 'INCOME':
            switch (invoice.subType) {
                case 'SIMPLE':
                    if (checkedRevenues.list.length > 0 && validRevenueValue) render = true;
                    break;
                case 'REVERSE':
                    if (
                        checkedRevenues.list.length > 0 &&
                        validRevenueValue &&
                        selectedAdvanceInvoices.length > 0 &&
                        !selectedAdvanceInvoices.map((i) => advanceInvoices[i].value !== 0).includes(false)
                    )
                        render = true;
                    break;
                default:
                    render = false;
            }
            break;
        case 'EXPENSE':
            switch (invoice.subType) {
                case 'SIMPLE':
                    if (checkedExpenses.list.length > 0 && validExpenseValue) render = true;
                    break;
                case 'REVERSE':
                    if (
                        checkedExpenses.list.length > 0 &&
                        validExpenseValue &&
                        selectedAdvanceInvoices.length > 0 &&
                        !selectedAdvanceInvoices.map((i) => advanceInvoices[i].value !== 0).includes(false)
                    )
                        render = true;
                    break;
                default:
                    render = false;
            }
            break;
        case 'ADVANCE':
            if (
                (checkedRevenues.list.length > 0 && validRevenueValue) ||
                (checkedExpenses.list.length > 0 && validExpenseValue)
            )
                render = true;
            break;
        case 'REVERSAL':
            if (
                ((checkedRevenues.list.length > 0 && validRevenueValue) ||
                    (checkedExpenses.list.length > 0 && validExpenseValue)) &&
                selectedAdvanceInvoices.length > 0 &&
                !selectedAdvanceInvoices.map((i) => advanceInvoices[i].value !== 0).includes(false)
            )
                render = true;
            break;
        default:
            render = false;
    }

    return render;
};

const canRenderSubmitButton = (invoice, viewOnly) =>
    invoice.name !== '' && invoice.billingNumber !== '' && invoice.issueDate !== null && !viewOnly;

const canRenderTypeSelection = (invoicePDF, type) => Boolean(invoicePDF) && type === null;
const canRenderTypeChange = (invoicePDF, type, invoiceId, viewOnly) =>
    Boolean(invoicePDF) && type !== null && !invoiceId && !viewOnly;

const canRenderSubtypeSelection = (invoice) => invoice.type && !invoice.subType;
const canRenderSubtypeChange = (invoice, viewOnly) => invoice.type && invoice.subType && !viewOnly;

const canRenderInvoiceValues = (invoice) => invoice.type !== null && invoice.subType !== null;
const canRenderInvoiceReversal = (invoice) =>
    (invoice.type === 'REVERSAL' && invoice.subType !== null) || (invoice.subType === 'REVERSE' && invoice.value > 0);

const createInvoiceBody = ({
    invoice,
    clients,
    partners,
    contracts,
    crmProjects,
    projects,
    invoiceClient,
    selectedPartner,
    selectedContract,
    selectedCrmProject,
    selectedProject,
    selectedArticleIds,
    selectedAdvanceInvoices,
    advanceInvoices,
    apiExchangeRate,
    invoiceOnAContract,
    invoiceOnAProject,
    checkedExpenses,
    checkedRevenues,
}) => {
    let body = {
        name: invoice.name,
        isValidated: true,
        billingNumber: invoice.billingNumber,
        issueDate: invoice.issueDate,
        type: invoice.type,
        subType: invoice.subType,
        value: Number(invoice.value),
        valueWithVat: Number(invoice.valueWithVat),
        secondValue: Number(invoice.secondValue),
        secondValueWithVat: Number(invoice.secondValueWithVat),
        exchangeDate: invoice.exchangeDate,
        exchangeRate: invoice.exchangeRate,
        manualExchange: invoice.exchangeRate !== apiExchangeRate,
        VAT: invoice.VAT,
        articles: [...selectedArticleIds.nomenclature, ...selectedArticleIds.stocks],
    };

    switch (invoice.type) {
        case 'EXPENSE':
            body.contactId = clients[invoiceClient]?.id ?? null;
            body.partnerId = partners[selectedPartner]?.id ?? null;
            body.expenseNames = checkedExpenses.list;

            if (invoiceOnAContract) {
                body.contractId = contracts[selectedContract]?.id ?? null;
                body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                body.contractPMId = null;
                body.pmProjectOverviewPlanId = null;
            }

            if (invoiceOnAProject) {
                body.contractId = null;
                body.crmProjectId = null;
                body.contractPMId = contracts[selectedContract]?.id ?? null;
                body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
            }

            if (invoice.subType === 'SIMPLE') {
                body.subType = 'SIMPLE';
            } else if (invoice.subType === 'REVERSE') {
                body.subType = 'REVERSE';
                body.invoiceIds = selectedAdvanceInvoices.map((i) => ({
                    id: advanceInvoices[i]?.id,
                }));
            }

            break;
        case 'ADVANCE':
            body.contactId = clients[invoiceClient]?.id ?? null;
            body.partnerId = partners[selectedPartner]?.id ?? null;

            if (invoice.subType === 'INCOME') body.revenues = checkedRevenues.list;
            else if (invoice.subType === 'EXPENSE') body.expenseNames = checkedExpenses.list;

            if (invoiceOnAContract) {
                body.contractId = contracts[selectedContract]?.id ?? null;
                body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                body.contractPMId = null;
                body.pmProjectOverviewPlanId = null;
            }

            if (invoiceOnAProject) {
                body.contractId = null;
                body.crmProjectId = null;
                body.contractPMId = contracts[selectedContract]?.id ?? null;
                body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
            }

            break;
        case 'REVERSAL':
            body.invoiceIds = selectedAdvanceInvoices.map((i) => ({
                id: advanceInvoices[i]?.id,
            }));
            if (invoice.subType === 'INCOME') body.revenues = checkedRevenues.list;
            else if (invoice.subType === 'EXPENSE') body.expenseNames = checkedExpenses.list;

            break;
        case 'INCOME':
            body.contactId = clients[invoiceClient]?.id ?? null;
            body.partnerId = partners[selectedPartner]?.id ?? null;
            body.revenues = checkedRevenues.list;

            if (invoiceOnAContract) {
                body.contractId = contracts[selectedContract]?.id ?? null;
                body.crmProjectId = crmProjects[selectedCrmProject]?.id ?? null;
                body.contractPMId = null;
                body.pmProjectOverviewPlanId = null;
            }

            if (invoiceOnAProject) {
                body.contractId = null;
                body.crmProjectId = null;
                body.contractPMId = contracts[selectedContract]?.id ?? null;
                body.pmProjectOverviewPlanId = projects[selectedProject]?.id ?? null;
            }

            if (invoice.subType === 'SIMPLE') {
                body.subType = 'SIMPLE';
            } else if (invoice.subType === 'REVERSE') {
                body.subType = 'REVERSE';
                body.invoiceIds = selectedAdvanceInvoices.map((i) => ({
                    id: advanceInvoices[i]?.id,
                }));
            }
            break;
    }

    return body;
};

export {
    DEFAULT_INVOICE,
    DEFAULT_REVENUES_AND_EXPENSES,
    SUBTYPES_DATA,
    getStyles,
    handleChangeTitle,
    canRenderClientSection,
    canRenderContractAndProjectSection,
    canRenderArticlesAndAccountancy,
    canRenderInfoFields,
    canRenderSubmitButton,
    canRenderTypeSelection,
    canRenderTypeChange,
    canRenderSubtypeSelection,
    canRenderSubtypeChange,
    canRenderInvoiceValues,
    canRenderInvoiceReversal,
    createInvoiceBody,
};
