import React, { useContext, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { Button, TextField } from '@material-ui/core';

import ActivityContext from 'contexts/ActivityContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const ActionActivity = ({
    actions,
    setActions,
    departmentId,
    partnerId,
    tenantUsers,
    contactId,
    groups,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const { user } = useContext(UserContext);
    const activityContext = useContext(ActivityContext);

    const [addingData, setAddingData] = useState({
        adding: false,
        name: '',
        loading: false,
    });

    const handleChangeAddingData = (key, value) =>
        setAddingData((prev) => ({ ...prev, [key]: value }));

    const [formData, setFormData] = useState({
        actionId: null,
        comment: '',
        responsible: [],
    });

    const handleChangeFormData = (key, value) =>
        setFormData((prev) => ({ ...prev, [key]: value }));

    const userIndexToId = (users) => users.map((user) => tenantUsers[user].id);

    const schema = yup.object().shape({
        actionId: yup
            .string()
            .typeError(t('The action is mandatory!'))
            .required(t('The action is mandatory!')),
        comment: yup
            .string()
            .typeError(t('The comment is required!'))
            .required(t('The comment is required!')),
    });

    const actionSchema = yup.object().shape({
        name: yup
            .string()
            .typeError(t('The name is required!'))
            .required(t('The name is required!')),
    });

    const createDocumentActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schema.validate(formData);

            const { actionId, comment, responsible } = formData;

            const bodyData = new FormData();
            const reqBody = {
                actionId,
                title: '-',
                description: comment,
                note: '-',
                actionType: 'ACTION',
                status: 'TODO',
                ownerId: user.id,
                responsible: userIndexToId(responsible),
                supervisor: [],
                watcher: [],
                partnerId: partnerId ? partnerId : undefined,
                departmentId,
                groups,
            };

            if (contactId) reqBody.contactId = contactId;

            bodyData.append('data', JSON.stringify(reqBody));

            activityContext.submitNewActivity(bodyData);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        }
    };

    const editDocumentActivity = async () => {
        if (activityContext.isCreating) return;

        try {
            await schema.validate(formData);

            const { actionId, comment, responsible } = formData;

            const bodyData = new FormData();
            const reqBody = {
                actionId,
                title: '-',
                description: comment,
                note: '-',
                actionType: 'ACTION',
                status: 'TODO',
                ownerId: user.id,
                responsible: userIndexToId(responsible),
                supervisor: [],
                watcher: [],
                partnerId: partnerId ? partnerId : undefined,
                departmentId,
                groups,
            };

            if (contactId) reqBody.contactId = contactId;

            bodyData.append('data', JSON.stringify(reqBody));
            bodyData.append(
                'id',
                JSON.stringify(activityContext.activityForEdit.id),
            );

            activityContext.editActivity(bodyData);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
        }
    };

    const handleAddAction = async () => {
        try {
            handleChangeAddingData('loading', true);

            const { name } = addingData;
            await actionSchema.validate({ name });

            const response = await API.post('activityActions', { name });

            setActions((prev) => [...prev, response.data.data]);

            handleChangeFormData('actionId', response.data.data.id);
            handleChangeAddingData('name', '');
            handleChangeAddingData('adding', false);

            enqueueSnackbar(t('The action was added successfully!'), {
                variant: 'success',
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            handleChangeAddingData('loading', false);
        }
    };

    useEffect(() => {
        const { activityForEdit } = activityContext;

        if (activityForEdit) {
            const { actionId, description, users } = activityForEdit;
            const responsible = users.map((user) =>
                tenantUsers.findIndex((tu) => tu.id === user.userId),
            );
            setFormData({ actionId, comment: description, responsible });
        }
    }, [activityContext]);

    return (
        <form>
            <div className="mb-5 flex items-end gap-3">
                {addingData.adding ? (
                    <>
                        <LabelWrapper label={t('Action name')}>
                            <TextField
                                disabled={addingData.loading}
                                name="name"
                                placeholder={t('Action name')}
                                value={addingData.name}
                                onChange={(e) =>
                                    handleChangeAddingData(
                                        e.target.name,
                                        e.target.value,
                                    )
                                }
                            />
                        </LabelWrapper>
                        <Button
                            disabled={addingData.loading}
                            className="flex-shrink-0"
                            startIcon={<CheckIcon />}
                            onClick={handleAddAction}
                        >
                            {t('Save')}
                        </Button>
                    </>
                ) : (
                    <LabelWrapper label={t('Pick an action')}>
                        <Dropdown
                            disabled={addingData.loading}
                            variant="black"
                            options={actions.map((action) => t(action.name))}
                            selectedOption={(() => {
                                const index = actions.findIndex(
                                    (action) => action.id === formData.actionId,
                                );
                                return index >= 0 ? index : null;
                            })()}
                            setSelectedOption={(i) =>
                                handleChangeFormData('actionId', actions[i].id)
                            }
                        />
                    </LabelWrapper>
                )}
                <Button
                    disabled={addingData.loading}
                    className="flex-shrink-0"
                    startIcon={addingData.adding ? <CloseIcon /> : <AddIcon />}
                    onClick={() =>
                        handleChangeAddingData('adding', !addingData.adding)
                    }
                >
                    {addingData.adding ? t('Cancel') : t('Add action')}
                </Button>
            </div>
            <div className="mb-5">
                <LabelWrapper label={t('Select responsible')}>
                    <MultiDropdown
                        options={tenantUsers.map((user) => user?.profile?.name)}
                        placeholder={t('Responsible')}
                        selectedOptions={formData.responsible}
                        variant="black"
                        setSelectedOptions={(i) => {
                            if (formData.responsible.indexOf(i) > -1) {
                                handleChangeFormData(
                                    'responsible',
                                    formData.responsible.filter(
                                        (opt) => opt !== i,
                                    ),
                                );
                            } else {
                                handleChangeFormData('responsible', [
                                    ...formData.responsible,
                                    i,
                                ]);
                            }
                        }}
                    />
                </LabelWrapper>
            </div>

            <div className="mb-10">
                <TextField
                    name="comment"
                    placeholder={t('Add comment/Note')}
                    label={t('Comment')}
                    value={formData.comment}
                    multiline
                    rows={3}
                    onChange={(e) =>
                        handleChangeFormData(e.target.name, e.target.value)
                    }
                />
            </div>
            <Button
                disabled={addingData.loading}
                color="secondary"
                startIcon={
                    activityContext.activityForEdit !== null ? (
                        <EditIcon />
                    ) : (
                        <AddIcon />
                    )
                }
                onClick={
                    activityContext.activityForEdit !== null
                        ? editDocumentActivity
                        : createDocumentActivity
                }
            >
                {`${activityContext.activityForEdit !== null ? t('Edit') : t('Add')} ${t('action')}`}
            </Button>
        </form>
    );
};

ActionActivity.propTypes = {
    actions: PropTypes.array,
    setActions: PropTypes.func,
    departmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    partnerId: PropTypes.string,
    tenantUsers: PropTypes.arrayOf(PropTypes.object),
    activity: PropTypes.object,
    contactId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    groups: PropTypes.array,
};

ActionActivity.defaultProps = {
    actions: [],
    setActions: () => {},
    departmentId: null,
    partnerId: null,
    tenantUsers: [],
    activity: null,
    contactId: null,
    groups: [],
};

export default ActionActivity;
