import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';
import { errorHandling, formatPositiveNumber } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
    customButton: {
        backgroundColor: 'var(--error)',
        color: 'var(--buttons-text)',
        '&:hover': {
            backgroundColor: 'var(--error-light)',
        },
    },
}));

const AllocationAdd = () => {
    const styles = useStyles();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);

    const [formData, setFormData] = useState({
        materialResourceId: undefined,
        quantity: '',
        status: '',
        responsibleId: undefined,
        pmProjectOverviewPlanId: undefined,
    });
    const [projects, setProjects] = useState([]);
    const [resources, setResources] = useState([]);
    const [responsible, setResponsible] = useState([]);

    const selectedResource = resources[formData.materialResourceId] ?? null;
    const maxQuantity = id ? (formData.quantity + selectedResource?.quantity ?? 1) : (selectedResource?.quantity ?? 1);

    const schema = yup.object().shape({
        materialResourceId: yup.string().trim().required(t('The resource is mandatory!')),
        quantity: yup
            .number()
            .typeError(t('Quantity is mandatory!'))
            .integer()
            .min(1, t('Quantity must be bigger then 0!'))
            .max(
                maxQuantity,
                t('The maximum quantity must be less or equal to {{quantity}}', {
                    quantity: maxQuantity,
                }),
            )
            .required(t('Quantity is mandatory!')),
        status: yup.string().required(t('The status is mandatory!')),
        responsibleId: yup.string().trim().required(t('The responsible is mandatory!')),
        pmProjectOverviewPlanId: yup.string(),
    });

    const handleFormDataUpdate = (e) =>
        setFormData((prev) => {
            if (e.target.name === 'quantity')
                return {
                    ...prev,
                    quantity: formatPositiveNumber(e.target.value),
                };

            if (e.target.name === 'materialResourceId')
                return {
                    ...prev,
                    materialResourceId: e.target.value,
                    quantity: '',
                };

            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsUploading(true);

            const newFormData = {
                ...formData,
                materialResourceId: resources[formData.materialResourceId]?.id ?? undefined,
                responsibleId: responsible[formData.responsibleId]?.id ?? undefined,
                pmProjectOverviewPlanId: projects[formData.pmProjectOverviewPlanId]?.id ?? undefined,
            };

            await schema.validate(newFormData);

            if (!id) await API.post('resourceAllocation', newFormData);
            else await API.put('resourceAllocation', { ...newFormData, id });

            enqueueSnackbar(!id ? t('Allocation created successfully!') : t('Allocation updated successfully!'), {
                variant: 'success',
            });

            history.push(resourceModule.allocationResources.all);
        } catch (error) {
            if (error?.errors) enqueueSnackbar(error.errors[0], { variant: 'error' });
            else
                enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                    variant: 'error',
                });

            console.error(error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsUploading(true);

            await API.delete('resourceAllocation', { params: { id } });

            enqueueSnackbar(t('Allocation deleted successfully!'), {
                variant: 'success',
            });

            history.push(resourceModule.allocationResources.all);
        } catch (error) {
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const projectsRes = await API.get('PmProjects', {
                    params: {
                        perPage: 99999,
                        currentPage: 0,
                        pagesToLoad: 1,
                    },
                });

                const resResponsible = await API.get('humanResources', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                        pagesToLoad: 1,
                    },
                });

                const resResources = await API.get('materialResources', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                        pagesToLoad: 1,
                    },
                });

                const projectData = projectsRes.data.PmProjects.content;
                const responsibleData = resResponsible.data.allHumanResources.humanResources;
                const resourcesData = resResources.data.allMaterialResources.materialResource;

                if (id) {
                    const res = await API.get(`resourceAllocation/${id}`);
                    const allocationData = res.data;

                    const projectIndex = projectData.findIndex(
                        (project) => project.id === allocationData.pmProjectOverviewPlanId,
                    );
                    const responsibleIndex = responsibleData.findIndex(
                        (responsible) => responsible.id === allocationData.responsibleId,
                    );
                    const resourcesIndex = resourcesData.findIndex(
                        (resource) => resource.id === allocationData.materialResourceId,
                    );

                    setFormData({
                        materialResourceId: resourcesIndex >= 0 ? resourcesIndex : undefined,
                        quantity: allocationData.quantity,
                        status: allocationData.status,
                        responsibleId: responsibleIndex >= 0 ? responsibleIndex : undefined,
                        pmProjectOverviewPlanId: projectIndex >= 0 ? projectIndex : undefined,
                    });
                }

                setProjects(projectData);
                setResponsible(responsibleData);
                setResources(resourcesData);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t('Something went wrong!'), {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <div className="page-container">
            {isLoading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <form className="flex max-w-2xl flex-col gap-5" onSubmit={handleSubmit}>
                    <LabelWrapper label={t('Select resource')}>
                        <Dropdown
                            variant="black"
                            options={resources.map((resource) => resource.name)}
                            placeholder={t('Choose resource')}
                            selectedOption={formData.materialResourceId}
                            setSelectedOption={(i) =>
                                handleFormDataUpdate({
                                    target: {
                                        name: 'materialResourceId',
                                        value: i,
                                    },
                                })
                            }
                            disabled={isUploading}
                        />
                    </LabelWrapper>
                    <TextField
                        name="quantity"
                        placeholder={`${t('Quantity')} (${t('maximum')} ${maxQuantity})`}
                        label={`${t('Quantity')} (${t('maximum')} ${maxQuantity})`}
                        type="text"
                        value={formData.quantity}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading || !selectedResource}
                    />
                    <TextField
                        name="status"
                        placeholder="Status"
                        label="Status"
                        type="text"
                        value={formData.status}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading || !selectedResource}
                    />
                    <LabelWrapper label={t('Select responsible')}>
                        <Dropdown
                            variant="black"
                            options={responsible.map((responsible) => responsible.name)}
                            placeholder={t('Choose the responsible')}
                            selectedOption={formData.responsibleId}
                            setSelectedOption={(i) =>
                                handleFormDataUpdate({
                                    target: {
                                        name: 'responsibleId',
                                        value: i,
                                    },
                                })
                            }
                            disabled={isUploading || !selectedResource}
                        />
                    </LabelWrapper>
                    <LabelWrapper label={t('Select project')}>
                        <Dropdown
                            variant="black"
                            options={projects.map((project) => project.name)}
                            placeholder={t('Choose the project')}
                            selectedOption={formData.pmProjectOverviewPlanId}
                            setSelectedOption={(i) =>
                                handleFormDataUpdate({
                                    target: {
                                        name: 'pmProjectOverviewPlanId',
                                        value: i,
                                    },
                                })
                            }
                            disabled={isUploading || !selectedResource}
                        />
                    </LabelWrapper>
                    <div className="mt-5 flex flex-wrap justify-between gap-5">
                        <div className="flex flex-wrap gap-5">
                            <Button
                                startIcon={!id ? <AddIcon /> : <CheckIcon />}
                                color="primary"
                                type="submit"
                                disabled={isUploading}
                            >
                                {!id ? t('Allocate resource') : t('Edit allocated resource')}
                            </Button>
                            {id && (
                                <Button
                                    className={styles.customButton}
                                    startIcon={<DeleteIcon />}
                                    type="submit"
                                    disabled={isUploading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete();
                                    }}
                                >
                                    {t('Delete allocated resource')}
                                </Button>
                            )}
                        </div>
                        <Button
                            startIcon={<BackspaceIcon />}
                            color="secondary"
                            disabled={isUploading}
                            onClick={(e) => {
                                e.preventDefault();
                                history.goBack();
                            }}
                        >
                            {t('Back')}
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default AllocationAdd;
