import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DraftsIcon from '@material-ui/icons/Drafts';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import API from 'utils/axios';

const StyledTableCell = ({ children }) => (
    <TableCell style={{ backgroundColor: 'rgb(var(--base-layout-transparent) / 7.5%)' }}>{children}</TableCell>
);

StyledTableCell.propTypes = {
    children: PropTypes.node,
};

const EmailTemplateRow = ({ template }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '35',
            permissionType: 'ALL',
        },
    ]);

    return (
        <TableRow colSpan={3} padding={false}>
            <StyledTableCell>{template.name}</StyledTableCell>
            <StyledTableCell>
                <div
                    className="h-8 w-1/2 rounded-md"
                    style={{ backgroundColor: template.headerPalette.backgroundColor }}
                ></div>
            </StyledTableCell>
            <StyledTableCell>
                <div className="flex h-8 w-1/2 items-center justify-center rounded-md">
                    <p
                        style={{
                            color: template.headerPalette.foregroundColor,
                            fontWeight: 600,
                        }}
                    >
                        FONT
                    </p>
                </div>
            </StyledTableCell>
            <StyledTableCell>
                <div
                    className="h-8 w-1/2 rounded-md"
                    style={{ backgroundColor: template.footerPalette.backgroundColor }}
                ></div>
            </StyledTableCell>
            <StyledTableCell>
                <div className="flex h-8 w-1/2 items-center justify-center rounded-md">
                    <p
                        style={{
                            color: template.footerPalette.foregroundColor,
                            fontWeight: 600,
                        }}
                    >
                        FONT
                    </p>
                </div>
            </StyledTableCell>
            <StyledTableCell>
                <div className="flex justify-end">
                    {canAll && (
                        <Button
                            color="primary"
                            size="small"
                            startIcon={<DraftsIcon />}
                            onClick={() =>
                                history.push(
                                    configurator.base +
                                        configurator.emailTemplates.base +
                                        configurator.emailTemplates.update,
                                )
                            }
                        >
                            {t('Edit email template')}
                        </Button>
                    )}
                </div>
            </StyledTableCell>
        </TableRow>
    );
};

EmailTemplateRow.propTypes = {
    template: PropTypes.object,
};

EmailTemplateRow.defaultProps = {
    template: null,
};

const ManageEmailTemplates = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [templates, setTemplates] = useState([]);

    const getTemplates = async () => {
        setLoading(true);

        try {
            const response = await API.get('email_template');

            setTemplates([response.data.data]);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getTemplates();
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {t('ERP Configurator')} | {t('Email templates')}
                </title>
            </Helmet>

            {loading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : templates.length ? (
                <TableContainer component={Box}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Email template name')}</TableCell>
                                <TableCell>{t('Header background color')}</TableCell>
                                <TableCell>{t('Header text color')}</TableCell>
                                <TableCell>{t('Footer background color')}</TableCell>
                                <TableCell>{t('Footer text color')}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableSeparator />
                            {templates?.map((template) => (
                                <EmailTemplateRow key={template.id} template={template} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <NoDataPlaceholder />
            )}
        </>
    );
};

export default ManageEmailTemplates;
