import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import EmployeeAPI from 'api/Employee';
import BasicTooltip from 'components/shared/basic-tooltip';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { internalActivity, projectManagement } from 'routes';
import API from 'utils/axios';

const rootUrl = {
    pm: projectManagement.base + projectManagement.projectManagementConfigurator.base,
    internalActivity: internalActivity.base,
};

const DisplayEmployeeOnProject = ({ employee, getResources, canAll }) => {
    const history = useHistory();
    const confirm = useConfirm();
    const { t } = useTranslation();
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const employeeTotalHours = employee.Employees.schedule.map((h) => h.hours).reduce((acc, val) => acc + val, 0);

    const deleteEmployeeFromProject = async (employeeId) => {
        try {
            await API.delete(`/ProjectEmployees?&employeeId=${employeeId}&pmProjectId=${projectId}`);
            enqueueSnackbar(t('Employee was deleted from project successfully!'), { variant: 'success' });
            getResources();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="group relative mb-3 flex max-w-3xl justify-between rounded-md bg-layout-transparent-dark px-8 py-4">
            <div className="relative flex items-center">
                <p className="mr-2 text-lg font-semibold">{employee.Employees.firstName}</p>
                <p className="text-dark-text">{employee.Employees.position}</p>
                <p className="mx-4 text-sm text-dark-text opacity-50">&#9679;</p>
                <p className="text-dark-text">{employeeTotalHours}h</p>
            </div>

            {canAll && (
                <div className="flex gap-2">
                    <div className="flex items-center">
                        <BasicTooltip tip={t('Delete employee from project')}>
                            <div
                                className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-error-light transition-all  hover:bg-error group-hover:pointer-events-auto group-hover:translate-y-0"
                                onClick={() =>
                                    confirm(t('Are you sure you want to delete this employee from project?'), () =>
                                        deleteEmployeeFromProject(employee.employeeId),
                                    )
                                }
                            >
                                <DeleteIcon className="text-buttons-text" style={{ fontSize: '1.1rem' }} />
                            </div>
                        </BasicTooltip>
                    </div>
                </div>
            )}
        </div>
    );
};

DisplayEmployeeOnProject.propTypes = {
    employee: PropTypes.object.isRequired,
    moduleType: PropTypes.oneOf(['pm', 'internalActivity']).isRequired,
    actionCallback: PropTypes.func,
    getResources: PropTypes.func,
    canAll: PropTypes.bool,
};

DisplayEmployeeOnProject.defaultProps = {
    canAll: false,
};

export default DisplayEmployeeOnProject;
