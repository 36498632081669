import React, { Fragment, useContext, useMemo, useState } from 'react';

import { ReactComponent as FilterIcon } from 'assets/pipelines/svgs/filter-icon.svg';

import { Button, InputAdornment } from '@material-ui/core';

import GlobalContext from 'contexts/GlobalContext';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { DatePicker, Dropdown, LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { LocaleTextField } from 'RaisisComponents/Inputs';
import { useTranslation } from 'react-i18next';
import { getDayBeginningAndEnding } from 'utils';

import List from '../list/list';

const breakpointClasses = {
    sm: {
        wrapper: 'sm:shadow-none sm:border sm:border-main-text',
    },
    md: {
        wrapper: 'md:shadow-none md:border md:border-main-text',
    },
    lg: {
        wrapper: 'lg:shadow-none lg:border lg:border-main-text',
    },
    xl: {
        wrapper: 'xl:shadow-none xl:border xl:border-main-text',
    },
    '2xl': {
        wrapper: '2xl:shadow-none 2xl:border 2xl:border-main-text',
    },
};

const handleCheckHasValidValues = (value) => {
    if (value === null || value === undefined) return false;
    if (Array.isArray(value)) return value.length && value.every((e) => e !== null && e !== undefined);
    if (typeof value === 'object')
        return Object.values(value).length && Object.values(value).every((e) => e !== null && e !== undefined);

    return true;
};

const mappedTransformations = {
    select: (key, oldValue, newValue) => newValue,
    multiselect: (key, oldValue, newValue) => {
        const isPresent = oldValue.indexOf(newValue) >= 0;
        if (isPresent) return oldValue.filter((v) => v !== newValue);
        else return [...oldValue, newValue];
    },
    date: (key, oldValue, newValue) => {
        const returnValue = { ...oldValue };
        key.forEach((k) => {
            returnValue[k] = getDayBeginningAndEnding(newValue)[k];
        });

        return returnValue;
    },
    value: (key, oldValue, newValue) => newValue,
    list: (key, oldValue, newValue) => newValue,
};

const FilterAdd = ({ relations, element, filter, onChangeFilter, disabled, mobileBP }) => {
    const { currencyObj } = useContext(GlobalContext);
    const { t } = useTranslation();

    const [newFilter, setNewFilter] = useState(
        filter.find((element) => _.isEqual(element.relations, relations)) ?? {
            id: relations.at(-1),
            key: element.id,
            value: element.data.defaultValue,
            relations,
            metadata: element.data.filterData.metadata,
        },
    );

    const canApply = useMemo(() => handleCheckHasValidValues(newFilter.value), [newFilter.value]);

    const handleChangeValue = (type, key, value) =>
        setNewFilter((prev) => ({
            ...prev,
            value: mappedTransformations[type](key, prev.value, value),
        }));

    return (
        <div
            className={`flex h-full flex-col rounded-xl bg-layout-light px-4 py-3 shadow ${breakpointClasses[mobileBP].wrapper}`}
            style={{
                minWidth: '200px',
            }}
        >
            <div className="flex flex-col px-3.5 py-2.5">
                {element.data.inputs.map((input, index) => (
                    <Fragment key={input.key}>
                        <LabelWrapper label={input.label} style={{ marginTop: index !== 0 ? '0.5rem' : '0' }}>
                            {input.type === 'select' && (
                                <Dropdown
                                    disabled={disabled}
                                    placeholder={input.label}
                                    variant="black"
                                    options={input.options.map((item) => input.render(item))}
                                    selectedOption={(() => {
                                        const index = input.options.findIndex(
                                            (option) => option[input.key[0]] === newFilter.value,
                                        );

                                        return index >= 0 ? index : null;
                                    })()}
                                    setSelectedOption={(i) =>
                                        handleChangeValue(input.type, input.key, input.options[i][input.key[0]])
                                    }
                                />
                            )}
                            {input.type === 'multiselect' && (
                                <MultiDropdown
                                    disabled={disabled}
                                    placeholder={input.label}
                                    variant="black"
                                    options={input.options.map((item) => input.render(item))}
                                    selectedOptions={newFilter.value.map((v) =>
                                        input.options.findIndex((option) => option[input.key[0]] === v),
                                    )}
                                    setSelectedOptions={(i) =>
                                        handleChangeValue(input.type, input.key, input.options[i][input.key[0]])
                                    }
                                />
                            )}
                            {input.type === 'date' && (
                                <DatePicker
                                    disabled={disabled}
                                    variant="black"
                                    date={newFilter.value[input.key[0]]}
                                    setDate={(e) => handleChangeValue(input.type, input.key, e)}
                                />
                            )}
                            {input.type === 'value' && (
                                <LocaleTextField
                                    className="w-full"
                                    disabled={disabled}
                                    placeholder={input.label || t('Enter value')}
                                    minDecimals={0}
                                    maxDecimals={0}
                                    name={input.key[0]}
                                    value={newFilter.value}
                                    onChange={(e) => handleChangeValue(input.type, input.key, e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">{currencyObj.currency}</InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            {input.type === 'list' && (
                                <List
                                    disabled={disabled}
                                    options={input.options.map((item) => input.render(item))}
                                    selectedOption={(() => {
                                        const index = input.options.findIndex(
                                            (option) => option[input.key[0]] === newFilter.value,
                                        );

                                        return index >= 0 ? index : null;
                                    })()}
                                    setSelectedOption={(i) => {
                                        handleChangeValue(
                                            input.type,
                                            input.key,
                                            input.options[i]?.[input.key[0]] ?? null,
                                        );
                                    }}
                                />
                            )}
                        </LabelWrapper>
                    </Fragment>
                ))}

                <div
                    className={`grid transform transition-all duration-300 ${
                        canApply && element.data.inputs.length > 0 ? 'mb-6 translate-y-6' : 'translate-y-0'
                    } ${canApply ? 'opacity-100' : 'opacity-0'}`}
                    style={{
                        gridTemplateRows: canApply ? '1fr' : '0fr',
                    }}
                >
                    <div className="overflow-hidden">
                        <Button
                            disabled={disabled}
                            startIcon={<FilterIcon />}
                            className="w-full"
                            color="secondary"
                            onClick={() => onChangeFilter(newFilter)}
                        >
                            {t('Apply filter')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

FilterAdd.propTypes = {
    relations: PropTypes.array,
    element: PropTypes.object,
    filter: PropTypes.array,
    onChangeFilter: PropTypes.func,
    disabled: PropTypes.bool,
    mobileBP: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', '2xl']),
};

FilterAdd.defaultProps = {
    relations: [],
    element: {},
    filter: [],
    onChangeFilter: () => {},
    disabled: false,
    mobileBP: 'sm',
};

export default FilterAdd;
