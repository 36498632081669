import { useEffect, useState } from 'react';

const useElementRect = (ref) => {
    const [rect, setRect] = useState({
        box: {
            x: undefined,
            y: undefined,
            width: undefined,
            height: undefined,
            top: undefined,
            right: undefined,
            bottom: undefined,
            left: undefined,
        },
        viewport: {
            x: undefined,
            y: undefined,
            width: undefined,
            height: undefined,
            top: undefined,
            right: undefined,
            bottom: undefined,
            left: undefined,
        },
    });

    useEffect(() => {
        if (!ref.current) return;

        const observer = new ResizeObserver(([entry]) => {
            requestAnimationFrame(() => {
                if (!entry) return;
                setRect({ box: entry.contentRect, viewport: entry.target.getBoundingClientRect() });
            });
        });

        observer.observe(ref.current);

        return () => observer.disconnect();
    }, [ref]);

    return rect;
};

export default useElementRect;
