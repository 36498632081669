import React from 'react';

import Indicator from 'components/shared/indicator/indicator';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, generateUUID } from 'utils';
import { CHART_ELEMENTS_VALUES, getAverageStatus } from 'utils/kpiUtils';

const KpiChartTickY = ({ detailed, indexes, payload, x, y, width, ...rest }) => {
    if (!detailed) return null;

    const { t } = useTranslation();
    const id = generateUUID();
    const { value } = payload;
    const { color, status } = getAverageStatus(value, indexes);
    const { x1offset, text, rect, foreignObject } = CHART_ELEMENTS_VALUES['tick']['y'];
    const { dy, dx } = text;
    const { rx, ry, w, h, yOffset } = rect;
    const { xOffset, yOffset: yOffsetForeignObject } = foreignObject;

    return (
        <g className="group mix-blend-overlay" pointerEvents="bounding-box">
            <text {...rest} x={x} y={y} className="fill-current text-white opacity-40 group-hover:opacity-100">
                <tspan dy={dy} dx={-dx}>
                    {value}
                </tspan>
            </text>

            <defs>
                <linearGradient id={id} x1={x + x1offset} x2={width} y1={y} y2={y} gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stopColor={color} />
                    <stop offset="100%" stopColor="var(--layout-transparent)" />
                </linearGradient>
            </defs>
            <line x1={x + x1offset} x2={width} y1={y} y2={y} stroke={`url(#${id})`} />
            <rect rx={rx} ry={ry} width={w} height={h} fill={color} x={x + x1offset} y={y - yOffset} />
            <foreignObject
                x={x + xOffset}
                y={y - yOffsetForeignObject}
                y1={y}
                y2={y}
                width={1}
                height={1}
                className="relative overflow-visible"
            >
                <div className="absolute left-0 -translate-x-1/2 transform opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                    <Indicator style={{ backgroundColor: color }}>
                        <p className="text-xl font-semibold leading-5">{t(capitalizeFirstLetter(status))}</p>
                    </Indicator>
                </div>
            </foreignObject>
        </g>
    );
};

KpiChartTickY.propTypes = {
    detailed: PropTypes.bool,
    indexes: PropTypes.object,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    payload: PropTypes.object,
};

KpiChartTickY.defaultProps = {
    detailed: false,
    indexes: null,
    x: null,
    y: null,
    width: null,
    payload: null,
};

export default KpiChartTickY;
