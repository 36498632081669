import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import {
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';

import TableDisplay from 'components/shared/configurator/table-display';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { Header, Search } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm } from 'routes';

const ManageContacts = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '6',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '6',
            permissionType: 'VIEW',
        },
    ]);

    const [type, setType] = useState('ALL');
    const [query, setQuery] = useState('');

    const handleStatusChange = (event) => setType(event.target.value);

    return (
        <>
            <Helmet>
                <title>{t('manage-contacts')}</title>
            </Helmet>

            <Header
                pageTitle={t('manage-contacts')}
                action={
                    <>
                        <Search
                            searchIconBig={true}
                            value={query}
                            setValue={setQuery}
                            withSearchIcon={false}
                        />
                        {canAll ? (
                            <Button
                                color="secondary"
                                startIcon={<AddIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={() =>
                                    history.push(
                                        crm.base +
                                            crm.contacts.base +
                                            crm.contacts.create,
                                    )
                                }
                            >
                                {t('add-new-lead')}
                            </Button>
                        ) : null}
                    </>
                }
                toolbar={
                    <div className="flex">
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="status"
                                name="status-filter"
                                value={type}
                                onChange={handleStatusChange}
                            >
                                <div className="no-user-select-recursive flex flex-wrap items-center text-main-text">
                                    <FormControlLabel
                                        value="ALL"
                                        control={<Radio />}
                                        label={t('All')}
                                    />
                                    <FormControlLabel
                                        value="CRM"
                                        control={<Radio />}
                                        label={t('CRM Contacts')}
                                    />
                                    <FormControlLabel
                                        value="PM"
                                        control={<Radio />}
                                        label={t('PM Contacts')}
                                    />
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                }
            />
            {canView && (
                <div className="page-container">
                    <TableDisplay
                        entity="Contacts"
                        permissionId="6"
                        query={query}
                        filterParameters={{ type }}
                    />
                </div>
            )}
        </>
    );
};

ManageContacts.propTypes = {
    pmProjectId: PropTypes.string,
};

ManageContacts.defaultProps = {
    pmProjectId: null,
};

export default ManageContacts;
