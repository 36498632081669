import { useContext, useMemo } from 'react';

import GlobalContext from 'contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

const useDays = () => {
    const { t } = useTranslation();
    const { language } = useContext(GlobalContext);

    const days = useMemo(
        () => [t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday'), t('Sunday')],
        [language],
    );

    return days;
};

export default useDays;
