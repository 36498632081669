import React from 'react';

import { SendRounded } from '@material-ui/icons';

import { Button, CircularProgress } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EmailNotificationButtons = ({ cancel, send, disableSend }) => {
    const { t } = useTranslation();

    return (
        <div className="w-full rounded-b-xl p-4">
            <div className="flex justify-between">
                <Button onClick={cancel} disabled={disableSend}>
                    {t('CANCEL')}
                </Button>
                <div className="flex items-center gap-8">
                    <Button
                        color="primary"
                        onClick={send}
                        disabled={disableSend}
                        endIcon={
                            disableSend ? (
                                <CircularProgress size={20} />
                            ) : (
                                <SendRounded />
                            )
                        }
                    >
                        {t('SEND')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
EmailNotificationButtons.propTypes = {
    send: PropTypes.func,
    cancel: PropTypes.func,
    disableSend: PropTypes.bool,
};

EmailNotificationButtons.defaultProps = {
    send: () => {},
    cancel: () => {},
    disableSend: false,
};

export default EmailNotificationButtons;
