import React from 'react';

import PropTypes from 'prop-types';

const ExportTableCell = ({ children, textColor, backgroundColor, pdfMakeData, style }) => {
    return (
        <th
            className="border border-black"
            data-pdfmake={JSON.stringify({
                fillColor: backgroundColor,
                margin: [0, 0, 0, -5],
                alignment: 'center',
                ...pdfMakeData,
            })}
        >
            <p
                className="px-0.5 py-1 leading-7"
                style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    color: textColor,
                    textAlign: 'center',
                    ...style,
                }}
            >
                {children}
            </p>
        </th>
    );
};

ExportTableCell.propTypes = {
    children: PropTypes.element,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    pdfMakeData: PropTypes.object,
    style: PropTypes.object,
};

ExportTableCell.defaultProps = {
    children: null,
    backgroundColor: null,
    textColor: null,
    pdfMakeData: {},
    style: {},
};

export default ExportTableCell;
