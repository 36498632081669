import React from 'react';

import { User } from 'lucide-react';

import PropTypes from 'prop-types';

const Avatar = ({ url, placeholder, style }) => {
    return (
        <div
            className={`flex h-full items-center justify-center rounded-full bg-cover bg-center ${!url ? 'border border-layout-transparent bg-layout-lighter' : ''}`}
            style={{
                aspectRatio: '1 / 1',
                backgroundImage: url ? `url('${url}')` : undefined,
                ...style,
            }}
        >
            {!url && !placeholder && <User className="h-4/5 w-4/5" />}
            {!url && placeholder && <p className="text-xl font-semibold leading-5">{placeholder}</p>}
        </div>
    );
};

Avatar.propTypes = {
    url: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.object,
};

Avatar.defaultProps = {
    url: null,
    placeholder: '',
    style: {},
};

export default Avatar;
