import React, { cloneElement, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, ChevronRight, ChevronsLeft, Ellipsis } from 'lucide-react';

import useNavProps from 'hooks/useNavProps';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const handleMatchesRoutes = (items, matcher) => {
    let currentRoutesArray = [];

    for (const item of items) {
        const matches = matcher(item);
        currentRoutesArray.push({ ...item, matches });

        if (item.subItems) {
            const subItemsRoutesArray = handleMatchesRoutes(
                item.subItems,
                matcher,
            );
            currentRoutesArray = [
                ...currentRoutesArray,
                ...subItemsRoutesArray,
            ];
        }

        if (currentRoutesArray.find((prop) => Boolean(prop.matches))) break;
        else currentRoutesArray = [];
    }

    return currentRoutesArray;
};

const handleGetParentsRoutes = (module, modules) => {
    let currentRoutesArray = [];

    const { modulePath, parentModulePath } = module;

    const candidateModule = modules.find(
        (module) => module.modulePath === parentModulePath,
    );
    const matcher = (item) =>
        Boolean(item.subModulePath) && item.subModulePath === modulePath;
    currentRoutesArray = handleMatchesRoutes(candidateModule.navItems, matcher);

    if (candidateModule.parentModulePath) {
        const parentRoutesArray = handleGetParentsRoutes(
            candidateModule,
            modules,
        );
        currentRoutesArray = [...parentRoutesArray, ...currentRoutesArray];
    }

    return currentRoutesArray;
};

const handleCreateNavigationArray = (navigationProps, pathname, t, history) => {
    if (pathname === '/') return [];

    let navigationArray = [];

    const { mainMenuProps, secondaryMenusProps, activeSecondaryMenuIndex } =
        navigationProps;
    const matcher = (item) => {
        return Boolean(item.route) && pathname.includes(item.route);
    };

    const secondaryMenu = secondaryMenusProps[activeSecondaryMenuIndex];
    if (secondaryMenu) {
        navigationArray = handleMatchesRoutes(secondaryMenu.navItems, matcher);
        const parentsRoutes = handleGetParentsRoutes(secondaryMenu, [
            mainMenuProps,
            ...secondaryMenusProps,
        ]);

        navigationArray = [...parentsRoutes, ...navigationArray];
    } else {
        navigationArray = handleMatchesRoutes(mainMenuProps.navItems, matcher);
    }

    navigationArray.unshift({
        icon: <Box />,
        name: 'EPR Dashboard',
        route: '/',
        onRoute: function () {
            history.push(this.route);
        },
    });

    if (navigationArray.at(-1).route !== pathname) {
        navigationArray.push({
            name: t('Operation page'),
            route: pathname,
            icon: navigationArray.at(-1).icon,
            onRoute: function () {
                history.push(this.route);
            },
        });
    }

    return navigationArray;
};

const NAVIGATION_SLICE_ARRAY = 3;

export const Header = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);

    const {
        pageIcon,
        pageTitle,
        titleAction,
        action,
        toolbar,
        toolbarSecondary,
        toolbarTertiary,
    } = props;
    const { navigationProps } = useNavProps();

    const navigationArray = handleCreateNavigationArray(
        navigationProps,
        pathname,
        t,
        history,
    );
    const displayedNavigation =
        navigationArray.length > NAVIGATION_SLICE_ARRAY
            ? open
                ? navigationArray
                : navigationArray.slice(
                      navigationArray.length - NAVIGATION_SLICE_ARRAY,
                  )
            : navigationArray;

    const icon = open ? <ChevronsLeft /> : <Ellipsis />;

    return (
        <div className="mx-8 mt-8 overflow-hidden rounded-2.5xl border border-layout-transparent bg-layout-transparent xl:mx-4 xl:mt-4 md:m-0 md:rounded-none">
            {navigationArray.length > 0 && (
                <div
                    className="flex flex-wrap items-center gap-3 border-b border-layout-transparent px-6 py-4"
                    style={{
                        minHeight: '5rem',
                    }}
                >
                    {navigationArray.length > NAVIGATION_SLICE_ARRAY && (
                        <div
                            className="flex h-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full transition-all duration-300 hover:bg-layout-transparent-dark"
                            style={{ aspectRatio: '1 / 1' }}
                        >
                            {cloneElement(icon, {
                                className: 'h-8 w-8',
                                onClick: () => setOpen((prev) => !prev),
                            })}
                        </div>
                    )}
                    {displayedNavigation.map((item, index) => (
                        <div
                            key={index}
                            className="flex h-10 items-center gap-3"
                        >
                            {((index === 0 &&
                                !open &&
                                navigationArray.length >
                                    NAVIGATION_SLICE_ARRAY) ||
                                index !== 0) && (
                                <ChevronRight className="h-6 w-6 flex-shrink-0 opacity-30" />
                            )}
                            <p
                                onClick={() => item.onRoute?.()}
                                className={`flex-shrink-0 text-xl leading-5 ${item.route ? (index === displayedNavigation.length - 1 ? 'pointer-events-none' : 'cursor-pointer opacity-60 hover:opacity-100') : 'pointer-events-none opacity-60'}`}
                            >
                                {item.name}
                            </p>
                        </div>
                    ))}
                </div>
            )}
            <div
                className={`flex items-center justify-between gap-3 px-6 py-4 ${toolbar ? 'border-b border-layout-transparent' : ''}`}
                style={{
                    minHeight: '5rem',
                }}
            >
                <div
                    className={`flex items-center gap-3 ${titleAction ? 'group cursor-pointer' : 'pointer-events-none'}`}
                    onClick={titleAction}
                >
                    {cloneElement(pageIcon ?? navigationArray.at(-1).icon, {
                        className:
                            'h-9 w-9 flex-shrink-0 group-hover:text-disabled',
                    })}
                    <h1 className="text-2xl font-medium leading-6 group-hover:text-disabled">
                        {pageTitle ?? navigationArray.at(-1).name}
                    </h1>
                </div>
                {action && (
                    <div className="flex items-center gap-3">{action}</div>
                )}
            </div>
            {toolbar && (
                <div
                    className={`flex items-center gap-3 px-6 py-4 ${toolbarSecondary ? 'border-b border-layout-transparent' : ''}`}
                    style={{
                        minHeight: '5rem',
                    }}
                >
                    {toolbar}
                </div>
            )}
            {toolbarSecondary && (
                <div
                    className={`flex items-center gap-3 px-6 py-4 ${toolbarTertiary ? 'border-b border-layout-transparent' : ''}`}
                    style={{
                        minHeight: '5rem',
                    }}
                >
                    {toolbarSecondary}
                </div>
            )}
            {toolbarTertiary && (
                <div
                    className="flex items-center gap-3 px-6 py-4"
                    style={{
                        minHeight: '5rem',
                    }}
                >
                    {toolbarTertiary}
                </div>
            )}
        </div>
    );
};

Header.propTypes = {
    pageIcon: PropTypes.element,
    pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    titleAction: PropTypes.func,
    action: PropTypes.element,
    toolbar: PropTypes.element,
    toolbarSecondary: PropTypes.element,
    toolbarTertiary: PropTypes.element,
};

Header.defaultProps = {
    pageIcon: null,
    pageTitle: null,
    titleAction: undefined,
    action: null,
    toolbar: null,
    toolbarSecondary: null,
    toolbarTertiary: null,
};
