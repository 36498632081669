import React from 'react';

import PropTypes from 'prop-types';

const KpiWrapper = ({ children, title }) => {
    return (
        <div className="relative flex h-full w-full flex-col overflow-hidden rounded-2.5xl border border-layout-transparent bg-layout-transparent">
            {title && (
                <div
                    className="flex items-center gap-3 border-b border-layout-transparent bg-layout-transparent px-8 py-2"
                    style={{ minHeight: '4rem' }}
                >
                    {title}
                </div>
            )}
            <div className="flex-1">{children}</div>
        </div>
    );
};

KpiWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

KpiWrapper.defaultProps = {
    children: null,
    title: null,
};

export default KpiWrapper;
