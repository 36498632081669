import React from 'react';

import { Button } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getStyles } from 'utils/invoicesUtils';

const InvoiceSubmitButton = ({ invoiceId, disableInputs, onResetInvoiceInfo, onInvoiceCreationOrUpdate }) => {
    const styles = getStyles();
    const { t } = useTranslation();

    return (
        <div className="mt-10 flex justify-start gap-5">
            <Button
                className={styles.success}
                startIcon={<i className="fa-solid fa-file-invoice" />}
                onClick={onInvoiceCreationOrUpdate}
                disabled={disableInputs}
            >
                {invoiceId ? t('Update the invoice') : t('Add the invoice')}
            </Button>

            {!invoiceId && (
                <Button
                    className={styles.error}
                    startIcon={<i className="fa-solid fa-file-invoice" />}
                    onClick={() => onResetInvoiceInfo(true)}
                >
                    {t('Reset the invoice')}
                </Button>
            )}
        </div>
    );
};

InvoiceSubmitButton.propTypes = {
    invoiceId: PropTypes.string,
    disableInputs: PropTypes.bool,
    onResetInvoiceInfo: PropTypes.func,
    onInvoiceCreationOrUpdate: PropTypes.func,
};

InvoiceSubmitButton.defaultProps = {
    invoiceId: null,
    disableInputs: false,
    onResetInvoiceInfo: () => {},
    onInvoiceCreationOrUpdate: () => {},
};

export default InvoiceSubmitButton;
