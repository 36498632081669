import React, { useState } from 'react';

import { Goal } from 'lucide-react';

import Blur from 'components/shared/blur/blur';
import Loading from 'components/shared/loading';
import useMonths from 'hooks/useMonths';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter, generateYears } from 'utils';
import { getAverage, getAverageStatus, getPercent, getPeriodsFilter } from 'utils/kpiUtils';

import KpiFilters from './kpi-filters';
import KpiWrapper from './kpi-wrapper';

const KpiAverageComparison = ({ kpi, period }) => {
    const { t } = useTranslation();
    const { name, data, previousAverage, indexes } = kpi;
    const { selectedDivision } = period;

    const months = useMonths();
    const years = generateYears(2015, new Date().getFullYear() + 5);

    const [loading, setLoading] = useState(false);

    const [averages, setAverages] = useState(previousAverage);
    const [filter, setFilter] = useState(
        getPeriodsFilter(
            { value: years.indexOf(new Date(previousAverage.createAt).getFullYear()), options: years },
            { value: new Date(previousAverage.createAt).getMonth(), options: months },
        ),
    );

    const average = getAverage(data[selectedDivision]);
    const { percent, color, icon: Icon } = getPercent(average, averages[selectedDivision]);

    const currentIndexes = indexes.at(-1).divisions.find((division) => division.type === selectedDivision);
    const { status, color: statusColor } = getAverageStatus(average, currentIndexes);

    //TODO: Request new averages to compare and set them
    const handleChangeFilter = (k, v) =>
        setFilter((prev) => ({ ...prev, selectedFilter: { ...prev.selectedFilter, [k]: v } }));

    return (
        <KpiWrapper>
            {loading && (
                <Blur>
                    <Loading style={{ height: '100%' }} />
                </Blur>
            )}
            <div className="flex items-end gap-5 p-8 lg:flex-col lg:items-start sm:items-center">
                <p className="text-8xl font-light">{average.toFixed(2)}</p>
                <div className="flex flex-col gap-2 lg:flex-row sm:flex-col sm:gap-5">
                    <div className="flex items-center gap-2 sm:justify-center">
                        <Goal className="h-7 w-7 opacity-80" />
                        <p className="text-xl leading-5 opacity-80">{name}</p>
                    </div>
                    <div className="flex items-center gap-2 sm:flex-col sm:gap-5">
                        <div className="flex items-center gap-2">
                            <div className="rounded-2.5xl px-3 py-1" style={{ backgroundColor: statusColor }}>
                                <p className="text-xl font-medium leading-5">{t(capitalizeFirstLetter(status))}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                {Icon && <Icon className="h-7 w-7" style={{ color }} />}
                                <p className="text-xl font-semibold leading-5" style={{ color }}>
                                    {percent.toFixed(2)}%
                                </p>
                            </div>
                        </div>
                        <p className="text-xl leading-5 opacity-80">{t('compared to')}</p>
                        <div className="flex items-center gap-2">
                            <KpiFilters
                                disabled={loading}
                                labels={false}
                                periodsFilter={filter}
                                onChangeFilter={handleChangeFilter}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </KpiWrapper>
    );
};

KpiAverageComparison.propTypes = {
    kpi: PropTypes.object,
    period: PropTypes.object,
};

KpiAverageComparison.defaultProps = {
    kpi: null,
    period: null,
};

export default KpiAverageComparison;
