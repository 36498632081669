import React, { cloneElement } from 'react';

import { EllipsisVertical, FileText } from 'lucide-react';

import PropTypes from 'prop-types';

import ActionsPopover from '../actions-popover/actions-popover';
import TagSmall from '../tag-small/tag-small';

const FileRow = ({ children, info, disabled, actions }) => {
    return (
        <>
            <div className="flex h-16 items-center justify-between gap-3 rounded-xl border border-layout-transparent bg-layout-transparent pr-3">
                <div className="flex h-full min-w-0 items-center gap-6">
                    <div className="flex h-full w-16 flex-shrink-0 items-center justify-center rounded-lg bg-gradient-to-tr from-gradient-primary-dark to-gradient-primary-light">
                        {cloneElement(info.icon, {
                            className: `transition-colors ${info.icon.className} ${disabled ? 'text-disabled' : ''}`,
                        })}
                    </div>
                    <div className={`truncate text-xl font-medium ${disabled ? 'text-disabled' : ''}`}>{children}</div>
                </div>
                <div className="flex h-10 items-center gap-3">
                    {info.text && <TagSmall>{info.text.toUpperCase()}</TagSmall>}
                    <ActionsPopover
                        icon={<EllipsisVertical className="h-7 w-7" />}
                        opacity={{ default: 0.6, hover: 1 }}
                        actions={actions}
                        disabled={disabled}
                    />
                </div>
            </div>
        </>
    );
};

FileRow.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    info: PropTypes.object,
    disabled: PropTypes.bool,
    actions: PropTypes.array,
};

FileRow.defaultProps = {
    children: null,
    info: { icon: <FileText />, text: '' },
    disabled: false,
    actions: [],
};

export default FileRow;
