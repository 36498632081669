import { formatDate } from 'utils';
import API from 'utils/axios';

export const getTenantUsers = async () => {
    try {
        const res = await API.get('/tenants', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });
        return res.data.users || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getProjectUsers = async (params) => {
    try {
        const res = await API.get('/ProjectUsers', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
                ...params,
            },
        });

        return res.data.Users.users.map((u) => u.User) || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getProjectAffiliates = async (params) => {
    try {
        const res = await API.get('/ProjectAffiliates', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
                ...params,
            },
        });

        return (
            res.data.Affiliates.affiliates.map((u) => u.Affiliate.user) || []
        );
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getDepartments = async () => {
    try {
        const res = await API.get('/departments', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });
        return res.data.departments.departments || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getPmDepartments = async () => {
    try {
        const res = await API.get('/pmDepartments', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
                type: 'PLANNING_OVERVIEW',
            },
        });

        return res.data.pmDepartments || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getPartners = async () => {
    try {
        const res = await API.get('/partners', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });
        return res.data.formattedPartners || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getPermissions = async () => {
    try {
        const res = await API.get('permissions');
        return res.data.data || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getContactById = async (id) => {
    try {
        const res = await API.get('/contact', {
            params: {
                id,
            },
        });
        return res.data.data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getFields = async () => {
    try {
        const res = await API.get('fields');
        return res.data.data || [];
    } catch (err) {
        console.error(err);
        return [];
    }
};

export const getEntityFields = async (entity) => {
    try {
        const res = await API.get('/field', {
            params: {
                type: entity,
            },
        });

        return res.data.data || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

/**
 * Resources (internal/external functions)
 */
export const getResources = async (type = 'internal') => {
    const resources = {
        internalEmployees: [],
        externalEmployees: [],
    };

    const url = type === 'internal' ? 'employees' : 'pm_employees';

    try {
        const response = await API.get(url);
        resources.internalEmployees = response.data[
            type === 'internal' ? 'employees' : 'pmEmployees'
        ].filter((e) => e.employeeType === 'INTERNAL');
        resources.externalEmployees = response.data[
            type === 'internal' ? 'employees' : 'pmEmployees'
        ].filter((e) => e.employeeType === 'EXTERNAL');
    } catch (err) {
        console.error(err);
    }

    return resources;
};

/**
 * Expenses Nomenclature
 */
export const getExpensesNames = async () => {
    let expenses = [];

    try {
        const response = await API.get('/expensesName', {
            params: {
                perPage: 99999,
                pagesToLoad: 1,
                currentPage: 0,
            },
        });
        expenses = response.data.expensesName.expenseNames;
    } catch (err) {
        console.error(err);
    }

    return expenses;
};

export const getExpensesNameById = async (id) => {
    let expense = null;

    try {
        const response = await API.get('/expenseName', {
            params: {
                id,
            },
        });
        expense = response.data.expenseName;
    } catch (err) {
        console.error(err);
    }

    return expense;
};

export const getArticles = async () => {
    try {
        const response = await API.get('/articles', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
                many: 'ALL',
            },
        });
        return response.data.articles || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getStocksArticles = async () => {
    try {
        const response = await API.get('articles_from_stocks', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
            },
        });

        return response.data.data || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getContacts = async (type = 'ALL') => {
    try {
        const response = await API.get('/contacts', {
            params: {
                perPage: 99999,
                currentPage: 0,
                pagesToLoad: 1,
                type,
            },
        });

        return response.data.data.contacts || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getCompanyVAT = async () => {
    try {
        const response = await API.get('/tenant');
        return response.data.data.percentageVAT || null;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export const getCompanyLogoAndIcon = async () => {
    try {
        const response = await API.get('/tenant');
        const { logoUrl, iconUrl } = response.data.data;
        return { logoUrl, iconUrl };
    } catch (err) {
        console.error(err);
    }
};

/**
 * [Get Exchange Rates from BNR]
 */
export let exchangeRates = [];

export const getExchangeRates = async () => {
    try {
        const response = await API.get('/currency');
        exchangeRates = response.data.data;
    } catch (error) {
        console.error(error);
    }
};

export const getSpecificRate = (currency) => {
    if (currency === 'RON' || currency === 'ALL')
        return {
            currency: 'RON',
            changeRate: 1,
        };

    return exchangeRates.filter(
        (r) => r.currency === currency.toUpperCase(),
    )[0];
};

export const getCompanyDetails = async () => {
    try {
        const response = await API.get('/tenant');
        return response.data.data;
    } catch (err) {
        console.error(err);
    }
};

export const getKpi = async (id) => {
    try {
        const kpiRes = await API.get('kpi', {
            params: {
                id,
            },
        });

        return kpiRes.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getKpiArr = async (currentPage, perPage, callback) => {
    try {
        const kpiArrRes = await API.get('kpi_all', {
            params: {
                currentPage,
                perPage,
                pagesToLoad: 1,
            },
        });

        callback(kpiArrRes.data.data);

        return kpiArrRes.data.length;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getPersonalKpiArr = async (currentPage, perPage, callback) => {
    try {
        const kpiArrRes = await API.get('kpi_personal', {
            params: {
                currentPage,
                perPage,
                pagesToLoad: 1,
            },
        });

        callback(kpiArrRes.data.data);

        return kpiArrRes.data.length;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getCrmKpiArr = async (currentPage, perPage, callback) => {
    try {
        const kpiArrRes = await API.get('kpi_crm', {
            params: {
                currentPage,
                perPage,
                pagesToLoad: 1,
            },
        });

        callback(kpiArrRes.data.data);

        return kpiArrRes.data.length;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getLeadsReport = async (params = {}) => {
    try {
        const response = await API.get('leadsReport', { params });

        return response.data.data.map((item) => ({
            ...item,
            actions: item.actions.map((action) => ({
                ...action,
                date: formatDate(action.date),
            })),
        }));
    } catch (err) {
        console.error(err);
    }
};

export const getAgentsReport = async (params = {}) => {
    try {
        const response = await API.get('agentsReport', { params });
        return response.data.data;
    } catch (err) {
        console.error(err);
    }
};

export const getProjects = async (params = {}) => {
    try {
        const response = await API.get('PmProjects', { params });
        return response.data.PmProjects;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getActivityActions = async () => {
    try {
        const response = await API.get('activityActions');
        return response.data.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getAgentUsers = async () => {
    try {
        const response = await API.get('agentUsers');
        return response.data.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
