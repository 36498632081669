import React from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import Employee from 'api/Employee';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { internalActivity, projectManagement } from 'routes';
import { formatPositiveNumber, PHONE_NO_REGEX } from 'utils';
import * as yup from 'yup';

export default function EmployeeForm({ moduleType, employeeType, employee = {} }) {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();

    const [employeeData, setEmployeeData] = React.useState({ ...employee });

    const defaultSchedule = [
        {
            day: 'MONDAY',
            hours: 8,
        },
        {
            day: 'TUESDAY',
            hours: 8,
        },
        {
            day: 'WEDNESDAY',
            hours: 8,
        },
        {
            day: 'THURSDAY',
            hours: 8,
        },
        {
            day: 'FRIDAY',
            hours: 8,
        },
        {
            day: 'SATURDAY',
            hours: 0,
        },
        {
            day: 'SUNDAY',
            hours: 0,
        },
    ];

    const [schedule, setSchedule] = React.useState(() => {
        if (employee.id) {
            return employee.schedule.map((day) => ({
                day: day.day,
                hours: day.hours,
            }));
        }

        return defaultSchedule;
    });

    let validationSchema = yup.object().shape({
        schedule: yup.array().of(
            yup.object().shape({
                hours: yup
                    .number()
                    .typeError(t('Number of hours worked on a day must be at least 0!'))
                    .min(0, t('Number of hours worked on a day must be at least 0!'))
                    .max(24, t('Number of hours worked on a day can not be bigger than 24 hours!'))
                    .required(t('Number of hours worked on a day must be at least 0!')),
            }),
        ),

        email: yup
            .string()
            .typeError(t('The email address is mandatory'))
            .required(t('The email address is mandatory'))
            .email(t('The email address is not valid')),

        phoneNumber: yup
            .string()
            .trim()
            .typeError(t('The phone number field is mandatory'))
            .required(t('The phone number field is mandatory'))
            .matches(PHONE_NO_REGEX, t('The phone number is not valid'))
            .min(10, t('The phone number needs to contain at least 10 digits')),

        position: yup
            .string()
            .trim()
            .typeError(t('Position of employee is mandatory!'))
            .min(2, t('Position must be at least 2 characters long!'))
            .required(t('Position of employee is mandatory!')),

        lastName: yup
            .string()
            .trim()
            .typeError(t('Surname is mandatory!'))
            .min(3, t('Surname must be at least 3 characters long!'))
            .required(t('Surname is mandatory!')),

        firstName: yup
            .string()
            .trim()
            .typeError(t('Name is mandatory!'))
            .min(3, t('Name must be at least 3 characters long!'))
            .required(t('Name is mandatory!')),
    });

    const handleChange = (e) => {
        setEmployeeData({
            ...employeeData,
            [e.target.name]: e.target.value,
        });
    };

    const editSchedule = (index, value) => {
        const newSchedule = [...schedule];
        newSchedule[index].hours = value;
        setSchedule(newSchedule);
    };

    const createEmployee = async () => {
        try {
            await validationSchema.validate({
                ...employeeData,
                schedule,
            });

            Employee[moduleType]
                .create({
                    ...employeeData,
                    employeeType,
                    schedule,
                })
                .then((res) => {
                    if (res.ok) {
                        enqueueSnackbar(t('The employee was successfully added!'), {
                            variant: 'success',
                        });

                        if (moduleType === 'pm') {
                            history.push(
                                projectManagement.base +
                                    projectManagement.projectManagementConfigurator.base +
                                    '?tab=0' +
                                    `&subTab=${employeeType === 'internal' ? 2 : 3}`,
                            );
                        } else if (moduleType === 'internalActivity') {
                            history.push(
                                internalActivity.base +
                                    internalActivity.planning.base +
                                    `?tab=${employeeType === 'internal' ? 1 : 2}`,
                            );
                        }
                    }
                });
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const editEmployee = async () => {
        try {
            await validationSchema.validate({
                ...employeeData,
                schedule,
            });

            Employee[moduleType]
                .edit({
                    ...employeeData,
                    schedule,
                })
                .then((res) => {
                    if (res.ok) {
                        enqueueSnackbar(t('The employee was successfully edited!'), {
                            variant: 'success',
                        });

                        if (moduleType === 'pm') {
                            history.push(
                                projectManagement.base +
                                    projectManagement.projectManagementConfigurator.base +
                                    '?tab=0' +
                                    `&subTab=${employeeType === 'internal' ? 2 : 3}`,
                            );
                        } else if (moduleType === 'internalActivity') {
                            history.push(
                                internalActivity.base +
                                    internalActivity.planning.base +
                                    `?tab=${employeeType === 'internal' ? 1 : 2}`,
                            );
                        }
                    }
                });
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <React.Fragment>
            <div style={{ maxWidth: '24rem' }}>
                <div className="relative mb-5 flex items-center gap-2">
                    <TextField
                        name="firstName"
                        label={t('Name')}
                        placeholder="Popescu"
                        value={employeeData.firstName}
                        onChange={handleChange}
                    />

                    <TextField
                        name="lastName"
                        label={t('Surname')}
                        placeholder="Andrei"
                        value={employeeData.lastName}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative mb-5">
                    <TextField
                        name="position"
                        label={t('Position')}
                        placeholder="Developer"
                        value={employeeData.position}
                        onChange={handleChange}
                    />
                </div>

                <div className="relative mb-5">
                    <TextField
                        name="phoneNumber"
                        label={t('Phone number')}
                        placeholder="0753176913"
                        value={employeeData.phoneNumber}
                        onChange={handleChange}
                    />
                </div>

                <div className="mb-10">
                    <TextField
                        name="email"
                        label="Email"
                        placeholder={t('client@domain.com')}
                        value={employeeData.email}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <h4 className="mb-10 text-2xl text-dark-text">{t('Scheduling hours worked by the employee in a week')}</h4>

            <div className="mb-20 flex w-full flex-wrap gap-4">
                {schedule.map((h, i) => (
                    <TextField
                        key={h.day}
                        label={t(h.day.charAt(0) + h.day.slice(1).toLowerCase())}
                        placeholder={t('8 hours')}
                        value={schedule[i].hours}
                        onChange={(e) => editSchedule(i, formatPositiveNumber(e.target.value))}
                        style={{ width: '50px' }}
                    />
                ))}
            </div>

            <Button startIcon={<AddIcon />} color="primary" onClick={employee.id ? editEmployee : createEmployee}>
                {employee.id ? t('Edit employee') : t('Create employee')}
            </Button>
        </React.Fragment>
    );
}

EmployeeForm.propTypes = {
    moduleType: PropTypes.oneOf(['pm', 'internalActivity']),
    employeeType: PropTypes.oneOf(['internal', 'external']).isRequired,
    employee: PropTypes.object,
};
