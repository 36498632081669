import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Trash } from 'lucide-react';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import PhotoIcon from '@material-ui/icons/Photo';

import { Button, TextField } from '@material-ui/core';

import ErrorButton from 'components/shared/error-button';
import FileUploadContainer from 'components/shared/file-upload-container';
import GroupsSelector from 'components/shared/groups-selector/groups-selector';
import Loading from 'components/shared/loading';
import UserContext from 'contexts/UserContext';
import DOMPurify from 'dompurify';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useConfirm from 'hooks/useConfirm';
import htmlToDraft from 'html-to-draftjs';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { Editor } from 'react-draft-wysiwyg';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { configurator } from 'routes';
import {
    errorHandling,
    formatDate,
    PHONE_NO_REGEX,
    uploadSingleFile,
} from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const UserDetails = ({ editableField, userInfo, noDate }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const confirm = useConfirm();

    const { user } = useContext(UserContext);

    const id = userInfo?.id;

    const [groups, setGroups] = useState([]);

    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');

    const [phone, setPhone] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [observations, setObservations] = useState('');
    const [campaigns, setCampaigns] = useState('');

    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const [imageBlob, setImageBlob] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleImageUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    enqueueSnackbar(t(message), { variant: 'error' });
                    return;
                }
                setImageBlob(blob);
            },
            'image',
        );
    };

    const htmlToDraftBlocks = (html) => {
        const blocksFromHtml = htmlToDraft(html);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap,
        );
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const [emailContent, setEmailContent] = useState(
        htmlToDraftBlocks(
            `
            <p style="color: rgb(0,0,0)">They will give you real-time access to a variety of important documents and information as follows:</p>
            <ul>
                <li style="color: rgb(0,0,0)">Wiki Project: Here you will find a complete history and all relevant documents for the project. Whenever you need a property deed, a planning certificate, an opinion or a decision, etc. you can locate and download them directly from this platform.</li>
                <li style="color: rgb(0,0,0)">RIEM Report: This report will contain all identified risks and measures taken to mitigate/eliminate them.</li>
                <li style="color: rgb(0,0,0)">Pipeline Activity: A dashboard showing all actions and their current status throughout the project.</li>
                <li style="color: rgb(0,0,0)">Project Gantt Chart: Will give you a detailed view of the project's schedule and progress.</li>
                <li style="color: rgb(0,0,0)">Financial Tools: Project P&L and CF: Detailed financial information on profit and loss and project cash flow.</li>
                <li style="color: rgb(0,0,0)">Other relevant information and documents that may be of interest to you.</li>
            </ul>
            `,
        ),
    );

    const schema = yup.object().shape({
        phone: yup
            .string()
            .trim()
            .required(t('Phone number field required!'))
            .matches(PHONE_NO_REGEX, t('Invalid phone number!'))
            .min(10, t('Phone number must be at least 10 digits')),
        email: yup
            .string()
            .trim()
            .required(t('Email field is required!'))
            .email(t('Email is invalid!')),
        userName: yup
            .string()
            .trim()
            .typeError(t('Username field is required!'))
            .min(3, t('User name must be at least 3 characters long!'))
            .max(30, t('User name is to long!'))
            .required(t('Username field is required!')),
        roleId: yup.string().typeError(t('Role selection field is mandatory!')),
    });

    const createUser = async () => {
        try {
            await schema.validate({ userName, email, phone, roleId });

            const reqBody = new FormData();
            reqBody.append(
                'data',
                JSON.stringify({
                    email,
                    observation: observations,
                    campaigns,
                    profile: {
                        name: userName,
                        phoneNo: phone,
                    },
                    emailContent: DOMPurify.sanitize(
                        draftToHtml(
                            convertToRaw(emailContent.getCurrentContent()),
                        ).replaceAll('color: currentcolor;', ''),
                    ),
                    groups,
                    roleId,
                }),
            );

            reqBody.append('profileImage', imageBlob);

            try {
                await API.post('/user', reqBody, {
                    'Content-Type': 'multipart/form-data',
                });
                enqueueSnackbar(t('User was successfully created!'), {
                    variant: 'success',
                });

                history.push(configurator.base + '?tab=Manage users');
            } catch (err) {
                enqueueSnackbar(
                    errorHandling(err).length > 100
                        ? errorHandling(err)
                        : t(errorHandling(err)),
                    {
                        variant: 'error',
                    },
                );
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err.errors);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const updateUser = async () => {
        try {
            await schema.validate({ userName, email, phone, roleId });

            const reqBody = new FormData();
            reqBody.append(
                'data',
                JSON.stringify({
                    data: {
                        email,
                        observation: observations,
                        campaigns,
                        profile: {
                            name: userName,
                            phoneNo: phone,
                        },
                        groups,
                        roleId,
                    },
                    id: id,
                }),
            );

            reqBody.append('profileImage', imageBlob);

            try {
                await API.put('/user', reqBody, {
                    'Content-Type': 'multipart/form-data',
                });

                enqueueSnackbar(t('User was successfully updated'), {
                    variant: 'success',
                });

                history.push(configurator.base + '?tab=Manage users');
            } catch (err) {
                console.error(err);
                enqueueSnackbar(
                    errorHandling(err).length > 100
                        ? errorHandling(err)
                        : t(errorHandling(err)),
                    {
                        variant: 'error',
                    },
                );
            } finally {
                setLoading(false);
            }
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteUser = async () => {
        try {
            setLoading(true);

            await API.delete('/user', { params: { id } });

            history.push(configurator.base + '?tab=users');
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
        } finally {
            setLoading(false);
        }
    };

    /**
     * Setting user info
     */
    useEffect(() => {
        (async () => {
            try {
                const response = await API.get('/userRoles', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                    },
                });

                setRoles(response.data.roles);

                if (userInfo) {
                    setUserName(userInfo.profile.name);
                    setEmail(userInfo.email);
                    setPhone(userInfo.profile.phoneNo);
                    setCreatedAt(userInfo.profile.createAt);
                    setObservations(userInfo.observation);
                    setCampaigns(userInfo.campaigns);
                    setGroups(userInfo.groups.map((group) => group.groupId));
                    setRoleId(userInfo.roleId);
                }
            } catch (error) {
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, [userInfo]);

    const canEditUserRole = user.id !== userInfo?.id;

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Fragment>
                    <div className="mt-10 flex flex-col justify-between gap-10">
                        <div className="flex gap-10 xl:flex-col xl:gap-5">
                            <div className="w-full max-w-md flex-shrink-0">
                                <h4 className="mb-10 text-2xl text-dark-text">
                                    {t('User')}
                                </h4>
                                <div className="relative mb-5">
                                    <GroupsSelector
                                        groups={groups}
                                        setGroups={setGroups}
                                        disabled={editableField}
                                    />
                                </div>
                                <div className="relative mb-5">
                                    <TextField
                                        label={t('User name')}
                                        placeholder={t('User one name')}
                                        value={userName}
                                        disabled={editableField}
                                        onChange={(e) =>
                                            setUserName(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="relative mb-5">
                                    <LabelWrapper label={t('Role')}>
                                        <Dropdown
                                            disabled={!canEditUserRole}
                                            options={roles.map((role) =>
                                                t(role.name),
                                            )}
                                            selectedOption={(() => {
                                                const index = roles.findIndex(
                                                    (role) =>
                                                        role.id === roleId,
                                                );

                                                return index >= 0
                                                    ? index
                                                    : null;
                                            })()}
                                            setSelectedOption={(i) =>
                                                setRoleId(roles[i].id)
                                            }
                                        />
                                    </LabelWrapper>
                                </div>
                                <div
                                    className={`relative mb-5 ${id ? 'cursor-not-allowed' : ''}`}
                                >
                                    <TextField
                                        className={`${id ? 'pointer-events-none' : ''}`}
                                        type="email"
                                        label="Email"
                                        placeholder={t('name@email.com')}
                                        value={email}
                                        disabled={editableField}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="relative mb-5">
                                    <TextField
                                        label={t('Phone')}
                                        placeholder="0752681439"
                                        value={phone}
                                        disabled={editableField}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                    />
                                </div>

                                <div className="relative mb-5">
                                    <TextField
                                        label={t('Observations')}
                                        placeholder={t('Observations...')}
                                        value={observations}
                                        disabled={editableField}
                                        multiline
                                        rows={3}
                                        onChange={(e) =>
                                            setObservations(e.target.value)
                                        }
                                    />
                                </div>

                                <div className="relative mb-5">
                                    <TextField
                                        label={t('Campaigns')}
                                        placeholder={`${t('Campaigns')}...`}
                                        value={campaigns}
                                        disabled={editableField}
                                        multiline
                                        rows={3}
                                        onChange={(e) =>
                                            setCampaigns(e.target.value)
                                        }
                                    />
                                </div>

                                {!editableField && (
                                    <div
                                        className={`mb-5 ${noDate ? 'xl:mb-0' : ''}`}
                                    >
                                        <LabelWrapper
                                            label={t('Profile image')}
                                        >
                                            <FileUploadContainer
                                                onUpload={handleImageUpload}
                                            >
                                                <Button
                                                    fullWidth
                                                    startIcon={<PhotoIcon />}
                                                >
                                                    {(() => {
                                                        if (
                                                            userInfo ==
                                                                undefined &&
                                                            imageBlob === null
                                                        ) {
                                                            return t(
                                                                'Add profile image',
                                                            );
                                                        } else if (
                                                            userInfo ==
                                                                undefined &&
                                                            imageBlob
                                                        ) {
                                                            return (
                                                                imageBlob.name.substr(
                                                                    0,
                                                                    10,
                                                                ) + '...'
                                                            );
                                                        } else if (
                                                            userInfo &&
                                                            imageBlob === null
                                                        ) {
                                                            return t(
                                                                'Change profile image',
                                                            );
                                                        } else {
                                                            return (
                                                                imageBlob.name.substr(
                                                                    0,
                                                                    10,
                                                                ) + '...'
                                                            );
                                                        }
                                                    })()}
                                                    {/* {userInfo == undefined ? t('Add profile image') : t('Change profile image')} */}
                                                </Button>
                                            </FileUploadContainer>
                                        </LabelWrapper>
                                    </div>
                                )}

                                {!noDate ? (
                                    <div className="relative mb-5 xl:mb-0">
                                        <TextField
                                            name={'registration date'}
                                            disabled="true"
                                            label={t('Registration date')}
                                            value={formatDate(createdAt)}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            {!id && (
                                <div className="relative mb-5 h-full max-w-7xl bg-layout-light">
                                    <LabelWrapper
                                        label={t('Additional email content')}
                                    >
                                        <Editor
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            editorState={emailContent}
                                            onEditorStateChange={
                                                setEmailContent
                                            }
                                            toolbarStyle={{
                                                margin: 2,
                                                padding: 2,
                                                borderRadius: 0,
                                                color: 'black',
                                                border: 'none',
                                                backgroundColor: 'inherit',
                                            }}
                                            wrapperStyle={{
                                                width: '100%',
                                            }}
                                            editorStyle={{
                                                borderRadius: 0,
                                                backgroundColor: 'white',
                                                padding: '0px 8px',
                                                color: 'black',
                                                minHeight: '20rem',
                                                lineHeight: 1.2,
                                            }}
                                            toolbar={{
                                                options: [
                                                    'inline',
                                                    'blockType',
                                                    'fontSize',
                                                    'list',
                                                    'textAlign',
                                                    'colorPicker',
                                                    'link',
                                                    'remove',
                                                    'history',
                                                ],
                                            }}
                                        />
                                    </LabelWrapper>
                                </div>
                            )}
                        </div>
                    </div>

                    {!editableField && (
                        <div className="mt-10 flex gap-3">
                            {id ? (
                                <>
                                    <Button
                                        type="submit"
                                        startIcon={<CheckIcon />}
                                        color="primary"
                                        onClick={() => {
                                            updateUser();
                                            setLoading(true);
                                        }}
                                    >
                                        {t('Update profile info')}
                                    </Button>
                                    {user.id !== userInfo?.id && (
                                        <ErrorButton
                                            startIcon={<Trash />}
                                            onClick={() =>
                                                confirm(
                                                    t(
                                                        'Are you sure you want to delete this user?',
                                                    ),
                                                    () => handleDeleteUser(),
                                                )
                                            }
                                        >
                                            {t('Delete user')}
                                        </ErrorButton>
                                    )}
                                </>
                            ) : (
                                <Button
                                    startIcon={<AddIcon />}
                                    color="primary"
                                    onClick={() => {
                                        setLoading(true);
                                        createUser();
                                    }}
                                >
                                    {t('Add user')}
                                </Button>
                            )}
                        </div>
                    )}
                </Fragment>
            )}
        </>
    );
};

UserDetails.propTypes = {
    editableField: PropTypes.bool,
    submit: PropTypes.element,
    id: PropTypes.object,
    noBtn: PropTypes.bool,
    userInfo: PropTypes.object,
    noDate: PropTypes.bool,
};

UserDetails.defaultProps = {
    editableField: true,
    submit: null,
    id: null,
    noBtn: null,
    userInfo: null,
    noDate: null,
};

export default UserDetails;
