import React, { memo, useContext, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';

import { Button, TextField } from '@material-ui/core';

import CustomModal from 'components/modals/custom_modal';
import BasicTooltip from 'components/shared/basic-tooltip';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import EditElementModal from 'pages/configurator/documents_configurator/documents_items/edit-element-modal';
import PropTypes from 'prop-types';
import { ActionButton, DatePicker, Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

import ProjectStructureActivity from './project-structure-activity';
import { ProjectContext } from './project-structure-form';

/**
 *  ? Project activities memoization -> I added the memoization in order to avoid unnecessary rerenders
 *  ? In this screen we can have a lot of components and we need memoization in order to avoid an expensive render
 *  ! If in the future more props will be added to this components, you will need to memoize them using a React mechanism such as useMemo or useCallback in order, and check if they changed between rerenders in memo second parameter
 *  ! Props such as object or arrays who can change will be checked using lodash equality function, while the primitives and memoized arrays and objects that will never change will be checked using === operator
 *  !!! All components that are defined using using memo will have this rules applied !!!
 */
const ProjectStructureActivitiesWrapper = memo(
    function ProjectStructureActivitiesWrapper({ activities, milestone }) {
        return (
            activities.length > 0 && (
                <div className="flex flex-col gap-8">
                    {activities.map((activity, index) => (
                        <ProjectStructureActivity
                            key={activity.id}
                            index={index}
                            activity={activity}
                            milestone={milestone}
                        />
                    ))}
                </div>
            )
        );
    },
    (prev, next) => _.isEqual(prev.activities, next.activities) && _.isEqual(prev.milestone, next.milestone),
);

ProjectStructureActivitiesWrapper.propTypes = {
    activities: PropTypes.array,
    milestone: PropTypes.object,
};

ProjectStructureActivitiesWrapper.defaultProps = {
    activities: [],
    milestone: {},
};

/**
 * ? This component renders one section of the project structure
 * @param {object} props - Component properties
 * @param {array} props.allMilestones - We use this array to find the info about the milestone on witch the section is based on (if is based on a milestone)
 * @param {object} props.milestone - Props of the section
 * @param {number} props.index - Index of the section
 * @param {array} props.sections - Array of all the sections
 * @returns
 */
const ProjectStructureMilestone = ({ allMilestones, milestone, index, sections }) => {
    const { t } = useTranslation();
    const {
        onUpdateMilestone,
        onDeleteMilestone,
        onAddActivity,
        projectStructureInfo,
        canEdit,
        onUpdateMilestoneDate,
        onUpdateMilestoneDependency,
    } = useContext(ProjectContext);

    const statuses = ['NEW', 'IN_PROGRESS', 'DONE'];
    const [open, setOpen] = useState(false);
    const handleCloseModal = () => setOpen(false);
    const handleOpenModal = () => setOpen(true);

    const handleUpdateMilestone = (e) => onUpdateMilestone(milestone.id, e.target.name, e.target.value);
    const handleUpdateMilestoneDescription = (value) => {
        onUpdateMilestone(milestone.id, 'description', value);
        handleCloseModal();
    };

    const handleDeleteMilestone = () => onDeleteMilestone(milestone.id, milestone.referenceKey);

    return (
        <>
            <div
                className="relative"
                style={{
                    zIndex: sections.length - index + 1,
                }}
            >
                <div className="flex flex-col gap-6 rounded-sm bg-layout-lighter p-2">
                    <div className="flex items-center justify-between gap-2">
                        <h3>
                            {t('Section')} {index + 1}
                            {milestone.milestoneId
                                ? ` (${allMilestones.find((m) => m.id === milestone.milestoneId)?.name})`
                                : ''}
                        </h3>
                        {canEdit && (
                            <div
                                className="transition:opacity flex h-11 w-11 flex-shrink-0 cursor-pointer items-center justify-center text-center text-main-text opacity-50 hover:opacity-100 sm:self-start"
                                onClick={handleDeleteMilestone}
                            >
                                <CloseIcon className="text-error" style={{ fontSize: '2rem' }} />
                            </div>
                        )}
                    </div>

                    <div className="flex w-full gap-2 md:flex-col">
                        <LabelWrapper label={t('Name')}>
                            <TextField
                                disabled={!canEdit}
                                name="name"
                                placeholder={t('Name')}
                                value={milestone.name}
                                onChange={handleUpdateMilestone}
                            />
                        </LabelWrapper>
                        <div className="inline-flex">
                            <LabelWrapper label={t('Start date')}>
                                <DatePicker
                                    disabled={!canEdit}
                                    date={milestone.startDate}
                                    setDate={(value) => onUpdateMilestoneDate(value, index)}
                                />
                            </LabelWrapper>
                        </div>
                        <div className="inline-flex">
                            <LabelWrapper label={'Status'}>
                                <Dropdown
                                    disabled={!canEdit}
                                    icon={<EditIcon />}
                                    placeholder={'Status'}
                                    options={statuses.map((status) => t(status))}
                                    selectedOption={statuses.findIndex((status) => milestone.status === status)}
                                    setSelectedOption={(e) =>
                                        handleUpdateMilestone({
                                            target: {
                                                name: 'status',
                                                value: statuses[e],
                                            },
                                        })
                                    }
                                />
                            </LabelWrapper>
                        </div>
                        {index !== 0 && (
                            <div className="inline-flex items-center gap-2">
                                <LabelWrapper label={t('Depends on')}>
                                    <Dropdown
                                        disabled={!canEdit}
                                        icon={<SettingsEthernetIcon />}
                                        placeholder={t('Depends on')}
                                        options={sections.map((m, index) => m.name || `${t('Section')} ${index + 1}`)}
                                        selectedOption={(() => {
                                            const selection = sections.findIndex(
                                                (m) => m.referenceKey === milestone.dependency,
                                            );

                                            return selection > -1 ? selection : null;
                                        })()}
                                        setSelectedOption={(rIndex) => onUpdateMilestoneDependency(index, rIndex)}
                                        hiddenOptions={Array.from(
                                            { length: sections.length - index },
                                            (_, i) => sections.length - (i + 1),
                                        )}
                                    />
                                </LabelWrapper>

                                <div className="mt-6">
                                    <ActionButton
                                        disabled={!canEdit}
                                        icon={<DeleteIcon />}
                                        color={milestone.dependency !== null ? 'var(--error)' : 'var(--disabled)'}
                                        onClick={() =>
                                            handleUpdateMilestone({
                                                target: {
                                                    name: 'dependency',
                                                    value: null,
                                                },
                                            })
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={`flex flex-col gap-2 ${milestone.activities.length > 0 ? 'mb-12' : ''}`}>
                        {milestone.description && (
                            <LabelWrapper label={t('Description')}>
                                <iframe
                                    className="h-40 w-full rounded-md border border-layout-transparent bg-white p-2"
                                    srcDoc={`<base target="_blank" /> ${DOMPurify.sanitize(milestone.description)}`}
                                />
                            </LabelWrapper>
                        )}

                        <div
                            className={`inline-flex ${
                                milestone.description
                                    ? 'self-end'
                                    : 'w-full justify-center rounded-md bg-layout-transparent p-2'
                            }`}
                        >
                            <Button color="primary" onClick={handleOpenModal} disabled={!canEdit}>
                                {t(projectStructureInfo ? 'Edit description' : 'Add description')}
                            </Button>
                        </div>
                    </div>

                    <ProjectStructureActivitiesWrapper activities={milestone.activities} milestone={milestone} />

                    {canEdit && (
                        <div className="mt-6 inline-flex">
                            <BasicTooltip tip={t('Add activity')}>
                                <div
                                    className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-layout-transparent text-center text-main-text transition-colors hover:bg-layout-transparent-dark"
                                    onClick={() => onAddActivity(milestone.id)}
                                >
                                    <AddIcon className="text-buttons-text" />
                                </div>
                            </BasicTooltip>
                        </div>
                    )}
                </div>
            </div>
            {open && (
                <CustomModal open={open}>
                    <EditElementModal
                        onClose={handleCloseModal}
                        value={milestone.description}
                        onFinish={handleUpdateMilestoneDescription}
                    />
                </CustomModal>
            )}
        </>
    );
};

ProjectStructureMilestone.propTypes = {
    milestone: PropTypes.object,
    index: PropTypes.number,
    allMilestones: PropTypes.array,
    sections: PropTypes.array,
};

ProjectStructureMilestone.defaultProps = {
    milestone: {},
    index: 0,
    allMilestones: [],
    sections: [],
};

export default memo(
    ProjectStructureMilestone,
    (prev, next) =>
        prev.index === next.index &&
        prev.allMilestones === next.allMilestones &&
        _.isEqual(prev.sections, next.sections) &&
        _.isEqual(prev.milestone, next.milestone),
);
