import React from 'react';

import PropTypes from 'prop-types';

import PipelineItem from './item/pipeline-item';
import PipelineSortLabel from './pipeline-sort-label';

const PipelineCompactContent = ({ columns, sortKeys, itemProps, data }) => {
    return (
        <div className="grid grid-cols-pipeline-auto gap-8 sm:grid-cols-1">
            {sortKeys.map((sortKey, index) =>
                (() => {
                    if (!sortKey.render) return null;

                    return (
                        <div
                            key={sortKey.key}
                            className={`col-span-full grid gap-6 ${index !== sortKeys.length - 1 ? 'mb-32' : ''}`}
                            style={{
                                gridTemplateColumns: 'subgrid',
                            }}
                        >
                            <div className="col-span-full">
                                <PipelineSortLabel sortKey={sortKey} />
                            </div>
                            <div
                                key={sortKey.key}
                                className="col-span-full grid gap-6"
                                style={{ gridTemplateColumns: 'subgrid' }}
                            >
                                {(() => {
                                    const sortKeyData = data.content[sortKey.key].content;

                                    if (sortKeyData.length > 0) {
                                        return sortKeyData.map((item) => {
                                            const column = columns.find(
                                                (column) => column.key === item.metadata.pipelineStatus,
                                            );

                                            return (
                                                <div key={item.id} className="place-content-start">
                                                    <PipelineItem itemProps={itemProps} column={column} item={item} />
                                                </div>
                                            );
                                        });
                                    }

                                    return null;
                                })()}
                            </div>
                        </div>
                    );
                })(),
            )}
        </div>
    );
};

PipelineCompactContent.propTypes = {
    columns: PropTypes.array,
    sortKeys: PropTypes.array,
    data: PropTypes.object,
    itemProps: PropTypes.object,
};

PipelineCompactContent.defaultProps = {
    columns: [],
    sortKeys: [],
    data: {},
    itemProps: {},
};

export default PipelineCompactContent;
