import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
    ArrowDown,
    ArrowRightLeft,
    ArrowUp,
    BarChartHorizontal,
    BookUser,
    Boxes,
    BrainCircuit,
    BriefcaseBusiness,
    ChartNoAxesCombined,
    ClipboardType,
    FileChartColumn,
    FileCheck,
    FileCog,
    FilePenLine,
    FilePieChart,
    FilePlus2,
    FileStack,
    FileText,
    FolderCog,
    GitFork,
    Goal,
    Handshake,
    HelpCircle,
    KanbanSquare,
    Layers,
    LayoutGrid,
    List,
    ListCollapse,
    ListTodo,
    ListTree,
    MapPin,
    MonitorCog,
    MousePointer,
    Package,
    ReceiptEuro,
    ReceiptText,
    Search,
    Settings,
    TrendingUpDown,
    User,
    UserCheck,
    Users,
    Wallet,
} from 'lucide-react';

import SupportModal from 'components/modals/modal';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Routes
import {
    accountancy,
    affiliate,
    configurator,
    crm,
    internalActivity,
    kpi,
    projectInManagement,
    projectManagement,
    quotes,
    resourceModule,
} from 'routes';

const mappedModulesPaths = {
    main: '/',
    quotes: quotes.base,
    projectManagement: projectManagement.base,
    project: projectInManagement.base,
    affiliateProject: affiliate.projectManagement.project,
    resourceManagement: resourceModule.base,
};

const NavPropsContext = createContext();

export const NavPropsProvider = ({ children }) => {
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);

    const { setGlobalModalOpen, setGlobalModalChildren } =
        useContext(GlobalContext);

    const history = useHistory();
    const location = useLocation();
    const [navName, changeNavName] = useState(null);

    const perms = {
        internalActivity: [
            checkPerm([
                {
                    permissionId: '1',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '2',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '3',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '4',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '42',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        crm: [
            checkPerm([
                {
                    permissionId: '6',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '7',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '8',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '9',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '10',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '46',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '47',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        offer: [
            checkPerm([
                {
                    permissionId: '9',
                    permissionType: 'ALL',
                },
            ]),
            checkPerm([
                {
                    permissionId: '18',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '49',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        pm: [
            checkPerm([
                {
                    permissionId: '59',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '58',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '50',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '56',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '57',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '61',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        project: [
            checkPerm([
                {
                    permissionId: '51',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '52',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '53',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '60',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '48',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '54',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        rm: [],
        accountancy: [
            checkPerm([
                {
                    permissionId: '35',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '36',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '37',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '38',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '39',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '40',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '41',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        kpi: [
            checkPerm([
                {
                    permissionId: '62',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '63',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '64',
                    permissionType: 'VIEW',
                },
            ]),
        ],
        config: [
            checkPerm([
                {
                    permissionId: '17',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '11',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '13',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '14',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '15',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '33',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '34',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '35',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '43',
                    permissionType: 'VIEW',
                },
            ]),
            checkPerm([
                {
                    permissionId: '55',
                    permissionType: 'VIEW',
                },
            ]),
        ],
    };

    const handleFilterNavItems = (items) =>
        items.reduce((acc, curr) => {
            if (!curr.render) return acc;

            const newItem = { ...curr };
            if (newItem.subItems)
                newItem.subItems = handleFilterNavItems(newItem.subItems);

            acc.push(newItem);
            return acc;
        }, []);

    const mainMenuProps = {
        modulePath: mappedModulesPaths['main'],
        navItems: [
            {
                name: t('Internal Activity'),
                icon: <ListTree className="li-icon-width" />,
                render: perms.internalActivity.includes(true),
                subItems: [
                    {
                        name: t('Timeline Activities'),
                        icon: <ListTodo className="li-icon-width" />,
                        render: perms.internalActivity[0],
                        route:
                            internalActivity.base +
                            internalActivity.timeline.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Tasks pipeline'),
                        icon: <KanbanSquare className="li-icon-width" />,
                        render: perms.internalActivity[0],
                        route:
                            internalActivity.base +
                            internalActivity.tasksPipeline.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Milestones pipeline'),
                        icon: <KanbanSquare className="li-icon-width" />,
                        render: perms.internalActivity[1],
                        route:
                            internalActivity.base +
                            internalActivity.pipeline.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: 'Planning Overview',
                        icon: <BarChartHorizontal className="li-icon-width" />,
                        render: perms.internalActivity[2],
                        route:
                            internalActivity.base +
                            internalActivity.planning.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Partners'),
                        icon: <BookUser className="li-icon-width" />,
                        render: perms.internalActivity[3],
                        route:
                            internalActivity.base +
                            internalActivity.partners.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Project structures'),
                        icon: <GitFork className="li-icon-width" />,
                        render: perms.internalActivity[4],
                        route:
                            internalActivity.base +
                            internalActivity.projectStructure.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                ],
            },
            {
                name: 'CRM',
                icon: <Users className="li-icon-width" />,
                render: perms.crm.includes(true),
                subItems: [
                    {
                        name: t('contacts'),
                        icon: <User className="li-icon-width" />,
                        render: perms.crm[0] || perms.crm[5] || perms.crm[6],
                        subItems: [
                            {
                                name: t('Manage Contacts'),
                                icon: <List className="li-icon-width" />,
                                render: perms.crm[0],
                                route:
                                    crm.base +
                                    crm.contacts.base +
                                    crm.contacts.read,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                            {
                                name: t('Leads report'),
                                icon: <FileText className="li-icon-width" />,
                                render: perms.crm[5],
                                route:
                                    crm.base +
                                    crm.contacts.base +
                                    crm.contacts.report.base +
                                    crm.contacts.report.leads,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                            {
                                name: t('Agents report'),
                                icon: <FileText className="li-icon-width" />,
                                render: perms.crm[6],
                                route:
                                    crm.base +
                                    crm.contacts.base +
                                    crm.contacts.report.base +
                                    crm.contacts.report.agents,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                        ],
                    },
                    {
                        name: t('contact-activity'),
                        icon: <User className="li-icon-width" />,
                        render: false,
                        route:
                            crm.base +
                            crm.contactActivity.base +
                            '/' +
                            location.pathname.split('/')[3],
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Catalogue'),
                        icon: <Package className="li-icon-width" />,
                        render: perms.crm[1],
                        route: crm.base + crm.estate.base + crm.estate.read,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('contracts'),
                        icon: <FileCheck className="li-icon-width" />,
                        render: perms.crm[2],
                        subItems: [
                            {
                                name: t('Contracts list'),
                                icon: <List className="li-icon-width" />,
                                render: true,
                                route:
                                    crm.base +
                                    crm.contracts.base +
                                    crm.contracts.read,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                            {
                                name: t('Contracts pipeline'),
                                icon: (
                                    <KanbanSquare className="li-icon-width" />
                                ),
                                render: true,
                                route:
                                    crm.base +
                                    crm.contracts.base +
                                    crm.contracts.pipeline,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                        ],
                    },
                    {
                        name: t('Offers'),
                        icon: <Handshake className="li-icon-width" />,
                        subModulePath: mappedModulesPaths['quotes'],
                        inDashboard: true,
                        render: perms.crm[3],
                        route:
                            quotes.base +
                            quotes.biddingHistory.base +
                            quotes.biddingHistory.read,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Reports'),
                        icon: <ChartNoAxesCombined className="li-icon-width" />,
                        render: perms.crm[4],
                        route: crm.base + crm.reports.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t("Sales KPI's"),
                        icon: <Goal className="li-icon-width" />,
                        render: false,
                        route: null,
                        onRoute: function () {
                            console.log("Sales KPI's");
                        },
                    },
                    {
                        name: t("Sales Reporting KPI's"),
                        icon: <FilePieChart className="li-icon-width" />,
                        render: false,
                        route: null,
                        onRoute: function () {
                            console.log("Sales Reporting KPI's");
                        },
                    },
                ],
            },
            {
                name: 'Module',
                icon: <LayoutGrid className="li-icon-width" />,
                render: perms.pm.includes(true) || perms.rm.includes(true),
                subItems: [
                    {
                        name: t('Project Management Module'),
                        subModulePath: mappedModulesPaths['projectManagement'],
                        inDashboard: true,
                        icon: <FolderCog className="li-icon-width" />,
                        render: perms.pm.includes(true),
                        route: projectManagement.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Resource Management Module'),
                        subModulePath: mappedModulesPaths['resourceManagement'],
                        inDashboard: true,
                        icon: <Layers className="li-icon-width" />,
                        render: perms.pm.includes(true),
                        // render:  perms.rm.includes(true), TODO: Change to this when permission will be implemented
                        route: resourceModule.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                ],
            },
            // {
            //     name: t('Project Management Module'),
            //     icon: <FolderCog className="li-icon-width" />,
            //     render: isAffiliate && perms.modules[0],
            //     subItems: [
            //         {
            //             name: t('Projects'),
            //             subModulePath: mappedModulesPaths['affiliateProject'],
            //             inDashboard: false,
            //             icon: <MousePointer className="li-icon-width" />,
            //             render: true,
            //             route:
            //                 affiliate.base +
            //                 affiliate.projectManagement.projects,
            //             onRoute: function () {
            //                 history.push(this.route);
            //             },
            //         },
            //         {
            //             name: t('Planning Overview'),
            //             render: true,
            //             icon: <BarChartHorizontal className="li-icon-width" />,
            //             route:
            //                 affiliate.base +
            //                 affiliate.projectManagement.overview,
            //             onRoute: function () {
            //                 history.push(this.route);
            //             },
            //         },
            //     ],
            // },
            {
                name: t('Financials'),
                icon: <Wallet className="li-icon-width" />,
                render: perms.accountancy.includes(true),
                subItems: [
                    {
                        name: t('Expenses nomenclature'),
                        icon: <ArrowDown className="li-icon-width" />,
                        render: perms.accountancy[0],
                        route:
                            accountancy.base +
                            accountancy.expenseNomenclature.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Revenues nomenclature'),
                        icon: <ArrowUp className="li-icon-width" />,
                        render: perms.accountancy[1],
                        route:
                            accountancy.base +
                            accountancy.revenueNomenclature.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Accounting accounts'),
                        icon: <List className="li-icon-width" />,
                        render: perms.accountancy[2],
                        route:
                            accountancy.base +
                            accountancy.accountingAccounts.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Contract type nomenclature'),
                        icon: <FileCog className="li-icon-width" />,
                        render: perms.accountancy[3],
                        route:
                            accountancy.base +
                            accountancy.contractTypeNomenclature.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Invoices'),
                        icon: <ReceiptEuro className="li-icon-width" />,
                        render: perms.accountancy[4],
                        route: accountancy.base + accountancy.invoice.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: `GFT - ${t('Financial Tool')}`,
                        icon: <FileChartColumn className="li-icon-width" />,
                        render:
                            perms.accountancy[4] ||
                            perms.accountancy[5] ||
                            perms.accountancy[6],
                        subItems: [
                            {
                                name: t('P&L'),
                                icon: (
                                    <TrendingUpDown className="li-icon-width" />
                                ),
                                render: perms.accountancy[5],
                                route: accountancy.base + accountancy.PL.base,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                            {
                                name: t('Cashflow'),
                                icon: (
                                    <ArrowRightLeft className="li-icon-width" />
                                ),
                                render: perms.accountancy[6],
                                route:
                                    accountancy.base +
                                    accountancy.cashflow.base,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                            {
                                name: t('Invoices report'),
                                icon: <ReceiptText className="li-icon-width" />,
                                render: perms.accountancy[4],
                                route:
                                    accountancy.base +
                                    accountancy.report.base +
                                    accountancy.report.invoices,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                        ],
                    },
                ],
            },
            {
                name: t("KPI's"),
                icon: <Goal className="li-icon-width" />,
                render: perms.kpi.includes(true),
                subItems: [
                    {
                        name: t("Personal KPI's"),
                        icon: <UserCheck className="li-icon-width" />,
                        render: perms.kpi[0],
                        route: kpi.base + kpi.personal.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t("CRM KPI's"),
                        icon: <ChartNoAxesCombined className="li-icon-width" />,
                        render: perms.kpi[1],
                        route: kpi.base + kpi.crm.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t("KPI's nomenclature"),
                        icon: <ListCollapse className="li-icon-width" />,
                        render: perms.kpi[2],
                        route: kpi.base + kpi.nomenclature.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                ],
            },
            {
                name: t('Accounting'),
                icon: <FilePenLine className="li-icon-width" />,
                render: false,
                route: null,
                onRoute: function () {
                    console.log('Accounting');
                },
            },
            {
                name: t('Business Intelligence'),
                icon: <BrainCircuit className="li-icon-width" />,
                render: false,
                route: null,
                onRoute: function () {
                    console.log('Business Intelligence');
                },
            },
            {
                name: t('ERP Configurator'),
                icon: <Settings className="li-icon-width" />,
                render: perms.config.includes(true),
                route: configurator.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('Technical support'),
                icon: <HelpCircle className="li-icon-width" />,
                render: true,
                route: null,
                onRoute: function () {
                    setGlobalModalChildren(<SupportModal />);
                    setGlobalModalOpen(true);
                },
            },
        ],
    };

    // const affiliateProjectMenuProps = {
    //     moduleName: t('Project'),
    //     backUrl: mappedModulesPaths['main'],
    //     modulePath: mappedModulesPaths['affiliateProject'],
    //     parentModulePath: mappedModulesPaths['main'],
    //     navItems: [
    //         {
    //             name: t('Wikis'),
    //             icon: <ClipboardType className="li-icon-width" />,
    //             render: true,
    //             route:
    //                 affiliate.base +
    //                 affiliate.projectManagement.project +
    //                 '/' +
    //                 location.pathname.split('/')[3] +
    //                 affiliate.projectWikis.base,
    //             onRoute: function () {
    //                 history.push(this.route);
    //             },
    //         },
    //         {
    //             name: t('Tasks'),
    //             icon: <ListTodo className="li-icon-width" />,
    //             render: true,
    //             route:
    //                 affiliate.base +
    //                 affiliate.projectManagement.project +
    //                 '/' +
    //                 location.pathname.split('/')[3] +
    //                 affiliate.projectTasks.base,
    //             onRoute: function () {
    //                 history.push(this.route);
    //             },
    //         },
    //         {
    //             name: t('Planning Overview'),
    //             icon: <BarChartHorizontal className="li-icon-width" />,
    //             render: true,
    //             route:
    //                 affiliate.base +
    //                 affiliate.projectManagement.project +
    //                 '/' +
    //                 location.pathname.split('/')[3] +
    //                 affiliate.planingOverviewOnProject.base,
    //             onRoute: function () {
    //                 history.push(this.route);
    //             },
    //         },
    //     ],
    // };

    const quotesMenuProps = {
        moduleName: t('Offer'),
        backUrl: mappedModulesPaths['main'],
        modulePath: mappedModulesPaths['quotes'],
        parentModulePath: mappedModulesPaths['main'],
        navItems: [
            {
                name: t('Offer history'),
                icon: <FileStack className="li-icon-width" />,
                render: perms.crm[3],
                route:
                    quotes.base +
                    quotes.biddingHistory.base +
                    quotes.biddingHistory.read,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('Offers pipeline'),
                icon: <KanbanSquare className="li-icon-width" />,
                render: perms.crm[3],
                route:
                    quotes.base +
                    quotes.biddingPipeline.base +
                    quotes.biddingPipeline.read,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('New offer'),
                icon: <FilePlus2 className="li-icon-width" />,
                render: perms.offer[0],
                route:
                    quotes.base +
                    quotes.newBidding.base +
                    quotes.newBidding.read,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('Article nomenclature'),
                icon: <List className="li-icon-width" />,
                render: perms.offer[1] || perms.offer[2],
                route:
                    quotes.base +
                    quotes.nomenclature.base +
                    quotes.nomenclature.read,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('Stocks report'),
                icon: <FileText className="li-icon-width" />,
                render: perms.offer[2],
                route: quotes.base + quotes.report.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
        ],
    };

    const projectManagementMenuProps = {
        moduleName: t('Project Management'),
        backUrl: mappedModulesPaths['main'],
        modulePath: mappedModulesPaths['projectManagement'],
        parentModulePath: mappedModulesPaths['main'],
        navItems: [
            {
                name: t('Projects'),
                subModulePath: mappedModulesPaths['project'],
                inDashboard: false,
                icon: <MousePointer className="li-icon-width" />,
                render: perms.pm[0],
                route:
                    projectManagement.base +
                    projectManagement.projectsManagement.base +
                    projectManagement.projectsManagement.read,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('Planning Overview'),
                icon: <BarChartHorizontal className="li-icon-width" />,
                render: perms.pm[1],
                route:
                    projectManagement.base +
                    projectManagement.projectsPlaningOverview.base +
                    projectManagement.projectsPlaningOverview.read,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('Pipeline Projects'),
                icon: <KanbanSquare className="li-icon-width" />,
                render: perms.pm[2],
                route:
                    projectManagement.base +
                    projectManagement.projectsPipeline.base +
                    projectManagement.projectsPipeline.read,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: 'Financial Tools',
                icon: <FileChartColumn className="li-icon-width" />,
                render: perms.pm[3] || perms.pm[4],
                subItems: [
                    {
                        name: t('P&L'),
                        icon: <TrendingUpDown className="li-icon-width" />,
                        render: perms.pm[3],
                        route:
                            projectManagement.base +
                            projectManagement.projectsPL.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Cashflow'),
                        icon: <ArrowRightLeft className="li-icon-width" />,
                        render: perms.pm[4],
                        route:
                            projectManagement.base +
                            projectManagement.projectsCashflow.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                ],
            },
            {
                name: 'Configurator',
                icon: <Settings className="li-icon-width" />,
                render: perms.pm[5],
                route:
                    projectManagement.base +
                    projectManagement.projectManagementConfigurator.base +
                    projectManagement.projectManagementConfigurator.read,
                onRoute: function () {
                    history.push(this.route);
                },
            },
        ],
    };

    const projectMenuProps = {
        moduleName: t('Project'),
        backUrl: mappedModulesPaths['projectManagement'],
        modulePath: mappedModulesPaths['project'],
        parentModulePath: mappedModulesPaths['projectManagement'],
        navItems: [
            {
                name: t('Wikis'),
                icon: <ClipboardType className="li-icon-width" />,
                render: perms.project[0],
                route:
                    projectInManagement.base +
                    '/' +
                    location.pathname.split('/')[2] +
                    projectInManagement.wiki.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: 'Task Management',
                icon: <ListTodo className="li-icon-width" />,
                render: perms.project[1],
                route:
                    projectInManagement.base +
                    '/' +
                    location.pathname.split('/')[2] +
                    projectInManagement.projectsTaskManagement.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('Tasks pipeline'),
                icon: <KanbanSquare className="li-icon-width" />,
                render: perms.project[2],
                route:
                    projectInManagement.base +
                    '/' +
                    location.pathname.split('/')[2] +
                    projectInManagement.projectsTasksPipeline.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: 'Planning Overview',
                icon: <BarChartHorizontal className="li-icon-width" />,
                render: perms.pm[1],
                route:
                    projectInManagement.base +
                    '/' +
                    location.pathname.split('/')[2] +
                    projectInManagement.planingOverviewOnProject.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: `CPI - ${t('Client Interactions')}`,
                icon: <BriefcaseBusiness className="li-icon-width" />,
                render: perms.project[3] || perms.project[4],
                subItems: [
                    {
                        name: t('Contracts'),
                        icon: <FileCheck className="li-icon-width" />,
                        render: perms.project[3] || perms.project[4],
                        subItems: [
                            {
                                name: t('Contracts list'),
                                icon: <List className="li-icon-width" />,
                                render: perms.project[3],
                                route:
                                    projectInManagement.base +
                                    '/' +
                                    location.pathname.split('/')[2] +
                                    projectInManagement.projectContracts.base +
                                    projectInManagement.projectContracts.read,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                            {
                                name: t('Contracts pipeline'),
                                icon: (
                                    <KanbanSquare className="li-icon-width" />
                                ),
                                render: perms.project[4],
                                route:
                                    projectInManagement.base +
                                    '/' +
                                    location.pathname.split('/')[2] +
                                    projectInManagement.projectContracts.base +
                                    projectInManagement.projectContracts
                                        .pipeline,
                                onRoute: function () {
                                    history.push(this.route);
                                },
                            },
                        ],
                    },
                    {
                        name: t('Invoices'),
                        icon: <ReceiptEuro className="li-icon-width" />,
                        render: true,
                        route: null,
                        onRoute: function () {
                            console.log('Facturi');
                        },
                    },
                ],
            },
            {
                name: `IPO - ${t('Internal Operations')}`,
                icon: <MonitorCog className="li-icon-width" />,
                render: true,
                route: null,
                onRoute: function () {
                    console.log('Internal project operations');
                },
            },
            {
                name: `RPIM - ${t('Resource Interaction Manager')}`,
                icon: <Layers className="li-icon-width" />,
                render: true,
                route: null,
                onRoute: function () {
                    console.log('Resource Project Operations');
                },
            },
            {
                name: 'RIEM',
                icon: <FileText className="li-icon-width" />,
                render: perms.project[5],
                route:
                    projectInManagement.base +
                    '/' +
                    location.pathname.split('/')[2] +
                    projectInManagement.riem.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t("KPI's"),
                icon: <Goal className="li-icon-width" />,
                render: true,
                route: null,
                onRoute: function () {
                    console.log("KPI's");
                },
            },
            {
                name: t("Reporting KPI's"),
                icon: <FilePieChart className="li-icon-width" />,
                render: true,
                route: null,
                onRoute: function () {
                    console.log("Reporting KPI's");
                },
            },
            {
                name: t('Watcher Insights'),
                icon: <Search className="li-icon-width" />,
                render: true,
                route: null,
                onRoute: function () {
                    console.log('Watcher Insights');
                },
            },
            {
                name: `FDPE - ${t('Financial Data Exporter')}`,
                icon: <FileChartColumn className="li-icon-width" />,
                render: perms.pm[3] || perms.pm[4],
                subItems: [
                    {
                        name: t('P&L'),
                        icon: <TrendingUpDown className="li-icon-width" />,
                        render: perms.pm[3],
                        route:
                            projectInManagement.base +
                            '/' +
                            location.pathname.split('/')[2] +
                            projectInManagement.projectPL.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                    {
                        name: t('Cashflow'),
                        icon: <ArrowRightLeft className="li-icon-width" />,
                        render: perms.pm[4],
                        route:
                            projectInManagement.base +
                            '/' +
                            location.pathname.split('/')[2] +
                            projectInManagement.projectCashflow.base,
                        onRoute: function () {
                            history.push(this.route);
                        },
                    },
                ],
            },
            {
                name: 'Configurator',
                icon: <Settings className="li-icon-width" />,
                render: perms.pm[5],
                route:
                    projectInManagement.base +
                    '/' +
                    location.pathname.split('/')[2] +
                    projectInManagement.configurator,
                onRoute: function () {
                    history.push(this.route);
                },
            },
        ],
    };

    const resourcesModuleMenuProps = {
        moduleName: t('Manage Resources'),
        backUrl: mappedModulesPaths['main'],
        modulePath: mappedModulesPaths['resourceManagement'],
        parentModulePath: mappedModulesPaths['main'],
        navItems: [
            {
                name: t('Resources'),
                icon: <Boxes className="li-icon-width" />,
                render: true,
                route: resourceModule.resources.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
            {
                name: t('Warehouse locations'),
                icon: <MapPin className="li-icon-width transform" />,
                render: true,
                route: resourceModule.warehouse.base,
                onRoute: function () {
                    history.push(this.route);
                },
            },
        ],
    };

    const secondaryMenusProps = [
        quotesMenuProps,
        projectManagementMenuProps,
        projectMenuProps,
        // affiliateProjectMenuProps,
        resourcesModuleMenuProps,
    ];

    const activeSecondaryMenuIndex = secondaryMenusProps.findIndex(
        (a) => location.pathname.search(a.modulePath) > -1,
    );

    useEffect(() => {
        return () => {
            changeNavName(null);
        };
    }, [activeSecondaryMenuIndex]);

    const { filteredMainMenuProps, filteredSecondaryMenusProps } = {
        filteredMainMenuProps: {
            ...mainMenuProps,
            navItems: handleFilterNavItems(mainMenuProps.navItems),
        },
        filteredSecondaryMenusProps: secondaryMenusProps.map((menu) => ({
            ...menu,
            navItems: handleFilterNavItems(menu.navItems),
        })),
    };

    const sidebarProps =
        filteredSecondaryMenusProps[activeSecondaryMenuIndex] ??
        filteredMainMenuProps;

    const dashboardProps = (
        filteredSecondaryMenusProps[activeSecondaryMenuIndex] ??
        filteredMainMenuProps
    ).navItems.reduce(
        (acc, curr) => {
            const subItems = curr.subItems ?? [];
            if (!subItems.length)
                return { ...acc, others: [...acc.others, curr] };

            const itemsKeys = subItems.filter(
                (itemKey) =>
                    Boolean(itemKey.subModulePath) &&
                    Boolean(itemKey.inDashboard),
            );
            const ignore =
                Boolean(itemsKeys.length) &&
                itemsKeys.length === subItems.length;

            const result = filteredSecondaryMenusProps
                .filter((moduleItem) =>
                    itemsKeys
                        .map((itemKey) => itemKey.subModulePath)
                        .includes(moduleItem.modulePath),
                )
                .map((moduleItem) => ({
                    icon: itemsKeys.find(
                        (itemKey) =>
                            itemKey.subModulePath === moduleItem.modulePath,
                    ).icon,
                    name: moduleItem.moduleName,
                    subItems: moduleItem.navItems.flatMap(
                        (nI) => nI.subItems ?? nI,
                    ),
                }));

            if (ignore) return { ...acc, modules: [...acc.modules, ...result] };

            result.unshift({
                ...curr,
                subItems: subItems.flatMap((sB) => sB.subItems ?? sB),
            });

            return { ...acc, modules: [...acc.modules, ...result] };
        },
        { modules: [], others: [] },
    );

    const navigationProps = {
        mainMenuProps,
        secondaryMenusProps,
        activeSecondaryMenuIndex,
    };

    return (
        <NavPropsContext.Provider
            value={{
                navName,
                changeNavName,
                sidebarProps,
                dashboardProps,
                navigationProps,
            }}
        >
            {children}
        </NavPropsContext.Provider>
    );
};

NavPropsProvider.propTypes = {
    children: PropTypes.object,
};

export default function useNavProps() {
    return useContext(NavPropsContext);
}
