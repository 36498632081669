import React from 'react';

import { CalendarDays, CalendarRange } from 'lucide-react';

import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import { PERIODS } from 'utils/kpiUtils';

import KpiFilters from './kpi-filters';

const KpiToolbar = ({ period, periodsFilter, disabled, onChangePeriod, onChangeDivision, onChangeFilter }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="w-40">
                <LabelWrapper label={t('Period')}>
                    <Dropdown
                        variant="black"
                        disabled={disabled}
                        icon={<CalendarDays />}
                        options={PERIODS.map((period) => t(capitalizeFirstLetter(period.type)))}
                        selectedOption={PERIODS.map((period) => period.type).indexOf(period.type)}
                        setSelectedOption={onChangePeriod}
                    />
                </LabelWrapper>
            </div>

            <div className="w-40">
                <LabelWrapper label={t('Division')}>
                    <Dropdown
                        variant="black"
                        disabled={disabled}
                        icon={<CalendarRange />}
                        options={period.divisions.map((division) => t(capitalizeFirstLetter(division)))}
                        selectedOption={period.divisions.indexOf(period.selectedDivision)}
                        setSelectedOption={onChangeDivision}
                    />
                </LabelWrapper>
            </div>

            <KpiFilters periodsFilter={periodsFilter} disabled={disabled} onChangeFilter={onChangeFilter} />
        </>
    );
};

KpiToolbar.propTypes = {
    period: PropTypes.object,
    periodsFilter: PropTypes.object,
    disabled: PropTypes.bool,
    onChangePeriod: PropTypes.func,
    onChangeDivision: PropTypes.func,
    onChangeFilter: PropTypes.func,
};

KpiToolbar.defaultProps = {
    period: null,
    periodsFilter: null,
    disabled: false,
    onChangePeriod: () => {},
    onChangeDivision: () => {},
    onChangeFilter: () => {},
};

export default KpiToolbar;
