import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Trash2, UserRoundCog } from 'lucide-react';

import { Button, TableCell } from '@material-ui/core';

import UserRole from 'components/crm/user-roles/user-role';
import ErrorButton from 'components/shared/error-button';
import UserContext from 'contexts/UserContext';
import useConfirm from 'hooks/useConfirm';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ExpandableRow } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { errorHandling, linkStyle } from 'utils';
import API from 'utils/axios';

const UserRolesRow = ({ role, triggerRefetch }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const confirm = useConfirm();
    const { checkPerm, user } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '55',
            permissionType: 'ALL',
        },
    ]);

    const { id, name, rolePermissions } = role;

    const [loading, setLoading] = useState(false);

    const handleDelete = async () => {
        try {
            setLoading(true);

            await API.delete('userRoles', {
                params: {
                    id,
                },
            });

            enqueueSnackbar(t('Role deleted successfully!'), {
                variant: 'success',
            });

            triggerRefetch();
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ExpandableRow
            colSpan={2}
            padding={false}
            row={
                <>
                    <TableCell>
                        <span
                            className={`font-semibold ${linkStyle}`}
                            onClick={(e) => {
                                e.stopPropagation();

                                if (!canAll) return;

                                history.push(
                                    configurator.base +
                                        configurator.userRoles.base +
                                        configurator.userRoles.update +
                                        '/' +
                                        id,
                                );
                            }}
                        >
                            {name}
                        </span>
                    </TableCell>
                    <TableCell>{rolePermissions.length}</TableCell>
                </>
            }
            collapse={
                <div className="flex flex-col gap-8 p-5">
                    <UserRole initialRole={role} initialDisabled={true} />
                    {canAll && user.Role.id !== role.id && (
                        <div className="flex items-center gap-4">
                            <Button
                                disabled={loading}
                                startIcon={<UserRoundCog />}
                                className="w-max"
                                color="primary"
                                onClick={() => {
                                    history.push(
                                        configurator.base +
                                            configurator.userRoles.base +
                                            configurator.userRoles.update +
                                            '/' +
                                            id,
                                    );
                                }}
                            >
                                {t('Edit role')}
                            </Button>
                            <ErrorButton
                                disabled={loading}
                                startIcon={<Trash2 />}
                                onClick={() =>
                                    confirm(
                                        t(
                                            'Are you sure you want to delete this role?',
                                        ),
                                        () => handleDelete(),
                                    )
                                }
                            >
                                {t('Delete')}
                            </ErrorButton>
                        </div>
                    )}
                </div>
            }
        />
    );
};

UserRolesRow.propTypes = {
    role: PropTypes.object,
    triggerRefetch: PropTypes.func,
};

UserRolesRow.defaultProps = {
    role: null,
    triggerRefetch: () => {},
};

export default UserRolesRow;
