import React, { useContext } from 'react';

import ValueDisplay from 'components/shared/value-display';
import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { toLocaleNumber } from 'utils';

const InvoiceReversal = (props) => {
    const { t } = useTranslation();
    const { currencyObj, referenceCurrencyObj, language } = useContext(GlobalContext);
    const { selectedAdvanceInvoices, setSelectedAdvanceInvoices, invoice, advanceInvoices, handleUpdateInvoiceValue } =
        props;

    return (
        <div className="mb-10 flex w-full max-w-max justify-start gap-3 rounded-md bg-layout-transparent p-2">
            <div className="inline-block">
                {advanceInvoices.map((i) => i.name).length >= 0 ? (
                    <LabelWrapper label={t('Reverse invoices')}>
                        <MultiDropdown
                            variant="default"
                            options={advanceInvoices.map((i) => i.name)}
                            placeholder={t('Choose the advance invoices')}
                            selectedOptions={selectedAdvanceInvoices}
                            setSelectedOptions={(i) =>
                                setSelectedAdvanceInvoices((prev) => {
                                    const newSelectedAdvancedInvoices = structuredClone(prev);
                                    const selectionIndex = newSelectedAdvancedInvoices.indexOf(i);

                                    if (selectionIndex >= 0)
                                        return newSelectedAdvancedInvoices.filter((ac) => ac !== i);

                                    newSelectedAdvancedInvoices.push(i);

                                    if (invoice.type === 'REVERSAL') {
                                        handleUpdateInvoiceValue({
                                            target: {
                                                value: newSelectedAdvancedInvoices.reduce(
                                                    (acc, curr) => (acc += advanceInvoices[curr].value),
                                                    0,
                                                ),
                                            },
                                        });
                                    }

                                    return newSelectedAdvancedInvoices;
                                })
                            }
                        />
                    </LabelWrapper>
                ) : (
                    <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                        {t('There are not advance invoices to be reversed! You must create an advance invoice!')}
                    </div>
                )}
            </div>

            {selectedAdvanceInvoices.length >= 0 && (
                <div className="flex gap-3 sm:flex-col">
                    <div className="flex flex-col gap-3">
                        <ValueDisplay
                            label={t('Reversal value')}
                            value={toLocaleNumber(
                                selectedAdvanceInvoices.reduce((acc, curr) => (acc += advanceInvoices[curr].value), 0),
                                language,
                                2,
                            )}
                            alias={currencyObj.currency}
                        />
                        <ValueDisplay
                            label={t('Reversal value (with VAT)')}
                            value={toLocaleNumber(
                                selectedAdvanceInvoices.reduce(
                                    (acc, curr) => (acc += advanceInvoices[curr].valueWithVat),
                                    0,
                                ),
                                language,
                                2,
                            )}
                            alias={currencyObj.currency}
                        />
                    </div>

                    <div className="flex flex-col gap-3">
                        <ValueDisplay
                            label={t('Reversal value in the reference currency')}
                            value={toLocaleNumber(
                                selectedAdvanceInvoices.reduce(
                                    (acc, curr) => (acc += advanceInvoices[curr].secondValue),
                                    0,
                                ),
                                language,
                                2,
                            )}
                            alias={referenceCurrencyObj.currency}
                        />
                        <ValueDisplay
                            label={t('Reversal value in the reference currency (with VAT)')}
                            value={toLocaleNumber(
                                selectedAdvanceInvoices.reduce(
                                    (acc, curr) => (acc += advanceInvoices[curr].secondValueWithVat),
                                    0,
                                ),
                                language,
                                2,
                            )}
                            alias={referenceCurrencyObj.currency}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

InvoiceReversal.propTypes = {
    selectedAdvanceInvoices: PropTypes.array,
    setSelectedAdvanceInvoices: PropTypes.func,
    advanceInvoices: PropTypes.arrayOf(PropTypes.object),
    invoice: PropTypes.object,
    editInvoiceInfo: PropTypes.func,
    handleUpdateInvoiceValue: PropTypes.func,
};

export default InvoiceReversal;
