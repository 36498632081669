import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import UserRole from 'components/crm/user-roles/user-role';
import Loading from 'components/shared/loading';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const UserRolesEdit = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState(null);

    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '55',
            permissionType: 'ALL',
        },
    ]);

    const getRoleById = async () => {
        try {
            const response = await API.get(`/userRole/${id}`);

            setRole(response.data.role);
        } catch (err) {
            enqueueSnackbar(errorHandling(err), { variant: 'error' });
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!canAll) {
            history.push('/');
            return;
        }

        getRoleById();
    }, [id]);

    return (
        <>
            <Helmet>
                <title>
                    Configurator ERP | {role ? role.name : t('Edit role')}
                </title>
            </Helmet>

            <Header pageTitle={role ? role.name : t('Edit role')} />

            <div className="page-container">
                {loading ? <Loading /> : <UserRole initialRole={role} />}
            </div>
        </>
    );
};

export default UserRolesEdit;
