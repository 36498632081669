import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ArticleForm from 'components/crm/quotes/article-form';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { quotes } from 'routes';

const EditNomenclature = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { checkPerm } = useContext(UserContext);
    const history = useHistory();

    const canAll = checkPerm([
        {
            permissionId: '18',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push(
                quotes.base +
                    quotes.nomenclature.base +
                    quotes.nomenclature.read,
            );
        }
    }, [canAll]);

    return (
        <>
            <Helmet>
                <title>
                    {t('nomenclature')} | {t('Article edit')}
                </title>
            </Helmet>
            <Header pageTitle={t('Article edit')} />
            <ArticleForm articleId={id} canAll={canAll} />
        </>
    );
};

export default EditNomenclature;
