import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, CircularProgress, makeStyles, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { resourceModule } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
    customButton: {
        backgroundColor: 'var(--error)',
        color: 'var(--buttons-text)',
        '&:hover': {
            backgroundColor: 'var(--error-light)',
        },
    },
}));

const WarehouseAdd = () => {
    const styles = useStyles();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isUploading, setIsUploading] = useState(false);

    const types = ['WAREHOUSE', 'OFFICE', 'SITE'];
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        space: '',
        type: undefined,
        responsibleId: undefined,
    });
    const [responsible, setResponsible] = useState([]);

    const handleFormDataUpdate = (e) => setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const schema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .min(2, t('Name must be at least 2 characters long!'))
            .required(t('Name is mandatory!')),
        address: yup.string().trim().required(t('Address is mandatory!')),
        space: yup.string().trim().required(t('Space is mandatory!')),
        type: yup.string().required(t('The location type is mandatory!')),
        responsibleId: yup.string().trim().required(t('The responsible is mandatory!')),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setIsUploading(true);

            const newFormData = {
                ...formData,
                type: types[formData.type],
                responsibleId: responsible[formData.responsibleId]?.id ?? undefined,
            };

            await schema.validate(newFormData);

            if (!id) await API.post('warehouse', newFormData);
            else await API.put('warehouse', newFormData);

            enqueueSnackbar(!id ? t('Warehouse created successfully!') : t('Warehouse updated successfully!'), {
                variant: 'success',
            });

            history.push(resourceModule.warehouse.categories);
        } catch (error) {
            if (error?.errors) enqueueSnackbar(error.errors[0], { variant: 'error' });
            else
                enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                    variant: 'error',
                });

            console.error(error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsUploading(true);

            await API.delete('warehouse', { params: { id } });

            enqueueSnackbar(t('Warehouse deleted successfully!'), {
                variant: 'success',
            });

            history.push(resourceModule.warehouse.categories);
        } catch (error) {
            enqueueSnackbar(errorHandling(error).length > 100 ? errorHandling(error) : t(errorHandling(error)), {
                variant: 'error',
            });
        } finally {
            setIsUploading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const resResponsible = await API.get('humanResources', {
                    params: {
                        currentPage: 0,
                        perPage: 99999,
                        pagesToLoad: 1,
                    },
                });
                const responsibleData = resResponsible.data.allHumanResources.humanResources;

                if (id) {
                    const resWarehouse = await API.get(`warehouse/${id}`);
                    const warehouseData = resWarehouse.data.getWarehouseById;

                    const typeIndex = types.findIndex((type) => type === warehouseData.type);
                    const responsibleIndex = responsibleData.findIndex(
                        (responsible) => responsible === warehouseData.responsibleId,
                    );

                    setFormData({
                        type: typeIndex >= 0 ? typeIndex : undefined,
                        name: warehouseData.name,
                        address: warehouseData.address,
                        space: warehouseData.space,
                        responsibleId: responsibleIndex >= 0 ? responsibleIndex : undefined,
                    });
                }

                setResponsible(responsibleData);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t('Something went wrong!'), {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        <div className="page-container">
            {isLoading ? (
                <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                    <CircularProgress />
                </div>
            ) : (
                <form className="flex max-w-xl flex-col gap-5" onSubmit={handleSubmit}>
                    <TextField
                        name="name"
                        placeholder={t('Name')}
                        label={t('Name')}
                        type="text"
                        value={formData.name}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <TextField
                        name="address"
                        placeholder={t('Address')}
                        label={t('Address')}
                        type="text"
                        value={formData.address}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <TextField
                        name="space"
                        placeholder={t('Space')}
                        label={t('Space')}
                        type="text"
                        value={formData.space}
                        onChange={handleFormDataUpdate}
                        disabled={isUploading}
                    />
                    <LabelWrapper label={t('Select location type')}>
                        <Dropdown
                            variant="black"
                            options={types.map((type) => t(type))}
                            placeholder={t('Choose the location type')}
                            selectedOption={formData.type}
                            setSelectedOption={(i) =>
                                handleFormDataUpdate({
                                    target: {
                                        name: 'type',
                                        value: i,
                                    },
                                })
                            }
                            disabled={isUploading}
                        />
                    </LabelWrapper>
                    <LabelWrapper label={t('Select responsible')}>
                        <Dropdown
                            variant="black"
                            options={responsible.map((responsible) => responsible.name)}
                            placeholder={t('Choose the responsible')}
                            selectedOption={formData.responsibleId}
                            setSelectedOption={(i) =>
                                handleFormDataUpdate({
                                    target: {
                                        name: 'responsibleId',
                                        value: i,
                                    },
                                })
                            }
                            disabled={isUploading}
                        />
                    </LabelWrapper>
                    <div className="mt-5 flex flex-wrap justify-between gap-5">
                        <div className="flex flex-wrap gap-5">
                            <Button
                                startIcon={!id ? <AddIcon /> : <CheckIcon />}
                                color="primary"
                                type="submit"
                                disabled={isUploading}
                            >
                                {!id ? t('Add warehouse') : t('Edit warehouse')}
                            </Button>
                            {id && (
                                <Button
                                    className={styles.customButton}
                                    startIcon={<DeleteIcon />}
                                    type="submit"
                                    disabled={isUploading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete();
                                    }}
                                >
                                    {t('Delete warehouse')}
                                </Button>
                            )}
                        </div>
                        <Button
                            startIcon={<BackspaceIcon />}
                            color="secondary"
                            disabled={isUploading}
                            onClick={(e) => {
                                e.preventDefault();
                                history.goBack();
                            }}
                        >
                            {t('Back')}
                        </Button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default WarehouseAdd;
