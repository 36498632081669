import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Button } from '@material-ui/core';

import Gantt from 'components/planning-overview/gantt/gantt';
import GantContext from 'contexts/GantContext';
import useDays from 'hooks/useDays';
import useMonths from 'hooks/useMonths';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { configurator } from 'routes';
import { formatDate, PHONE_NO_REGEX } from 'utils';
import API from 'utils/axios';
import { getResources } from 'utils/getterFunctions';
import * as yup from 'yup';

import UpdateEmployee from './update-employee/update-employee';

const Resource = ({ type }) => {
    const { employeeId, projectId, teamId } = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const months = useMonths();
    const days = useDays();

    /*
     * Resource management
     */
    const [internalEmployees, setInternalEmployees] = useState([]);
    const [externalEmployees, setExternalEmployees] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const fetchEmployees = useCallback(async () => {
        const resources = await getResources(type);
        setInternalEmployees(resources.internalEmployees);
        setExternalEmployees(resources.externalEmployees);
    }, []);

    useEffect(() => {
        (async () => {
            await fetchEmployees();
        })();
    }, [activeTab]);

    /*
     * Month info
     */
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedDate, setSelectedDate] = useState(null);

    const [project, setProject] = useState(null);
    const [team, setTeam] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [roleName, setRoleName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [userId, setUserId] = useState(null);
    const [employeeInfo, setEmployeeInfo] = useState(null);
    /**
     * Work around for the backend team
     */
    const [employeeType, setEmployeeType] = useState(null);
    const [teams, setTeams] = useState([]);

    const [schedule, setSchedule] = useState(null);
    const [exceptions, setExceptions] = useState({});
    const [dayId, setDayId] = useState(null);

    let schema = yup.object().shape({
        roleName: yup.string().trim().typeError().required(),
        email: yup.string().trim().required(t('Email field is required!')).email(t('Email is invalid!')),
        phone: yup
            .string()
            .trim()
            .required(t('Phone number field required!'))
            .matches(PHONE_NO_REGEX, t('Invalid phone number!'))
            .min(10, t('Phone number must be at least 10 digits')),
    });

    /**
     * Fetch employee schedule and manage data
     */
    useEffect(() => {
        (async () => {
            try {
                let res;

                res = await API.get(type == 'internal' ? '/projectOverviewPlanning' : '/pm_projectPlanningOverview', {
                    params: {
                        id: projectId,
                    },
                });

                const myProject = type == 'internal' ? res.data.project : res.data.pm;
                setProject(myProject);

                /**
                 * Get employee task schedule
                 */
                res = await API.get(type == 'internal' ? '/employeeTasksSchedule' : '/pmEmployeeTaskSchedules', {
                    params: {
                        employeeId,
                    },
                });

                const employeeTaskSchedule = res.data[type == 'internal' ? 'EmployeeTasks' : 'employeeTaskSchedules'];

                const newExceptions = {};
                employeeTaskSchedule.forEach((scheduleDay) => {
                    const scheduleDate = new Date(scheduleDay.date).getTime();
                    newExceptions[scheduleDate] = {
                        id: scheduleDay.id,
                        hours: scheduleDay.hours,
                        employeeId: scheduleDay.employeeId,
                        taskId: scheduleDay.taskId,
                    };
                });

                setExceptions(newExceptions);

                /**
                 * Get timeline dates for employee and total standard hours
                 */
                const response = await API.get(
                    type == 'internal' ? '/projectSchedulePerEmployee' : '/pmProjectSchedulePerEmployee',
                    {
                        params: {
                            employeeId,
                            projectId,
                            teamId,
                        },
                    },
                );
                setEmployeeInfo(response.data.info);

                /**
                 * Find all the teams that the current employee is in
                 */
                const myTeams = myProject.teams.filter((team) => {
                    return team.employees.findIndex((emp) => emp.employee.id === employeeId) > -1;
                });

                setTeams(myTeams);

                const myTeam = myProject.teams.filter((team) => team.id === teamId)[0];
                setTeam(myTeam);

                /**
                 * Filter employee tasks, to highlight days busy with any task
                 */
                const newTasks = myTeam.employees.filter((e) => e.employee.id === employeeId)[0].employee.tasks;

                let newTasks2 = [];
                myTeams.forEach((team) => {
                    const tasks = team.employees.filter((e) => e.employee.id === employeeId)[0].employee.tasks;
                    newTasks2 = newTasks2.concat(tasks);
                });

                setTasks(newTasks2);

                /**
                 * Fetch employee info
                 */
                res = await API.get(type == 'internal' ? '/employee' : 'pmEmployee', {
                    params: {
                        id: employeeId,
                    },
                });

                const employee = type == 'internal' ? res.data.employee : res.data.pmEmployee;

                setFirstName(employee.firstName);
                setRoleName(employee.position);
                setPhone(employee.phoneNumber);
                setEmail(employee.email);
                setSchedule(employee.schedule);
                if ('userId' in employee && Boolean(employee.userId)) {
                    setUserId(employee.userId);
                }

                /**
                 * Work around for the backend team
                 */
                setEmployeeType(employee.employeeType);
            } catch (err) {
                console.error(err);
            }
        })();
    }, [employeeId, projectId]);

    /**
     * Function that modifies hours on + / -
     */
    const modifyHours = useCallback(
        async (milis, step, taskId = null) => {
            const validateHour = (hour) => {
                if (hour < 0) return 8;
                if (hour > 8) return 0;
                return hour;
            };

            try {
                const newExceptions = { ...exceptions };
                let newHours;

                if (milis in newExceptions) {
                    newHours = validateHour(newExceptions[milis].hours + step);

                    /**
                     * Update exception
                     */
                    await API.put(type == 'internal' ? '/employeeTasksSchedule' : '/pmEmployeeTaskSchedules', {
                        id: newExceptions[milis].id,
                        data: {
                            date: new Date(milis),
                            hours: newHours,
                            employeeId,
                            taskId,
                        },
                    });

                    newExceptions[milis].hours = newHours;
                } else {
                    newHours = validateHour(8 + step);

                    /**
                     * Create exception
                     */
                    const res = await API.post(
                        type == 'internal' ? '/employeeTasksSchedule' : '/pmEmployeeTaskSchedules',
                        {
                            date: new Date(milis),
                            hours: newHours,
                            employeeId,
                            taskId,
                        },
                    );

                    newExceptions[milis] = {
                        id: res.data[type === 'internal' ? 'employeeScheduale' : 'pmTaskSchedule'].id,
                        employeeId,
                        hours: newHours,
                        taskId,
                    };
                }
                setExceptions(newExceptions);
            } catch (err) {
                console.error(err);
            }
        },
        [exceptions, employeeId, type],
    );

    useEffect(() => {
        const now = new Date();
        const date = new Date(now.getFullYear(), selectedMonth + 1, 0, 0, 0, 0);
        setSelectedDate(date);
    }, [selectedMonth]);

    const updateEmployee = useCallback(async () => {
        try {
            await schema.validate({
                roleName,
                email,
                phone,
            });

            await API.put(type == 'internal' ? '/employees' : '/pm_employees', {
                id: employeeId,
                data: {
                    firstName,
                    position: roleName,
                    email,
                    phoneNumber: phone,
                    employeeType,
                    teams: [],
                    schedule: schedule.map((daySchedule) => ({
                        day: daySchedule.day,
                        hours: daySchedule.hours,
                    })),
                },
            });
            enqueueSnackbar(t('Employee updated successfully'), { variant: 'success' });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(err.errors[0], { variant: 'error' });
        }
    }, [employeeId, firstName, roleName, email, phone, schedule, employeeType, teams]);

    /**
     * I'm using a lot of 'date - 0' way to convert from date to miliseconds
     */

    return (
        <>
            <Helmet>
                <title>
                    {t('Resource')} {firstName}
                </title>
            </Helmet>

            <Header
                pageTitle={`${t('Resource')} ${firstName}`}
                action={
                    userId !== null && (
                        <Button
                            color="secondary"
                            style={{ borderRadius: '999px' }}
                            onClick={() =>
                                history.push(
                                    configurator.base +
                                        configurator.users.base +
                                        configurator.users.update +
                                        '/' +
                                        userId +
                                        '?tab=1',
                                )
                            }
                        >
                            {t('View profile')}
                        </Button>
                    )
                }
            />

            <div className="page-container">
                <div className="mb-16">
                    <Tabs
                        tabs={[t('Standard hours & Activity'), t('User details')]}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                </div>

                {activeTab === 0 && (
                    <div>
                        {/* Calendar */}
                        <div className="bg-layout-transparent">
                            <div className="w-full border-b">
                                <div className="flex max-w-5xl justify-between p-8">
                                    <div>
                                        <p className="opacity-50">{t('Role')}</p>
                                        <p className="font-semibold">{roleName}</p>
                                    </div>
                                    <div>
                                        <p className="opacity-50">{t('Project')}</p>
                                        <p className="font-semibold">{project?.name}</p>
                                    </div>
                                    <div>
                                        <p className="opacity-50">{t('Team')}</p>
                                        <p className="font-semibold">{team?.name}</p>
                                    </div>
                                    <div>
                                        <p className="opacity-50">{t('Timeline per project')}</p>
                                        <p className="font-semibold">
                                            {formatDate(employeeInfo?.startDate)} - {formatDate(employeeInfo?.EndDate)}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="opacity-50">{t('Standard hours')}</p>
                                        <p className="font-semibold">{employeeInfo?.totalHours}</p>
                                    </div>
                                </div>
                            </div>

                            {/* Month selector */}
                            <div className="w-full border-b">
                                <div className="flex items-center space-x-6 p-8 text-main-text">
                                    <div
                                        className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition-colors hover:bg-layout-transparent"
                                        onClick={() => setSelectedMonth(selectedMonth - 1 < 0 ? 11 : selectedMonth - 1)}
                                    >
                                        <NavigateBeforeIcon style={{ fontSize: '16px' }} />
                                    </div>
                                    <h4 className="w-24 text-center" style={{ userSelect: 'none' }}>
                                        {months[selectedMonth]}
                                    </h4>
                                    <div
                                        className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full transition-colors hover:bg-layout-transparent"
                                        onClick={() => setSelectedMonth((selectedMonth + 1) % 12)}
                                    >
                                        <NavigateNextIcon style={{ fontSize: '16px' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                {selectedDate !== null && schedule !== null && (
                                    <div className="flex p-8">
                                        {Array.from(Array(6).keys()).map((_, week) => {
                                            let monthFirstDay = new Date(selectedDate);
                                            monthFirstDay.setDate(1);
                                            monthFirstDay = monthFirstDay.getDay();
                                            monthFirstDay = monthFirstDay === 0 ? 6 : monthFirstDay - 1;

                                            let monthLastDay = selectedDate.getDay();
                                            monthLastDay = monthLastDay === 0 ? 6 : monthLastDay - 1;

                                            return (
                                                <div key={week} className="flex flex-col">
                                                    {Array.from(Array(7).keys()).map((_, day) => {
                                                        const dayDate = new Date(selectedDate);
                                                        dayDate.setDate(day + 1 + week * 7 - monthFirstDay);
                                                        let isBusy = false;
                                                        let taskId = null;

                                                        /**
                                                         * Find if a task takes place this day
                                                         */
                                                        tasks.every((task) => {
                                                            const taskStartDate = new Date(task.startDate);
                                                            taskStartDate.getTime();

                                                            const taskEndDate = new Date(task.endDate);
                                                            taskEndDate.getTime();

                                                            if (dayDate >= taskStartDate && dayDate <= taskEndDate) {
                                                                isBusy = true;
                                                                taskId = task.id;
                                                            }

                                                            return !isBusy;
                                                        });

                                                        return (
                                                            <div key={week + '_' + day}>
                                                                {(week === 0 && day >= monthFirstDay) ||
                                                                (week === 4 && day <= monthLastDay) ||
                                                                (week > 0 && week < 4) ? (
                                                                    <div
                                                                        className={`group flex h-10 w-64 items-center justify-between overflow-hidden rounded-md bg-transparent pl-4 hover:bg-primary-main hover:shadow-lg ${
                                                                            isBusy
                                                                                ? 'pointer-events-auto opacity-100'
                                                                                : 'pointer-events-none opacity-50'
                                                                        }`}
                                                                        onMouseLeave={() => {
                                                                            if (dayId === dayDate - 0) setDayId(null);
                                                                        }}
                                                                    >
                                                                        <div className="mr-4 flex space-x-4">
                                                                            {/* Day's starting letter */}
                                                                            <p className="w-4">{days[day].charAt(0)}</p>

                                                                            {/* This day's date */}
                                                                            <p className="w-16">
                                                                                {dayDate.getDate()}{' '}
                                                                                {months[dayDate.getMonth()]
                                                                                    .slice(0, 3)
                                                                                    .toUpperCase()}
                                                                            </p>

                                                                            {/* Working hours this day */}
                                                                            <p>
                                                                                {(() => {
                                                                                    if (!(dayDate - 0 in exceptions)) {
                                                                                        const scheduleDayIndex =
                                                                                            schedule.findIndex(
                                                                                                (sd) =>
                                                                                                    sd.day ===
                                                                                                    days[day],
                                                                                            );
                                                                                        return scheduleDayIndex >= 0
                                                                                            ? schedule[scheduleDayIndex]
                                                                                                  .hours
                                                                                            : 8;
                                                                                    } else
                                                                                        return exceptions[dayDate - 0]
                                                                                            .hours;
                                                                                })()}
                                                                                h
                                                                            </p>
                                                                        </div>

                                                                        {/* Buttons to edit working hours */}
                                                                        <div className="flex text-main-text opacity-0 group-hover:opacity-100">
                                                                            {/* Modify hours + / - */}
                                                                            <div
                                                                                onClick={() =>
                                                                                    modifyHours(dayDate - 0, 1, taskId)
                                                                                }
                                                                                className="mr-0.5 flex h-10 w-10 cursor-pointer items-center justify-center bg-layout-transparent hover:bg-layout-transparent-dark"
                                                                                style={{ userSelect: 'none' }}
                                                                            >
                                                                                +
                                                                            </div>
                                                                            <div
                                                                                onClick={() =>
                                                                                    modifyHours(dayDate - 0, -1, taskId)
                                                                                }
                                                                                className="flex h-10 w-10 cursor-pointer items-center justify-center bg-layout-transparent hover:bg-layout-transparent-dark"
                                                                                style={{ userSelect: 'none' }}
                                                                            >
                                                                                -
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="h-10" />
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Gantt */}
                        {project !== null && (
                            <GantContext.Provider
                                value={{
                                    viewOnly: true,
                                    internalEmployees,
                                    externalEmployees,
                                    type,
                                }}
                            >
                                <Gantt projects={[project]} />
                            </GantContext.Provider>
                        )}
                    </div>
                )}

                {activeTab === 1 && (
                    <UpdateEmployee
                        roleName={roleName}
                        setRoleName={setRoleName}
                        phone={phone}
                        setPhone={setPhone}
                        email={email}
                        setEmail={setEmail}
                        teams={teams}
                        updateEmployee={updateEmployee}
                    />
                )}
            </div>
        </>
    );
};

Resource.propTypes = {
    type: PropTypes.string,
};

Resource.defaultProps = {
    type: 'internal',
};

export default Resource;
