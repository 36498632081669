import React from 'react';

import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const InvoiceArticlesSelection = ({
    articlesFromNomenclature,
    articlesFromStocks,
    selectedArticleIds,
    setSelectedArticleIds,
}) => {
    const { t } = useTranslation();

    return (
        <div className="mb-10">
            <p className="mb-2 mt-2 text-xl font-bold">{t('Select articles')}</p>
            <div className="flex items-center gap-5">
                <LabelWrapper label={t('Add article from nomenclature')}>
                    <MultiDropdown
                        options={articlesFromNomenclature.map((a) => a.name)}
                        selectedOptions={articlesFromNomenclature.reduce((acc, cur, index) => {
                            if (selectedArticleIds.nomenclature.includes(cur.id)) return [...acc, index];

                            return acc;
                        }, [])}
                        setSelectedOptions={(i) => {
                            const id = articlesFromNomenclature[i].id;
                            setSelectedArticleIds((prev) => ({
                                ...prev,
                                nomenclature: prev.nomenclature.includes(id)
                                    ? prev.nomenclature.filter((identifier) => identifier !== id)
                                    : [...prev.nomenclature, id],
                            }));
                        }}
                    />
                </LabelWrapper>
                <LabelWrapper label={t('Add article from stocks')}>
                    <MultiDropdown
                        options={articlesFromStocks.map((a) => `${a.name} - (${a.Stock.name})`)}
                        selectedOptions={articlesFromStocks.reduce((acc, cur, index) => {
                            if (selectedArticleIds.stocks.includes(cur.id)) return [...acc, index];

                            return acc;
                        }, [])}
                        setSelectedOptions={(i) => {
                            const id = articlesFromStocks[i].id;
                            setSelectedArticleIds((prev) => ({
                                ...prev,
                                stocks: prev.stocks.includes(id)
                                    ? prev.stocks.filter((identifier) => identifier !== id)
                                    : [...prev.stocks, id],
                            }));
                        }}
                    />
                </LabelWrapper>
            </div>
        </div>
    );
};

InvoiceArticlesSelection.propTypes = {
    articlesFromNomenclature: PropTypes.array,
    articlesFromStocks: PropTypes.array,
    selectedArticleIds: PropTypes.object,
    setSelectedArticleIds: PropTypes.func,
};

InvoiceArticlesSelection.defaultProps = {
    articlesFromNomenclature: [],
    articlesFromStocks: [],
    selectedArticleIds: {
        nomenclature: [],
        stocks: [],
    },
    setSelectedArticleIds: () => {},
};

export default InvoiceArticlesSelection;
