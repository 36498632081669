import React, { useContext, useEffect } from 'react';

import UserRole from 'components/crm/user-roles/user-role';
import UserContext from 'contexts/UserContext';
import { Header } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const UserRolesCreate = () => {
    const { t } = useTranslation();

    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '55',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canAll) {
            history.push('/');
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Configurator ERP | {t('Add new role')}</title>
            </Helmet>

            <Header pageTitle={t('Add new role')} />
            <div className="page-container">
                <UserRole />
            </div>
        </>
    );
};

export default UserRolesCreate;
