import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import PropTypes from 'prop-types';
import { ActionButton, Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const InvoiceCrmDataSelection = ({
    invoiceOnAContract,
    contractsOptions,
    crmProjects,
    selectedContract,
    selectedCrmProject,
    invoiceClient,
    setSelectedContract,
    setSelectedCrmProject,
    onCheck,
}) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-4">
            {/* Checkbox for the contracts */}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={invoiceOnAContract}
                        onChange={onCheck}
                        name="CRM contracts"
                        style={{
                            color: 'var(--primary-main)',
                        }}
                        color="primary"
                    />
                }
                label={<p className="user-select-none">{t('Invoice based on a CRM contract or project')}</p>}
            />

            {/* Dropdown for CRM contracts */}
            {invoiceOnAContract ? (
                contractsOptions.length ? (
                    <LabelWrapper label={t('CRM contracts')}>
                        <div className="flex max-w-min items-center gap-3">
                            <Dropdown
                                disabled={selectedCrmProject !== null}
                                variant="black"
                                options={contractsOptions}
                                placeholder={t('Choose the contract for the invoice')}
                                selectedOption={selectedContract}
                                setSelectedOption={(e) => setSelectedContract(e)}
                            />

                            <ActionButton
                                icon={<DeleteIcon />}
                                color={selectedContract !== null ? 'var(--error)' : 'var(--disabled)'}
                                disabled={selectedContract !== null ? false : true}
                                onClick={() => setSelectedContract(null)}
                            />
                        </div>
                    </LabelWrapper>
                ) : (
                    <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                        {t(
                            'The selected client has no contract yet! Create a new one or pick another client for the invoice!',
                        )}
                    </div>
                )
            ) : null}

            {invoiceOnAContract && invoiceClient !== null ? (
                crmProjects.length ? (
                    <LabelWrapper label={t('CRM projects')}>
                        <div className="flex max-w-min items-center gap-3">
                            <Dropdown
                                disabled={selectedContract !== null}
                                variant="black"
                                options={crmProjects.map((p) => p.name)}
                                placeholder={t('Choose the project for the invoice')}
                                selectedOption={selectedCrmProject}
                                setSelectedOption={(e) => setSelectedCrmProject(e)}
                            />

                            <ActionButton
                                icon={<DeleteIcon />}
                                color={selectedCrmProject !== null ? 'var(--error)' : 'var(--disabled)'}
                                disabled={selectedCrmProject !== null ? false : true}
                                onClick={() => setSelectedCrmProject(null)}
                            />
                        </div>
                    </LabelWrapper>
                ) : (
                    <div className="max-w-sm flex-wrap text-center font-semibold italic text-error">
                        {t(
                            'The selected client has no project yet! Create a new one or pick another client for the invoice!',
                        )}
                    </div>
                )
            ) : null}
        </div>
    );
};

InvoiceCrmDataSelection.propTypes = {
    invoiceOnAContract: PropTypes.bool,
    contractsOptions: PropTypes.array,
    crmProjects: PropTypes.array,
    selectedContract: PropTypes.number,
    selectedCrmProject: PropTypes.number,
    invoiceClient: PropTypes.number,
    setSelectedContract: PropTypes.func,
    setSelectedCrmProject: PropTypes.func,
    onCheck: PropTypes.func,
};

InvoiceCrmDataSelection.defaultProps = {
    invoiceOnAContract: false,
    contractsOptions: [],
    crmProjects: [],
    selectedContract: null,
    selectedCrmProject: null,
    invoiceClient: null,
    setSelectedContract: () => {},
    setSelectedCrmProject: () => {},
    onCheck: () => {},
};

export default InvoiceCrmDataSelection;
