import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
    ArrowUpRight,
    BadgeCheck,
    Ban,
    Download,
    FileCheck,
    KanbanSquare,
    Rows3,
    Send,
    TextSelect,
    User,
} from 'lucide-react';
import AddIcon from '@material-ui/icons/Add';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { ReactComponent as AuthorIcon } from 'assets/pipelines/svgs/author-icon.svg';
import { ReactComponent as CalendarBlankIcon } from 'assets/pipelines/svgs/calendar-blank-icon.svg';
import { ReactComponent as CalendarRangeIcon } from 'assets/pipelines/svgs/calendar-range-icon.svg';
import { ReactComponent as CalendarSelectionIcon } from 'assets/pipelines/svgs/calendar-selection-icon.svg';
import { ReactComponent as ClientIcon } from 'assets/pipelines/svgs/client-icon.svg';
import { ReactComponent as CurrentDayIcon } from 'assets/pipelines/svgs/current-day-icon.svg';
import { ReactComponent as EqualIcon } from 'assets/pipelines/svgs/equal-icon.svg';
import { ReactComponent as GreatEqualIcon } from 'assets/pipelines/svgs/greater-equal-icon.svg';
import { ReactComponent as HighestIcon } from 'assets/pipelines/svgs/highest-icon.svg';
import { ReactComponent as LastThirtyDaysIcon } from 'assets/pipelines/svgs/last-thirty-days-icon.svg';
import { ReactComponent as LessEqualIcon } from 'assets/pipelines/svgs/less-equal-icon.svg';
import { ReactComponent as LowestIcon } from 'assets/pipelines/svgs/lowest-icon.svg';
import { ReactComponent as PriceIcon } from 'assets/pipelines/svgs/price-icon.svg';
import { ReactComponent as RecentIcon } from 'assets/pipelines/svgs/recent-icon.svg';
import { ReactComponent as SpecificIcon } from 'assets/pipelines/svgs/specific-icon.svg';

import { Button } from '@material-ui/core';

import Filter from 'components/shared/filter/filter';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import PipelineCompactContent from 'components/shared/pipelines/pipeline-compact-content';
import PipelineContent from 'components/shared/pipelines/pipeline-content';
import Sort from 'components/shared/sort/sort';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Header, Tabs } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm, projectInManagement } from 'routes';
import {
    errorHandling,
    formatDate,
    getDayBeginningAndEnding,
    hexToRgb,
    toLocaleNumber,
} from 'utils';
import API from 'utils/axios';

const DEFAULT_PIPELINE_STATE = {
    length: 0,
    content: {
        DRAFT: {
            length: 0,
            content: {
                REST: [],
            },
        },
        SENT: {
            length: 0,
            content: {
                REST: [],
            },
        },
        BLOCKED: {
            length: 0,
            content: {
                REST: [],
            },
        },
        FINISHED: {
            length: 0,
            content: {
                REST: [],
            },
        },
    },
};

const DEFAULT_COMPACT_PIPELINE_STATE = {
    length: 0,
    content: {
        REST: {
            length: 0,
            content: [],
        },
    },
};

const VIEW_TYPES = ['CLASSIC', 'COMPACT'];

const COLUMNS_BREAKPOINTS = {
    5: '2xl',
    4: 'xl',
    3: 'lg',
    2: 'md',
    1: 'sm',
};

const ContractsPipeline = ({ pmProjectId }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [view, setView] = useState('CLASSIC');

    const [clients, setClients] = useState([]);
    const [partners, setPartners] = useState([]);
    const [users, setUsers] = useState([]);
    const [contractTypes, setContractTypes] = useState([]);

    const [contracts, setContracts] = useState([]);
    const [formattedContracts, setFormattedContracts] = useState({
        classic: DEFAULT_PIPELINE_STATE,
        compact: DEFAULT_COMPACT_PIPELINE_STATE,
    });

    const { currencyObj, language } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);

    const canView = checkPerm([
        {
            permissionId: '8',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '8',
            permissionType: 'ALL',
        },
    ]);

    const canViewPmContracts = checkPerm([
        {
            permissionId: '48',
            permissionType: 'VIEW',
        },
    ]);

    const canAllPmContracts = checkPerm([
        {
            permissionId: '48',
            permissionType: 'ALL',
        },
    ]);

    const statuses = [
        { id: 'DRAFT', label: t('Draft') },
        { id: 'SENT', label: t('Sent') },
        { id: 'BLOCKED', label: t('Blocked') },
        { id: 'FINISHED', label: t('Finished') },
    ];

    const [filter, setFilter] = useState([]);
    const filterOptions = [
        {
            id: 'statuses',
            icon: <Rows3 />,
            label: t('Statuses'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('Statuses'),
                    render: (value) => {
                        let statusesString = '';
                        value.forEach((v, index) => {
                            const status = statuses.find(
                                (t) => t.id === v,
                            ).label;
                            statusesString += `${status}${index !== value.length - 1 ? ', ' : ''}`;
                        });

                        return statusesString;
                    },
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: statuses,
                        label: t('Select statuses'),
                        render: (item) => item.label,
                    },
                ],
            },
            list: null,
        },
        {
            id: 'entity-group',
            icon: <GroupOutlinedIcon />,
            label: t('Associate'),
            data: null,
            list: [
                {
                    id: 'entity-options',
                    icon: <ClientIcon />,
                    label: 'Client',
                    data: null,
                    list: [
                        {
                            id: 'entityId',
                            icon: <RecentIcon />,
                            label: 'Recent',
                            data: {
                                defaultValue: null,
                                filterData: {
                                    label: 'Client',
                                    render: (value) => {
                                        const data = JSON.parse(
                                            clients.find(
                                                (client) => client.id === value,
                                            ).data,
                                        );
                                        return 'standard' in data &&
                                            'name' in data['standard']
                                            ? data['standard'].name
                                            : 'Nume inexistent';
                                    },
                                    metadata: {
                                        backendKey: 'contactId',
                                    },
                                },
                                inputs: [
                                    {
                                        key: ['id'],
                                        type: 'list',
                                        options: clients.slice(0, 4),
                                        label: t('Select client'),
                                        render: (item) => {
                                            const data = JSON.parse(item.data);
                                            return {
                                                icon: <ClientIcon />,
                                                label:
                                                    'standard' in data &&
                                                    'name' in data['standard']
                                                        ? data['standard'].name
                                                        : 'Nume inexistent',
                                            };
                                        },
                                    },
                                ],
                            },
                            list: null,
                        },
                        {
                            id: 'entityId',
                            icon: <SpecificIcon />,
                            label: t('Specific client'),
                            data: {
                                defaultValue: null,
                                filterData: {
                                    label: 'Client',
                                    render: (value) => {
                                        const data = JSON.parse(
                                            clients.find(
                                                (client) => client.id === value,
                                            ).data,
                                        );
                                        return 'standard' in data &&
                                            'name' in data['standard']
                                            ? data['standard'].name
                                            : 'Nume inexistent';
                                    },
                                    metadata: {
                                        backendKey: 'contactId',
                                    },
                                },
                                inputs: [
                                    {
                                        key: ['id'],
                                        type: 'select',
                                        options: clients,
                                        label: t('Select client'),
                                        render: (item) => {
                                            const data = JSON.parse(item.data);
                                            return 'standard' in data &&
                                                'name' in data['standard']
                                                ? data['standard'].name
                                                : 'Nume inexistent';
                                        },
                                    },
                                ],
                            },
                            list: null,
                        },
                    ],
                },
                {
                    id: 'entity-options',
                    icon: <BusinessOutlinedIcon />,
                    label: t('Partner'),
                    data: null,
                    list: [
                        {
                            id: 'entityId',
                            icon: <RecentIcon />,
                            label: 'Recent',
                            data: {
                                defaultValue: null,
                                filterData: {
                                    label: t('Partner'),
                                    render: (value) => {
                                        const data = JSON.parse(
                                            partners.find(
                                                (partner) =>
                                                    partner.id === value,
                                            ).data,
                                        );
                                        return 'standard' in data &&
                                            'name' in data['standard']
                                            ? data['standard'].name
                                            : 'Nume inexistent';
                                    },
                                    metadata: {
                                        backendKey: 'partnerId',
                                    },
                                },
                                inputs: [
                                    {
                                        key: ['id'],
                                        type: 'list',
                                        options: partners.slice(0, 4),
                                        label: t('Select partner'),
                                        render: (item) => {
                                            const data = JSON.parse(item.data);
                                            return {
                                                icon: (
                                                    <BusinessOutlinedIcon
                                                        style={{
                                                            fontSize: '2.25rem',
                                                        }}
                                                    />
                                                ),
                                                label:
                                                    'standard' in data &&
                                                    'name' in data['standard']
                                                        ? data['standard'].name
                                                        : 'Nume inexistent',
                                            };
                                        },
                                    },
                                ],
                            },
                            list: null,
                        },
                        {
                            id: 'entityId',
                            icon: <SpecificIcon />,
                            label: t('Specific partner'),
                            data: {
                                defaultValue: null,
                                filterData: {
                                    label: t('Partner'),
                                    render: (value) => {
                                        const data = JSON.parse(
                                            partners.find(
                                                (partner) =>
                                                    partner.id === value,
                                            ).data,
                                        );
                                        return 'standard' in data &&
                                            'name' in data['standard']
                                            ? data['standard'].name
                                            : 'Nume inexistent';
                                    },
                                    metadata: {
                                        backendKey: 'partnerId',
                                    },
                                },
                                inputs: [
                                    {
                                        key: ['id'],
                                        type: 'select',
                                        options: partners,
                                        label: t('Select partner'),
                                        render: (item) => {
                                            const data = JSON.parse(item.data);
                                            return 'standard' in data &&
                                                'name' in data['standard']
                                                ? data['standard'].name
                                                : 'Nume inexistent';
                                        },
                                    },
                                ],
                            },
                            list: null,
                        },
                    ],
                },
            ],
        },
        {
            id: 'date-group',
            icon: <CalendarBlankIcon />,
            label: t('Date'),
            data: null,
            list: [
                {
                    id: 'date',
                    icon: <CurrentDayIcon />,
                    label: t('Today'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date())
                                .startDate,
                            endDate: getDayBeginningAndEnding(new Date())
                                .endDate,
                        },
                        filterData: {
                            label: t('Date'),
                            render: (value) =>
                                formatDate(value.startDate, true, true),
                            metadata: {},
                        },
                        inputs: [],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <LastThirtyDaysIcon />,
                    label: t('Last 30 days'),
                    data: {
                        defaultValue: {
                            startDate: (() => {
                                const date = getDayBeginningAndEnding(
                                    new Date(),
                                ).startDate;
                                date.setDate(date.getDate() - 29);
                                return date;
                            })(),
                            endDate: getDayBeginningAndEnding(new Date())
                                .endDate,
                        },
                        filterData: {
                            label: 'Interval',
                            render: (value) =>
                                `${formatDate(value.startDate, true, true)} - ${formatDate(value.endDate, true, true)}`,
                            metadata: {},
                        },
                        inputs: [],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <CalendarSelectionIcon />,
                    label: t('Selected date'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date())
                                .startDate,
                            endDate: getDayBeginningAndEnding(new Date())
                                .endDate,
                        },
                        filterData: {
                            label: t('Date'),
                            render: (value) =>
                                formatDate(value.startDate, true, true),
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['startDate', 'endDate'],
                                type: 'date',
                                options: null,
                                label: t('Choose a date'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'date',
                    icon: <CalendarRangeIcon />,
                    label: t('Selection range'),
                    data: {
                        defaultValue: {
                            startDate: getDayBeginningAndEnding(new Date())
                                .startDate,
                            endDate: getDayBeginningAndEnding(new Date())
                                .endDate,
                        },
                        filterData: {
                            label: 'Interval',
                            render: (value) =>
                                `${formatDate(value.startDate, true, true)} - ${formatDate(value.endDate, true, true)}`,
                            metadata: {},
                        },
                        inputs: [
                            {
                                key: ['startDate'],
                                type: 'date',
                                options: null,
                                label: t('Select the end date'),
                                render: null,
                            },
                            {
                                key: ['endDate'],
                                type: 'date',
                                options: null,
                                label: t('Select the start date'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        {
            id: 'price-group',
            icon: <PriceIcon />,
            label: t('Contract value'),
            data: null,
            list: [
                {
                    id: 'value',
                    icon: <EqualIcon />,
                    label: t('Equal to'),
                    data: {
                        defaultValue: 0,
                        filterData: {
                            label: t('Price equal to'),
                            render: (value) =>
                                `${toLocaleNumber(value, language, 0, 0)} ${currencyObj.currency}`,
                            metadata: {
                                backendKey: 'equal',
                            },
                        },
                        inputs: [
                            {
                                key: ['equal'],
                                type: 'value',
                                options: null,
                                label: t('Enter value'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'value',
                    icon: <GreatEqualIcon />,
                    label: t('Equal or greater then'),
                    data: {
                        defaultValue: 0,
                        filterData: {
                            label: t('Price equal or greater then'),
                            render: (value) =>
                                `${toLocaleNumber(value, language, 0, 0)} ${currencyObj.currency}`,
                            metadata: {
                                backendKey: 'greater',
                            },
                        },
                        inputs: [
                            {
                                key: ['gte'],
                                type: 'value',
                                options: null,
                                label: t('Enter value'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
                {
                    id: 'value',
                    icon: <LessEqualIcon />,
                    label: t('Equal or less then'),
                    data: {
                        defaultValue: 0,
                        filterData: {
                            label: t('Price equal or less then'),
                            render: (value) =>
                                `${toLocaleNumber(value, language, 0, 0)} ${currencyObj.currency}`,
                            metadata: {
                                backendKey: 'smaller',
                            },
                        },
                        inputs: [
                            {
                                key: ['lte'],
                                type: 'value',
                                options: null,
                                label: t('Enter value'),
                                render: null,
                            },
                        ],
                    },
                    list: null,
                },
            ],
        },
        {
            id: 'authorId',
            icon: <AuthorIcon />,
            label: t('Author'),
            data: {
                defaultValue: null,
                filterData: {
                    label: t('Author'),
                    render: (value) =>
                        users.find((u) => u.id === value).profile.name,
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'select',
                        options: users,
                        label: t('Select author'),
                        render: (item) => item.profile.name,
                    },
                ],
            },
            list: null,
        },
        {
            id: 'contractTypeIds',
            icon: <ListAltIcon />,
            label: t('Contract types'),
            data: {
                defaultValue: [],
                filterData: {
                    label: t('Contract types'),
                    render: (value) => {
                        let typesString = '';
                        value.forEach((v, index) => {
                            const type = contractTypes.find(
                                (t) => t.id === v,
                            ).name;
                            typesString += `${type}${index !== value.length - 1 ? ', ' : ''}`;
                        });

                        return typesString;
                    },
                    metadata: {},
                },
                inputs: [
                    {
                        key: ['id'],
                        type: 'multiselect',
                        options: contractTypes,
                        label: t('Select contract types'),
                        render: (item) => item.name,
                    },
                ],
            },
            list: null,
        },
    ];

    const columns = useMemo(() => {
        const defCols = [
            {
                title: t('Drafts'),
                key: 'DRAFT',
                color: '#FB924B',
                backgroundColor: `rgb(${hexToRgb('#FB924B')} / 24%)`,
                borderColor: '#FB924B',
            },
            {
                title: t('Contracts sent'),
                key: 'SENT',
                color: 'var(--main-text)',
                backgroundColor: '#083469',
                borderColor: 'var(--layout-transparent)',
            },
            {
                title: t('Contracts blocked'),
                key: 'BLOCKED',
                color: 'var(--main-text)',
                backgroundColor: '#3587A4',
                borderColor: 'var(--layout-transparent)',
            },
            {
                title: t('Contracts finalized'),
                key: 'FINISHED',
                color: 'var(--main-text)',
                backgroundColor: '#3EC356',
                borderColor: 'var(--layout-transparent)',
            },
        ];

        const statusesFilter = filter.find((f) => f.key === 'statuses');
        if (statusesFilter)
            return defCols.filter((c) => statusesFilter.value.includes(c.key));

        return defCols;
    }, [filter]);

    const sortKeys = [
        {
            key: 'REST',
            title: t('All contracts'),
            tags: [],
            render: true,
        },
    ];

    const [sort, setSort] = useState([
        {
            key: 'totalPrice',
            label: t('Price'),
            value: null,
            type: 'toggle',
            options: [
                {
                    icon: <MoneyOffIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
        {
            key: 'createAt',
            label: t('Creation date'),
            value: 'desc',
            type: 'toggle',
            options: [
                {
                    icon: <EventBusyIcon style={{ fontSize: '2rem' }} />,
                    value: null,
                },
                { icon: <HighestIcon />, value: 'asc' },
                { icon: <LowestIcon />, value: 'desc' },
            ],
        },
    ]);

    const mappedStatusData = {
        DRAFT: {
            label: t('Draft'),
            icon: <TextSelect />,
        },
        SENT: {
            label: t('Contract sent'),
            icon: <Send />,
        },
        BLOCKED: {
            label: t('Contract blocked'),
            icon: <Ban />,
        },
        FINISHED: {
            label: t('Contract finished'),
            icon: <BadgeCheck />,
        },
    };

    const itemProps = {
        header: {
            status: (item) => mappedStatusData[item.metadata.pipelineStatus],
            tags: () => [],
        },
        title: {
            icon: <User />,
            render: (item) =>
                item.contact
                    ? item.contact.data.standard.name
                    : item.Partners
                      ? item.Partners.data.standard.name
                      : item.project
                        ? item.project.contact.data.standard.name
                        : item.data.standard.number,
        },
        head: {
            render: (item) => item.data.standard.number,
        },
        body: {
            renderVisibleRows: (item) => [
                {
                    label: mappedStatusData[item.metadata.pipelineStatus].label,
                    content: formatDate(item.statusHistory.at(-1).createdAt),
                    canRender:
                        item.metadata.pipelineStatus !== 'DRAFT' &&
                        item.metadata.pipelineStatus !== 'SENT',
                },
                {
                    label: t('Sent date'),
                    content: formatDate(
                        item.statusHistory.find(
                            (element) => element.status === 'SENT',
                        ).createdAt,
                    ),
                    canRender: item.metadata.pipelineStatus !== 'DRAFT',
                },
                {
                    label: t('Creation date'),
                    content: formatDate(item.createAt),
                    canRender: true,
                },
                {
                    label: t('Price without VAT'),
                    content: `${toLocaleNumber(item.priceWithoutVat, language, 2, 4)} ${currencyObj.currency.toLowerCase()}`,
                    canRender: true,
                },
                {
                    label: t('Price with VAT'),
                    content: `${toLocaleNumber(item.totalPrice, language, 2, 4)} ${currencyObj.currency.toLowerCase()}`,
                    canRender: true,
                },
            ],
            renderExpandedRows: (item) => [
                {
                    label: t('Last update'),
                    content: formatDate(item.updateAt),
                    canRender: true,
                },
                {
                    label:
                        item.metadata.pipelineStatus === 'DRAFT'
                            ? t('Sent to')
                            : t('To'),
                    content: item.contact
                        ? item.contact.data.standard.email
                        : item.project
                          ? item.project.contact.data.standard.email
                          : '-',
                    canRender: item.contact || item.project,
                },
                {
                    label: t('Author'),
                    content: item.author.profile.name,
                    canRender: true,
                },
                {
                    label: t('Contract type'),
                    content: item.contractType.name,
                    canRender: true,
                },
            ],
        },
        file: {
            render: (item) =>
                t("{{associate}}'s - Contract", {
                    associate: item.contact
                        ? item.contact.data.standard.name
                        : item.Partners
                          ? item.Partners.data.standard.name
                          : item.project
                            ? item.project.contact.data.standard.name
                            : item.data.standard.number,
                }),
            info: { icon: <FileCheck />, text: 'pdf' },
            disabled: false,
            actions: [
                {
                    icon: <Download />,
                    text: t('Download file'),
                    action: async (item) => {
                        try {
                            const file = await fetch(item.templatePdf);
                            const blob = await file.blob();

                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute(
                                'download',
                                `${t("{{associate}}'s - Contract", {
                                    associate: item.contact
                                        ? item.contact.data.standard.name
                                        : item.Partners
                                          ? item.Partners.data.standard.name
                                          : item.project
                                            ? item.project.contact.data.standard
                                                  .name
                                            : item.data.standard.number,
                                })}.pdf`,
                            );

                            document.body.appendChild(link);
                            link.click();

                            document.body.removeChild(link);
                        } catch (error) {
                            console.error('Error downloading file:', error);
                            enqueueSnackbar(errorHandling(error), {
                                variant: 'error',
                            });
                        }
                    },
                    disabled: false,
                    confirm: false,
                },
            ],
            canRender: (item) => Boolean(item.templatePdf),
        },
        button: {
            render: () => t('Open contract'),
            icon: <ArrowUpRight />,
            disabled: false,
            action: (item) =>
                history.push(
                    pmProjectId
                        ? projectInManagement.base +
                              '/' +
                              pmProjectId +
                              projectInManagement.projectContracts.base +
                              projectInManagement.projectContracts.update +
                              '/' +
                              item.id
                        : crm.base +
                              crm.contracts.base +
                              crm.contracts.update +
                              '/' +
                              item.id,
                ),
            canRender: () =>
                Boolean(canAll && !pmProjectId) ||
                Boolean(canAllPmContracts && pmProjectId),
        },
    };

    const getUsers = async () => {
        try {
            const response = await API.get('/tenants', {
                params: {
                    currentPage: 0,
                    perPage: 99999,
                    pagesToLoad: 1,
                },
            });

            setUsers(response.data.users);
        } catch (err) {
            console.error(err);
            throw new Error(err);
        }
    };

    const getPartners = async () => {
        try {
            const response = await API.get('/partners', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                },
            });

            setPartners(response.data.formattedPartners);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const getClients = async () => {
        try {
            const response = await API.get('/contacts', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                    type: 'ALL',
                },
            });
            const fetchedClients = response.data.data.contacts;

            setClients(fetchedClients);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const getContractTypes = async () => {
        try {
            const res = await API.get('contractTypes', {
                params: {
                    perPage: 99999,
                    currentPage: 0,
                    pagesToLoad: 1,
                },
            });

            setContractTypes(res.data.contractTypes.contractTypes);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const handleFormatContracts = (contracts) => {
        const mappedContracts = contracts.map((contract) => ({
            ...contract,
            data:
                typeof contract.data === 'string'
                    ? JSON.parse(contract.data)
                    : contract.data,
            Partners: contract.Partners
                ? typeof contract.Partners.data === 'string'
                    ? {
                          ...contract.Partners,
                          data: JSON.parse(contract.Partners.data),
                      }
                    : contract.Partners
                : null,
            contact: contract.contact
                ? typeof contract.contact.data === 'string'
                    ? {
                          ...contract.contact,
                          data: JSON.parse(contract.contact.data),
                      }
                    : contract.contact
                : null,
            project: contract.project
                ? typeof contract.project.contact.data === 'string'
                    ? {
                          ...contract.project,
                          contact: {
                              ...contract.project.contact,
                              data: JSON.parse(contract.project.contact.data),
                          },
                      }
                    : contract.project
                : null,
        }));

        const { classic, compact } = mappedContracts.reduce(
            (acc, curr) => ({
                classic: {
                    ...acc.classic,
                    length: contracts.length,
                    content: {
                        ...acc.classic.content,
                        [curr.statusHistory.at(-1).status]: {
                            ...acc.classic.content[
                                curr.statusHistory.at(-1).status
                            ],
                            length:
                                acc.classic.content[
                                    curr.statusHistory.at(-1).status
                                ].length + 1,
                            content: {
                                ...acc.classic.content[
                                    curr.statusHistory.at(-1).status
                                ].content,
                                ['REST']: [
                                    ...acc.classic.content[
                                        curr.statusHistory.at(-1).status
                                    ].content['REST'],
                                    {
                                        ...curr,
                                        metadata: {
                                            ...curr.metadata,
                                            pipelineStatus:
                                                curr.statusHistory.at(-1)
                                                    .status,
                                            pipelineKey: 'REST',
                                        },
                                    },
                                ],
                            },
                        },
                    },
                },
                compact: {
                    ...acc.compact,
                    length: contracts.length,
                    content: {
                        ...acc.compact.content,
                        ['REST']: {
                            length: acc.compact.content['REST'].length + 1,
                            content: [
                                ...acc.compact.content['REST'].content,
                                {
                                    ...curr,
                                    metadata: {
                                        ...curr.metadata,
                                        pipelineStatus:
                                            curr.statusHistory.at(-1).status,
                                        pipelineKey: 'REST',
                                    },
                                },
                            ],
                        },
                    },
                },
            }),
            {
                classic: DEFAULT_PIPELINE_STATE,
                compact: DEFAULT_COMPACT_PIPELINE_STATE,
            },
        );

        return { classic, compact };
    };

    const getContracts = async (sort, filter) => {
        try {
            const querySort = sort
                .filter((element) => element.value !== null)
                .reduce(
                    (acc, curr) => ({ ...acc, [curr.key]: curr.value }),
                    {},
                );

            const queryFilter = filter.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.metadata.backendKey ?? curr.key]: curr.value,
                }),
                {},
            );

            const response = await API.get(
                pmProjectId ? 'pm_contract_pipeline' : 'contract_pipeline',
                {
                    params: {
                        ...querySort,
                        ...queryFilter,
                        projectId: pmProjectId ?? undefined,
                    },
                },
            );
            const formattedContracts = handleFormatContracts(
                response.data.contracts,
            );

            setFormattedContracts(formattedContracts);
            setContracts(response.data.contracts);
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    useEffect(() => {
        if (
            !(canView && !pmProjectId) &&
            !(canViewPmContracts && pmProjectId)
        ) {
            history.push('/');
            return;
        }

        (async () => {
            try {
                await Promise.all([
                    getContracts(sort, filter),
                    getUsers(),
                    getClients(),
                    getPartners(),
                    getContractTypes(),
                ]);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(t(errorHandling(error)), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, [canView, canViewPmContracts]);

    const handleSort = async (sort, lastAppliedSort) => {
        try {
            setLoading(true);
            await Promise.all([
                getContracts(sort, filter),
                getUsers(),
                getClients(),
                getPartners(),
                getContractTypes(),
            ]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilter = async (filter) => {
        try {
            setLoading(true);
            await Promise.all([
                getContracts(sort, filter),
                getUsers(),
                getClients(),
                getPartners(),
                getContractTypes(),
            ]);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    {pmProjectId ? 'PM' : 'CRM'} | {t('Contracts pipeline')}
                </title>
            </Helmet>

            <Header
                pageTitle={t('Contracts pipeline')}
                pageIcon={<KanbanSquare />}
                action={
                    <>
                        {(Boolean(canAll && !pmProjectId) ||
                            Boolean(canAllPmContracts && pmProjectId)) && (
                            <Button
                                color="secondary"
                                startIcon={<AddIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={
                                    pmProjectId
                                        ? () =>
                                              history.push(
                                                  projectInManagement.base +
                                                      '/' +
                                                      pmProjectId +
                                                      projectInManagement
                                                          .projectContracts
                                                          .base +
                                                      projectInManagement
                                                          .projectContracts
                                                          .create,
                                              )
                                        : () =>
                                              history.push(
                                                  crm.base +
                                                      crm.contracts.base +
                                                      crm.contracts.create,
                                              )
                                }
                            >
                                {t('add-new-contract')}
                            </Button>
                        )}
                    </>
                }
                toolbar={
                    <div className="flex w-full items-center justify-between gap-3">
                        <Tabs
                            tabs={VIEW_TYPES.map((type) => t(type))}
                            activeTab={VIEW_TYPES.indexOf(view)}
                            setActiveTab={(i) => setView(VIEW_TYPES[i])}
                        />
                        <Sort
                            disabled={
                                loading || formattedContracts.length === 0
                            }
                            sort={sort}
                            setSort={setSort}
                            onSort={handleSort}
                        />
                    </div>
                }
                toolbarSecondary={
                    <Filter
                        disabled={loading}
                        filter={filter}
                        setFilter={setFilter}
                        filterOptions={filterOptions}
                        onFilter={handleFilter}
                        mobileBP="md"
                    />
                }
            />

            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '70vh' }} />
                ) : contracts.length ? (
                    <>
                        {view === 'CLASSIC' && (
                            <PipelineContent
                                columns={columns}
                                sortKeys={sortKeys}
                                itemProps={itemProps}
                                data={formattedContracts.classic}
                                mobileBP={COLUMNS_BREAKPOINTS[columns.length]}
                            />
                        )}
                        {view === 'COMPACT' && (
                            <PipelineCompactContent
                                columns={columns}
                                sortKeys={sortKeys}
                                itemProps={itemProps}
                                data={formattedContracts.compact}
                            />
                        )}
                    </>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>
        </>
    );
};

ContractsPipeline.propTypes = {
    pmProjectId: PropTypes.string,
};

ContractsPipeline.defaultProps = {
    pmProjectId: null,
};

export default ContractsPipeline;
