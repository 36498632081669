import React, { useContext, useEffect, useState } from 'react';

import GlobalContext from 'contexts/GlobalContext';
import OfferContext from 'contexts/OfferContext';
import PropTypes from 'prop-types';
import { LabelWrapper, MultiDropdown } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { toLocaleNumber } from 'utils';

const AttributesAndVariationsRow = (props) => {
    const { article, editArticle, disabled } = props;
    const { currencyObj, language } = useContext(GlobalContext);

    const { offerContext } = useContext(OfferContext);

    const [attributesSelected, setAttributesSelected] = useState([]);
    const [variationsSelected, setVariationsSelected] = useState([]);

    const { t } = useTranslation();

    // we set the attributes and variations with the selected ones from article on edit
    useEffect(() => {
        if (article.OfferArticles?.length) {
            // This if check if in OfferArticles from article exist the offer who we want to edit
            if (article.OfferArticles.findIndex((art) => art.offerId === offerContext.offerId) !== -1) {
                // We set the indexes of the selected attributes
                const attributeIndexes = article.OfferArticles[
                    article.OfferArticles.findIndex((art) => art.offerId === offerContext.offerId)
                ].OfferArticleAttributes?.map((atr) => {
                    return article.ArticleAttribute?.findIndex(
                        (elm) => elm.id === atr.articleAttribute.originalArticleAttributeId,
                    );
                });

                setAttributesSelected(attributeIndexes);

                // If are selected attributes we will edit the article and we add the selected attributes
                if (attributeIndexes?.length) {
                    attributeIndexes.map((attribute) => {
                        const attributeToAdd = article.ArticleAttribute[attribute];
                        const newSelectedAttributes = [...article.selectedAttributes, attributeToAdd];
                        editArticle(article.id, 'selectedAttributes', newSelectedAttributes);
                    });
                }

                // We set the indexes of the selected variations
                const variationIndexes = article.OfferArticles[
                    article.OfferArticles.findIndex((art) => art.offerId === offerContext.offerId)
                ].OfferArticlesVariations?.map((variation) => {
                    return article.ArticleVariations?.findIndex(
                        (elm) => elm.id === variation.articleVariation.originalArticleVariationId,
                    );
                });

                setVariationsSelected(variationIndexes);

                // If are selected variations we will edit the article and we add the selected variations
                if (variationIndexes?.length) {
                    variationIndexes.map((variation) => {
                        const variationToAdd = article.ArticleVariations[variation];
                        const newSelectedVariations = [...article.selectedVariations, variationToAdd];
                        editArticle(article.id, 'selectedVariations', newSelectedVariations);
                    });
                }
            }
        }
    }, [article]);

    return (
        <div className="mb-10">
            <div className="flex justify-between gap-6 sm:flex-col">
                <div className="mt-4 flex-1 space-y-4 text-white">
                    <MultiDropdown
                        variant="black"
                        placeholder={t('Attributes')}
                        options={article.ArticleAttribute.map((attribute) => attribute.name)}
                        selectedOptions={attributesSelected}
                        setSelectedOptions={(attribute) => {
                            if (attributesSelected.indexOf(attribute) > -1) {
                                setAttributesSelected(attributesSelected.filter((opt) => opt !== attribute));
                                const newSelectedAttributesAfterDelete = article.selectedAttributes.filter(
                                    (attr) => attr.id !== article.ArticleAttribute[attribute].id,
                                );
                                editArticle(article.id, 'selectedAttributes', newSelectedAttributesAfterDelete);
                            } else {
                                setAttributesSelected([...attributesSelected, attribute]);
                                const attributeToAdd = article.ArticleAttribute[attribute];
                                const newSelectedAttributes = [...article.selectedAttributes, attributeToAdd];
                                editArticle(article.id, 'selectedAttributes', newSelectedAttributes);
                            }
                        }}
                        disabled={disabled}
                    />

                    {article.selectedAttributes?.map((attribute, index) => (
                        <div
                            key={attribute.id}
                            className="rounded-md border border-layout-transparent-dark bg-layout-transparent p-2"
                        >
                            <div className="flex items-center">
                                <span className="mr-2 text-main-text">{index + 1}.</span>
                                <div className="flex-1 rounded-md bg-layout-transparent p-2 text-center text-main-text">
                                    {attribute.name}
                                </div>
                            </div>
                            <div className="mt-2 flex gap-2">
                                <LabelWrapper label={t('Unit price')}>
                                    <div className="flex-1 rounded-md border border-layout-transparent-dark bg-layout-transparent p-1 text-center text-main-text">
                                        {toLocaleNumber(attribute.price.pricePerUnit, language, 2)}{' '}
                                        {currencyObj.currency}
                                    </div>
                                </LabelWrapper>

                                <LabelWrapper label={t('VAT rate')}>
                                    <div className="flex-1 rounded-md border border-layout-transparent-dark bg-layout-transparent p-1 text-center text-main-text">
                                        {toLocaleNumber(attribute.price.procentualVAT, language, 2)} %
                                    </div>
                                </LabelWrapper>

                                <LabelWrapper label={t('UM')}>
                                    <div className="flex-1 rounded-md border border-layout-transparent-dark bg-layout-transparent p-1 text-center text-main-text">
                                        {attribute.price.unit}
                                    </div>
                                </LabelWrapper>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mt-4 flex-1 space-y-4 text-white">
                    <MultiDropdown
                        variant="black"
                        placeholder={t('Variations')}
                        options={article.ArticleVariations.map((variation) => variation.name)}
                        selectedOptions={variationsSelected}
                        setSelectedOptions={(variation) => {
                            if (variationsSelected.indexOf(variation) > -1) {
                                setVariationsSelected(variationsSelected.filter((opt) => opt !== variation));
                                const newSelectedVariationsAfterDelete = article.selectedVariations.filter(
                                    (vari) => vari.id !== article.ArticleVariations[variation].id,
                                );
                                editArticle(article.id, 'selectedVariations', newSelectedVariationsAfterDelete);
                            } else {
                                setVariationsSelected([...variationsSelected, variation]);
                                const variationToAdd = article.ArticleVariations[variation];
                                const newSelectedVariations = [...article.selectedVariations, variationToAdd];
                                editArticle(article.id, 'selectedVariations', newSelectedVariations);
                            }
                        }}
                        disabled={disabled}
                    />

                    {article.selectedVariations?.map((variation, index) => (
                        <div
                            key={variation.id}
                            className="rounded-md border border-layout-transparent-dark bg-layout-transparent p-2"
                        >
                            <div className="flex items-center">
                                <span className="mr-2 text-main-text">{index + 1}.</span>
                                <div className="flex-1 rounded-md bg-layout-transparent p-2 text-center text-main-text">
                                    {variation.name}
                                </div>
                            </div>
                            <div className="mt-2 flex gap-2">
                                <LabelWrapper label={t('Unit price')}>
                                    <div className="flex-1 rounded-md border border-layout-transparent-dark bg-layout-transparent p-1 text-center text-main-text">
                                        {toLocaleNumber(variation.price.pricePerUnit, language, 2)}{' '}
                                        {currencyObj.currency}
                                    </div>
                                </LabelWrapper>

                                <LabelWrapper label={t('VAT rate')}>
                                    <div className="flex-1 rounded-md border border-layout-transparent-dark bg-layout-transparent p-1 text-center text-main-text">
                                        {toLocaleNumber(variation.price.procentualVAT, language, 2)} %
                                    </div>
                                </LabelWrapper>

                                <LabelWrapper label={t('UM')}>
                                    <div className="flex-1 rounded-md border border-layout-transparent-dark bg-layout-transparent p-1 text-center text-main-text">
                                        {variation.price.unit}
                                    </div>
                                </LabelWrapper>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

AttributesAndVariationsRow.propTypes = {
    editArticle: PropTypes.func,
    article: PropTypes.object,
    disabled: PropTypes.bool,
};

AttributesAndVariationsRow.defaultProps = {
    editArticle: () => null,
    article: {},
    disabled: false,
};

export default AttributesAndVariationsRow;
