import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';

import { Button } from '@material-ui/core';

import TableDisplay from 'components/shared/configurator/table-display';
import UserContext from 'contexts/UserContext';
import { PropTypes } from 'prop-types';
import { Header, Search } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm, projectInManagement as pmRoute } from 'routes';

const ManageContracts = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pmProjectId } = props;

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '8',
            permissionType: 'ALL',
        },
    ]);

    const canAllPmContracts = checkPerm([
        {
            permissionId: '60',
            permissionType: 'ALL',
        },
    ]);

    const canView = checkPerm([
        {
            permissionId: '8',
            permissionType: 'VIEW',
        },
    ]);

    const [query, setQuery] = useState('');

    return (
        <>
            <Helmet>
                <title>
                    {pmProjectId ? 'PM' : 'CRM'} | {t('manage-contracts')}
                </title>
            </Helmet>

            <Header
                pageTitle={t('manage-contracts')}
                action={
                    <>
                        <Search
                            searchIconBig={true}
                            value={query}
                            setValue={setQuery}
                            withSearchIcon={false}
                        />
                        {(canAll && !pmProjectId) ||
                        (canAllPmContracts && pmProjectId) ? (
                            <Button
                                color="secondary"
                                startIcon={<AddIcon />}
                                style={{ borderRadius: '999px' }}
                                onClick={
                                    pmProjectId
                                        ? () =>
                                              history.push(
                                                  pmRoute.base +
                                                      '/' +
                                                      pmProjectId +
                                                      pmRoute.projectContracts
                                                          .base +
                                                      pmRoute.projectContracts
                                                          .create,
                                              )
                                        : () =>
                                              history.push(
                                                  crm.base +
                                                      crm.contracts.base +
                                                      crm.contracts.create,
                                              )
                                }
                            >
                                {t('add-new-contract')}
                            </Button>
                        ) : null}
                    </>
                }
            />

            {canView && (
                <div className="page-container relative">
                    <TableDisplay
                        entity={pmProjectId ? 'ContractPM' : 'Contracts'}
                        pmProjectId={pmProjectId}
                        query={query}
                    />
                </div>
            )}
        </>
    );
};

ManageContracts.propTypes = {
    pmProjectId: PropTypes.string,
};

ManageContracts.defaultProps = {
    pmProjectId: null,
};

export default ManageContracts;
