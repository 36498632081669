import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const Search = (props) => {
    const { t } = useTranslation();
    const { value, setValue, light, searchIconBig, disabled } = props;
    const isSearch = value.trim().length > 0;

    const handleRemoveSearch = () => setValue('');

    return (
        <div className="search-input-container">
            {/* Search icon */}
            <div className="absolute left-2 top-1/2 flex -translate-y-1/2 transform">
                <SearchIcon
                    className={`self-center ${searchIconBig ? 'search-icon-big' : 'search-icon'} ${disabled ? 'disabled' : ''}`}
                />
            </div>

            {/* Input */}
            <input
                className={`outline-none rounded-full py-2 pl-12 pr-12 font-light placeholder-buttons-text placeholder-opacity-75 ${
                    light ? 'border-white bg-transparent' : 'border-layout-transparent-dark bg-layout-transparent'
                } ${searchIconBig ? 'text-xl' : ''} ${disabled ? 'text-disabled' : 'text-buttons-text'}`}
                name="search"
                placeholder={t('search...')}
                value={value}
                autoComplete="off"
                disabled={disabled}
                onChange={(e) => setValue(e.target.value)}
            />

            {/* Close icon */}
            {isSearch && (
                <div
                    className="absolute right-2 top-1/2 flex -translate-y-1/2 transform cursor-pointer self-center"
                    onClick={handleRemoveSearch}
                >
                    <CloseIcon className={`self-center ${searchIconBig ? 'search-icon-big' : 'search-icon'}`} />
                </div>
            )}
        </div>
    );
};
Search.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    light: PropTypes.bool,
    searchIconBig: PropTypes.bool,
    disabled: PropTypes.bool,
};
Search.defaultProps = {
    value: '',
    setValue: () => null,
    light: false,
    searchIconBig: false,
    disabled: false,
};
