import React, { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { TextField } from '@material-ui/core';

import ErrorButton from 'components/shared/error-button';
import SuccessButton from 'components/shared/success-button';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import * as yup from 'yup';

import { RiemContext } from '../riem';

const RiemFooterForm = ({ riem }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { setIsOperatingOnRowAt, onEditInfo } = useContext(RiemContext);
    const [isMounted, setIsMounted] = useState(false);
    const [formState, setFormState] = useState(riem.footer);
    const formRef = useRef(null);
    const handleChangeField = (e) => setFormState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    const schema = yup.object().shape({
        total: yup
            .string()
            .typeError(t('The total score label is mandatory'))
            .required(t('The total score label is mandatory')),
    });
    const handleSuccessAction = async () => {
        try {
            await schema.validate(formState);
            onEditInfo(formState, riem.id, 'footer');
            setIsOperatingOnRowAt(null);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        }
    };
    useEffect(() => {
        const form = formRef.current;
        if (!form) return;
        const firstInput = form.querySelector('input');
        firstInput.focus({ preventScroll: true });
        firstInput.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        setIsMounted(true);
    }, []);
    return (
        <form
            ref={formRef}
            role="row"
            className="col-span-full grid border border-black bg-layout-transparent-dark text-justify"
            style={{
                gridTemplateColumns: 'subgrid',
            }}
        >
            <div role="cell" className="col-span-3 flex items-center justify-center px-2 py-1">
                <LabelWrapper label={t('Total score label')}>
                    <TextField
                        name="total"
                        placeholder={t('Total score label')}
                        value={formState.total}
                        onChange={handleChangeField}
                    />
                </LabelWrapper>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-xl font-bold">
                    {riem.items.reduce((acc, curr) => (acc += curr.identifiedScore), 0)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-xl font-bold">
                    {riem.items.reduce((acc, curr) => (acc += curr.identifiedProbability), 0)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-xl font-bold">
                    {riem.items.reduce((acc, curr) => (acc += curr.identifiedTotal), 0)}
                </p>
            </div>
            <div role="cell" />
            <div role="cell" />
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-xl font-bold">
                    {riem.items.reduce((acc, curr) => (acc += curr.residualScore), 0)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-xl font-bold">
                    {riem.items.reduce((acc, curr) => (acc += curr.residualProbability), 0)}
                </p>
            </div>
            <div role="cell" className="flex items-center justify-center">
                <p className="px-0.5 py-1 text-xl font-bold">
                    {riem.items.reduce((acc, curr) => (acc += curr.residualTotal), 0)}
                </p>
            </div>
            <div role="cell" />
            <div role="cell" />

            {isMounted &&
                createPortal(
                    <div className="flex flex-col items-center justify-center gap-2">
                        <SuccessButton startIcon={<CheckCircleIcon />} size="small" onClick={handleSuccessAction}>
                            {t('Complete')}
                        </SuccessButton>
                        <ErrorButton
                            startIcon={<CancelIcon />}
                            size="small"
                            onClick={() => setIsOperatingOnRowAt(null)}
                        >
                            {t('Cancel')}
                        </ErrorButton>
                    </div>,
                    document.getElementById('riem-footer-container'),
                )}
        </form>
    );
};
RiemFooterForm.propTypes = {
    riem: PropTypes.object,
};
RiemFooterForm.defaultProps = {
    riem: {},
};
export default RiemFooterForm;
