import React, { useCallback, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';

import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AccountingAccounts } from 'api/Accounting_Accounts';
import { Revenues } from 'api/Expenses_Revenues';
import BasicTooltip from 'components/shared/basic-tooltip';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, Dropdown, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { accountancy } from 'routes';
import { errorHandling } from 'utils';
import * as yup from 'yup';

const RevenueForm = ({ revenueId, canEdit, setInitializeData }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [width] = useScreenSizes();

    // Main state for storing the data for the REVENUES
    const [loadingRevenue, setLoadingRevenue] = useState(!!revenueId);
    const [loadingRevenuesSubmission, setLoadingRevenuesSubmission] = useState(false);
    const [revenue, setRevenue] = useState({
        name: '',
        accountingAccount: null,
        cashflow: null,
        subRevenueNames: [],
    });

    // This state is used to compere the initial revenue data with the revenue data from the database
    // in order to disable the creation or edit button
    const [initialJSON, setInitialJSON] = useState(
        JSON.stringify({
            name: '',
            subRevenueNames: [],
        }),
    );

    const cashflowTypes = ['INVESTMENT', 'OPERATIONAL'];

    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [initialAccount, setInitialAccount] = useState(null);

    useEffect(() => {
        (async () => {
            if (!revenueId) return;

            Revenues.getById(revenueId).then((res) => {
                if (res.ok) {
                    if (accounts.length) {
                        const revenueWithAccountingAccounts = {
                            id: res.data.id,
                            name: res.data.name,
                            isDeleted: res.data.isDeleted,
                            cashflow: cashflowTypes.findIndex((type) => type === res.data.cashflow),
                            accountingAccount: accounts.findIndex(
                                (account) => account.id === res.data.accountingAccountId,
                            ),
                            subRevenueNames: res.data.subRevenueNames
                                .map((subRev) => ({
                                    id: subRev.id,
                                    name: subRev.name,
                                    isDeleted: subRev.isDeleted,
                                    cashflow: cashflowTypes.findIndex((type) => type === subRev.cashflow),
                                    accountingAccount: accounts.findIndex(
                                        (account) => account.id === subRev.accountingAccountId,
                                    ),
                                    subRevenueNames: subRev.subRevenueNames
                                        .map((subSubRev) => ({
                                            id: subSubRev.id,
                                            name: subSubRev.name,
                                            isDeleted: subSubRev.isDeleted,
                                            cashflow: cashflowTypes.findIndex((type) => type === subSubRev.cashflow),
                                            accountingAccount: accounts.findIndex(
                                                (account) => account.id === subSubRev.accountingAccountId,
                                            ),
                                        }))
                                        .filter((subSubRev) => subSubRev.isDeleted === false),
                                }))
                                .filter((subRev) => subRev.isDeleted === false),
                        };
                        setRevenue(revenueWithAccountingAccounts);
                        setInitialJSON(JSON.stringify(revenueWithAccountingAccounts));
                    }
                } else {
                    console.error(res.error);
                    enqueueSnackbar(
                        errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                        {
                            variant: 'error',
                        },
                    );
                }
                setLoadingRevenue(false);
            });
        })();
    }, [accounts, revenueId]);

    useEffect(() => {
        AccountingAccounts.getAll().then((res) => {
            if (res.ok) {
                setAccounts(res.data);
            } else {
                console.error(res.error);
                enqueueSnackbar(
                    errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                    {
                        variant: 'error',
                    },
                );
            }
        });
    }, []);

    /**
     * This useEffect is used for setting the state of the dropdown
     *  as we are searching for the index of the selected value in the array of accounts
     */
    useEffect(() => {
        if (selectedAccount && accounts.length > 0) {
            const selectingIndex = accounts.findIndex((indx) => Number(indx.id) === Number(selectedAccount));
            if (selectingIndex > -1) {
                setSelectedAccount(selectingIndex);
                setInitialAccount(selectingIndex);
            }
        }
    }, [selectedAccount, accounts]);

    // ----------------------------------------------------------------

    /**
     * Functions for revenues
     */

    const addRevenue = useCallback(() => {
        const newRevenue = { ...revenue };
        newRevenue.subRevenueNames.push({
            id: Date.now(),
            name: '',
            cashflow: null,
            accountingAccount: null,
            subRevenueNames: [],
        });
        setRevenue(newRevenue);
    }, [revenue]);

    const updateRevenue = useCallback(
        (revenueIndex, revenueName = undefined, accountingAccountIndex = undefined, cashflowTypeIndex = undefined) => {
            const newRevenue = { ...revenue };
            const revenueToUpdate = newRevenue.subRevenueNames[revenueIndex];

            if (revenueName !== undefined) {
                revenueToUpdate.name = revenueName;
            }

            if (accountingAccountIndex !== undefined) {
                revenueToUpdate.accountingAccount = accountingAccountIndex;
            }

            if (cashflowTypeIndex !== undefined) {
                revenueToUpdate.cashflow = cashflowTypeIndex;
            }

            setRevenue(newRevenue);
        },
        [revenue],
    );

    const deleteRevenue = useCallback(
        (id) => {
            const newRevenue = { ...revenue };
            newRevenue.subRevenueNames = newRevenue.subRevenueNames.filter((rev) => rev.id !== id);
            setRevenue(newRevenue);
        },
        [revenue],
    );

    /**
     * Functions for sub-revenues
     */

    const addSubRevenue = useCallback(
        (revenueIndex) => {
            const newRevenue = { ...revenue };
            newRevenue.subRevenueNames[revenueIndex].subRevenueNames.push({
                id: Date.now(),
                name: '',
                cashflow: null,
                accountingAccount: null,
            });
            setRevenue(newRevenue);
        },
        [revenue],
    );

    const updateSubRevenue = useCallback(
        (
            revenueIndex,
            subRevenueIndex,
            revenueName = undefined,
            accountingAccountIndex = undefined,
            cashflowTypeIndex = undefined,
        ) => {
            const newRevenue = { ...revenue };
            const revenueToUpdate = newRevenue.subRevenueNames[revenueIndex].subRevenueNames[subRevenueIndex];

            if (revenueName !== undefined) {
                revenueToUpdate.name = revenueName;
            }

            if (accountingAccountIndex !== undefined) {
                revenueToUpdate.accountingAccount = accountingAccountIndex;
            }

            if (cashflowTypeIndex !== undefined) {
                revenueToUpdate.cashflow = cashflowTypeIndex;
            }

            setRevenue(newRevenue);
        },
        [revenue],
    );

    const deleteSubRevenue = useCallback(
        (id) => {
            const newRevenue = { ...revenue };
            newRevenue.subRevenueNames.map((subRev1) => {
                subRev1.subRevenueNames = subRev1.subRevenueNames.filter((rev) => rev.id !== id);
            });
            setRevenue(newRevenue);
        },
        [revenue],
    );

    // ----------------------------------------------------------------

    /**
     * Function for deleting revenue or sub-revenue. After request with the id, we call
     * function for deleting revenues or sub-revenues from frontend
     * @param {*} id - of the revenue or sub-revenue
     * @param {*} type - revenue for Revenues or sub-revenue for Sub-revenues (to now that function for deleting we will call)
     */
    const deleteRevenueOrSubRevenue = async (id, type) => {
        if (type === 'revenue') {
            deleteRevenue(id);
        } else {
            deleteSubRevenue(id);
        }

        if (typeof id === 'string') {
            Revenues.delete(id).then((res) => {
                if (res.ok) {
                    type == 'revenue'
                        ? enqueueSnackbar(t('Revenue deleted successfully!'), { variant: 'success' })
                        : enqueueSnackbar(t('Sub-revenue deleted successfully!'), { variant: 'success' });
                } else {
                    console.error(res.error);
                    enqueueSnackbar(
                        errorHandling(res.error).length > 100 ? errorHandling(res.error) : t(errorHandling(res.error)),
                        {
                            variant: 'error',
                        },
                    );
                }
            });
        }
    };

    const schema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .typeError(t('The title of the revenue is mandatory!'))
            .min(3, t('The title of the revenue must be at least 3 characters long!'))
            .required(t('The title of the revenue is mandatory!')),
        accountingAccount: yup
            .number()
            .typeError(t('The revenue must have an accounting account!'))
            .required(t('The revenue must have an accounting account!')),
        cashflow: yup
            .number()
            .typeError(t('The revenue must have a Cashflow propagation!'))
            .required(t('The revenue must have a Cashflow propagation!')),
        subRevenueNames: yup
            .array()
            .of(
                yup.object({
                    name: yup
                        .string()
                        .trim()
                        .typeError(t('The name of the revenue is mandatory!'))
                        .min(3, t('The name of the revenue must be at least 3 characters long!'))
                        .required(t('The name of the revenue is mandatory!')),
                    accountingAccount: yup
                        .number()
                        .typeError(t('The revenue must have an accounting account!'))
                        .required(t('The revenue must have an accounting account!')),
                    cashflow: yup
                        .number()
                        .typeError(t('The revenue must have a Cashflow propagation!'))
                        .required(t('The revenue must have a Cashflow propagation!')),
                    subRevenueNames: yup.array().of(
                        yup.object({
                            cashflow: yup
                                .number()
                                .when('name', {
                                    is: (value) => value !== '',
                                    then: yup
                                        .number()
                                        .typeError(t('The sub-revenue must have a Cashflow propagation!'))
                                        .required(t('The sub-revenue must have a Cashflow propagation!')),
                                })
                                .typeError(t('The name of the sub-revenue is mandatory!')),
                            name: yup
                                .string()
                                .trim()
                                .typeError(t('The name of the sub-revenue is mandatory!'))
                                .min(3, t('The name of the sub-revenue must be at least 3 characters long!'))
                                .required(t('The name of the sub-revenue is mandatory!')),
                            accountingAccount: yup
                                .number()
                                .when('name', {
                                    is: (value) => value !== '',
                                    then: yup
                                        .number()
                                        .typeError(t('The sub-revenue must have an accounting account!'))
                                        .required(t('The sub-revenue must have an accounting account!')),
                                })
                                .typeError(t('The name of the sub-revenue is mandatory!')),
                        }),
                    ),
                }),
            )
            .min(1, t('You must declare at least one revenue name!'))
            .required(t('You must declare at least one revenue name!')),
    });

    const submitRevenue = useCallback(async () => {
        const formatSubRevenue = (subRevenue) => {
            const newSubRevenue = {
                id: typeof subRevenue.id === 'number' ? undefined : subRevenue.id,
                name: subRevenue.name,
                cashflow: cashflowTypes[subRevenue.cashflow],
                accountingAccountId: accounts[subRevenue.accountingAccount].id,
                subRevenueNames:
                    'subRevenueNames' in subRevenue
                        ? subRevenue.subRevenueNames.map((se) => formatSubRevenue(se))
                        : undefined,
            };

            return newSubRevenue;
        };

        if (revenueId) {
            // Update revenue

            try {
                setLoadingRevenuesSubmission(true);

                await schema.validate({
                    name: revenue.name,
                    cashflow: revenue.cashflow,
                    accountingAccount: revenue.accountingAccount,
                    subRevenueNames: revenue.subRevenueNames,
                });

                delete revenue.isDeleted;
                delete revenue.parentId;
                delete revenue.tenantId;
                delete revenue.createdAt;
                delete revenue.updatedAt;

                const editRequestBody = {
                    ...revenue,
                    cashflow: cashflowTypes[revenue.cashflow],
                    accountingAccountId: accounts[revenue.accountingAccount].id,
                    subRevenueNames: revenue.subRevenueNames.map((subRevenue) => formatSubRevenue(subRevenue)),
                };

                delete editRequestBody.accountingAccount;

                Revenues.edit(editRequestBody).then((res) => {
                    if (res.ok) {
                        enqueueSnackbar(t('Revenue updated successfully!'), { variant: 'success' });
                        history.push(accountancy.base + accountancy.revenueNomenclature.base);
                    } else {
                        console.error(res.error);
                        throw res.error;
                    }
                });
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err), { variant: 'error' });
            } finally {
                setLoadingRevenuesSubmission(false);
            }
        } else {
            // Create revenue
            try {
                setLoadingRevenuesSubmission(true);
                setInitializeData?.((prev) => ({ ...prev, loading: true }));

                await schema.validate({
                    name: revenue.name,
                    cashflow: revenue.cashflow,
                    accountingAccount: revenue.accountingAccount,
                    subRevenueNames: revenue.subRevenueNames,
                });

                const createRequestBody = {
                    ...revenue,
                    cashflow: cashflowTypes[revenue.cashflow],
                    accountingAccountId: accounts[revenue.accountingAccount].id,
                    subRevenueNames: revenue.subRevenueNames.map((subRevenue) => formatSubRevenue(subRevenue)),
                };

                delete createRequestBody.accountingAccount;

                Revenues.create(createRequestBody).then((res) => {
                    if (res.ok) {
                        enqueueSnackbar(t('Revenue created successfully!'), { variant: 'success' });

                        if (setInitializeData) setInitializeData((prev) => ({ ...prev, tab: 0 }));
                        else history.push(accountancy.base + accountancy.revenueNomenclature.base);
                    } else {
                        console.error(res.error);
                        throw res.error;
                    }
                });
            } catch (err) {
                console.error(err);
                enqueueSnackbar(errorHandling(err), { variant: 'error' });
            } finally {
                setLoadingRevenuesSubmission(false);
                setInitializeData?.((prev) => ({ ...prev, loading: false }));
            }
        }
    }, [revenue, revenueId, selectedAccount]);

    return (
        <div className="max-w-6xl">
            {loadingRevenue ? (
                <div className="flex justify-center rounded-md bg-layout-transparent p-12">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {/* 
                Title of the revenue with his accounting account
                 */}
                    <div className="flex items-end">
                        <LabelWrapper label={t('Revenue title')}>
                            <TextField
                                placeholder={t('Revenue title')}
                                value={revenue.name}
                                onChange={(e) => setRevenue({ ...revenue, name: e.target.value })}
                                disabled={!canEdit}
                            />
                        </LabelWrapper>
                        {canEdit && (
                            <div className="ml-2 flex-shrink-0">
                                <BasicTooltip tip={t('Add revenue')}>
                                    <div
                                        style={{ padding: '0.5rem' }}
                                        className="grid cursor-pointer place-content-center rounded-md bg-primary-main transition-all  hover:bg-primary-light group-hover:pointer-events-auto group-hover:translate-y-0"
                                        onClick={addRevenue}
                                    >
                                        <AddIcon className="text-buttons-text" style={{ fontSize: '1.5rem' }} />
                                    </div>
                                </BasicTooltip>
                            </div>
                        )}
                        <div className="ml-2 w-3/12">
                            <LabelWrapper label={t('Choose the accounting account')}>
                                <Dropdown
                                    variant="black"
                                    options={accounts.map((account) => account.code + ' - ' + account.name)}
                                    placeholder={t('Choose the accounting account')}
                                    selectedOption={revenue.accountingAccount}
                                    setSelectedOption={(e) => {
                                        if (canEdit) setRevenue({ ...revenue, accountingAccount: e });
                                    }}
                                    disabled={!canEdit}
                                />
                            </LabelWrapper>
                        </div>

                        <div className="ml-2 w-3/12">
                            <LabelWrapper label={t('Cashflow propagation')}>
                                <Dropdown
                                    variant="black"
                                    options={cashflowTypes.map((type) => t(type))}
                                    placeholder={t('Cashflow propagation')}
                                    selectedOption={revenue.cashflow}
                                    setSelectedOption={(e) => {
                                        if (canEdit) setRevenue({ ...revenue, cashflow: e });
                                    }}
                                    disabled={!canEdit}
                                />
                            </LabelWrapper>
                        </div>
                    </div>

                    <div
                        className="ml-3 gap-2 pl-4"
                        style={{
                            position: revenue.subRevenueNames.length > 0 && 'relative',
                            borderLeft: revenue.subRevenueNames.length > 0 && `2px solid var(--warning)`,
                        }}
                    >
                        {revenue.subRevenueNames.map((subRevenue1, subRevenue1Index) => (
                            <div key={subRevenue1.id} className="flex flex-col">
                                {/* 
                                Parent revenue with his accounting account
                                */}
                                <div className="group relative mt-4 flex items-end">
                                    <div
                                        style={{
                                            borderLeft: `1px solid var(--warning)`,
                                            height: '1rem',
                                            borderBottom: `1px solid var(--warning)`,
                                            width: '13px',
                                            borderRadius: '0px 0px 0px 10px',
                                            position: 'absolute',
                                            left: '-13.5px',
                                            top: '21px',
                                        }}
                                    ></div>

                                    <LabelWrapper label={t('Revenue name')}>
                                        <TextField
                                            placeholder={t('Revenue name')}
                                            value={subRevenue1.name}
                                            onChange={(e) => updateRevenue(subRevenue1Index, e.target.value)}
                                            disabled={!canEdit}
                                        />
                                    </LabelWrapper>
                                    <div
                                        style={{
                                            backgroundColor: `var(--warning)`,
                                            width: '2rem',
                                            height: '1px',
                                            marginBottom: '16px',
                                        }}
                                    ></div>

                                    <div className="w-3/12">
                                        <LabelWrapper label={t('Choose the accounting account')}>
                                            <Dropdown
                                                variant="black"
                                                options={accounts.map((account) => account.code + ' - ' + account.name)}
                                                placeholder={t('Choose the accounting account')}
                                                selectedOption={subRevenue1.accountingAccount}
                                                setSelectedOption={(e) => {
                                                    if (canEdit) updateRevenue(subRevenue1Index, undefined, e);
                                                }}
                                                style={{ zIndex: subRevenue1Index + 100 }}
                                                disabled={!canEdit}
                                            />
                                        </LabelWrapper>
                                    </div>

                                    <div className="ml-2 w-3/12">
                                        <LabelWrapper label={t('Cashflow propagation')}>
                                            <Dropdown
                                                variant="black"
                                                options={cashflowTypes.map((type) => t(type))}
                                                placeholder={t('Cashflow propagation')}
                                                selectedOption={subRevenue1.cashflow}
                                                setSelectedOption={(e) => {
                                                    updateRevenue(subRevenue1Index, undefined, undefined, e);
                                                }}
                                                disabled={!canEdit}
                                            />
                                        </LabelWrapper>
                                    </div>

                                    {canEdit && (
                                        <div style={{ zIndex: subRevenue1Index }} className="mb-2 flex gap-2 pl-2">
                                            <div className="flex-shrink-0">
                                                <BasicTooltip tip={t('Add sub-revenue')} position="right">
                                                    <ActionButton
                                                        size={width <= 750 ? 9 : 7}
                                                        color="var(--success)"
                                                        icon={<AddIcon />}
                                                        onClick={() => addSubRevenue(subRevenue1Index)}
                                                    />
                                                </BasicTooltip>
                                            </div>
                                            {subRevenue1.subRevenueNames.length === 0 && (
                                                <div className="duration-250 flex-shrink-0 translate-x-5 transform opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100 md:translate-x-0 md:opacity-100">
                                                    <BasicTooltip tip={t('Delete revenue')} position="right">
                                                        <ActionButton
                                                            size={width <= 750 ? 9 : 7}
                                                            icon={<DeleteIcon />}
                                                            color="var(--error)"
                                                            onClick={() =>
                                                                revenueId
                                                                    ? deleteRevenueOrSubRevenue(
                                                                          subRevenue1.id,
                                                                          'revenue',
                                                                      )
                                                                    : deleteRevenue(subRevenue1.id)
                                                            }
                                                        />
                                                    </BasicTooltip>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                {subRevenue1.subRevenueNames.length > 0 && (
                                    <div
                                        className="relative ml-8 space-y-4 pl-4 md:ml-3"
                                        style={{
                                            borderLeft: `2px solid  var(--warning)`,
                                            // borderRadius: '0px 0px 0px 20px',
                                        }}
                                    >
                                        {/* 
                                        Child revenue with his accounting account
                                        */}
                                        {subRevenue1.subRevenueNames.map((subRevenue2, subRevenue2Index) => (
                                            <div
                                                key={subRevenue2.id}
                                                className={`group relative flex items-end ${
                                                    subRevenue2.name.length && 'gap-6'
                                                }`}
                                            >
                                                {subRevenue1.subRevenueNames.length && (
                                                    <div
                                                        style={{
                                                            borderLeft: `1px solid  var(--warning)`,
                                                            height: '1rem',
                                                            borderBottom: `1px solid  var(--warning)`,
                                                            width: '13px',
                                                            borderRadius: '0px 0px 0px 10px',
                                                            position: 'absolute',
                                                            left: '-13.5px',
                                                            top: '21px',
                                                        }}
                                                    ></div>
                                                )}

                                                <LabelWrapper label={t('Sub-revenue name')}>
                                                    <TextField
                                                        className="rounded-md bg-layout-light bg-opacity-5"
                                                        placeholder={t('Sub-revenue name')}
                                                        value={subRevenue2.name}
                                                        onChange={(e) =>
                                                            updateSubRevenue(
                                                                subRevenue1Index,
                                                                subRevenue2Index,
                                                                e.target.value,
                                                            )
                                                        }
                                                        disabled={!canEdit}
                                                    />
                                                </LabelWrapper>

                                                {subRevenue2.name.length > 0 && (
                                                    <div className="flex w-9/12 gap-2 md:w-6/12">
                                                        <div className="w-6/12">
                                                            <LabelWrapper label={t('Choose the accounting account')}>
                                                                <Dropdown
                                                                    variant="black"
                                                                    options={accounts.map(
                                                                        (account) =>
                                                                            account.code + ' - ' + account.name,
                                                                    )}
                                                                    placeholder={t('Choose the accounting account')}
                                                                    selectedOption={subRevenue2.accountingAccount}
                                                                    setSelectedOption={(e) => {
                                                                        updateSubRevenue(
                                                                            subRevenue1Index,
                                                                            subRevenue2Index,
                                                                            undefined,
                                                                            e,
                                                                        );
                                                                    }}
                                                                    disabled={!canEdit}
                                                                />
                                                            </LabelWrapper>
                                                        </div>

                                                        <div className="w-6/12">
                                                            <LabelWrapper label={t('Cashflow propagation')}>
                                                                <Dropdown
                                                                    variant="black"
                                                                    options={cashflowTypes.map((type) => t(type))}
                                                                    placeholder={t('Cashflow propagation')}
                                                                    selectedOption={subRevenue2.cashflow}
                                                                    setSelectedOption={(e) => {
                                                                        updateSubRevenue(
                                                                            subRevenue1Index,
                                                                            subRevenue2Index,
                                                                            undefined,
                                                                            undefined,
                                                                            e,
                                                                        );
                                                                    }}
                                                                    disabled={!canEdit}
                                                                />
                                                            </LabelWrapper>
                                                        </div>
                                                    </div>
                                                )}

                                                {canEdit && (
                                                    <div
                                                        style={{ zIndex: subRevenue1Index }}
                                                        className={`${
                                                            subRevenue2.name.length > 0 ? 'pl-0' : 'pl-2'
                                                        } duration-250 mb-2 flex-shrink-0 translate-x-5 transform opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100 md:translate-x-0 md:opacity-100`}
                                                    >
                                                        <BasicTooltip tip={t('Delete sub-revenue')} position="right">
                                                            <ActionButton
                                                                size={width <= 750 ? 9 : 7}
                                                                icon={<DeleteIcon />}
                                                                color="var(--error)"
                                                                onClick={() =>
                                                                    revenueId
                                                                        ? deleteRevenueOrSubRevenue(
                                                                              subRevenue2.id,
                                                                              'sub-revenue',
                                                                          )
                                                                        : deleteSubRevenue(subRevenue2.id)
                                                                }
                                                            />
                                                        </BasicTooltip>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    {canEdit && (
                        // Create or edit button
                        <div
                            className={`pt-12 transition-opacity ${
                                initialJSON !== JSON.stringify(revenue) || selectedAccount !== initialAccount
                                    ? 'pointer-events-auto opacity-100'
                                    : 'pointer-events-none opacity-25'
                            }`}
                        >
                            <Button
                                color="primary"
                                onClick={submitRevenue}
                                startIcon={revenueId ? <CheckIcon /> : <AddIcon />}
                                disabled={loadingRevenuesSubmission}
                            >
                                {revenueId ? t('Edit revenue') : t('Add revenue')}
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

RevenueForm.propTypes = {
    revenueId: PropTypes.string,
    canEdit: PropTypes.bool,
    setInitializeData: PropTypes.func,
};

RevenueForm.defaultProps = {
    revenueId: null,
    canEdit: true,
    setInitializeData: null,
};

export default RevenueForm;
