import React from 'react';

import ScrollableContainer from 'components/scrollable-container/scrollable-container';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CRM_KPI_STATUSES, getAverageStatus } from 'utils/kpiUtils';

import KpiUser from './kpi-user';
import KpiWrapper from './kpi-wrapper';

const KpiViewUsers = ({ kpi, division }) => {
    const { t } = useTranslation();
    const { users, usersIndexes } = kpi;

    if (!users.length) return null;

    const sortedUsers = users.reduce(
        (acc, cur) => {
            const { averages, indexes } = cur;
            const currentIndexes = (
                indexes
                    .slice()
                    .reverse()
                    .find((item) => item.monitor) || usersIndexes.at(-1)
            ).divisions.find((d) => d.type === division);

            const average = averages[division];
            const { status, color } = getAverageStatus(average, currentIndexes);

            return { ...acc, [status]: [...acc[status], { ...cur, average, color }] };
        },
        {
            WEAK: [],
            GOOD: [],
            SUPER: [],
            IDEAL: [],
        },
    );

    return (
        <div className="grid grid-cols-kpi-users gap-8">
            {Object.entries(sortedUsers).map(([status, users]) => {
                if (!users.length) return null;

                return (
                    <KpiWrapper
                        key={status}
                        title={<p className="text-xl font-medium leading-5">{t(CRM_KPI_STATUSES[status])}</p>}
                    >
                        <div
                            className="h-full"
                            style={{
                                maxHeight: '36rem',
                            }}
                        >
                            <ScrollableContainer>
                                <div className="flex flex-col px-5 py-4">
                                    {users.map((user, index) => (
                                        <KpiUser key={user.id} user={user} divider={index !== users.length - 1} />
                                    ))}
                                </div>
                            </ScrollableContainer>
                        </div>
                    </KpiWrapper>
                );
            })}
        </div>
    );
};

KpiViewUsers.propTypes = {
    kpi: PropTypes.object,
    division: PropTypes.string,
};

KpiViewUsers.defaultProps = {
    kpi: null,
    division: null,
};

export default KpiViewUsers;
