import React from 'react';

import Indicator from 'components/shared/indicator/indicator';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';
import { getAverageStatus, getIndexesPositions, PROGRESS_BAR_OFFSETS } from 'utils/kpiUtils';

const KpiProgressBar = ({ average, indexes, detailed }) => {
    const { t } = useTranslation();
    const { color } = getAverageStatus(average, indexes);

    const max = Math.max(indexes.idealIndex, average);

    const offset = PROGRESS_BAR_OFFSETS[detailed];
    const width =
        average === 0
            ? `${offset / 2}px`
            : average >= max
              ? '100%'
              : `calc(${(average / max) * 100}% - ${offset / 2}px)`;

    const indexesPositions = getIndexesPositions(max, indexes, offset);

    return (
        <div className={`flex flex-col gap-2 ${detailed ? 'group' : ''}`}>
            <div className="relative h-8">
                {indexesPositions.map((indexLimit, index) => {
                    const { position, color, tip, value } = indexLimit;

                    return (
                        <div
                            key={index}
                            className={`absolute bottom-0 -translate-x-1/2 transform ${!detailed ? 'group' : ''}`}
                            style={{ left: position }}
                        >
                            <div className={`relative flex flex-col items-center ${detailed ? 'gap-2' : 'gap-1'}`}>
                                <p
                                    className={`${detailed ? 'text-xl leading-5' : 'text-lg leading-4 opacity-40 group-hover:opacity-100'}`}
                                >
                                    {value}
                                </p>
                                <div className="h-3 w-1 rounded-full" style={{ backgroundColor: color }} />

                                {!detailed && (
                                    <div className="absolute left-1/2 -translate-x-1/2 transform opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                        <Indicator style={{ backgroundColor: color }}>
                                            <p className="text-xl font-semibold leading-5">
                                                {t(capitalizeFirstLetter(tip))}
                                            </p>
                                        </Indicator>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}

                {detailed && (
                    <div
                        className="absolute bottom-0 -translate-x-1/2 transform opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                        style={{ left: width }}
                    >
                        <Indicator style={{ backgroundColor: color }}>
                            <p className="text-xl font-semibold leading-5">{average.toFixed(2)}</p>
                        </Indicator>
                    </div>
                )}
            </div>

            <div className="relative h-2 overflow-hidden rounded-full border border-layout-transparent text-layout-transparent-dark">
                <div
                    className="absolute left-0 top-0 h-full rounded-full"
                    style={{
                        width,
                        background: `linear-gradient(to right, var(--white), ${color})`,
                    }}
                />
            </div>

            {detailed && (
                <div className="relative h-8">
                    {indexesPositions.map((indexLimit, index) => {
                        const { position, color, tip } = indexLimit;

                        return (
                            <div
                                key={index}
                                className="absolute top-0 -translate-x-1/2 transform"
                                style={{ left: position }}
                            >
                                <div className="flex flex-col items-center gap-2">
                                    <div className="h-3 w-1 rounded-full" style={{ backgroundColor: color }} />
                                    <p className="text-xl leading-5" style={{ color }}>
                                        {t(capitalizeFirstLetter(tip))}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

KpiProgressBar.propTypes = {
    average: PropTypes.number,
    indexes: PropTypes.object,
    detailed: PropTypes.bool,
};

KpiProgressBar.defaultProps = {
    average: 0,
    indexes: null,
    detailed: false,
};

export default KpiProgressBar;
