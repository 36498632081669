import React from 'react';

import PropTypes from 'prop-types';

import ExportTableCell from './export-table-cell';

const ExportTableHeader = ({ columns, textColor, backgroundColor }) => {
    return (
        <tr className="bg-disabled">
            {columns.map((column) => {
                const { key, content, pdfMakeData, style } = column;

                return (
                    <ExportTableCell
                        key={key}
                        textColor={textColor}
                        backgroundColor={backgroundColor}
                        pdfMakeData={pdfMakeData}
                        style={style}
                    >
                        {content}
                    </ExportTableCell>
                );
            })}
        </tr>
    );
};

ExportTableHeader.propTypes = {
    columns: PropTypes.array,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
};

ExportTableHeader.defaultProps = {
    columns: [],
    backgroundColor: null,
    textColor: null,
};

export default ExportTableHeader;
