import React from 'react';

import PropTypes from 'prop-types';

import KpiChart from '../components/kpi-chart';
import KpiWrapper from '../components/kpi-wrapper';

const KpiCrmViewChart = ({ kpi, division }) => {
    return (
        <KpiWrapper>
            <div className="h-60 p-8">
                <KpiChart kpi={kpi} division={division} padding={6} detailed />
            </div>
        </KpiWrapper>
    );
};

KpiCrmViewChart.propTypes = {
    kpi: PropTypes.object,
    division: PropTypes.string,
};

KpiCrmViewChart.defaultProps = {
    kpi: null,
    division: null,
};

export default KpiCrmViewChart;
