import React from 'react';

import { TextField } from '@material-ui/core';

import PropTypes from 'prop-types';
import { DatePicker, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const InvoiceInfoFields = ({ invoice, editInvoiceInfo }) => {
    const { t } = useTranslation();

    return (
        <div className="mb-28">
            <div className="mb-10 flex max-w-4xl gap-10 sm:flex-col">
                <LabelWrapper label={t('Invoice name')}>
                    <TextField
                        placeholder={t('Invoice name')}
                        value={invoice.name}
                        onChange={(e) => editInvoiceInfo('name', e.target.value)}
                    />
                </LabelWrapper>

                <LabelWrapper label={t('Invoice number')}>
                    <TextField
                        placeholder={'F1234'}
                        value={invoice.billingNumber}
                        onChange={(e) => editInvoiceInfo('billingNumber', e.target.value)}
                    />
                </LabelWrapper>
            </div>

            <div className="flex justify-start">
                <div className="inline-block">
                    <LabelWrapper label={t('Issue date of the invoice')}>
                        <DatePicker date={invoice.issueDate} setDate={(e) => editInvoiceInfo('issueDate', e)} />
                    </LabelWrapper>
                </div>
            </div>
        </div>
    );
};

InvoiceInfoFields.propTypes = {
    invoice: PropTypes.object,
    editInvoiceInfo: PropTypes.func,
};

InvoiceInfoFields.defaultProps = {
    invoice: null,
    editInvoiceInfo: () => {},
};

export default InvoiceInfoFields;
