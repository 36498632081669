import React from 'react';

import { Sparkles, UserCheck } from 'lucide-react';

import { TextField } from '@material-ui/core';

import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

import KpiWrapper from './kpi-wrapper';

const KpiDetails = ({ kpi, onChangeKpi, types, disabled }) => {
    const { t } = useTranslation();
    const { name, type, monitorUsers } = kpi;

    return (
        <KpiWrapper title={<p className="text-xl font-bold uppercase leading-5 tracking-widest">{t('Details')}</p>}>
            <div className="flex flex-col gap-6 p-8">
                <LabelWrapper label={t('KPI name')}>
                    <TextField
                        name="name"
                        placeholder={t('KPI name')}
                        value={name}
                        onChange={(e) => onChangeKpi(e.target.name, e.target.value)}
                        disabled={disabled}
                    />
                </LabelWrapper>
                <div className="flex gap-6 sm:flex-col">
                    <LabelWrapper label={t('KPI type')}>
                        <Dropdown
                            variant="black"
                            icon={<Sparkles />}
                            placeholder={t('KPI type')}
                            options={types.map((type) => type.name)}
                            selectedOption={(() => {
                                const index = types.findIndex((t) => t.id === type);
                                return index >= 0 ? index : null;
                            })()}
                            setSelectedOption={(i) => onChangeKpi('type', types[i].id)}
                            disabled={disabled}
                        />
                    </LabelWrapper>
                    <LabelWrapper label={t('Employee KPI monitoring')}>
                        <Dropdown
                            variant="black"
                            icon={<UserCheck />}
                            placeholder={t('Employee KPI monitoring')}
                            options={[t('No'), t('Yes')]}
                            selectedOption={Number(monitorUsers)}
                            setSelectedOption={(i) => onChangeKpi('monitorUsers', Boolean(i))}
                            disabled={disabled}
                        />
                    </LabelWrapper>
                </div>
            </div>
        </KpiWrapper>
    );
};

KpiDetails.propTypes = {
    kpi: PropTypes.object,
    onChangeKpi: PropTypes.func,
    types: PropTypes.array,
    disabled: PropTypes.bool,
};

KpiDetails.defaultProps = {
    kpi: null,
    onChangeKpi: () => {},
    types: [],
    disabled: false,
};

export default KpiDetails;
