import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from 'components/shared/avatar/avatar';
import UserContext from 'contexts/UserContext';
import PropTypes from 'prop-types';
import { configurator } from 'routes';

const KpiUser = ({ user, divider }) => {
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const view = checkPerm([
        {
            permissionId: '11',
            permissionType: 'ALL',
        },
    ]);

    const { id, profile, average, color } = user;
    const { name, profileImageUrl } = profile;

    const handleViewUser = () =>
        history.push(configurator.base + configurator.users.base + configurator.users.update + '/' + user.id);

    return (
        <>
            <div
                key={id}
                className={`flex items-center justify-between gap-2 rounded-lg py-2 pl-2 pr-4 transition-all duration-150 ${view ? 'cursor-pointer hover:border hover:border-layout-transparent hover:bg-layout-transparent-dark' : ''}`}
                onClick={handleViewUser}
            >
                <div className="flex items-center gap-4">
                    <Avatar
                        url={profileImageUrl}
                        style={{
                            height: '3rem',
                        }}
                    />
                    <p className="text-xl font-medium leading-6">{name}</p>
                </div>
                <div className="flex items-center gap-2">
                    <p className="text-xl font-medium leading-6 opacity-80">{average}</p>
                    <div className="h-1 w-3 rounded-full" style={{ backgroundColor: color }} />
                </div>
            </div>
            {divider ? <hr className="border-layout-transparent" /> : ''}
        </>
    );
};

KpiUser.propTypes = {
    user: PropTypes.object,
    divider: PropTypes.bool,
};

KpiUser.defaultProps = {
    user: null,
    divider: false,
};

export default KpiUser;
