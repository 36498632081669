import React, { useEffect, useState } from 'react';

import { EllipsisVertical } from 'lucide-react';

import { Popover } from '@material-ui/core';

import useConfirm from 'hooks/useConfirm';
import useInViewport from 'hooks/useInViewport';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ActionsPopover = ({ opacity, icon, actions, disabled, style }) => {
    const { t } = useTranslation();
    const confirmAction = useConfirm();

    const [currentOpacity, setCurrentOpacity] = useState(opacity.default);
    const [anchorEl, setAnchorEl] = useState(null);
    const isInView = useInViewport({ current: anchorEl });

    const open = Boolean(anchorEl);
    const id = open ? 'file-row-id' : undefined;

    const handleOpen = (event) => {
        setCurrentOpacity(opacity.hover);
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setCurrentOpacity(opacity.default);
        setAnchorEl(null);
    };

    const handleCall = (action) => {
        action();
        handleClose();
    };

    const handleAction = (item) => {
        const { action, confirm } = item;

        if (confirm) {
            confirmAction(t('Are you sure you want to perform this action?'), () => handleCall(action));
            return;
        }

        handleCall(action);
    };

    useEffect(() => {
        if (!isInView) handleClose();
    }, [isInView]);

    if (actions.length === 0) return null;

    return (
        <>
            <div
                aria-describedby={id}
                className={`flex h-full flex-shrink-0 cursor-pointer items-center justify-center rounded-full transition-all duration-300 hover:bg-layout-transparent-dark ${open ? 'bg-layout-transparent-dark' : ''} ${disabled ? 'pointer-events-none text-disabled' : ''}`}
                style={{ ...style, opacity: currentOpacity, aspectRatio: '1 / 1' }}
                onMouseEnter={() => !open && setCurrentOpacity(opacity.hover)}
                onMouseLeave={() => !open && setCurrentOpacity(opacity.default)}
                onClick={handleOpen}
            >
                {icon}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                marginThreshold={12}
            >
                <div className="flex flex-col gap-2 rounded-xl border border-layout-transparent bg-layout-lighter p-2">
                    {actions.map((item, index) => (
                        <div
                            key={index}
                            className={`group flex h-16 cursor-pointer items-center justify-between gap-6 overflow-hidden rounded-lg border border-layout-transparent bg-layout-transparent pl-4 transition-colors hover:bg-layout-transparent-dark ${item.disabled ? 'pointer-events-none text-disabled' : ''}`}
                            onClick={() => handleAction(item)}
                        >
                            <p className={`text-lg font-medium ${item.disabled ? 'text-disabled' : ''}`}>{item.text}</p>
                            <div
                                className={`relative z-0 flex h-full w-12 flex-shrink-0 items-center justify-center overflow-hidden rounded-l-sm bg-gradient-to-tr transition-colors group-hover:text-gradient-primary-light ${item.disabled ? 'from-disabled to-disabled text-main-text' : 'from-gradient-primary-dark to-gradient-primary-light'}`}
                            >
                                {item.icon}
                                <div
                                    className="absolute left-0 top-0 h-full w-full bg-gradient-to-tr from-gradient-text via-gradient-text to-gradient-primary-light opacity-0 transition-opacity group-hover:opacity-100"
                                    style={{ zIndex: 'calc(-infinity)' }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Popover>
        </>
    );
};

ActionsPopover.propTypes = {
    opacity: PropTypes.object,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    actions: PropTypes.array,
    style: PropTypes.object,
};

ActionsPopover.defaultProps = {
    opacity: { default: 0.4, hover: 1 },
    icon: <EllipsisVertical className="h-6 w-6" />,
    disabled: false,
    actions: [],
    style: {},
};

export default ActionsPopover;
