import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FileUp, ListFilter, Send } from 'lucide-react';

import { Button, CircularProgress } from '@material-ui/core';

import EmailNotification from 'components/shared/email-notification/email-notification';
import ExportTable from 'components/shared/export/export-table';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import ModalReportLeadsContacts from 'pages/crm/contacts/modal-report-leads-contacts';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
    capitalizeFirstLetter,
    errorHandling,
    getRawImageFromPath,
} from 'utils';
import exportExcel from 'utils/exportExcel';
import exportHtmlAsPDF from 'utils/exportHtmlAsPDF';
import { formatExcelData, mergeExcelCellStyles } from 'utils/exportUtils';
import {
    getCompanyLogoAndIcon,
    getContacts,
    getLeadsReport,
    getProjects,
} from 'utils/getterFunctions';

const ReportLeadsContacts = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        tenant: { companyName },
    } = useContext(GlobalContext);
    const { checkPerm } = useContext(UserContext);
    const history = useHistory();

    const canView = checkPerm([
        {
            permissionId: '46',
            permissionType: 'VIEW',
        },
    ]);

    const canNotify = checkPerm([
        {
            permissionId: '31',
            permissionType: 'VIEW',
        },
    ]);

    const columns = [
        {
            key: 'name',
            content: t('First and last name'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'user',
            content: t('Lead agent'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'status',
            content: 'Status',
            render: (content, entry, index) =>
                content
                    .split(', ')
                    .map((item) =>
                        t(capitalizeFirstLetter(item.split('_').join(' '))),
                    )
                    .join(', '),
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'lead_source',
            content: t('Lead source'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'phone_number',
            content: t('Phone number'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'email',
            content: 'Email',
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'address',
            content: t('Address'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'actions',
            content: t('Actions'),
            pdfMakeData: { margin: [-4, -4, -4, -9], alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                list: true,
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
            render: (content, entry, index, color, backgroundColor) =>
                content.length ? (
                    <table
                        className="-mx-0.5 -my-1"
                        data-pdfmake={JSON.stringify({
                            widths: ['*', '*', '*'],
                        })}
                    >
                        <thead>
                            <tr>
                                <th
                                    className="border-b border-r border-black px-0.5 py-1"
                                    style={{
                                        color,
                                        backgroundColor,
                                    }}
                                    data-pdfmake={JSON.stringify({
                                        fillColor: backgroundColor,
                                        border: [false, false, true, true],
                                    })}
                                >
                                    {t('Type')}
                                </th>
                                <th
                                    className="border-b border-r border-black px-0.5 py-1"
                                    style={{
                                        color,
                                        backgroundColor,
                                    }}
                                    data-pdfmake={JSON.stringify({
                                        fillColor: backgroundColor,
                                        border: [false, false, true, true],
                                    })}
                                >
                                    {t('Date')}
                                </th>
                                <th
                                    className="border-b border-black px-0.5 py-1"
                                    style={{
                                        color,
                                        backgroundColor,
                                    }}
                                    data-pdfmake={JSON.stringify({
                                        fillColor: backgroundColor,
                                        border: [false, false, false, true],
                                    })}
                                >
                                    {t('Feedback')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {content.map((item, index) => {
                                const { name, date, feedback } = item;

                                return (
                                    <tr key={index}>
                                        <td
                                            className={`border-r border-black px-0.5 py-1 ${index !== content.length - 1 ? 'border-b' : ''}`}
                                            style={{
                                                color,
                                                backgroundColor,
                                                fontWeight: 'normal',
                                            }}
                                            data-pdfmake={JSON.stringify({
                                                fillColor: backgroundColor,
                                                border: [
                                                    false,
                                                    false,
                                                    true,
                                                    index !== content.length - 1
                                                        ? true
                                                        : false,
                                                ],
                                                bold: false,
                                            })}
                                        >
                                            {name}
                                        </td>
                                        <td
                                            className={`border-r border-black px-0.5 py-1 ${index !== content.length - 1 ? 'border-b' : ''}`}
                                            style={{
                                                color,
                                                backgroundColor,
                                                fontWeight: 'normal',
                                            }}
                                            data-pdfmake={JSON.stringify({
                                                fillColor: backgroundColor,
                                                border: [
                                                    false,
                                                    false,
                                                    true,
                                                    index !== content.length - 1
                                                        ? true
                                                        : false,
                                                ],
                                                bold: false,
                                            })}
                                        >
                                            {date}
                                        </td>
                                        <td
                                            className={`border-black px-0.5 py-1 ${index !== content.length - 1 ? 'border-b' : ''}`}
                                            style={{
                                                color,
                                                backgroundColor,
                                                fontWeight: 'normal',
                                            }}
                                            data-pdfmake={JSON.stringify({
                                                fillColor: backgroundColor,
                                                border: [
                                                    false,
                                                    false,
                                                    false,
                                                    index !== content.length - 1
                                                        ? true
                                                        : false,
                                                ],
                                                bold: false,
                                            })}
                                        >
                                            {feedback}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : null,
        },
    ];

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [exportingExcel, setExportingExcel] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [clients, setClients] = useState([]);
    const [projects, setProjects] = useState([]);
    const [companyLogo, setCompanyLogo] = useState(null);

    const [open, setOpen] = useState(false);
    const [notify, setNotify] = useState(false);
    const [data, setData] = useState([]);

    const [filteredOptions, setFilteredOptions] = useState({
        type: 'LEADS',
        options: [],
        startDate: null,
        endDate: null,
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleToggleNotify = () => setNotify((prev) => !prev);

    const handleFilter = async (params) => {
        try {
            setFetching(true);

            const dataRes = await getLeadsReport(params);

            setData(dataRes);
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
            console.error(error);
        } finally {
            setFetching(false);
        }
    };

    const handleExport = async () => {
        try {
            setExporting(true);

            const html = document.getElementById(
                'contacts-report-table',
            ).innerHTML;

            const columnsWidths = [
                '10%',
                '10%',
                '10%',
                '10%',
                '10%',
                '10%',
                '10%',
                '30%',
            ];

            await exportHtmlAsPDF(
                'landscape',
                [html],
                [],
                null,
                null,
                true,
                null,
                `Leads_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                [
                    {
                        elements: [
                            {
                                key: 'table',
                                props: {
                                    widths: columnsWidths,
                                    dontBreakRows: true,
                                },
                            },
                        ],
                    },
                ],
                [],
            );
        } catch (error) {
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
            console.error(error);
        } finally {
            setExporting(false);
        }
    };

    const handleExportExcel = async () => {
        try {
            setExportingExcel(true);

            const {
                columns: exportColumns,
                rows,
                lastRow,
            } = formatExcelData(columns, data);

            await exportExcel(
                `Leads_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                `Leads_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                t('Leads report'),
                exportColumns,
                rows,
                lastRow,
                (cell, iCell, row, iRow) => {
                    mergeExcelCellStyles(cell, iCell, exportColumns);
                },
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setExportingExcel(false);
        }
    };

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }

        (async () => {
            try {
                const [dataRes, clientsRes, projectsRes, { logoUrl }] =
                    await Promise.all([
                        getLeadsReport(),
                        getContacts(),
                        getProjects({
                            perPage: 99999,
                            currentPage: 0,
                            pagesToLoad: 1,
                        }),
                        getCompanyLogoAndIcon(),
                    ]);

                const logoBase64 = await getRawImageFromPath(logoUrl);
                setCompanyLogo(logoBase64);

                setData(dataRes);
                setClients(clientsRes);
                setProjects(projectsRes.content);
            } catch (error) {
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
                console.error(error);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <>
            <Helmet>
                <title>{t('Leads report')}</title>
            </Helmet>
            <Header
                pageTitle={t('Leads report')}
                action={
                    <>
                        <Button
                            color="primary"
                            startIcon={
                                fetching ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <ListFilter />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleOpen}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel
                            }
                        >
                            {t(fetching ? 'Loading...' : 'Filter data')}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={
                                exportingExcel ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <FileUp />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleExportExcel}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel ||
                                !data.length
                            }
                        >
                            {t(
                                exportingExcel
                                    ? 'Loading...'
                                    : 'Download Excel file',
                            )}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={
                                exporting ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <FileUp />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleExport}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel ||
                                !data.length
                            }
                        >
                            {t(exporting ? 'Loading...' : 'Export as PDF')}
                        </Button>
                        {canNotify && (
                            <Button
                                startIcon={<Send />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={handleToggleNotify}
                                disabled={
                                    loading ||
                                    fetching ||
                                    exporting ||
                                    exportingExcel
                                }
                            >
                                {t('Notify')}
                            </Button>
                        )}
                    </>
                }
            />
            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '100%', margin: 'auto 0' }} />
                ) : data.length ? (
                    <div className="overflow-x-auto" id="contacts-report-table">
                        <ExportTable
                            companyLogo={companyLogo}
                            title={t('Leads report')}
                            columns={columns}
                            data={data}
                        />
                    </div>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>

            <ModalReportLeadsContacts
                open={open}
                onClose={handleClose}
                onFilter={handleFilter}
                clients={clients}
                projects={projects}
                filteredOptions={filteredOptions}
                setFilteredOptions={setFilteredOptions}
            />

            <EmailNotification open={notify} cancel={handleToggleNotify} />
        </>
    );
};

export default ReportLeadsContacts;
