import React, { useContext, useEffect, useState } from 'react';

import { FileUp, ListFilter } from 'lucide-react';

import { Button, CircularProgress } from '@material-ui/core';

import ExportTable from 'components/shared/export/export-table';
import Loading from 'components/shared/loading';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import { useSnackbar } from 'notistack';
import { Header } from 'RaisisComponents';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { displayNumber, errorHandling, getRawImageFromPath } from 'utils';
import API from 'utils/axios';
import exportExcel from 'utils/exportExcel';
import exportHtmlAsPDF from 'utils/exportHtmlAsPDF';
import { formatExcelData, mergeExcelCellStyles } from 'utils/exportUtils';
import { getCompanyLogoAndIcon } from 'utils/getterFunctions';

import ModalReportsExport from './modal-report-filter';

const Report = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
        tenant: { companyName },
        currencyObj,
        referenceCurrencyObj,
        language,
    } = useContext(GlobalContext);

    const columns = [
        {
            key: 'number',
            content: null,
            render: (content, entry, index) => index + 1,
        },
        {
            key: 'name',
            content: t('Product name'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'categories',
            content: t('Categories'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'description',
            content: t('Description'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'project',
            content: t('Project name'),
            render: (content, entry, index) => content,
            pdfMakeData: { alignment: 'right' },
            style: { textAlign: 'right' },
            excelData: {
                style: {
                    alignment: {
                        horizontal: 'right',
                    },
                },
            },
        },
        {
            key: 'mainPrice',
            content: `${t('Price')} (${currencyObj.currency})`,
            render: (content, entry, index) => displayNumber(content, language),
        },
        {
            key: 'secondaryPrice',
            content: `${t('Price')} (${referenceCurrencyObj.currency})`,
            render: (content, entry, index) => displayNumber(content, language),
        },
        {
            key: 'totalQuantity',
            content: t('Stock quantity'),
            render: (content, entry, index) => content,
        },
        {
            key: 'freeQuantity',
            content: t('Free quantity'),
            render: (content, entry, index) => content,
        },
        {
            key: 'reservedQuantity',
            content: t('Bidded quantity'),
            render: (content, entry, index) => content,
        },
        {
            key: 'soldQuantity',
            content: t('Sold quantity'),
            render: (content, entry, index) => content,
        },
        // {
        //     key: 'leads',
        //     content: t('Leads'),
        //     render: (content, entry, index) => content,
        //     pdfMakeData: { alignment: 'right' },
        //     style: { textAlign: 'right' },
        //     excelData: {
        //         style: {
        //             alignment: {
        //                 horizontal: 'right',
        //             },
        //         },
        //     },
        // },
    ];

    const info = {
        description: t('Explanations of columns'),
        elements: [
            { label: t('Name'), content: t('Name of the article or stock.') },
            { label: t('Price'), content: t('Price of the article or stock.') },
            {
                label: t('Categories'),
                content: t('Categories of the article or stock.'),
            },
            {
                label: t('Description'),
                content: t('Description of the article or stock.'),
            },
            {
                label: t('Project name'),
                content: t('Project of the article or stock.'),
            },
            { label: t('Quantity'), content: t('Number of units in stock.') },
            {
                label: t('Free quantity'),
                content: t('Number of units available for sale.'),
            },
            {
                label: t('Bidded quantity'),
                content: t(
                    'Number of units bidded for customers (not yet invoiced).',
                ),
            },
            // {
            //     label: t('Leads'),
            //     content: t('The leeds to whom the article is bidded.'),
            // },
        ],
    };

    const [loading, setLoading] = useState(true);
    const [exporting, setExporting] = useState(false);
    const [exportingExcel, setExportingExcel] = useState(false);
    const [fetching, setFetching] = useState(false);

    const [stocks, setStocks] = useState([]);
    const [projects, setProjects] = useState([]);
    const [companyLogo, setCompanyLogo] = useState(null);

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    const [filteredOptions, setFilteredOptions] = useState({
        detailsType: 'SYNTHETIC',
        type: 'COMPANY',
        options: [],
    });

    useEffect(() => {
        (async () => {
            try {
                const [dataRes, stocksRes, projectsRes, { logoUrl }] =
                    await Promise.all([
                        API.get('/article_reports', {
                            params: filteredOptions,
                        }),
                        API.get('/stocks', {
                            params: {
                                perPage: 99999,
                                currentPage: 0,
                                pagesToLoad: 1,
                            },
                        }),
                        API.get('/PmProjects', {
                            params: {
                                perPage: 99999,
                                currentPage: 0,
                                pagesToLoad: 1,
                            },
                        }),
                        getCompanyLogoAndIcon(),
                    ]);

                const logoBase64 = await getRawImageFromPath(logoUrl);
                setCompanyLogo(logoBase64);

                setData(dataRes.data.report);
                setStocks(stocksRes.data.stocks.content);
                setProjects(projectsRes.data.PmProjects.content);
            } catch (error) {
                console.error(error);
                enqueueSnackbar(errorHandling(error), { variant: 'error' });
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFilter = async (params) => {
        try {
            setFetching(true);

            const dataRes = await API.get('/article_reports', { params });

            setData(dataRes.data.report);
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setFetching(false);
        }
    };

    const handleExport = async () => {
        try {
            setExporting(true);

            const html = document.getElementById(
                'stocks-report-table',
            ).innerHTML;

            const columnsWidths = [
                '4%',
                '12%',
                '12%',
                '16%',
                '12%',
                '12%',
                '12%',
                '5%',
                '5%',
                '5%',
                '5%',
            ];

            await exportHtmlAsPDF(
                'landscape',
                [html],
                [],
                null,
                null,
                true,
                null,
                `Stocks_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                [
                    {
                        elements: [
                            {
                                key: 'table',
                                props: {
                                    widths: columnsWidths,
                                    dontBreakRows: true,
                                },
                            },
                        ],
                    },
                ],
                [],
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setExporting(false);
        }
    };

    const handleExportExcel = async () => {
        try {
            setExportingExcel(true);

            const {
                columns: exportColumns,
                rows,
                lastRow,
            } = formatExcelData(columns, data, info);

            await exportExcel(
                `Stocks_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                `Stocks_Report_Export_${new Date().toISOString().slice(0, 5)}_${companyName}.pdf`,
                t('Stocks report'),
                exportColumns,
                rows,
                lastRow,
                (cell, iCell, row, iRow) => {
                    const { textColor, backgroundColor } = data[iRow];

                    cell.font = {
                        color: {
                            argb: textColor
                                ? `${textColor.slice(1, textColor.length)}`
                                : '000000',
                        },
                    };

                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {
                            argb: backgroundColor
                                ? `${backgroundColor.slice(1, backgroundColor.length)} `
                                : 'FFFFFF',
                        },
                    };

                    mergeExcelCellStyles(cell, iCell, exportColumns);
                },
            );
        } catch (error) {
            console.error(error);
            enqueueSnackbar(errorHandling(error), { variant: 'error' });
        } finally {
            setExportingExcel(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Stocks report')}</title>
            </Helmet>
            <Header
                pageTitle={t('Stocks report')}
                action={
                    <>
                        <Button
                            color="primary"
                            startIcon={
                                fetching ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <ListFilter />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleOpen}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel
                            }
                        >
                            {t(fetching ? 'Loading...' : 'Filter data')}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={
                                exportingExcel ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <FileUp />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleExportExcel}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel ||
                                !data.length
                            }
                        >
                            {t(
                                exportingExcel
                                    ? 'Loading...'
                                    : 'Download Excel file',
                            )}
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={
                                exporting ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <FileUp />
                                )
                            }
                            style={{ borderRadius: '999px' }}
                            onClick={handleExport}
                            disabled={
                                loading ||
                                fetching ||
                                exporting ||
                                exportingExcel ||
                                !data.length
                            }
                        >
                            {t(exporting ? 'Loading...' : 'Export as PDF')}
                        </Button>
                    </>
                }
            />

            <div className="page-container">
                {loading ? (
                    <Loading style={{ height: '100%', margin: 'auto 0' }} />
                ) : data.length ? (
                    <div className="overflow-x-auto" id="stocks-report-table">
                        <ExportTable
                            companyLogo={companyLogo}
                            title={t('Stocks report')}
                            columns={columns}
                            data={data}
                            info={info}
                        />
                    </div>
                ) : (
                    <NoDataPlaceholder />
                )}
            </div>

            <ModalReportsExport
                open={open}
                onClose={handleClose}
                onFilter={handleFilter}
                stocks={stocks}
                projects={projects}
                filteredOptions={filteredOptions}
                setFilteredOptions={setFilteredOptions}
            />
        </>
    );
};

export default Report;
