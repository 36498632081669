import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';

const InvoicePartnerDropdown = (props) => {
    const { t } = useTranslation();
    const {
        partners,
        partnersOptions,
        selectedPartner,
        setSelectedPartner,
        onResetMiddleFields,
        getNewCrmData,
        getNewProjects,
        disabled = false,
        selectedClientId,
    } = props;

    return (
        <>
            {/* Dropdown for the invoice client */}
            <div className="flex justify-start">
                <div className="inline-block">
                    <LabelWrapper label={t('Partner')}>
                        <Dropdown
                            variant="black"
                            options={partnersOptions}
                            placeholder={t('Choose the partner of the invoice')}
                            selectedOption={selectedPartner}
                            setSelectedOption={(e) => {
                                setSelectedPartner(e);
                                getNewCrmData(selectedClientId, partners[e].id);
                                getNewProjects(selectedClientId, partners[e].id);
                                onResetMiddleFields();
                            }}
                            disabled={disabled}
                        />
                    </LabelWrapper>
                </div>
            </div>
        </>
    );
};

InvoicePartnerDropdown.propTypes = {
    partnersOptions: PropTypes.array,
    selectedPartner: PropTypes.number,
    setSelectedPartner: PropTypes.func,
    onResetMiddleFields: PropTypes.func,
    disabled: PropTypes.bool,
    getNewCrmData: PropTypes.func,
    getNewProjects: PropTypes.func,
    partners: PropTypes.array,
    selectedClientId: PropTypes.string,
};
export default InvoicePartnerDropdown;
