import React from 'react';

import PropTypes from 'prop-types';
import { CHART_ELEMENTS_VALUES } from 'utils/kpiUtils';

const KpiChartTickX = ({ data, detailed, payload, ...rest }) => {
    const { index, value } = payload;
    const { value: dataValue } = data[index];

    const { tspan } = CHART_ELEMENTS_VALUES['tick']['x'];
    const { dy } = tspan;

    return (
        <text
            {...rest}
            style={{
                fill: detailed && dataValue !== null ? 'rgb(var(--base-white) / 70%)' : 'rgb(var(--base-white) / 40%)',
            }}
        >
            <tspan dy={dy}>{value}</tspan>
        </text>
    );
};

KpiChartTickX.propTypes = {
    data: PropTypes.array,
    detailed: PropTypes.bool,
    payload: PropTypes.object,
};

KpiChartTickX.defaultProps = {
    data: [],
    detailed: false,
    payload: null,
};

export default KpiChartTickX;
