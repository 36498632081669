import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import EmailIcon from '@material-ui/icons/Email';
import FilterListIcon from '@material-ui/icons/FilterList';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import {
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import FrappeGantt from 'components/frappe-gantt/frappe-gantt-layout';
import TimelineEmailNotification from 'components/internal-activity/timeline-email-notification';
import CustomModal from 'components/modals/custom_modal';
import LoadingExportModal from 'components/modals/loading-export-modal';
import TaskManagementDisplay from 'components/modules/pm-task-management/pm-task-management-display';
import PMTaskManagementModal from 'components/modules/pm-task-management/pm-task-management-modal';
import TaskManagementOpportunityDisplay from 'components/modules/pm-task-management/pm-task-management-opportunity-display';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import GlobalContext from 'contexts/GlobalContext';
import TaskManagementContext from 'contexts/TaskManagementContext';
import UserContext from 'contexts/UserContext';
import useQuery from 'hooks/useQuery';
import { useSnackbar } from 'notistack';
import FilterMilestonesModal from 'pages/internal-activity/timeline-activity/filter-milestones-modal';
import PropTypes from 'prop-types';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { errorHandling } from 'utils';
import API from 'utils/axios';
import exportImages from 'utils/exportImages';
import exportImagesToPDF from 'utils/exportImagesToPDF';
import { getFilteredMilestones, getTasks } from 'utils/ganttUtils';
import {
    getPmDepartments,
    getProjectAffiliates,
    getProjectUsers,
} from 'utils/getterFunctions';

const PMTaskManagementTimeline = ({ projectName }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const { checkPerm } = useContext(UserContext);
    const { language } = useContext(GlobalContext);

    const [modalOpen, setModalOpen] = useState(false);

    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [departments, setDepartments] = useState([]);

    const [query] = useQuery();
    const view = query.get('view') ?? 'Day';

    const [seeGantt, setSeeGantt] = useState(false);
    const handleToggleGantt = () => setSeeGantt((prev) => !prev);

    const [loadingExportImage, setLoadingExportImage] = useState(false);
    const [loadingexportImagesAsPDF, setLoadingexportImagesAsPDF] =
        useState(false);

    const canView = checkPerm([
        {
            permissionId: '52',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '52',
            permissionType: 'ALL',
        },
    ]);

    const canUsersAll = checkPerm([
        {
            permissionId: '11',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }
    }, [canView]);

    const [projectAccounts, setProjectAccounts] = useState([]);

    const [openNotify, setOpenNotify] = useState(false);
    const handleToggleNotify = () => setOpenNotify((prev) => !prev);

    const [notifyInitialMilestone, setNotifyInitialMilestone] = useState(null);
    const handleNotify = (milestoneId) => {
        setNotifyInitialMilestone(milestoneId);
        handleToggleNotify();
    };

    const [milestones, setMilestones] = useState([]);

    // Filter gantt milestones
    const [filteredMilestones, setFilteredMilestones] = useState([]);
    const memoizedFilteredMilestones = useMemo(
        () => filteredMilestones,
        [filteredMilestones],
    );
    const [ganttFilterData, setGanttFilterData] = useState({
        type: 0,
        milestones: [],
        startDate: new Date(),
        endDate: new Date(),
    });
    const [openFilterGantt, setOpenFilterGantt] = useState(false);

    const handleOpenFilterGantt = () => setOpenFilterGantt((prev) => !prev);

    const handleFilterGantt = () => {
        const fMilestones = getFilteredMilestones(milestones, ganttFilterData);

        setFilteredMilestones(fMilestones);
        setOpenFilterGantt(false);
    };

    const handleDisplayMilestoneGantt = (index) => {
        setGanttFilterData((prev) => ({
            ...prev,
            type: 1,
            milestones: [index],
        }));

        setFilteredMilestones([milestones[index]]);
        handleToggleGantt();
    };

    const customGanttDates = useMemo(
        () => ({
            startDate:
                ganttFilterData.type === 2 ||
                ganttFilterData.type === 3 ||
                ganttFilterData.type === 4
                    ? new Date(ganttFilterData.startDate)
                    : null,
            endDate:
                ganttFilterData.type === 2 ||
                ganttFilterData.type === 3 ||
                ganttFilterData.type === 4
                    ? new Date(ganttFilterData.endDate)
                    : null,
        }),
        [
            ganttFilterData.type,
            ganttFilterData.startDate,
            ganttFilterData.endDate,
        ],
    );

    const getTenantAccounts = async () => {
        try {
            const [projectAccounts, projectAffiliates] = await Promise.all([
                getProjectUsers({ pmProjectId: projectId }),
                getProjectAffiliates({ pmProjectId: projectId }),
            ]);

            setProjectAccounts([...projectAccounts, ...projectAffiliates]);
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Status of the activity ( to do or done)
     * This state is use for sorting the activity by status
     */
    const [status, setStatus] = useState('all');
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    /**
     * Type of the activity ( comment, tasks, files, documents, invoices)
     * This state is use for sorting the activity by type
     */
    const [activityType, setActivityType] = useState('ALL');
    const handleTypeChange = (event) => {
        setActivityType(event.target.value);
    };

    useEffect(() => {
        (async () => {
            try {
                const deps = await getPmDepartments();
                setDepartments(deps);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    /**
     * State for open and close the modal
     * On creation of an activity: isCreating is true
     */
    const [isCreating, setIsCreating] = useState(false);

    const submitTaskManagementActivity = async (formData) => {
        if (isCreating) return;

        try {
            setIsCreating(true);

            await API.post('/pmProjectActivity', formData);

            enqueueSnackbar(t('Task Management activity added successfully!'), {
                variant: 'success',
            });

            setModalOpen(false);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    /*
        Fetching data periodically, and separating all activities in 2 arrays
        Update occurs only if differences are spotted
    */
    const [loadingActivities, setLoadingActivities] = useState(true);
    const [todoActivities, setTodoActivities] = useState([]);
    const [doneActivities, setDoneActivities] = useState([]);
    const [failedActivities, setFailedActivities] = useState([]);

    const fetchActivities = async () => {
        if (!departments.length) return;

        const params = {
            currentPage: 0,
            perPage: 99999,
            pagesToLoad: 1,
            pmProjectPlanId: projectId,
        };

        const res = await API.get('/pmProjectActivity', { params });

        const milestoneActivities = res.data.activity
            .map((activity) => activity.activityInfo)
            .filter((activity) => activity.activitiesTasks.length > 0);
        setMilestones(milestoneActivities);

        const sortedMilestones = milestoneActivities.sort(
            (a, b) =>
                new Date(a.createAt).getTime() - new Date(b.createAt).getTime(),
        );
        setFilteredMilestones(sortedMilestones);

        const filteredActivities = res.data.activity.reduce(
            (acc, cur) => {
                if (!cur.activityInfo.status) return acc;

                const canDisplay =
                    (activityType === 'ALL' ||
                        activityType === cur.activityInfo.actionType) &&
                    (selectedDepartment === 0 ||
                        cur.activityInfo.departmentId ===
                            departments[selectedDepartment - 1].id);

                if (canDisplay)
                    return {
                        ...acc,
                        [cur.activityInfo.status]: [
                            ...acc[cur.activityInfo.status],
                            cur,
                        ],
                    };
                return acc;
            },
            {
                TODO: [],
                DONE: [],
                FAILED: [],
            },
        );

        if (
            JSON.stringify(filteredActivities.TODO) !==
            JSON.stringify(todoActivities)
        ) {
            setTodoActivities(filteredActivities.TODO);
        }

        if (
            JSON.stringify(filteredActivities.DONE) !==
            JSON.stringify(doneActivities)
        ) {
            setDoneActivities(filteredActivities.DONE);
        }

        if (
            JSON.stringify(filteredActivities.FAILED) !==
            JSON.stringify(failedActivities)
        ) {
            setFailedActivities(filteredActivities.FAILED);
        }
    };

    const [isDeleting, setIsDeleting] = useState(false);
    const deleteActivity = async (activity) => {
        if (isDeleting) return;

        setIsDeleting(true);

        try {
            await API.delete('/pmProjectActivity', {
                data: {
                    id: activity.id,
                },
            });
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
        } finally {
            setIsDeleting(false);
            fetchActivities();
        }
    };

    const [isUpdating, setIsUpdating] = useState(false);
    const updateActivity = async (activity, newValue) => {
        if (isUpdating) return;
        setIsUpdating(true);

        try {
            await API.post('/mark_activity', {
                id: activity.id,
                status: newValue,
            });

            fetchActivities();
        } catch (err) {
            console.error(err);
            enqueueSnackbar(
                errorHandling(err).length > 100
                    ? errorHandling(err)
                    : t(errorHandling(err)),
                {
                    variant: 'error',
                },
            );
        } finally {
            setIsUpdating(false);
        }
    };

    /**
     * This useEffect is for sorting. When the dependency is changed, is calling fetchActivities with correct params for sorting
     */
    useEffect(() => {
        const getData = async () => {
            setLoadingActivities(true);
            await Promise.allSettled([fetchActivities(), getTenantAccounts()]);
            setLoadingActivities(false);
        };

        if (departments.length > 0) {
            getData();
        } else {
            setLoadingActivities(false);
        }
    }, [departments, activityType, selectedDepartment]);

    // Export Gantt as image logic

    const handleExportGanntAsImage = async () => {
        try {
            setLoadingExportImage(true);

            const ganttQuery = [
                {
                    ids: ['gantt-wrapper-root'],
                    type: 'gantt',
                    element: {
                        type: 'milestones',
                        purpose: 'pm',
                    },
                    data: {
                        view,
                        customDates: customGanttDates,
                        selectedEntities: memoizedFilteredMilestones.map(
                            (m) => m.id,
                        ),
                    },
                    request: {
                        params: {
                            currentPage: 0,
                            perPage: 99999,
                            pagesToLoad: 1,
                            pmProjectPlanId: projectId,
                        },
                    },
                },
            ];
            await exportImages(ganttQuery, language, true);
        } catch (error) {
            enqueueSnackbar(t("Gantt couldn't be exported"), {
                variant: 'error',
            });
            console.error(error);
        } finally {
            setLoadingExportImage(false);
        }
    };

    // Export Gantt as pdf logic

    const handleExportGanntAsPDF = async () => {
        try {
            setLoadingexportImagesAsPDF(true);

            await exportImagesToPDF(
                'landscape',
                language,
                [
                    {
                        ids: ['gantt-wrapper-root'],
                        type: 'gantt',
                        element: {
                            type: 'milestones',
                            purpose: 'pm',
                        },
                        data: {
                            view,
                            customDates: customGanttDates,
                            selectedEntities: memoizedFilteredMilestones.map(
                                (m) => m.id,
                            ),
                        },
                        request: {
                            params: {
                                currentPage: 0,
                                perPage: 99999,
                                pagesToLoad: 1,
                                pmProjectPlanId: projectId,
                            },
                        },
                    },
                ],
                [
                    {
                        ids: ['gantt-legend-root'],
                        type: 'gantt-legend',
                        element: {
                            type: 'milestones',
                        },
                    },
                ],
                () => setLoadingexportImagesAsPDF(false),
                null,
                true,
                'multi-page',
                projectName,
                `Gantt_Export_${new Date().toISOString().slice(0, 10)}_${projectName}.pdf`,
            );
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Task Management`}</title>
            </Helmet>

            <Header
                pageTitle={`Task Management`}
                action={
                    <>
                        {canAll && seeGantt !== false && (
                            <Button
                                startIcon={
                                    loadingExportImage ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <ImportExportIcon />
                                    )
                                }
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={handleExportGanntAsImage}
                            >
                                {t('Export Gantt as image')}
                            </Button>
                        )}
                        {canAll && seeGantt !== false && (
                            <Button
                                startIcon={
                                    loadingexportImagesAsPDF ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <ImportExportIcon />
                                    )
                                }
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={
                                    loadingexportImagesAsPDF
                                        ? undefined
                                        : handleExportGanntAsPDF
                                }
                            >
                                {t('Export Gantt as PDF')}
                            </Button>
                        )}
                        {canAll && seeGantt === false && (
                            <Button
                                startIcon={<EmailIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={handleToggleNotify}
                            >
                                {t('Notify')}
                            </Button>
                        )}
                        {canAll && seeGantt === false && (
                            <div
                                className={`duration-350 transform transition-all ${
                                    modalOpen
                                        ? 'translate-x-8 opacity-0'
                                        : 'translate-x-0 opacity-100'
                                }`}
                            >
                                <Button
                                    startIcon={<AddIcon />}
                                    color="secondary"
                                    style={{ borderRadius: '999px' }}
                                    onClick={() => {
                                        setModalOpen(true);
                                    }}
                                >
                                    {t('Add activity')}
                                </Button>
                            </div>
                        )}
                    </>
                }
                toolbar={
                    <div className="flex items-center gap-8">
                        {!seeGantt ? (
                            <Tabs
                                tabs={[
                                    t('All'),
                                    ...(departments?.map((d) => d.name) ?? []),
                                ]}
                                activeTab={selectedDepartment}
                                setActiveTab={setSelectedDepartment}
                            />
                        ) : (
                            <div className="flex-shrink-0">
                                <Button
                                    style={{ borderRadius: '999px' }}
                                    startIcon={<FilterListIcon />}
                                    onClick={handleOpenFilterGantt}
                                >
                                    {t('Filter milestones and opportunities')}
                                </Button>
                            </div>
                        )}
                        <div className="flex-shrink-0">
                            <Button
                                style={{ borderRadius: '999px' }}
                                onClick={handleToggleGantt}
                            >
                                {seeGantt === true
                                    ? t('See Timeline')
                                    : t('See Gantt')}
                            </Button>
                        </div>
                    </div>
                }
                toolbarSecondary={
                    !seeGantt ? (
                        <div className="flex flex-col">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="status"
                                    name="status-filter"
                                    value={status}
                                    onChange={handleStatusChange}
                                >
                                    <div className="no-user-select-recursive flex flex-wrap items-center">
                                        <FormControlLabel
                                            value="all"
                                            control={<Radio />}
                                            label={t('All')}
                                        />
                                        <FormControlLabel
                                            value="todo"
                                            control={<Radio />}
                                            label={t('To do')}
                                        />
                                        <FormControlLabel
                                            value="done"
                                            control={<Radio />}
                                            label={t('Done')}
                                        />
                                        <FormControlLabel
                                            value="failed"
                                            control={<Radio />}
                                            label={t('Failed')}
                                        />
                                    </div>
                                </RadioGroup>
                            </FormControl>

                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="activity-type"
                                    name="activity-type-filter"
                                    value={activityType}
                                    onChange={handleTypeChange}
                                >
                                    <div className="no-user-select-recursive flex flex-wrap items-center">
                                        <FormControlLabel
                                            value="ALL"
                                            control={<Radio />}
                                            label={t('All')}
                                        />
                                        <FormControlLabel
                                            value="COMMENT"
                                            control={<Radio />}
                                            label={t('Comments')}
                                        />
                                        <FormControlLabel
                                            value="TASK"
                                            control={<Radio />}
                                            label={'Tasks'}
                                        />
                                        <FormControlLabel
                                            value="MILESTONE"
                                            control={<Radio />}
                                            label={t('Milestones')}
                                        />
                                        <FormControlLabel
                                            value="OPPORTUNITY"
                                            control={<Radio />}
                                            label={t('Opportunities')}
                                        />
                                        <FormControlLabel
                                            value="FILES"
                                            control={<Radio />}
                                            label={t('Files')}
                                        />
                                        <FormControlLabel
                                            value="DOCUMENTS"
                                            control={<Radio />}
                                            label={t('Documents')}
                                        />
                                        {/* <FormControlLabel
                                        value="INVOICES_AND_BILLS"
                                        control={<Radio  />}
                                        label={t('Invoices/Bills')}
                                    /> */}
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ) : null
                }
            />

            <div className="page-container">
                {seeGantt ? (
                    <Fragment>
                        <FrappeGantt
                            milestones={memoizedFilteredMilestones}
                            customGanttDates={customGanttDates}
                            getTasksFunction={getTasks}
                        />

                        <CustomModal open={openFilterGantt}>
                            <FilterMilestonesModal
                                setOpen={setOpenFilterGantt}
                                ganttFilterData={ganttFilterData}
                                setGanttFilterData={setGanttFilterData}
                                handleFilterGantt={handleFilterGantt}
                                milestones={milestones}
                            />
                        </CustomModal>

                        <LoadingExportModal
                            open={
                                loadingExportImage || loadingexportImagesAsPDF
                            }
                        />
                    </Fragment>
                ) : (
                    <TaskManagementContext.Provider
                        value={{
                            isCreating,
                            setIsCreating,
                            submitTaskManagementActivity,
                            fetchActivities,
                        }}
                    >
                        <div className=" flex items-start justify-center lg:items-center">
                            <div
                                className="relative z-40 flex w-full flex-col items-center pl-24 md:pl-32 sm:pl-0"
                                style={{ maxWidth: '1000px' }}
                            >
                                {loadingActivities && <CircularProgress />}
                                {(todoActivities.length > 0 ||
                                    doneActivities.length > 0 ||
                                    failedActivities.length > 0) && (
                                    <Fragment>
                                        {(((status === 'all' ||
                                            status === 'todo') &&
                                            todoActivities.length > 0) ||
                                            isCreating) && (
                                            <div className="relative w-full border-l-2 border-layout-lighter py-8 pl-8 sm:pl-4">
                                                <h2 className="mb-12">
                                                    {t('To do')}
                                                </h2>

                                                <div
                                                    className={`pointer-events-none flex w-full items-center justify-center overflow-hidden rounded-md bg-layout-transparent transition-all duration-300 ${
                                                        isCreating
                                                            ? 'opacity-1 mb-10 h-48'
                                                            : 'mb-0 h-0 opacity-0'
                                                    }`}
                                                >
                                                    <CircularProgress />
                                                </div>

                                                {todoActivities.map(
                                                    (a, aIndex) =>
                                                        a.activityInfo
                                                            .actionType ===
                                                            'TASK' &&
                                                        a.activityInfo
                                                            .pipelineType ===
                                                            'LEADS_TO_POTENTIAL' ? (
                                                            <TaskManagementOpportunityDisplay
                                                                key={
                                                                    a
                                                                        .activityInfo
                                                                        .id
                                                                }
                                                                opportunity={a}
                                                                deleteActivity={
                                                                    deleteActivity
                                                                }
                                                                fetchActivities={
                                                                    fetchActivities
                                                                }
                                                                updateActivity={
                                                                    updateActivity
                                                                }
                                                                actionArrow={{
                                                                    mark: canAll,
                                                                    edit: canAll,
                                                                    gantt:
                                                                        canAll &&
                                                                        a
                                                                            .activityInfo
                                                                            .activitiesTasks
                                                                            .length >
                                                                            0,
                                                                    notify:
                                                                        canAll &&
                                                                        a
                                                                            .activityInfo
                                                                            .activitiesTasks
                                                                            .length >
                                                                            0,
                                                                }}
                                                                onNotify={
                                                                    handleNotify
                                                                }
                                                                userLink={
                                                                    canUsersAll
                                                                }
                                                                setSeeGantt={() => {
                                                                    handleDisplayMilestoneGantt(
                                                                        aIndex,
                                                                    );
                                                                }}
                                                            />
                                                        ) : (
                                                            <TaskManagementDisplay
                                                                key={
                                                                    a
                                                                        .activityInfo
                                                                        .id
                                                                }
                                                                activity={a}
                                                                deleteActivity={
                                                                    deleteActivity
                                                                }
                                                                fetchActivities={
                                                                    fetchActivities
                                                                }
                                                                updateActivity={
                                                                    updateActivity
                                                                }
                                                                actionArrow={{
                                                                    mark: canAll,
                                                                    edit: canAll,
                                                                    gantt:
                                                                        canAll &&
                                                                        a
                                                                            .activityInfo
                                                                            .activitiesTasks
                                                                            .length >
                                                                            0,
                                                                    notify:
                                                                        canAll &&
                                                                        a
                                                                            .activityInfo
                                                                            .activitiesTasks
                                                                            .length >
                                                                            0,
                                                                }}
                                                                onNotify={
                                                                    handleNotify
                                                                }
                                                                userLink={
                                                                    canUsersAll
                                                                }
                                                                setSeeGantt={() => {
                                                                    handleDisplayMilestoneGantt(
                                                                        aIndex,
                                                                    );
                                                                }}
                                                            />
                                                        ),
                                                )}
                                            </div>
                                        )}

                                        {(status === 'all' ||
                                            status === 'done') &&
                                            doneActivities.length > 0 && (
                                                <div className="relative w-full border-l-2 border-layout-light py-8 pl-8 sm:pl-4">
                                                    <h2 className="mb-12">
                                                        {t('Done')}
                                                    </h2>
                                                    {doneActivities.map(
                                                        (a, aIndex) =>
                                                            a.activityInfo
                                                                .actionType ===
                                                                'TASK' &&
                                                            a.activityInfo
                                                                .pipelineType ===
                                                                'LEADS_TO_POTENTIAL' ? (
                                                                <TaskManagementOpportunityDisplay
                                                                    key={
                                                                        a
                                                                            .activityInfo
                                                                            .id
                                                                    }
                                                                    opportunity={
                                                                        a
                                                                    }
                                                                    deleteActivity={
                                                                        deleteActivity
                                                                    }
                                                                    fetchActivities={
                                                                        fetchActivities
                                                                    }
                                                                    updateActivity={
                                                                        updateActivity
                                                                    }
                                                                    userLink={
                                                                        canUsersAll
                                                                    }
                                                                    actionArrow={{
                                                                        mark: canAll,
                                                                        gantt:
                                                                            canAll &&
                                                                            a
                                                                                .activityInfo
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0,
                                                                        notify:
                                                                            canAll &&
                                                                            a
                                                                                .activityInfo
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0,
                                                                    }}
                                                                    onNotify={
                                                                        handleNotify
                                                                    }
                                                                    setSeeGantt={() => {
                                                                        handleDisplayMilestoneGantt(
                                                                            aIndex,
                                                                        );
                                                                    }}
                                                                />
                                                            ) : (
                                                                <TaskManagementDisplay
                                                                    key={
                                                                        a
                                                                            .activityInfo
                                                                            .id
                                                                    }
                                                                    activity={a}
                                                                    deleteActivity={
                                                                        deleteActivity
                                                                    }
                                                                    fetchActivities={
                                                                        fetchActivities
                                                                    }
                                                                    updateActivity={
                                                                        updateActivity
                                                                    }
                                                                    userLink={
                                                                        canUsersAll
                                                                    }
                                                                    actionArrow={{
                                                                        mark: canAll,
                                                                        gantt:
                                                                            canAll &&
                                                                            a
                                                                                .activityInfo
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0,
                                                                        notify:
                                                                            canAll &&
                                                                            a
                                                                                .activityInfo
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0,
                                                                    }}
                                                                    onNotify={
                                                                        handleNotify
                                                                    }
                                                                    setSeeGantt={() => {
                                                                        handleDisplayMilestoneGantt(
                                                                            aIndex,
                                                                        );
                                                                    }}
                                                                />
                                                            ),
                                                    )}
                                                </div>
                                            )}

                                        {(status === 'all' ||
                                            status === 'failed') &&
                                            failedActivities.length > 0 && (
                                                <div className="relative w-full border-l-2 border-error-light py-8 pl-8 sm:pl-4">
                                                    <h2 className="mb-12">
                                                        {t('Failed')}
                                                    </h2>
                                                    {failedActivities.map(
                                                        (a, aIndex) =>
                                                            a.activityInfo
                                                                .actionType ===
                                                                'TASK' &&
                                                            a.activityInfo
                                                                .pipelineType ===
                                                                'LEADS_TO_POTENTIAL' ? (
                                                                <TaskManagementOpportunityDisplay
                                                                    key={
                                                                        a
                                                                            .activityInfo
                                                                            .id
                                                                    }
                                                                    opportunity={
                                                                        a
                                                                    }
                                                                    deleteActivity={
                                                                        deleteActivity
                                                                    }
                                                                    fetchActivities={
                                                                        fetchActivities
                                                                    }
                                                                    updateActivity={
                                                                        updateActivity
                                                                    }
                                                                    userLink={
                                                                        canUsersAll
                                                                    }
                                                                    actionArrow={{
                                                                        mark: canAll,
                                                                        gantt:
                                                                            canAll &&
                                                                            a
                                                                                .activityInfo
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0,
                                                                        notify:
                                                                            canAll &&
                                                                            a
                                                                                .activityInfo
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0,
                                                                    }}
                                                                    onNotify={
                                                                        handleNotify
                                                                    }
                                                                    setSeeGantt={() => {
                                                                        handleDisplayMilestoneGantt(
                                                                            aIndex,
                                                                        );
                                                                    }}
                                                                />
                                                            ) : (
                                                                <TaskManagementDisplay
                                                                    key={
                                                                        a
                                                                            .activityInfo
                                                                            .id
                                                                    }
                                                                    activity={a}
                                                                    deleteActivity={
                                                                        deleteActivity
                                                                    }
                                                                    fetchActivities={
                                                                        fetchActivities
                                                                    }
                                                                    updateActivity={
                                                                        updateActivity
                                                                    }
                                                                    userLink={
                                                                        canUsersAll
                                                                    }
                                                                    actionArrow={{
                                                                        mark: canAll,
                                                                        gantt:
                                                                            canAll &&
                                                                            a
                                                                                .activityInfo
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0,
                                                                        notify:
                                                                            canAll &&
                                                                            a
                                                                                .activityInfo
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0,
                                                                    }}
                                                                    onNotify={
                                                                        handleNotify
                                                                    }
                                                                    setSeeGantt={() => {
                                                                        handleDisplayMilestoneGantt(
                                                                            aIndex,
                                                                        );
                                                                    }}
                                                                />
                                                            ),
                                                    )}
                                                </div>
                                            )}
                                    </Fragment>
                                )}

                                {!todoActivities.length &&
                                    !doneActivities.length &&
                                    !failedActivities.length &&
                                    !loadingActivities && <NoDataPlaceholder />}
                            </div>

                            {canAll && modalOpen && (
                                <div className="sticky top-8 z-50 ml-8 rounded-md bg-layout-transparent xl:fixed xl:left-2/4 xl:top-2/4 xl:ml-0 xl:-translate-x-2/4 xl:-translate-y-2/4 xl:transform xl:bg-layout-light">
                                    <PMTaskManagementModal
                                        closeModal={() => setModalOpen(false)}
                                        departments={departments}
                                        tenants={projectAccounts}
                                    />
                                </div>
                            )}

                            <div
                                className={`fixed left-0 top-0  z-40 hidden h-svh w-screen ${
                                    modalOpen ? 'xl:flex' : ''
                                }`}
                                style={{
                                    backgroundColor: 'rgba(0,0,0,0.3)',
                                    backdropFilter: 'blur(5px)',
                                }}
                                onClick={() => setModalOpen((prev) => !prev)}
                            />

                            <TimelineEmailNotification
                                open={openNotify}
                                cancel={() => {
                                    setNotifyInitialMilestone(null);
                                    handleToggleNotify();
                                }}
                                milestones={milestones}
                                initialMilestone={notifyInitialMilestone}
                                exportProps={{
                                    purpose: 'pm',
                                    params: {
                                        currentPage: 0,
                                        perPage: 99999,
                                        pagesToLoad: 1,
                                        pmProjectPlanId: projectId,
                                    },
                                }}
                            />
                        </div>
                    </TaskManagementContext.Provider>
                )}
            </div>
        </>
    );
};

PMTaskManagementTimeline.propTypes = {
    projectName: PropTypes.string,
};

export default PMTaskManagementTimeline;
