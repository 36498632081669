import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';

import FrappeGanttLayout from 'components/frappe-gantt/frappe-gantt-layout';
import ActivityModal from 'components/internal-activity/activity-modal';
import Activity from 'components/internal-activity/activity/activity';
import Opportunity from 'components/internal-activity/opportunity/opportunity';
import TimelineEmailNotification from 'components/internal-activity/timeline-email-notification';
import CustomModal from 'components/modals/custom_modal';
import LoadingExportModal from 'components/modals/loading-export-modal';
import FileDisplay from 'components/shared/file-display/file-display';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import ActivityContext from 'contexts/ActivityContext';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import useQuery from 'hooks/useQuery';
import useScreenSizes from 'hooks/useScreenSizes.js';
import { useSnackbar } from 'notistack';
import { Header, Tabs } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { crm } from 'routes';
import { formatDate } from 'utils';
import API from 'utils/axios';
import exportImages from 'utils/exportImages';
import exportImagesToPDF from 'utils/exportImagesToPDF';
import { getTasks } from 'utils/ganttUtils';
import {
    getContactById,
    getDepartments,
    getEntityFields,
} from 'utils/getterFunctions';

const ContactActivity = () => {
    const { t } = useTranslation();
    const { id: contactId } = useParams();
    const history = useHistory();
    const { user } = useContext(UserContext);
    const { language } = useContext(GlobalContext);

    const [query] = useQuery();
    const view = query.get('view') ?? 'Day';

    const [departments, setDepartments] = useState([]);
    const [contact, setContact] = useState(null);
    const [contactFields, setContactFields] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(0);
    const [activityForEdit, setActivityForEdit] = useState(null);
    const [width] = useScreenSizes();
    const [showClientInfo, setShowClientInfo] = useState(false);

    const [loadingExportImage, setLoadingExportImage] = useState(false);
    const [loadingexportImagesAsPDF, setLoadingexportImagesAsPDF] =
        useState(false);

    const [seeGantt, setSeeGantt] = useState(false);
    const handleToggleGantt = () => setSeeGantt((prev) => !prev);

    const { enqueueSnackbar } = useSnackbar();

    const { checkPerm } = useContext(UserContext);

    const canAll = checkPerm([
        {
            permissionId: '5',
            permissionType: 'ALL',
        },
    ]);

    const canViewDocuments = checkPerm([
        {
            permissionId: '26',
            permissionType: 'VIEW',
        },
    ]);

    const canViewActions = checkPerm([
        {
            permissionId: '45',
            permissionType: 'VIEW',
        },
    ]);

    const canAllActions = checkPerm([
        {
            permissionId: '45',
            permissionType: 'ALL',
        },
    ]);

    const canEditDocuments = checkPerm([
        {
            permissionId: '26',
            permissionType: 'ALL',
        },
    ]);

    const canNotify = checkPerm([
        {
            permissionId: '31',
            permissionType: 'VIEW',
        },
    ]);

    const canUsersAll = checkPerm([
        {
            permissionId: '11',
            permissionType: 'ALL',
        },
    ]);

    //Send notification states
    const [milestones, setMilestones] = useState([]);
    const memoizedMilestones = useMemo(() => milestones, [milestones]);

    const [openNotify, setOpenNotify] = useState(false);
    const handleToggleNotify = () => setOpenNotify((prev) => !prev);

    const [notifyInitialMilestone, setNotifyInitialMilestone] = useState(null);
    const handleNotify = (milestoneId) => {
        setNotifyInitialMilestone(milestoneId);
        handleToggleNotify();
    };

    /*
        Filters
        Status: todo, done, all
        Type: comment, task, email...
   */
    const [status, setStatus] = useState('all');
    const [activityType, setActivityType] = useState('ALL');

    const handleTypeChange = (event) => {
        setActivityType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    /*
        Variables for manipulating activity modal
        On create: createActivity is true
        On update: updatedActivity is the activity itself
    */
    const [isCreating, setIsCreating] = useState(false);
    const [createActivity, setCreateActivity] = useState(false);

    const submitNewActivity = async (reqBody) => {
        if (isCreating) return;

        try {
            setIsCreating(true);
            await API.post('/contact_activities', reqBody);
            enqueueSnackbar(t('Activity added successfully!'), {
                variant: 'success',
            });
            setCreateActivity(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    const editActivity = async (reqBody) => {
        if (isCreating) return;

        try {
            setIsCreating(true);
            await API.put('/contact_activities', reqBody);
            enqueueSnackbar(t('Activity was edited successfully!'), {
                variant: 'success',
            });
            setActivityForEdit(null);
            setCreateActivity(false);
        } catch (err) {
            console.error(err);
        } finally {
            setIsCreating(false);
            fetchActivities();
        }
    };

    /*
        Fetching data periodically, and separating all activities in 2 arrays
        Update occurs only if differences are spotted
    */
    const [loadingActivities, setLoadingActivities] = useState(false);
    const [todoActivities, setTodoActivities] = useState([]);
    const [doneActivities, setDoneActivities] = useState([]);
    const [failedActivities, setFailedActivities] = useState([]);

    const fetchActivities = async () => {
        if (loadingActivities) return;

        const params = {
            currentPage: 0,
            perPage: 99999,
            pagesToLoad: 1,
            id: contactId,
        };

        const res = await API.get('/contacts_activities', { params });

        let activities = res.data.activities;

        if (!canViewDocuments || !canViewActions) {
            activities = activities.filter((activity) => {
                if (
                    !canViewDocuments &&
                    (activity.actionType === 'DOCUMENTS' ||
                        activity.actionType === 'FILES')
                ) {
                    return false;
                }

                if (!canViewActions && activity.actionType === 'ACTION') {
                    return false;
                }

                return true;
            });
        }

        const milestoneActivities = activities.filter(
            (activity) => activity.activitiesTasks.length > 0,
        );
        setMilestones(milestoneActivities);

        const filteredActivities = activities.reduce(
            (acc, cur) => {
                if (!cur.status) return acc;

                const canDisplay =
                    (activityType === 'ALL' ||
                        activityType === cur.actionType) &&
                    (selectedDepartment === 0 ||
                        cur.departmentId ===
                            departments[selectedDepartment - 1].id);

                if (canDisplay)
                    return { ...acc, [cur.status]: [...acc[cur.status], cur] };
                return acc;
            },
            {
                TODO: [],
                DONE: [],
                FAILED: [],
            },
        );

        if (
            JSON.stringify(filteredActivities.TODO) !==
            JSON.stringify(todoActivities)
        ) {
            setTodoActivities(filteredActivities.TODO);
        }

        if (
            JSON.stringify(filteredActivities.DONE) !==
            JSON.stringify(doneActivities)
        ) {
            setDoneActivities(filteredActivities.DONE);
        }

        if (
            JSON.stringify(filteredActivities.FAILED) !==
            JSON.stringify(failedActivities)
        ) {
            setFailedActivities(filteredActivities.FAILED);
        }
    };

    /*
        CRUD
        1. Delete
    */
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteActivity = async (activity) => {
        if (user.id !== activity.authorId) return;
        if (isDeleting) return;

        setIsDeleting(true);

        try {
            await API.delete('contact_activities', {
                data: {
                    id: activity.id,
                },
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsDeleting(false);
            fetchActivities();
        }
    };

    /*
        2. Update
    */
    const [isUpdating, setIsUpdating] = useState(false);

    const updateActivity = async (activity, newValue) => {
        if (user.id !== activity.authorId) return;
        if (isUpdating) return;

        setIsUpdating(true);

        try {
            await API.post('/mark_activity', {
                id: activity.id,
                status: newValue,
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsUpdating(false);
            fetchActivities();
        }
    };

    // Export Gantt as image logic

    const handleExportGanntAsImage = async () => {
        try {
            setLoadingExportImage(true);

            const ganttQuery = [
                {
                    ids: ['gantt-wrapper-root'],
                    type: 'gantt',
                    element: {
                        type: 'milestones',
                        purpose: 'client',
                    },
                    data: {
                        view,
                        customDates: null,
                        selectedEntities: null,
                    },
                    request: {
                        params: {
                            currentPage: 0,
                            perPage: 99999,
                            pagesToLoad: 1,
                            id: contactId,
                        },
                    },
                },
            ];
            await exportImages(ganttQuery, language, true);
        } catch (error) {
            enqueueSnackbar(t("Gantt couldn't be exported"), {
                variant: 'error',
            });
            console.error(error);
        } finally {
            setLoadingExportImage(false);
        }
    };

    // Export Gantt as pdf logic

    const handleExportGanntAsPDF = async () => {
        try {
            setLoadingexportImagesAsPDF(true);

            const contactName =
                typeof contact.data === 'string'
                    ? JSON.parse(contact.data).standard.name
                    : contact.data.standard.name;

            await exportImagesToPDF(
                'landscape',
                language,
                [
                    {
                        ids: ['gantt-wrapper-root'],
                        type: 'gantt',
                        element: {
                            type: 'milestones',
                            purpose: 'client',
                        },
                        data: {
                            view,
                            customDates: null,
                            selectedEntities: null,
                        },
                        request: {
                            params: {
                                currentPage: 0,
                                perPage: 99999,
                                pagesToLoad: 1,
                                id: contactId,
                            },
                        },
                    },
                ],
                [
                    {
                        ids: ['gantt-legend-root'],
                        type: 'gantt-legend',
                        element: {
                            type: 'milestones',
                        },
                    },
                ],
                () => setLoadingexportImagesAsPDF(false),
                null,
                true,
                'multi-page',
                null,
                `Gantt_Export_${new Date().toISOString().slice(0, 10)}_${contactName}.pdf`,
            );
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Fetch departments
     */
    useEffect(() => {
        (async () => {
            const deps = await getDepartments();
            const contact = await getContactById(contactId);
            const contactFields = await getEntityFields('Contacts');
            setContactFields(contactFields.objectToRender);
            if (!contact) {
                history.push(crm.base + crm.contacts.base + crm.contacts.read);
            }

            setDepartments(deps);
            setContact(contact);
        })();
    }, []);

    useEffect(() => {
        const getData = async () => {
            setLoadingActivities(true);
            await fetchActivities();
            setLoadingActivities(false);
        };

        if (departments.length > 0) {
            getData();
        } else {
            setLoadingActivities(false);
        }
    }, [departments, activityType, selectedDepartment]);

    useEffect(() => {
        if (width <= 1500 && createActivity && showClientInfo)
            setShowClientInfo(false);
    }, [width]);

    return (
        <>
            <Helmet>
                <title>CRM | {t('contact-activity')}</title>
            </Helmet>

            <Header
                pageTitle={
                    selectedDepartment !== 0
                        ? departments[selectedDepartment - 1].name
                        : t('contact-activity')
                }
                action={
                    <Fragment>
                        {canNotify && seeGantt === false && (
                            <Button
                                startIcon={<EmailIcon />}
                                color="secondary"
                                style={{ borderRadius: '999px' }}
                                onClick={handleToggleNotify}
                            >
                                {t('Notify')}
                            </Button>
                        )}

                        {canAll && seeGantt === false && (
                            <>
                                <div
                                    className={`duration-350 transform opacity-100 transition-all`}
                                >
                                    <Button
                                        startIcon={<VisibilityIcon />}
                                        color="secondary"
                                        style={{ borderRadius: '999px' }}
                                        onClick={() => {
                                            setShowClientInfo((prev) => !prev);
                                        }}
                                    >
                                        {showClientInfo
                                            ? t('Hide client info')
                                            : t('Show client info')}
                                    </Button>
                                </div>
                                <div
                                    className={`duration-350 transform transition-all ${
                                        createActivity
                                            ? 'translate-x-8 opacity-0'
                                            : 'translate-x-0 opacity-100'
                                    }`}
                                >
                                    <Button
                                        startIcon={<AddIcon />}
                                        color="secondary"
                                        style={{ borderRadius: '999px' }}
                                        onClick={() => {
                                            setCreateActivity((prev) => !prev);
                                        }}
                                    >
                                        {t('Add activity')}
                                    </Button>
                                </div>
                            </>
                        )}

                        {canAll && seeGantt !== false && (
                            <>
                                <Button
                                    startIcon={
                                        loadingExportImage ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <ImportExportIcon />
                                        )
                                    }
                                    color="secondary"
                                    style={{ borderRadius: '999px' }}
                                    onClick={
                                        loadingExportImage
                                            ? undefined
                                            : handleExportGanntAsImage
                                    }
                                >
                                    {t('Export Gantt as image')}
                                </Button>
                                <Button
                                    startIcon={
                                        loadingexportImagesAsPDF ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <ImportExportIcon />
                                        )
                                    }
                                    color="secondary"
                                    style={{ borderRadius: '999px' }}
                                    onClick={
                                        loadingexportImagesAsPDF
                                            ? undefined
                                            : handleExportGanntAsPDF
                                    }
                                >
                                    {t('Export Gantt as PDF')}
                                </Button>
                            </>
                        )}
                    </Fragment>
                }
                toolbar={
                    <div className="flex items-center gap-8">
                        {!seeGantt && (
                            <Tabs
                                tabs={[
                                    t('All'),
                                    ...(departments?.map((d) => d.name) ?? []),
                                ]}
                                activeTab={selectedDepartment}
                                setActiveTab={setSelectedDepartment}
                            />
                        )}
                        <div className="flex-shrink-0">
                            <Button
                                style={{ borderRadius: '999px' }}
                                onClick={handleToggleGantt}
                            >
                                {seeGantt === true
                                    ? t('See Timeline')
                                    : t('See Gantt')}
                            </Button>
                        </div>
                    </div>
                }
                toolbarSecondary={
                    <div className="flex flex-col">
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="status"
                                name="status-filter"
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <div className="no-user-select-recursive flex items-center">
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio />}
                                        label={t('All')}
                                    />
                                    <FormControlLabel
                                        value="todo"
                                        control={<Radio />}
                                        label={t('To do')}
                                    />
                                    <FormControlLabel
                                        value="done"
                                        control={<Radio />}
                                        label={t('Done')}
                                    />
                                    <FormControlLabel
                                        value="failed"
                                        control={<Radio />}
                                        label={t('Failed')}
                                    />
                                </div>
                            </RadioGroup>
                        </FormControl>

                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="activity-type"
                                name="activity-type-filter"
                                value={activityType}
                                onChange={handleTypeChange}
                            >
                                <div className="no-user-select-recursive flex items-center">
                                    <FormControlLabel
                                        value="ALL"
                                        control={<Radio />}
                                        label={t('All')}
                                    />
                                    <FormControlLabel
                                        value="COMMENT"
                                        control={<Radio />}
                                        label={t('Comments')}
                                    />
                                    <FormControlLabel
                                        value="TASK"
                                        control={<Radio />}
                                        label={'Tasks'}
                                    />
                                    <FormControlLabel
                                        value="MILESTONE"
                                        control={<Radio />}
                                        label={t('Milestones')}
                                    />
                                    <FormControlLabel
                                        value="OPPORTUNITY"
                                        control={<Radio />}
                                        label={t('Opportunities')}
                                    />
                                    <FormControlLabel
                                        value="FILES"
                                        control={<Radio />}
                                        label={t('Files')}
                                    />
                                    <FormControlLabel
                                        value="DOCUMENTS"
                                        control={<Radio />}
                                        label={t('Documents')}
                                    />

                                    {/* <FormControlLabel
                                        value="INVOICES_AND_BILLS"
                                        control={<Radio  />}
                                        label="Facturi/Proforme"
                                    /> */}
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                }
            />

            <div className="page-container">
                {/* 
                    Timeline
                 */}
                {seeGantt && (
                    <Fragment>
                        <FrappeGanttLayout
                            milestones={memoizedMilestones}
                            getTasksFunction={getTasks}
                        />
                        <LoadingExportModal
                            open={
                                loadingExportImage || loadingexportImagesAsPDF
                            }
                        />
                    </Fragment>
                )}

                {!seeGantt && (
                    <ActivityContext.Provider
                        value={{
                            isCreating,
                            setIsCreating,
                            submitNewActivity,
                            fetchActivities,
                            isContact: true,
                            setActivityForEdit,
                            activityForEdit,
                            editActivity,
                        }}
                    >
                        <div className="relative z-40 flex items-start justify-center gap-12">
                            {contact !== null && showClientInfo && (
                                <div className="sticky top-8 z-50 whitespace-nowrap rounded-md bg-layout-transparent 2xl:fixed 2xl:left-2/4 2xl:top-2/4 2xl:-translate-x-2/4 2xl:-translate-y-2/4 2xl:transform 2xl:bg-layout-light">
                                    <div className="mb-5 flex items-center justify-between px-10 pt-10 sm:px-4 sm:pt-4">
                                        <h2 className="font-semibold">
                                            {t('Contact information')}
                                        </h2>
                                        <CloseIcon
                                            className="ml-10 cursor-pointer text-primary-main opacity-50 hover:opacity-100"
                                            style={{
                                                fontSize: '2.5rem',
                                                transition: 'opacity .2s ease',
                                            }}
                                            onClick={() => {
                                                setShowClientInfo(
                                                    (prev) => !prev,
                                                );
                                            }}
                                        />
                                    </div>
                                    {(() => {
                                        const parsedData = JSON.parse(
                                            contact.data,
                                        );
                                        return (
                                            <div
                                                className="overflow-y-auto px-4 py-8"
                                                style={{
                                                    minWidth: '300px',
                                                    width:
                                                        width > 600
                                                            ? 'min-content'
                                                            : '90vw',
                                                    maxHeight:
                                                        width > 1500
                                                            ? '85vh'
                                                            : '70vh',
                                                }}
                                            >
                                                {contactFields.map((column) => {
                                                    const body =
                                                        column.fields.map(
                                                            (field) => {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            field.name
                                                                        }
                                                                        className="mb-2 text-base text-main-text"
                                                                    >
                                                                        {![
                                                                            'Text',
                                                                            'Phone',
                                                                            'Email',
                                                                            'Number',
                                                                        ].indexOf(
                                                                            field.type,
                                                                        ) >= 0
                                                                            ? (() => {
                                                                                  const checkForValue =
                                                                                      column.name in
                                                                                          parsedData &&
                                                                                      field.name in
                                                                                          parsedData[
                                                                                              column
                                                                                                  .name
                                                                                          ];

                                                                                  if (
                                                                                      checkForValue
                                                                                  ) {
                                                                                      const value =
                                                                                          parsedData[
                                                                                              column
                                                                                                  .name
                                                                                          ][
                                                                                              field
                                                                                                  .name
                                                                                          ];
                                                                                      const isArray =
                                                                                          Array.isArray(
                                                                                              parsedData[
                                                                                                  column
                                                                                                      .name
                                                                                              ][
                                                                                                  field
                                                                                                      .name
                                                                                              ],
                                                                                          );
                                                                                      const fileType =
                                                                                          field.type;

                                                                                      if (
                                                                                          isArray &&
                                                                                          fileType ===
                                                                                              'File'
                                                                                      )
                                                                                          return (
                                                                                              <>
                                                                                                  <br />
                                                                                                  <FileDisplay
                                                                                                      utilityOptions={{
                                                                                                          canDelete: false,
                                                                                                          canCopyLink: true,
                                                                                                          canDownload: true,
                                                                                                      }}
                                                                                                      files={
                                                                                                          value
                                                                                                      }
                                                                                                      purpose={
                                                                                                          fileType
                                                                                                      }
                                                                                                      view={
                                                                                                          true
                                                                                                      }
                                                                                                  />
                                                                                              </>
                                                                                          );

                                                                                      if (
                                                                                          isArray &&
                                                                                          fileType ===
                                                                                              'Image'
                                                                                      )
                                                                                          return (
                                                                                              <>
                                                                                                  <br />
                                                                                                  <FileDisplay
                                                                                                      files={
                                                                                                          value
                                                                                                      }
                                                                                                      purpose={
                                                                                                          fileType
                                                                                                      }
                                                                                                      view={
                                                                                                          true
                                                                                                      }
                                                                                                  />
                                                                                              </>
                                                                                          );

                                                                                      if (
                                                                                          !isArray &&
                                                                                          fileType ===
                                                                                              'Date'
                                                                                      )
                                                                                          return (
                                                                                              <span className="font-semibold text-main-text">
                                                                                                  {formatDate(
                                                                                                      value,
                                                                                                  )}
                                                                                              </span>
                                                                                          );

                                                                                      if (
                                                                                          !isArray
                                                                                      )
                                                                                          return (
                                                                                              <span className="font-semibold text-main-text">
                                                                                                  {
                                                                                                      value
                                                                                                  }
                                                                                              </span>
                                                                                          );
                                                                                  } else {
                                                                                      return (
                                                                                          <>

                                                                                          </>
                                                                                      );
                                                                                  }
                                                                              })()
                                                                            : ''}
                                                                    </div>
                                                                );
                                                            },
                                                        );
                                                    return (
                                                        <div
                                                            key={column.name}
                                                            className="mb-10 last:mb-0"
                                                        >
                                                            <h4 className="mb-5 border-b-2 border-layout-transparent pb-2 text-2xl">
                                                                {
                                                                    column.displayName
                                                                }
                                                            </h4>
                                                            {body}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })()}
                                </div>
                            )}

                            <div
                                className="relative z-40 flex w-full flex-col items-center pl-24 md:pl-32 sm:pl-0"
                                style={{ maxWidth: '1000px' }}
                            >
                                {loadingActivities && <CircularProgress />}
                                {/* Activities Timeline */}
                                {(todoActivities.length > 0 ||
                                    doneActivities.length > 0 ||
                                    failedActivities.length > 0) && (
                                    <>
                                        {(((status === 'all' ||
                                            status === 'todo') &&
                                            todoActivities.length > 0) ||
                                            isCreating) && (
                                            <div className="relative w-full border-l-2 border-layout-lighter py-8 pl-8 sm:pl-4">
                                                <h2 className="mb-12">
                                                    {t('To do')}
                                                </h2>

                                                {/*
                                                    Creating loading placeholder
                                                */}
                                                <div
                                                    className={`pointer-events-none flex w-full items-center justify-center overflow-hidden rounded-md bg-layout-transparent transition-all duration-300 ${
                                                        isCreating
                                                            ? 'opacity-1 mb-10 h-48'
                                                            : 'mb-0 h-0 opacity-0'
                                                    }`}
                                                >
                                                    <CircularProgress />
                                                </div>

                                                {todoActivities.map((a) =>
                                                    a.actionType === 'TASK' &&
                                                    a.pipelineType ===
                                                        'LEADS_TO_POTENTIAL' ? (
                                                        <Opportunity
                                                            key={a.id}
                                                            opportunity={a}
                                                            deleteOpportunity={
                                                                deleteActivity
                                                            }
                                                            updateOpportunity={
                                                                updateActivity
                                                            }
                                                            actionArrow={{
                                                                mark: canAll,
                                                                edit: canAll,
                                                                notify:
                                                                    canNotify &&
                                                                    a
                                                                        .activitiesTasks
                                                                        .length >
                                                                        0
                                                                        ? true
                                                                        : false,
                                                            }}
                                                            onNotify={
                                                                handleNotify
                                                            }
                                                            userLink={
                                                                canUsersAll
                                                            }
                                                            setEditActivity={() => {
                                                                setCreateActivity(
                                                                    true,
                                                                );
                                                                setActivityForEdit(
                                                                    a,
                                                                );
                                                            }}
                                                        />
                                                    ) : (
                                                        <Activity
                                                            key={a.id}
                                                            activity={a}
                                                            deleteActivity={
                                                                deleteActivity
                                                            }
                                                            updateActivity={
                                                                updateActivity
                                                            }
                                                            actionArrow={{
                                                                mark:
                                                                    (a.actionType !==
                                                                        'ACTION' &&
                                                                        canAll) ||
                                                                    (a.actionType ===
                                                                        'ACTION' &&
                                                                        canAllActions),
                                                                edit:
                                                                    (a.actionType !==
                                                                        'ACTION' &&
                                                                        canAll) ||
                                                                    (a.actionType ===
                                                                        'ACTION' &&
                                                                        canAllActions),
                                                                notify:
                                                                    canNotify &&
                                                                    a
                                                                        .activitiesTasks
                                                                        .length >
                                                                        0
                                                                        ? true
                                                                        : false,
                                                            }}
                                                            onNotify={
                                                                handleNotify
                                                            }
                                                            userLink={
                                                                canUsersAll
                                                            }
                                                            setEditActivity={() => {
                                                                setCreateActivity(
                                                                    true,
                                                                );
                                                                setActivityForEdit(
                                                                    a,
                                                                );
                                                            }}
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        )}

                                        {(status === 'all' ||
                                            status === 'done') &&
                                            doneActivities.length > 0 && (
                                                <div className="relative w-full border-l-2 border-layout-light py-8 pl-8 sm:pl-4">
                                                    <h2 className="mb-12">
                                                        {t('Done')}
                                                    </h2>
                                                    {doneActivities.map((a) =>
                                                        a.actionType ===
                                                            'TASK' &&
                                                        a.pipelineType ===
                                                            'LEADS_TO_POTENTIAL' ? (
                                                            <Opportunity
                                                                key={a.id}
                                                                opportunity={a}
                                                                deleteOpportunity={
                                                                    deleteActivity
                                                                }
                                                                updateOpportunity={
                                                                    updateActivity
                                                                }
                                                                userLink={
                                                                    canUsersAll
                                                                }
                                                                actionArrow={{
                                                                    mark: canAll,
                                                                    notify:
                                                                        canNotify &&
                                                                        a
                                                                            .activitiesTasks
                                                                            .length >
                                                                            0
                                                                            ? true
                                                                            : false,
                                                                }}
                                                                onNotify={
                                                                    handleNotify
                                                                }
                                                            />
                                                        ) : (
                                                            <Activity
                                                                key={a.id}
                                                                activity={a}
                                                                deleteActivity={
                                                                    deleteActivity
                                                                }
                                                                updateActivity={
                                                                    updateActivity
                                                                } // To actually edit activity in the backend
                                                                userLink={
                                                                    canUsersAll
                                                                }
                                                                actionArrow={{
                                                                    mark:
                                                                        (a.actionType !==
                                                                            'ACTION' &&
                                                                            canAll) ||
                                                                        (a.actionType ===
                                                                            'ACTION' &&
                                                                            canAllActions),
                                                                    notify:
                                                                        canNotify &&
                                                                        a
                                                                            .activitiesTasks
                                                                            .length >
                                                                            0
                                                                            ? true
                                                                            : false,
                                                                }}
                                                                onNotify={
                                                                    handleNotify
                                                                }
                                                            />
                                                        ),
                                                    )}
                                                </div>
                                            )}

                                        {(status === 'all' ||
                                            status === 'failed') &&
                                            failedActivities.length > 0 && (
                                                <div className="relative w-full border-l-2 border-error-light py-8 pl-8 sm:pl-4">
                                                    <h2 className="mb-12">
                                                        {t('Failed')}
                                                    </h2>
                                                    {failedActivities.map(
                                                        (a) =>
                                                            a.actionType ===
                                                                'TASK' &&
                                                            a.pipelineType ===
                                                                'LEADS_TO_POTENTIAL' ? (
                                                                <Opportunity
                                                                    key={a.id}
                                                                    opportunity={
                                                                        a
                                                                    }
                                                                    deleteOpportunity={
                                                                        deleteActivity
                                                                    }
                                                                    updateOpportunity={
                                                                        updateActivity
                                                                    }
                                                                    userLink={
                                                                        canUsersAll
                                                                    }
                                                                    actionArrow={{
                                                                        mark: canAll,
                                                                        notify:
                                                                            canNotify &&
                                                                            a
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0
                                                                                ? true
                                                                                : false,
                                                                    }}
                                                                    onNotify={
                                                                        handleNotify
                                                                    }
                                                                />
                                                            ) : (
                                                                <Activity
                                                                    key={a.id}
                                                                    activity={a}
                                                                    deleteActivity={
                                                                        deleteActivity
                                                                    }
                                                                    updateActivity={
                                                                        updateActivity
                                                                    } // To actually edit activity in the backend
                                                                    userLink={
                                                                        canUsersAll
                                                                    }
                                                                    actionArrow={{
                                                                        mark:
                                                                            (a.actionType !==
                                                                                'ACTION' &&
                                                                                canAll) ||
                                                                            (a.actionType ===
                                                                                'ACTION' &&
                                                                                canAllActions),
                                                                        notify:
                                                                            canNotify &&
                                                                            a
                                                                                .activitiesTasks
                                                                                .length >
                                                                                0
                                                                                ? true
                                                                                : false,
                                                                    }}
                                                                    onNotify={
                                                                        handleNotify
                                                                    }
                                                                />
                                                            ),
                                                    )}
                                                </div>
                                            )}
                                    </>
                                )}

                                {!todoActivities.length &&
                                    !doneActivities.length &&
                                    !failedActivities.length &&
                                    !loadingActivities && <NoDataPlaceholder />}
                            </div>

                            {/* New Activity Modal */}
                            {canAll && createActivity && (
                                <div className="sticky top-8 z-50 ml-8 rounded-md bg-layout-transparent 2xl:fixed 2xl:left-2/4 2xl:top-2/4 2xl:ml-0 2xl:-translate-x-2/4 2xl:-translate-y-2/4 2xl:transform 2xl:bg-layout-light">
                                    <ActivityModal
                                        close={() => setCreateActivity(false)}
                                        contactId={contactId}
                                        selectedDepartment={selectedDepartment}
                                        editDocuments={canEditDocuments}
                                    />
                                </div>
                            )}
                            <div
                                className={`fixed left-0 top-0 z-40 hidden h-svh w-screen ${
                                    createActivity || showClientInfo
                                        ? '2xl:flex'
                                        : ''
                                }`}
                                style={{
                                    backgroundColor: 'rgba(0,0,0,0.3)',
                                    backdropFilter: 'blur(5px)',
                                }}
                                onClick={() => {
                                    setCreateActivity(false);
                                    setShowClientInfo(false);
                                }}
                            />

                            <TimelineEmailNotification
                                open={openNotify}
                                cancel={() => {
                                    setNotifyInitialMilestone(null);
                                    handleToggleNotify();
                                }}
                                milestones={milestones}
                                initialMilestone={notifyInitialMilestone}
                                exportProps={{
                                    purpose: 'client',
                                    params: {
                                        currentPage: 0,
                                        perPage: 99999,
                                        pagesToLoad: 1,
                                        id: contactId,
                                    },
                                }}
                            />
                        </div>
                    </ActivityContext.Provider>
                )}
            </div>
        </>
    );
};

export default ContactActivity;
