import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { Button, CircularProgress, TextField } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import NoDataPlaceholder from 'components/shared/no-data-placeholder';
import Pagination from 'components/shared/pagination';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { ActionButton, LabelWrapper } from 'RaisisComponents/index.js';
import { useTranslation } from 'react-i18next';
import { configurator } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const OfferTags = ({ setInitializeData }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { checkPerm } = useContext(UserContext);

    const globalContext = useContext(GlobalContext);

    const [offerTags, setOfferTags] = useState([]);

    const [pageNow, setPageNow] = useState(0);

    const canView = checkPerm([
        {
            permissionId: '33',
            permissionType: 'VIEW',
        },
    ]);

    const canAll = checkPerm([
        {
            permissionId: '33',
            permissionType: 'ALL',
        },
    ]);

    useEffect(() => {
        if (!canView) {
            history.push('/');
        }
    }, [canView]);

    const getOfferTags = async (currentPage, perPage) => {
        try {
            const res = await API.get('/offer_tags', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setPageNow(currentPage);

            setOfferTags(res.data.data.offerTag);
            setInitializeData?.((prev) => ({ ...prev, nextDisabled: !res.data.data.length }));
            return res.data.data.length;
        } catch (err) {
            throw new Error(err);
        }
    };

    const deleteOfferTag = async (tagId) => {
        try {
            await API.delete(`/offer_tag/${tagId}`);
            enqueueSnackbar(t('Tag deleted successfully!'), {
                variant: 'success',
            });
        } catch (err) {
            enqueueSnackbar(errorHandling(err).length > 100 ? errorHandling(err) : t(errorHandling(err)), {
                variant: 'error',
            });
        }
    };

    return (
        <>
            <Pagination loading={loading} setLoading={setLoading} getFunction={getOfferTags}>
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : offerTags.length ? (
                    <>
                        {offerTags.map((tag, index) => (
                            <div key={index} className="group relative flex max-w-md items-center space-x-2">
                                <div className="pt-3 text-main-text">{index + 1 + pageNow * 10}.</div>
                                <LabelWrapper label={`${index === 0 ? t('Tag name') : ''}`}>
                                    <TextField
                                        placeholder={t('Tag name')}
                                        value={tag.name}
                                        disabled={true}
                                        readOnly={true}
                                    />
                                </LabelWrapper>

                                {canAll && !setInitializeData && (
                                    <div className="left-full top-7 mt-5 flex gap-2 pl-1">
                                        <div className="flex-shrink-0">
                                            <BasicTooltip tip={t('Edit tag')}>
                                                <ActionButton
                                                    color="var(--success)"
                                                    icon={<EditIcon />}
                                                    onClick={() =>
                                                        history.push(
                                                            configurator.base +
                                                                configurator.offerTags.base +
                                                                configurator.offerTags.update +
                                                                '/' +
                                                                tag.id,
                                                        )
                                                    }
                                                />
                                            </BasicTooltip>
                                        </div>

                                        <div className="duration-250 flex-shrink-0 translate-x-5 transform opacity-0 transition-all group-hover:translate-x-0 group-hover:opacity-100">
                                            <BasicTooltip tip={t('Delete tag')}>
                                                <ActionButton
                                                    icon={<DeleteIcon />}
                                                    color="var(--error)"
                                                    onClick={() => {
                                                        globalContext.setGlobalModalOpen(true);
                                                        globalContext.setGlobalModalChildren(
                                                            <div className="flex-col">
                                                                <p className="mb-3 text-center text-2xl font-bold text-main-text">
                                                                    {t('If a tag is deleted,')}
                                                                    <br />
                                                                    {t(
                                                                        'this will be deleted permanently from this article!',
                                                                    )}
                                                                </p>
                                                                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                                    {t('Are you sure you want to')}
                                                                    <br />
                                                                    {t('delete this tag?')}
                                                                </p>
                                                                <div className="flex items-center justify-center">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            globalContext.setGlobalModalOpen(false);
                                                                            deleteOfferTag(tag.id);
                                                                        }}
                                                                    >
                                                                        {t('Yes')}
                                                                    </Button>
                                                                    <div className="w-4" />
                                                                    <Button
                                                                        onClick={() => {
                                                                            globalContext.setGlobalModalOpen(false);
                                                                        }}
                                                                    >
                                                                        {t('No')}
                                                                    </Button>
                                                                </div>
                                                            </div>,
                                                        );
                                                    }}
                                                />
                                            </BasicTooltip>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </>
                ) : (
                    <NoDataPlaceholder />
                )}
            </Pagination>
        </>
    );
};

OfferTags.propTypes = {
    setInitializeData: PropTypes.func,
};

OfferTags.defaultProps = {
    setInitializeData: null,
};

export default OfferTags;
