import React, { useCallback, useContext } from 'react';

import BlockIcon from '@material-ui/icons/Block';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';

import { Button, ClickAwayListener } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import GantContext from 'contexts/GantContext';
import GlobalContext from 'contexts/GlobalContext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';
import API from 'utils/axios';

const EmployeeData = ({ employee, getProjects, setEmployeeModal }) => {
    const { type, viewOnly = false } = useContext(GantContext);
    const [firstName, setFirstName] = React.useState('');
    const globalContext = useContext(GlobalContext);
    const { t } = useTranslation();

    React.useEffect(() => {
        if (!employee) return;
        setFirstName(employee.employee.firstName);
    }, [employee]);

    const deleteTask = useCallback(async (id) => {
        try {
            await API.delete(type == 'pm' ? '/pmEmployeeTasks' : 'employeeTask', {
                params: {
                    id,
                },
            });
            getProjects();
        } catch (err) {
            console.error(err);
        }
    }, []);

    return (
        <ClickAwayListener onClickAway={() => null}>
            <div
                className="fixed left-1/2 top-1/2 z-50 w-96 space-y-4 rounded-lg bg-layout-light p-6 shadow-lg transition-all"
                style={{
                    transform: employee ? 'translate(-50%, -50%)' : 'translate(-50%, calc(-50% - 20px))',
                    opacity: employee ? '1' : '0',
                    pointerEvents: employee ? 'auto' : 'none',
                }}
            >
                <CloseIcon
                    className="absolute right-3 top-3 cursor-pointer text-error transition-colors duration-300  hover:text-error-light"
                    style={{
                        fontSize: '2rem',
                    }}
                    onClick={() => {
                        setEmployeeModal(null);
                    }}
                />

                <Button startIcon={<PersonIcon />} className="pointer-events-none">
                    {firstName}
                </Button>

                {!viewOnly && (
                    <div className="flex flex-col gap-2">
                        {employee?.employee.tasks.map((task) => (
                            <div
                                key={task.id}
                                className="flex w-3/4 items-center justify-between rounded-3xl border border-layout-transparent-dark p-1 text-main-text"
                            >
                                <div className="pl-2">
                                    <p className="font-medium">{task.name}</p>
                                    <p>
                                        {formatDate(task.startDate)} - {formatDate(task.endDate)}
                                    </p>
                                </div>
                                <div
                                    className="flex items-center"
                                    onClick={() => {
                                        globalContext.setGlobalModalOpen(true);
                                        globalContext.setGlobalModalChildren(
                                            <div className="flex-col">
                                                <p className="mb-8 text-center text-2xl font-bold text-main-text">
                                                    {t('Are you sure you want to')}
                                                    <br />
                                                    {t('delete the employee assignment period?')}
                                                </p>
                                                <div className="flex items-center justify-center">
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            setEmployeeModal(null);
                                                            globalContext.setGlobalModalOpen(false);
                                                            deleteTask(task.id);
                                                        }}
                                                    >
                                                        {t('Yes')}
                                                    </Button>
                                                    <div className="w-4" />
                                                    <Button
                                                        onClick={() => {
                                                            globalContext.setGlobalModalOpen(false);
                                                        }}
                                                    >
                                                        {t('No')}
                                                    </Button>
                                                </div>
                                            </div>,
                                        );
                                    }}
                                >
                                    <BasicTooltip tip={t('Delete the employee assignment period')}>
                                        <div className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-error transition-all  hover:bg-error-light group-hover:pointer-events-auto group-hover:translate-y-0">
                                            <BlockIcon className="text-buttons-text" style={{ fontSize: '1.1rem' }} />
                                        </div>
                                    </BasicTooltip>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* <div className="flex">
                    <div className="w-full">
                        <p className="opacity-25">Funcție</p>
                        <p className="font-bold">{position}</p>
                    </div>
                    <div className="w-full">
                        <p className="opacity-25">Echipa</p>
                        <p className="font-bold">Nume Echipa Unu</p>
                    </div>
                </div>
                <div className="w-full">
                    <p className="opacity-25">Timeline pe proiect</p>
                    <p className="font-bold">12 APR - 29 FEB</p>
                </div> */}
                {/* <div
                    className="w-full py-4"
                    style={{
                        borderTop: '1px solid rgba(255, 255, 255, .2)',
                        borderBottom: '1px solid rgba(255, 255, 255, .2)',
                    }}
                >
                    <p className="font-bold">80 ore normate</p>
                </div> */}

                {/* Day rows */}
                {/* <div className="space-y-1">
                    {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                        <div key={day} className="flex items-center w-full justify-between rounded-md bg-transparent hover:bg-blue-500 hover:shadow-lg pl-2 h-8 overflow-hidden group">
                            <p>L</p>
                            <p>2 IAN</p>
                            <p>8 ore</p>
                            <div className="flex opacity-0 group-hover:opacity-100 text-white">
                                <div className="h-8 w-8 bg-layout-transparent hover:bg-layout-transparent-dark cursor-pointer mr-0.5 text-center">
                                    <BorderColorIcon style={{ fontSize: '12px' }} />
                                </div>
                                <div className="h-8 w-8 bg-layout-transparent hover:bg-layout-transparent-dark cursor-pointer text-center">
                                    <PowerSettingsNewIcon style={{ fontSize: '12px' }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div> */}
            </div>
        </ClickAwayListener>
    );
};

EmployeeData.propTypes = {
    employee: PropTypes.object,
    getProjects: PropTypes.func,
    setEmployeeModal: PropTypes.func,
};

export default EmployeeData;
