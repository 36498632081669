import React, { useCallback, useContext, useState } from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

import {
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import Pagination from 'components/shared/pagination';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { TableSeparator } from 'RaisisComponents/index.js';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import API from 'utils/axios';

const CompleteAccountRow = ({ userInfo, deleteUserFromPM, canAll }) => {
    const { t } = useTranslation();

    return (
        <TableRow colSpan={6} padding={false}>
            <TableCell>
                <div className="flex items-center">
                    <div
                        className="mr-4 h-12 w-12 flex-shrink-0 rounded-full bg-cover bg-center"
                        style={{
                            backgroundImage: `url(${userInfo?.profileImageUrl})`,
                        }}
                    />
                    {userInfo.profile?.name}
                </div>
            </TableCell>
            <TableCell>{userInfo.Role.name}</TableCell>
            <TableCell>
                {userInfo.isActive ? t('active') : t('inactive')}
            </TableCell>
            <TableCell>{userInfo.profile?.phoneNo}</TableCell>
            <TableCell>{userInfo.email || ''}</TableCell>
            <TableCell>{userInfo.observation}</TableCell>
            <TableCell>
                {canAll && (
                    <div className="flex justify-end">
                        <Button
                            size="small"
                            startIcon={<DeleteIcon />}
                            onClick={() => deleteUserFromPM(userInfo.id)}
                        >
                            {t('Delete user from PM')}
                        </Button>
                    </div>
                )}
            </TableCell>
        </TableRow>
    );
};

CompleteAccountRow.propTypes = {
    userInfo: PropTypes.object,
    deleteUserFromPM: PropTypes.func,
    canAll: PropTypes.bool,
};

CompleteAccountRow.defaultProps = {
    userInfo: null,
    deleteUserFromPM: () => null,
    canAll: false,
};

export default function CompleteProfiles() {
    const { checkPerm } = useContext(UserContext);
    const canAll = checkPerm([
        {
            permissionId: '61',
            permissionType: 'ALL',
        },
    ]);

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAccounts = async (currentPage, perPage) => {
        try {
            const response = await API.get('/PmUsers', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad: 1,
                },
            });

            setAccounts(response.data.pmUsers.content);
            return response.data.pmUsers.count;
        } catch (err) {
            throw new Error(err);
        }
    };

    const deleteUserFromPM = async (userId) => {
        try {
            await API.delete(`/PmUsers?&userId=${userId}`).then(() => {
                enqueueSnackbar(t('User deleted from PM successfully!'), {
                    variant: 'success',
                });
                getAccounts(0, 10);
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('PM Configurator')} | {t('Completed accounts')}
                </title>
            </Helmet>

            <Pagination
                loading={loading}
                setLoading={setLoading}
                getFunction={getAccounts}
            >
                {loading ? (
                    <div className="flex h-64 w-full items-center justify-center bg-layout-main">
                        <CircularProgress />
                    </div>
                ) : (
                    <TableContainer component={Box}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('User')}</TableCell>
                                    <TableCell>{t('Role')}</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>{t('Phone')}</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>{t('Observations')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableSeparator />
                                {accounts.map((user) => (
                                    <CompleteAccountRow
                                        key={user.id}
                                        userInfo={user}
                                        deleteUserFromPM={deleteUserFromPM}
                                        canAll={canAll}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Pagination>
        </React.Fragment>
    );
}

CompleteProfiles.propTypes = {
    pmId: PropTypes.string,
};

CompleteProfiles.defaultProps = {
    pmId: '',
};
