import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown, LabelWrapper } from 'RaisisComponents';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'utils';

const KpiFilters = ({ labels, disabled, periodsFilter, onChangeFilter }) => {
    const { t } = useTranslation();

    return periodsFilter.filter.map((item) => (
        <div key={item.key} className="w-40">
            {labels ? (
                <LabelWrapper label={t(capitalizeFirstLetter(item.key))}>
                    <Dropdown
                        variant="black"
                        disabled={disabled}
                        options={item.options}
                        selectedOption={periodsFilter.selectedFilter[item.key]}
                        setSelectedOption={(i) => onChangeFilter(item.key, i)}
                    />
                </LabelWrapper>
            ) : (
                <Dropdown
                    variant="black"
                    disabled={disabled}
                    options={item.options}
                    selectedOption={periodsFilter.selectedFilter[item.key]}
                    setSelectedOption={(i) => onChangeFilter(item.key, i)}
                />
            )}
        </div>
    ));
};

KpiFilters.propTypes = {
    labels: PropTypes.bool,
    disabled: PropTypes.bool,
    periodsFilter: PropTypes.object,
    onChangeFilter: PropTypes.func,
};

KpiFilters.defaultProps = {
    labels: true,
    disabled: false,
    periodsFilter: null,
    onChangeFilter: () => {},
};

export default KpiFilters;
