import React from 'react';

import PropTypes from 'prop-types';

import KpiIndexesContent from './kpi-indexes-content';
import KpiWrapper from './kpi-wrapper';

const KpiIndexes = ({
    update,
    indexes,
    onChangeIndexes,
    onAddIndexes,
    onRemoveIndexes,
    onChangeMonitorIndexes,
    onUpdateIndexes,
    onCancelUpdateIndexes,
    title,
    disabled,
    periods,
    remove,
    monitor,
    operations,
    ignoreToday,
    disabledIndexes,
}) => {
    return (
        <KpiWrapper title={title}>
            <KpiIndexesContent
                update={update}
                indexes={indexes}
                onChangeIndexes={onChangeIndexes}
                onAddIndexes={onAddIndexes}
                onRemoveIndexes={onRemoveIndexes}
                onChangeMonitorIndexes={onChangeMonitorIndexes}
                onUpdateIndexes={onUpdateIndexes}
                onCancelUpdateIndexes={onCancelUpdateIndexes}
                disabled={disabled}
                periods={periods}
                remove={remove}
                monitor={monitor}
                operations={operations}
                ignoreToday={ignoreToday}
                disabledIndexes={disabledIndexes}
            />
        </KpiWrapper>
    );
};

KpiIndexes.propTypes = {
    update: PropTypes.bool,
    indexes: PropTypes.array,
    onChangeIndexes: PropTypes.func,
    onAddIndexes: PropTypes.func,
    onRemoveIndexes: PropTypes.func,
    onChangeMonitorIndexes: PropTypes.func,
    onUpdateIndexes: PropTypes.func,
    onCancelUpdateIndexes: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    disabled: PropTypes.bool,
    periods: PropTypes.bool,
    remove: PropTypes.bool,
    monitor: PropTypes.bool,
    operations: PropTypes.bool,
    ignoreToday: PropTypes.bool,
    disabledIndexes: PropTypes.array,
};

KpiIndexes.defaultProps = {
    update: false,
    indexes: [],
    onChangeIndexes: () => {},
    onAddIndexes: () => {},
    onRemoveIndexes: () => {},
    onChangeMonitorIndexes: () => {},
    onUpdateIndexes: () => {},
    onCancelUpdateIndexes: () => {},
    title: null,
    disabled: false,
    periods: true,
    remove: false,
    monitor: false,
    operations: false,
    ignoreToday: false,
    disabledIndexes: [],
};

export default KpiIndexes;
