import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { Button } from '@material-ui/core';

import BasicTooltip from 'components/shared/basic-tooltip';
import GantContext from 'contexts/GantContext';
import GlobalContext from 'contexts/GlobalContext';
import UserContext from 'contexts/UserContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { internalActivity, projectInManagement } from 'routes';
import { errorHandling } from 'utils';
import API from 'utils/axios';

const InternalResources = () => {
    const cellHeight = 30;
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const isModulePM =
        history.location.pathname.search('/project-management') > -1 ||
        history.location.pathname.search('/projects-in-management') > -1;
    const { checkPerm } = useContext(UserContext);

    const canAllCrmPlanningOverview = checkPerm([
        {
            permissionId: '3',
            permissionType: 'ALL',
        },
    ]);
    const canAllPmPlanningOverview = checkPerm([
        {
            permissionId: '58',
            permissionType: 'ALL',
        },
    ]);

    const canAll = isModulePM
        ? canAllPmPlanningOverview
        : canAllCrmPlanningOverview;

    const globalContext = useContext(GlobalContext);
    const {
        internalEmployees,
        fetchEmployees,
        type,
        projectId = null,
    } = useContext(GantContext);
    const url = useMemo(() => {
        return type === 'internal' ? '/employees' : '/pm_employees';
    }, [type]);

    const editEmployeeUrl = useCallback(
        (employeeId) => {
            return type === 'internal'
                ? internalActivity.base +
                      internalActivity.internalResources.base +
                      internalActivity.internalResources.update +
                      '/' +
                      employeeId
                : projectInManagement.base +
                      `/${projectId}` +
                      projectInManagement.internalResources.base +
                      projectInManagement.internalResources.update +
                      '/' +
                      employeeId;
        },
        [type, projectId],
    );

    const calculateTotalHoursForEmployee = useCallback((employee) => {
        return employee.schedule
            .map((h) => h.hours)
            .reduce((acc, val) => acc + val, 0);
    }, []);

    const deleteEmployee = useCallback(
        async (id) => {
            try {
                await API.delete(
                    url + (type === 'internal' ? `` : `?id=${id}`),
                    {
                        data: {
                            id,
                        },
                    },
                );
                fetchEmployees();
            } catch (err) {
                enqueueSnackbar(
                    errorHandling(err).length > 100
                        ? errorHandling(err)
                        : t(errorHandling(err)),
                    {
                        variant: 'error',
                    },
                );
            }
        },
        [fetchEmployees, type],
    );

    const activateEmployee = useCallback(
        async (e) => {
            try {
                await API.put(url, {
                    id: e.id,
                    data: {
                        firstName: e.firstName,
                        lastName: 'm2m',
                        position: e.position,
                        schedule: e.schedule.map((s) => {
                            delete s.employeeId;
                            delete s.id;
                            delete s.date;
                            return { ...s };
                        }),
                        employeeType: e.employeeType,
                        email: e.email,
                        phoneNumber: e.phoneNumber,
                        CNP: '74895798753545',
                        address: 'Strada Narcisselor Nr 7',
                        teams: [],
                        isDeleted: false,
                    },
                });
                fetchEmployees();
            } catch (err) {
                enqueueSnackbar(
                    errorHandling(err).length > 100
                        ? errorHandling(err)
                        : t(errorHandling(err)),
                    {
                        variant: 'error',
                    },
                );
            }
        },
        [fetchEmployees],
    );

    return (
        <>
            <div className="page-container">
                {/* Active employee section */}
                {internalEmployees.length > 0 &&
                    internalEmployees
                        .filter((e) => !e.isDeleted)
                        .map((e) => (
                            <div
                                key={e.id}
                                className="group relative flex w-96 justify-between rounded-md bg-layout-transparent-dark px-4"
                                style={{
                                    height: cellHeight - 2.5,
                                    marginTop: 2.5,
                                }}
                            >
                                <div className="relative flex items-center">
                                    <p className="mr-2 text-lg font-semibold">
                                        {e.firstName}
                                    </p>
                                    <p className="text-white text-opacity-75">
                                        {e.position}
                                    </p>
                                    <p className="ml-1 mr-1 text-sm text-white text-opacity-50">
                                        &#9679;
                                    </p>
                                    <p className="text-white text-opacity-75">
                                        {calculateTotalHoursForEmployee(e)}h
                                    </p>
                                </div>

                                {canAll && (
                                    <div className="flex gap-2">
                                        <div className="flex items-center">
                                            <BasicTooltip
                                                tip={t('Edit employee')}
                                            >
                                                <div
                                                    className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-primary-main transition-all  hover:bg-primary-light group-hover:pointer-events-auto group-hover:translate-y-0"
                                                    onClick={() =>
                                                        history.push(
                                                            editEmployeeUrl(
                                                                e.id,
                                                            ),
                                                        )
                                                    }
                                                >
                                                    <EditIcon
                                                        className="text-white"
                                                        style={{
                                                            fontSize: '1.1rem',
                                                        }}
                                                    />
                                                </div>
                                            </BasicTooltip>
                                        </div>

                                        <div className="flex items-center">
                                            <BasicTooltip
                                                tip={t('Deactivate employee')}
                                            >
                                                <div
                                                    className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-red-400 transition-all  hover:bg-red-500 group-hover:pointer-events-auto group-hover:translate-y-0"
                                                    onClick={() => {
                                                        globalContext.setGlobalModalOpen(
                                                            true,
                                                        );
                                                        globalContext.setGlobalModalChildren(
                                                            <div className="flex-col">
                                                                <p className="mb-3 text-center text-2xl font-bold text-white">
                                                                    {t(
                                                                        'If an employee is deactivated,',
                                                                    )}
                                                                    <br />
                                                                    {t(
                                                                        'this will be deleted from all team, from all projects!',
                                                                    )}
                                                                </p>
                                                                <p className="mb-8 text-center text-2xl font-bold text-white">
                                                                    {t(
                                                                        'Are you sure you want to',
                                                                    )}
                                                                    <br />
                                                                    {t(
                                                                        'deactivate this employee?',
                                                                    )}
                                                                </p>
                                                                <div className="flex items-center justify-center">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            deleteEmployee(
                                                                                e.id,
                                                                            );
                                                                            globalContext.setGlobalModalOpen(
                                                                                false,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'Yes',
                                                                        )}
                                                                    </Button>
                                                                    <div className="w-4" />
                                                                    <Button
                                                                        onClick={() =>
                                                                            globalContext.setGlobalModalOpen(
                                                                                false,
                                                                            )
                                                                        }
                                                                    >
                                                                        {t(
                                                                            'No',
                                                                        )}
                                                                    </Button>
                                                                </div>
                                                            </div>,
                                                        );
                                                    }}
                                                >
                                                    <BlockIcon
                                                        className="text-white"
                                                        style={{
                                                            fontSize: '1.1rem',
                                                        }}
                                                    />
                                                </div>
                                            </BasicTooltip>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                <div className="mb-28"></div>

                {/* Deactivated employee section*/}
                {internalEmployees.length > 0 &&
                    internalEmployees
                        .filter((e) => e.isDeleted)
                        .map((e) => (
                            <div
                                key={e.id}
                                className="group relative flex w-96 justify-between rounded-md bg-layout-transparent px-4"
                                style={{
                                    height: cellHeight - 2.5,
                                    marginTop: 2.5,
                                }}
                            >
                                <div className="relative flex items-center ">
                                    <p className="mr-2 text-lg font-semibold text-white text-opacity-40">
                                        {e.firstName}
                                    </p>
                                    <p className="text-white text-opacity-30">
                                        {e.position}
                                    </p>
                                    <p className="ml-1 mr-1 text-sm text-white text-opacity-30">
                                        &#9679;
                                    </p>
                                    <p className="text-white text-opacity-40">
                                        {calculateTotalHoursForEmployee(e)} h
                                    </p>
                                </div>

                                {canAll && (
                                    <div className="flex items-center">
                                        <BasicTooltip
                                            tip={t('Activate employee')}
                                        >
                                            <div
                                                className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-green-500 transition-all  hover:bg-green-600 group-hover:pointer-events-auto group-hover:translate-y-0"
                                                onClick={() =>
                                                    activateEmployee(e)
                                                }
                                            >
                                                <PersonAddIcon
                                                    className="text-white"
                                                    style={{
                                                        fontSize: '1.1rem',
                                                    }}
                                                />
                                            </div>
                                        </BasicTooltip>
                                    </div>
                                )}
                            </div>
                        ))}
            </div>
        </>
    );
};

export default InternalResources;
